import "@fontsource/inter";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  Container,
  PosButton,
  PosCheckbox,
  PosImage,
  PosPageHeading,
} from "apps/pos/Common/uiCore";
import POSTextareaAutosize from "components/Input/POSTextareaAutosize";
import { sectionTitle, title } from "constants/constant";
import { PrintIcon } from "constants/icons";
import images from "constants/images";
import { addCustomerLabel } from "constants/posLabel";
import { setOrderInstructionNote, setShippingInstructionNote, setTemporaryStorageLocationNote } from "sagas/pos/actions";
import { keys } from "utils/helper";
import ButtonDatePicker from "./Common/ButtonDatePicker/ButtonDatePicker";

import { Button } from "components";
import EmailQuotes from "./EmailQuote";
import "./printOptions.css";

const PrintOptions = ({
  printInvoice,
  setPrintLoader,
  setPrintLoaderWithTotal,
  showPrices,
  priceCheck,
  markAsQuote,
  handlePriceCheck,
  handleMarkAsQuoteCheck,
  expiryDate,
  setExpiryDate,
  orderInstructions,
  setOrderInstructions,
  shippingInstructions,
  setShippingInstructions,
  temporaryStorageLocation,
  setTemporaryStorageLocation,
  emailQuote
}) => {
  const dispatch = useDispatch();
  const [hasFocus, setHasFocus] = useState(false);
  const [openEmailQuote, setOpenEmailQuote] = useState(false);
  const handleFocus = () => {
    setHasFocus(false);
  }

  const handleBlur = () => {
    setHasFocus(true);
  }

  const handleKeyPress = (e) => {
    const { key, keyCode } = e;
    const currentId = document.getElementById("shipping-instruction");

    if (document?.activeElement !== currentId) {
      const { one, two, numKey1, numKey2 } = keys;

      if (key === one || keyCode === numKey1) {
        setPrintLoader(true);
        printInvoice(1);
      }

      if (key === two || keyCode === numKey2) {
        setPrintLoaderWithTotal(true);
        printInvoice(2);
      }
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [hasFocus]);

  const { printIcon, cartTotalIcon } = images;
  const { print } = title;
  const { whatToPrint } = sectionTitle;

  const handleKeyDown = (e) => {
    e.keyCode !== keys.esc && e.stopPropagation()
  }

  const printCartWithTotal = () => {
    setPrintLoaderWithTotal(true);
    printInvoice(2);
  }

  return (
    <>
      <Container className="modal-content-style modal-container-styling">
        <main>
          <div className="pricing-modal-header print-option-header">
            <div className="payment-option-heading">
              <div>
                <PosPageHeading heading={print} grayHeading className="mb-1" />
                <PosPageHeading heading={whatToPrint} blackHeading />
              </div>
              <PrintIcon />
            </div>
          </div>
          <div className="print-option-section d-flex justify-content-between">
            <div className="d-flex">
              <div>
                <PosImage src={printIcon} alt="pick-icon" />
              </div>
              <div className="print-option-info">
                <div className="print-option-title">Pick ticket</div>
                <div className="print-option-subtitle">
                  List of all items to note picking info about warehouse
                  shipments
                </div>
              </div>
            </div>
            <div className="print-btn-sec">
              <PosButton
                fontWeight
                variant="contained"
                className="custom-size-5 btn"
                color="secondary"
                type="submit"
                onClick={() => {
                  setPrintLoader(true);
                  printInvoice(1);
                }}
              >
                Print
              </PosButton>
            </div>
          </div>
          <div className="print-option-section d-flex justify-content-between cart-with-total-sec flex-row-reverse">
            <div className="print-btn-sec">
              <PosButton
                fontWeight
                variant="contained"
                className="custom-size-5 btn"
                color="secondary"
                type="submit"
                onClick={printCartWithTotal}
              >
                Print
              </PosButton>
            </div>
            <div className="d-flex">
              <div>
                <PosImage src={cartTotalIcon} alt="cart-total-icon" />
              </div>
              <div className="print-option-info">
                <div className="print-option-title">Cart with Totals</div>
                <div className="print-option-subtitle">
                  Summary of all cart items with total amounts
                </div>
                {showPrices && (
                  <div className="hide-price-checkbox">
                    <PosCheckbox
                      checked={!priceCheck}
                      onChange={handlePriceCheck}
                      label="Hide prices"
                      className="checkbox-focus"
                    />
                  </div>
                )}
                <div className="mark-quote-container">
                  <div className="mark-quote-checkbox">
                    <PosCheckbox
                      checked={markAsQuote}
                      onChange={handleMarkAsQuoteCheck}
                      label="Mark as quote"
                      className="checkbox-focus"
                    />
                  </div>
                </div>
                {markAsQuote ?
                  <div className="email-quote-link">
                    <ButtonDatePicker value={expiryDate ? expiryDate : dayjs().add(1, 'day')}
                      setExpiryDate={setExpiryDate} />
                    <Button
                      className="see-all"
                      onClick={() => setOpenEmailQuote(true)}
                    >
                      Email Quote
                    </Button>
                  </div> : null
                }
              </div>
            </div>
          </div>
          <hr className="estimator-bottom-line" />
          <div onKeyDown={(e) => handleKeyDown(e)}>
            <div className="order-num-input">
              <span className="form-field-title text-black">
                {addCustomerLabel.orderNotes}
              </span>
            </div>
            <div className="margintop-10 text-area-focus">
              <POSTextareaAutosize
                id="order-notes"
                name="order-notes"
                className="double-bordered-input"
                value={orderInstructions}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={(e) => {
                  e.stopPropagation()
                  setOrderInstructions((e.target as HTMLInputElement).value);
                  dispatch(
                    setOrderInstructionNote(
                      (e.target as HTMLInputElement).value
                    )
                  );
                }}
              />
            </div>
          </div>
          <div onKeyDown={(e) => handleKeyDown(e)}>
            <div className="order-num-input">
              <span className="form-field-title text-black">
                {addCustomerLabel.shippingInstructions}
              </span>
            </div>
            <div className="margintop-10 text-area-focus">
              <POSTextareaAutosize
                id="shipping-instruction"
                name="shipping-instruction"
                className="double-bordered-input"
                value={shippingInstructions}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={(e) => {
                  e.stopPropagation()
                  setShippingInstructions((e.target as HTMLInputElement).value);
                  dispatch(
                    setShippingInstructionNote(
                      (e.target as HTMLInputElement).value
                    )
                  );
                }}
              />
            </div>
          </div>
          <div onKeyDown={(e) => handleKeyDown(e)}>
            <div className="order-num-input">
              <span className="form-field-title text-black">
                {addCustomerLabel.temporaryStorageLocation}
              </span>
            </div>
            <div className="margintop-10 text-area-focus">
              <POSTextareaAutosize
                id="temporary-storage-location"
                name="temporary-storage-location"
                className="double-bordered-input"
                value={temporaryStorageLocation}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={(e) => {
                  e.stopPropagation()
                  setTemporaryStorageLocation((e.target as HTMLInputElement).value);
                  dispatch(
                    setTemporaryStorageLocationNote(
                      (e.target as HTMLInputElement).value
                    )
                  );
                }}
              />
            </div>
          </div>
        </main>
      </Container >
      {openEmailQuote ?
        <EmailQuotes openEmailQuote={openEmailQuote} setOpenEmailQuote={setOpenEmailQuote} expiryDate={expiryDate} setExpiryDate={setExpiryDate} printCartWithTotal={printCartWithTotal} emailQuote={emailQuote} />
        : null}
    </>
  );
};

export default PrintOptions;
