enum GlobalNumbers {
  ZERO,
  ONE,
  TWO,
  THREE,
  FOUR,
}

enum DateFormat {
  MM_DD_YYYY = "MM/DD/YY",
  YYYY_MM_DD = "YYYY-MM-DD",
  MMMM_D_YYYY_HH_MM = "MMMM D, YYYY HH:MM",
  DD_MM_YYYY = "DD-MM-YYYY",
}
enum PaginationFormat {
  Ten = 10,
  One = 1,
  Ascending = "ASCENDING",
  Descending = "DESCENDING",
  Twenty = 20,
  Asc = "asc",
  Calories = "calories",
  Fifty = 50,
}

enum ProductTypes {
  typeClearance = "CLEARANCE", //1
  typeDiscontinued = "DISCONTINUED", //2
  typeSalesOverride = "SALES_OVERRIDE", //3
  typePriceScheduleSingle = "PRICE_SCHEDULE_SINGLE", //4
  typePriceScheduleVolume = "PRICE_SCHEDULE_VOLUME", //5
  typeLastPosRetailPrice = "LAST_POS_RETAIL_PRICE", //6
  typeSalesPrice = "SALES_PRICE", //7 set custom price
  typeVendorPrice = "VENDOR_PRICE", //8 set custom price
  typeInActive = "INACTIVE", //9
  typeMovingAvgCost = "MOVING_AVG_COST",
  outStock = "OUT OF STOCK",
  typeWholesalePrice = "WHOLESALE_PRICE",
}

enum Keys {
  enter = "Enter",
  password = "password",
  text = "text",
  escape = "Escape",
}

enum SearchKey {
  card = "card",
  permission = "permission",
  lockUnlocks = "lockUnlock",
  changePassword = "changePassword",
}

export enum FieldName {
  userCountryCode = "countryCode",
  userProfilePhone = "userProfilePhone",
  userProfileName = "userProfileName",
  userProfileLastName = "userProfileLastName",
  userProfileEmail = "userProfileEmail",
  extCodes = "extCode",
  adminPermissions = "adminPermission",
  userPermissions = "userPermission",
  password = "password",
}

export enum imgAlterText {
  closeIconAltText = "close-icon",
  printIconAltText = "print-icon",
  returnIconAltText = "return-icon",
  plusIconAltText = "plus-icon",
  returnSuccessFully = "Return processed successfully.",
  returnNotSuccessFully = "Return not processed successfully.",
  returnPartially = "Return processed partially",
  returnFully = "Return complete",
  returnFullyFailed = "Return failed",
}

export enum flagKey {
  sale = 0,
  noSale = 1,
}

export enum locationType {
  current = "CURRENT",
  all = "ALL",
}

export enum tabType {
  add = 0,
  remove = 1,
  move = 2,
}

export enum paymentType {
  cash = "CASH",
  card = "CARD",
  check = "CHECK",
  payLater = "REGISTERED_ACCOUNT",
  storeCredit = "STORE_CREDIT",
  customerStoreCredit = "CUSTOMER_CREDIT",
}

export const defaultSelectedRefundMode = {
  'CASH': '0',
  'CREDIT_CARD': '2',
  'CHECK': '4',
  'CUSTOMER_CREDIT': '3',
  'REGISTERED_ACCOUNT': '4'
}

export const defaultSelectedRefundModeReverse = {
  '0': 'CASH',
  '2': 'CREDIT_CARD',
  '4': 'REGISTERED_ACCOUNT',
  '3': 'CUSTOMER_CREDIT',
}

export const PaymentTypeEnum = {
  CASH: "CASH",
  CREDIT_CARD: "CARD",
  CHECK: "CHECK",
  REGISTERED_ACCOUNT: "PAY LATER",
  CUSTOMER_CREDIT: "STORE CREDIT",
};

export const RefundPaymentTypeEnum = {
  CASH: "Cash",
  CREDIT_CARD: "Card",
  CHECK: "Check",
  REGISTERED_ACCOUNT: "Pay later",
  CUSTOMER_CREDIT: "Store credit",
};

export enum localStorageDataGet {
  taker = "taker",
}

enum PhoneCode {
  phnCode = "+1",
}

export enum documentByModule {
  tax = "tax",
  ship = "ship",
  customer = "customer",
  customerList = "customerList",
  taxExemptCerti = "Tax exempt certificate",
  taxExemptCertificate = "Tax Exempt Certificate",
  shared = "SHARED",
  all = "ALL",
  current = "CURRENT",
  privateDoc = "PRIVATE",
  notSet = "NOT_SET",
  payment = "payment",
  addressProf = "ID - Address Proof",
  deliveryProf = "Delivery Proof",
}

export enum hashRoute {
  search = "#search",
}

export enum statusCode {
  "notFound" = 404,
}

export enum itemHistoryLabel {
  avgMonthly = "AVG. MONTHLY USAGE",
  avgDeviation = "AVG. DEVIATION",
  monthsInSeasons = "MONTHS IN SEASON",
}

export enum storageTabTypeEnum {
  pending = 0,
  delivered = 1,
}

export enum cardNumbers {
  four = 4,
}

export enum LookupTypeEnum {
  jobName = "JOB_NAME",
  order = "ORDER",
  customerPO = "CUSTOMER_PO",
}
export enum accessoriesKey {
  goesWith = 1,
  replaces = 2,
}

export enum CustomerTabs {
  customer = 0,
  documents = 1,
  drafts = 2,
  orders = 3,
  storage = 4,
}

export enum ProductDetailText {
  Cost = "COSTS",
  Vendor = "Vendor",
  VendorCost = "Vendor Cost",
  Stock = "Stock",
  Price = "PRICES",
  Wholesale = "Wholesale",
  WholesalePrice = "Wholesale Price",
  PriceEach = "Price /",
  Prof = "Prof %",
  Margin = "Margin %",
  Override = "Override",
  AppiedToPos = "Applies to POS only.",
  Discontinued = "Discontinued",
  Remove = "REMOVE",
  Clearance = "Clearance",
  ClearanceQty = "Clearance Qty.",
  ADDITIONAL_PRICES = "ADDITIONAL PRICES",
  Manufacturer = "Manufacturer",
  Hot_Buy = "Hot Buy",
  Hot_Buy_Expiry = "Hot Buy Expiry",
  Golden_Rod = "Golden Rod",
  Claim_Back = "Claim Back",
  Use_Claim_back_price = "Use Claim back price",
  salesPrice = "sales price",
  wholesalePrice = "wholesale price.",
  changedFrom = "Changed from $",
  movingAvg = "Moving Avg.",
}

export enum Numbers {
  zero = 0,
}
export {
  DateFormat, GlobalNumbers, Keys, PaginationFormat, PhoneCode, ProductTypes, SearchKey
};

export enum ProductPriceType {
  DISCONTINUED = 1,
  CLEARANCE = 2,
  SALES_OVERRIDE = 3,
  PRICE_SCHEDULE_SINGLE = 4,
  PRICE_SCHEDULE_VOLUME = 5,
  LAST_POS_RETAIL_PRICE = 6,
  SALES_PRICE = 7,
  VENDOR_PRICE = 8,
  MOVING_AVG_COST = 9,
  FIXED_MARGIN_PRICE = 10,
  SALES_PRICE_PER_CASE = 11,
  CUSTOM = 12,
}

export enum ProductVpgTypes {
  typeVpgSalesPrice = "PRODUCT_WHOLESALE_PRICE", //7 set custom price
  typeVpgVendorPrice = "PRODUCT_VENDOR_PRICE", //8 set custom price
  typeVpgMovingAvgCost = "MOVING_AVG_COST",
}

export const ChargeCreditEnum = {
  Visa: 1,
  Mastercard: 2,
  'American Express': 3,
  Discover: 4,
};