import "@fontsource/inter";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Container, PosButton, PosHelmet, PosImage, PosPageHeading } from "apps/pos/Common/uiCore";
import { Paths } from "enums";
import { Distributor, RootState } from "models";
import { beforeUnloadCart, openAndPrintPdf } from "utils/helper";

import {
  clearLoadCartDetails,
  setCustomerAlertHide,
  setPosFlowData,
  shippingAddressSelectedData
} from "sagas/persistReducer/actions";
import { setJobNameForAll, setMarkAsQuoteReducer, setShippingInstructionNote, setStoreCreditAmout } from "sagas/pos/actions";

import images from "constants/images";
import "./neworder.scss";
import MaterialIcon from "./Common/uiCore/MaterialIcon";

const OrderCompleted = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { posFlowData } = useSelector<RootState, any>(
    (state) => state.persistReducer
  );
  const distributor = useSelector<RootState, Distributor>(
    (state) => state.distributor
  );
  const { checkInvoiceReload } = useSelector<RootState, any>(
    (state) => state.pos
  );

  const { priceVisible } = posFlowData || {};

  const printOrderInvoice = posFlowData?.orderCompleteData?.docs?.invoicePdfBase64;
  const orderCompleteId = posFlowData?.orderCompleteData?.orderId;
  const shipmentId = posFlowData?.orderCompleteData?.shipmentId;

  const broadcastFunction = (newWindow) => {
    const signingChannel = new BroadcastChannel('signingChannel');
    signingChannel.addEventListener("message", (event) => {
      if (event.origin !== window.location.origin || !event.data) {
        return;
      }

      try {
        const { message } = JSON.parse(event.data);
        if (message === 'saved' && newWindow) {
          newWindow.close();
          signingChannel.close();
        }
      } catch (error) {
        console.error("Error processing packingSlip message:", error);
      }
    });
  }

  const printInvoice = (data) => {
    openAndPrintPdf(data)
  };

  const pickingSlip = () => {
    // navigate(`${Paths.posPackingSlip}/${orderCompleteId}/${shipmentId} `)
    const newWindow = window.open(`${distributor.httpsUrl}${Paths.packingSlip}?orderID=${orderCompleteId}&shipmentID=${shipmentId}&sign=true&photo=false`);
    broadcastFunction(newWindow)
  }

  const handleKeyPress = (e) => {
    const { keyCode } = e;
    if (true) {
      if (keyCode === 73) {
        printInvoice(printOrderInvoice);
      } else if (keyCode === 78) {
        dispatch(clearLoadCartDetails());
        const data = {
          priceVisible: priceVisible,
        };
        dispatch(setPosFlowData(data));
        navigate(Paths.posNewOrder);
      }
      else if (keyCode === 80) {
        pickingSlip()
      }
    }
  }
  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return function cleanup() {
      document?.removeEventListener("keydown", handleKeyPress);
    };
  }, []);
  useEffect(() => {
    if (!posFlowData?.orderCompleteData?.orderId || checkInvoiceReload) {
      navigate(Paths.posNewOrder);
      dispatch(clearLoadCartDetails());
    }
    const payload = {
      ...posFlowData,
      cartProducts: [],
      addInStorage: []
    }
    dispatch(setPosFlowData(payload));
    beforeUnloadCart([])
  }, []);
  return (
    <Container className="new-container mt-10">
      <PosHelmet title="POS" metaName="Admin page" content="Admin page" />
      <div className="order-complete gap-3">
        <div className="d-flex justify-content-between align-items-center w-100 flex-direction-column">
          <div className="order-row">
            <div className="order-cell">
              <PosPageHeading
                heading="Order Complete"
                blackHeading
                className="mb-1"
              />
            </div>
            <div className="d-flex gap-2">
              <div>
                {shipmentId !== 0 && <PosButton
                  variant="contained"
                  color="secondary"
                  className="modal-select-default-btn ship-btn justify-content-start order-complete-print-invoice-btn btn"
                  onClick={() => pickingSlip()}
                  tabIndex={1}
                >
                  <div>
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="d-flex align-items-center">
                        <MaterialIcon icon="receipt_long" size="18" classes="mr-2" />Packing Slip
                      </span>
                      <span className="keyboard-shortcut-pay keyboard-shortcut avatar-shortcut ml-2">
                        P
                      </span>
                    </div>
                  </div>
                </PosButton>}
              </div>
              <div>
                <PosButton
                  variant="contained"
                  color="secondary"
                  className="modal-select-default-btn ship-btn justify-content-start order-complete-print-invoice-btn btn"
                  onClick={() => printInvoice(printOrderInvoice)}
                  tabIndex={1}
                >
                  <div>
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="d-flex align-items-center">
                        <MaterialIcon icon="receipt_long" size="18" classes="mr-2" />Invoice
                      </span>
                      <span className="keyboard-shortcut-pay keyboard-shortcut avatar-shortcut ml-2">
                        I
                      </span>
                    </div>
                  </div>
                </PosButton>
              </div>
              <div onKeyDown={(e) => handleKeyPress(e)}>
                <PosButton
                  variant="contained"
                  color="primary"
                  className="modal-select-default-btn ship-btn-dark justify-content-start order-complete-print-invoice-btn btn"
                  tabIndex={2}
                  onClick={() => {
                    dispatch(clearLoadCartDetails());
                    const data = {
                      priceVisible: priceVisible,
                      addDocument: [],
                      poNumber: "",
                      firstName: "",
                      lastName: "",
                      phone: "",
                      email: "",
                      companyName: "",
                    };
                    dispatch(setPosFlowData(data));
                    dispatch(setShippingInstructionNote(""));
                    dispatch(setJobNameForAll(""));
                    dispatch(setMarkAsQuoteReducer(false));
                    dispatch(setCustomerAlertHide(true));
                    dispatch(setStoreCreditAmout(""));
                    navigate(Paths.posNewOrder);
                    dispatch(shippingAddressSelectedData(false))
                  }}
                >
                  <div className="d-flex justify-content-between w-100 align-items-center">
                    <span className="text-white d-flex align-items-center">
                      <MaterialIcon icon="add_circle" size="18" classes="mr-2" /> Start a New Order
                    </span>
                    <span className="keyboard-shortcut-pay keyboard-shortcut avatar-shortcut ml-2">
                      N
                    </span>
                  </div>
                </PosButton>
              </div>
            </div>
          </div>
          <div className="order-row">
            <div className="order-cell">
              <div className={`page-heading d-flex gap-2 focus-link-navigation`}>
                <span className="heading-gray-text">
                  <a
                    href={`${distributor?.httpsUrl}${Paths.manageOrder}?orderID=${orderCompleteId}`}
                    target="_blank"
                  >
                    <span>{`ID #${orderCompleteId}`}</span>
                    <PosImage
                      src={images.linkIcon}
                      height={24}
                      width={24}
                      alt="Link Image"
                    />
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="order-row btm-border">
          <div className="order-cell">
            <span className="int-20-reg-bold">
              <i className="fas fa-check-circle icn-btn"></i> Order placed and
              processed successfully.
            </span>
          </div>

        </div>
        <div className="order-row">
          <div className="order-cell">
            <span></span>
          </div>
          <div></div>
        </div>
      </div>
    </Container>
  );
};

export default OrderCompleted;
