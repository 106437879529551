import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import './ButtonDatePicker.scss';

import { setQuoteExpiryDate } from 'sagas/pos/actions';

const ButtonDatePicker = ({ value, setExpiryDate }) => {
  const [dateValue, setDateValue] = useState(value);
  const dispatch = useDispatch();
  const dateInputRef = useRef(null);

  useEffect(() => {
    setDateValue(value);
  }, [value]);

  const handleButtonClick = () => {
    if (dateInputRef.current) {
      dateInputRef.current.showPicker()
    }
  };

  return (
    <div className='button-date-picker'>
      <label
        className="set-expiry-btn btn-datepicker-focus"
        onClick={(e) => {
          e.stopPropagation();  // Prevent Card onClick from firing
          handleButtonClick();
        }}
        htmlFor='datepicker'
        tabIndex={0}
        onKeyDown={(e) => {
          e.stopPropagation();  // Prevent Card onClick from firing when using keyboard
          switch (e.key) {
            case 'Enter':
            case ' ':
              dateInputRef.current.showPicker()
              break;
          }
        }}
      >
        {!dateValue ? 'Set Expiry Date' : 'Expires'} {
          !dateValue ? '' : dayjs(dateValue).format("MM/DD/YY")
        }

      </label>

      <input
        type="date"
        ref={dateInputRef}
        value={dayjs(dateValue).format("YYYY-MM-DD")}
        tabIndex={-1}
        onChange={(event) => {
          if (event.target.value) {
            setDateValue(event.target.value);
            setExpiryDate(event.target.value);
            dispatch(setQuoteExpiryDate(event.target.value));
          }
          else {
            setDateValue("")
          }
        }}
        onClick={(event) => event.stopPropagation()}
        id='datepicker'
      />
    </div>
  );
};

export default ButtonDatePicker;

