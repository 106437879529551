import { Box, Container, Divider, Grid } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { array, number, object, string } from "yup";
import { ErrorMessage } from "../formik/errorMessage";

import PageHeader from "apps/pos/Common/PageHeader/PageHeader";
import {
  PosButton,
  PosHelmet,
  PosImage,
  PosInput,
  PosSnackbar,
} from "apps/pos/Common/uiCore";
import { setIsApiLoading } from "../pages/customerInfo/CustomerOrders/redux/customerOrder.slice";
import { useDeliverEntireShipment } from "../pages/customerInfo/CustomerStorage/hooks/useDeliverEntireShipment";
import { useDeliverShipment } from "../pages/customerInfo/CustomerStorage/hooks/useDeliverShipment";
import { getStorageItemDetail } from "../pages/customerInfo/CustomerStorage/redux/selector";
import { setDeliverySuccessData, setIsPendingOrderDelivery } from "../pages/customerInfo/CustomerStorage/redux/storage.slice";

import DeliveryDetailsModals from "apps/pos/storage/DeliveryDetails";
import { Keys } from "constants/enum";
import { CheckIcon, DoneAllIcon } from "constants/icons";
import images from "constants/images";
import { Paths } from "enums";

import "../../pos/storage/putInStorage.scss";

const DeliverItemsInStorage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();

  const { deliverEntireShipmentFn, isSuccess: isDeliverEntireShipmentSuccess, isLoading: deliverEntireShipmentIsLoading, data: entireShipmentDeliveryData } = useDeliverEntireShipment()
  const { deliverShipmentFn, isSuccess: isDeliverShipmentSuccess, data, isLoading: deliverShipmentIsLoading } = useDeliverShipment()

  const isApiLoading = [deliverEntireShipmentIsLoading, deliverShipmentIsLoading].includes(true)
  const storageItemDetail = useSelector(getStorageItemDetail)
  const { shipmentInStorageID } = storageItemDetail || {}
  const { defaultIcon } = images;

  const [deliveryDetails, setDeliveryDetails] = useState(false);
  const [deliverEntireShipment, setDeliverEntireShipment] = useState(false);
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);

  const formik = useFormik<any>({
    initialValues: {
      items: [],
    },
    validationSchema: object().shape({
      items: array(
        object({
          salesPrice: number(),
          shipmentInStorageItemID: number(),
          qtyInDelivery: string(),
        })
      ),
    }),
    onSubmit: () => {
      // console.log("values");
    },
  });

  const { values, setFieldValue, handleSubmit, errors } = formik;

  useEffect(() => {
    if (isDeliverShipmentSuccess || isDeliverEntireShipmentSuccess) {
      dispatch(setIsPendingOrderDelivery(true))
      setDeliveryDetails(false)
      setIsSnackbarVisible(true)
    }

  }, [isDeliverEntireShipmentSuccess, isDeliverShipmentSuccess])

  useEffect(() => {
    if ((isDeliverShipmentSuccess || isDeliverEntireShipmentSuccess) && !isSnackbarVisible) {

      dispatch(setDeliverySuccessData(deliverEntireShipment ? entireShipmentDeliveryData : data))
      setTimeout(() => {
        navigate(Paths.subStorageItems, { state: { isStorageInfo: true, isDeliverShipmentSuccess: isDeliverShipmentSuccess, isNotFromSR: true } })

      }, 2500);
    }
  }, [isDeliverShipmentSuccess, isDeliverEntireShipmentSuccess])

  useEffect(() => {
    if (state?.pendingItemsData?.length) {
      state?.pendingItemsData?.forEach((_, index: number) => {
        setFieldValue(`items[${index}].qtyInDelivery`, '');
      });
    }
  }, [state?.pendingItemsData]);

  const handleKeyDown = (event) => {
    if (event.key === Keys.escape && location.pathname === Paths.deliverItemsInStorage) {
      navigate(Paths.subStorageItems, { state: { isNotFromSR: true } });
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [navigate]);

  useEffect(() => {
    dispatch(setIsApiLoading(isApiLoading));
  }, [isApiLoading])

  const handleDeliverAllButton = () => {
    setDeliverEntireShipment(true);
    setDeliveryDetails(true)
  }

  const handleDeliverButton = () => {
    handleSubmit()
    setDeliverEntireShipment(false);
    !errors?.items?.length && setDeliveryDetails(true)
  }

  const handleQtyInDelivery = (
    e,
    qtyInStorage,
    index
  ) => {
    const { target: { value } } = e
    if (Number(value) <= 0) {
      setFieldValue(`items[${index}].qtyInDelivery`, "");
    } else {
      const difference = Number(qtyInStorage);
      if (difference < 0) {
        setFieldValue(`items[${index}].qtyInDelivery`, 0);
      }
      else if (Number(value) >= difference) {
        setFieldValue(`items[${index}].qtyInDelivery`, difference);
      } else if (Number(value) < difference) {
        setFieldValue(`items[${index}].qtyInDelivery`, value);
      }
    }

  };

  const isDeliverBtnDisabled = values?.items?.filter((item) => item.qtyInDelivery > 0).length

  return (
    <>
      <FormikProvider value={formik}>
        <Form>
          <div className="put-storage-container">
            <PosHelmet
              title="pos :: Deliver Items in Storage"
              metaName="deliverItemsInStorage page"
              content="deliverItemsInStorage page"
            />
            <PageHeader
              onClose={() => navigate(-1)}
              title="Deliver Items in Storage"
              titleColor={false}
              subTitleColor
              subTitle={`#${shipmentInStorageID ? shipmentInStorageID : storageItemDetail?.shipmentID}`}
              tabIndex={-1}
            >
              <div className="d-flex" tabIndex={0}>
                {/* {Remove false to display deliver all button} */}
                {false && <div className="btn-change-customer btn">
                  <PosButton
                    variant="contained"
                    className="deliver-all-btn"
                    startIcon={<DoneAllIcon />}
                    onClick={handleDeliverAllButton}
                    tabIndex={1}
                  >
                    Deliver All
                  </PosButton>
                </div>}
                <div>
                  <PosButton
                    className="btn deliver-btn"
                    color="primary"
                    variant="contained"
                    startIcon={<CheckIcon />}
                    onClick={handleDeliverButton}
                    tabIndex={1}
                    disabled={!isDeliverBtnDisabled}
                  >
                    Deliver
                  </PosButton>
                </div>
              </div>
            </PageHeader>
            <Container className="new-container mt-3">
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={4} className="fs-10">
                    <div className="storage-first-sec-title">ITEM</div>
                  </Grid>
                  <Grid item xs={1} className="fs-10">
                    PRODUCT ID
                  </Grid>
                  <Grid item xs={2} className="fs-10">
                    MANUFACTURER
                  </Grid>
                  <Grid item xs={1} className="fs-10 text-right">
                    IN STORAGE
                  </Grid>
                  <Grid item xs={1} className="fs-10 text-right">
                    DELIVERED
                  </Grid>
                  <Grid item xs={1} className="fs-10 text-right">
                    AWAITING
                  </Grid>
                  <Grid item xs={2} className="fs-10">
                    <div className="storage-qty-title">TO DELIVER</div>
                  </Grid>
                </Grid>
              </Box>
            </Container>

            <div className="put-in-storage-table">
              {state?.pendingItemsData?.map((item, index) => {
                const { productName, manufacturerCode, manufacturerName, productID, qtyInStorage, qtyDelivered } = item;
                return (
                  <Container className="new-container" key={`index${index}`}>
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={2} className="order-items">
                        <Grid item xs={4} className="customer-first-info-col">
                          <PosImage
                            src={defaultIcon}
                            alt="filter"
                            className="customer-storage-img"
                          />
                          <div className="customer-name fs-16">
                            <span className="fw-medium">{productName}</span>
                            <span className="int-14-reg-gray">{manufacturerCode}</span>
                          </div>
                        </Grid>
                        <Grid item xs={1} className="fs-16">
                          {productID}
                        </Grid>
                        <Grid item xs={2} className="fs-16">
                          {manufacturerName}
                        </Grid>
                        <Grid item xs={1} className="fs-16 text-right">
                          {qtyInStorage}
                        </Grid>
                        <Grid item xs={1} className="fs-16 text-right">
                          {qtyDelivered}
                        </Grid>
                        <Grid item xs={1} className="fs-16 text-right">
                          {qtyInStorage}
                        </Grid>
                        <Grid item xs={2} className="text-right">
                          <PosInput
                            className="qty-input input-focus-styling input-out-focus-light-text"
                            variant="filled"
                            id="filled-basic"
                            autoFocus={!index}
                            InputProps={{ disableUnderline: true }}
                            name={`items[${index}].qtyInDelivery`}
                            value={values?.items[index]?.qtyInDelivery}
                            onChange={(e) => handleQtyInDelivery(e, qtyInStorage, index)}
                            onFocus={(e) => e.target.select()}
                          />
                          <ErrorMessage name={`items[${index}].qtyInDelivery`} />

                        </Grid>
                      </Grid>
                      <Divider className="update-product-container-line" />
                    </Box>
                  </Container>
                );
              })}
            </div>
            <DeliveryDetailsModals
              deliveryDetails={deliveryDetails}
              setDeliveryDetails={setDeliveryDetails}
              isDeliverEntireShipment={deliverEntireShipment}
              itemsDetail={values}
              isApiLoading={isApiLoading}
              deliverEntireShipmentFn={deliverEntireShipmentFn}
              deliverShipmentFn={deliverShipmentFn}
            />
          </div>
        </Form>
      </FormikProvider>
      {(isDeliverShipmentSuccess || isDeliverEntireShipmentSuccess) ? (
        <PosSnackbar
          open={isSnackbarVisible}
          message={`${deliverEntireShipment ? 'Entire shipment in storage delivered' : 'Selected shipment items in storage delivered'}`}
          onClose={() => {
            setIsSnackbarVisible(false);
          }}
          actionText="Undo"
        />
      ) : null}
    </>
  );
};

export default DeliverItemsInStorage;
