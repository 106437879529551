import { Button } from "components";
import { PosInput, PosModel } from "../../Common/uiCore";
import { Formik } from "formik";
import * as Yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import { snakbarShow } from "sagas/pos/snakbar/snakbar.slice";
import { useEffect, useMemo } from "react";
import SPButton from "components/SPButton/Button";
import MaterialIcon from "../../Common/uiCore/MaterialIcon/MaterialIcon";
import { sendEmailPackingSlip } from "services/pos/pos.service";
import { useNavigate, useParams } from "react-router-dom";
import { Paths } from "enums/paths";
import { authorizationToken } from "utils/utils";
import { clearSignatures } from "sagas/pos/actions";

interface PackingSlipState {
    packingSlip: string | null;
    packingSlipLoading: boolean;
    packingSlipError: string | null;
    displaySignature: boolean;
}

const CustomerSendModal = ({ open, onClose }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { orderId, shipmentId } = useParams();

    const {
        packingSlipLoading,
        packingSlipError,
        displaySignature
    } = useSelector((state: { pos: PackingSlipState }) => state.pos);

    const validationSchema = Yup.object().shape({
        customerEmail: Yup.string()
            .email('The email is invalid.')
    });

    const loginId = useMemo(() => {
        const decodedData = authorizationToken("Authorization");
        return decodedData?.DefaultLoginId;
    }, [])

    const handleSubmitSignature = (values, { setSubmitting }) => {
        const body = {
            orderID: Number(orderId),
            shipmentID: Number(shipmentId),
            handlerId: loginId,
            base64Image: displaySignature,
            email: values.submitType === 'send' ? values.customerEmail : ""
        }

        sendEmailPackingSlip(body)
            .then((response) => {
                if (response?.base64Template) {
                    dispatch(clearSignatures());
                    dispatch(snakbarShow({ message: `Signature saved` }));
                    navigate(Paths.posOrderComplete);

                }
            })
            .catch((error) => {
                console.log(error, "error")
            })
            .finally(() => {
                setSubmitting(false);
            })
    }

    if (packingSlipLoading) {
        return <div>Loading...</div>
    }

    if (packingSlipError) {
        return <div>Error...</div>
    }

    return (
        <Formik
            initialValues={{
                customerEmail: "",
                submitType: ""
            }}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={handleSubmitSignature}
        >
            {({ values, handleSubmit, setFieldValue, errors, setFieldError, isSubmitting }) => {
                useEffect(() => {
                    const customerName = document.querySelector('.customer-name') as HTMLElement;
                    const customerEmail = document.querySelector('.customer-email')?.textContent.trim();
                    if (customerName && !customerName?.textContent?.toLowerCase().includes("generic")) {
                        setFieldValue("customerEmail", customerEmail);
                    }
                }, [open])

                const handleFormSubmit = (e) => {
                    e.preventDefault();
                    const submitType = values.customerEmail && !errors.customerEmail ? 'send' : 'dont-send';
                    setFieldValue('submitType', submitType);
                    handleSubmit();
                };

                return (
                    <PosModel
                        open={open}
                        onClose={onClose}
                        className={`set-customer-code-modal`}
                        dialogClassName={`set-customer-code-modal-container packing-slip-customer-modal`}
                        dialogHeader={
                            <div className="modal-header">
                                <span className="modal-title">
                                    Send Packing Slip
                                </span>
                                <Button className="bg-transparent border-0 p-0 button-focus"
                                    onClick={onClose} >
                                    <MaterialIcon icon="close" />
                                </Button>
                            </div>
                        }
                        dialogFooter={
                            <>
                                <div className="d-flex flex-row-reverse">
                                    <SPButton
                                        label="Send"
                                        type="primary"
                                        onClick={() => {
                                            setFieldValue('submitType', 'send');
                                            handleSubmit();
                                        }}
                                        disabled={isSubmitting || !values.customerEmail || errors.customerEmail}
                                        classes={isSubmitting && values.submitType === 'send' ?
                                            "button-loading-save-packing-slip button-accent button-is-loading-black" : ""}
                                    />
                                    <SPButton
                                        label="Don't Send"
                                        type="secondary"
                                        onClick={() => {
                                            setFieldValue('submitType', 'dont-send');
                                            handleSubmit();
                                        }}
                                        disabled={isSubmitting}
                                        classes={isSubmitting && values.submitType === 'dont-send' ?
                                            "button-loading-save-packing-slip button-accent button-is-loading-black mr-2" : "mr-2"}
                                    />
                                </div>
                            </>
                        }
                    >
                        <form onSubmit={handleFormSubmit}>
                            <div className="modal-form">
                                <div className="modal-input-title mb-2">
                                    Customer Email
                                </div>
                                <div className="w-100">
                                    <PosInput
                                        className="input-focus-styling"
                                        variant="filled"
                                        id="filled-basic"
                                        autoFocus
                                        InputProps={{ disableUnderline: true }}
                                        name="customerEmail"
                                        value={values?.customerEmail}
                                        onChange={(e) => {
                                            setFieldError
                                            setFieldValue("customerEmail", e.target.value)
                                        }}
                                        error={errors.customerEmail !== undefined}
                                        helperText={errors.customerEmail?.toString()}
                                    />
                                </div>
                                {
                                    errors.customerEmail ?
                                        <p className="text-red">
                                            {errors.customerEmail?.toString()}
                                        </p>
                                        : null
                                }
                            </div>
                        </form>
                    </PosModel>
                );
            }}
        </Formik>
    );
};

export default CustomerSendModal;

