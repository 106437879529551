import { useEffect, useRef, useState, useCallback } from 'react';
import { createPortal } from 'react-dom';
import SignaturePad from 'components/SignaturePad/SignaturePad';

interface RenderHtmlWithComponentProps {
    htmlString: string;
}

interface DOMElements {
    targetDiv: HTMLElement | null;
    container: HTMLElement | null;
    printElement: HTMLElement | null;
    signatureSurface: HTMLElement | null;
    thead: HTMLElement | null;
    customerName: HTMLElement | null;
    customerCompany: string | null;
}

const RenderHtmlWithComponent = ({ htmlString }: RenderHtmlWithComponentProps) => {

    const containerRef = useRef<HTMLDivElement>(null);
    const [targetDiv, setTargetDiv] = useState<HTMLElement | null>(null);
    const [customerName, setCustomerName] = useState<string>('');

    useEffect(() => {
        // Find and disable the specific form1 submission
        const form1 = document.forms['form1'];
        if (form1) {
            form1.onsubmit = (e) => {
                e.preventDefault();
                return false;
            };
        }
    }, []);

    const getDOMElements = useCallback((): DOMElements => ({
        targetDiv: containerRef.current?.querySelector<HTMLElement>('.sign-here') ?? null,
        container: containerRef.current?.querySelectorAll<HTMLElement>('.container')[1] ?? null,
        printElement: document.querySelector<HTMLElement>('.print-here'),
        signatureSurface: document.querySelector<HTMLElement>('.sign-here'),
        thead: document.querySelector<HTMLElement>('thead'),
        customerName: document.querySelector<HTMLElement>('.customer-name'),
        customerCompany: document.querySelector('.customer-company')?.textContent.trim() ?? null,
    }), []);

    const applyStyles = useCallback((elements: DOMElements) => {
        if (elements.targetDiv) {
            elements.targetDiv.innerHTML = '';
        }
        if (elements.container) {
            elements.container.style.backgroundColor = 'white';
        }
        if (elements.printElement) {
            elements.printElement.style.display = 'none';
        }
        if (elements.thead) {
            elements.thead.style.position = 'static';
        }
        if (elements.customerName) {
            elements.customerName.style.display = "inline";
        }
    }, []);

    const generateCustomerName = useCallback((elements: DOMElements): string => {
        if (!elements.customerName?.textContent) return '';

        const nameText = elements.customerName.textContent.trim();
        if (nameText.toLowerCase().includes("generic")) return '';

        return `${nameText}${elements.customerCompany ? ", " + elements.customerCompany : ""}`;
    }, []);

    const cleanupBRElements = useCallback((elements: DOMElements) => {
        if (!elements.signatureSurface || !elements.printElement) return;

        let currentElement = elements.signatureSurface.nextSibling;
        while (currentElement && currentElement !== elements.printElement) {
            const nextElement = currentElement.nextSibling;
            if (currentElement.nodeName === 'BR') {
                currentElement.remove();
            }
            currentElement = nextElement;
        }
    }, []);

    const cleanupUnnecessaryElements = useCallback(() => {
        const elementsToRemove = [
            '.signature-btn-wrapper',
            '.signature-banner',
            '.signature-dialog'
        ];

        elementsToRemove.forEach(selector => {
            const element = containerRef.current?.querySelector(selector);
            if (element) {
                element.remove();
            }
        });
    }, []);

    useEffect(() => {
        if (!containerRef.current || !htmlString) return;

        const elements = getDOMElements();
        cleanupBRElements(elements);
        cleanupUnnecessaryElements();
        applyStyles(elements);

        const name = generateCustomerName(elements);

        setTargetDiv(elements.targetDiv);
        setCustomerName(name);

    }, [htmlString, getDOMElements, applyStyles, cleanupBRElements, generateCustomerName]);

    if (!htmlString) return null;

    return (
        <>
            <div
                ref={containerRef}
                dangerouslySetInnerHTML={{ __html: htmlString }}
            />
            {targetDiv && createPortal(
                <SignaturePad name={customerName} />,
                targetDiv
            )}
        </>
    );
};

export default RenderHtmlWithComponent;