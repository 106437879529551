import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";

import { allRowURL } from "./constantUrl";
import { getAllRowsDataRequest, getAllRowsDataSuccess, getAllRowsDataFail } from "sagas/pos/actions";

export const useAllRowsData = (stockWarehouseId) => {
  const dispatch = useDispatch();

  const { refetch: allRowRefetch } = useQuery(
    ["all-rows", stockWarehouseId],
    async () => {
      if (!stockWarehouseId) return; // Avoid fetching if stockWarehouseId is undefined

      dispatch(getAllRowsDataRequest());
      const response = await allRowURL(stockWarehouseId);
      return response?.data;
    },
    {
      enabled: Boolean(stockWarehouseId), // Only run query if stockWarehouseId is truthy
      refetchOnWindowFocus: false,
      onSuccess(data) {
        dispatch(getAllRowsDataSuccess(data));
      },
      onError(err) {
        dispatch(getAllRowsDataFail(err));
      },
    }
  );

  const getAllRows = useCallback(() => {
    allRowRefetch();
  }, [allRowRefetch]);

  return { getAllRows };
};
