import { AnyAction } from "redux";
import * as constants from "./constants";

const initialState = {
  isLoading: false,
  allUsersData: [],
  awaitingApprovalData: [],
  invitedUserData: [],
  approveUserSuccess: "",
  rejectUserSuccess: false,
  editUserSuccess: "",
  editUserPermissionSuccess: "",
  error: "",
  reviewUserId: null,
  editUser: false,
  isRemoveUser: false,
  isApproveStart: false,
  searchUser: "",
  searchUserResult: [],
  isPaginated: false,
  searchUserLoading: false,
  loggedInUser: {},
  searchUserId: ""
};

const reducer = function quotesReducer(
  state = initialState,
  action: AnyAction
) {
  switch (action.type) {
    case constants.GET_ALL_USERS_START:
      return { ...state, isLoading: true };
    case constants.GET_ALL_USERS_SUCCESS:
      return { ...state, allUsersData: action.payload, isLoading: false };
    case constants.GET_ALL_USERS_ERROR:
      return { ...state, error: action.payload, isLoading: false };

    case constants.EDIT_USERS_DETAILS_START:
      return {
        ...state,
        isLoading: true,
        editUserSuccess: constants.START,
        editUser: true
      };

    case constants.EDIT_USERS_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        editUserSuccess: constants.SUCCESS,
        editUser: false
      };
    case constants.EDIT_USERS_DETAILS_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        editUserSuccess: constants.ERROR,
        editUser: false
      };

    case constants.EDIT_USERS_DETAILS_CANCEL:
      return {
        ...state,
        isLoading: false,
        editUserSuccess: constants.CANCEL,
        editUser: false
      }
    case constants.EDIT_PERMISSIONS_START:
      return {
        ...state,
        isLoading: true,
        editUserPermissionSuccess: constants.START,
        editUser: true
      };

    case constants.EDIT_PERMISSIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        editUserPermissionSuccess: constants.SUCCESS,
        editUser: false
      };
    case constants.EDIT_PERMISSIONS_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        editUserPermissionSuccess: constants.ERROR,
        editUser: false
      };
    case constants.EDIT_PERMISSIONS_ERROR_API:
      return {
        ...state,
        isLoading: false,
        editUser: false
      };
    case constants.GET_AWAITING_APPROVAL_START:
      return { ...state, isLoading: true };
    case constants.GET_AWAITING_APPROVAL_SUCCESS:
      return {
        ...state,
        awaitingApprovalData: action.payload,
        isLoading: false,
      };
    case constants.GET_AWAITING_APPROVAL_ERROR:
      return {
        ...state,
        error: action.payload,
        awaitingApprovalData: [],
        isLoading: false,
      };

    case constants.GET_INVITED_START:
      return { ...state, isLoading: true };
    case constants.GET_INVITED_SUCCESS:
      return {
        ...state,
        invitedUserData: action.payload,
        isLoading: false,
      };
    case constants.GET_INVITED_ERROR:
      return {
        ...state,
        error: action.payload,
        invitedUserData: [],
        isLoading: false,
      };

    case constants.REJECT_USER_START:
      return { ...state, isLoading: true, rejectUserSuccess: constants.START };

    case constants.REJECT_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        rejectUserSuccess: constants.SUCCESS,
      };
    case constants.REJECT_USER_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        rejectUserSuccess: constants.ERROR,
      };

    case constants.REVIEW_USER_START:
      return {
        ...state,
        reviewUserId: action.payload,
      };

    case constants.REVIEW_USER_END:
      return {
        ...state,
        reviewUserId: null,
      };

    case constants.APPROVE_USER_START:
      return { ...state, isLoading: true, approveUserSuccess: constants.START, isApproveStart: true };
    case constants.APPROVE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        approveUserSuccess: constants.SUCCESS,
        isApproveStart: false
      };
    case constants.APPROVE_USER_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        approveUserSuccess: constants.ERROR,
        isApproveStart: false
      };

    case constants.REMOVE_USER_START:
      return { ...state, isLoading: true };
    case constants.REMOVE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case constants.REMOVE_USER_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case constants.RESET_USER_FLAG:
      return {
        ...state,
        approveUserSuccess: "",
        editUserSuccess: "",
        editUserPermissionSuccess: "",
        rejectUserSuccess: "",
        isLoading: false,
      };

    case constants.EDIT_USERS_FALSE:
      return {
        ...state,
        editUserSuccess: false,
      };

    case constants.USER_MESSAGE_REMOVE:
      return {
        ...state,
        userMessage: "",
        isLoading: false,
      };

    case constants.SEARCH_USER_ADD:
      return {
        ...state,
        searchUser: action.payload
      }
    case constants.GET_ALL_SEARCH_USERS_START:
      return { ...state, isLoading: true, searchUserLoading: true };
    case constants.GET_ALL_SEARCH_USERS_SUCCESS:
      return { ...state, searchUserResult: action.payload.data, isPaginated: action.payload.isPaginated, isLoading: false, searchUserLoading: false };
    case constants.GET_ALL_SEARCH_USERS_ERROR:
      return { ...state, error: action.payload, searchUserResult: [], isLoading: false, searchUserLoading: false };
    case constants.SET_LOGIN_USER:
      return { ...state, loggedInUser: action.payload }
    case constants.SET_SEARCH_USER_ID:
      return { ...state, searchUserId: action.payload }
    default:
      return state;
  }
};

export default reducer;
