import { PosButton, PosModel } from "apps/pos/Common/uiCore";
import IconButton from "apps/springboard/Component/SBItems/elements/IconButton";

const AllBackOrderModel = ({ open, handleCloseModel, handleConfirm }) => {

    return (
        <PosModel
            open={open}
            onClose={handleCloseModel}
            className={`set-customer-code-modal`}
            dialogClassName={`set-customer-code-modal-container`}
            dialogHeader={
                <div className="modal-header">
                    <IconButton
                        name="close"
                        className="close-icon"
                        type="tertiary"
                        onClick={handleCloseModel}
                    />
                </div>
            }
            dialogFooter={
                <div
                    className={`d-flex flex-wrap jcfe w-100 flex-row-reverse justify-end`}
                >
                    <div className="d-flex flex-wrap">
                        <PosButton
                            variant="contained"
                            color="secondary"
                            className={`btn-font-600 add-to-return-cart-button mt-2 back-order-confirmation-modal-btn btn`}
                            fontWeight
                            onClick={handleConfirm}
                        >
                            Continue with Backorders
                        </PosButton>
                    </div>
                    <div>
                        <PosButton
                            type="button"
                            variant="contained"
                            className={`add-to-return-cart-button mr-2 mt-2 btn-font-600 set-storage-back-btn btn`}
                            fontWeight
                            onClick={handleCloseModel}
                        >
                            Back
                        </PosButton>
                    </div>
                </div>
            }
        >
            <div className="pl-2 ml-4">
                <p className="fs-16 inter-reg black_text">
                    All items will be backordered. Continue?
                </p>
            </div>
        </PosModel>
    )

}

export default AllBackOrderModel;