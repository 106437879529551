import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ManageUserModal } from "components";

import texts from "apps/pos/locales";
import { AsideProps, FavoritesData, PosFlowDataProp } from "constants/interfaces";
import { Paths } from "enums";
import { UpdateModalContent } from "sagas/modal/actions";
import { setPosFlowData } from "sagas/persistReducer/actions";
import { authorizationToken } from "utils/utils";
import {
  Distributor,
  NavigationList,
  RootState,
} from "../models";
import BookMarkDialog from "./BookMarkDialog";
import CashDrawerDialog from "./CashDrawerDialog";

import {
  getFavoriteLinks,
  getNavigationMenu,
} from "services";

import MaterialIcon from "apps/pos/Common/uiCore/MaterialIcon";
import { supportLink } from "constants/staticData";
import "layouts/css/nav.scss";
import "react-pro-sidebar/dist/css/styles.css";
import { setBookMarkImage } from "utils/helper";

const Aside = ({ collapsed, showBookmarks, setShowBookMark, showMenu, setShowMenuMark, currentUserData }: AsideProps) => {
  const [favoritesList, setFavoritesList] = useState<FavoritesData[]>();
  const [navData, setNavData] = useState<NavigationList>();
  const [subMenuOptions, setSubMenuOptions] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [modelName, setModelName] = useState("");

  const [isCashDrawOpen, setCashDrawIsOpen] = useState<boolean>(false);
  const [cashModelName, setCashModelName] = useState("");
  const [cashMenuUrl, setCashMenuUrl] = useState<string>("");

  const distributor = useSelector<RootState, Distributor>(
    (state) => state.distributor
  );

  const { posFlowData } = useSelector<RootState, PosFlowDataProp>((state) => state.persistReducer);

  const dispatch = useDispatch();

  const { Authorization, printCashReport } = texts;
  const decodedData = authorizationToken(Authorization);
  const currentLocation = window.location;
  const openLink = (url) => {
    window.open(url);
  };

  useEffect(() => {
    getFavoriteLinks(
      decodedData?.DefaultLoginId
    ).then((favoritesData) => {
      setFavoritesList(favoritesData?.data ? favoritesData?.data : []);
    }).catch(() => {
      setFavoritesList([]);
    });
    //TODO: might need later
    // getTenantDetail().then((result) => {
    //   const plan = result?.subscription?.plan?.name || "";
    //   setTenantPlan(plan === "Springboard LITE" ? "LITE" : "FULL");
    // });
    getNavigationMenu().then((result) => {
      setNavData(result);
    });
  }, []);
  const pathName = window.location.pathname;
  const menuName = pathName.split("/")[2];

  const openMenuOptions = (value) => {
    if (subMenuOptions === value) {
      setSubMenuOptions("");
    } else {
      setSubMenuOptions(value);
    }
  };

  const showBookmarksDialog = () => {
    setShowBookMark(!showBookmarks)
  }

  const hideBookmarksDialog = () => {
    if (!collapsed) {
      setShowBookMark(false)
      setShowMenuMark([])
    }
  }

  const showMenuDialog = (newValue) => {
    if (showMenu.includes(newValue)) {
      // If the value already exists in the array, remove it
      setShowMenuMark(showMenu.filter((value) => value !== newValue));
    } else {
      // If the value doesn't exist in the array, add it
      setShowMenuMark([...showMenu, newValue]);
    }
  }

  const openAddBookmarksDialog = () => {
    setIsOpen(true)
    setModelName("add")
  }

  const openCashDrawerDialog = () => {
    setCashDrawIsOpen(true)
    setCashModelName("Generate Report")
  }


  const openManageBookmarksDialog = () => {
    setIsOpen(true)
    setModelName("manage")
  }

  const profileOpen = () => {
    dispatch(
      UpdateModalContent({
        isOpen: true,
        isAdmin: true,
        navigate: false,
        classModal: "user-profile-dialog",
        children: (
          <ManageUserModal
            isUser={true}
            user={currentUserData}
            loginUser={currentUserData}
            isApproval={false}
          />
        ),
        modalSize: "xl",
        fixedModal: true,
        removePaddingBottom: false,
        noMaxWidth: false
      })
    );
  }

  const clearCart = () => {
    const data = {
      ...posFlowData,
      cartProducts: [],
      addInStorage: [],
      poNumber: "",
      customer: {},
      savedCart: {},
      schedule: {},
      taker: {},
      pickOrderQuantity: [],
      pickQuantityArr: [],
      finalQtyArrayInfoData: [],
      shippingCharge: { shippingAmt: 0, shippingAmtTax: 0 },
    };
    dispatch(setPosFlowData(data));
    window.location.href = `${distributor.httpsUrl}/sso/logout`;
  };

  const navLinkClick = (menuItem) => () => {
    if (menuItem?.children?.length) {
      openMenuOptions(menuItem?.title);
      showMenuDialog(menuItem?.title)
    } else {
      if (menuItem?.url !== "#") {
        if (menuItem.title === printCashReport) {
          const menuLink = `${distributor.httpsUrl}${menuItem?.url}`;
          setCashMenuUrl(menuLink)
          openCashDrawerDialog();
        } else {
          window.open(`${distributor.httpsUrl}${menuItem?.url}`, menuItem.title === printCashReport ? '_blank' : "_self");
        }

      }
    }
  }
  //TODO: might need later
  // const navLinkChilderenClick = (menuItem) => () => {
  //   if (menuItem?.url !== "#" && menuItem.title !== printCashReport) {
  //     openLink(
  //       `${distributor.httpsUrl}${menuItem?.url}`
  //     );
  //   }
  // }

  const sideNavLinkChilderenClick = (option) => () => {
    if (
      option?.url !==
      `/${Paths.historyAdmin}`
    ) {
      openLink(
        `${distributor.httpsUrl}${option?.url !== null
          ? option?.url
          : "#"
        }`
      );
    } else {
      window.location.href =
        Paths.historyAdmin;
    }
  }

  return (
    <>
      <aside className={`sidenav ${!collapsed ? "sidenav-collapsed" : "sidenav-open"}`} role="navigation" onMouseLeave={hideBookmarksDialog}>
        <div className="sidenav-wrapper">
          {/* <!-- sidenav content --> */}
          <div className="sidenav-content">
            <div className="sidenav-group-wrapper">
              <a
                onClick={showBookmarksDialog}
                className={`${showBookmarks
                  ? `sidenav-item sidenav-group-switch bookmarks-switch sidenav-group-switch-expanded`
                  : `sidenav-item sidenav-group-switch bookmarks-switch`
                  }`}
              >
                <div className="sidenav-item-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                  >
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path
                      d="M17 3H7c-1.1 0-2 .9-2 2v16l7-3 7 3V5c0-1.1-.9-2-2-2zm0 15l-5-2.18L7 18V6c0-.55.45-1 1-1h8c.55 0 1 .45 1 1v12z"
                    />
                  </svg>
                </div>
                <span className="sidenav-item-label">Bookmarks</span>
              </a>

              <div className="sidenav-item-group">
                <nav className="sidenav-saved-bookmarks">
                  <div className="sidenav-bookmarks-actions">
                    {favoritesList?.length ? <button className="manage-bookmarks-btn" type="button" onClick={openManageBookmarksDialog}>
                      Manage
                    </button> : null}
                    <button className="add-bookmark-btn" type="button" onClick={openAddBookmarksDialog}>
                      Add Bookmark
                    </button>
                  </div>
                </nav>
                {favoritesList?.length ?
                  favoritesList?.map((favorite: FavoritesData, i) => (
                    <a
                      key={`favorite-${i}-${favorite?.bookmarkID}`}
                      className="sidenav-item sidenav-saved-bookmark sidenav-item-new-tab"
                      target={"_" + favorite?.target?.toLowerCase()}
                      href={favorite?.linkRoute}
                    >
                      <div className="sidenav-item-icon" key={`favorite-${i}`}>
                        <img src={setBookMarkImage(favorite?.linkRoute)} alt="Link" draggable="false" loading="eager" />
                      </div>
                      <label className={`sidenav-item-label ${favorite?.linkName?.length > 40 ? "sidenav-item-long-label" : ""}`}>{favorite.linkName}</label></a>
                  )) : null
                }
              </div>
            </div>

            {navData &&
              menuName &&
              navData[menuName]?.roots &&
              navData[menuName]?.roots?.length &&
              navData[menuName]?.roots.map((menuItem, index) => {
                const { type, title, children, iconName, url } = menuItem || {}
                const activeUrl = (url !== "#" && (url === currentLocation?.pathname)) ||
                  (children?.length && children.find(urls => urls?.url === currentLocation?.pathname))
                return (
                  <div key={`navdata-${index}`}>
                    {type === "Heading" && (
                      <span className="sidenav-item-head">
                        {title}
                      </span>
                    )}
                    <div className="sidenav-group-wrapper">
                      {type === "MenuItem" &&
                        title !== "Commissions" && (
                          <a
                            onClick={navLinkClick(menuItem)}
                            className={`${(showMenu && showMenu.includes(title))
                              ? `sidenav-item sidenav-group-switch sidenav-group-switch-expanded `
                              : children?.length
                                ? "sidenav-item sidenav-group-switch " : "sidenav-item"
                              }${activeUrl ?
                                ` sidenav-item-active`
                                : ""
                              }`}
                            draggable="false"
                          >
                            <div className="sidenav-item-icon">
                              <MaterialIcon icon={iconName} />
                            </div>
                            <span
                              //TODO: might need later
                              // onClick={navLinkChilderenClick(menuItem)}
                              className="sidenav-item-label"
                            >
                              {title}
                            </span>
                            {children &&
                              children?.length !== 0 && (
                                <svg
                                  className="sidenav-group-switch-icon"
                                  xmlns="http://www.w3.org/2000/svg"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  width="24"
                                >
                                  <path
                                    d="M24 24H0V0h24v24z"
                                    fill="none"
                                    opacity=".87"
                                  />
                                  <path d="M15.88 9.29L12 13.17 8.12 9.29c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41-.39-.38-1.03-.39-1.42 0z" />
                                </svg>
                              )}
                          </a>
                        )}
                      <div className="sidenav-item-group">
                        {((showMenu && showMenu.includes(title)) || collapsed) &&
                          children?.map((option, index) => {
                            const { type, title } = option || {}
                            return (
                              <div key={`submenu-${index}`}>
                                {type === "SubHeading" ? (
                                  <nav key={index}>
                                    <span className=" sidenav-item-head">
                                      {title}
                                    </span>
                                  </nav>
                                ) : (
                                  <a className="sidenav-item">
                                    <span
                                      onClick={sideNavLinkChilderenClick(option)}
                                      className="sidenav-item-label"
                                    >
                                      {title}
                                    </span>
                                  </a>
                                )}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                );
              })}

            <div className="sidenav-item-divider sidenav-mobile-only"></div>

            <a
              className="sidenav-item sidenav-direct-link sidenav-mobile-only"
              onClick={profileOpen}
              tab-index="0"
            >
              <div className="sidenav-item-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path
                    d="M12 5.9c1.16 0 2.1.94 2.1 2.1s-.94 2.1-2.1 2.1S9.9 9.16 9.9 8s.94-2.1 2.1-2.1m0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H5.9V17c0-.64 3.13-2.1 6.1-2.1M12 4C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v2c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-2c0-2.66-5.33-4-8-4z"
                  />
                </svg>
              </div>
              <span className="sidenav-item-label">Manage account</span>
            </a>

            <a
              className="sidenav-item sidenav-direct-link sidenav-mobile-only"
              href={supportLink}
            >
              <div className="sidenav-item-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path
                    d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"
                  />
                </svg>
              </div>
              <span className="sidenav-item-label">Support</span>
            </a>

            <div className="sidenav-item-divider sidenav-mobile-only"></div>

            <a
              className="sidenav-item sidenav-direct-link sidenav-mobile-only"
              tab-index="0" onClick={clearCart}
            >
              <div className="sidenav-item-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  enableBackground="new 0 0 24 24"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                >
                  <g>
                    <path d="M0,0h24v24H0V0z" fill="none" />
                  </g>
                  <g>
                    <g>
                      <path
                        d="M5,5h6c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h6c0.55,0,1-0.45,1-1v0 c0-0.55-0.45-1-1-1H5V5z"
                      />
                      <path
                        d="M20.65,11.65l-2.79-2.79C17.54,8.54,17,8.76,17,9.21V11h-7c-0.55,0-1,0.45-1,1v0c0,0.55,0.45,1,1,1h7v1.79 c0,0.45,0.54,0.67,0.85,0.35l2.79-2.79C20.84,12.16,20.84,11.84,20.65,11.65z"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <span className="sidenav-item-label">Logout</span>
            </a>

          </div>

          {/* <!-- sidenav footer --> */}
          <div className="master-sidenav-footer">
            <div
              className="sidenav-footer-logo"
              title="Powered by Jumper | Jumper.com"
            >
              <img
                src="https://d1930195eyqolw.cloudfront.net/Jumper/images/logo/refresh/powered_by_jumper.svg"
                alt="Powered by Jumper"
                className="sidenav-footer-logo-full"
                draggable="false"
              />
              <img
                src="https://d1930195eyqolw.cloudfront.net/Jumper/images/logo/refresh/jumper_symbol_black.svg"
                alt="Powered by Jumper"
                className="sidenav-footer-logo-symbol"
                draggable="false"
              />
            </div>
          </div>
        </div>
      </aside>

      <BookMarkDialog visible={isOpen} onClose={setIsOpen} modelName={modelName} favoritesList={favoritesList} setModelName={setModelName} personID={decodedData?.DefaultLoginId} setFavoritesList={setFavoritesList} />
      <CashDrawerDialog visible={isCashDrawOpen} onClose={setCashDrawIsOpen} modelName={cashModelName} cashMenuUrl={cashMenuUrl} />

    </>
  );
};

export default Aside;
