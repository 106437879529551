import { AnyAction } from "redux";
import { all, call, put, takeLatest } from "redux-saga/effects";

import { adminMessage } from "constants/message";
import * as services from "services";
import * as actions from "./actions";
import * as constants from "./constants";

export function* resetPasswordUserAsync(action: AnyAction): any {
  try {
    const resetPasswrodUserResponse = yield call(
      services.resetPassword,
      action.payload
    );
    if (resetPasswrodUserResponse) {
      yield put(actions.resetPasswordRequsetSuccess(resetPasswrodUserResponse));
    }
  } catch (errors) {
    yield put(actions.resetPasswordRequsetError(errors));
  }
}

export function* newPasswordUserAsync(action: AnyAction): any {
  try {
    const newPasswrodUserResponse = yield call(
      services.newPassword,
      action.payload
    );
    if (newPasswrodUserResponse) {
      yield put(actions.newPasswordRequsetSuccess(newPasswrodUserResponse));
    }
  } catch (errors) {
    yield put(actions.newPasswordRequsetError(errors));
  }
}

export function* newPasswordTokenUserAsync(action: AnyAction): any {
  try {
    const newPasswrodTokenUserResponse = yield call(
      services.newPasswordToken,
      action.payload
    );
    if (newPasswrodTokenUserResponse) {
      yield put(
        actions.newPasswordRequsetTokenSuccess(newPasswrodTokenUserResponse)
      );
    }
  } catch (errors) {
    yield put(actions.newPasswordRequsetTokenError(errors));
  }
}

export function* inviteUserAsync(action: AnyAction): any {
  try {
    const inviteUserResponse = yield call(services.inviteUser, action.payload);
    if (inviteUserResponse) {
      yield put(actions.inviteSuccess(inviteUserResponse));
    } else {
      yield put(actions.inviteErrorAPI());
    }
  } catch (errors) {
    yield put(actions.inviteError(errors?.title ? errors?.title : adminMessage.inviteSent));
  }
}

//watcher Saga

function* resetPasswordUserWatcher() {
  yield takeLatest(
    constants.RESET_PASSWORD_REQUEST_START,
    resetPasswordUserAsync
  );
}

function* newPasswordUserWatcher() {
  yield takeLatest(constants.NEW_PASSWORD_START, newPasswordUserAsync);
}

function* newPasswordTokenUserWatcher() {
  yield takeLatest(
    constants.NEW_PASSWORD_TOKEN_START,
    newPasswordTokenUserAsync
  );
}

function* inviteUserUserWatcher() {
  yield takeLatest(
    constants.INVITE_START,
    inviteUserAsync
  );
}

// export default authenticatedUserWatcher;

export default function* AuthenticatedUserSaga() {
  yield all([
    resetPasswordUserWatcher(),
    newPasswordUserWatcher(),
    newPasswordTokenUserWatcher(),
    inviteUserUserWatcher()
  ]);
}
