import "@fontsource/inter";
import { styled, Tooltip } from "@material-ui/core";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AdjustStock from "apps/pos/UnitsAvailable/AdjustStock/AdjustStock";
import AvailableAllStock from "apps/pos/UnitsAvailable/AvailableAllSctock/AvailableAllStock";
import { Button } from "components";
import CommonModel from "components/Dialog/Dialog";
import { PosButton } from "../Common/uiCore";
import { useGetDraftsByProductId } from "../hooks/useGetDraftsByProductId";
import AllocatedStockInAllDrafts from "./AllocatedStockInAllDrafts/AllocatedStockInAllDrafts";

import texts from "apps/pos/locales";
import { EditIcon } from "constants/icons";
import { PosFlowDataProp } from "constants/interfaces";
import { PosResponseProps, RootState } from "models";
import { getLocalStoraGeItem, saleable } from "utils/utils";

import {
  disableAdjustStockTabs,
  enterDisableAddToCart,
  fromLocationRowClear,
  fromLocationShelfClear,
  fromLocationUnitClear,
  getRowsActiveClear,
  getShelfClear,
  getUnitsActiveClear,
  getWarehouseLocationsClear,
  setAdjustStockByProduct,
  setIsLoading,
  setMoveTabDisable,
  stockByWarehouseClear,
  stockByWarehouseStart,
} from "sagas/pos/actions";

import { getLocationData } from "utils/getLocationData";
import "./unitsavailable.scss";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "#ffffff",
    color: "#000000",
    boxShadow: "0rem 0.625rem 2.5rem #0000001A",
    borderRadius: "0.75rem",
    fontSize: "1rem",
    padding: "15px 0.9375rem",
    maxWidth: "31.0625rem",
    letterSpacing: "0.01rem",
    fontWeight: "400",
  },
  [`& .MuiTooltip-arrow`]: {
    color: "#ffffff",
  },
}));

const UnitsAvailable = ({
  handleFocusInput,
  discontinueProduct,
  productIdInfo,
  clearanceProduct,
  allocatedDraftRowRef,
  adjustStockFlag,
  isProductInactive
}) => {

  const { stockWarehouseId } = getLocalStoraGeItem("workspace") || {};

  const { stockWarehouseData, isStockUpdateProduct, allRowsData, productsData, isStockInLoading } = useSelector<
    RootState,
    PosResponseProps
  >((state) => state.pos);
  const { posFlowData } = useSelector<RootState, PosFlowDataProp>(
    (state) => state.persistReducer
  );

  const { cartProductDetails } = posFlowData || {};
  const { productId } = cartProductDetails || {};
  const currentWorkspace = getLocalStoraGeItem("workspace");
  const [modalVisible, setModalVisible] = useState(false);
  const [allStockModal, setAllStockModal] = useState(false);
  const [allDraftOrdersModal, setAllDraftMOdalsModal] = useState(false);

  const currentProductId = productIdInfo ?? productId;
  let draftsByProductIdData = [];
  if (currentProductId) {
    const { draftsByProductIdData: dataOfDrafts } = useGetDraftsByProductId({
      stockWarehouseId,
      productId: currentProductId
    });
    draftsByProductIdData = dataOfDrafts;
  }

  const totalQty = draftsByProductIdData?.reduce((total, obj) => Number(obj?.qtyOrdered) + Number(total), 0)

  const [units, setUnits] = useState([]);
  const [unitsErrors, setUnitsErrors] = useState([]);
  const [unitsLoading, setUnitsLoading] = useState(false);
  const [shelves, setShelves] = useState([]);
  const [shelvesErrors, setShelvesErrors] = useState([]);
  const [shelvesLoading, setShelvesLoading] = useState(false);

  const dispatch = useDispatch();
  const ProductCountOnLocation = useMemo(() => {
    return stockWarehouseData?.length
      ? stockWarehouseData
        ?.find((item) => item.productId === Number(currentProductId))
        ?.stockWarehouses?.map((item) => {
          return item;
        })
      : [];
  }, [stockWarehouseData]);

  const productCountOnLocationTotal = () => {
    return (
      ProductCountOnLocation?.length &&
      ProductCountOnLocation?.reduce(
        (total: number, currentValue: { unitQtyAvailable: number }) =>
          (total = total + currentValue.unitQtyAvailable),
        0
      )
    );
  };

  const findQtyByWarehouse = ProductCountOnLocation?.find(
    (item) => item.stockWarehouseName === currentWorkspace.warehouseName
  );
  const availableQty = productCountOnLocationTotal() || 0;
  const {
    seeAllLocations,
    seeAllDraftOrders,
    outOfStock,
    totalIn,
    warehouse,
    adjustStock,
    discontinued,
    available,
    inactiveProduct
  } = texts;
  const adjustStockCloseModel = () => {
    if (!isStockInLoading) {
      handleFocusInput();
      dispatch(enterDisableAddToCart(false));
      if (isStockUpdateProduct) {
        dispatch(setAdjustStockByProduct());
        dispatch(stockByWarehouseClear());
        dispatch(stockByWarehouseStart([productId]));
      }
      dispatch(fromLocationUnitClear());
      dispatch(fromLocationShelfClear());
      dispatch(getUnitsActiveClear());
      dispatch(getShelfClear());
      dispatch(getRowsActiveClear());
      dispatch(fromLocationRowClear());
      setModalVisible(false);
      dispatch(enterDisableAddToCart(false));
      dispatch(getWarehouseLocationsClear());
    }
  };

  const saleableRows = useMemo(() => Array.isArray(allRowsData) ? allRowsData.filter(saleable) : [], [allRowsData])

  const handlePrePopulateLocationData = () => {
    const row = saleableRows?.find((item) => Boolean(item))
    setUnitsLoading(true)
    setShelvesLoading(true)

    getLocationData({
      setUnitsLoading,
      setUnits,
      setShelvesLoading,
      setShelves,
      setShelvesErrors,
      setUnitsErrors,
      row,
      productsData,
      getLocationProductWise: false
    })
  }

  const handleAdjustStockOpen = () => {
    setModalVisible(true)

  }

  useEffect(() => {
    if (saleableRows?.length === 1 && units?.length === 1 && shelves?.length === 1) {
      dispatch(setIsLoading(false))
      setModalVisible(true)
    }
  }, [saleableRows, units, shelves])

  return (
    <>
      <div className="stock-accordion">
        <div className="units-available-container">
          <div className="units-available-section">
            {/* TODO:need this code */}
            {/* {discontinueProduct ? (
              <div className="discontinue-product">
                <span>Discontinued</span>
              </div>
            ) : ( */}
            <span className="units-available-title d-flex">
              {findQtyByWarehouse?.unitQtyAvailable === undefined ? <div className='unitQtyAvailable-list skeleton-item' key={'list-skeleton-'} /> :
                findQtyByWarehouse?.unitQtyAvailable === 0 ||
                  !findQtyByWarehouse?.unitQtyAvailable
                  ? outOfStock
                  : findQtyByWarehouse?.unitQtyAvailable > 1
                    ? `${findQtyByWarehouse?.unitQtyAvailable} ${available}`
                    : `${findQtyByWarehouse?.unitQtyAvailable} ${available}`}
              <span className="d-flex justify-content-center align-items-center">
                {isProductInactive ?
                  <div className="inactive-product">
                    <span>{inactiveProduct}</span>
                  </div>
                  : <>{discontinueProduct ? (
                    <div className="discontinue-product">
                      <span>{discontinued}</span>
                    </div>
                  ) : (
                    <>
                      {
                        findQtyByWarehouse?.unitQtyAvailable === undefined ? null :
                          <div
                            className={`${findQtyByWarehouse?.unitQtyAvailable
                              ? "units-available-badge-dot"
                              : "units-available-badge-dot-orange"
                              }`}
                          ></div>
                      }
                    </>
                  )}
                    {clearanceProduct ? (
                      <div className="clearance-product">
                        <span>CLEARANCE</span>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>}
              </span>
            </span>

            <span>
              <span className="units-available-in-warehouse">
                in {currentWorkspace?.warehouseName}
              </span>
            </span>
          </div>
          {
            (discontinueProduct && findQtyByWarehouse?.unitQtyAvailable === 0) || !adjustStockFlag ?
              <CustomTooltip title="Please contact your manager to adjust stock" placement="top" arrow>
                <div>
                  <PosButton
                    variant="contained"
                    className="btn adjust-button adjust-stock-button move-stock-btn"
                    startIcon={<EditIcon />}
                    disabled
                  >
                    {adjustStock}
                  </PosButton>
                </div>
              </CustomTooltip>
              :
              <PosButton
                variant="contained"
                className="btn adjust-button adjust-stock-button move-stock-btn"
                onClick={() => {
                  handleAdjustStockOpen()
                  dispatch(disableAdjustStockTabs(true))
                  dispatch(enterDisableAddToCart(true))
                  dispatch(setMoveTabDisable(false))
                }}
                startIcon={<EditIcon />}
              >
                {adjustStock}
              </PosButton>
          }
        </div>
        {findQtyByWarehouse?.unitQtyAvailable === undefined ? <div className='unitQtyAvailable-list skeleton-item' key={'list-skeleton-'} /> :
          <div className="units-available-location">
            {(availableQty || 0) +
              " " +
              totalIn +
              " " +
              ProductCountOnLocation?.length +
              " " +
              warehouse}
            {ProductCountOnLocation?.length > 1 && "s"}
            <div className="gray-dot"></div>
            <Button
              className={`border-0 bg-transparent p-0 ${availableQty
                ? "units-available-see-all"
                : "units-available-see-all-grey"
                }`
              }
              onClick={() => {
                setAllStockModal(availableQty);
                dispatch(enterDisableAddToCart(true));
              }}
              tabIndex={0}
            >
              {seeAllLocations}
            </Button>
          </div>}
        {draftsByProductIdData?.length > 0 ? <div className="units-available-location">
          {`${totalQty ?? 0} allocated in ${draftsByProductIdData?.length ?? 0} Draft Orders`}
          <div className="gray-dot"></div>
          <Button
            className={`border-0 bg-transparent p-0 ${totalQty
              ? "units-available-see-all"
              : "units-available-see-all-grey"
              }`
            }
            onClick={() => {
              setAllDraftMOdalsModal(totalQty);
              dispatch(enterDisableAddToCart(true));
            }}
            tabIndex={0}
          >
            {seeAllDraftOrders}
          </Button>
        </div> : null}
      </div>
      <CommonModel
        openModal={modalVisible}
        closeModal={adjustStockCloseModel}
        dialogClassName="adjust-stock-dialog-box"
        closeIconClassName="adjust-stock-close button-focus"
      >
        <AdjustStock
          handleFocusInput={handleFocusInput}
          handlePrePopulateLocationData={handlePrePopulateLocationData}
          setModalVisible={setModalVisible}
          units={units}
          setUnits={setUnits}
          unitsErrors={unitsErrors}
          setUnitsErrors={setUnitsErrors}
          unitsLoading={unitsLoading}
          setUnitsLoading={setUnitsLoading}
          shelves={shelves}
          setShelves={setShelves}
          shelvesErrors={shelvesErrors}
          setShelvesErrors={setShelvesErrors}
          shelvesLoading={shelvesLoading}
          setShelvesLoading={setShelvesLoading}
        />
      </CommonModel>

      <CommonModel
        openModal={allStockModal}
        closeModal={() => {
          setAllStockModal(false);
          dispatch(enterDisableAddToCart(false));
        }}
        dialogClassName="available-stock-dialog-box"
        closeIconClassName="button-focus stock-close-btn"
      >
        <AvailableAllStock
          ProductCountOnLocation={ProductCountOnLocation}
          setModalVisible={setModalVisible}
          setAllStockModal={setAllStockModal}
          warehouseDetails={stockWarehouseData}
          productIdInfo={productIdInfo}
          handlePrePopulateLocationData={handlePrePopulateLocationData}
          discontinueProduct={discontinueProduct}
          findQtyByWarehouse={findQtyByWarehouse}
        />
      </CommonModel>

      <CommonModel
        openModal={allDraftOrdersModal}
        closeModal={() => {
          setAllDraftMOdalsModal(false);
          dispatch(enterDisableAddToCart(false));
        }}
        dialogClassName="available-stock-dialog-box"
        closeIconClassName="button-focus stock-close-btn"

      >
        <AllocatedStockInAllDrafts
          draftsByProductIdData={draftsByProductIdData}
          cartProductDetails={cartProductDetails}
          allocatedDraftRowRef={allocatedDraftRowRef}
        />
      </CommonModel>
    </>
  );
};

export default UnitsAvailable;
