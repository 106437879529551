import { Tooltip } from "@material-ui/core";
import { TableColumn } from "components/TableColumn/TableColumn";

import { PaginationFormat } from "constants/enum";
import { getComparator } from "utils/helper";
import { isoToFormat, returnFixedOrEmptyValue, showDot } from "utils/utils";

export const EnhancedTableBody = ({
  purchaseHistory,
  stableSort
}) => {
  const { Asc, Calories } = PaginationFormat;
  return purchaseHistory?.length ? (
    stableSort(purchaseHistory, getComparator(Asc, Calories))?.map(
      (row, index) => {
        const { purchaseDate, poNum, vendorName, vendorId, qtyInItemMU, extCost, vendorPrice, muName, priceMU, itemMuName, itemMU, jobName } = row
        const poNumber = showDot(poNum, 10);
        const job = showDot(jobName, 10);
        return (
          <div className="table-body-row-purchase" key={index}>
            <TableColumn className="inv-date-col" value={isoToFormat(purchaseDate, "MM/DD/YYYY")} />
            {poNumber ? <Tooltip title={poNum} placement="top">
              <div className={`table-body-col`}>
                {poNumber || ""}
              </div>
            </Tooltip> : <div className={`table-body-col`}>
              {""}
            </div>}
            {jobName ? <Tooltip title={jobName} placement="top">
              <div className={`table-body-col`}>
                {job || ""}
              </div>
            </Tooltip> : <div className={`table-body-col`}>
              {""}
            </div>}
            <TableColumn className="customer-col-width">
              <div className="flex-1">{vendorName} </div>
              <div className="flex-1 customer-code">{vendorId}</div>
            </TableColumn>
            <TableColumn value={muName && priceMU ? `${muName} (${priceMU})` : ""} />
            <TableColumn value={returnFixedOrEmptyValue(vendorPrice)} />
            <TableColumn>
              <div className="flex-1">{returnFixedOrEmptyValue(qtyInItemMU)} </div>
              <div className="flex-1 customer-code">{(itemMuName && itemMU) ? `${itemMuName} (${itemMU})` : ""}</div>
            </TableColumn>
            <TableColumn value={returnFixedOrEmptyValue(extCost)} />
          </div>
        );
      }
    )
  ) : (
    <div className="sales-history-no-data-found">
      No Data Found
    </div>
  )
}
