import "@fontsource/inter";
import { Tab } from "@mui/material";
import { SyntheticEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import {
  Divider,
  PosButton,
  PosIconButton,
  PosImage,
  PosPageHeading,
  PosTab,
  PosTabPanel
} from "apps/pos/Common/uiCore";
import CustomerStorageDeliveredItems from "apps/pos/pages/customerInfo/CustomerStorageDeliveredItems/CustomerStorageDeliveredItems";
import CustomerStoragePendingDetails from "apps/pos/pages/customerInfo/CustomerStoragePendingDetails/CustomerStoragePendingDetails";
import CustomerStoragePendingItems from "apps/pos/pages/customerInfo/CustomerStoragePendingItems/CustomerStoragePendingItems";
import AddDocumentModal from "apps/pos/pages/customerInfo/Documents/addDocument/AddDocumentModal";
import DeliveryDetailsModals from "apps/pos/storage/DeliveryDetails";
import EditStorageDetailModal from "apps/pos/storage/EditStorageDetailModal";
import UndoDeliveryModal from "apps/pos/storage/UndoDeliveryModal";
import { RootState } from "models";
import { setIsApiLoading } from "../../CustomerOrders/redux/customerOrder.slice";
import { useGetDeliveredOrderItems } from "../../CustomerStorage/hooks/useGetDeliveredOrderItems";
import { useGetDeliverySlip } from "../../CustomerStorage/hooks/useGetDeliverySlip";
import { useGetPendingStorageItems } from "../../CustomerStorage/hooks/useGetPendingStorageItems";
import { useGetStorageShipments } from "../../CustomerStorage/hooks/useGetStorageShipments";
import { useUndoDelivery } from "../../CustomerStorage/hooks/useUndoDelivery";
import { getDeliveredStorageItemDetail, getDeliverySuccessData, getIsLocationUpdated, getStorageItemDetail } from "../../CustomerStorage/redux/selector";
import { setDeliverySuccessData, setUpdateLocationSuccess } from "../../CustomerStorage/redux/storage.slice";

import { CustomerTabs, documentByModule } from "constants/enum";
import { ArrowBackRoundedIcon } from "constants/icons";
import images from "constants/images";
import { PosFlowDataProp } from "constants/interfaces";
import { Paths } from "enums";
import { keys, openAndPrintPdf } from "utils/helper";
import { getLocalStoraGeItem } from "utils/utils";

import {
  setTabIndexNumber,
} from "sagas/persistReducer/actions";
import {
  setHeaderHeightCount,
} from "sagas/pos/actions";

import { snakbarShow } from "sagas/pos/snakbar/snakbar.slice";
import "./storageDataSubTab.scss";

interface StorageDataSubTabProps {
  setCustomerInfo?: (boolean) => void;
  headerHeightCount?: number;
  storageItemInfo?: boolean;
}

const StorageDataSubTab = ({
  setCustomerInfo,
  headerHeightCount,
  storageItemInfo
}: StorageDataSubTabProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { isNotFromSR } = state || {};
  const [searchParams] = useSearchParams();
  const { getDeliverySlip, getDeliverySlipData, getDeliverySlipIsLoading, isSuccess } = useGetDeliverySlip();
  const isReturn = searchParams.get("isReturn")
  const currentWorkspace = getLocalStoraGeItem("workspace");

  const { stockWarehouseId } = currentWorkspace;

  const storageItemDetail = useSelector(getStorageItemDetail)
  const isLocationUpdated = useSelector(getIsLocationUpdated)
  const { posFlowData } = useSelector<RootState, PosFlowDataProp>(
    (state) => state.persistReducer
  );
  const { customer } = posFlowData || {};

  const { shipmentID, orderID, shipmentInStorageID } = storageItemDetail || {}
  const { storageShipments: shipmentOrdersData, getStorageShipmentData } =
    useGetStorageShipments();

  useEffect(() => {
    if (stockWarehouseId && customer?.customerId && (isLocationUpdated || !state?.isNotFromSR)) {
      getStorageShipmentData(stockWarehouseId, customer?.customerId)
    }

  }, [stockWarehouseId, customer?.customerId, isLocationUpdated])

  let filteredItems;
  shipmentOrdersData?.length > 0 && shipmentOrdersData.filter((item) => {
    const { shipmentInStorageID: itemsShipmentInStorageID } = item || {};
    if (itemsShipmentInStorageID === shipmentInStorageID) {
      filteredItems = { ...item }
    }
  })
  const pendingData = filteredItems ? filteredItems : storageItemDetail;


  const deliveredStorageItemDetail = useSelector(getDeliveredStorageItemDetail)

  const { pendingShipmentStorageItemsData, getStorageShipmentsIsLoading, getGetPendingStorageItems } = useGetPendingStorageItems()
  const { undoDeliveryFn, undoDeliveryIsSuccess, undoDeliveryIsloading } = useUndoDelivery();
  const { getDeliveredOrderItems, deliveredItemData, getDeliveredOrderItemsIsLoading } = useGetDeliveredOrderItems();

  const isApiLoading = [getStorageShipmentsIsLoading, undoDeliveryIsloading, getDeliveredOrderItemsIsLoading, getDeliverySlipIsLoading].includes(true)

  const singleDeliveredData = deliveredItemData?.find((item) => Boolean(item))
  const { storage } = CustomerTabs

  const undoDeliveryData = {
    deliveryID: deliveredStorageItemDetail?.deliveryId,
    handlerID: deliveredStorageItemDetail?.handlerId,
  }

  const { loadCartDetails } = useSelector<
    RootState,
    PosFlowDataProp
  >((state) => state.persistReducer);

  useEffect(() => {
    if (!storageItemInfo && shipmentID) {
      getGetPendingStorageItems(shipmentID, shipmentInStorageID)
    }
  }, [storageItemInfo && shipmentID])

  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    if (!isNotFromSR) {
      setTabValue(1)
    }
  }, [isNotFromSR])

  const [deliveryDetails, setDeliveryDetails] = useState(false);
  const [openDeliveryProofModel, setOpenDeliveryProofModel] = useState(false);
  const [editDetailsModal, setEditDetailsModal] = useState(false);
  const [undoDeliveryModal, setUndoDeliveryModal] = useState(false);

  const deliverySuccessData = useSelector(getDeliverySuccessData);
  const { deliveryID: deliverySuccessDeliveryID } = deliverySuccessData || {}

  useEffect(() => {
    dispatch(setIsApiLoading(isApiLoading));
  }, [isApiLoading])

  useEffect(() => {
    if (undoDeliveryIsSuccess) {
      dispatch(snakbarShow({ message: "Moved all items back to storage" }))
      setUndoDeliveryModal(false)
      setTimeout(() => {
        dispatch(setDeliverySuccessData({}))
        navigate(Paths.customer);
      }, 2500);
    }
  }, [undoDeliveryIsSuccess])

  useEffect(() => {
    if (deliveredStorageItemDetail?.shipmentId >= 0 && deliveredStorageItemDetail?.deliveryId) {
      getDeliveredOrderItems(deliveredStorageItemDetail?.shipmentId, deliveredStorageItemDetail?.deliveryId)
    }
  }, [deliveredStorageItemDetail?.shipmentId, deliveredStorageItemDetail?.deliveryId])

  useEffect(() => {
    if (storageItemInfo && shipmentID && deliverySuccessDeliveryID) {
      getDeliveredOrderItems(shipmentID, deliverySuccessDeliveryID)
    }
  }, [storageItemInfo && shipmentID, deliverySuccessData?.deliverID])

  useEffect(() => {
    const { data } = deliverySuccessData || {}
    if (storageItemInfo && shipmentID && data) {
      getDeliveredOrderItems(shipmentID, data)
    }
  }, [storageItemInfo && shipmentID, deliverySuccessData?.data])

  const { deliveryID } = singleDeliveredData || {}

  const getCustomerInfoTab = () => {
    switch (tabValue) {
      case 0:
        return (
          <PosTabPanel value={tabValue} index={tabValue}>
            <CustomerStoragePendingDetails storageItemInfo={storageItemInfo} setOpenDeliveryProofModel={setOpenDeliveryProofModel} pendingData={pendingData} deliveredData={singleDeliveredData} />
          </PosTabPanel>
        );

      case 1:
        return (
          <PosTabPanel value={tabValue} index={tabValue}>
            {storageItemInfo ? <CustomerStorageDeliveredItems setOpenDeliveryProofModel={setOpenDeliveryProofModel} deliveredData={deliveredItemData} /> : <CustomerStoragePendingItems data={pendingShipmentStorageItemsData} />}
          </PosTabPanel>
        );

      default:
        return null;
    }
  };

  const handleChange = (_: SyntheticEvent, newValue: number) => {
    // Might need 
    // dispatch(setTabIndexNumber(newValue));
    setTabValue(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  useEffect(() => {
    dispatch(setHeaderHeightCount(headerHeightCount));
  }, [headerHeightCount]);

  const backToCartPage = () => {
    setCustomerInfo && setCustomerInfo(false);
    dispatch(setTabIndexNumber(0));
  }

  const handleKeyPressDown = (e) => {
    const { keyCode } = e;
    const { esc } = keys;
    const { cartId } = loadCartDetails || {}
    if (keyCode === esc && cartId) {
      cartId && backToCartPage();
    }
    else if (keyCode === esc && !cartId) {
      dispatch(setTabIndexNumber(storage));
      navigate(Paths.customer)
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPressDown);

    return function cleanup() {
      document.removeEventListener("keydown", handleKeyPressDown);
    };
  }, [loadCartDetails]);

  const deliveryProofPayload = {
    orderID,
    shipmentID,
  }

  const printPackingSlip = () => {
    if (deliverySuccessData?.deliverySlipPdfBase64) {
      openAndPrintPdf(deliverySuccessData?.deliverySlipPdfBase64)
    } else {
      const firstItem = deliveredItemData?.find((i) => Boolean(i?.orderID))
      const { orderID, deliveryID } = firstItem || {}
      if (orderID && deliveryID) {
        getDeliverySlip(orderID, deliveryID)
      }
    }
  };

  useEffect(() => {
    if (isSuccess && getDeliverySlipData?.deliverySlipPdfBase64) {
      openAndPrintPdf(getDeliverySlipData?.deliverySlipPdfBase64)
    }
  }, [getDeliverySlipData])

  const handleDeliverButton = () => {
    navigate(Paths.deliverItemsInStorage, { state: { pendingItemsData: pendingShipmentStorageItemsData, orderId: storageItemDetail.orderID, shipmentItemValue: storageItemDetail.shipmentItemValue, shipmentId: storageItemDetail.shipmentID } })
  }

  const { rightGreenFilled, blackPrintFilled, storageBlack, shipping } = images

  const handleBackButton = () => {
    dispatch(setTabIndexNumber(storage));
    navigate(Paths.customer)
  }

  return (
    <>
      <div className="customer-info-section customer-info-container">
        {!isReturn && <PosIconButton
          className="back-next-btn-small-left customer-info-back-btn pos-icon-back-btn"
          tabIndex={-1}
          onClick={handleBackButton}
        >
          <div className="btn-back-customer">
            <ArrowBackRoundedIcon />
          </div>
        </PosIconButton>
        }
        <div className="pricing-modal-header mt-0 mb-0">
          <div className="customer-info-heading">
            <div>
              <div className="mb-4">
                <PosPageHeading heading={storageItemInfo ? `Delivery #${(deliverySuccessDeliveryID ?? deliveryID) ?? ''}` : 'Items in Storage'} grayHeading className="mb-2" />
                {storageItemInfo ?
                  <PosPageHeading heading='Shipped' blackHeading startingIcon={rightGreenFilled}
                    iconStyling="mr-2"
                    className="icon-align-center"
                  />
                  :
                  <PosPageHeading heading={`#${storageItemDetail.shipmentInStorageID ? storageItemDetail.shipmentInStorageID : shipmentID}`} blackHeading />
                }
              </div>
            </div>
            <div className="d-flex flex-wrap">
              <div className="btn-change-customer">
                {storageItemInfo ? <PosButton
                  variant="contained"
                  className="mb-3 undo-delivery-btn btn"
                  startIcon={storageItemInfo && <PosImage src={storageBlack} alt="print-icon" />}
                  onClick={() => setUndoDeliveryModal(true)}
                >
                  {'Undo Delivery'}
                </PosButton> : <PosButton
                  variant="contained"
                  className="mb-3 edit-storage-btn btn"
                  // startIcon={storageItemInfo && <PosImage src={storageBlack} alt="print-icon" />}
                  onClick={() => {
                    dispatch(setUpdateLocationSuccess(false))
                    setEditDetailsModal(true)
                  }}
                >
                  {'Edit Storage Details'}
                </PosButton>}

              </div>
              <div>
                {storageItemInfo ?
                  <PosButton
                    variant="contained"
                    className="mb-3 print-slip-btn btn"
                    startIcon={<PosImage src={blackPrintFilled} alt="print-icon" />}
                    onClick={printPackingSlip}
                  >
                    Print Packing Slip
                  </PosButton>
                  :
                  <PosButton
                    className="mb-3 deliver-btn btn"
                    color="primary"
                    variant="contained"
                    startIcon={<PosImage src={shipping} alt="print-icon" className="black-to-white-color-change" />}
                    onClick={handleDeliverButton}
                  >
                    Deliver
                  </PosButton>}
              </div>
            </div>
          </div>
        </div>
        <div></div>
        <PosTab value={tabValue} onChange={handleChange} className="customer-tab-header">
          <Tab label="Details" {...a11yProps(0)} className="customer-tab-header-sec" />
          <Tab label="Items" {...a11yProps(1)} className="customer-tab-header-sec" />
        </PosTab>
        <Divider className="update-product-container-line" />
        {getCustomerInfoTab()}
      </div>

      <DeliveryDetailsModals
        deliveryDetails={deliveryDetails}
        setDeliveryDetails={setDeliveryDetails}
      />
      <AddDocumentModal
        isDeliveryProofModal
        openModel={openDeliveryProofModel}
        setOpenModel={setOpenDeliveryProofModel}
        taxExemption={false}
        documentBy={documentByModule.deliveryProf}
        deliveryProofPayload={deliveryProofPayload}
      />
      <EditStorageDetailModal
        storageModal={editDetailsModal}
        setStorageModal={setEditDetailsModal}
        rowData={storageItemDetail}
      />
      <UndoDeliveryModal
        deliveryId={deliverySuccessDeliveryID || deliveryID}
        setUndoDeliveryModal={setUndoDeliveryModal}
        undoDeliveryModal={undoDeliveryModal}
        undoDeliveryFn={undoDeliveryFn}
        isApiLoading={isApiLoading}
        deliveredItemUndoDeliveryPayload={undoDeliveryData}
      />
    </>
  );
};

export default StorageDataSubTab;
