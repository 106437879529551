import { SignaturePad } from "./components/SignaturePad";
import "./index.scss"

function SignaturePadComp({ name }) {
    return (
        <main className="signature-container">
            <SignaturePad autoOpen={true} name={name} />
        </main>
    );
}

export default SignaturePadComp;