
import { Tooltip } from "@material-ui/core";
import { TableColumn } from "components/TableColumn/TableColumn";

import { PaginationFormat } from "constants/enum";
import { getComparator } from "utils/helper";
import { isoToFormat, returnFixedOrEmptyValue, showDot } from "utils/utils";

export const EnhancedTableBody = ({
  invoiceHistory,
  stableSort
}) => {
  const { Asc, Calories } = PaginationFormat;

  return invoiceHistory?.length ? (
    stableSort(invoiceHistory, getComparator(Asc, Calories))?.map(
      (row, index) => {
        const { invoiceDate, orderId, invoicedQtyOfUnits, lineId, muName, priceMU, netPrice, extPrice, profPct, poNum, jobName } = row
        const poNumber = showDot(poNum, 10);
        const job = showDot(jobName, 10);
        return (
          <div className="table-body-row-invoice" key={index}>
            <TableColumn className="inv-date-col" value={isoToFormat(invoiceDate, "MM/DD/YYYY")} />
            {poNumber ? <Tooltip title={poNum} placement="top">
              <div className={`table-body-col`}>
                {poNumber || ""}
              </div>
            </Tooltip> : <div className={`table-body-col`}>
              {""}
            </div>}
            {jobName ? <Tooltip title={jobName} placement="top">
              <div className={`table-body-col`}>
                {job || ""}
              </div>
            </Tooltip> : <div className={`table-body-col`}>
              {""}
            </div>}
            <TableColumn value={orderId || ""} />
            <TableColumn value={lineId || ""} />
            <TableColumn value={returnFixedOrEmptyValue(invoicedQtyOfUnits)} />
            <TableColumn value={muName && priceMU ? `${muName} (${priceMU})` : ""} />
            <TableColumn value={returnFixedOrEmptyValue(netPrice, true)} />
            <TableColumn value={returnFixedOrEmptyValue(extPrice, true)} />
            <TableColumn value={returnFixedOrEmptyValue(profPct)} />
          </div>
        );
      }
    )
  ) : (
    <div className="sales-history-no-data-found">
      No Data Found
    </div>
  )
}