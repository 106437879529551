import { CustomerDocumentDataProp, TaxDocumentDataProp } from 'models';
import moment from 'moment';
import './document.scss';
import DocumentsListSkeleton from './DocumentsListSkeleton';

interface IListDocument {
    isLoading: boolean;
    customerDocumentDetails?: CustomerDocumentDataProp[];
    taxDocumentDetails?: TaxDocumentDataProp[];
    searchTerm?: string;
    handleDraft?: (item, index, date) => void;
}

const ListDocument = ({ isLoading, customerDocumentDetails, taxDocumentDetails, searchTerm, handleDraft }: IListDocument) => {

    const openDocument = (document, index) => {
        const item = {
            file: document?.certificateName || document?.name,
            description: document?.docDec || document?.description,
            documentSaveTypeName: document?.docType || document?.documentSaveType,
            typeId: document?.companyDocID || document?.id,
            size: document?.docSize || document?.fileSizeText,
            fileUrl: document?.url || document?.docURL,
            readOnly: true
        }
        const date = document?.modifiedBy ? new Intl.DateTimeFormat("en-GB", {
            year: "numeric",
            month: "short",
            day: "2-digit",
        }).format(document?.modifiedBy) : document?.createdDate;
        handleDraft(item, index, date)
    }

    return (
        <div className="document-table">
            {isLoading ?
                <table>
                    <DocumentsListSkeleton />
                </table>
                : <table>
                    {taxDocumentDetails?.length ?
                        <>
                            <thead>
                                <tr>
                                    <th className='first-th'>{searchTerm ? `${taxDocumentDetails?.length === 1 ? '1 Tax Exempt Certificate' : `${taxDocumentDetails?.length} Tax Exempt Certificates`} found` : "Tax Exempt Certificates"}</th>
                                    <th className='all-th'>Type</th>
                                    <th className='all-th'>Status</th>
                                    <th className='all-th'>Last Modified</th>
                                    <th className='all-th'>Applied On</th>
                                    <th className='all-th'>Company</th>
                                    <th className='all-th'>Region</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    taxDocumentDetails.map((taxDocument, indexedDB) => {
                                        const { certificateName, companyName, companyDocID, createdDate, region, IsBuyerCompany } = taxDocument || {};
                                        return (
                                            <tr key={indexedDB} onClick={() => openDocument(taxDocument, indexedDB)}>
                                                <td className='list-document-name'>{certificateName}<br /><span>{companyDocID}</span></td>
                                                <td>Tax exempt certificate</td>
                                                <td>Uploaded</td>
                                                <td>{moment(createdDate).format("MM/DD/YY")}</td>
                                                <td>{IsBuyerCompany ? "Buyer company" : "Ship to company"}</td>
                                                <td className='list-document-name'>{companyName}</td>
                                                <td className='list-document-name'>{region}</td>
                                            </tr>
                                        )
                                    }

                                    )
                                }
                            </tbody>
                        </>
                        :
                        <thead>
                            <tr>
                                <th className='first-th'>No Tax Exempt Certificates {searchTerm ? "found" : "added"}</th>
                            </tr>
                        </thead>
                    }
                </table>
            }


            {isLoading ?
                <table>
                    <DocumentsListSkeleton />
                </table>
                : <table>
                    {customerDocumentDetails?.length ?
                        <>
                            <thead>
                                <tr>
                                    <th className='first-th'>{searchTerm ? `${customerDocumentDetails?.length === 1 ? '1 Company Document' : `${customerDocumentDetails?.length} Company Documents`} found` : "Company Documents"}</th>
                                    <th className='all-th'>Type</th>
                                    <th className='all-th'>Status</th>
                                    <th className='all-th'>Last Modified</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    customerDocumentDetails.map((customerDocument, indexedDB) => {
                                        const { typeName, name, id, modifiedAt, modifiedBy } = customerDocument || {};
                                        const date = new Intl.DateTimeFormat("en-GB", {
                                            year: "numeric",
                                            month: "short",
                                            day: "2-digit",
                                        }).format(modifiedBy);
                                        return (
                                            <tr key={indexedDB} onClick={() => openDocument(customerDocument, indexedDB)}>
                                                <td className='list-document-name'>{name}<br /><span>{id}</span></td>
                                                <td className='list-document-name'>{typeName}</td>
                                                <td>Uploaded</td>
                                                <td>{moment(modifiedAt).format("MM/DD/YY") ??
                                                    moment(date).format("MM/DD/YY")}</td>
                                            </tr>
                                        )
                                    }
                                    )
                                }
                            </tbody>
                        </> :
                        <thead>
                            <tr>
                                <th className='first-th'>No Company Documents {searchTerm ? "found" : "added"}</th>
                            </tr>
                        </thead>
                    }
                </table>}
        </div>
    );
};

export default ListDocument;
