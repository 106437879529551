import "@fontsource/inter";
import { Drawer, Tab } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Divider,
  PosButton,
  PosIconButton,
  PosImage,
  PosPageHeading,
  PosSnackbar,
  PosTab,
  PosTabPanel,
} from "apps/pos/Common/uiCore";
import CustomerNotesModal from "apps/pos/CustomerNotesModal";
import { NewOrderDrawer } from "apps/pos/Drawers";
import SaveForLater from "apps/pos/SaveForLater";
import CustomerDrafts from "../customerInfo/CustomerDrafts/CustomerDrafts";
import CustomerOrders from "../customerInfo/CustomerOrders/CustomerOrders";
import CustomerStorage from "../customerInfo/CustomerStorage/CustomerStorage";
import Document from "../customerInfo/Documents/document";
import CustomerInfoUpdated from "./CustomerInfoUpdated";

import { snackbarDetail } from "constants/constant";
import { ProductPriceType, ProductTypes, documentByModule } from "constants/enum";
import { AddCircleIcon, ArrowBackRoundedIcon } from "constants/icons";
import images from "constants/images";
import { PosFlowDataProp, UploadedDocumentItemProp } from "constants/interfaces";
import { Paths, Placeholders } from "enums";
import { RootState, user } from "models";
import { keys } from "utils/helper";
import { authorizationToken, calculatePriceItemMuEach, getLocalStoraGeItem, priceToBeFixedConverter, roundToDecimalPlaces, showScrollCartPage } from "utils/utils";

import {
  clearCustomerAccountInfo,
  clearLoadCartDetails,
  loadCartRequest,
  savedDraftsListPageReset,
  setCustomerAlertHide,
  setExemptTax,
  setNoLoadDetailsAction,
  setPosFlowData,
  setTabIndexNumber,
  setTaxExemptManually,
  shippingAddressSelectedData
} from "sagas/persistReducer/actions";
import {
  cartDraftRequest,
  clearPackingSlipData,
  clearSearchCustomer,
  clearSearchDrafts,
  getShippingFacilitiesClear,
  getShippingFacilitiesRequest,
  priceSchedulesRequest,
  savedDraftsReset,
  setHeaderHeightCount,
  setJobNameForAll,
  setMarkAsQuoteReducer,
  setShippingInstructionNote,
  setStoreCreditAmout,
  suggestedProductsClear,
} from "sagas/pos/actions";

import "./customerDetails.scss";

interface CustomerDetailsModalProps {
  setCustomerInfo?: (boolean) => void;
  setSuggestedProducts?: ([]) => void;
  setCartEmpty?: (boolean) => void;
  setSuggestionListVisible?: (boolean) => void;
  headerHeightCount?: number;
  isNewOrder?: boolean;
  locationData?: any;
}

const CustomerDetails = ({
  setCustomerInfo,
  headerHeightCount,
  isNewOrder,
}: CustomerDetailsModalProps) => {
  const useStyles = makeStyles({
    table: {
      minWidth: 500,
    },
    root: {
      position: "unset",
      "& .MuiTableHead-root": {
        position: "unset",
      },
    },
    drawer: {
      "& .MuiDrawer-paper": {
        borderTopLeftRadius: 30,
        borderBottomLeftRadius: 30,
      },
    },
  });

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();
  const stateData = location.state;

  const {
    draftLoadMessage,
    priceScheduleDetails,
    isPriceScheduleLoading,
    isLoading,
    draftListError,
    isPurchaseOrderNoEdit,
    orderInstructionNote,
    shippingInstructionNote,
  } = useSelector<RootState, any>((state) => state.pos);

  const { isLoadCartSuccess, posFlowData, loadCartDetails, tabOpenIndex, customerAccount, saveProductInfo, isTaxExempted, isPickAddress, recipientSwitchInfo, allowPickupCheck } =
    useSelector<RootState, PosFlowDataProp>((state) => state.persistReducer);

  const { loggedInUser } = useSelector<
    RootState,
    user
  >((state) => state.user);

  const { customer: posCustomer, cartProducts, shiptoFacilities, savedCart, salesman, shippingCharge, customerInfoUpdated, firstName: posFirstName,
    lastName: posLastName,
    phone: posPhone, email, selectQuoteId, selectQuoteType, poNumber, schedule, addDocument, cartProductDetails } = posFlowData || {}
  const { companyName: posCompanyName } = posCustomer || {}
  const { cartId, shipToByFacility, products } = loadCartDetails || {};

  const [newOrderShippingAddress, setNewOrderShippingAddress] = useState(false);
  const [priceScheduleIdData, SetPriceScheduleIdData] = useState(false);
  const [notesModal, setNotesModal] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [draftCartId, setDraftCartId] = useState("");
  const [isSnackbarVisibleResume, setIsSnackbarVisibleResume] = useState(false);
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [value, setValue] = useState(tabOpenIndex || 0);
  const [cartExtPrice, setCartExtPrice] = useState(0);
  const [taxRate, setTaxRate] = useState<number>(
    shiptoFacilities?.tax?.taxRate * 100
  );
  const [saveForLaterClickedFrom, setSaveForLaterClickedFrom] = useState(null)
  const [showSaveForLater, setShowSaveForLater] = useState<boolean>(false);
  const { resumeDraft, clearCart } = snackbarDetail
  useEffect(() => {
    if (!posCustomer?.customerId) {
      navigate(Paths.posNewOrder, { replace: true });
    }
  }, [])

  useEffect(() => {
    if (priceScheduleIdData && !isPriceScheduleLoading) {
      priceScheduleData();
      SetPriceScheduleIdData(false);
    }
  }, [priceScheduleIdData, priceScheduleDetails?.data?.length, isLoading]);

  useEffect(() => {
    if (draftListError) {
      const preparedData = {
        stockWarehouseId: currentWorkspace?.stockWarehouseId,
        customerId: posCustomer.customerId,
        takerId: currentTaker?.personId,
        cartId: null,
      };
      if (posCustomer?.priceScheduleId) {
        dispatch(getShippingFacilitiesRequest(posCustomer.customerId));
        dispatch(loadCartRequest(preparedData));
        setTimeout(() => {
          dispatch(savedDraftsReset());
          dispatch(shippingAddressSelectedData(false));
        }, 1000);
      }
    }
  }, [draftListError]);

  const backToCartPage = () => {
    dispatch(clearSearchDrafts());
    dispatch(clearSearchCustomer())
    if (isActiveCart) {
      dispatch(setPosFlowData({ ...posFlowData, viaSwitchCustomer: false, scheduleAdded: false }))
      navigate(`${Paths.posCart}/${cartId}`)
    } else {
      navigate(Paths.posNewOrder)
    }
    if (productInCart) {
    } else {
      if (isNewOrder && !isActiveCart) {
        const data = {
          ...posFlowData,
          poNumber: "",
          customer: {},
          savedCart: {},
          schedule: {},
          taker: {},
          pickOrderQuantity: [],
          pickQuantityArr: [],
          pickAllQuantityArray: [],
          shippingCharge: { shippingAmt: 0, shippingAmtTax: 0 },
          isCartAvailable: false,
          addDocument: [],
        };
        dispatch(setPosFlowData(data));
        dispatch(clearCustomerAccountInfo());
        dispatch(setTabIndexNumber(0));
        dispatch(savedDraftsListPageReset());
      } else {
        setCustomerInfo && setCustomerInfo(false);
        dispatch(setTabIndexNumber(0));
      }
    }
  };

  const handleKeyPressDown = (e) => {
    const { keyCode } = e;
    const { esc } = keys;
    const { cartId } = loadCartDetails || {};

    if (keyCode === esc && cartId) {
      cartId && backToCartPage();
    } else if (keyCode === esc && !isActiveCart) {
      navigate(Paths.posNewOrder, { state: { searchString: stateData?.searchString } })
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPressDown);

    return function cleanup() {
      document.removeEventListener("keydown", handleKeyPressDown);
    };
  }, [loadCartDetails]);

  useEffect(() => {
    if (draftLoadMessage && !deleteSuccess) {
      setState({ ...state, right: true });
    }
  }, [draftLoadMessage, deleteSuccess]);

  useEffect(() => {
    dispatch(setHeaderHeightCount(headerHeightCount));
  }, [headerHeightCount]);

  useEffect(() => {
    if (!isTaxExempted) {
      if (isPickAddress) {
        setTaxRate(shipToByFacility?.tax?.taxRate * 100);
      } else {
        const countDocuments = addDocument.filter(
          (e) =>
            e?.documentSaveTypeName?.toLowerCase() ===
            documentByModule.taxExemptCerti.toLowerCase()
        ).length;
        if (countDocuments > 0) {
          setTaxRate(0);
        } else {
          setTaxRate(shiptoFacilities?.tax?.taxRate * 100);
        }
      }
    }
  }, [isPickAddress]);

  useEffect(() => {
    if (!isTaxExempted) {
      setTaxRate(shiptoFacilities?.tax?.taxRate * 100);
    }
  }, [shiptoFacilities?.tax?.taxRate]);

  const updateCalculation = () => {
    let calculateExtPrice = 0;
    let calculateTotalQuantity = 0;
    let calculateVendorPrice = 0;
    cartProducts?.length &&
      cartProducts.forEach(
        ({
          priceMU,
          itemMU,
          posRetailPrice,
          // itemMuPrice,
          qtyOrdered,
          vendorPrice,
          // extPrice,
        }) => {
          const itemMuPriceWithPosRetailPrice =
            (calculatePriceItemMuEach(priceMU, itemMU, posRetailPrice) *
              qtyOrdered || 0);
          // if (itemMuPrice) {
          //   calculateExtPrice = calculateExtPrice + Number(extPrice);
          // } else {
          calculateExtPrice =
            calculateExtPrice + parseFloat(roundToDecimalPlaces(itemMuPriceWithPosRetailPrice).toFixed(2));
          // }
          calculateVendorPrice =
            calculateVendorPrice + Number(vendorPrice.price) * qtyOrdered;
          calculateTotalQuantity = calculateTotalQuantity + qtyOrdered;
        }
      );
    setCartExtPrice(roundToDecimalPlaces(calculateExtPrice));
  };

  useEffect(() => {
    updateCalculation();
  }, [cartProducts?.length, cartProducts, cartProductDetails?.qtyOrdered]);

  const customerPricingScheduleRef = useRef<any>();
  const savedOrderFoundInfoRef = useRef();
  const classes = useStyles();
  const currentTaker = getLocalStoraGeItem("taker");
  const takerFullName = currentTaker?.fullName;
  const { personId: takerPersonId } = currentTaker || {};
  const currentWorkspace = getLocalStoraGeItem("workspace");
  const { stockWarehouseId } = currentWorkspace || {};
  const productInCart = products?.length;
  const { isPosGeneric, companyName } = customerAccount || {};
  const { blackOutlinedCustomer } = images;
  const isActiveCart = window.location.search.includes("cart");
  const { typeClearance, typeDiscontinued } = ProductTypes;
  const shippingAmount = shippingCharge?.shippingAmt || 0;
  const firstName = savedCart?.customer?.fullName?.split(" ")?.[0];
  const lastName = savedCart?.customer?.fullName?.split(" ")?.[1];

  useEffect(() => {
    if (!stockWarehouseId || !takerPersonId) {
      navigate(Paths.posNewOrder);
    }
  }, [stockWarehouseId, takerPersonId])

  const priceScheduleData = () => {
    if (priceScheduleDetails?.data?.length) {
      setState({ ...state, right: true });
    } else {
      setState({ ...state, right: false });
      const preparedData = {
        stockWarehouseId: currentWorkspace?.stockWarehouseId,
        customerId: posCustomer.customerId,
        takerId: currentTaker?.personId,
        cartId: null,
      };
      dispatch(getShippingFacilitiesRequest(posCustomer.customerId));
      dispatch(loadCartRequest(preparedData));
      dispatch(savedDraftsReset());
    }
  };

  useEffect(() => {
    if (isLoadCartSuccess && !isActiveCart) {
      navigate(`${Paths.posCart}/${cartId}`);
    }
  }, [isLoadCartSuccess])


  const priceScheduleApiCall = () => {
    if (!priceScheduleDetails?.data?.length) {
      dispatch(priceSchedulesRequest({ pageSize: "1", size: 20 }));
    }
  };

  const handleNewOrder = () => {
    dispatch(clearSearchDrafts());
    dispatch(suggestedProductsClear());
    dispatch(shippingAddressSelectedData(false));
    dispatch(setJobNameForAll(""));
    dispatch(setMarkAsQuoteReducer(false));
    dispatch(clearPackingSlipData());
    setTimeout(() => {
      const data = {
        ...posFlowData,
        customer: posCustomer,
        shippingCharge: {
          shippingAmt: 0,
          shippingAmtTax: 0,
        },
        cartProducts: [],
        addInStorage: [],
        savedCart: {
          jobName: null,
        },
        poNumber: null,
        salesman: null
      };
      dispatch(setPosFlowData(data));
      dispatch(getShippingFacilitiesClear());
      const currentTaker = JSON.parse(localStorage.getItem("taker"));
      if (posCustomer?.priceScheduleId) {
        if (currentTaker?.personId) {
          const currentWorkspace = JSON.parse(localStorage.getItem("workspace"));
          const preparedData = {
            stockWarehouseId: currentWorkspace.stockWarehouseId,
            customerId: posFlowData?.customer?.customerId,
            takerId: currentTaker?.personId,
            cartId: null,
          };
          dispatch(getShippingFacilitiesRequest(posFlowData?.customer?.customerId));
          dispatch(loadCartRequest(preparedData));
        }
      } else {
        SetPriceScheduleIdData(true);
        priceScheduleApiCall();
      }
      setDeleteSuccess(false);
    }, 1000);
  };

  const handleSerchCustomer = () => {
    if (posCustomer.hasInternalNotes === true) {
      setNotesModal(true);
    } else {
      handleNewOrder();
    }
  };

  const redirectToNewOrder = () => {
    const data = {
      ...posFlowData,
      addDocument: [],
      isCartAvailable: cartProducts?.length ? true : false,
      isLastCustomerGeneric: isPosGeneric,
      poNumber: "",
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      companyName: "",
      schedule: {}
    };
    dispatch(setPosFlowData(data));
    dispatch(setShippingInstructionNote(""));
    dispatch(setJobNameForAll(""));
    dispatch(setMarkAsQuoteReducer(false));
    dispatch(setCustomerAlertHide(true));
    dispatch(setStoreCreditAmout(""));
    dispatch(clearCustomerAccountInfo());
    dispatch(savedDraftsListPageReset());
    dispatch(setTabIndexNumber(0));
    navigate(Paths.posNewOrder);
    dispatch(clearPackingSlipData());
  };
  const shippingTaxAmount = Number(
    ((shippingAmount * taxRate) / 100).toFixed(2)
  );

  const productsInCart = cartProducts
    ? cartProducts
    : saveProductInfo;

  const saveForLaterAPI = async (draftSaveName) => {
    let shipTaxAmtToBeSent;

    if (savedCart?.customer?.taxExemptFlag && isTaxExempted) {
      shipTaxAmtToBeSent = 0;
    } else {
      if (
        savedCart?.shipToByFacility?.recipient?.taxExemptFlag &&
        isTaxExempted
      ) {
        shipTaxAmtToBeSent = 0;
      } else {
        if (isTaxExempted) {
          shipTaxAmtToBeSent = 0;
        } else {
          if (savedCart?.shipToByFacility?.tax?.shipTaxFlag === 0) {
            shipTaxAmtToBeSent = 0;
          } else {
            shipTaxAmtToBeSent = Number(shippingTaxAmount);
          }
        }
      }
    }

    const decodedData = authorizationToken("Authorization");

    const cartProductsForLater = [];

    productsInCart?.length &&
      productsInCart?.forEach(
        (
          {
            productId,
            manufacturerCode,
            manufacturerId,
            vendorId,
            productName,
            vendorPrice,
            salesPrice,
            posRetailPrice,
            priceMU,
            muName,
            itemMU,
            itemMuName,
            unitPerCase,
            qtyInStock,
            qtyOrdered,
            shippingNote,
            orderNote,
            initialPriceData,
            clearance,
            discontinued,
            priceScheduleProductPrice,
            salesOverride,
            updatedAvailableQty,
            priceScheduleVendorProductGroupId,
            productPriceTypeApplied,
            itemType,
            markupMultiplier
          },
          index
        ) => {
          cartProductsForLater.push({
            lineId: index + 1,
            productId,
            manufacturerCode,
            manufacturerId,
            vendorId,
            itemType,
            productName,
            vendorPrice: vendorPrice?.price ?? vendorPrice,
            salesPrice: salesPrice?.price ?? salesPrice,
            initialPosRetailPrice: initialPriceData ? initialPriceData : 0,
            finalPosRetailPrice: posRetailPrice ? Number(posRetailPrice) : 0,
            priceScheduleVendorProductGroupId,
            priceMU,
            muName,
            itemMU,
            itemMuName,
            unitPerCase,
            qtyInStock,
            updatedAvailableQty,
            qtyOrdered,
            clearanceFlag: clearance?.type === typeClearance ? 1 : 0,
            discontinueFlag: discontinued?.type === typeDiscontinued ? 1 : 0,
            priceScheduleProductId:
              priceScheduleProductPrice?.priceScheduleProductId || null,
            markupMultiplier,
            overridePrice: salesOverride?.price || null,
            pickConfigs: null,
            shippingNote: shippingNote,
            orderNote: orderNote,
            productPriceTypeApplied: typeof productPriceTypeApplied === "string" ? ProductPriceType[productPriceTypeApplied] : productPriceTypeApplied,
          });
        }
      );

    const subTotalAmount = cartExtPrice ? cartExtPrice.toFixed(2) : 0;
    const taxAmount = ((cartExtPrice * taxRate) / 100).toFixed(2);

    const grandTotalAmount =
      savedCart?.shipToByFacility?.tax?.shipTaxFlag === 1
        ? Number(subTotalAmount) +
        Number(taxAmount) +
        Number(shippingAmount) +
        Number(shippingTaxAmount)
        : Number(subTotalAmount) + Number(taxAmount) + Number(shippingAmount);
    const { recipient } = shiptoFacilities || {}
    const genericPhoneInfo = {
      type: Placeholders.phoneType,
      number: `${recipient?.phone1}${recipient?.phone2}${recipient?.phone3}`,
      extn: recipient?.phone4 || "",
      intCode: "1",
      areaCode: recipient?.phone1,
      prefix: recipient?.phone2,
      lineNum: recipient?.phone3,
    };
    const firstNameGeneric = recipientSwitchInfo ? recipient?.firstName : posFirstName
    const lastNameGeneric = recipientSwitchInfo ? recipient?.lastName : posLastName
    const emailNameGeneric = recipientSwitchInfo ? (recipient?.email ? recipient.email : null) : (email ? email : null)
    const companyNameGeneric = recipientSwitchInfo ? recipient?.companyName : companyName
    const phoneNumberGeneric = recipientSwitchInfo ? (genericPhoneInfo?.number ? genericPhoneInfo : null) : (posPhone?.number ? posPhone : null)

    const customerData = {
      buyOnBanNote: posCustomer?.hasInternalNotes,
      customerId: savedCart?.customer?.customerId
        ? savedCart?.customer?.customerId
        : "",
      customerNote: savedCart?.customerNote || "",
      customerInfoUpdated: customerInfoUpdated,
      // will change in next PR
      firstName: isPosGeneric ? firstNameGeneric : firstName || "",
      lastName: isPosGeneric ? lastNameGeneric : lastName || "",
      posCustCode: savedCart?.customer?.posCustCode || null,
      phone: isPosGeneric ? phoneNumberGeneric : savedCart?.customer?.phone || null,
      email: isPosGeneric ? emailNameGeneric : savedCart?.customer?.email ?? "",
      companyName: isPosGeneric ? companyNameGeneric : null,
      buyOnCreditHold: savedCart?.customer?.creditHoldFlag === 1,
      showPrices: savedCart?.customer?.showPrices
        ? savedCart?.customer?.showPrices
        : false,
      isPosGeneric: savedCart?.customer?.isPosGeneric
        ? savedCart?.customer?.isPosGeneric
        : false,
      lookupType: savedCart?.customer?.lookupType,
    };
    const uploadData = [];
    const formFiles = [];
    addDocument?.length &&
      addDocument.map((item: UploadedDocumentItemProp) => {
        const {
          file,
          description,
          typeId,
          documentSaveType,
          locationSaveType,
          uploadedBy,
          originalFile,
        } = item;
        formFiles.push(originalFile);
        if (originalFile) {
          const data = {
            fileName: file,
            description: description,
            typeId: typeId,
            documentSaveType: documentSaveType,
            locationSaveType: locationSaveType,
            uploadedBy: uploadedBy,
          };
          uploadData.push(data);
        }
      });

    const savedDraftTaxExempt = savedCart?.customer?.taxExemptFlag
      ? 1
      : savedCart?.shipToByFacility?.recipient?.taxExemptFlag
        ? 1
        : isTaxExempted
          ? 1
          : 0

    const preparedData = {
      sessionStartedAt: savedCart?.sessionStartedAt
        ? savedCart?.sessionStartedAt
        : "",
      quoteId: selectQuoteId ? selectQuoteId : null,
      quoteType: selectQuoteType ? selectQuoteType : "NOT_SET",
      cartId: savedCart?.cartId ? savedCart?.cartId : "",
      loggedInUserId: decodedData?.DefaultLoginId,
      transactionType: "SALE",
      stockWarehouseId: currentWorkspace?.stockWarehouseId,
      takerId: currentTaker?.personId,
      purchaseOrderNum: (poNumber || (!isPurchaseOrderNoEdit && loadCartDetails?.products?.length && loadCartDetails.purchaseOrderNum) || null),
      jobName: draftSaveName,
      customer: customerData,
      taxRate: savedDraftTaxExempt === 1 ? 0 : savedCart?.shipToByFacility?.tax?.taxRate,
      taxSchedule: savedDraftTaxExempt === 1 ? "NONTAX" : savedCart?.shipToByFacility?.tax?.taxSchedule,
      initialTaxAmt: savedDraftTaxExempt === 1 ? 0 : taxAmount,
      initialTaxExemptFlag: savedDraftTaxExempt === 1 ? 1 : 0,
      shipTaxFlag: savedCart?.shipToByFacility?.tax?.shipTaxFlag
        ? savedCart?.shipToByFacility?.tax?.shipTaxFlag
        : 0,
      initialShipToAddressId: savedCart?.shipToByFacility?.facilityId,
      finalShipToAddressId: shiptoFacilities?.facilityId,
      salesPersonId: posFlowData?.salesman?.ssoId || posFlowData?.salesman?.personId || 0,
      initialScheduleId: savedCart?.customer?.priceScheduleId,
      finalScheduleId: schedule?.id
        ? schedule?.id
        : savedCart?.customer?.priceScheduleId
          ? savedCart?.customer?.priceScheduleId
          : "",
      paymentMode: "CASH",
      paymentRef1: null,
      paymentRef2: null,
      subTotalAmt: Number(subTotalAmount),
      taxAmt: savedCart?.customer?.taxExemptFlag
        ? 0
        : savedCart?.shipToByFacility?.recipient?.taxExemptFlag
          ? 0
          : isTaxExempted
            ? 0
            : Number(taxAmount) || 0,
      taxExemptFlag: savedDraftTaxExempt,
      shippingFeeAmt: Number(shippingAmount),
      shippingTaxAmt: shipTaxAmtToBeSent ? shipTaxAmtToBeSent : 0.0,
      grandTotalAmt: Number(grandTotalAmount.toFixed(4)) || 0,
      tenderAmt: 0,
      tenderBalanceAmt: Number(priceToBeFixedConverter(0 - grandTotalAmount)) || 0,
      products: cartProductsForLater,
      uploadFiles: uploadData?.length ? uploadData : null,
      shippingNote: shippingInstructionNote,
      orderNote: orderInstructionNote,
      takerName: takerFullName,
      salesPersonName: salesman?.salespersonName ? salesman?.salespersonName : '',
      noDeliverToShipAddressButPickupFlag: allowPickupCheck ?? false
    };
    dispatch(setNoLoadDetailsAction(false));
    const processDataFormat = JSON.stringify(JSON.stringify(preparedData));

    const formData = new FormData();
    formData.append("posOrderTransactionRequest", processDataFormat);

    addDocument.forEach((item: UploadedDocumentItemProp) => {
      const { originalFile } = item;
      if (originalFile) {
        formData.append("formFiles", originalFile);
      }
    });

    dispatch(cartDraftRequest(formData, savedCart?.cartId));
  };

  const clearPageAndRedirect = () => {
    if (cartProducts?.length) {
      showScrollCartPage();
      setSaveForLaterClickedFrom(true);
      setShowSaveForLater(true);
    } else {
      const data = {
        ...posFlowData,
        cartProducts: [],
        addInStorage: [],
        poNumber: "",
        customer: {},
        savedCart: {},
        schedule: {},
        taker: {},
        pickOrderQuantity: [],
        pickQuantityArr: [],
        pickAllQuantityArray: [],
        shippingCharge: { shippingAmt: 0, shippingAmtTax: 0 },
        isCartAvailable: false,
        addDocument: [],
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        companyName: "",
      };
      dispatch(shippingAddressSelectedData(false));
      dispatch(setPosFlowData(data));
      dispatch(setShippingInstructionNote(""));
      dispatch(setJobNameForAll(""));
      dispatch(setMarkAsQuoteReducer(false));
      dispatch(setCustomerAlertHide(true));
      dispatch(setStoreCreditAmout(""));
      dispatch(clearLoadCartDetails());
      dispatch(clearSearchCustomer());
      dispatch(setExemptTax(false));
      dispatch(setTaxExemptManually(false))
      dispatch(savedDraftsListPageReset());
      dispatch(suggestedProductsClear());
      dispatch(clearCustomerAccountInfo());
      navigate(Paths.posNewOrder);
      dispatch(clearSearchDrafts());
      dispatch(clearPackingSlipData());
    }
  };

  const customerHeader: any = useRef();
  const tabHeaderRef: any = useRef();
  const storageSearchInputRef: any = useRef();
  const storagePillsRef: any = useRef();

  const { current: customerHeaderCurrent }: any = customerHeader
  const { current: tabHeaderRefCurrent }: any = tabHeaderRef
  const { current: storageSearchInputRefCurrent }: any = storageSearchInputRef
  const { current: storagePillsRefCurrent }: any = storagePillsRef

  const customerHeaderHeight = customerHeaderCurrent?.clientHeight;
  const tabHeaderRefHeight = tabHeaderRefCurrent?.clientHeight;
  const storageSearchInputRefHeight = storageSearchInputRefCurrent?.clientHeight;
  const storagePillsRefHeight = storagePillsRefCurrent?.clientHeight;

  const storageTopDataHeight = customerHeaderHeight + tabHeaderRefHeight + storageSearchInputRefHeight + storagePillsRefHeight + 151

  const getCustomerInfoTab = () => {

    switch (value) {
      case 0:
        return (
          <PosTabPanel value={value} index={value}>
            {isActiveCart ? <CustomerInfoUpdated /> : (
              <CustomerDrafts
                setDraftCartId={setDraftCartId}
                setDeleteSuccess={setDeleteSuccess}
                handleCloseModal={handleCloseModal}
                savedOrderFoundInfoRef={savedOrderFoundInfoRef}
              />
            )}
          </PosTabPanel>
        );

      case 1:
        return (
          <PosTabPanel value={value} index={value} className="customer-tab">
            {isActiveCart ? <Document savedOrderFoundInfoRef={savedOrderFoundInfoRef} /> : (
              <CustomerInfoUpdated />
            )}
          </PosTabPanel>
        );

      case 2:
        return (
          <PosTabPanel value={value} index={value}>
            <Document savedOrderFoundInfoRef={savedOrderFoundInfoRef} />
          </PosTabPanel>
        );

      case 3:
        return (
          <PosTabPanel value={value} index={value}>
            {isActiveCart ? null : (
              <CustomerOrders savedOrderFoundInfoRef={savedOrderFoundInfoRef} />
            )}
          </PosTabPanel>
        );

      case 4:
        return (
          <PosTabPanel value={value} index={value}>
            {isActiveCart ? null : (
              <CustomerStorage
                storageSearchInputRef={storageSearchInputRef}
                storagePillsRef={storagePillsRef}
                storageTopDataHeight={storageTopDataHeight}
                savedOrderFoundInfoRef={savedOrderFoundInfoRef}
              />
            )}
          </PosTabPanel>
        );

      default:
        return null;
    }
  };

  const handleChange = (_: SyntheticEvent, newValue: number) => {
    dispatch(setTabIndexNumber(newValue));
    setValue(newValue);
  };

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const handleCloseModal = (value) => {
    setNotesModal(value);
  };

  const onCloseSnackbar = () => {
    setIsSnackbarVisibleResume(false)
  }
  return (
    <>
      <div className="customer-info-section customer-info-container customer-section-info">
        <PosIconButton
          className="back-next-btn-small-left customer-info-back-btn customer-main-sec-back-btn customer-details-back-btn"
          onClick={backToCartPage}
          tabIndex={-1}
        >
          <div className="btn-back-customer">
            <ArrowBackRoundedIcon />
          </div>
        </PosIconButton>
        <div className="pricing-modal-header mt-0 mb-0" ref={customerHeader}>
          <div className="customer-info-heading">
            <div>
              {
                cartId ?
                  <PosPageHeading heading={`Cart #${cartId}`} grayHeading className="mb-3" /> :
                  <PosPageHeading heading={`Customer`} grayHeading className="mb-3" />
              }
              {posCompanyName && (
                <PosPageHeading heading={posCompanyName} blackHeading />
              )}
            </div>
            <div className="d-flex">
              {posCustomer?.isPosGeneric && isActiveCart && cartProducts?.length > 0 ? (
                <div className="btn-change-customer">
                  <PosButton
                    className="btn mb-3 switch-customer-btn"
                    variant="contained"
                    disabled={Boolean(productInCart)}
                    onClick={redirectToNewOrder}
                    tabIndex={1}
                    startIcon={
                      <PosImage
                        src={blackOutlinedCustomer}
                        alt="shipping-icon"
                      />
                    }
                  >
                    Switch Customer
                  </PosButton>
                </div>
              ) : null}
              <div>
                <PosButton
                  className="btn mb-3 start-new-order-btn"
                  tabIndex={1}
                  id="start-order-btn"
                  variant="contained"
                  color={isActiveCart ? "default" : "primary"}
                  onClick={() => {
                    if (loggedInUser) {
                      localStorage.setItem("taker", JSON.stringify(loggedInUser))
                    }
                    isActiveCart
                      ? clearPageAndRedirect()
                      : handleSerchCustomer();
                  }}
                  startIcon={<AddCircleIcon />}
                >
                  Start a New Order
                </PosButton>
              </div>
            </div>
          </div>
        </div>
        <div ref={tabHeaderRef}>
          <PosTab
            value={value}
            onChange={handleChange}
            className="customer-tab-header"
          >
            {isActiveCart ? null : (<Tab
              label="Drafts"
              {...a11yProps(0)}
              className="customer-tab-header-sec"
            />)}
            <Tab
              label="Account"
              {...a11yProps(1)}
              className="customer-tab-header-sec"
              tabIndex={2}
            />
            <Tab
              label="Documents"
              {...a11yProps(2)}
              className="customer-tab-header-sec"
            />
            {isActiveCart ? null : <Tab
              label="Orders"
              {...a11yProps(3)}
              className="customer-tab-header-sec"
            />}
            {isActiveCart ? null : <Tab
              label="Storage"
              {...a11yProps(4)}
              className="customer-tab-header-sec"
            />}
          </PosTab>
        </div>
        <Divider className="update-product-container-line" />
        {getCustomerInfoTab()}
      </div>
      <CustomerNotesModal
        open={notesModal}
        onClose={handleCloseModal}
        handleNewOrder={handleNewOrder}
        draftCartId={draftCartId}
        setIsSnackbarVisibleResume={setIsSnackbarVisibleResume}
      />
      <NewOrderDrawer
        addCustomerFormModal={false}
        setDraftCartModal={false}
        draftCartModal={false}
        open={state["right"]}
        drawerClass={classes.drawer}
        state={state}
        setState={setState}
        openTaker={false}
        customerPricingScheduleRef={customerPricingScheduleRef}
        newOrderShippingAddress={newOrderShippingAddress}
        savedOrderFoundInfoRef={savedOrderFoundInfoRef}
        onCloseDrawer={() => {
          setState({ ...state, right: false });
          setNewOrderShippingAddress(false);
        }}
      />
      {showSaveForLater ?
        <Drawer
          anchor={"right"}
          open={showSaveForLater}
          className={`${classes.drawer} default-drawer-style no-cart-drawer main-drawer-classes drawer-scrollbar-style`}
          onClose={() => {
            setSaveForLaterClickedFrom(null);
            setShowSaveForLater(false);
          }}
        >
          <SaveForLater
            saveForLaterAPI={saveForLaterAPI}
            setSaveForLaterClickedFrom={setSaveForLaterClickedFrom}
            saveForLaterClickedFrom={saveForLaterClickedFrom}
            setShowSaveForLater={setShowSaveForLater}
            setSuggestionListVisible={() => {
              // Required Later
            }}
            setCartEmpty={() => {
              // Required Later
            }}
            setSuggestedProducts={() => {
              // Required Later
            }}
          />
        </Drawer>
        : null}
      <PosSnackbar
        open={isSnackbarVisibleResume}
        message={resumeDraft}
        onClose={onCloseSnackbar}
        actionText={clearCart}
      />
    </>
  );
};

export default CustomerDetails;
