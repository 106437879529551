import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";

import { PosButton } from "apps/pos/Common/uiCore";
import ProductCard from "apps/product/Components/ProductCard";
import ProductTable from "apps/product/Components/ProductTable";
import ShapedSkeleton from "apps/springboard/Component/ShapedSkeleton";

import {
  commonConstants,
  layoutType,
  statusValue,
} from "apps/product/helper/commonConstants";
import { ArrowDropDownIcon, PersonAddIcon } from "constants/icons";
import { Paths } from "enums";

import {
  getProductSearchLoader,
  getProductSkeleton,
  getSearchKeyword,
  productsSearched
} from "apps/product/modules/productListingContainer/redux/selector";
import { distributorData } from "sagas/distributor/selector";

import { searchProductsClear } from "../../redux/product.slice";
import "./style.scss";

const ProductListing = ({
  selectedLayoutType,
  handleSearch,
  handleGetFilter,
  setCurrentPage,
  currentPage,
  keyword,
}) => {
  const dispatch = useDispatch();
  const { zero, one, full } = statusValue;

  const [productList, setProductList] = useState([]);
  const [productCount, setProductCount] = useState(null);
  const [doesHaveMore, setHaveMore] = useState(true);
  const [show, setShow] = useState(false);

  const productsSearchedData = useSelector(productsSearched);
  const productSearchLoader = useSelector(getProductSearchLoader);
  const productSkeleton = useSelector(getProductSkeleton);
  const distributor = useSelector(distributorData);
  const searchText = useSelector(getSearchKeyword);

  useEffect(() => {
    handleGetFilter();
  }, []);

  const getMode = localStorage.getItem("jumperMode");

  const pendingProduct = () => {
    return window.open(
      `${distributor?.httpsUrl}${Paths.pendingProductLink}`,
      commonConstants.target,
      commonConstants.noreferrer
    );
  };
  const regularProduct = () => {
    return window.open(
      `${distributor?.httpsUrl}${Paths.regularProductLink}`,
      commonConstants.target,
      commonConstants.noreferrer
    );
  };
  const rebateProduct = () => {
    return window.open(
      `${distributor?.httpsUrl}${Paths.rebateProductLink}`,
      commonConstants.target,
      commonConstants.noreferrer
    );
  };

  useEffect(() => {
    if (!keyword && !searchText) {
      setProductList([]);
      setProductCount(null);
      setHaveMore(false);
      setCurrentPage(one)
      dispatch(searchProductsClear());
    }
  }, [keyword, searchText]);

  useEffect(() => {
    if (productsSearchedData?.data) {
      const data = productsSearchedData || [];
      const { data: productLists, isPaginated, page } = data || [];
      const count = productLists.length;
      setProductList(productLists);
      setProductCount(count);
      setHaveMore(isPaginated);
      setCurrentPage(page)
    } else {
      setProductList([]);
      setProductCount(zero);
      setHaveMore(false);
      setCurrentPage(one)
    }
  }, [productsSearchedData]);

  const handleScroll = () => {
    setCurrentPage(currentPage + one);
    if (productsSearchedData) {
      handleSearch(currentPage + one);
    }
  };

  const gridView = selectedLayoutType === layoutType.grid;
  const noData = productCount === 0 && keyword && !productSearchLoader;
  const showNoData = () => {
    return (
      <div className="product-found-info d-flex align-items-center justify-content-center flex-column">
        <div className="mb-3">
          <span>Add your first product</span>
        </div>
        {getMode === full ? (
          <PosButton
            variant="contained"
            color="secondary"
            button-is-loading
            className={`return-successful-all-print-btn modal-select-default-btn print-btn picking-qty-btn mb-0 h-auto w-auto`}
            onClick={() => {
              setShow(!show);
            }}
          >
            <div>
              <div className="select-default-text w-100">
                <div>
                  <PersonAddIcon />
                </div>
                <div className="default-schedule-heading w-100">
                  <span className="int-18-reg-bold">
                    {commonConstants.newProduct}
                  </span>
                </div>
                <div>
                  <ArrowDropDownIcon fontSize="small" />
                </div>
              </div>
              <div className={`product-list-dropdown ${show ? "d-block" : ""}`}>
                <div
                  className="print-dropdown-content"
                  onClick={pendingProduct}
                >
                  Pending Product
                </div>
                <div
                  className="print-dropdown-content"
                  onClick={regularProduct}
                >
                  Regular Product{" "}
                </div>
                <div className="print-dropdown-content" onClick={rebateProduct}>
                  Rebate Product
                </div>
              </div>
            </div>
          </PosButton>
        ) : (
          <PosButton
            variant="contained"
            color="primary"
            className="btn"
            onClick={pendingProduct}
            startIcon={<PersonAddIcon />}
          >
            {commonConstants.addPendingProduct}
          </PosButton>
        )}
      </div>
    );
  };

  const productDisplay = () => {
    if (productSkeleton) {
      return null;
    }

    if (selectedLayoutType === layoutType.grid) {
      return <ProductTable productList={productList} />;
    }

    return (
      <>
        {productList.map((productDetails) => (
          <ProductCard
            key={productDetails.id}
            productDetails={productDetails}
          />
        ))}
      </>
    );
  };

  const productSkeletonView = () => {
    return (
      <div className="mt-4">
        {gridView ? (
          Array(5)
            .fill("-")
            .map((item, index) => (
              <ShapedSkeleton
                key={item + index}
                width={800}
                height={50}
                shape="0 0 40 40, 60 0 250 16, 60 24 130 20, 400 0 100 20"
                radius={4}
                classes="mb-4"
              />
            ))
        ) : (
          <div className="d-flex justify-content-between">
            {Array(3)
              .fill("-")
              .map((item, index) => (
                <ShapedSkeleton
                  key={item + index}
                  width={250}
                  height={262}
                  shape="0 0 80 80, 0 100 250 20, 0 150 120 20, 0 176 80 20, 0 246 80 16"
                  radius={4}
                />
              ))}
          </div>
        )}
      </div>
    );
  };

  const productCountShow = () => {
    return (
      <div className="products-suggestion-sp product-found-info mt-0">
        <div className="product-found-text">
          <span>Found {productCount} products</span>
        </div>
        <div className="horizontal-liner-sp product-found-horizontal-line" />
      </div>
    );
  };

  const renderContent = () => {
    if (productSkeleton) {
      return productSkeletonView();
    } else if (noData) {
      return showNoData();
    } else if (productCount !== null && keyword) {
      return productCountShow();
    } else {
      return null;
    }
  };

  return (
    <div className="product-listing-container">
      {renderContent()}
      <InfiniteScroll
        className={`pb-60 ${selectedLayoutType !== layoutType.grid && "product-listing"
          }`}
        dataLength={productList?.length || zero}
        next={handleScroll}
        height={550}
        hasMore={doesHaveMore}
        loader={<></>}
        scrollThreshold={0.9}
      >
        {productDisplay()}
      </InfiniteScroll>
      <div className="product-footer"></div>
    </div>
  );
};

export default ProductListing;
