import { useMutation } from '@tanstack/react-query';
import { getCashOptions } from './constantUrl';
import { stopLoading } from 'utils/utils';
import { useCallback } from 'react';

export const useGetCashOptions = (payload) => {
  const { mutate, isLoading, data, error, isSuccess } = useMutation(
    async () => {
      return getCashOptions(payload);
    },
    {
      onSuccess: stopLoading,
      onError: stopLoading
    });

  const getCashOptionFn = useCallback(
    () => {
      mutate();
    },
    [mutate]
  );

  return {
    getCashOptionFn,
    isLoading,
    data,
    error,
    isSuccess,
  };
};