export const GET_WORKSTATIONS_START = "GET_WORKSTATIONS_START";
export const GET_WORKSTATIONS_SUCCESS = "GET_WORKSTATIONS_SUCCESS";
export const GET_WORKSTATIONS_ERROR = "GET_WORKSTATIONS_ERROR";

export const GET_PRICESCHEDULES_START = "GET_PRICESCHEDULES_START";
export const GET_PRICESCHEDULES_SUCCESS = "GET_PRICESCHEDULES_SUCCESS";
export const GET_PRICESCHEDULES_ERROR = "GET_PRICESCHEDULES_ERROR";
export const GET_PRICESCHEDULES_CLEAR = "GET_PRICESCHEDULES_CLEAR";

export const GET_SAVED_DRAFTS_START = "GET_SAVED_DRAFTS_START";
export const GET_SAVED_DRAFTS_SUCCESS = "GET_SAVED_DRAFTS_SUCCESS";
export const GET_SAVED_DRAFTS_ERROR = "GET_SAVED_DRAFTS_ERROR";
export const GET_SAVED_DRAFTS_RESET = "GET_SAVED_DRAFTS_RESET";

export const GET_TAKERS_START = "GET_TAKERS_START";
export const GET_TAKERS_SUCCESS = "GET_TAKERS_SUCCESS";
export const GET_TAKERS_ERROR = "GET_TAKERS_ERROR";

export const GET_SALESPERSONS_START = "GET_SALESPERSONS_START";
export const GET_SALESPERSONS_SUCCESS = "GET_SALESPERSONS_SUCCESS";
export const GET_SALESPERSONS_ERROR = "GET_SALESPERSONS_ERROR";

export const GET_PRODUCTS_START = "GET_PRODUCTS_START";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_ERROR = "GET_PRODUCTS_ERROR";
export const GET_PRODUCTS_DATA_CLEAR = "GET_PRODUCTS_DATA_CLEAR";

export const EDIT_PRODUCT = "EDIT_PRODUCT";

export const GET_FACILITY_TYPES_START = "GET_FACILITY_TYPES_START";
export const GET_FACILITY_TYPES_SUCCESS = "GET_FACILITY_TYPES_SUCCESS";
export const GET_FACILITY_TYPES_ERROR = "GET_FACILITY_TYPES_ERROR";

export const GET_JOB_DESCRIPTION_START = "GET_JOB_DESCRIPTION_START";
export const GET_JOB_DESCRIPTION_SUCCESS = "GET_JOB_DESCRIPTION_SUCCESS";
export const GET_JOB_DESCRIPTION_ERROR = "GET_JOB_DESCRIPTION_ERROR";

export const GET_STATES_START = "GET_STATES_START";
export const GET_STATES_SUCCESS = "GET_STATES_SUCCESS";
export const GET_STATES_ERROR = "GET_STATES_ERROR";

export const GET_SUGGESTED_PRODUCTS_START = "GET_SUGGESTED_PRODUCTS_START";
export const GET_SUGGESTED_PRODUCTS_SUCCESS = "GET_SUGGESTED_PRODUCTS_SUCCESS";
export const GET_SUGGESTED_PRODUCTS_ERROR = "GET_SUGGESTED_PRODUCTS_ERROR";
export const GET_SUGGESTED_PRODUCTS_CLEAR = "GET_SUGGESTED_PRODUCTS_CLEAR";

export const GET_SHIPPING_FACILITIES_START = "GET_SHIPPING_FACILITIES_START";
export const GET_SHIPPING_FACILITIES_SUCCESS =
  "GET_SHIPPING_FACILITIES_SUCCESS";
export const GET_SHIPPING_FACILITIES_ERROR = "GET_SHIPPING_FACILITIES_ERROR";
export const GET_SHIPPING_FACILITIES_CLEAR = "GET_SHIPPING_FACILITIES_CLEAR";

// start
export const GET_SALES_HISTORY_START = "GET_SALES_HISTORY_START";
export const GET_SALES_HISTORY_SUCCESS = "GET_SALES_HISTORY_SUCCESS";
export const GET_SALES_HISTORY_ERROR = "GET_SALES_HISTORY_ERROR";

export const USER_PRICE_SCHEDULE_DATA_REQUEST =
  "USER_PRICE_SCHEDULE_DATA_REQUEST";
export const USER_PRICE_SCHEDULE_DATA_SUCCESS =
  "USER_PRICE_SCHEDULE_DATA_SUCCESS";
export const USER_PRICE_SCHEDULE_DATA_ERROR = "USER_PRICE_SCHEDULE_DATA_ERROR";
// end

export const GET_SHIPTO_FACILITIES_START = "GET_SHIPTO_FACILITIES_START";
export const GET_SHIPTO_FACILITIES_SUCCESS = "GET_SHIPTO_FACILITIES_SUCCESS";
export const GET_SHIPTO_FACILITIES_ERROR = "GET_SHIPTO_FACILITIES_ERROR";

export const ADD_SHIPPING_FACILITIES_START = "ADD_SHIPPING_FACILITIES_START";
export const ADD_SHIPPING_FACILITIES_SUCCESS =
  "ADD_SHIPPING_FACILITIES_SUCCESS";
export const ADD_SHIPPING_FACILITIES_ERROR = "ADD_SHIPPING_FACILITIES_ERROR";
export const ADD_SHIPPING_FACILITIES_CLEAR = "ADD_SHIPPING_FACILITIES_CLEAR";

export const GET_RECENT_CUSTOMERS_START = "GET_RECENT_CUSTOMERS_START";
export const GET_RECENT_CUSTOMERS_SUCCESS = "GET_RECENT_CUSTOMERS_SUCCESS";
export const GET_RECENT_CUSTOMERS_ERROR = "GET_RECENT_CUSTOMERS_ERROR";
export const GET_RECENT_CUSTOMERS_CLEAR = "GET_RECENT_CUSTOMERS_CLEAR";

export const SEARCH_CUSTOMERS_START = "SEARCH_CUSTOMERS_START";
export const SEARCH_CUSTOMERS_SUCCESS = "SEARCH_CUSTOMERS_SUCCESS";
export const SEARCH_CUSTOMERS_ERROR = "SEARCH_CUSTOMERS_ERROR";
export const CLEAR_SEARCH_CUSTOMER = "CLEAR_SEARCH_CUSTOMER";
export const RESET_SEARCH_CUSTOMERS_ERROR = "RESET_SEARCH_CUSTOMERS_ERROR";

export const SEARCH_PRODUCTS_START = "SEARCH_PRODUCTS_START";
export const SEARCH_PRODUCTS_SUCCESS = "SEARCH_PRODUCTS_SUCCESS";
export const SEARCH_PRODUCTS_ERROR = "SEARCH_PRODUCTS_ERROR";
export const CLEAR_SEARCH_PRODUCT = "CLEAR_SEARCH_PRODUCT";

export const ADD_PRICE_SCHEDULE_START = "ADD_PRICE_SCHEDULE_START";
export const ADD_PRICE_SCHEDULE_SUCCESS = "ADD_PRICE_SCHEDULE_SUCCESS";
export const ADD_PRICE_SCHEDULE_ERROR = "ADD_PRICE_SCHEDULE_ERROR";

export const UPDATE_PRICE_SCHEDULE_START = "UPDATE_PRICE_SCHEDULE_START";
export const UPDATE_PRICE_SCHEDULE_SUCCESS = "UPDATE_PRICE_SCHEDULE_SUCCESS";
export const UPDATE_PRICE_SCHEDULE_ERROR = "UPDATE_PRICE_SCHEDULE_ERROR";
export const UPDATE_PRICE_SCHEDULE_FALSE = "UPDATE_PRICE_SCHEDULE_FALSE";

export const ADD_NEW_PRODUCT_START = "ADD_NEW_PRODUCT_START";
export const ADD_NEW_PRODUCT_SUCCESS = "ADD_NEW_PRODUCT_SUCCESS";
export const ADD_NEW_PRODUCT_ERROR = "ADD_NEW_PRODUCT_ERROR";
export const ADD_NEW_PRODUCT_CLEAR = "ADD_NEW_PRODUCT_CLEAR";

//cartPage

export const STOCK_BY_WAREHOUSE_START = "STOCK_BY_WAREHOUSE_START";
export const STOCK_BY_WAREHOUSE_SUCCESS = "STOCK_BY_WAREHOUSE_SUCCESS";
export const STOCK_BY_WAREHOUSE_ERROR = "STOCK_BY_WAREHOUSE_ERROR";
export const STOCK_BY_WAREHOUSE_CLEAR = "STOCK_BY_WAREHOUSE_CLEAR";

export const CART_SUBMIT_START = "CART_SUBMIT_START";
export const CART_SUBMIT_SUCCESS = "CART_SUBMIT_SUCCESS";
export const CART_SUBMIT_ERROR = "CART_SUBMIT_ERROR";
export const CART_SUBMIT_CLEAR = "CART_SUBMIT_CLEAR";

export const CART_PRINT_START = "CART_PRINT_START";
export const CART_PRINT_SUCCESS = "CART_PRINT_SUCCESS";
export const CART_PRINT_ERROR = "CART_PRINT_ERROR";
export const CART_PRINT_CLEAR = "CART_PRINT_CLEAR";

export const CART_PRINT_WITH_TOTALS_START = "CART_PRINT_WITH_TOTALS_START";
export const CART_PRINT_WITH_TOTALS_SUCCESS = "CART_PRINT_WITH_TOTALS_SUCCESS";
export const CART_PRINT_WITH_TOTALS_ERROR = "CART_PRINT_WITH_TOTALS_ERROR";
export const CART_PRINT_WITH_TOTALS_CLEAR = "CART_PRINT_WITH_TOTALS_CLEAR";

export const IS_GO_TO_CART = "IS_GO_TO_CART";
export const NOT_ADDED_NEW_PRODUCT = "NOT_ADDED_NEW_PRODUCT";
export const CART_DRAFT_START = "CART_DRAFT_START";
export const CART_DRAFT_SUCCESS = "CART_DRAFT_SUCCESS";
export const CART_DRAFT_ERROR = "CART_DRAFT_ERROR";
export const CART_DRAFT_CLEAR = "CART_DRAFT_CLEAR";

export const GET_WAREHOUSE_LOCATIONS_START = "GET_WAREHOUSE_LOCATIONS_START";
export const GET_WAREHOUSE_LOCATIONS_SUCCESS =
  "GET_WAREHOUSE_LOCATIONS_SUCCESS";
export const GET_WAREHOUSE_LOCATIONS_ERROR = "GET_WAREHOUSE_LOCATIONS_ERROR";
export const GET_WAREHOUSE_LOCATIONS_CLEAR = "GET_WAREHOUSE_LOCATIONS_CLEAR";
export const CHECK_PAGE_REFRESH = "CHECK_PAGE_REFRESH";

export const success = "success";
export const error = "error";

export const ARR0W_DOWN_INDEX = "ARR0W_DOWN_INDEX";
export const ARR0W_UP_INDEX = "ARR0W_UP_INDEX";
export const ARR0W_INDEX_RESET = "ARR0W_INDEX_RESET";
export const SET_SELECTED_INDEX = "SET_SELECTED_INDEX";

export const ARR0W_DOWN_INDEX_PRODUCT_PAGE = "ARR0W_DOWN_INDEX_PRODUCT_PAGE";
export const ARR0W_UP_INDEX_PRODUCT_PAGE = "ARR0W_UP_INDEX_PRODUCT_PAGE";
export const ARR0W_INDEX_RESET_PRODUCT_PAGE = "ARR0W_INDEX_RESET_PRODUCT_PAGE";
export const SET_SELECTED_PRODUCT_INDEX = "SET_SELECTED_PRODUCT_INDEX";

export const ARR0W_UP_INDEX_GENERIC = "ARR0W_UP_INDEX_GENERIC"
export const ARR0W_INDEX_RESET_GENERIC = "ARR0W_INDEX_RESET_GENERIC"
export const ARR0W_DOWN_INDEX_GENERIC = "ARR0W_DOWN_INDEX_GENERIC";

export const ARR0W_DRAFT_DOWN_INDEX = "ARR0W_DRAFT_DOWN_INDEX";
export const ARR0W_DRAFT_UP_INDEX = "ARR0W_DRAFT_UP_INDEX";
export const ARR0W_DRAFT_INDEX_RESET = "ARR0W_DRAFT_INDEX_RESET";

export const ARR0W_INDEX_RESET_CUSTOMER_ORDER_MODEL = "ARR0W_INDEX_RESET_CUSTOMER_ORDER_MODEL";
export const ARR0W_UP_INDEX_CUSTOMER_ORDER_MODEL = "ARR0W_UP_INDEX_CUSTOMER_ORDER_MODEL";
export const ARR0W_DOWN_INDEX_CUSTOMER_ORDER_MODEL = "ARR0W_DOWN_INDEX_CUSTOMER_ORDER_MODEL";

export const RESET_NEW_ADDRESS = "RESET_NEW_ADDRESS";

export const GET_NEW_PRICE = "GET_NEW_PRICE";

export const GET_CHANGE_PRICE_INFO = "GET_CHANGE_PRICE_INFO";

export const GET_UPDATE_ITEM_MODAL = "GET_UPDATE_ITEM_MODAL";

export const GET_CATEGORIES_REQUEST = "GET_CATEGORIES_REQUEST";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_ERROR = "GET_CATEGORIES_ERROR";

export const GET_NEW_PRODUCT_MANUFACTURER_REQUEST =
  "GET_NEW_PRODUCT_MANUFACTURER_REQUEST";
export const GET_NEW_PRODUCT_MANUFACTURER_SUCCESS =
  "GET_NEW_PRODUCT_MANUFACTURER_SUCCESS";
export const GET_NEW_PRODUCT_MANUFACTURER_ERROR =
  "GET_NEW_PRODUCT_MANUFACTURER_ERROR";

export const GET_NEW_PRODUCT_VENDOR_REQUEST = "GET_NEW_PRODUCT_VENDOR_REQUEST";
export const GET_NEW_PRODUCT_VENDOR_SUCCESS = "GET_NEW_PRODUCT_VENDOR_SUCCESS";
export const GET_NEW_PRODUCT_VENDOR_ERROR = "GET_NEW_PRODUCT_VENDOR_ERROR";

export const GET_NEW_PRODUCT_GROUP_REQUEST = "GET_NEW_PRODUCT_GROUP_REQUEST";
export const GET_NEW_PRODUCT_GROUP_SUCCESS = "GET_NEW_PRODUCT_GROUP_SUCCESS";
export const GET_NEW_PRODUCT_GROUP_ERROR = "GET_NEW_PRODUCT_GROUP_ERROR";

// Start Adjust Stock api
//Get Rows
export const GET_ROWS_ACTIVE_START = "GET_ROWS_ACTIVE_START";
export const GET_ROWS_ACTIVE_SUCCESS = "GET_ROWS_ACTIVE_SUCCESS";
export const GET_ROWS_ACTIVE_ERROR = "GET_ROWS_ACTIVE_ERROR";
export const GET_ROWS_ACTIVE_CLEAR = "GET_ROWS_ACTIVE_CLEAR";
//Get Units
export const GET_UNIT_ACTIVE_START = "GET_UNIT_ACTIVE_START";
export const GET_UNIT_ACTIVE_SUCCESS = "GET_UNIT_ACTIVE_SUCCESS";
export const GET_UNIT_ACTIVE_ERROR = "GET_UNIT_ACTIVE_ERROR";
export const GET_UNIT_ACTIVE_CLEAR = "GET_UNIT_ACTIVE_CLEAR";
// Get Shelf
export const GET_SHELF_START = "GET_SHELF_START";
export const GET_SHELF_SUCCESS = "GET_SHELF_SUCCESS";
export const GET_SHELF_ERROR = "GET_SHELF_ERROR";
export const GET_SHELF_CLEAR = "GET_SHELF_CLEAR";
// Stock In
export const STOCK_IN_START = "STOCK_IN_START";
export const STOCK_IN_SUCCESS = "STOCK_IN_SUCCESS";
export const STOCK_IN_ERROR = "STOCK_IN_ERROR";
export const STOCK_IN_CLEAR = "STOCK_IN_CLEAR";
// Stock Out
export const STOCK_OUT_START = "STOCK_OUT_START";
export const STOCK_OUT_SUCCESS = "STOCK_OUT_SUCCESS";
export const STOCK_OUT_ERROR = "STOCK_OUT_ERROR";
export const STOCK_OUT_CLEAR = "STOCK_OUT_CLEAR";
// Stock Move
export const STOCK_MOVE_START = "STOCK_MOVE_START";
export const STOCK_MOVE_SUCCESS = "STOCK_MOVE_SUCCESS";
export const STOCK_MOVE_ERROR = "STOCK_MOVE_ERROR";
export const STOCK_MOVE_CLEAR = "STOCK_MOVE_CLEAR";
export const DISABLE_ADJUST_STOCK_TAB = "DISABLE_ADJUST_STOCK_TAB";
// End Adjust Stock api

// Start Item Ledger Pagination
export const ITEM_LEDGER_START = "ITEM_LEDGER_START";
export const ITEM_LEDGER_SUCCESS = "ITEM_LEDGER_SUCCESS";
export const ITEM_LEDGER_ERROR = "ITEM_LEDGER_ERROR";
export const ITEM_LEDGER_CLEAR = "ITEM_LEDGER_CLEAR";
// End Item Ledger

// Start From location product
export const FROM_LOCATION_PRODUCT_START = "FROM_LOCATION_PRODUCT_START";
export const FROM_LOCATION_PRODUCT_SUCCESS = "FROM_LOCATION_PRODUCT_SUCCESS";
export const FROM_LOCATION_PRODUCT_ERROR = "FROM_LOCATION_PRODUCT_ERROR";
export const FROM_LOCATION_PRODUCT_CLEAR = "FROM_LOCATION_PRODUCT_CLEAR";

// Start From location Row
export const FROM_LOCATION_ROW_START = "FROM_LOCATION_ROW_START";
export const FROM_LOCATION_ROW_SUCCESS = "FROM_LOCATION_ROW_SUCCESS";
export const FROM_LOCATION_ROW_ERROR = "FROM_LOCATION_ROW_ERROR";
export const FROM_LOCATION_ROW_CLEAR = "FROM_LOCATION_ROW_CLEAR";
// End From location Row

// Start From location Unit
export const FROM_LOCATION_UNIT_START = "FROM_LOCATION_UNIT_START";
export const FROM_LOCATION_UNIT_SUCCESS = "FROM_LOCATION_UNIT_SUCCESS";
export const FROM_LOCATION_UNIT_ERROR = "FROM_LOCATION_UNIT_ERROR";
export const FROM_LOCATION_UNIT_CLEAR = "FROM_LOCATION_UNIT_CLEAR";
// End From location Unit

// Start From location Shelf
export const FROM_LOCATION_SHELF_START = "FROM_LOCATION_SHELF_START";
export const FROM_LOCATION_SHELF_SUCCESS = "FROM_LOCATION_SHELF_SUCCESS";
export const FROM_LOCATION_SHELF_ERROR = "FROM_LOCATION_SHELF_ERROR";
export const FROM_LOCATION_SHELF_CLEAR = "FROM_LOCATION_SHELF_CLEAR";
// End From location Shelf

export const DOCUMENT_TYPES_REQUEST = "DOCUMENT_TYPES_REQUEST";
export const DOCUMENT_TYPES_SUCCESS = "DOCUMENT_TYPES_SUCCESS";
export const DOCUMENT_TYPES_ERROR = "DOCUMENT_TYPES_ERROR";


export const SEARCH_RETURN_CUSTOMERS_START = "SEARCH_RETURN_CUSTOMERS_START";
export const SEARCH_RETURN_CUSTOMERS_SUCCESS =
  "SEARCH_RETURN_CUSTOMERS_SUCCESS";
export const SEARCH_RETURN_CUSTOMERS_ERROR = "SEARCH_RETURN_CUSTOMERS_ERROR";

export const SEARCH_RETURN_PRODUCTS_START = "SEARCH_RETURN_PRODUCTS_START";
export const SEARCH_RETURN_PRODUCTS_SUCCESS = "SEARCH_RETURN_PRODUCTS_SUCCESS";
export const SEARCH_RETURN_PRODUCTS_ERROR = "SEARCH_RETURN_PRODUCTS_ERROR";
export const SEARCH_RETURN_PRODUCTS_CLEAR = "SEARCH_RETURN_PRODUCTS_CLEAR";


//  new cart page changes 
export const SET_CHANGE_PRICE = "SET_CHANGE_PRICE";
export const SET_INITIAL_CUSTOM_PRICE = "SET_INITIAL_CUSTOM_PRICE";

export const SET_ADJUST_STOCK_UPDATE_PRODUCT = "SET_ADJUST_STOCK_UPDATE_PRODUCT"

export const DISABLE_ENTER_ADD_TO_CART = "DISABLE_ENTER_ADD_TO_CART";
export const ADD_TO_CART_DRAWER = "ADD_TO_CART_DRAWER";
export const GET_TAB_SHIPPING = "GET_TAB_SHIPPING"
export const GET_PRODUCT_INDEX = "GET_PRODUCT_INDEX"
export const SET_CANCEL_PRICE = "SET_CANCEL_PRICE";

export const HEADER_HEIGHT_COUNT = "HEADER_HEIGHT_COUNT";

export const SET_ORDER_INSTRUCTION_NOTE = "SET_ORDER_INSTRUCTION_NOTE";
export const SET_QUOTE_EXPIRY_DATE = "SET_QUOTE_EXPIRY_DATE";
export const SET_SHIPPING_INSTRUCTION_NOTE = "SET_SHIPPING_INSTRUCTION_NOTE";
export const SET_TEMPORARY_LOCATION_INSTRUCTION_NOTE = "SET_TEMPORARY_LOCATION_INSTRUCTION_NOTE";

export const GET_ALL_ROWS_START = "GET_ALL_ROWS_START";
export const GET_ALL_ROWS_SUCCESS = "GET_ALL_ROWS_SUCCESS";
export const GET_ALL_ROWS_ERROR = "GET_ALL_ROWS_ERROR";

export const GET_SINGLE_ROWS_START = "GET_SINGLE_ROWS_START";
export const GET_SINGLE_ROWS_SUCCESS = "GET_SINGLE_ROWS_SUCCESS";
export const GET_SINGLE_ROWS_ERROR = "GET_SINGLE_ROWS_ERROR";
// Start Item Ledger Detail
export const ITEM_LEDGER_INOUT_START = "ITEM_LEDGER_INOUT_START";
export const ITEM_LEDGER_INOUT_SUCCESS = "ITEM_LEDGER_INOUT_SUCCESS";
export const ITEM_LEDGER_INOUT_ERROR = "ITEM_LEDGER_INOUT_ERROR";
export const ITEM_LEDGER_INOUT_CLEAR = "ITEM_LEDGER_INOUT_CLEAR";
// End Item Ledger

export const SET_MOVE_TAB_DISABLE = "SET_MOVE_TAB_DISABLE";

// start
export const GET_QUOTE_HISTORY_START = "GET_QUOTE_HISTORY_START";
export const GET_QUOTE_HISTORY_SUCCESS = "GET_QUOTE_HISTORY_SUCCESS";
export const GET_QUOTE_HISTORY_ERROR = "GET_QUOTE_HISTORY_ERROR";
// end

// start
export const GET_ITEM_HISTORY_START = "GET_ITEM_HISTORY_START";
export const GET_ITEM_HISTORY_SUCCESS = "GET_ITEM_HISTORY_SUCCESS";
export const GET_ITEM_HISTORY_ERROR = "GET_ITEM_HISTORY_ERROR";
// end

// start
export const GET_PURCHASE_HISTORY_START = "GET_PURCHASE_HISTORY_START";
export const GET_PURCHASE_HISTORY_SUCCESS = "GET_PURCHASE_HISTORY_SUCCESS";
export const GET_PURCHASE_HISTORY_ERROR = "GET_PURCHASE_HISTORY_ERROR";
// end
// start
export const GET_INVOICE_HISTORY_START = "GET_INVOICE_HISTORY_START";
export const GET_INVOICE_HISTORY_SUCCESS = "GET_INVOICE_HISTORY_SUCCESS";
export const GET_INVOICE_HISTORY_ERROR = "GET_INVOICE_HISTORY_ERROR";
// end

export const SET_IS_LOADING = "SET_IS_LOADING";
export const PURCHASE_ORDER_NUMBER_EDIT = "PURCHASE_ORDER_NUMBER_EDIT";

export const SEARCH_DRAFT_START = "SEARCH_DRAFT_START";
export const SEARCH_DRAFT_SUCCESS = "SEARCH_DRAFT_SUCCESS";
export const SEARCH_DRAFT_ERROR = "SEARCH_DRAFT_ERROR";
export const CLEAR_SEARCH_DRAFT = "CLEAR_SEARCH_DRAFT";

export const SET_JOB_NAME = "SET_JOB_NAME";

export const NO_MAIL_START = "NO_MAIL_START";
export const NO_MAIL_SUCCESS = "NO_MAIL_SUCCESS";
export const NO_MAIL_ERROR = "NO_MAIL_ERROR";

export const SET_STORE_CREDIT_AMOUNT = "SET_STORE_CREDIT_AMOUNT";
export const SET_PRICE_CHECK = "SET_PRICE_CHECK";
export const SET_MARK_AS_QUOTE = "SET_MARK_AS_QUOTE";
export const SET_UNPAID_BALANCE = "SET_UNPAID_BALANCE";

export const NEW_PRODUCT_ID = "NEW_PRODUCT_ID"
export const SET_BILLING_DETAILS = "SET_BILLING_DETAILS"
export const RESET_BILLING_DETAILS = "RESET_BILLING_DETAILS"

export const CLEAR_CART_DRAFTS_START = "CLEAR_CART_DRAFTS_START"
export const CLEAR_CART_DRAFTS_SUCCESS = "CLEAR_CART_DRAFTS_SUCCESS"
export const CLEAR_CART_DRAFTS_ERROR = "CLEAR_CART_DRAFTS_ERROR"

export const GET_POS_PRICE_CHANGE_HISTORY_START = "GET_POS_PRICE_CHANGE_HISTORY_START"
export const GET_POS_PRICE_CHANGE_HISTORY_SUCCESS = "GET_POS_PRICE_CHANGE_HISTORY_SUCCESS"
export const GET_POS_PRICE_CHANGE_HISTORY_ERROR = "GET_POS_PRICE_CHANGE_HISTORY_ERROR"

export const GET_MOVING_AVG_COST_HISTORY_START = "GET_MOVING_AVG_COST_HISTORY__START"
export const GET_MOVING_AVG_COST_HISTORY_SUCCESS = "GET_MOVING_AVG_COST_HISTORY__SUCCESS"
export const GET_MOVING_AVG_COST_HISTORY_ERROR = "GET_MOVING_AVG_COST_HISTORY__ERROR"

export const ADD_TO_QTY = "ADD_TO_QTY"
export const ADD_TO_INDEX = "ADD_TO_INDEX"

export const CUSTOMER_REGION_LIST_START = "CUSTOMER_REGION_LIST_START"
export const CUSTOMER_REGION_LIST_SUCCESS = "CUSTOMER_REGION_LIST_SUCCESS"
export const CUSTOMER_REGION_LIST_ERROR = "CUSTOMER_REGION_LIST_ERROR"


export const CART_EMAIL_WITH_TOTALS_START = "CART_EMAIL_WITH_TOTALS_START"
export const CART_EMAIL_WITH_TOTALS_SUCCESS = "CART_EMAIL_WITH_TOTALS_SUCCESS"
export const CART_EMAIL_WITH_TOTALS_ERROR = "CART_EMAIL_WITH_TOTALS_ERROR"
export const CART_EMAIL_WITH_TOTALS_RESET = "CART_EMAIL_WITH_TOTALS_RESET"

export const SET_ORDER_SEARCHING = "SET_ORDER_SEARCHING"

// packing slip
export const PACKING_SLIP_START = "PACKING_SLIP_START"
export const PACKING_SLIP_SUCCESS = "PACKING_SLIP_SUCCESS"
export const PACKING_SLIP_ERROR = "PACKING_SLIP_ERROR"

export const SET_SIGNATURES = "SET_SIGNATURES"
export const CLEAR_SIGNATURES = "CLEAR_SIGNATURES"

export const CLEAR_PACKING_SLIP_DATA = "CLEAR_PACKING_SLIP_DATA"