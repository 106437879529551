import { StatusInterface } from "./types";

export const productStatusOptions = [
  //Do not change the id 1 of status "Active"
  {
    id: 1,
    status: "Active",
    label: "Active",
    subLabel: "Can be sold everywhere"
  },
  {
    id: 2,
    status: "Pending",
    label: "Pending",
    subLabel: "Can be sold everywhere except Trampoline",
  },
  {
    id: 0,
    status: "Inactive",
    label: "Inactive",
    subLabel: "Cannot be sold"
  },
];

export const productStatusNoInactiveOptions = [
  //Do not change the id 1 of status "Active"
  {
    id: 1,
    status: "Active",
    label: "Active",
    subLabel: "Can be sold everywhere"
  },
  {
    id: 2,
    status: "Pending",
    label: "Pending",
    subLabel: "Can be sold everywhere except Trampoline",
  }
];

export const productStatusDefaultOptions: StatusInterface = {
  id: 0,
  status: "Inactive",
  label: "Inactive",
  subLabel: "Cannot be sold",
};

export const labels = {
  productSearchDetail: "Details, Characteristics, Prices, Accessories...",
  setAsClearance: "Set as Clearance",
  setAsDiscontinued: "Set as Discontinued",
  cloneAsPending: "Clone as Pending",
  vendorPriceLabel: "Vendor Price",
  wholeSalePriceLabel: "Wholesale Price",
  venderPriceId: 3,
  wholeSalePriceId: 1,
  notSet: "Not Set",
  id: "ID",
  code: "Code",
  edit: "Edit",
  name: "Name",
  description: "Description",
  status: "Status",
  manufacturer: "Manufacturer",
  vendor: "Vendor",
  group: "Group",
  noPriceSchedule: "No pricing schedule set",
  manageSchedules: "Manage Schedules",
  category: "Category",
  availability: "Availability",
  daysToShip: "Days to ship",
  addedOn: "Added on",
  lastUpdated: "Last updated",
  LastUpdated: "LastUpdated",
  addOn: "addOn",
  daystoship: "daystoship",
  availabiliti: "availability",
  categories: "category",
  groups: "group",
  manufact: "manufact",
  statuses: "status",
  descriptions: "description",
  names: "name",
  codes: "code",
  iD: "id",
  Starred: "Starred",
  Overview: "Overview",
  Details: "Details",
  Measurements: "Measurements",
  CostsPrices: "Costs & Prices",
  MetadataFlags: "Metadata & Flags",
  Reports: "Reports",
  RetailPriceSchedules: "Retail Price Schedules",
  ViewAll: "View All",
  Accessories: "Accessories",
  Characteristics: "Characteristics",
  Add: "Add",
  Attach: "Attach",
  Attached: "ATTACHED",
  Detach: "DETACH",
  DetachAll: "DETACH ALL",
  Manage: "Manage",
  Notes: "Notes",
  Coupons: "Coupons",
  ImagesDocs: "Images & Docs",
  Identifiers: "Identifiers",
  UPC: "UPC (Universal Product Code)",
  EAN: "EAN (European Article Number)",
  GTIN: "GTIN (Global Trade Item Number)",
  GCID: "GCID (Global Catalog ID)",
  ASIN: "ASIN (Amazon Standard Identification Number)",
  Asin: "asin",
  Gcid: "gcid",
  Gtin: "gtin",
  Ean: "ean",
  Upc: "upc",
  canBeDrop: "canBeDrop",
  isMerquery: "isMerquery",
  hidePrice: "hidePrice",
  hideItem: "hideItem",
  Virtual: "Virtual",
  Hyperlink: "Hyperlink",
  Keywords: "Keywords",
  Footnote: "Footnote",
  FolderPath: "FolderPath",
  Folder_Path: "Folder Path",
  SEO: "SEO",
  Yes: "Yes",
  No: "No",
  ClearanceEmailBlast: "Clearance Email Blast",
  HidefromAmazonFeed: "Hide from Amazon Feed",
  Hidepriceandpreventonlineordering: "Hide price and prevent online ordering",
  ContainsMercury: "Contains Mercury",
  CanbeDropShipped: "Can be Drop Shipped",
  Flags: "Flags",
  Back: "Back",
  SaveChanges: "Save Changes",
  readonly: "(Read only)",
  Upload: "Upload",
  imageText: "Use the exact (case sensitive) file name and path. e.g. prodimages/tcp/43245.jpg",
  DefaultLargeImage: "Default Large Image",
  DefaultThumbnail: "Default Thumbnail",
  DOCS: "DOCS",
  PIB: "PIB",
  PSDS: "PSDS",
  Logs: "Logs",
  replaces: "Replaces",
  goesWith: "Goes with",
  claimBack: "Claim Back",
  hotBuy: "Hot Buy",
  list: "List",
  Clearance: "Clearance",
  Discontinued: "Discontinued",
  appliestoPosonly: "Applies to POS only",
  Override: "Override",
  priceEach: "Price/Each",
  priceCase: "Price/Case",
  appliestoTrampolineonly: "Applies to Trampoline only",
  seeHistory: "See History",
  notAdded: "Not added",
  document: "document",
  retailMessage: "Changes will be applied to all products that belong to the selected group for this vendor.",
  describe: "DESCRIPTION",
  basePriceType: "BASE PRICE TYPE",
  markupPercentage: "Markup Multiplier",
  breakQty: "BREAK QTY.",
  addCharacteristic: "Add a Characteristic…",
  tickets: "Tickets"
}

export const productDetailErrorMessage = {
  vendorPriceMin: "Vendor price must be more than manufacturer price",
  stockPriceMax: "Stock price must not be more than vender price",
  salesPriceMin: "Sales Price must be less than override price",
  overridePriceMin: "Override Price must be greater than sales price",

}

export const sectionName = {
  overview: "overview",
  Overview: "Overview",
  StarredOption: "Starred",
  StarredSection: "StarredSection",
  OverviewOption: "Overview",
  OverviewMainOption: "OverviewMain",
  details: 'details',
  DetailsSection: "DetailsSection",
  DetailsOption: "Details",
  MeasurementsSection: "MeasurementsSection",
  costs: "costs",
  CostOption: "Costs & Prices",
  CostSection: "CostSection",
  MetadataOption: "Metadata",
  MetadataSection: "MetadataSection",
  metaData: "metaData",
  ReportsOption: "Reports",
  ReportsSection: "ReportsSection",
  reports: "reports",
  ImagesOption: "Images",
  images: "images",
  ImagesSection: "ImagesSection",
  AccessoriesOption: "Accessories",
  accessories: "accessories",
  AccessoriesSection: "AccessoriesSection",
  characteristics: "characteristics",
  CharacteristicsOption: "Characteristics",
  CharacteristicsSection: "CharacteristicsSection",
  NotesOption: "Notes",
  notes: "notes",
  NotesSection: "NotesSection",
  CouponsOption: "Coupons",
  coupons: "coupons",
  CouponsSection: "CouponsSection",
  LogsOption: "Logs",
  LogsSection: "LogsSection",
  RetailPriceSchedulesOption: "Retail Price Schedules",
  RetailPriceSchedulesSection: "RetailPriceSchedulesSection"
}

export const retailPriceLabel = {
  name: "Name",
  breakQty: "Break Qty.",
  markup: "Markup Multiplier",
  basePrice: "Base Price Type",
  description: "Description",
  more: "more",
  viewAllSchedules: "View all Schedules",
  message: " Pricing schedule is not set for this item.",
  starredStart: "Add frequently used items here for easy use. Click ",
  starredEnd: " on an item to add.",
  errorMessage: "Can’t find anything for",
  prodBigImg: "prodimages/",
  prodThumImg: "prodthumb/",
  accessoryPlaceholder: "Add a Product...",
  searchingMessage: "Searching…",
  couponsPlaceholder: "Attach to a coupon…",
}

export const vpgComputePriceTypeOptions = [
  {
    vpgComputePriceName: "Last Cost",
    vpgComputePriceType: "PRODUCT_VENDOR_PRICE"
  },
  {
    vpgComputePriceName: "Moving Avg Cost",
    vpgComputePriceType: "MOVING_AVG_COST",
  },
  {
    vpgComputePriceName: "Wholesale Price",
    vpgComputePriceType: "PRODUCT_WHOLESALE_PRICE",
  }
];

export const notActiveCouponStatus = "Not Active"

export const productModalNames = {
  productNotesModalConst: "productNotesModal",
  productCouponsModalConst: "productCouponsModal",
  productCharacteristicsModalConst: "productCharacteristicsModal",
  productDetailsAccessoriesModalConst: "productDetailsAccessoriesModal",
  productDetailsImagesModalConst: "productDetailsImagesModal",
  productDetailsMetadataModalConst: "productDetailsMetadataModal",
  productDetailsMetadataFlagesModalConst: "productDetailsMetadataFlagesModal",
  productDetailsMetadataSeoModalConst: "productDetailsMetadataSeoModal",
  productDetailsMeasurementModalConst: " productDetailsMeasurementModal",
  productDetailsCostsModalConst: "productDetailsCostsModal",
  productDetailsOverviewModalConst: "productDetailsOverviewModal",
  productOverviewModalConst: "productOverviewModal",
  productLogModalConst: "productLogModal",
  retailListModelConst: "retailListModel",
  productOverviewConst: "productOverview"
}