export const SET_POS_FLOW_DATA = "SET_POS_FLOW_DATA";
export const RESET_POS_FLOW_DATA = "RESET_POS_FLOW_DATA";
export const LOAD_CART_SUCCESS = "LOAD_CART_SUCCESS";
export const LOAD_CART_ERROR = "LOAD_CART_ERROR";
export const LOAD_CART_START = "LOAD_CART_START";
export const CLEAR_LOAD_CART_SUCCESS_FLAG = "CLEAR_LOAD_CART_SUCCESS_FLAG";
export const RESET_LOAD_CART = "RESET_LOAD_CART";
export const SET_PRIVACY = "SET_PRIVACY";
export const SET_EXEMPT_TAX = "SET_EXEMPT_TAX";
export const SET_WORK_SPACE = "SET_WORK_SPACE";
export const SET_TO_PICKUP = "SET_TO_PICKUP";
export const IS_TAX_MESSAGE = "IS_TAX_MESSAGE";
export const CLEAR_LOAD_CART = "CLEAR_LOAD_CART";
export const SET_SHIP_TO_ADDRESS = "SET_SHIP_TO_ADDRESS";

export const SUB_TOTAL_AMOUNT = "SUB_TOTAL_AMOUNT";
export const TAX_AMOUNT = "TAX_AMOUNT";
export const SHIPPING_AMOUNT = "SHIPPING_AMOUNT";
export const SHIPPING_AMOUNT_TO_BE_SENT = "SHIPPING_AMOUNT_TO_BE_SENT";
export const SHIPPING_TAX_AMOUNT = "SHIPPING_TAX_AMOUNT";
export const TAKER_ID_TO_BE_SENT = "TAKER_ID_TO_BE_SENT";
export const TAX_RATE_TO_BE_SENT = "TAX_RATE_TO_BE_SENT";

export const SET_TAX_RATE_OF_SHIPPING_ADDRESS =
  "SET_TAX_RATE_OF_SHIPPING_ADDRESS";

export const SET_CUSTOM_TAX_RATE = "SET_CUSTOM_TAX_RATE";
export const SAVE_PRODUCT = "SAVE_PRODUCT"

export const GET_CHANGE_PRICE_INFO = "GET_CHANGE_PRICE_INFO"
export const SHOW_ORIGINAL_PRICE = "SHOW_ORIGINAL_PRICE"
export const SHOW_ALREADY_PICKED_INFO = "SHOW_ALREADY_PICKED_INFO"

export const STORE_BACKUP_PRODUCTS = "STORE_BACKUP_PRODUCTS"

export const EXEMPT_SHIP_TO_LOCATION = "EXEMPT_SHIP_TO_LOCATION"

export const SHIPPING_ADDRESS_SELECTED = "SHIPPING_ADDRESS_SELECTED"
export const CLEAR_CART_BACKUP_PRODUCTS = "CLEAR_CART_BACKUP_PRODUCTS"
export const SET_MODIFIED_PRICE = "SET_MODIFIED_PRICE"
export const RECIPIENT_INFO_SWITCH_INFO = "RECIPIENT_INFO_SWITCH_INFO"

export const PERSON_NOTES = "PERSON_NOTES";

export const SET_RETURN_FLOW_DATA = "SET_RETURN_FLOW_DATA";
export const SHIPPING_FEE_DATA = "SHIPPING_FEE_DATA";
export const RESTOCKING_FEE_DATA = "RESTOCKING_FEE_DATA";
export const RESTOCKING_FEE_REPLACE_DATA = "RESTOCKING_FEE_REPLACE_DATA";
export const HANDLING_FEE_REPLACE_DATA = "HANDLING_FEE_REPLACE_DATA";
export const HANDLING_FEE_DATA = "HANDLING_FEE_DATA";
export const SHIPPING_FEE_CLEAR_DATA = "SHIPPING_FEE_CLEAR_DATA";
export const RESTOCKING_FEE_CLEAR_DATA = "RESTOCKING_FEE_CLEAR_DATA";
export const HANDLING_FEE_CLEAR_DATA = "HANDLING_FEE_CLEAR_DATA";
export const LOAD_RETURN_DATA = "LOAD_RETURN_DATA";
export const LOAD_PRODUCT_DRAFT_ACTION = "LOAD_PRODUCT_DRAFT_ACTION";
export const SET_NO_LOAD_DETAILS = "SET_NO_LOAD_DETAILS";

export const SET_TAB_INDEX = "SET_TAB_INDEX";

export const RETURN_ALL_DATA_ARRAY = "RETURN_ALL_DATA_ARRAY";

export const SET_NEW_CUCTOMER_CODE = "SET_NEW_CUCTOMER_CODE";

export const ADD_NEW_CUSTOMER_INFO_START = "ADD_NEW_CUSTOMER_INFO_START";
export const ADD_NEW_CUSTOMER_INFO_SUCCESS = "ADD_NEW_CUSTOMER_INFO_SUCCESS";
export const ADD_NEW_CUSTOMER_INFO_ERROR = "ADD_NEW_CUSTOMER_INFO_ERROR";
export const ADD_NEW_CUSTOMER_INFO_CLEAR = "ADD_NEW_CUSTOMER_INFO_CLEAR";

export const MANIPULATE_STARRED_PRODUCT_INFO = "MANIPULATE_STARRED_PRODUCT_INFO";
export const CLEAR_STARRED_PRODUCT_INFO = "CLEAR_STARRED_PRODUCT_INFO";

export const SET_PRICE_VISIBLE_BY_DEFAULT = "SET_PRICE_VISIBLE_BY_DEFAULT";
export const SET_STORE_PICKED_ADDRESS = "SET_STORE_PICKED_ADDRESS";

export const CUSTOMER_ACCOUNT_INFO = "CUSTOMER_ACCOUNT_INFO"
export const CLEAR_CUSTOMER_ACCOUNT_INFO = "CLEAR_CUSTOMER_ACCOUNT_INFO"

export const GET_DRAFTS_START = "GET_DRAFTS_START";
export const GET_DRAFTS_SUCCESS = "GET_DRAFTS_SUCCESS";
export const GET_DRAFTS_ERROR = "GET_DRAFTS_ERROR";
export const GET_DRAFTS_RESET = "GET_DRAFTS_RESET";
export const ADD_NEW_CUSTOMER_MODEL = "ADD_NEW_CUSTOMER_MODEL";
export const IS_EMAIL = "IS_EMAIL";

export const IS_GPM_CUSTOM = "IS_GPM_CUSTOM"
export const IS_ORDER_TOTAL_CUSTOM = "IS_ORDER_TOTAL_CUSTOM"

export const SET_ORIGINAL_PERCENTAGE = "SET_ORIGINAL_PERCENTAGE"
export const SET_ORDER_TOTAL = "SET_ORDER_TOTAL"

export const SKIP_ORDERER_INFO = "SKIP_ORDERER_INFO"

export const SET_EXEMPT_TAX_MANUALLY = "SET_EXEMPT_TAX_MANUALLY"

export const SET_CUSTOMER_ALERT_HIDE = "SET_CUSTOMER_ALERT_HIDE"

export const MANIPULATE_STARRED_METADATA_SB = "MANIPULATE_STARRED_METADATA_SB"

export const REFUND_METHOD_CHANGE = "REFUND_METHOD_CHANGE"

export const SET_ALLOW_PICKUP = "SET_ALLOW_PICKUP"

export const UPDATE_CUSTOMER_DATA_MESSAGE = "UPDATE_CUSTOMER_DATA_MESSAGE"

export const OPTIMISTIC_DELETE_DRAFT = "OPTIMISTIC_DELETE_DRAFT"

export const SET_UPDATED_STOCK_DATA_ON_REFRESH_START = "SET_UPDATED_STOCK_DATA_ON_REFRESH_START"
export const SET_UPDATED_STOCK_DATA_ON_REFRESH_ERROR = "SET_UPDATED_STOCK_DATA_ON_REFRESH_ERROR"

export const SET_UPDATED_STOCK_DATA_ON_REFRESH_SUCCESS = "SET_UPDATED_STOCK_DATA_ON_REFRESH_SUCCESS"