export const fifteenNoThreeDigitRegex = /^[1-9](?:\d{1,11})?\.?(?:\d{1,3})?$/;

export const numberRegex = /^\d{0,10}(\.\d{0,2})?$/;

export const letterRegex = /[a-zA-Z]/;

export const twoDecimalRegex = /^\d{0,9}(\.\d{0,2})?$/;

export const threeDecimalRegex = /^\d{0,9}(\.\d{0,3})?$/;

export const profRegex = /^\d{1,2}(\.\d{0,2})?$/;

export const upperCaseRegex = /\b(\w)/g;

export const numbersOnlyRegEx = /^[0-9]+$/;

export const noStartEndSpaceRegEx = /^[a-zA-Z0-9]/;

export const numberOnlyRegex = /[0-9]/;

export const numericOnlyRegex = /[^0-9]/g;

export const phoneRegex = RegExp(
  /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
);
export const fiveDecimalRegex = /^\d{0,9}(\.\d{0,5})?$/;
export const shipChargeRegex = /^0[0-9]*$/;
export const decimalRegEx = /^\d{1,8}(\.\d{0,3})?$/;
export const decimal18RegEx = /^\d{1,15}(\.\d{0,3})?$/;
export const letterCapitalRegex = /^[a-zA-Z][\sa-zA-Z]*/;
export const oneDecimal = /^(\d*\.{0,1}\d{0,2}$)/;
export const customerCodeRegex = /^[a-zA-Z0-9]*$/;
export const marginRateRegex = /^\d{0,1}(\.\d{0,2})?$/;
export const qtyRegEx = /^[1-9]+[0-9]*$/;

export const twoDecimalRegexWithOutRound = /^-?\d+(?:\.\d{0,2})?/;

export const poNumberRegex =
  /^[a-zA-Z0-9 !@#$%^&*()-=_+~`[\]{}|;:'",.<>/?]{1,20}$/i;
export const jobAndReceiptNameRegex =
  /^[a-zA-Z0-9 !@#$%^&*()-=_+~`[\]{}|;:'",.<>/?]{0,100}$/i;
export const fullNameRegex = /^[\w\W]+(?:\s[\w\W]+)?\s[\w\W]+$/;
export const trailingLatterAplaNum = /^[a-zA-Z0-9].*[a-zA-Z0-9]$/;
export const phoneReplaceD = /[^\d+]/g;
export const phoneReplaceOne = /^\+1/;
export const removeSpaceRegex = /\s/g;
export const notAllowZeroRegex = /^[1-9][0-9]*$/;
export const notAllowWithDecimalZeroRegex = /^[1-9][0-9]{0,8}(\.\d{1,2})?$/;
export const numbersWithDecimalRegEx = /^\d*\.?\d*$/;

export const title = {
  returnQtyTitle: "Return Qty. (Return for Credit)",
  replaceQtyTitle: "Replace Qty. (Replacement Only)",
  additionalQtyTitle: "Additional Qty. to Order",
  manufacturerPolicy: "Manufacturer Return Policy",
  reasonTitle: "Reason",
  returnToTitle: "Return To",
  returnableQtyTitle: "RETURNABLE",
  totalReturnableQtyTitle: "TOTAL CREDIT QTY.",
  totalReplacementQuantity: "TOTAL REPLACEMENT QTY.",
  inStorage: "IN STORAGE",
  availableQtyTitle: "AVL. IN STOCK",
  equalPickQty: "Picked quantity should be less than replace quantity",
  equalReplacePickQty: "Total Picked Qty cannot be more than total Replace Qty",
  noMorePickedQty: `Same product's picked quantity total should be less than available location's quantity`,
  noPickingData: "No Picking Info Available",
  lessPickedThanOrder: "Picked quantity can not be more than ordered quantity",
  equalPickedToOrder:
    "Picked quantity can not be more or less than ordered quantity",
  sameLocationQty:
    "Total Picked Qty across one or more lines of the same product should not be exceed Total Available Qty in the selected location",
  savedPickingInfo: "Picking info. saved",
  searchInvoice: "Search Orders, Invoices, Customers",
  ReturnProduct: "Product to Return",
  searchReturnProduct: "Search product to return",
  noDataFound: "No data found",
  noRecordsAvailable: "No records available",
  noDraftsSaved: "No drafts found for ",
  noOrdersFoundEmptyState: "No orders placed",
  noOrdersFound: "No orders found",
  noPendingShipmentsFoundEmptyState: "Stored shipments will show up here",
  noPendingShipmentsFound: "No pending shipments found",
  noDeliveredShipmentsFoundEmptyState:
    "Delivered shipments from storage will show up here",
  noDeliveredShipmentsFound: "No delivered shipments found",
  documentStep: "Step 2: Documents",
  attachDocument: "Attach documents for this customer.",
  completeRegistration: "Complete Registration",
  print: "Print",
  availableStockLocation: "Available Stock in All Locations",
  allocatedStockInAllDrafts: "Allocated Stock in All Draft Orders",
  putInStorageTitle: "Put in Storage",
  quantity: "Quantity",
  estDeliveryDate: "Est. Delivery Date",
  setPickingInfo: "Set Picking info.",
  location: "LOCATION",
  storageDesk: "Storage Desk",
  reason: "REASON",
  addNewCustomer: "Add a New Customer",
  registeredAccountInfo:
    "Registered accounts will undergo rigorous background checks and credit references. They must meet Accounts department’s guidelines and approval before they can place orders.",
  approvalEmail:
    "An email was just sent for approval. Once approved, this account will be available for new orders.",
  newRegisteredAccount: "New Registered Account",
  registeredAccount: "Account registered",
  awaitingApproval: "Awaiting approval",
  firstDetailStep: "Step 1: Details",
  date: "DATE",
  id: "ID",
  items: "ITEMS",
  orderSubtotal: "Order Subtotal",
  shippingAndHandling: "Shipping and handling",
  tax: "Tax",
  creditFromPastOrders: "Store Credit",
  totalPaymentDue: "Total Payment Due",
  skipForNow: "Skip for now",
  includePendingDues: "Include pending dues",
  continueToPayment: "Continue to Payment",
  includeCredit: "Use Store Credit",
  changeCustomer: "Change Customer",
  selectCustomer: "Select a Customer",
  setStorageDetail: "Set Storage details",
  grossProfitMargin: "Gross Profit Margin",
  resetButton: "Reset",
  applyMargin: "Apply Margin",
  orderTotalTitel: "Order Total",
  saveChanges: "Save Changes",
  orderNotesTitle: "ORDER NOTES",
  shippingNotesTitle: "SHIPPING NOTES",
  wholeSalePrice: "Wholesale Price",
  mustBeLessThan: "Must be less than",
  POLabel: "PO #",
  selectCashDrawer: "Select Cash Drawer",
  tenderSuggestionTitle: "Set tender amount to $",
  modalTitleGPM: "Set Custom Gross Profit Margin",
  confirmation: "Confirmation",
  overShipmentQty: "Over Shipment QTY.",
  estDeliveryDateCapital: "EST. DELIVERY DATE",
};

export const fileExtension = {
  png: "png",
  pdf: "pdf",
  jpeg: "jpeg",
  jpg: "jpg",
  gif: "gif",
  svg: "svg",
  doc: "doc",
  txt: "txt",
  html: "html",
  docx: "docx",
  psd: "psd",
  log: "log",
  odt: "odt",
  csv: "csv",
};

export const fileType = {
  pdf: "Portable Document Format",
  png: "Portable Network Graphic",
  jpeg: "Joint Photographic Experts Group",
  jpg: "Joint Photographic Experts Group",
  gif: "Graphics Interchange Format",
  svg: "Scalable Vector Graphics",
  doc: "Microsoft Word document",
  docx: "Microsoft Word document",
  txt: "Plain Text File",
  html: "Hypertext Markup Language File",
  psd: "Adobe Photoshop Document",
  log: "Log File",
  odt: "OpenDocument Text Document",
  csv: "Comma Separated Values File",
};

export const dialogAnimVariants = {
  initial: {
    opacity: 0,
    y: 10,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.2,
      ease: "circOut",
    },
  },
  exit: {
    opacity: 0,
    y: 10,
    transition: {
      duration: 0.2,
      ease: "backOut",
    },
  },
};

export const adminTitles = {
  adminUser: "Admin Users",
  admin: "Admin",
  adminInvite: "Invited User",
  adminReview: "Awaiting Approval Users",
};

export const sidenavState = {
  collapse: "collapsed",
  expand: "expanded",
};

export const errorMessageRoute =
  "Route is missing or invalid (Parameter 'linkRoute')";
export const pageUrlRegex = /^(http|https):\/\/.*$/;
export const alphanumericWithoutSpaceRegex = /^[a-zA-Z0-9]{1,}$/;
export const desiredPattern = /^\/app\/pos\/cart\/(.*)$/;
export const tabNamesList = {
  orders: "Orders",
  tickets: "Tickets",
  preOrders: "Pre-Orders",
  products: "Products",
  quotes: "Quotes",
  buyers: "Buyers",
  projects: "Projects",
  orderRmas: "Order RMAs",
  vendorRgas: "Vendor RGAs",
  invoices: "Invoices",
  portalUsers: "Users",
};

export const searchApiUrlSuffixes = {
  orders: "/orders",
  tickets: "/tickets",
  preOrders: "/preorders",
  products: "/products",
  quotes: "/quotes/all",
  buyers: "/buyers",
  projects: "/projects",
  orderRmas: "/rmas",
  vendorRgas: "/rgas",
  invoices: "/invoices",
  portalUsers: "/users/portal",
};

export const MAX_RECENT_SEARCH_COUNT = 5;

export const COUNTRY_CODE = "+1";

export const returnFailedFlag = false;

export const FKEYS = {
  0: "F1",
  1: "F2",
  2: "F3",
  3: "F4",
  4: "F5",
  5: "F6",
  6: "F7",
  7: "F8",
  8: "F9",
  9: "F10",
};

export const storageDataOptions = [
  {
    title: "Order ID",
    firstData: "12983",
  },
  {
    title: "Put-in Date",
    firstData: "05/19/23",
    secondData: "2 days ago",
  },
  {
    title: "Est. Delivery Date",
    firstData: "05/22/23",
    secondData: "In 3 days",
  },
  {
    title: "Reason",
    firstData: "Site closed due to COVID",
  },
  {
    title: "Customer PO",
    firstData: "19782345234",
  },
  {
    title: "Job Name",
    firstData: "Revised Edition",
  },
  {
    title: "Location",
    firstData: "Storage Desk",
  },
  {
    title: "Value of Remaining Items",
    firstData: "$122.59",
  },
  {
    title: "Ship Address",
    firstData: "92-92 Rockaway Beach Blvd, Queens, New York 11693",
  },
];

export const createData = (
  date,
  id,
  po,
  jobName,
  mode,
  status,
  shipments,
  paidVia,
  total
) => {
  return {
    date,
    id,
    po,
    jobName,
    mode,
    status,
    shipments,
    paidVia,
    total,
  };
};

export const rows = [
  createData(
    "05/19/23",
    12983,
    19782345,
    "Revised Edition",
    "Pickup",
    "Delivered",
    4,
    "Cash",
    "$650.25"
  ),
  createData(
    "05/19/23",
    12983,
    19782345,
    "Raymond Kroc Shopping List",
    "Pickup",
    "Delivered",
    4,
    "Cash",
    "$650.25"
  ),
  createData(
    "05/19/23",
    12983,
    19782345,
    "Revised Edition",
    "Pickup",
    "Delivered",
    4,
    "Cash",
    "$650.25"
  ),
  createData(
    "05/19/23",
    12983,
    19782345,
    "Revised Edition",
    "Pickup",
    "Delivered",
    4,
    "Cash",
    "$650.25"
  ),
  createData(
    "05/19/23",
    12983,
    19782345,
    "Revised Edition",
    "Pickup",
    "Delivered",
    4,
    "Cash",
    "$650.25"
  ),
  createData(
    "05/19/23",
    12983,
    19782345,
    "Revised Edition",
    "Pickup",
    "Delivered",
    4,
    "Cash",
    "$650.25"
  ),
];

export const sectionTitle = {
  addTitle: "Add items",
  tenderAmountTitle: "Tender Amount",
  returnChangeTitle: "Return Change",
  noReturnChange: "No change to return",
  whatToPrint: "What would you like to print?",
  resumeLastDraft: "Resume last draft",
  fixedLink: "goodmart.com/products/",
  code: "Code",
  name: "Name",
  descriptionTitle: "Description",
  status: "Status",
  manufacturer: "Manufacturer",
  vendor: "Vendor",
  starred: "Starred",
  toBeUploaded: "TO BE UPLOADED",
  Category: "Category",
  Availability: "Availability",
  DaysToShip: "Days to ship",
};

export const buttonTitle = {
  remove: "Remove",
  change: "Change",
  upload: "Upload",
  active: "Active",
};

export const staticData = {
  storeItemForCustomer: "Store this item for the customer to pick up later.",
  noMoreThanPickQty: "Cannot be more than picked qty.",
  storageAppliesToAll: "This applies to all items in storage for this order.",
  noItem: "No",
  itemInStorage: "Item in Storage",
  removeAll: "Remove All",
  notSet: "Not set",
  inStorage: "in Storage",
  storageExpanded: "expand",
  storageCollapse: "collapse",
  taxApplicable: "Applicable tax amounts will",
  taxReturns: "be included with all returns",
  loading: "Loading ...",
  noRecordFound: "No record found",
  noDocumentsFound: "No Documents Found",
  couponsLoading: "Loading available coupons…",
  noActiveCoupons: "No active coupons available to add",
  documentSearchPlaceholder: "Search documents",
  draftSearchPlaceholder: "Search drafts",
  orderSearchPlaceholder: "Search orders",
  pendingShipmentSearchPlaceholder: "Search pending shipments",
  deliveredShipmentSearchPlaceholder: "Search delivered shipments",
  shipmentsSearchPlaceholder: "Search shipments",
  canNotUndoAction: "You cannot undo this action.",
};

export const alertMessage = {
  confirmDeleteForCharacteristics:
    "Are you sure you want to delete this characteristics? This action cannot be undone.",
  confirmDeleteForAccessories:
    "Are you sure you want to delete this accessory? This action cannot be undone.",
  confirmDeleteForCoupon:
    "Are you sure you want to delete this coupon? This action cannot be undone.",
  characteristicAlreadyExits: "This Characteristic is already added",
  couponAlreadyExits: "This coupon is already added",
  characteristicValue: "Please enter a value",
  accessoriesAlreadyExits: "This accessories is already added",
  accessoriesLimitExits: "More than 12 accessories can be added",
  characteristicLimitExits: "More than 20 characteristic can be added",
  confirmDeleteForClearance: "Are you sure you want to remove Clearance Price?",
  confirmDeleteForDiscontinued:
    "Are you sure you want to remove Discontinued Price?",
  successRemoveClearance: "Clearance Price removed",
  successRemoveDiscontinued: "Discontinued Price removed",
  returnProductErrorMessage:
    "This item has some quantity put in storage. Deliver them to make a return.",
  confirmRemovePriceSchedule: "Are you sure you want to delete",
  canNotBeUndone: "This action cannot be undone.",
  successRemovePriceSchedule: "Price Schedule removed",
  batchAddedSuccess: "Added to batch",
  batchAddError: "Failed adding to batch. Please try again",
  tryBitLater: "Please try again in a bit",
  tryAgain: "Please try again after",
  minutes: "minutes",
  stockMinMaxRemoved: "Stock Min/Max removed",
  deleteDraftConfirmationOne: "Are you sure you want to delete draft",
  deleteDraftConfirmationTwo: "from saved drafts?",
  deleteAllDraftConfirmation:
    "Are you sure you want to delete all saved drafts?",
  reasonIsRquired: "Reason is required",
  quoteStatusChangeConfirmation: "Are you sure you want to set this quote as",
};

export const commonClassName = {
  customerTableRowDataStyling: "customer-table-row-data-styling",
  customerInfoTableTitle: "customer-info-table-title",
};

export const addressProofTypeId = 49;

export const geneCustomerOrderLimit = 50;
export const couponListItemLimit = 500;
export const fiveWarehouseID = 5;
export const cardNumberValidation = [
  "0000",
  "1111",
  "2222",
  "3333",
  "4444",
  "5555",
  "6666",
  "7777",
  "8888",
  "9999",
  "1234",
  "2345",
  "3456",
  "4567",
  "5678",
  "6789",
  "7890",
  "0987",
  "9876",
  "8765",
  "7654",
  "6543",
  "5432",
  "4321",
];

export const rotationCSS = {
  animation: "spin 2s linear infinite",
  "@keyframes spin": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
};

export const snackbarDetail = {
  resumeDraft: "Resuming draft",
  clearCart: "Clear Cart",
  savedDraft: "Saved draft deleted",
  priceSchedule: "Pricing Schedule changed",
  taxExamptMessage: "Cart set as Tax exempt",
  uploadDoc: "Document uploaded",
  takerChange: "Taker changed for this cart",
  salePersonChange: "Salesperson changed for this cart",
  quoteSent: "Quote sent"
};

export const handleFocus = (event) => event.target.select();

export const storageItem = {
  location: "location",
  estDeliveryDate: "estDeliveryDate",
  reason: "reason",
};

export const backOrderConfirmationModalConstants = {
  item: "item",
  items: "items",
  backorder: "backorder",
  backorders: "backorders",
  Backorder: "Backorder",
  continueWith: "Continue with",
  has: "has",
  have: "have",
  messageBodyMiddleText:
    "enough quantity available to be fulfilled. Are you sure you want to pick less than available? This will place",
  backordersForThem: "backorders for them.",
  backorderForIt: "backorder for it.",
};

export const coinDenominations = [0.01, 0.05, 0.1, 0.25, 0.5, 1, 2, 5];
export const coinValues = [
  {
    value: 0.01,
    name: "1c",
  },
  {
    value: 0.05,
    name: "5c",
  },
  {
    value: 0.1,
    name: "10c",
  },
  {
    value: 0.25,
    name: "25c",
  },
  {
    value: 0.5,
    name: "50c",
  },
  {
    value: 1,
    name: "1",
  },
  {
    value: 2,
    name: "2",
  },
  {
    value: 5,
    name: "5",
  },
];

export const staticTenderValue = "0.00";

export const returnDraftOrderNoteTitle =
  "Draft orders are created for items with additional quantity requested";
export const returnDraftOrderNoteBodyLine1 =
  "Note the Draft Order IDs so you can select the customer on POS";
export const returnDraftOrderNoteBodyLine2 =
  "and use the Draft Order ID to find and process them.";
export const wholesaleMarginNote =
  "Margin % calculated based on vendor price";
export const overrideMarginMote =
  "Margin % calculated based on vendor price";
export const ZERO_NUMBER = 0;
export const colors = {
  borderColor: "#DDDDDD",
  coralRed: "#FF4141",
  giantsOrange: "#FF5722",
  mikadoYellow: "#FFC107",
  ultramarineBlue: "#3161FF",
  UfoGreen: "#34C759",
};

export const statusDetail: any = {
  0: {
    value: "Expired and already sent",
    color: "#DDDDDD",
  },
  1: {
    value: "Newly created",
    color: "#34C759",
  },
  2: {
    value: "Quote killed",
    color: "bg-gray-700",
  },
  3: {
    value: "Deleted",
    color: "bg-gray-700",
  },
  4: {
    value: "Order Placed",
    color: "bg-gray-700",
  },
  5: {
    value: "Quote request sent",
    color: "#3161FF",
  },
  6: {
    value: "Dead(killed)",
    color: "#3161FF",
  },
  7: {
    value: "Dead(no-quote)",
    color: "#3161FF",
  },
};

export const springBoardText = {
  description: "Description",
  documentType: "Document type",
  Change: "Change",
  browse: "browse",
  dropFileHere: "Drop file here or",
  cancelUploadText: "Cancel upload?",
  documentUpload: "Document uploaded",
  addDocument: "Add Document",
  sendMessageToCustomer: "Send Message to Customer",
  toCustomerEmail: "To (Customer email)",
  Subject: "Subject",
  Message: "Message",
  dueDateChanged: "Due date revised",
  closeDateChanged: "Close date changed",
  rep: "Rep",
  noRep: "No Reps assigned",
  item: "item",
  noItem: "No items added",
  document: "Document",
  noDocument: "No Documents added",
  newMessage: "New Message",
  message: "Message",
  messages: "Messages",
  noMessageSent: "No Messages sent",
  sortBy: "Sort by:",
  newestFirst: "Newest first",
  sent: "sent",
  rfi: "RFI",
  sendRFI: "Send RFI",
  noRFISent: "No RFI sent",
  sents: "Sent",
  initialNotes: "Initial Notes",
  estimatorNotes: "Estimator Notes",
  majorReps: "Major Reps",
  jobHighlights: "Job Highlights",
  itemActionNotes: "Item Action Notes",
  addNewNote: "Start typing to add a new note...",
  cancel: "Cancel",
  save: "Save",
  subQuotes: "Subquotes",
  subQuote: "SUBQUOTE",
  tickets: "TICKETS",
  addSubQuote: "Add Subquote",
  confirmDialog: "You have unsaved changes. Close without saving?",
  customerNoteText: "The customer can see this",
  parentQuoteTitle: "Parent quote",
  everyone: "Everyone",
  changeEstimator: "Change Estimator",
  newEstimator: "New Estimator",
  estimatorRequired: "Estimator is required",
  currentEstimator: "Current Estimator",
  assignedOn: "Assigned on",
  change: "Change",
  extendBy: "Extend by",
  expireDateRequired: "Expiration date to is required",
  expireDateMessage: "Set a new expiry date. This will re-activate the quote.",
  changeExpirationDate: "Change Expiry Date",
  likelihoodOfClose: "Likelihood of Close",
  selectPercentage: "Select percentage",
  percentageRequired: "Percentage is required",
  comments: "Comments",
  currentSalesperson: "Current Salesperson",
  newSalesperson: "New Salesperson",
  setAs: "Set as",
  set: "Set",
  changeSalesperson: "Change Salesperson",
  changeQuoteStatus: "Change Quote Status",
  currentStatus: "Current Status",
  followUpNotes: "Follow-up Notes",
  assignEstimator: "Assign Estimator",
  fifteen: 15,
  two: 2,
  five: 5,
  expired: "Expired",
  new: "New",
  revision: "Revision",
  killed: "Dead(killed)",
  noQuote: "Dead(no-quote)",
  activate: "Activate",
  zero: 0,
  one: 1,
  setDueDate: "Set Due Date",
  reviseDueDate: "Revise Due Date",
  newDueDate: "New Revised Due Date",
  changeCloseDate: "Change Close Date",
  newCloseDate: "New Close Date",
  revise: "Revise",
  dueDateLabel: "Due Date",
  revisedDueDateLabel: "Revised Due Date",
  notSet: "Not Set",
  starredStart: "Add frequently used items here for easy use. Click ",
  starredEnd: "on an item to add.",
  collaborators: "Collaborators",
  specifier: "Specifier",
  architect: "Architect",
  engineer: "Engineer",
  specs: "Specs",
  fixtureSpecification: "Fixture Specification",
  fixtureQuantities: "Fixture Quantities",
  controlSpecification: "Controls Specification",
  controlBom: "Controls B.O.M.",
  specIncomplete:
    "Spec incomplete; subject to approval. Change subject to re-quote",
  specQuoteCounts: "Spec Quote based on average set of counts",
  equipment: "Equipment",
  quoteBatteryPacks: "Quoting Battery Packs where noted",
  quotingEmCircuiting: "Quoting EM Circuiting where noted",
  quotingInvertor: "Quoting Inverter",
  generator: "Generator",
  exclusionAndException: "Exclusions and Exceptions",
  noEm: "No EM",
  typeAbcNoQuoteNoSpecStatic: "Types A, B, C No Quote; No Spec",
  typeAbcNoQuoteNoQty: "Types A, B, C No Quote; No Quantity",
  documentationAndDesign: "Documentation and Design",
  electricalDrawingEtc: "Electrical drawings, etc. not provided",
  removeFromStar: "Remove from Starred",
  addToStar: "Add to Starred",
  noSubquotes: "No Subquotes",
  showStarred: "Show Starred",
  dueOnLabel: "DUE ON",
  closeDateLabel: "CLOSE DATE",
  priceLabel: "PRICE",
  quoteToLabel: "QUOTED TO",
  estimatorLabel: "ESTIMATOR",
  salesPersonLabel: "SALESPERSON",
  likeHoodOfCloseLabel: "LIKELIHOOD OF CLOSE",
  majorRepsUpperCase: "MAJOR REPS",
  hideStarred: "Hide Starred",
  no: "No",
  status: "STATUS"
};

export const springBoardDocumentsTabs = {
  number: "No.",
  type: "Type",
  rep: "Rep",
  addedOn: "Added on",
  addedBy: "Added by",
  size: "Size",
  fileType: "File type",
  no: "No",
  name: "Name",
  docType: "Document Type",
  amountOfPo: "Amount of PO ($)",
  amountOfPoDocument: "Amount of PO",
  lastUpdated: "Last updated",
  uploadedBy: "Uploaded By",
  upload: "Upload",
  noDocForQuote: "No Documents for this Quote",
  deleted: "Deleted",
  download: "Download",
  open: "OPEN",
};

export const springBoardDocumentsItems = {
  springBoardLite: "Springboard LITE",
  lite: "LITE",
  full: "FULL",
  allReps: "All Reps",
  assignedReps: "ASSIGNED REPS",
  assignNewRep: "Assign A New Rep",
  editItems: "Edit Items",
  total: "Total",
  pricingMethod: "PRICING METHOD",
  itemRepMargin: "Item/Rep margin",
  itemTotal: "ITEM TOTAL",
  jobQuoteTotal: "JOB QUOTE TOTAL",
  shippingCharge: "SHIPPING CHARGE",
  quoteMargin: "QUOTE MARGIN",
  emailQuote: "Email Quote",
  setAsWon: "SET AS WON",
  noItemForThisQuote: "No items for this Quote",
  noItemAdded: "No Items added",
  previewPdf: "Preview PDF",
  margin: "MARGIN",
  convertToExpressQuote: "Convert to Express Quote",
  reviewConvertToExpressQuoteAndPlaceOrder:
    "Review Converted Express Quote + Place Order",
  updateMargins: "Update Margins",
  placeOrder: "Place Order",
  somethingWrong: "Something Wrong",
  statusNotValidForQuoteEmail: "Status not valid for Quote Email",
};

export const springboardCustomerInfo = {
  customerInfo: "CUSTOMER INFO.",
  edit: "Edit",
  name: "Name",
  email: "Email",
  phone: "Phone",
  score: "Score",
  manage: "Manage",
  assignToAnotherRecipient: "Copy/Assign to another Recipient",
  requestMissingInfo: "Request missing information on this quote",
  linkQuoteRecipient: "Link Quote Recipient to Order Customer",
  assign: "Copy/Assign",
  sendRfi: "Send RFI",
  linkRecipient: "Link Recipient",
  preferred: "Preferred",
  editCustomerInfo: "Edit Customer Info",
  customer: "Customer",
  cancel: "Cancel",
  saveChanges: "Save Changes",
  ext: "Ext.",
  contactName: "Contact Name",
  companyName: "Company Name",
  recipientName: "Recipient Name",
  recipientMessage: "Use only alphanumeric characters without spaces",
};

export const itemListHeader = {
  line: "Line#",
  item: "Item",
  qty: "Qty",
  price: "Price",
  cost: "Cost",
  margin: "Margin",
  type: "Type",
  ctrlType: "Control Type",
  leadTime: "Lead Time",
};

export const dateType = {
  dueDate: "dueDate",
  closeDate: "closeDate",
  expireDate: "expireDate",
};

export const springboardSuccessMessages = {
  statusChangedToRevision: "Status changed to revision",
  statusChangedToNoQuote: "Status changed to no-quote",
  statusChangedToNew: "Status changed to new",
  statusChangedToExpired: "Status changed to expired",
};

export const validStatusValues = [1, 5, 2, 4, 0, 6];
export const defaultSelectedMainTab = 1;

export const warehouseName = {
  5: "Jersey City",
  6: "Greenvale",
};

export const SELECTION_STATE = {
  IDLE: 'idle',
  BUSY: 'selecting'
};

export const layoutForNotepad = {
  windowed: "windowed",
  docked: "docked",
  openNotepads: "open-notepad",
  syncNotepads: 'sync-notepad'
}

export const onlyReplacementQtyMessage =
  "Draft orders created for replacement items";
export const onlyAdditionalQtyMessage =
  "Draft orders created for items with additional qty. requested";
export const bothQtyMessage =
  "Draft orders created for replacement and additional qty. items";