import { FeeDataProps, ReturnFlowDataProps } from "constants/interfaces";
import * as constants from "./constants";

export function setPosFlowData(payload: any) {
  return {
    type: constants.SET_POS_FLOW_DATA,
    payload,
  };
}

export function resetPosFlowData() {
  return {
    type: constants.RESET_POS_FLOW_DATA,
  };
}

export function setNewCustomerCode(payload: { posCustCode: number }) {
  return {
    type: constants.SET_NEW_CUCTOMER_CODE,
    payload
  };
}

export function setPriceVisibleByDefault() {
  return {
    type: constants.SET_PRICE_VISIBLE_BY_DEFAULT,
  };
}

export function setStorePickedUpAddress(payload: any) {
  return {
    type: constants.SET_STORE_PICKED_ADDRESS,
    payload,
  };
}

export function loadCartRequest(payload: any) {
  return {
    type: constants.LOAD_CART_START,
    payload,
  };
}

export function loadCartSuccess(payload: any) {
  return {
    type: constants.LOAD_CART_SUCCESS,
    payload,
  };
}

export function loadCartError(error: any) {
  return {
    type: constants.LOAD_CART_ERROR,
    error,
  };
}

export function loadCartSuccessClear(payload: boolean) {
  return {
    type: constants.CLEAR_LOAD_CART_SUCCESS_FLAG,
    payload,
  };
}

export function resetLoadCart() {
  return {
    type: constants.RESET_LOAD_CART
  };
}

export function setNoLoadDetailsAction(payload: boolean) {
  return { type: constants.SET_NO_LOAD_DETAILS, payload };
}

export function setDefaultPrivacy(payload: boolean) {
  return { type: constants.SET_PRIVACY, payload };
}

export function setExemptTax(payload: boolean) {
  return { type: constants.SET_EXEMPT_TAX, payload };
}

export function setToPickUp(payload: boolean) {
  return { type: constants.SET_TO_PICKUP, payload };
}

export function setTaxMessage(payload: boolean) {
  return { type: constants.IS_TAX_MESSAGE, payload };
}

export function clearLoadCartDetails() {
  return { type: constants.CLEAR_LOAD_CART };
}

export function setShipToAddress(payload: boolean) {
  return { type: constants.SET_SHIP_TO_ADDRESS, payload };
}

export function setTaxRateOfShipToAddress(payload) {
  return { type: constants.SET_TAX_RATE_OF_SHIPPING_ADDRESS, payload };
}

export function setCustomTaxRate(payload) {
  return { type: constants.SET_CUSTOM_TAX_RATE, payload };
}

export function saveProduct(payload) {
  return {
    type: constants.SAVE_PRODUCT,
    payload,
  };
}

export function getChangePriceInfo(payload) {
  return {
    type: constants.GET_CHANGE_PRICE_INFO,
    payload,
  };
}

export function showOriginalPriceInfo(payload) {
  return {
    type: constants.SHOW_ORIGINAL_PRICE,
    payload,
  };
}

export function showAlreadyPickedInfoData(payload) {
  return {
    type: constants.SHOW_ALREADY_PICKED_INFO,
    payload,
  };
}

export function examptShipTolocationData(payload) {
  return {
    type: constants.EXEMPT_SHIP_TO_LOCATION,
    payload,
  };
}

export function shippingAddressSelectedData(payload) {
  return {
    type: constants.SHIPPING_ADDRESS_SELECTED,
    payload,
  };
}

export function recipientInfoSwitchInfoData(payload) {
  return {
    type: constants.RECIPIENT_INFO_SWITCH_INFO,
    payload,
  };
}

export function setBackupProducts(payload) {
  return {
    type: constants.STORE_BACKUP_PRODUCTS,
    payload,
  };
}

export function clearCartBackupProducts(payload) {
  return {
    type: constants.CLEAR_CART_BACKUP_PRODUCTS,
    payload,
  };
}

export function setModifiedPriceData(payload) {
  return {
    type: constants.SET_MODIFIED_PRICE,
    payload,
  };
}

export function personNotes(payload) {
  return {
    type: constants.PERSON_NOTES,
    payload,
  };
}

export function setReturnFlowData(payload: ReturnFlowDataProps) {
  return {
    type: constants.SET_RETURN_FLOW_DATA,
    payload,
  };
}

export function shippingFeeDataInfo(payload: FeeDataProps) {
  return {
    type: constants.SHIPPING_FEE_DATA,
    payload,
  };
}
export function shippingFeeDataClearData() {
  return {
    type: constants.SHIPPING_FEE_CLEAR_DATA,
  };
}

export function restockingFeeDataInfo(payload: FeeDataProps) {
  return {
    type: constants.RESTOCKING_FEE_DATA,
    payload,
  };
}
export function restockingFeeDataClearData() {
  return {
    type: constants.RESTOCKING_FEE_CLEAR_DATA,
  };
}

export function handlingFeeDataInfo(payload: FeeDataProps) {
  return {
    type: constants.HANDLING_FEE_DATA,
    payload,
  };
}
export function handlingFeeDataClearData() {
  return {
    type: constants.HANDLING_FEE_CLEAR_DATA,
  };
}

export function loadReturnDataAction(payload) {
  return {
    type: constants.LOAD_RETURN_DATA,
    payload,
  };
}

export function loadProductDraftAction(payload) {
  return {
    type: constants.LOAD_PRODUCT_DRAFT_ACTION,
    payload,
  };
}

export function setTabIndexNumber(payload) {
  return {
    type: constants.SET_TAB_INDEX,
    payload,
  };
}

export function setReturnAllDataArrayAction(payload) {
  return {
    type: constants.RETURN_ALL_DATA_ARRAY,
    payload,
  };
}

export function addNewCustomerRequest(payload: any) {
  return {
    type: constants.ADD_NEW_CUSTOMER_INFO_START,
    payload,
  };
}
export function addNewCustomerSuccess(payload: any) {
  return {
    type: constants.ADD_NEW_CUSTOMER_INFO_SUCCESS,
    payload,
  };
}
export function addNewCustomerError(error: any) {
  return {
    type: constants.ADD_NEW_CUSTOMER_INFO_ERROR,
    error,
  };
}
export function addNewCustomerClear() {
  return {
    type: constants.ADD_NEW_CUSTOMER_INFO_CLEAR,
  };
}

export const manipulateStarredProductDetails = (payload) => {
  return {
    type: constants.MANIPULATE_STARRED_PRODUCT_INFO,
    payload,
  };
}

export const setCustomerAccountInfo = (payload) => {
  return {
    type: constants.CUSTOMER_ACCOUNT_INFO,
    payload,
  };
}

export const clearCustomerAccountInfo = () => {
  return {
    type: constants.CLEAR_CUSTOMER_ACCOUNT_INFO
  };
}

export function savedDraftsListPageRequest(payload: any) {
  return {
    type: constants.GET_DRAFTS_START,
    payload,
  };
}

export function deleteDraftOptimistically(payload: any) {
  return {
    type: constants.OPTIMISTIC_DELETE_DRAFT,
    payload,
  };
}

export function savedDraftsListPageSuccess(payload: any) {
  return {
    type: constants.GET_DRAFTS_SUCCESS,
    payload,
  };
}

export function savedDraftsListPageError(error: any) {
  return {
    type: constants.GET_DRAFTS_ERROR,
    error,
  };
}


export function savedDraftsListPageReset() {
  return {
    type: constants.GET_DRAFTS_RESET,
  };
}

export function addNewCustomerModel(payload: boolean) {
  return {
    type: constants.ADD_NEW_CUSTOMER_MODEL,
    payload,
  };
}
export const clearStarredProductDetails = () => ({ type: constants.CLEAR_STARRED_PRODUCT_INFO })

export function setIsMail(payload: any) {
  return {
    type: constants.IS_EMAIL,
    payload,
  };
}

export function restockingFeeReplaceInfo(payload) {
  return {
    type: constants.RESTOCKING_FEE_REPLACE_DATA,
    payload,
  };
}

export function handlingFeeReplaceInfo(payload) {
  return {
    type: constants.HANDLING_FEE_REPLACE_DATA,
    payload,
  };
}

export function isGpmCustomFlag(payload) {
  return {
    type: constants.IS_GPM_CUSTOM,
    payload,
  };
}


export function isOrderTotalCustomFlag(payload) {
  return {
    type: constants.IS_ORDER_TOTAL_CUSTOM,
    payload,
  };
}

export function setOriginalOrderTotal(payload) {
  return {
    type: constants.SET_ORDER_TOTAL,
    payload,
  };
}


export function setOriginalPercentage(payload) {
  return {
    type: constants.SET_ORIGINAL_PERCENTAGE,
    payload,
  };
}

export function skipOrdererInfo(payload) {
  return {
    type: constants.SKIP_ORDERER_INFO,
    payload,
  };
}

export function setSubTotalAmount(payload: any) {
  return {
    type: constants.SUB_TOTAL_AMOUNT,
    payload,
  };
}
export function setTaxAmount(payload: any) {
  return {
    type: constants.TAX_AMOUNT,
    payload,
  };
}
export function setShippingAmountReducer(payload: any) {
  return {
    type: constants.SHIPPING_AMOUNT,
    payload,
  };
}
export function setShipTaxAmtToBeSent(payload: any) {
  return {
    type: constants.SHIPPING_AMOUNT_TO_BE_SENT,
    payload,
  };
}
export function setShippingTaxAmount(payload: any) {
  return {
    type: constants.SHIPPING_TAX_AMOUNT,
    payload,
  };
}
export function setTakerIdToBeSent(payload: any) {
  return {
    type: constants.TAKER_ID_TO_BE_SENT,
    payload,
  };
}
export function setTaxRateToBeSent(payload: any) {
  return {
    type: constants.TAX_RATE_TO_BE_SENT,
    payload,
  };
}

export function setTaxExemptManually(payload: any) {
  return {
    type: constants.SET_EXEMPT_TAX_MANUALLY,
    payload,
  };
}

export function setCustomerAlertHide(payload: boolean) {
  return {
    type: constants.SET_CUSTOMER_ALERT_HIDE,
    payload,
  };
}

export const manipulateStarredMetadataSb = (payload) => {
  return {
    type: constants.MANIPULATE_STARRED_METADATA_SB,
    payload,
  };
}
export function setRefundMethodCheckData(payload) {
  return {
    type: constants.REFUND_METHOD_CHANGE,
    payload,
  }
}

export function setAllowPickupCheck(payload: boolean) {
  return {
    type: constants.SET_ALLOW_PICKUP,
    payload,
  };
}

export function updateCustomerDataRegister() {
  return {
    type: constants.UPDATE_CUSTOMER_DATA_MESSAGE
  };
}

export function setUpdatedStockDataOnRefreshStart(payload: any) {
  return {
    type: constants.SET_UPDATED_STOCK_DATA_ON_REFRESH_START,
    payload
  }
}

export function setUpdatedStockDataOnRefreshError() {
  return {
    type: constants.SET_UPDATED_STOCK_DATA_ON_REFRESH_ERROR
  }
}


export function setUpdatedStockDataOnRefreshSuccess(payload: any) {
  return {
    type: constants.SET_UPDATED_STOCK_DATA_ON_REFRESH_SUCCESS,
    payload,
  };
}
