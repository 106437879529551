/* eslint-disable */
import "@fontsource/inter";
import {
  styled,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import _ from "loadsh";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { PosButton, PosImage } from "apps/pos/Common/uiCore";

import { bothQtyMessage, onlyAdditionalQtyMessage, onlyReplacementQtyMessage, returnDraftOrderNoteBodyLine1, returnDraftOrderNoteBodyLine2, returnFailedFlag } from "constants/constant";
import { imgAlterText, paymentType, RefundPaymentTypeEnum } from "constants/enum";
import { ArrowDropDownIcon } from "constants/icons";
import images from "constants/images";
import { PosFlowDataProp, ReturnFlowDataMainProps } from "constants/interfaces";
import { supportLink } from "constants/staticData";
import { Paths } from "enums";
import { beforeUnloadCart, openAndPrintPdf } from "utils/helper";

import { RootState } from "models";


import "apps/pos/poscommonstyling.scss";
import { handlingFeeDataClearData, loadCartRequest, loadReturnDataAction, restockingFeeDataClearData, setPosFlowData, setReturnAllDataArrayAction, setReturnFlowData, shippingFeeDataClearData } from "sagas/persistReducer/actions";
import { finalArrAction, getReturnCartData, removedArrAllData, removedArrPartialData, returnCartSubmitFailedAction, returnProductsCreditMemoClear, returnProductsCreditMemoRequest, searchReturnInvoiceClear } from "sagas/returns/actions";
import { getLocalStoraGeItem } from "utils/utils";
import Group_3942 from "../../../springboard/images/icons/Group_3942.svg";
import close_icon from "../../../springboard/images/icons/close_icon.svg";
import LeaveReturnCartModal from "../Modal/LeaveReturnCartModal/LeaveReturnCartModal";
import "./ReturnSuccessfully.scss";
import { StyledTableCell, useStyles } from "./style";
import { setJobNameForAll } from "sagas/pos/actions";

// Custom styled tooltip component
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "#ffffff",
    color: "#000000",
    boxShadow: "0rem 0.625rem 2.5rem #0000001A",
    borderRadius: "0.75rem",
    fontSize: "1rem",
    padding: "0.9375rem 0.9375rem",
    maxWidth: "31.0625rem",
    letterSpacing: "0.01rem",
    fontWeight: "500",
  },
  [`& .MuiTooltip-arrow`]: {
    color: "#ffffff", // Arrow color matches the background
  },
}));

const ReturnSuccessfully = () => {

  const { returnProductsOrdersData, printCreditMemoSuccess, returnProductsTicketPrintData, isReturnProductsTicketPrintLoading, getCompleteReturnPayload } = useSelector<RootState, ReturnFlowDataMainProps>(
    (state) => state.returnReducer
  );
  const { posFlowData } = useSelector<RootState, PosFlowDataProp>((state) => state.persistReducer);
  const returnProcessResultData = returnProductsOrdersData?.result
  const returnProcessDraftsData = returnProductsOrdersData?.drafts

  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const mainProduct = useRef();
  const { plusFilledIcon, orangePartiallyFilled,
    orangePartiallyOutlined,
    printImage, cashIcon, checkBlackReturn } = images;

  const [printMemoLoadingSpinner, setPrintMemoLoadingSpinner] = useState(false);
  const [orderInvoiceLoadingSpinner, setOrderInvoiceLoadingSpinner] = useState(false);
  const [showDraftOrderIdNote, setShowDraftOrderIdNote] = useState(false);

  const totalReturnQuantity = returnProcessResultData?.length && returnProcessResultData?.reduce((total, obj) => Number(obj.returnQty) + Number(total), 0)
  const totalNonSaleableData = returnProcessResultData?.length && returnProcessResultData?.reduce((total, obj) => Number(obj.noSaleableQty) + Number(total), 0)
  const totalItemReplaced = returnProcessResultData?.length && returnProcessResultData?.reduce((total, obj) => Number(obj.replaceQty) + Number(total), 0)
  const totalReturnAmount = returnProcessResultData?.length && returnProcessResultData?.reduce((total, obj) => Number(obj.returnAmount) + Number(total), 0)
  const totalOverShip = returnProcessResultData?.length && returnProcessResultData?.reduce((total, obj) => Number(obj.overShipmentQty) + Number(total), 0)
  const totalAdditionQuantity = returnProcessResultData?.length && returnProcessResultData?.reduce((total, obj) => Number(obj.additionalQty) + Number(total), 0)

  const {
    printIconAltText,
    returnIconAltText,
    returnPartially, returnFully,
    returnFullyFailed
  } = imgAlterText

  const handleKeyPress = (e) => {
    if (!disablePrintBtn) {
      const { keyCode } = e;
      if (keyCode === 27) {
        navigate(-1);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    if (!returnProcessResultData?.length) {
      navigate(Paths.posNewOrder);
    } else {
      history.pushState(null, null, location?.href);
      window.onpopstate = function (event) {
        navigate(Paths.posNewOrder);
      };
    }
    beforeUnloadCart([])
    dispatch(setReturnFlowData({}));
  }, []);

  useEffect(() => {
    return function cleanup() {
      document?.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const printCreditMemo = () => {
    const info = returnProcessResultData?.length && returnProcessResultData?.map((item) => {
      return item?.docs?.creditMemoUrl
    })
    dispatch(returnProductsCreditMemoRequest(info))
  }
  const openOrderInvoice = () => {
    const info = returnProcessResultData?.length && returnProcessResultData?.map((item) => {
      return item?.docs?.invoiceUrl
    })
    info?.length > 0 && info?.map(item => {
      return window.open(item, '_blank', 'noreferrer');
    })
  }
  const printOrderInvoice = () => {
    openOrderInvoice();
    setTimeout(() => {
      setOrderInvoiceLoadingSpinner(false)
    }, 100);
  }
  const printAll = () => {
    const info = returnProcessResultData?.length && returnProcessResultData?.map((item) => {
      return item?.docs?.creditMemoUrl
    })
    dispatch(returnProductsCreditMemoRequest(info))
    openOrderInvoice();
  }

  const disablePrintBtn = returnProcessResultData?.length && returnProcessResultData?.some((item) => {
    return item?.returnAmount === 0
  })

  useEffect(() => {
    if (printCreditMemoSuccess) {
      setPrintMemoLoadingSpinner(false)
      openAndPrintPdf(returnProductsTicketPrintData)
      dispatch(returnProductsCreditMemoClear())
    }
  }, [printCreditMemoSuccess]);

  useEffect(() => {
    setPrintMemoLoadingSpinner(isReturnProductsTicketPrintLoading)
  }, [isReturnProductsTicketPrintLoading])

  const [leavemodel, setLeavemodel] = useState(false);

  const locationData = useLocation();

  const addedReturnedProduct = locationData?.state?.addedReturnedProduct;
  const returnAllDataArray = locationData?.state?.returnAllDataArray;

  const handleSkipCart = () => {
    addedReturnedProduct?.length > 0 && addedReturnedProduct?.pop();
    dispatch(setReturnFlowData({
      addedReturnedProduct: addedReturnedProduct,
      returnCartData: {}
    }));
    returnAllDataArray?.length > 0 && returnAllDataArray?.pop();
    dispatch(setReturnAllDataArrayAction(returnAllDataArray))
    dispatch(setReturnAllDataArrayAction(returnAllDataArray?.splice(0, returnAllDataArray.length)))
    dispatch(setReturnAllDataArrayAction([]))
    dispatch(searchReturnInvoiceClear())
    dispatch(shippingFeeDataClearData())
    dispatch(restockingFeeDataClearData())
    dispatch(handlingFeeDataClearData())
    dispatch(dispatch(getReturnCartData([])))
    const addedReturnProductArray = {
      addedReturnedProduct: []
    }
    dispatch(setReturnFlowData(addedReturnProductArray));
    dispatch(setReturnFlowData({}));
    dispatch(removedArrAllData(true));
    navigate(Paths.posNewOrder);
  }

  const completeFullyReturn = returnProcessResultData?.every((item) => {
    return item?.isSuccess === true
  })
  const failedReturn = returnProcessResultData?.every((item) => {
    return item?.isSuccess === false
  })
  const partiallyReturn = returnProcessResultData?.some((item) => {
    return item?.isSuccess === false
  })
  const partiallyReturnOrdersData = returnProcessResultData?.filter((item) => {
    return item?.isSuccess === false
  })

  const findFailedOrder = returnProcessResultData?.filter((item) => {
    return item?.isSuccess === false
  })

  const test = _.filter(getCompleteReturnPayload, (value, key) => {
    if (findFailedOrder?.find(arr2Obj => arr2Obj?.orderID == key)) {
      return value
    }
  })?.flat()

  const handleGoToReturnCart = () => {
    if (findFailedOrder?.length > 0) {
      addedReturnedProduct?.length > 0 && addedReturnedProduct?.pop();
      dispatch(setReturnFlowData({
        addedReturnedProduct: addedReturnedProduct,
        returnCartData: {}
      }));
      returnAllDataArray?.length > 0 && returnAllDataArray?.pop();
      dispatch(setReturnAllDataArrayAction(returnAllDataArray))
      dispatch(setReturnAllDataArrayAction(returnAllDataArray?.splice(0, returnAllDataArray.length)))
      dispatch(setReturnAllDataArrayAction([]))
      dispatch(searchReturnInvoiceClear())
      dispatch(shippingFeeDataClearData())
      dispatch(restockingFeeDataClearData())
      dispatch(handlingFeeDataClearData())
      const addedReturnProductArray = {
        addedReturnedProduct: test
      }
      dispatch(setReturnFlowData(addedReturnProductArray));
      dispatch(finalArrAction(test))
      dispatch(returnCartSubmitFailedAction());
      dispatch(removedArrPartialData(true));
      navigate(Paths.posReturnCart);
    }
  }

  const checkArray = returnProcessResultData?.length > 0 && returnProcessResultData?.filter((item) => {
    return item?.paidVia === paymentType.check && !item?.overShipmentQty
  })?.sort((a, b) =>
    a.customerName > b.customerName ? 1 : -1,
  );
  const cardArray = returnProcessResultData?.length > 0 && returnProcessResultData?.filter((item) => {
    return item?.paidVia?.includes(paymentType.card) && !item?.overShipmentQty
  })?.sort((a, b) =>
    a.customerName > b.customerName ? 1 : -1,
  );
  const cashkArray = returnProcessResultData?.length > 0 && returnProcessResultData?.filter((item) => {
    return item?.paidVia === paymentType.cash && !item?.overShipmentQty
  })?.sort((a, b) =>
    a.customerName > b.customerName ? 1 : -1,
  );
  const paidViaPayLaterArray = returnProcessResultData?.length > 0 && returnProcessResultData?.filter((item) => {
    return item?.paidVia === paymentType.payLater && !item?.overShipmentQty
  })?.sort((a, b) =>
    a.customerName > b.customerName ? 1 : -1,
  );
  const paidViaStoreCredit = returnProcessResultData?.length > 0 && returnProcessResultData?.filter((item) => {
    return item?.paidVia === paymentType.storeCredit || item?.paidVia === paymentType.customerStoreCredit && !item?.overShipmentQty
  })?.sort((a, b) =>
    a.customerName > b.customerName ? 1 : -1,
  );

  const overShipItems = returnProcessResultData?.length > 0 && returnProcessResultData?.filter((item) => {
    return item?.overShipmentQty
  })?.sort((a, b) =>
    a.customerName > b.customerName ? 1 : -1,
  );

  const finalArray = [...paidViaPayLaterArray || [], ...cardArray || [], ...checkArray || [], ...cashkArray || [], ...paidViaStoreCredit || [], ...overShipItems || []]

  const hasDraftOrderId = finalArray?.map((item) => {
    const draftID = returnProcessDraftsData?.find((info) => {
      if (item?.customerID === info?.customerID) {
        return item?.customerID && info
      }
    })
    return !!draftID?.orderID
  })

  const hasInvoiceUrl = returnProcessResultData?.length > 0 && returnProcessResultData?.some((item) => {
    return item?.docs?.invoiceUrl
  })
  const hasCreditMemoUrl = returnProcessResultData?.length > 0 && returnProcessResultData?.some((item) => {
    return item?.docs?.creditMemoUrl
  })
  const currentWorkspace = getLocalStoraGeItem("workspace");
  const { stockWarehouseId } = currentWorkspace || {};
  const currentTaker = getLocalStoraGeItem("taker");

  const messageMap = {
    both: bothQtyMessage,
    replacementOnly: onlyReplacementQtyMessage,
    additionalOnly: onlyAdditionalQtyMessage,
    none: null
  };

  const messageKey = (totalItemReplaced && totalAdditionQuantity)
    ? 'both'
    : (totalItemReplaced)
      ? 'replacementOnly'
      : (totalAdditionQuantity)
        ? 'additionalOnly'
        : 'none';


  return (
    <div className='return-successfull-container'>
      <main ref={mainProduct}>
        <div>
          <section className="return-complete-title-section return-complete-top-title-section">
            {failedReturn ?
              <div className="d-flex align-items-center">
                <div className={classes.partialFilledIcon}>
                  <PosImage src={orangePartiallyFilled} alt={returnIconAltText} />
                </div>
                <span className="title ml-2">
                  {returnFullyFailed}
                </span>
              </div>
              : partiallyReturn ?
                <div className="d-flex align-items-center">
                  <div className={classes.partialFilledIcon}>
                    <PosImage src={orangePartiallyFilled} alt={returnIconAltText} />
                  </div>
                  <span className="title ml-2">
                    {returnPartially}
                  </span>
                </div>
                : completeFullyReturn &&
                <div className="d-flex align-items-center">
                  <div className={classes.partialFilledIcon}>
                    <PosImage src={checkBlackReturn} alt={returnIconAltText} />
                  </div>
                  <span className="title ml-2">
                    {/* Return complete */}
                    {returnFully}
                  </span>
                </div>
            }
            <div className="d-flex">
              <div className="all-print-btn">
                {(!hasInvoiceUrl && hasCreditMemoUrl) && <PosButton
                  onClick={() => {
                    setPrintMemoLoadingSpinner(true);
                    printCreditMemo();
                  }}
                  disabled={disablePrintBtn}
                  variant="contained" color="secondary"
                  button-is-loading
                  className={`return-successful-btn h-auto modal-select-default-btn ship-btn picking-qty-btn mb-0 w-auto ${printMemoLoadingSpinner ? 'button-is-loading' : ""}`}>
                  {!printMemoLoadingSpinner ? (
                    <div className="select-default-text w-100">
                      <div>
                        <PosImage src={printImage} alt={printIconAltText} />
                      </div>
                      <div className="default-schedule-heading w-100">
                        <span className="int-18-reg-bold">Print Credit Memo</span>
                      </div>
                    </div>
                  ) : null}
                </PosButton>}

                {(!hasCreditMemoUrl && hasInvoiceUrl) && <PosButton
                  onClick={() => {
                    setOrderInvoiceLoadingSpinner(true);
                    printOrderInvoice();
                  }}
                  variant="contained" color="secondary"
                  button-is-loading
                  className={`return-successful-btn h-auto modal-select-default-btn ship-btn picking-qty-btn mb-0 w-auto ${orderInvoiceLoadingSpinner ? 'button-is-loading' : ""}`}>
                  {!orderInvoiceLoadingSpinner ? (
                    <div className="select-default-text w-100">
                      <div>
                        <PosImage src={printImage} alt={printIconAltText} />
                      </div>
                      <div className="default-schedule-heading w-100">
                        <span className="int-18-reg-bold">Print Order Invoice</span>
                      </div>
                    </div>
                  ) : null}
                </PosButton>}

                {(hasCreditMemoUrl && hasInvoiceUrl) && <PosButton
                  variant="contained" color="secondary"
                  button-is-loading
                  className={`return-successful-all-print-btn modal-select-default-btn print-btn picking-qty-btn mb-0 h-auto`}>

                  <div>
                    <div className="select-default-text w-100">
                      <div>
                        <PosImage src={printImage} alt={printIconAltText} />
                      </div>
                      <div className="default-schedule-heading w-100">
                        <span className="int-18-reg-bold">Print</span>
                      </div>
                      <div>
                        <ArrowDropDownIcon fontSize="small" />
                      </div>
                    </div>
                    <div className="print-all-dropdown-content">
                      <div className="print-dropdown-content" onClick={() => {
                        setPrintMemoLoadingSpinner(true);
                        printCreditMemo();
                      }}>Print Credit Memo</div>
                      <div className="print-dropdown-content" onClick={() => {
                        printOrderInvoice();
                      }}>Print Order Invoice</div>
                      <div className="print-dropdown-content" onClick={() => {
                        setOrderInvoiceLoadingSpinner(true);
                        printAll();
                      }}>Print All</div>
                    </div>

                  </div>

                </PosButton>}
              </div>
              <PosButton
                onClick={() => {
                  handleSkipCart()
                }}
                variant="contained" color="secondary"
                className={`return-cart-new-order return-successful-btn picking-qty-btn mb-0 ml-2 h-auto`}>

                <div className="select-default-text w-100">
                  <div>
                    <PosImage src={plusFilledIcon} alt={printIconAltText} />
                  </div>
                  <div className="default-schedule-heading w-100">
                    <span className="int-18-reg-bold">Start a New Order</span>
                  </div>
                </div>

              </PosButton>
            </div>
          </section>
          {partiallyReturn && <section className="failed-retry-section">
            <div className="failed-retry-left-sec">
              <span>
                {/* {partiallyReturnOrdersData?.length} orders could not be processed. Please try again. */}
                {partiallyReturnOrdersData?.length} order{partiallyReturnOrdersData?.length > 1 ? 's' : ''} could not be processed. {returnFailedFlag ? "Please try again." : "Please contact Jumper support for further help."}
              </span>
            </div>
            <div className="failed-retry-right-sec">
              {returnFailedFlag ? <PosButton
                variant="contained" color="primary"
                onClick={() => setLeavemodel(true)}
                className={`pick-replace-quantity-btn complete-return-btn`}>
                Retry failed returns
              </PosButton> :
                <PosButton
                  variant="contained" color="primary"
                  onClick={() =>
                    window.open(
                      supportLink,
                      "_blank"
                    )}
                  className={`pick-replace-quantity-btn tax-file-add-doc-btn`}>
                  Contact Support
                </PosButton>
              }
            </div>
          </section>}
          {/* <section className="return-complete-title-section return-complete-second-section">
            <div className={classes.divSpan}>
              <div className={noReturn ? classes.noReturnIcon : ''}>
                <PosImage src={noReturn ? removeRedWithBgIcon : checkBlackReturn} alt={returnIconAltText} />
              </div>
              <span className={noReturn ? classes.spanNoReturn : classes.spanReturn}>
                {noReturn ? returnNotSuccessFully : returnSuccessFully}
              </span>
            </div>

            {(returnProcessResultDataObject?.docs?.invoiceUrl && returnProcessResultDataObject?.docs?.creditMemoUrl) && <PosButton
              variant="contained" color="secondary"
              button-is-loading
              className={`modal-select-default-btn print-btn picking-qty-btn mb-0 `}>
              
               <div>
               <div className="select-default-text w-100">
                 <div>
                   <PosImage src={print} alt={printIconAltText} />
                 </div>
                 <div className="default-schedule-heading w-100">
                   <span className="int-18-reg-bold">Print</span>
                 </div>
                 <div>
                  <ArrowDropDownIcon fontSize="small" />
                 </div>
               </div>
                <div className="print-all-dropdown-content">
                  <div className="print-dropdown-content" onClick={() => {
                    setPrintMemoLoadingSpinner(true);
                    printCreditMemo();
                  }}>Print Credit Memo</div>
                  <div className="print-dropdown-content" onClick={() => {
                    setPrintOrderInvoiceLoadingSpinner(true);
                    printOrderInvoice();
                  }}>Print Order Invoice</div>
                  <div className="print-dropdown-content" onClick={() => {
                    setOrderInvoiceLoadingSpinner(true);
                    printAll();
                  }}>Print All</div>
                </div>
               
             </div>
              
            </PosButton>}
            </div>
            <div
              className="modal-select-default-btn ship-btn-dark picking-qty-btn"
              onClick={() => navigate(Paths.posNewOrder)}
            >
              <div>
                <PosImage src={plusIcon} alt={plusIconAltText} />
              </div>
              <div className="select-default-text">
                <div className="default-schedule-heading">
                  <span className="int-18-reg-bold-white-return">
                    Start a New Order
                  </span>
                </div>
              </div>
              <div>
                <span className="schedule-shortcut">N</span>
              </div>
            </div>
          </section> */}
        </div>
        {/* {!noReturn && */}
        <div className={`main-successfull-div ${classes.mainDivTable}`}>
          <div className="scroll-styling">
            {(hasDraftOrderId.includes(true) && !showDraftOrderIdNote) ?
              <div className="d-flex return-draft-tooltip jcsb">
                <div className="d-flex">
                  <div>
                    <img src={Group_3942} alt="" className="return-draft-note-icon" />
                  </div>
                  <div>
                    <div className="tooltip-header">
                      {messageMap[messageKey]}
                    </div>
                    <div className="tooltip-description">
                      {`${returnDraftOrderNoteBodyLine1}`}<br />{`${returnDraftOrderNoteBodyLine2}`}
                    </div>
                  </div>
                </div>
                <div>
                  <img onClick={() => setShowDraftOrderIdNote(true)} src={close_icon} alt="close" className="draft-order-note-close" />
                </div>
              </div> : null}

            <TableContainer>

              <Typography
                variant="h6"
                component="h2"
                className={classes.headerSummary}
              >
                Summary
              </Typography>
              <div>
                <Table
                  className={`${classes.root} success-main-data-table`}
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead className="sticky-table-header">
                    <TableRow className="product-search-table-header-row complete-return-table-header">
                      <StyledTableCell align="left"></StyledTableCell>
                      <StyledTableCell align="left">RMA</StyledTableCell>
                      <StyledTableCell align="left">ORDER #</StyledTableCell>
                      <StyledTableCell align="right">
                        ITEMS RETURNED
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        NON-SALEABLE ITEMS
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        ITEMS REPLACED
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        ITEMS OVER SHIPPED
                      </StyledTableCell>
                      <StyledTableCell align="center">CREDIT AMT.</StyledTableCell>
                      <StyledTableCell align="left">PAID VIA</StyledTableCell>
                      <StyledTableCell align="left" className="table-column-draft-order-tooltip-icon">
                        DRAFT ORDER ID
                        {/* Might need in future */}
                        {/* <div className="return-draft-tooltip-main-div">
                          <ClickAwayListener onClickAway={() => setOpen(false)}>
                            <StyledTooltip
                              title={
                                <div className="d-flex return-draft-tooltip">
                                  <div className="tooltip-icon">
                                    <img src={Group_3942} alt="" className="tooltip-icon" />
                                  </div>
                                  <div>
                                    <div className="tooltip-header">
                                      Draft orders are created for items with additional quantity requested
                                    </div>
                                    <div className="tooltip-description">
                                      Search for the customer on POS and use the Draft Order ID to process them.
                                    </div>
                                  </div>
                                </div>
                              }
                              placement="bottom"
                              arrow
                              PopperProps={{
                                disablePortal: true,
                              }}
                              onOpen={() => setOpen(!open)}
                              onClose={() => setOpen(!open)}
                              open={open}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              color="white"
                            >
                              <div onClick={() => {
                                setOpen(!open)
                              }}>
                                <HelpOutlineIcon fontSize="small" />
                              </div>
                            </StyledTooltip>
                          </ClickAwayListener>
                        </div> */}
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody className="table-border-styling">
                    {returnProcessResultData?.length && finalArray?.map((item) => {

                      const draftID = returnProcessDraftsData?.find((info) => {
                        if (item?.orderID === info?.parentOrderId) {
                          return item?.customerID && info
                        }
                      })
                      const finalDraftId = draftID?.orderID

                      const { orderRmaID, orderID, returnQty, noSaleableQty, replaceQty, returnAmount, paidVia, paymentRef1, isSuccess, overShipmentQty, message } = item
                      let paid = paidVia;
                      if (paidVia === paymentType.payLater) {
                        paid = RefundPaymentTypeEnum.REGISTERED_ACCOUNT
                      }
                      if (paidVia === paymentType.storeCredit || paidVia === paymentType.customerStoreCredit) {
                        paid = RefundPaymentTypeEnum.CUSTOMER_CREDIT
                      }
                      if (paidVia === paymentType.check) {
                        paid = RefundPaymentTypeEnum.CHECK
                      }
                      if (paidVia === paymentType.cash) {
                        paid = RefundPaymentTypeEnum.CASH
                      }
                      const checkFailedOrder = isSuccess === false

                      return (
                        <TableRow className="success-table-data-row">
                          <StyledTableCell align="left" className="rma-info-col">
                            <div className="return-successful-main-table">
                              {checkFailedOrder ?
                                <CustomTooltip title={message} placement="bottom" arrow>
                                  <div className="rma-partially-failed-icon">
                                    <PosImage src={orangePartiallyOutlined} alt='partially-icon' />
                                  </div>
                                </CustomTooltip>
                                : null}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell align="left" className="rma-info-col">
                            <span className="int-16-reg normal-content">
                              {orderRmaID}
                            </span>
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            <span className="int-16-reg normal-content">
                              {orderID}
                            </span>
                          </StyledTableCell>

                          <StyledTableCell align="right">
                            <span className="int-16-reg normal-content">{returnQty}</span>
                          </StyledTableCell>

                          <StyledTableCell align="right">
                            <span className="int-16-reg normal-content">{noSaleableQty}</span>
                          </StyledTableCell>

                          <StyledTableCell align="right">
                            <span className="int-16-reg normal-content">{replaceQty}</span>
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <span className="int-16-reg normal-content">{overShipmentQty}</span>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <span className="int-16-reg normal-content">${returnAmount?.toFixed(2)}</span>
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <span className="int-16-reg normal-content">{paidVia?.includes('CARD') ? `Card**${paymentRef1}` : paid}</span>
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {/* {draftID && finalDraftId !== 0 && <span className="int-16-reg normal-content draft-orderId-text">
                              {finalDraftId}
                            </span>} */}
                            {/* Will need after launch */}
                            {draftID && finalDraftId !== 0 && <div
                              className="redirect-backorder-id"
                              onClick={() => {
                                let dataWithCustomerId = {
                                  ...posFlowData,
                                  customer: {
                                    customerId: draftID?.customerID,
                                  }
                                };
                                dispatch(setPosFlowData(dataWithCustomerId));
                                let preparedData = {
                                  stockWarehouseId: stockWarehouseId,
                                  customerId: draftID?.customerID,
                                  takerId: currentTaker?.personId,
                                  cartId: draftID?.orderID,
                                };
                                dispatch(setJobNameForAll(""));
                                window.open(`${Paths.posCart}/${finalDraftId}`, '_blank', 'noreferrer');
                                dispatch(loadReturnDataAction(true));
                                dispatch(loadCartRequest(preparedData));
                                localStorage.setItem('returnProductLoadData', JSON.stringify(preparedData));
                              }}>
                              <span className="int-16-reg normal-content">
                                <u>{finalDraftId}</u>
                              </span>
                              <PosImage
                                src={images.openInNewTabIcon}
                                alt="backorder-id"
                                className="marginleft10"
                              />
                            </div>}
                          </StyledTableCell>
                          {/*  */}
                        </TableRow>
                      )
                    })}

                  </TableBody>

                  <TableBody className="table-border-styling table-total-data">
                    <TableRow className="total-data-row">
                      <StyledTableCell align="left">
                        <div>
                          <span className="int-16-reg normal-content">

                          </span>
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <div>
                          <span className="int-16-reg normal-content"></span>
                        </div>
                      </StyledTableCell>

                      <StyledTableCell align="left">
                        <span className="int-16-reg normal-content">

                        </span>
                      </StyledTableCell>

                      <StyledTableCell align="right">
                        <span className="int-16-reg normal-content">{totalReturnQuantity}</span>
                      </StyledTableCell>

                      <StyledTableCell align="right">
                        <span className="int-16-reg normal-content">{totalNonSaleableData}</span>
                      </StyledTableCell>

                      <StyledTableCell align="right">
                        <span className="int-16-reg normal-content">{totalItemReplaced}</span>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <span className="int-16-reg normal-content">{totalOverShip}</span>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <span className="int-16-reg normal-content">${totalReturnAmount?.toFixed(2)}</span>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <span className="int-16-reg normal-content"></span>
                      </StyledTableCell>
                      <StyledTableCell align="left">

                      </StyledTableCell>
                      <StyledTableCell align="left">

                      </StyledTableCell>
                    </TableRow>

                  </TableBody>
                </Table>
              </div>
            </TableContainer>
          </div>
        </div>
        {/* //  } */}
      </main>
      {returnFailedFlag && <LeaveReturnCartModal
        isRetry
        leaveCartModalOpen={leavemodel}
        setLeaveCartModalOpen={setLeavemodel}
        handleSkipCartModal={handleSkipCart}
        handleGoToReturnCartModal={handleGoToReturnCart}
        partiallyReturnOrdersData={partiallyReturnOrdersData}
      />
      }
    </div>
  );
};

export default ReturnSuccessfully;
