// ChargeCreditCardPage.js
import React, { Suspense } from 'react';

// Lazy load the actual ChargeCreditCard component
const LazyChargeCreditCard = React.lazy(() => import('./ChargeCreditCard'));

// Component that handles lazy loading
export const chargeCreditCard = () => (
  <Suspense fallback={<div></div>}>
    <LazyChargeCreditCard />
  </Suspense>
);

