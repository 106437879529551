import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { PosIconButton } from "apps/pos/Common/uiCore";
import { ReactNode } from "react";

import texts from "apps/pos/locales";
import { CloseIcon } from "constants/icons";

import useStyle from "./style";

interface Props extends DialogProps {
  title?: string;
  dialogClassName?: string;
  dialogHeader?: ReactNode;
  dialogTitle?: boolean;
  dialogFooter?: ReactNode
  onClose: () => void
  disableBackgroundClose?: boolean
  closeIconTabIndex?: number
  subTitle?: string
}

const PosModel = ({ title, dialogClassName, children, open, onClose, dialogHeader, dialogTitle, dialogFooter, disableBackgroundClose, closeIconTabIndex, subTitle }: Props) => {
  const classes = useStyle();
  const { backDropClick } = texts
  const handleDlgClose = (event, reason) => {
    if (reason === backDropClick && disableBackgroundClose) {
      return;
    }
    onClose();
  }

  return (
    <Dialog open={open} className={dialogClassName} onClose={handleDlgClose}>
      {dialogHeader && <>{dialogHeader}</>}
      {dialogTitle && <DialogTitle>
        <Box
          display="flex"
          alignItems="center"
        >
          <Typography className={`${subTitle ? classes.grayTitle : classes.title} model--title`}>{title}</Typography>
          {onClose && <PosIconButton onClick={() => onClose()} tabIndex={closeIconTabIndex}>
            <CloseIcon />
          </PosIconButton>}
        </Box>
        {subTitle ? <Typography className={`${classes.subTitle}`}>{subTitle}</Typography> : null}
      </DialogTitle>}
      <DialogContent>{children}</DialogContent>
      {dialogFooter && <DialogActions>{dialogFooter}</DialogActions>}
    </Dialog>
  );
};

export default PosModel;