
export const salesHistoryColumns = [
  {
    id: "invDate",
    displayName: "INV. DATE"
  },
  {
    id: "customer",
    displayName: "CUSTOMER"
  },
  {
    id: "quote",
    displayName: "QUOTE #"
  },
  {
    id: "quoteDate",
    displayName: "QUOTE DATE"
  },
  {
    id: "order",
    displayName: "ORDER #"
  },
  {
    id: "qty",
    displayName: "QTY."
  },
  {
    id: "netPrice",
    displayName: "NET PRICE"
  },
  {
    id: "uom",
    displayName: "UOM"
  },
  {
    id: "extPrice",
    displayName: "EXT. PRICE"
  },
  {
    id: "profit",
    displayName: "PROFIT %"
  },
  {
    id: "warehouse",
    displayName: "WAREHOUSE"
  }
];

export const selectData = [
  {
    label: "Lorem Ipsum",
    value: "Lorem Ipsum",
  },
  {
    label: "Dolor Sit",
    value: "Dolor Sit",
  },
  {
    label: "amet consectetur",
    value: "amet consectetur",
  },
  {
    label: "adipiscing elit",
    value: "adipiscing elit",
  },
];

export const itemLedgerColumnsCsv = [
  {
    id: "takenByTime",
    displayName: "DATE"
  },
  {
    id: "customerName",
    displayName: "CUSTOMER"
  },
  {
    id: "transactionId",
    displayName: "TRANSACTION #"
  },
  {
    id: "reason",
    displayName: "REASON"
  },
  {
    id: "takenByName",
    displayName: "TAKER"
  },
  {
    id: "takeInPriceByPriceMu",
    displayName: "PRICE"
  },
  {
    id: "qty",
    displayName: "COMMITTED QTY."
  },
  {
    id: "balance",
    displayName: "BALANCE"
  },
];

export const optionsDraftSort = [
  { value: "Recent First", label: "Recent First" },
  { value: "Oldest First", label: "Oldest First" },
];

export const returnOrderTableHeaderList = [
  {
    id: "1",
    name: "ITEM"
  },
  {
    id: "2",
    name: "CUSTOMER"
  },
  {
    id: "3",
    name: "ORDER DATE"
  },
  {
    id: "4",
    name: "INVOICE #"
  },
  {
    id: "5",
    name: "EXT. PRICE"
  },
  {
    id: "6",
    name: "RETURNABLE QTY."
  },
  {
    id: "7",
    name: "RETURN QTY."
  },
  {
    id: "8",
    name: "REPLACE QTY."
  },
];

export const documentsHeaderList = [
  {
    id: "1",
    name: "NAME"
  },
  {
    id: "2",
    name: "Type"
  },
  {
    id: "3",
    name: "STATUS"
  },
  {
    id: "4",
    name: "LAST MODIFIED"
  },
  {
    id: "5",
    name: "DESCRIPTION"
  },
];

export const documentsDataList = [
  {
    id: "1",
    file: "dan_edw_0293458.pdf",
    typeId: "178246",
    documentSaveType: "Tax exempt certificate",
    readOnly: false,
    status: "To be uploaded",
    lastModified: "03/21/22",
    description: "This description text gets truncate This description text gets truncate This description text gets truncate…",
  },
  {
    id: "2",
    file: "Raymond_Kroc_bill3.docx",
    typeId: "178242",
    documentSaveType: "Specs",
    readOnly: false,
    status: "Uploaded",
    lastModified: "03/08/22",
    description: "Important",
  },
  {
    id: "3",
    file: "nick_light.jpg",
    typeId: "178240",
    documentSaveType: "Product Photo",
    readOnly: true,
    status: "Uploaded",
    lastModified: "03/21/22",
    description: "This description text gets truncate…",
  },
  {
    id: "4",
    file: "Raymond_Kroc_bill3.docx",
    typeId: "178248",
    documentSaveType: "Specs",
    readOnly: false,
    status: "Uploaded",
    lastModified: "03/08/22",
    description: "Important",
  },
  {
    id: "5",
    file: "dan_edw_0293458.pdf",
    typeId: "178257",
    documentSaveType: "Tax exempt certificate",
    readOnly: false,
    status: "Uploaded",
    lastModified: "03/21/22",
    description: "This description text gets truncate…",
  },
];

export const cashFilterOption = {
  today: "today",
  lastWeek: "lastWeek",
  prevMonth: "prevMonth",
  currMonth: "currMonth"
}


export const scopesOptions = [
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Admin",
    value: "Admin",
  },
  {
    label: "Intern",
    value: "Intern",
  },
  {
    label: "New Hash",
    value: "New Hash",
  },
  {
    label: "Accountant",
    value: "Accountant",
  },
  {
    label: "Mresc Customer",
    value: "MrescCustomer",
  },
  {
    label: "Credit card only customer",
    value: "creditCardOnlyCustomer",
  },
  {
    label: "Free shipping customer",
    value: "isFreeShippingCustomer",
  },
  {
    label: "Fixed Margin Rate",
    value: "fixedMarginRate",
  },
  {
    label: "Hold for Credit Defaulted",
    value: "HoldForCreditDefaulted",
  },
  {
    label: "Opt Out",
    value: "optOut",
  },
];

export const permissionOptions = [
  {
    id: "canViewLogs",
    label: "Can view logs",
  },
  {
    id: "canManageProducts",
    label: "Can manage products",
  },
  {
    id: "canActivateCustomer",
    label: "Can activate customers",
  },
  {
    id: "canApproveCredit",
    label: "Can approve credit",
  },
  {
    id: "canSetFixedMargin",
    label: "Can set fixed margin",
  },
  {
    id: "canSetFreeShipping",
    label: "Can set free shipping",
  },
  {
    id: "canSetCreditHold",
    label: "Can set credit hold",
  },
  {
    id: "canEditDueDate",
    label: "Can edit due date",
  },
  {
    id: "canEditInitialOrderMargin",
    label: "Can edit initial order margin",
  },
  {
    id: "canEditOrderShipmentHold",
    label: "Can edit order shipment hold",
  },
  {
    id: "canReviewProducts",
    label: "Can review products",
  }, ,
  {
    id: "adjustStockFlag",
    label: "Can adjust stock"
  }
];

export const userAdminPermissionOptions = [
  {
    id: "canView",
    label: "Can view all users",
  },
  {
    id: "canInvite",
    label: "Can invite new users",
  },
  {
    id: "canReview",
    label: "Can review new accounts",
  },
  {
    id: "canLock",
    label: "Can lock user accounts",
  },
  {
    id: "canSetUserPermissions",
    label: "Can set user permission",
  },
  {
    id: "canDeactivate",
    label: "Can deactivate accounts",
  }
];

export const selectOptions = [
  { label: "Data Manager", value: "DataManager" },
  { label: "Sales Person", value: "Salesman" },
  { label: "Warehouse Person", value: "WarehousePerson" },
  { label: "Accounting User", value: "AccountingUser" },
]

export const supportLink = "https://jumperware.atlassian.net/servicedesk/customer/portals";
export const addManufactureLink = "/manager/mProd_AddMaker"
export const addVendorLink = "/manager/mProd_AddVendor"
export const uploadLink = "/manager/mDoc_Upload"
const createData = (
  image,
  item,
  manufacturer,
  qtyShipment,
  qtyStorage,
  qtyPutStorage,
  manufacturerData,
  manufacturerName,
) => {
  return {
    image,
    item,
    manufacturer,
    qtyShipment,
    qtyStorage,
    qtyPutStorage,
    manufacturerData,
    manufacturerName,
  };
};

export const storageData = [
  createData(
    "05/19/23",
    'TCL LED 30W Electric Circle Ballast Adapter',
    19782345,
    "4",
    "1",
    "Delivered",
    "24sc-97AD98YA11",
    "Sylvania"
  ),
  createData(
    "05/19/23",
    "Raymond Kroc Shopping List Raymond Kroc Shopping List",
    19782345,
    "4",
    "1",
    "Delivered",
    "2738948CASD",
    "Sylvania"
  ),
];

const createStorageItemsData = (
  name,
  itemId,
  productId,
  manufacturer,
  invoiceId,
  shipmentId,
  qtyInInvoice,
  previousDeliveriedQty,
  thisDeliveriesQty,
  toBeDeliveriesQty,
  value,
) => {
  return {
    name,
    itemId,
    productId,
    manufacturer,
    invoiceId,
    shipmentId,
    qtyInInvoice,
    previousDeliveriedQty,
    thisDeliveriesQty,
    toBeDeliveriesQty,
    value,
  };
};

export const storageItemsData = [
  createStorageItemsData(
    "TCL LED 30W Electric Circle Ballast Adapter",
    '24sc-97AD98YA11',
    '3487389',
    "Sylvania",
    '0045376',
    "147141",
    "3",
    "10",
    "2",
    "2",
    "$0.00"
  ),
  createStorageItemsData(
    "Leviton 120V Multi Base Bi-Pin Halogen Bulb",
    '2738948CASD',
    '3487389',
    "Sylvania",
    '0045376',
    "147141",
    "0",
    "12",
    "3",
    "3",
    "$4.55"
  ),
];

export const creditCardOption = [
  { value: 1, label: "Visa" },
  { value: 2, label: "Master" },
  { value: 3, label: "American Express" },
  { value: 4, label: "Discover" },
];

export const dateEnum = {
  dateToday: "TODAY",
  mdFormat: "MMM DD",
  ymdFormat: "YYYY-MM-DD",
  monthYear: "MMM",
  mmmddyy: "MMM DD, YY",
  mmddyyyy: "MM-DD-YYYY",
  mmddyyyyhhmm: "MMM D, YYYY HH:MM",
  mmmmyyyy: "MMMM YYYY"
};

export const dayJsFormat = {
  MMMDYYYY: 'll',
  MMDDYYYY: 'L'
}

export const movingAvgCostFilterOption = {
  lastWeek: "lastWeek",
  prevMonth: "prevMonth",
  currMonth: "currMonth",
  allTime: "allTime"
}

export const minDate1970 = '1970-01-01';
