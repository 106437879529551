/* eslint-disable */
import "@fontsource/inter";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import _ from "loadsh";
import { MutableRefObject, useCallback, useEffect, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import CustomerAndCartInfo from "apps/pos/AddProductPageComponent/CustomerAndCartInfo";
import ProductSuggestion from "apps/pos/AddProductPageComponent/ProductSuggestion";
import SearchProduct from "apps/pos/AddProductPageComponent/SearchProduct";
import LinearLoader from "components/LinearProgress/LinearProgress";

import { PosImage, PosSnackbar } from "apps/pos/Common/uiCore";
import texts from "apps/pos/locales";
import { IOption } from "apps/product/Components/CustomDropDown/type";
import { availabilityStatus, commonConstants, statusValue } from "apps/product/helper/commonConstants";
import { documentByModule, PaginationFormat } from "constants/enum";
import images from "constants/images";
import {
  PosFlowDataProp,
  UploadedDocumentItemProp,
} from "constants/interfaces";
import { Paths, Tabs } from "enums";
import { CustomerDocumentData, RootState } from "models";
import { authorizationToken, calculatePriceItemMuEach, getLocalStoraGeItem, showScrollCartPage } from "utils/utils";
import { CartDrawer } from "./Drawers";
import OrderDetails from "./OrderDetails";
import ImportQuoteModel from "./pages/importQuote/importQuoteModel";

import {
  addToCartDrawer,
  arrowDownIndexProductPage,
  arrowDraftReset,
  arrowReset,
  arrowResetProductPage,
  arrowUpIndexProductPage,
  clearSearchCustomer,
  clearSearchProduct,
  editProduct,
  getNewPrice,
  getShippingFacilitiesRequest,
  lastProductQty,
  lastViewProductIndex,
  notAddedNewProduct,
  priceSchedulesRequest,
  productsDataClear,
  productsRequest,
  salespersonsRequest,
  savedDraftsReset,
  searchProductRequest,
  setHeaderHeightCount,
  setJobNameForAll,
  setMarkAsQuoteReducer,
  setSelectedProductIndex,
  setShippingInstructionNote,
  setStoreCreditAmout,
  stockByWarehouseStart,
  suggestedProductsRequest,
  takersRequest
} from "sagas/pos/actions";
import { beforeUnloadCart, keys } from "utils/helper";


import {
  addNewCustomerClear,
  clearCartBackupProducts,
  isGpmCustomFlag,
  isOrderTotalCustomFlag,
  loadCartRequest,
  loadProductDraftAction,
  loadReturnDataAction,
  recipientInfoSwitchInfoData,
  setBackupProducts,
  setCustomerAlertHide,
  setExemptTax,
  setPosFlowData,
  setShipToAddress,
  setTabIndexNumber,
  setToPickUp,
  skipOrdererInfo
} from "sagas/persistReducer/actions";
import { customerDocumentRequest, taxDocumentRequest } from "sagas/pos/documentUpload/actions";
import { arrowResetImportQuote } from "sagas/pos/importQuotes/action";

import "./addProductPage.scss";
import "./poscommonstyling.scss";

const useStyles = makeStyles({
  table: {
    minWidth: 500,
  },
  root: {
    position: "unset",
    "& .MuiTableHead-root": {
      position: "unset",
      color: "black",
    },
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: 30,
      borderBottomLeftRadius: 30,
    },
  },
});

const AddProductPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [suggestionListVisible, setSuggestionListVisible] =
    useState<boolean>(true);
  const dispatch = useDispatch();
  const { cartId } = useParams<{ cartId: string }>();

  const {
    getTabShipping,
    searchProducts,
    productsData,
    takerDetails,
    suggestedProductsData,
    selectedProductIndex,
    searchProductsTotal,
    availableLocationInfo,
    notAddedNewProductData,
    isWareHouseDrawer,
    getProductIndex,
    headerHeightCount,
    shippingFacilities,
    priceScheduleDetails,
    salesPersonDetails
  } = useSelector<RootState, any>((state) => state.pos);

  const {
    setNoLoadDetails,
    posFlowData,
    loadCartDetails,
    loadCartStatus,
    backUpProducts,
    clearCartBackupData,
    shippingAddressSelected,
    loadReturnData,
    loadProductDraft,
    cartLoading,
    customerAddSuccess,
    isGPMCustom,
    isOrderTotalCustom,
    isPageReload,
    isManuallySetTaxExempt
  } = useSelector<RootState, PosFlowDataProp>((state) => state.persistReducer);

  const { customerDocumentDetails, taxDocumentDetails } = useSelector<
    RootState,
    CustomerDocumentData
  >((state) => state.documentReducer);

  const { shipToByFacility, customer, products } = loadCartDetails || {}
  const { customer: posCustomer } = posFlowData || {}
  const [takerData, setTakerData] = useState<any>({});
  const [enterPress, setEnterPress] = useState<boolean>(false);
  const [downPress, setDownPress] = useState<boolean>(false);
  const [upPress, setUpPress] = useState<boolean>(false);
  const [selectSchedule, setSelectSchedule] = useState<boolean>(false);
  const [selectSalesman, setSelectSalesman] = useState<boolean>(false);
  const [selectTaker, setSelectTaker] = useState<boolean>(false);
  const [addProduct, setAddProduct] = useState<boolean>(false);
  const [purchaseOrder, setPurchaseOrder] = useState<boolean>(false);
  const [shippingAddress, setShippingAddress] = useState<boolean>(false);
  const [suggestedProducts, setSuggestedProducts] = useState([]);
  const [searchProductsData, setSearchProductsData] = useState([]);
  const [showTaker] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [removeItem, setRemoveItem] = useState(false);
  const [isRemoveToastVisible, setIsRemoveToastVisible] = useState(false);
  const [updatedProductsFlag, setUpdatedProductsFlag] = useState<boolean>(true);
  const [selectTaxExemptFlag, setSelectTaxExemptFlag] = useState<number>();
  const [selectCustomer, setSelectCustomer] = useState<boolean>(false);
  const [showSaveForLater, setShowSaveForLater] = useState<boolean>(false);
  const [shipToByFacilityId, setShipToByFacilityId] = useState<number>(
    shipToByFacility?.facilityId
  );
  const [modal, setModal] = useState({ isOpen: false, modalTitle: "" });
  const [pageSize, setPageSize] = useState<number>(1);
  const [purchaseOrderNum, setPurchaseOrderNum] = useState<any>(
    loadCartDetails?.purchaseOrderNum
  );
  const [pickUpSelected, setPickUpSelected] = useState<boolean>(false);
  const [cartEmpty, setCartEmpty] = useState<boolean>(true);
  const { shared, all, current } = documentByModule;

  const [shippingAddressApiCallStart, setShippingAddressApiCallStart] =
    useState<boolean>(false);
  const [scheduleApiCallStart, setScheduleApiCallStart] =
    useState<boolean>(false);
  const [salesPersonApiCallStart, setSalesPersonApiCallStart] =
    useState<boolean>(false);
  const [takerApiCallStart, setTakerApiCallStart] = useState<boolean>(false);
  const [selectItemImportModel, setSelectItemImportModel] = useState(false);

  // new
  const [isCartCleared, setIsCartCleared] = useState(false);
  const [productIndex, setProductIndex] = useState(-1);
  const [openModel, setOpenModel] = useState(false);
  const [customerInfo, setCustomerInfo] = useState(false);
  const [productData, setProductData] = useState<any>({});
  const [saveForLaterClickedFrom, setSaveForLaterClickedFrom] = useState(null)   // intially null, if it is true -> direct close, false -> manually clicked on save for later

  useEffect(() => {
    dispatch(savedDraftsReset());
  }, []);

  const { addDocument } = posFlowData || {};

  useEffect(() => {
    if (cartId) {
      const { customer } = posFlowData || {}
      if (!isWareHouseDrawer) {
        dispatch(
          customerDocumentRequest({
            customerId: Number(customer?.personId) || Number(customer?.customerId),
            buildingId: 0,
            orderId: 0,
          })
        );
        dispatch(taxDocumentRequest(Number(customer?.companyID)));
      }
    }
  }, [shipToByFacilityId, cartId]);

  useEffect(() => {
    if (!isManuallySetTaxExempt) {
      const { addDocument } = posFlowData || {};

      if (customerDocumentDetails?.length || taxDocumentDetails?.length) {
        let readOnlyDocument = [];

        for (let i = 0; i < customerDocumentDetails?.length; i++) {
          const { objectId, description, documentSaveType, typeName, name, url, fileSizeText, id, modifiedAt, modifiedBy } = customerDocumentDetails[i];
          let read = {};
          read["customerId"] = objectId;
          read["description"] = description;
          read["documentSaveType"] = documentSaveType;
          read["documentSaveTypeName"] = typeName;
          read["file"] = name;
          read["fileUrl"] = url;
          read["locationSaveType"] =
            documentSaveType === shared
              ? all
              : current;
          read["size"] = fileSizeText;
          read["typeId"] = id;
          read["uploadedBy"] = modifiedBy;
          read["readOnly"] = true;
          read["lastModified"] = modifiedBy;
          read["modifiedAt"] = modifiedAt;
          readOnlyDocument.push(read);
        }

        for (let i = 0; i < taxDocumentDetails?.length; i++) {
          const { buyerCompanyID, docDec, docType, typeName, certificateName, docURL, docSize, companyDocID, createdDate } = taxDocumentDetails[i];
          const read = {};
          read["customerId"] = buyerCompanyID;
          read["description"] = docDec;
          read["documentSaveType"] = docType;
          read["documentSaveTypeName"] = typeName;
          read["file"] = certificateName;
          read["fileUrl"] = docURL;
          read["locationSaveType"] =
            docType === shared
              ? all
              : current;
          read["size"] = docSize;
          read["typeId"] = companyDocID;
          read["uploadedBy"] = createdDate;
          read["readOnly"] = true;
          read["lastModified"] = createdDate;
          read["modifiedAt"] = createdDate;
          readOnlyDocument.push(read);
        }

        let uploadData = [];

        addDocument?.map((item: UploadedDocumentItemProp) => {
          const { originalFile } = item;
          if (originalFile) {
            uploadData.push(item);
          }
        });

        let allDoc = readOnlyDocument.concat(uploadData);

        const data = {
          ...posFlowData,
          addDocument: allDoc,
        };
        dispatch(setPosFlowData(data));

      } else {
        const uploadData = [];

        addDocument?.map((item: UploadedDocumentItemProp) => {
          const { documentSaveTypeName, readOnly } = item || {}
          if (documentSaveTypeName?.toLowerCase() !== documentByModule.taxExemptCerti.toLowerCase() || !readOnly) {
            uploadData.push(item);
          }
        });

        const data = {
          ...posFlowData,
          addDocument: uploadData,
        };
        dispatch(setPosFlowData(data));
      }
    }
  }, [customerDocumentDetails, taxDocumentDetails]);

  const getLoadCartRequest = () => {
    const currentWorkspace = JSON.parse(localStorage.getItem("workspace"));
    const currentTaker = JSON.parse(localStorage.getItem("taker"));
    let preparedData = {
      stockWarehouseId: currentWorkspace.stockWarehouseId,
      customerId: posCustomer?.customerId,
      takerId: currentTaker?.personId,
      cartId: cartId ? Number(cartId) : cartId,
    };
    dispatch(getShippingFacilitiesRequest(customer?.personId));
    dispatch(loadCartRequest(preparedData));
  };

  useEffect(() => {
    if (cartId && cartId !== `${cartId}`) {
      if (cartId) {
        window.history.replaceState("", "", `${Paths.posCart}/${cartId}`);
      } else {
        navigate(Paths.posNewOrder);
      }
    }
  }, [cartId, cartId]);

  useEffect(() => {
    if (loadReturnData) {
      getLoadCartRequest();
      setTimeout(() => {
        dispatch(loadReturnDataAction(false));
      }, 1000);
    }
  }, [loadReturnData]);

  const allocatedDraftRowRef = useRef<any>();

  useEffect(() => {
    if (loadProductDraft && !isWareHouseDrawer) {
      getLoadCartRequest();
      setTimeout(() => {
        dispatch(loadProductDraftAction(false));
      }, 2000);
    }
  }, [loadProductDraft]);


  useEffect(() => {
    if (isCartCleared) {
      const timeout = setTimeout(() => setIsCartCleared(false), 3000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isCartCleared]);

  useEffect(() => {
    if (enterPress && !openModel) {
      handleProductSelect(searchProductsData[selectedProductIndex]);
      setEnterPress(false);
    }
  }, [enterPress, !openModel]);

  const scrollToSetting = (selectedProductIndex) => {
    const elementId = document?.getElementById(
      `tableRowRef${selectedProductIndex}`
    );
    elementId?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "end",
    });
  };


  useEffect(() => {
    if (addDocument) {
      let objectFiles = addDocument.filter((item: UploadedDocumentItemProp) => item?.originalFile);
      let isIncorrectFile = false;
      objectFiles.forEach((item: UploadedDocumentItemProp) => {
        if (!item?.originalFile?.type) {
          isIncorrectFile = true;
        }
      })
    }
  }, [isPageReload])



  // start
  const addProductPageProductSearchRef = useRef<any>();
  const cartDrawerRef = useRef<any>();
  const paymentMethodRef = useRef<React.Ref<HTMLDivElement>>();
  const mainProduct = useRef<any>();
  const modalRef = useRef<React.Ref<HTMLDivElement>>();

  function SuggestionPress(e) {
    if (customerInfo) return;
    const { keyCode } = e;
    if (
      document?.activeElement !== addProductPageProductSearchRef.current &&
      suggestedProductsData &&
      !cartDrawerRef.current &&
      cartEmpty &&
      !openModel
    ) {
      suggestedProducts?.map((product, index) => {
        if (keyCode === 49 + index || keyCode === 97 + index) {
          setProductData(product);
          dispatch(stockByWarehouseStart([product?.productId]));
          showProductIncart(product?.productId);
        }
      });
    }
  }
  useEffect(() => {
    document.addEventListener("keydown", SuggestionPress);

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener("keydown", SuggestionPress);
    };
  }, [suggestedProducts?.length, cartEmpty, !openModel, customerInfo]);
  // end

  useEffect(() => {
    async function downPressHandler() {
      if (downPress) {
        await dispatch(arrowDownIndexProductPage(searchProductsData?.length));
        scrollToSetting(selectedProductIndex + 1);
        setDownPress(false);
      }
    }
    downPressHandler();
  }, [downPress]);

  useEffect(() => {
    if (upPress) {
      if (selectedProductIndex > -1) {
        scrollToSetting(selectedProductIndex);
      }
      setUpPress(false);
    }
  }, [upPress]);

  useEffect(() => {
    const { customer } = posFlowData || {}
    const { data } = suggestedProductsData || {}
    const { stockWarehouseId } = getLocalStoraGeItem("workspace") || {};
    const preparedData = {
      stockWarehouseId,
      buyerId: customer?.personId || customer?.customerId,
    };
    if (!data?.length && !isWareHouseDrawer) {
      dispatch(suggestedProductsRequest(preparedData));
    }
    dispatch(setShipToAddress(true));
    !customerAddSuccess && dispatch(addNewCustomerClear());
  }, []);

  useEffect(() => {
    const customerExemptFlag = customer?.taxExemptFlag;
    setSelectTaxExemptFlag(customerExemptFlag);
  }, [customer]);

  useEffect(() => {
    if (loadCartStatus === "error") {
      dispatch(clearSearchCustomer());
      navigate(Paths.posNewOrder);
    }
  }, [loadCartStatus]);

  useEffect(() => {
    setTakerData(takerDetails);
  }, [takerDetails]);


  useEffect(() => {
    if (shippingAddressApiCallStart && shippingFacilities.length) {
      setShippingAddress(true);
      setShippingAddressApiCallStart(false);
    }
  }, [shippingAddressApiCallStart, shippingFacilities]);

  useEffect(() => {
    if (scheduleApiCallStart && priceScheduleDetails?.data?.length) {
      setSelectSchedule(true);
      setScheduleApiCallStart(false);
    }
  }, [scheduleApiCallStart, priceScheduleDetails]);

  useEffect(() => {
    if (salesPersonApiCallStart && salesPersonDetails?.data?.length) {
      setSelectSalesman(true);
      setSalesPersonApiCallStart(false);
    }
  }, [scheduleApiCallStart, salesPersonDetails]);

  useEffect(() => {
    if (takerApiCallStart && takerDetails?.data?.length) {
      setSelectTaker(true);
      setTakerApiCallStart(false);
    }
  }, [takerApiCallStart, takerDetails]);

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const selectScheduleWrapper = () => {
    dispatch(arrowReset());
    dispatch(arrowResetProductPage());
    if (!priceScheduleDetails?.data?.length) {
      setScheduleApiCallStart(true);
      dispatch(priceSchedulesRequest({ pageSize, size: 20 }));
    } else {
      setSelectSchedule(true);
    }
    setSelectSalesman(false);
    setShippingAddress(false);
    setSelectTaker(false);
    setPurchaseOrder(false);
    setAddProduct(false);
    setState({ ...state, right: true });
  };

  // temp commented
  // const addProductWrapper = () => {
  //   dispatch(arrowReset());
  //   dispatch(arrowResetProductPage());
  //   setSelectSchedule(false);
  //   setSelectSalesman(false);
  //   setShippingAddress(false);
  //   setSelectTaker(false);
  //   setPurchaseOrder(false);
  //   setAddProduct(true);
  //   setState({ ...state, right: true });
  // };

  const selectShippingWrapper = () => {
    dispatch(arrowReset());
    dispatch(arrowResetProductPage());
    setSelectSchedule(false);
    setSelectSalesman(false);
    setSelectTaker(false);
    setPurchaseOrder(false);
    if (!shippingFacilities?.length) {
      setShippingAddressApiCallStart(true);
      const { customer, isCartAvailable } = posFlowData || {}
      const { customer: loadCartDetailsCustomer } = loadCartDetails || {}
      dispatch(
        getShippingFacilitiesRequest(
          customer?.personId || (isCartAvailable
            ? loadCartDetailsCustomer?.customerId
            : customer?.customerId)
        )
      );
    } else {
      setShippingAddress(true);
    }
    setAddProduct(false);
    setState({ ...state, right: true });
  };

  const selectSalesmanWrapper = () => {
    dispatch(arrowReset());
    dispatch(arrowResetProductPage());
    setSelectSchedule(false);
    if (!salesPersonDetails?.data?.length) {
      dispatch(salespersonsRequest());
      setSalesPersonApiCallStart(true);
    } else {
      setSelectSalesman(true);
    }
    setShippingAddress(false);
    setSelectTaker(false);
    setPurchaseOrder(false);
    setAddProduct(false);
    setState({ ...state, right: true });
    (document.activeElement as HTMLElement).blur();
  };

  const selectTakerWrapper = () => {
    dispatch(arrowReset());
    dispatch(arrowResetProductPage());
    setSelectSchedule(false);
    setSelectSalesman(false);
    setShippingAddress(false);
    if (!takerDetails?.data?.length) {
      setTakerApiCallStart(true);
      dispatch(takersRequest());
    } else {
      setSelectTaker(true);
    }
    setPurchaseOrder(false);
    setAddProduct(false);
    setState({ ...state, right: true });
    (document.activeElement as HTMLElement).blur();
  };

  const selectPurchaseWrapper = () => {
    setSelectSchedule(false);
    setSelectSalesman(false);
    setShippingAddress(false);
    setSelectTaker(false);
    setPurchaseOrder(true);
    setAddProduct(false);
    setState({ ...state, right: true });
    (document.activeElement as HTMLElement).blur();
  };

  const selectAddProductWrapper = () => {
    if (notAddedNewProductData) {
      return;
    }
    setSelectSchedule(false);
    setSelectSalesman(false);
    setShippingAddress(false);
    setSelectTaker(false);
    setPurchaseOrder(false);
    setAddProduct(true);
    setState({ ...state, right: true });
    (document.activeElement as HTMLElement).blur();
    dispatch(notAddedNewProduct(true));
  };

  const showProductIncart = async (id) => {
    const currentWorkspace = getLocalStoraGeItem("workspace");
    const { stockWarehouseId } = currentWorkspace || {};
    dispatch(addToCartDrawer(true));
    const { customer, schedule } = posFlowData || {}
    const data = {
      stockWarehouseId: stockWarehouseId,
      productId: id,
      personId: customer?.personId || customer?.customerId,
      priceScheduleId: schedule?.id
        ? schedule?.id
        : customer?.priceScheduleId,
    };
    dispatch(productsRequest(data));
    setSelectSchedule(false);
    setSelectSalesman(false);
    setShippingAddress(false);
    setSelectTaker(false);
    setPurchaseOrder(false);
    setAddProduct(false);
    dispatch(lastProductQty(0));
    dispatch(lastViewProductIndex(null));
    setState({ ...state, right: true });
  };

  const editProductIncart = (data) => {
    dispatch(editProduct(data));
    if (data.updatedPrice) {
      dispatch(getNewPrice(data.updatedPrice));
    }
    setSelectSchedule(false);
    setSelectSalesman(false);
    setShippingAddress(false);
    setSelectTaker(false);
    setPurchaseOrder(false);
    setState({ ...state, right: true });
  };

  useEffect(() => {
    const data = {
      ...posFlowData,
      cartProductDetails: productsData,
    };
    dispatch(setPosFlowData(data));
  }, [productsData]);

  useEffect(() => {
    setCartEmpty(posFlowData?.cartProducts?.length ? false : true);
  }, []);

  useEffect(() => {
    if (!cartEmpty) {
      setProductSearch("");
    }
  }, [cartEmpty]);

  const [productSearch, setProductSearch] = useState<string>("");
  const importQuotePageProductSearchRef = useRef<React.LegacyRef<HTMLInputElement> | HTMLInputElement | null>(null);
  const customerAndCardInfoRef = useRef<MutableRefObject<any>>();
  const addProductPageRef = useRef<React.LegacyRef<HTMLDivElement> | HTMLDivElement>();
  const tableRowRef = useRef<React.Ref<HTMLTableRowElement>>();
  const drawerRef = useRef<React.Ref<HTMLDivElement>>();
  const addProductPageSearchRef = useRef<React.LegacyRef<HTMLDivElement>>();
  const availabilitydropdownRef = useRef<HTMLLIElement | null>(null);

  function handleKeyPressDown(e) {
    const { keyCode } = e;
    const { enter, forwardslash, divide, downarrow, uparrow, esc, f1, f2, f3, f4, f5, f6, f7, f8, f9, f10, n, tab } = keys;
    const isButtonFocused = document.activeElement.tagName.toLowerCase() === 'button'
    if (!drawerRef.current) {
      if (
        document?.activeElement !== addProductPageProductSearchRef.current &&
        addProductPageSearchRef.current
      ) {
        if (keyCode === enter && !availabilitydropdownRef.current && !isButtonFocused) {
          if (!cartDrawerRef.current) {
            setEnterPress(true);
          }
        }
      }
      if (keyCode === forwardslash || keyCode === divide) {
        setTimeout(() => {
          addProductPageProductSearchRef.current.focus();
          setSuggestionListVisible(false);
          dispatch(arrowResetProductPage());
        }, 10);
      } else if (keyCode === downarrow && !openModel && !availabilitydropdownRef.current) {
        if (document?.activeElement === addProductPageProductSearchRef.current) {
          addProductPageProductSearchRef.current.blur();
          dispatch(arrowResetProductPage());

        }
        if (!posFlowData?.isModalOpen && !cartDrawerRef.current) {
          setDownPress(true);
        }
      }
      else if (keyCode === uparrow && !openModel && !availabilitydropdownRef.current) {
        if (!posFlowData?.isModalOpen && !cartDrawerRef.current) {
          dispatch(arrowUpIndexProductPage());
          setUpPress(true);
        }
      }

      if (e.keyCode === esc) {
        showScrollCartPage();
        setProductSearch("");
        dispatch(clearSearchProduct());
        setShippingAddress(false);
        remove();
        setSearchProductsData([]);
        setFilteredSearchProduct([]);
        if (posFlowData?.cartProducts?.length > 0) {
          setCartEmpty(false);
        } else {
          setSuggestionListVisible(true);
        }
      }
    }

    if (customerAndCardInfoRef?.current && !cartDrawerRef.current && !modalRef.current && !drawerRef.current && !paymentMethodRef.current) {
      if (keyCode === f1) {
        dispatch(setTabIndexNumber(0));
        navigate(`${Paths.customer}?cart=${cartId}`, { state: { detail: posFlowData?.customer } });
        e.preventDefault()
      }
      if (keyCode === f2) {
        const data = {
          ...posFlowData,
          isModalOpen: true,
        };
        dispatch(setPosFlowData(data));
        selectShippingWrapper();
        dispatch(setToPickUp(false));
        dispatch(setShipToAddress(false));
        (document.activeElement as HTMLElement).blur();
      }
      if (keyCode === f3) {
        selectPurchaseWrapper()
        e.preventDefault()
      }
      if (keyCode === f4) {
        selectScheduleWrapper();
        dispatch(arrowDraftReset());
        (document.activeElement as HTMLElement).blur();
      }
      // Might be needed if the shortcut is enabled
      // if (keyCode === f5) {
      //   e.preventDefault()
      //   if (!products?.length) {
      //     selectSalesmanWrapper();
      //     dispatch(arrowReset());
      //     (document.activeElement as HTMLElement).blur();
      //   }
      // }
      // if (keyCode === f6) {
      //   e.preventDefault()
      //   selectTakerWrapper();
      //   (document.activeElement as HTMLElement).blur();
      // }
      if (keyCode === f7) {
        handleCloseDrawer();
        (document.activeElement as HTMLElement).blur();
        e.preventDefault()
      }
      if (keyCode === f8) {
        handleCloseDrawer();
        (document.activeElement as HTMLElement).blur();
        e.preventDefault()
      }
      if (keyCode === f9) {
        handleCloseDrawer();
        (document.activeElement as HTMLElement).blur();
        e.preventDefault()
      }
      if (keyCode === f10) {
        e.preventDefault()
        handleCloseDrawer();
        //Keeping this code ,might need 
        // (document.activeElement as HTMLElement).blur();
      }
    }
    if (keyCode === n && e.altKey && document.activeElement !== importQuotePageProductSearchRef?.current && !addProduct && !openModel && !customerAndCardInfoRef?.current) {
      e.preventDefault();
      selectAddProductWrapper()
      addProductPageProductSearchRef.current.focus()
      setSelectItemImportModel(false);
    }
    if (keyCode !== tab && keyCode !== enter) {
      const data = {
        ...posFlowData,
        savedCart: {
          ...loadCartDetails,
          customer: {
            ...customer,
            buyOnBanNote: posCustomer?.buyOnBanNote,
          },
        },
        shiptoFacilities: shippingAddressSelected
          ? posFlowData?.shiptoFacilities
          : shipToByFacility,
        addProductPage: true,
      };
      dispatch(setPosFlowData(data));
    }
  }

  useHotkeys('home', (e) => {
    if (e?.keyCode !== keys.home) return
    dispatch(setSelectedProductIndex(0));
    scrollToSetting(0);
  }
  )
  useHotkeys('end', (e) => {
    if (e?.keyCode !== keys.end) return;
    dispatch(setSelectedProductIndex(searchProductsData?.length - 1));
    scrollToSetting(searchProductsData?.length - 1);
  }
  )

  const searchProductsAPI = async (keywords) => {
    await setSearchProductsData([]);
    if (keywords?.length === 0) {
      dispatch(clearSearchProduct());
    }
    if (keywords.length >= 1) {
      setLoader(true);
      debounceFn(keywords);
    }
  };

  const handleDebounceFn = async (keywords) => {
    const currentWorkspace = getLocalStoraGeItem("workspace");
    const { stockWarehouseId } = currentWorkspace || {};
    const { customer } = posFlowData || {}

    const body = {
      page: 1,
      size: 20,
      stockWarehouseId: stockWarehouseId,
      keywords,
      personId: customer?.personId || customer?.customerId
    };
    dispatch(searchProductRequest(body));
  };

  function handleScroll() {
    const currentWorkspace = getLocalStoraGeItem("workspace");
    const { stockWarehouseId } = currentWorkspace || {};
    setPageSize(pageSize + 1);
    dispatch(
      searchProductRequest({
        page: pageSize + 1,
        size: 20,
        stockWarehouseId: stockWarehouseId,
        keywords: productSearch,
        personId: customer?.personId || customer?.customerId,
      })
    );
  }

  const debounceFn = useCallback(_.debounce(handleDebounceFn, 1000), [
    posFlowData,
  ]);

  useEffect(() => {
    setSearchProductsData(searchProducts);
    setLoader(false);
  }, [searchProducts]);

  useEffect(() => {
    if (availableLocationInfo?.length) {
      dispatch(stockByWarehouseStart(availableLocationInfo));
    }
  }, [availableLocationInfo]);

  useEffect(() => {
    setSuggestedProducts(suggestedProductsData);
  }, [suggestedProductsData]);

  useEffect(() => {
    if (!posFlowData?.isModalOpen) {
      document.addEventListener("keydown", handleKeyPressDown);

      // Don't forget to clean up
      return function cleanup() {
        document.removeEventListener("keydown", handleKeyPressDown);
      };
    }
  }, [posFlowData?.cartProducts, openModel]);

  useEffect(() => {
    setShipToByFacilityId(shipToByFacility?.facilityId);
    const salesPersonAvailable = salesPersonDetails?.data?.filter((taker) => taker.personId === loadCartDetails?.salesPersonId);
    const { SellerId, LoggedInUserFullName } = authorizationToken("Authorization");

    const currentTaker = JSON.parse(localStorage.getItem("taker"))
    const isTakerSalesman = !!currentTaker?.roles?.find((role) => role?.id === 7)

    const salesPerson = salesPersonAvailable?.length !== 0 ? salesPersonAvailable?.[0] : (isTakerSalesman && currentTaker?.ssoId) ? currentTaker : SellerId ? { ssoId: SellerId, salespersonName: LoggedInUserFullName } : null

    const salesPersonData = { ...salesPerson, salespersonName: salesPersonAvailable?.length ? salesPerson?.salespersonName : currentTaker?.fullName ? currentTaker?.fullName : '' }

    if (products?.length && !setNoLoadDetails) {
      const newProducts = products.map(element => {
        return { ...element, initialPriceData: element.posRetailPrice };
      });
      let data;
      const { isPosGeneric, fullName, companyName, phone, email } = loadCartDetails?.customer || {}
      if (isPosGeneric && fullName) {
        const splitFullname = (fullName?.split(" "))
        const genericUserFirstName = splitFullname?.slice(0, -1).join(" ");
        const genericUserLastName = splitFullname[splitFullname?.length - 1];

        data = {
          ...posFlowData,
          salesman: salesPersonData,
          savedCart: {
            ...loadCartDetails,
            customer: {
              ...customer,
              buyOnBanNote: posCustomer?.buyOnBanNote,
            },
          },
          cartProducts: newProducts,
          shiptoFacilities: shipToByFacility,
          isModalOpen: false,
          addProductPage: true,
          firstName: genericUserFirstName || "",
          lastName: genericUserLastName || "",
          phone: phone || "",
          email: email || "",
          companyName: companyName || "",
          isStorePickup: false
        };
        dispatch(recipientInfoSwitchInfoData(false));
        dispatch(skipOrdererInfo(false))
        dispatch(setPosFlowData(data));
      } else {
        data = {
          ...posFlowData,
          salesman: salesPersonData,
          savedCart: {
            ...loadCartDetails,
            customer: {
              ...customer,
              buyOnBanNote: posCustomer?.buyOnBanNote,
            },
          },
          cartProducts: newProducts,
          shiptoFacilities: shipToByFacility,
          isModalOpen: false,
          addProductPage: true,
        };
      }
      dispatch(setPosFlowData(data));
      if (posFlowData?.defaultTaxStatus) {
        dispatch(setExemptTax(customer?.taxExemptFlag === 1));
      }
      setSuggestionListVisible(false);
      setCartEmpty(false);
    } else {
      const data = {
        ...posFlowData,
        salesman: posFlowData?.salesman?.ssoId || posFlowData?.salesman?.personId ? posFlowData?.salesman : salesPersonData,
        savedCart: {
          ...loadCartDetails,
          customer: {
            ...customer,
            buyOnBanNote: posCustomer?.buyOnBanNote,
          },
        },
        // need to verify in next PR
        shiptoFacilities: shippingAddressSelected
          ? posFlowData?.shiptoFacilities
          : shipToByFacility, // Resolved NAN issue in payment drawer,
        isModalOpen: false,
        addProductPage: true,
      };
      dispatch(setPosFlowData(data));
      if (posFlowData?.defaultTaxStatus) {
        dispatch(setExemptTax(customer?.taxExemptFlag === 1));
      }
    }
  }, [loadCartDetails, salesPersonDetails]);

  useEffect(() => {
    if (searchProducts?.length) {
      setSearchProductsData(
        _.uniqBy([...searchProductsData, ...searchProducts], "productId")
      );
    }
  }, [searchProducts]);

  const handleProductSelect = (product) => {
    dispatch(arrowReset());
    // dispatch(stockByWarehouseStart([product?.productId]));
    //Might need later
    // const filteredProduct = posFlowData?.cartProducts?.filter(
    //   (e) => e.productId === product?.productId
    // )[0];

    // const data = {
    //   productsData: filteredProduct,
    //   updatedPrice: filteredProduct?.posRetailPrice,
    // };
    // if (filteredProduct) {
    //   editProductIncart(data);
    // } else {
    if (product?.productId) {
      setProductData(product);
      showProductIncart(product?.productId);
    }
    setShippingAddress(false);
    // }
  };
  const singleSuggestedLength = suggestedProductsData?.length === 1;

  // Start Clear Cart
  const testRef = useRef<boolean>();

  useEffect(() => {
    testRef.current = removeItem;
  }, [removeItem]);

  const handleClick = () => {
    loadCartDetails.products = [];
    const clearCartProducts = posFlowData.cartProducts
      ? posFlowData.cartProducts
      : [];
    dispatch(clearCartBackupProducts(clearCartProducts));
    const data = {
      ...posFlowData,
      cartProducts: [],
      pickQuantityArr: [],
      pickAllQuantityArray: [],
      pickOrderQuantity: [],
      poNumber: "",
      addInStorage: [],
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      companyName: "",
    };
    dispatch(setPosFlowData(data));
    dispatch(setShippingInstructionNote(""));
    dispatch(setStoreCreditAmout(""));
    dispatch(setJobNameForAll(""));
    dispatch(setMarkAsQuoteReducer(false));
    dispatch(setCustomerAlertHide(true));
    setIsCartCleared(true);
    setCartEmpty(true);
    beforeUnloadCart([])
    setTimeout(() => {
      setRemoveItem(false);
      setSuggestionListVisible(true);
      if (testRef.current) {
        return;
      }
    }, 4000);
  };
  const undoCartCleared = () => {
    if (clearCartBackupData?.length) {
      setCartEmpty(false);
      const data = {
        ...posFlowData,
        cartProducts: clearCartBackupData,
      };
      dispatch(setPosFlowData({ ...data }));
      dispatch(clearCartBackupProducts([]));

      setTimeout(() => {
      }, 2000);
    }
  };
  // End Clear Cart

  //start Remove cart
  useEffect(() => {
    const indexFound = _.findIndex(
      posFlowData?.cartProducts,
      ({ productId, indexNumber }) =>
        productId === productsData?.productId && getProductIndex === indexNumber
    );
    setProductIndex(indexFound > -1 ? indexFound : -1);
  }, [productsData]);

  const removeFromCart = () => {
    if (productIndex > -1) {
      const tempCartProducts = posFlowData?.cartProducts
        ? posFlowData?.cartProducts
        : [];
      if (isGPMCustom || isOrderTotalCustom) {
        dispatch(isGpmCustomFlag(false));
        dispatch(isOrderTotalCustomFlag(false));
        tempCartProducts?.length ? tempCartProducts.map((item) => {
          const itemMuPricePrices = Number(calculatePriceItemMuEach(item?.priceMU, item?.itemMU, item?.initialPriceData)).toFixed(5)
          item.posRetailPrice = item?.initialPriceData;
          const extPriceInfos = (item.qtyOrdered * Number(itemMuPricePrices)).toFixed(2);
          item.extPrice = Number(Number(extPriceInfos).toFixed(2));
        }) : []
      }
      const backupProduct = tempCartProducts.splice(productIndex, 1);

      const tempPickedQuantity = posFlowData?.pickOrderQuantity
        ? posFlowData?.pickOrderQuantity
        : "";
      tempPickedQuantity && tempPickedQuantity?.splice(productIndex, 1);

      const tempPickedArrQuantity = posFlowData?.pickQuantityArr
        ? posFlowData?.pickQuantityArr
        : "";
      tempPickedArrQuantity && tempPickedArrQuantity?.splice(productIndex, 1);

      const data = {
        ...posFlowData,
        cartProducts: tempCartProducts,
        pickOrderQuantity: tempPickedQuantity,
        pickQuantityArr: tempPickedArrQuantity,
        // pickQuantityArr: posFlowData.pickQuantityArr.filter((item) => item.entry !== productIndex)
      };
      dispatch(setBackupProducts(backupProduct));
      dispatch(setPosFlowData(data));
      dispatch(clearSearchProduct());

      setCartEmpty(false);
      dispatch(productsDataClear());
      if (!posFlowData?.cartProducts?.length) {
        setCartEmpty(true);

        setSuggestionListVisible(true);
      }
    }
  };

  const undoItem = () => {
    if (backUpProducts?.length) {
      const data = {
        ...posFlowData,
        cartProducts: [...posFlowData.cartProducts, ...backUpProducts],
      };

      dispatch(setPosFlowData({ ...data }));
      dispatch(setBackupProducts([]));
      if ([...posFlowData.cartProducts, ...backUpProducts].length) {
        setCartEmpty(false);
      }
      setTimeout(() => {
        setIsRemoveToastVisible(false);
      }, 1000);
    }
  };

  const handleRemoveClick = () => {
    dispatch(addToCartDrawer(false));
    setState({ ...state, right: false });
    setIsRemoveToastVisible(true);
    removeFromCart();
    setTimeout(() => {
      setIsRemoveToastVisible(false);
    }, 5000);
  };
  //End Remove cart

  const { closeIcon, settingIcon } = images;
  const { searchEmpty, importingQuote, importQuote } = texts;

  function closeImage() {
    return <PosImage src={closeIcon} alt="shipping-icon" />;
  }

  const handleOpen = () => {
    setOpenModel(true);
    dispatch(arrowResetImportQuote());
  };

  const handleCloseDrawer = () => {
    if (
      getTabShipping === Tabs.tabIndexFirst ||
      getTabShipping === Tabs.tabIndexTwo ||
      getTabShipping === Tabs.tabIndexThree ||
      getTabShipping === Tabs.tabIndexFour
    ) {
      setShippingAddress(true);
    } else {
      setShippingAddress(false);
    }
    setPurchaseOrder(false);
    dispatch(arrowReset());
    setSaveForLaterClickedFrom(null)
    setShowSaveForLater(false);
    setSelectCustomer(false);
    setSelectSchedule(false);
    setSelectSalesman(false);
    setSelectTaker(false);
    setAddProduct(false);
    setTimeout(() => {
      dispatch(productsDataClear());
    }, 500);
    dispatch(setShipToAddress(true));
    setPurchaseOrderNum(posFlowData?.poNumber);
    // need to check
    // dispatch(stockByWarehouseStart(productIdArr));
    setState({ ...state, right: false });
    dispatch(addToCartDrawer(false));
    setShippingAddress(false)
  };

  // start
  const headerRef: any = useRef();
  const settingIconRef: any = useRef();
  const mainCartSearch: any = useRef();
  const searchInputIsHeight = mainCartSearch?.current?.clientHeight;
  const finalIsSearchInputHeight = searchInputIsHeight ?? 0;
  const [searchInputHeightCount, setSearchInputHeightCount] = useState(
    finalIsSearchInputHeight
  );
  const [settingIconDivHeight, setSettingIconDivHeight] = useState(
    settingIconRef?.current?.clientHeight
  );

  useEffect(() => {
    getListSize();
  }, [
    !customerInfo,
    headerHeightCount,
    searchInputHeightCount,
    settingIconDivHeight,
    headerRef?.current?.clientHeight,
    settingIconRef?.current?.clientHeight,
  ]);

  useEffect(() => {
    window?.addEventListener("resize", getListSize);
    return () => {
      window?.removeEventListener("resize", getListSize);
    };
  }, [
    !customerInfo,
    headerHeightCount,
    searchInputHeightCount,
    settingIconDivHeight,
    headerRef?.current?.clientHeight,
    settingIconRef?.current?.clientHeight,
  ]);

  const getListSize = () => {
    const newHeaderHeight = headerRef?.current?.clientHeight;
    dispatch(setHeaderHeightCount(newHeaderHeight));
    const newSearchHeight = finalIsSearchInputHeight;
    setSearchInputHeightCount(newSearchHeight);
    const newSettingIconDivHeight = settingIconRef?.current?.clientHeight + 25;
    setSettingIconDivHeight(newSettingIconDivHeight);
  };
  // end

  const useHeightStyles = makeStyles({
    fixedHeader: {
      top: 0,
      padding: 0,
      "@media (min-width: 1200px)": {
        top: `${settingIconDivHeight + 59}px`,
        padding: "0 24px",
      },
    },
  });

  const heightClasses = useHeightStyles();

  const { One } = PaginationFormat;
  const { availability } = commonConstants
  const [moreUniversalSearchAvailable, setMoreUniversalSearchAvailable] =
    useState<boolean>(false); // Store that next Pagination is there or not
  const [universalSearchPage, setUniversalSearchPage] = useState<number>(One); // Lazy Loading Page Number
  const defaultFilteredValue = {
    key: statusValue.All,
    label: availability,
    value: availabilityStatus["5"]
  }
  const [filteredSearchProduct, setFilteredSearchProduct] = useState<any>([])

  const [availabilityData, setAvailabilityData] = useState<Record<number, string>>();
  const [options, setOptions] = useState<IOption | null>(defaultFilteredValue);
  const [productStatus, setProductStatus] = useState(defaultFilteredValue.key);

  const handleOptions = (newElem, label) => {
    if (label === availability) {
      if (options !== newElem) {
        setOptions(newElem)
        setProductStatus(newElem.key)
      }
      else {
        setOptions(defaultFilteredValue)
        setProductStatus(defaultFilteredValue.key)
      }
    }
  }

  const remove = () => {
    setOptions(defaultFilteredValue)
    setProductStatus(defaultFilteredValue.key)
    handleFilter(defaultFilteredValue);
  }

  const handleFilter = (filter: IOption | null) => {
    setOptions(filter);
    let combinedFilteredProducts: any[] = [];
    const tempArray: number[] = []
    if (filter) {
      const { InStock, OutStock } = statusValue
      if (filter.key === (InStock)) {
        tempArray.push(InStock)
        let filteredInStockProducts = searchProductsData?.filter((item) => item.qtyInStock > 0)
        combinedFilteredProducts = [...combinedFilteredProducts, ...(filteredInStockProducts || [])];
      }
      if (filter.key === (OutStock)) {
        tempArray.push(OutStock)
        let filteredOutOfStockProducts = searchProductsData?.filter((item) => item.qtyInStock === 0)
        combinedFilteredProducts = [...combinedFilteredProducts, ...(filteredOutOfStockProducts || [])];
      }
    } else {
      setFilteredSearchProduct(searchProductsData)
    }
    if (!tempArray.length) {
      combinedFilteredProducts = searchProductsData || [];
    }
    setFilteredSearchProduct(combinedFilteredProducts);
  }

  useEffect(() => {
    if (searchProductsData?.length && !options) {
      setFilteredSearchProduct(searchProductsData)
    } else if (searchProductsData?.length && options) {
      handleFilter(options)
    }
  }, [searchProductsData?.length])

  useEffect(() => {
    if (searchProducts.length) {
      if (searchProductsTotal !== filteredSearchProduct?.length) {
        setMoreUniversalSearchAvailable(true);
      } else {
        setMoreUniversalSearchAvailable(false);
      }
    } else {
      setMoreUniversalSearchAvailable(false);
    }
  }, [filteredSearchProduct]);

  //Search Lazy Loading
  useEffect(() => {
    (async () => {
      if (
        moreUniversalSearchAvailable &&
        searchProductsData &&
        universalSearchPage !== One
      ) {
        handleScroll()
      }
    })();
  }, [universalSearchPage]);

  return (
    <>
      <ImportQuoteModel
        open={openModel}
        setOpenModel={setOpenModel}
        setCartEmpty={setCartEmpty}
        suggestionListVisible={setSuggestionListVisible}
        setSelectItemImportModel={setSelectItemImportModel}
        selectItemImportModel={selectItemImportModel}
        importQuotePageProductSearchRef={importQuotePageProductSearchRef}
      />
      <div className="privacy-settings-icon cart-setting-icon" ref={settingIconRef}>
      </div>
      <PosSnackbar
        open={isCartCleared}
        message="Cart cleared"
        actionText="Undo"
        actionOnClick={() => {
          undoCartCleared();
          setRemoveItem(true);
        }}
        onClose={() => {
          setIsCartCleared(false);
        }}
      />
      <PosSnackbar
        open={isRemoveToastVisible}
        message="Item Removed"
        onClose={() => {
          setIsRemoveToastVisible(false);
        }}
        actionText="Undo"
        actionOnClick={() => undoItem()}
      />
      <Container className="new-container cart-container">
        {cartLoading ? (
          <div className="cart-page-loader">
            <LinearLoader />
          </div>
        ) : (
          <div className="main-wrapper add-product-main-container p-0">
            <main
              className={`${customerInfo ? "customer-info-page-styling" : ""
                } main-product-page p-0`}
              ref={mainProduct}
              style={{ marginTop: `${settingIconDivHeight}px` }}
            >
              <div
                className={`${customerInfo ? "" : "info-header-wrapper"} w-100`}
              >
                {!customerInfo && (
                  <>
                    <div
                      className={`fixed-cart-pos-header ${heightClasses.fixedHeader} ${(!suggestionListVisible &&
                        cartEmpty &&
                        !loader) ? "searchHeader-fix-top-focus" : ""}`}
                      ref={headerRef}
                    >
                      <section className="info-header">
                        <CustomerAndCartInfo
                          suggestionListVisible={suggestionListVisible}
                          customerAndCardInfoRef={customerAndCardInfoRef}
                          isCartCleared={isCartCleared}
                          cartEmpty={cartEmpty}
                          importQuote={importQuote}
                          selectTaxExemptFlag={selectTaxExemptFlag}
                          shipToByFacilityId={shipToByFacilityId}
                          takerData={takerData}
                          setProductSearch={setProductSearch}
                          closeImage={closeImage}
                          setShippingAddress={setShippingAddress}
                          setCartEmpty={setCartEmpty}
                          setSearchProductsData={setSearchProductsData}
                          setSuggestionListVisible={setSuggestionListVisible}
                          handleOpen={handleOpen}
                          selectAddProductWrapper={selectAddProductWrapper}
                          setSaveForLaterClickedFrom={setSaveForLaterClickedFrom}
                          setShowSaveForLater={setShowSaveForLater}
                          handleClick={handleClick}
                          setCustomerInfo={setCustomerInfo}
                          selectShippingWrapper={selectShippingWrapper}
                          selectPurchaseWrapper={selectPurchaseWrapper}
                          selectSalesmanWrapper={selectSalesmanWrapper}
                          selectTakerWrapper={selectTakerWrapper}
                          selectScheduleWrapper={selectScheduleWrapper}
                          getListSize={getListSize}
                          headerRef={headerRef}
                          settingIconRef={settingIconRef}
                          searchInputHeightCount={searchInputHeightCount}
                          settingIconDivHeight={settingIconDivHeight}
                          setFilteredSearchProduct={setFilteredSearchProduct}
                          clearFilter={remove}
                        />
                        {/* searchbar start */}
                        <SearchProduct
                          suggestionListVisible={suggestionListVisible}
                          cartEmpty={cartEmpty}
                          productSearch={productSearch}
                          addProductPageProductSearchRef={addProductPageProductSearchRef}
                          loader={loader}
                          addProductPageSearchRef={addProductPageSearchRef}
                          searchProductsData={searchProductsData}
                          addProductPageRef={addProductPageRef}
                          suggestedProducts={suggestedProducts}
                          singleSuggestedLength={singleSuggestedLength}
                          searchEmpty={searchEmpty}
                          importingQuote={importingQuote}
                          importQuote={importQuote}
                          setSuggestionListVisible={setSuggestionListVisible}
                          setCartEmpty={setCartEmpty}
                          setPageSize={setPageSize}
                          searchProductsAPI={searchProductsAPI}
                          setProductSearch={setProductSearch}
                          setShippingAddress={setShippingAddress}
                          setSearchProductsData={setSearchProductsData}
                          closeImage={closeImage}
                          handleOpen={handleOpen}
                          showProductIncart={showProductIncart}
                          headerRef={headerRef}
                          mainCartSearch={mainCartSearch}
                          handleFilter={handleFilter}
                          availabilityData={availabilityData}
                          handleOptions={handleOptions}
                          setAvailabilityData={setAvailabilityData}
                          setProductStatus={setProductStatus}
                          productStatus={productStatus}
                          setFilteredSearchProduct={setFilteredSearchProduct}
                          availabilitydropdownRef={availabilitydropdownRef}
                          clearFilter={remove}
                          setProductData={setProductData}
                        />
                        {/* searchbar end */}
                      </section>
                    </div>
                    {cartEmpty ? (
                      <ProductSuggestion
                        suggestionListVisible={suggestionListVisible}
                        addProductPageRef={addProductPageRef}
                        suggestedProducts={suggestedProducts}
                        singleSuggestedLength={singleSuggestedLength}
                        importQuote={importQuote}
                        addProductPageProductSearchRef={addProductPageProductSearchRef}
                        searchProductsData={searchProductsData}
                        classes={classes}
                        setSelectItemImportModel={setSelectItemImportModel}
                        tableRowRef={tableRowRef}
                        loader={loader}
                        showProductIncart={showProductIncart}
                        handleOpen={handleOpen}
                        handleScroll={handleScroll}
                        handleProductSelect={handleProductSelect}
                        searchInputHeightCount={searchInputHeightCount}
                        settingIconDivHeight={settingIconDivHeight}
                        heightClasses={heightClasses}
                        addProduct={addProduct}
                        selectAddProductWrapper={selectAddProductWrapper}
                        importQuotePageProductSearchRef={importQuotePageProductSearchRef}
                        handleCloseDrawer={handleCloseDrawer}
                        universalSearchPage={universalSearchPage}
                        moreUniversalSearchAvailable={moreUniversalSearchAvailable}
                        pageSize={pageSize}
                        setUniversalSearchPage={setUniversalSearchPage}
                        options={options}
                        filteredSearchProduct={filteredSearchProduct}
                        remove={remove}
                        setProductData={setProductData}
                      />
                    ) : (
                      <OrderDetails
                        settingIconDivHeight={settingIconDivHeight}
                        modal={modal}
                        setModal={setModal}
                        pickUpSelected={pickUpSelected}
                        showSaveForLater={showSaveForLater}
                        cartDrawerRef={cartDrawerRef}
                        saveForLaterClickedFrom={saveForLaterClickedFrom}
                        setSaveForLaterClickedFrom={setSaveForLaterClickedFrom}
                        setShowSaveForLater={setShowSaveForLater}
                        showProductIncart={editProductIncart}
                        setCartEmpty={setCartEmpty}
                        setSuggestionListVisible={setSuggestionListVisible}
                        setSuggestedProducts={setSuggestedProducts}
                        setCustomerInfo={setCustomerInfo}
                        setPickUpSelected={setPickUpSelected}
                        paymentMethodRef={paymentMethodRef}
                        modalRef={modalRef}
                        setProductData={setProductData}
                      />
                    )}
                  </>
                )}
                <CartDrawer
                  handleRemoveClick={handleRemoveClick}
                  updatedProductsFlag={updatedProductsFlag}
                  setUpdatedProductsFlag={setUpdatedProductsFlag}
                  selectSalesman={selectSalesman}
                  state={state}
                  setState={setState}
                  setSelectSalesman={setSelectSalesman}
                  onCloseDrawer={handleCloseDrawer}
                  drawerClass={classes.drawer}
                  selectTaker={selectTaker}
                  selectCustomer={selectCustomer}
                  shippingAddress={shippingAddress}
                  setPickUpSelected={setPickUpSelected}
                  setShipToByFacilityId={setShipToByFacilityId}
                  showTaker={showTaker}
                  setPurchaseOrderNum={setPurchaseOrderNum}
                  setSearchProductsData={setSearchProductsData}
                  setCartEmpty={setCartEmpty}
                  selectSchedule={selectSchedule}
                  purchaseOrder={purchaseOrder}
                  setPurchaseOrder={setPurchaseOrder}
                  addProduct={addProduct}
                  setAddProduct={setAddProduct}
                  cartDrawerRef={cartDrawerRef}
                  drawerRef={drawerRef}
                  isWareHouseDrawer={isWareHouseDrawer}
                  setSelectSchedule={setSelectSchedule}
                  setSelectTaker={setSelectTaker}
                  allocatedDraftRowRef={allocatedDraftRowRef}
                  setFilteredSearchProduct={setFilteredSearchProduct}
                  productData={productData}
                  setProductData={setProductData}
                />
              </div>
            </main>
          </div>
        )}
      </Container>
    </>
  );
};

export default AddProductPage;
