import _ from "loadsh";
import { useDispatch } from "react-redux";

import texts from "apps/pos/locales";
import { Button } from "components";
import { GlobalNumbers } from "constants/enum";
import { AddIcon, AttachMoneyIcon, CheckIcon, ClearIcon, EditIcon } from "constants/icons";
import images from "constants/images";
import { getProfit, getSalesPrice, priceToBeFixedConverter } from "utils/utils";
import { PosButton, PosCheckbox, PosImage, PosInput } from "../Common/uiCore";

import { getChangePriceInfo, showOriginalPriceInfo } from "sagas/persistReducer/actions";
import { enterDisableAddToCart, getNewPrice, setCancelPrice, setChangeCartPrice } from "sagas/pos/actions";


const CartPriceVolumeBox = ({ salesPrice, setPriceBox, changePrice, fixMarginCustFlag, fixMarginRate, inputQtyRef, tempPrice, priceInDecimal, decimalCount, marginRateMarkup, minusHundred, newPercentage, markupButtonArray, priceMU, muName, handleProfPercentChange, handlePriceChangeInput, handleAddQty, handleUpdateQty, customPrice, productOutOfStockAndDiscontinuedMessage, isProductInactive, inactiveProductMessage, moreOrderedQty, qtyBox, priceVolumeBox, discontinuedOOS, priceType, addCustomerLabel, moreOrderedQtyData, setIsCancel, getCancelPrice, setModifiedPrice, stateAndFunctions, setNewPrice, changePriceInfo, handleChangePrice, changePriceInputAndQtyInEachBox, isCustomPrice, displayPrice, discontinued, clearance, firstProductPrice, vendorPrice, itemMuName, itemMU, priceScheduleVolume, findRange, productUpdateFlag, qty, disClearance, updatedAvailableQty, updateDisable, isWareHouseAvailable, handleUpdateCart, modifiedPrice, newPrice, actualQty, itemMuPriceWithDisplayPrice, extPriceInfo, discontinueProduct, newQty, productId, productNotesRef, productShippingNote, productOrderNote, handleAddToCart, profPercent, isPriceAccept, setIsPriceAccept }) => {

  const dispatch = useDispatch()
  const { setChangePrice, setPriceInDecimal, setNewPercentage, isShowSetPrice, setTempNewPrice, handleRemoveClick, setProductOrderNote, setProductShippingNote } = stateAndFunctions;
  const { orderNotes, shippingInstructions } = addCustomerLabel || {}
  const { cartIcon, cautionIcon, groupIcon, lastPriceIcon, caution } = images;
  const { missingPriceScheduleMessage } = texts
  const handlePriceValue = () => {
    let displayPrice;

    if (tempPrice === GlobalNumbers.ZERO) {
      displayPrice = salesPrice?.price;
      handlePriceChangeInput(salesPrice?.price);
    } else if (tempPrice < GlobalNumbers.ZERO) {
      displayPrice = salesPrice?.price;
      handlePriceChangeInput(salesPrice?.price);
    } else if (priceInDecimal) {
      if (!Array.isArray(tempPrice) && decimalCount(tempPrice) < GlobalNumbers.TWO) {
        displayPrice = Number(tempPrice).toFixed(5);
      } else {
        displayPrice = Number(tempPrice).toFixed(5);
      }
    } else {
      displayPrice = tempPrice;
    }

    if (displayPrice === undefined || displayPrice === null) {
      displayPrice = salesPrice?.price;
    }
    return displayPrice;
  }
  return (
    <>
      {(setPriceBox && !changePrice && !fixMarginCustFlag) ? (
        <div className="set-price-text">
          <PosImage src={cautionIcon} alt="caution" />
          <span className="set-price-text-style">
            {missingPriceScheduleMessage}
          </span>
        </div>) : null}
      <div className="cart-price-volume">
        <div className="cart-price-volume-box mt-3">
          {fixMarginRate > 0 ?
            <div className="fixed-margin-info">
              <PosImage src={caution} alt="caution" />
              <span>
                {fixMarginRate}% Fixed Margin applied
              </span>
            </div>
            : <></>
          }
          {(setPriceBox && !fixMarginCustFlag) ? (
            <div className="cart-price-set-price-box">
              <span className="Set-price-label">{`${changePrice ? "Set a new price for this order" : "Set a price"}`}</span>
              {
                fixMarginRate ?
                  <>
                    <div className="product-search-border-visible-cart-page bgWhite margintop-10 pl-10">
                      <div className="price-input-section">
                        <span className="price-label">Price</span>
                        <div className="price-input-icon">
                          <AttachMoneyIcon className="light-gray-text" />
                          {fixMarginRate !== null && (
                            <PosInput
                              variant="standard"
                              id="standard-basic"
                              inputRef={inputQtyRef}
                              InputProps={{ disableUnderline: true }}
                              className="product-search-input bgWhite w85"
                              value={handlePriceValue()}
                              onFocus={(e) => {
                                e.target.select();
                              }}
                              disabled={true}
                            />
                          )}
                        </div>
                      </div>
                      <div className="price-Markup-section">
                        <span className="price-label">Markup</span>
                        <div className="markup-input-icon d-flex align-items-center">
                          <div className="mr-2">
                            <span className="price-label">%</span>
                          </div>
                          <PosInput
                            variant="standard"
                            id="selfadapt"
                            InputProps={{ disableUnderline: true }}
                            className="product-search-price-input bgWhite selfadapt p-0 justify-content-center"
                            value={
                              (marginRateMarkup ? Number(marginRateMarkup).toFixed(5) : "-100.00")
                            }
                            onFocus={(e) => {
                              e.target.select();
                            }}
                            disabled={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="new-price-options">
                    </div>
                    <div className="btns-container-discard-save set-price-button button-container">
                      <PosButton
                        variant="contained"
                        color="primary"
                        className="btn-savechanges btn"
                        id="setPriceBtn"
                        onClick={() => {
                          setModifiedPrice(true);
                          setChangePrice(false)
                          setTempNewPrice(tempPrice);
                          setNewPrice(
                            tempPrice < 0 ? 0 : Number(tempPrice)
                          );
                          setNewPercentage(newPercentage);
                          dispatch(
                            getNewPrice(
                              tempPrice < 0 ? 0 : Number(tempPrice)
                            )
                          );
                          dispatch(getChangePriceInfo(true));
                          dispatch(showOriginalPriceInfo(false));
                          dispatch(setChangeCartPrice(Number(tempPrice)))
                          dispatch(enterDisableAddToCart(false))
                        }
                        }
                        startIcon={<CheckIcon />}
                      >{`${changePrice ? "Change Price" : "Set price"} `}
                      </PosButton>
                    </div>
                  </> :
                  <>
                    <div className="product-search-border-visible-cart-page bgWhite margintop-10 pl-10">
                      <div className="price-input-section">
                        <span className="price-label">Price</span>
                        <div className="price-input-icon">
                          <AttachMoneyIcon />
                          {tempPrice !== null && (
                            <PosInput
                              variant="standard"
                              id="standard-basic"
                              inputRef={inputQtyRef}
                              autoFocus
                              InputProps={{ disableUnderline: true }}
                              className="product-search-input bgWhite w85"
                              value={handlePriceValue()}
                              onFocus={(e) => {
                                e.target.select();
                              }}
                              onChange={(e) => handlePriceChangeInput(e.target.value)}
                            />
                          )}
                        </div>
                      </div>
                      <div className="price-Markup-section">
                        <div className="price-label">Prof %</div>
                        <div className="markup-input-icon d-flex align-items-center">
                          <div className="mr-2">
                            <span className="price-label">%</span>
                          </div>
                          <PosInput
                            variant="standard"
                            id="selfadapt"
                            InputProps={{ disableUnderline: true }}
                            className="product-search-price-input bgWhite selfadapt p-0 justify-content-center"
                            value={newPercentage ?? minusHundred}
                            onFocus={(e) => {
                              e.target.select();
                            }}
                            onChange={(e) => handleProfPercentChange(e)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="new-price-options">
                      {markupButtonArray?.length ? markupButtonArray.map((item, index: number) => {
                        return (
                          <PosButton
                            key={index}
                            variant="contained"
                            color="secondary"
                            className="price-options btn"
                            onClick={() => {
                              setNewPercentage(item.markup);
                              setTempNewPrice(getSalesPrice(Number(item.markup), item.salesPrice));
                              inputQtyRef.current.focus()
                            }}
                          >
                            {`${item.markup}%`}
                          </PosButton>
                        )
                      }) : null}

                    </div>
                    <div className="btns-container-discard-save set-price-button button-container flex-row-reverse">
                      {changePrice ? <PosButton
                        className="btn-discard button-cancel btn"
                        variant="contained"
                        onClick={() => {
                          dispatch(enterDisableAddToCart(false))
                          setChangePrice(false);
                          setModifiedPrice(true);
                          dispatch(setChangeCartPrice(Number(getCancelPrice)));
                          setIsCancel(true);
                          if (changePriceInfo) {
                            dispatch(getChangePriceInfo(true));
                          }
                        }}
                        startIcon={<ClearIcon />}
                      >Cancel
                      </PosButton> : null}
                      <PosButton
                        variant="contained"
                        color="primary"
                        className="btn-savechanges change-price-set-btn btn"
                        id="setPriceBtn"
                        onClick={handleChangePrice}
                        startIcon={<CheckIcon />}
                      >{`${changePrice ? "Change Price" : "Set price"} `}
                      </PosButton>
                    </div>
                  </>
              }
            </div>) : null}

          {(changePriceInputAndQtyInEachBox || fixMarginCustFlag) ? (
            <div className="change-cart-price-box">
              <div className="cart-item-details">
                <div className="cart-item-details-row-images">
                  <div className="d-flex flex-column">
                    <div className="price-type-product">
                      <span>{(isCustomPrice) ? customPrice : priceType}</span>
                    </div>
                    <div className="cart-peice-sec">
                      <span className="cart-price">
                        {`$ ${priceToBeFixedConverter(displayPrice, 5)}`}
                      </span>
                    </div>
                  </div>
                  <div>
                    <PosButton
                      variant="contained"
                      className="reset-btn-link btn modal-remove-btn"
                      onClick={() => {
                        dispatch(setCancelPrice(displayPrice))
                        setChangePrice(true);
                        setModifiedPrice(false)
                        setPriceInDecimal(true);
                        dispatch(enterDisableAddToCart(true))
                        setNewPercentage(getProfit(tempPrice, vendorPrice?.price ?? vendorPrice))
                        isShowSetPrice(false)
                        setTempNewPrice(displayPrice)
                      }}
                      startIcon={<EditIcon />}
                      disabled={clearance || discontinued || fixMarginCustFlag || fixMarginRate}
                    >
                      Change
                    </PosButton>
                  </div>
                </div>
                <h6 className="neworder-mfg-code">
                  {muName} ({priceMU})
                </h6>
              </div>
            </div>) : null}
          {priceVolumeBox ? (
            <div className="volume-break-section">
              <div
                className={`${firstProductPrice
                  ? "div-opacity-low"
                  : "vo-break-main-div"
                  }`}
              >
                <div
                  className={`${changePriceInfo
                    ? "cart-volume-break-opacity-low cart-volume-break-opa"
                    : "cart-volume-break cart-volume-break-opa"
                    }`}
                >
                  <div className="d-flex flex-column">
                    <div className="d-flex volume-break-title-div">
                      <div className="volumne-break-title">
                        <span className="cart-volume-text">
                          VOLUME BREAKS
                        </span>
                      </div>
                      <div className="unit-price-title">
                        <span className="cart-unit-text">
                          PRICE PER
                          {` ${itemMuName.toUpperCase()} (${itemMU})`}
                        </span>
                      </div>
                    </div>
                    {priceScheduleVolume?.map(
                      (item, index) => {
                        const data = priceScheduleVolume.find((qty) => qty.maxQty === 99999)
                        return (
                          <>
                            <div
                              key={index}
                              className="volume-break-info"
                            >
                              {!changePriceInfo && (_.inRange(
                                qty,
                                priceScheduleVolume.indexOf(data) === index ? item.minQty + 1 : item.minQty,
                                item.maxQty + 1
                              ) && (
                                  <div className="qty-volume-break-arrow-icon">
                                    <PosImage src={groupIcon} alt="edit" />
                                  </div>
                                ))}
                              <span className="volume-break-qty">
                                {priceScheduleVolume.indexOf(data) === index ? `${item.minQty + 1}+` : `${item.minQty} - ${item.maxQty}`}
                              </span>
                              <span className="volume-break-price">
                                {item.price.toFixed(5)}
                              </span>
                            </div>
                          </>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
              {changePriceInfo && (
                <div className="custom-price-div">
                  <div className="cust-price">
                    Using Custom Price
                    <PosButton
                      variant="contained"
                      className="reset-btn-link btn"
                      onClick={() => {
                        dispatch(getChangePriceInfo(false));
                        dispatch(showOriginalPriceInfo(true));
                        dispatch(setChangeCartPrice(findRange?.price))
                      }}
                    >
                      Reset
                    </PosButton>
                  </div>
                </div>
              )}
            </div>
          ) : (
            null
          )}
          {(qtyBox || fixMarginCustFlag) ? (
            <div className="update-quantity-container">
              {isProductInactive ? <>
                <div className="d-flex discontinue-heading">
                  <PosImage src={cautionIcon} alt="caution" className="mr-2" />
                  <div className="int-18-reg-bold">
                    Cannot Add to Cart
                  </div>
                </div>
                <div className="discontinue-footer">
                  <h6 className="must-be-value">
                    {inactiveProductMessage}
                  </h6>
                </div>
              </> :
                <>{!discontinuedOOS ?
                  <>
                    <section className="cart-button-container sticky-bottom cart-btn-container-second">
                      {productUpdateFlag ? (
                        <>
                          {`Qty. in ${itemMuName} (${itemMU})`}
                          <div className="product-add-update-btn">
                            <div className=" w35 pd5 second-part-input-btn input-focus">
                              <PosInput
                                variant="standard"
                                id="standard-basic"
                                inputRef={inputQtyRef}
                                InputProps={{ disableUnderline: true }}
                                inputProps={{ maxLength: 8, pattern: "\d{8}" }}
                                value={qty}
                                autoFocus
                                onFocus={(e) => {
                                  e.target.select();
                                }}
                                onChange={(e) => handleUpdateQty(e)}
                                className="second-part-search-input-results"
                              />
                            </div>
                            {firstProductPrice >= 0 && (
                              <PosButton
                                disabled={!qty || (disClearance ? qty > (updatedAvailableQty - updateDisable) : false) || isWareHouseAvailable}
                                variant="contained"
                                color="primary"
                                className="btn-savechanges button-update-cart-price product-update-qty-btn btn"
                                startIcon={<AddIcon />}
                                onClick={handleUpdateCart}
                              >
                                Update Cart
                              </PosButton>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          {
                            <div className="shipping-btns d-flex shipping-quantity-sec">
                              {`Qty. in ${itemMuName} (${itemMU})`}
                              <div className="d-flex div-add-tocart-section">
                                {modifiedPrice ||
                                  firstProductPrice !== null ? (
                                  <div className="order-search-results shipping-quantity-btn cases-order w35 mb-3 qty-for-add-to-cart input-focus">
                                    <PosInput
                                      variant="standard"
                                      id="standard-basic"
                                      inputRef={inputQtyRef}
                                      InputProps={{ disableUnderline: true }}
                                      inputProps={{ maxLength: 8, pattern: "\d{8}" }}
                                      value={qty || ""}
                                      autoFocus
                                      type="text"
                                      onFocus={(e) => {
                                        e.target.select();
                                      }}
                                      onChange={(e) => handleAddQty(e)}
                                      className="order-search-input-results input-add-to-cart"
                                    />
                                  </div>
                                ) : (
                                  null
                                )}
                                {(modifiedPrice && newPrice >= 0) ||
                                  (firstProductPrice !== null) ? (
                                  <PosButton
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={!qty || (disClearance ? (actualQty ? (qty > (updatedAvailableQty - actualQty)) : (qty > updatedAvailableQty)) : false)}
                                    className="btn-saveforlater cart-bottom-btn add-to-cart-btn mb-3 add-to-cart-modal-btn"
                                    onClick={handleAddToCart}
                                    startIcon={<AddIcon />}
                                  >Add to Cart
                                  </PosButton>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div >
                          }
                        </>
                      )}
                    </section >
                    {((modifiedPrice && newPrice >= 0) ||
                      (firstProductPrice !== null)) && (Number(profPercent) < 15) ? <div className="hide-price-checkbox mt-0">
                      <PosCheckbox
                        checked={isPriceAccept}
                        onChange={(e) => setIsPriceAccept(e.target.checked)}
                        label="Accept current price with lower Prof. %"
                        className="checkbox-focus"
                      />
                    </div> : null}
                    {
                      < div className="unit-price-and-ext-price" >
                        <div className="unit-price">
                          {`PRICE PER ${itemMuName?.toUpperCase()} (${itemMU})`}
                          <span className="unit-price-text">
                            {`$ ${Number(itemMuPriceWithDisplayPrice).toFixed(5)}`}
                          </span>
                        </div>
                        <div className="unit-ext-price">
                          EXT.PRICE
                          <span className="unit-ext-text">
                            {`$${extPriceInfo}`}
                          </span>
                        </div>
                      </div >
                    }
                    <div className="backorder-and-remove">
                      {!discontinueProduct && moreOrderedQty && (
                        <div className="cart-items-back-order">
                          <PosImage src={lastPriceIcon} alt="price_icon" />
                          {`${moreOrderedQtyData} to Backorder`}
                        </div>
                      )}
                      {newQty > 0 && (
                        <div className="cart-items-added">
                          <PosImage src={cartIcon} alt="cart_icon" />
                          {`${newQty} already added to cart`}
                          {productUpdateFlag ? <Button
                            className="remove-btn-second-part bg-transparent border-0 p-0 button-focus "
                            onClick={() =>
                              handleRemoveClick(productId)
                            }
                          >
                            Remove
                          </Button> : null}
                        </div>
                      )}
                    </div>
                  </> :
                  <>
                    <div className="d-flex discontinue-heading">
                      <PosImage src={cautionIcon} alt="caution" className="mr-2" />
                      <div className="int-18-reg-bold">
                        Cannot Add to Cart
                      </div>
                    </div>
                    <div className="discontinue-footer">
                      <h6 className="must-be-value">
                        {productOutOfStockAndDiscontinuedMessage}
                      </h6>
                    </div>
                  </>
                }</>}
              {
                !discontinueProduct ? <div className="product-notes-container">
                  <div className="product-order-note-container">
                    <div className="product-order-note-header">
                      <span className="int-18-reg">{orderNotes}</span>
                    </div>
                    <div className="product-order-note-input-container">
                      <PosInput
                        id="standard-basic"
                        inputRef={productNotesRef}
                        InputProps={{ disableUnderline: true }}
                        value={productOrderNote}
                        onFocus={(e) => {
                          e.target.select();
                        }}
                        onChange={(e) => {
                          setProductOrderNote(e.target.value)
                        }}
                        className="product-order-note-input"
                      />
                    </div>
                  </div>
                  <div className="product-order-note-container">
                    <div className="product-order-note-header">
                      <span className="int-18-reg">{shippingInstructions}</span>
                    </div>
                    <div className="product-order-note-input-container">
                      <PosInput
                        id="standard-basic"
                        inputRef={productNotesRef}
                        variant="standard"
                        InputProps={{ disableUnderline: true }}
                        value={productShippingNote}
                        onFocus={(e) => {
                          e.target.select();
                        }}
                        onChange={(e) => {
                          setProductShippingNote(e.target.value)
                        }}
                        className="product-order-note-input"
                      />
                    </div>
                  </div>
                </div> : null
              }

            </div >
          ) : null}
        </div >
      </div >
    </>
  )
}

export default CartPriceVolumeBox;