import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { object, string } from "yup";

import DatePickerNew from "../Common/DatePickerNew/DatePickerNew";
import { useAddShipmentInStorage } from "../pages/customerInfo/CustomerOrders/hooks/useAddShipmentInStorage";
import { setIsApiLoading } from "../pages/customerInfo/CustomerOrders/redux/customerOrder.slice";
import { getOrderId, getShipmentId } from "../pages/customerInfo/CustomerOrders/redux/selector";

import {
  PosButton,
  PosInput,
  PosModel
} from "apps/pos/Common/uiCore";
import { Button } from "components";
import { title } from "constants/constant";
import { CheckIcon, ClearIcon } from "constants/icons";

import { useNavigate } from "react-router-dom";
import { snakbarShow } from "sagas/pos/snakbar/snakbar.slice";
import { customBusinessDaysAdd } from "utils/utils";
import { oneBusinessDay } from "../constants";
import "./setStorage.scss";

interface SetStorageDetailModalProps {
  setStorageModal?: (boolean) => void;
  storageModal?: boolean;
  itemsDetail?: any;
  isPutEntireShipment?: boolean;
}
const SetStorageDetailModal = ({
  storageModal,
  setStorageModal,
  itemsDetail,
  isPutEntireShipment,
}: SetStorageDetailModalProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { putInStorageTitle, estDeliveryDate, setStorageDetail } = title;

  const { addShipmentInStorageFn, isSuccess: addShipmentInStorageSuccess, isLoading: AddShipmentInStorageIsLoading } = useAddShipmentInStorage();
  // May need later
  // const { addEntireShipmentInStorageFn, isSuccess: addEntireShipmentInStorage, isLoading: AddEntireShipmentInStorageIsLoading } = useAddEntireShipmentInStorage();

  const isApiLoading = [
    AddShipmentInStorageIsLoading,
    // AddEntireShipmentInStorageIsLoading,    // May need later
  ].includes(true);

  const orderId = useSelector(getOrderId);
  const shipmentId = useSelector(getShipmentId);

  const currentTaker = JSON.parse(localStorage.getItem("taker"));

  const formik = useFormik({
    initialValues: {
      handlerID: currentTaker?.personId, // takerId,
      shipmentID: shipmentId,
      orderID: orderId,
      reason: "",
      storageLocation: "Storage Desk",
      estDeliveryDate: customBusinessDaysAdd(dayjs(), oneBusinessDay).toISOString(),
    },
    validationSchema: object().shape({
      storageLocation: string().required('Location is required'),
      estDeliveryDate: string().required('Est. Delivery Date is required'),
    }),
    onSubmit: () => {
      if (isPutEntireShipment) {
        // May need later
        // addEntireShipmentInStorageFn({...values, estDeliveryDate: dayjs(values?.estDeliveryDate).format('YYYY-MM-DD')})
      } else {
        const filteredItems = itemsDetail?.items?.map((item) => {
          return {
            ...item,
            qtyPutInStorage: Number(item.qtyPutInStorage),
          }
        }).filter((item) => item?.qtyPutInStorage > 0)

        addShipmentInStorageFn({
          ...values,
          items: filteredItems,
        });

      }
    },
  });

  useEffect(() => {
    dispatch(setIsApiLoading(isApiLoading));
  }, [isApiLoading]);

  // May need later
  // useEffect(() => {
  //   if (addShipmentInStorageSuccess || addEntireShipmentInStorage) {
  //     setStorageModal(false);
  //     setIsSnackbarVisible(true)
  //     setTimeout(() => {
  //       navigate(-1)
  //     }, 2500);
  //   }
  // },[addShipmentInStorageSuccess, addEntireShipmentInStorage])

  useEffect(() => {
    if (addShipmentInStorageSuccess) {
      setStorageModal(false);
      dispatch(snakbarShow({ message: `${isPutEntireShipment ? `Put entire shipment into Storage` : `Put selected items into Storage`}` }))
      setTimeout(() => {
        navigate(-1)
      }, 2500);
    }
  }, [addShipmentInStorageSuccess])

  const { values, setFieldValue, handleSubmit, errors, touched } = formik;
  const onCloseModal = () => {
    if (!isApiLoading) {
      setStorageModal(false)
    }
  }
  return (
    <>
      <FormikProvider value={formik}>
        <Form>
          <PosModel
            open={storageModal}
            onClose={onCloseModal}
            className={`set-customer-code-modal`}
            dialogClassName={`set-customer-code-modal-container set-storage-modal-container put-storage-modal-container`}
            dialogHeader={
              <div className="modal-header align-items-center">
                <span className="modal-title">
                  {setStorageDetail}
                </span>
                <Button className="bg-transparent border-0 p-0 button-focus" onClick={onCloseModal}>
                  <ClearIcon
                    className="clear-icon"

                  />
                </Button>
              </div>
            }
            dialogFooter={
              <>
                <div
                  className={`d-flex flex-wrap justify-content-between w-100 flex-row-reverse`}
                >
                  <div className="d-flex flex-wrap">
                    <PosButton
                      disabled={isApiLoading}
                      variant="contained"
                      color="primary"
                      className={`add-to-return-cart-button mt-2 put-in-storage-modal-btn btn`}
                      startIcon={<CheckIcon />}
                      fontWeight
                      onClick={() => handleSubmit()}
                    >
                      {putInStorageTitle}
                    </PosButton>
                  </div>
                  <div>
                    <PosButton
                      type="button"
                      variant="contained"
                      className={`add-to-return-cart-button mr-2 mt-2 btn-font-600 set-storage-back-btn btn `}
                      fontWeight
                      onClick={onCloseModal}
                    >
                      Back
                    </PosButton>
                  </div>

                </div>
              </>
            }
          >
            <>
              <div className="set-storage-type-section modal-input-bottom-spacing">
                <div className="title-text mb-2 font-weight-600">Location</div>
                <PosInput
                  className="input-focus-styling search-input-focus"
                  variant="filled"
                  id="filled-basic"
                  autoFocus
                  InputProps={{ disableUnderline: true }}
                  name="location"
                  value={values?.storageLocation}
                  onChange={(e) => {
                    setFieldValue("storageLocation", e.target.value);
                  }}
                />
              </div>
              {errors.storageLocation && touched.storageLocation ? (
                <p className="validation-error-text">
                  {errors.storageLocation}
                </p>
              ) : null}
              <div className="set-storage-type-section modal-input-bottom-spacing">
                <div className="title-text mb-2 font-weight-600">
                  {estDeliveryDate}
                </div>
                <div className="custom--datepicker">
                  <DatePickerNew value={dayjs(values?.estDeliveryDate).format("YYYY-MM-DD")}
                    onChange={(e) => {
                      if (e.target.value) {
                        setFieldValue("estDeliveryDate", dayjs(e.target.value));
                      }
                      else {
                        setFieldValue("estDeliveryDate", "");
                      }
                    }} />
                </div>
              </div>
              {errors.estDeliveryDate && touched.estDeliveryDate ? (
                <p className="validation-error-text">
                  {errors.estDeliveryDate}
                </p>
              ) : null}
              <div className="set-storage-type-section modal-input-bottom-spacing">
                <div className="title-text mb-2 font-weight-600">Reason</div>
                <PosInput
                  className="input-focus-styling"
                  variant="filled"
                  id="filled-basic"
                  InputProps={{ disableUnderline: true }}
                  name="reason"
                  value={values?.reason}
                  onChange={(e) => {
                    setFieldValue("reason", e.target.value);
                  }}
                />
              </div>
              {errors.reason && touched.reason ? (
                <p className="validation-error-text">
                  {errors.reason}
                </p>
              ) : null}
            </>
          </PosModel>
        </Form>
      </FormikProvider>
    </>
  );
};
export default SetStorageDetailModal;
