import "@fontsource/inter";
import { Box } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import dayjs from "dayjs";
import _ from "loadsh";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setTimeout } from "timers";

import AddNewCustomerModal from "apps/pos/AddNewCustomer/AddNewCustomerModal/AddNewCustomerModal";
import {
  PosButton,
  PosHelmet,
  PosIconButton,
  PosImage,
  PosInput,
  PosPageHeading
} from "apps/pos/Common/uiCore";
import GenericCustomersHooks from "apps/product/hooks/GenericCustomersHooks";
import NoDataFound from "apps/springboard/svgComponents/notDatafound";
import { FKEYS, title } from "constants/constant";
import { LookupTypeEnum, documentByModule, hashRoute } from "constants/enum";
import {
  ArrowBackRoundedIcon,
  ArrowForwardIcon,
  KeyboardIcon,
  PersonAddIcon,
  SearchIcon,
} from "constants/icons";
import images from "constants/images";
import {
  PosFlowDataProp,
  UploadedDocumentItemProp,
} from "constants/interfaces";
import { Paths } from "enums";
import { CustomerDocumentData, PosResponseProps, RootState, genericCustomers } from "models";
import { keys, renamePersonData } from "utils/helper";
import {
  authorizationToken,
  getLocalStoraGeItem,
  phoneNumberFormatNew,
} from "utils/utils";
import CustomerNotesModal from "./CustomerNotesModal";
import { NewOrderDrawer, WorkStationDrawer } from "./Drawers";
import { MMDDYYYY } from "./constants";

import {
  addNewCustomerClear,
  addNewCustomerModel,
  loadCartRequest,
  loadCartSuccessClear,
  resetLoadCart,
  setCustomerAccountInfo,
  setCustomerAlertHide,
  setIsMail,
  setNoLoadDetailsAction,
  setPosFlowData,
  setTabIndexNumber,
  shippingAddressSelectedData
} from "sagas/persistReducer/actions";
import {
  addCustomersRequest,
  arrowDownIndex,
  arrowReset,
  arrowUpIndex,
  clearPackingSlipData,
  clearSearchCustomer,
  getShippingFacilitiesClear,
  getStatesRequest,
  getWarehouseLocationsClear,
  isPurchaseOrderNoEdited,
  priceSchedulesRequest,
  recentCustomersRequest,
  savedDraftsRequest,
  savedDraftsReset,
  setJobNameForAll,
  setMarkAsQuoteReducer,
  setSelectedIndex,
  setShippingInstructionNote,
  setStoreCreditAmout,
  suggestedProductsClear
} from "sagas/pos/actions";
import { customerDocumentRequest, taxDocumentRequest } from "sagas/pos/documentUpload/actions";

import { snakbarShow } from "sagas/pos/snakbar/snakbar.slice";
import "./newOrderStyle.scss";
import "./neworder.scss";
import "./poscommonstyling.scss";

const StyledTableRow = withStyles(() => ({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  selected: {
    borderBottom: "1px solid darkGray",
  },
}))(TableRow);

const StyledTableCell = withStyles(() => ({
  head: {
    color: "#000",
    fontSize: 10,
    fontFamily: "Inter, Regular",
    paddingLeft: 0,
    paddingTop: 10,
    paddingBottom: 10,
    borderBottomWidth: 0,
  },
  body: {
    fontSize: 16,
    fontWeight: 500,
    fontFamily: "Inter, Regular",
    borderBottomWidth: 0,
    paddingLeft: 0,
    paddingTop: 10,
    paddingBottom: 10,
    color: "#000",
  },
}))(TableCell);

const useStyles = makeStyles({
  table: {
    minWidth: 500,
  },
  root: {
    position: "unset",
    "& .MuiTableHead-root": {
      position: "unset",
    },
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: 30,
      borderBottomLeftRadius: 30,
    },
  },
});

const NewOrder = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const hasHash = location.hash;
  const stateData = location.state;
  const { jobName, order, customerPO } = LookupTypeEnum;

  const currentWorkspace = getLocalStoraGeItem("workspace");
  const currentTakerInfoStorage = getLocalStoraGeItem("taker");
  const [searchListVisible, SetSearchListVisible] = useState(false);
  const [avatarListVisible, SetAvatarListVisible] = useState(true);
  const [searchString, setSearchString] = useState(stateData?.searchString ? stateData.searchString : "");
  const [loader, setLoader] = useState(false);
  const [recentCustomerLoader, setRecentCustomerLoader] = useState(false);
  const [recentCustomersData, setRecentCustomersData] = useState<any>({});
  const [searchCustomersData, setSearchCustomersData] = useState<any>();
  const [openTaker, SetOpenTaker] = useState(false);
  const [selectedWorkspace, setSelectedWorkspace] = useState("");
  const [draftCartModal, setDraftCartModal] = useState(false);
  const [addCustomerFormModal, setAddCustomerFormModal] = useState(false);
  const [doesHaveMore, setHaveMore] = useState(true);
  const [pageSize, setPageSize] = useState(1);
  const [tempPersonId, setTempPersonId] = useState(0);
  const [enterPress, setEnterPress] = useState(false);
  const [downPress, setDownPress] = useState(false);
  const [upPress, setUpPress] = useState(false);
  const [newOrderShippingAddress, setNewOrderShippingAddress] = useState(false);
  const [showDraftDrawer, setShowDraftDrawer] = useState(false);
  const [priceScheduleIdData, SetPriceScheduleIdData] = useState(false);

  const [isSwitchCustomerFlow, setIsSwitchCustomerFlow] = useState(false);
  const [isNotesModalVisible, setIsNotesModalVisible] = useState(false);
  const [isPriceScheduleDrawerVisible, setIsPriceScheduleDrawerVisible] =
    useState(false);
  const [customerDataForGenericFlow, setCustomerDataForGenericFlow] =
    useState(null);
  //JES-4766
  // const [customerSearchDataRow, setCustomerSearchDataRow] = useState<any>({});

  const { getGenericCustomersMutation } = GenericCustomersHooks();

  const {
    priceScheduleDetails,
    isPriceScheduleLoading,
    recentCustomers,
    searchCustomers,
    searchCustomersLength,
    selectedIndex,
    customerDataCount,
    error,
    draftLoadError,
    savedDrafts,
    getStatesData,
    isLoading,
    searchCustomerResponse,
  } = useSelector<RootState, PosResponseProps>((state) => state.pos);

  const genericCustomers = useSelector<RootState, genericCustomers>(
    (state) => state.genericCustomersReducer
  );
  const { customerDocumentDetails, taxDocumentDetails } = useSelector<
    RootState,
    CustomerDocumentData
  >((state) => state.documentReducer);
  const { shared, all, current } = documentByModule;
  const decodedData = authorizationToken("Authorization");
  const sellerId = decodedData?.SellerId;

  useEffect(() => {
    getGenericCustomersMutation.mutate();
  }, []);

  useEffect(() => {
    if (searchCustomers?.length) {
      SetAvatarListVisible(false);
      SetSearchListVisible(true)
    }
  }, [])

  useEffect(() => {
    dispatch(clearPackingSlipData());
  }, []);

  useEffect(() => {

    const { stockWarehouseId, warehouseName } = currentWorkspace || {};
    // need to test before remove
    // navigate(Paths.posNewOrder);
    if (sellerId === 0) {
      navigate(Paths.unauthorized);
    }
    dispatch(savedDraftsReset());
    dispatch(getWarehouseLocationsClear());

    if (!stockWarehouseId) {
      setState({ ...state, right: true });
    } else {
      const { priceVisible } = posFlowData || {};
      const data = {
        ...posFlowData,
        workspace: currentWorkspace,
        draftCartDeleted: false,
        defaultTaxStatus: true,
        addProductPage: false,
        priceVisible: (priceVisible !== undefined) ? priceVisible : true
      };
      dispatch(setPosFlowData(data));
      dispatch(
        recentCustomersRequest({
          id: stockWarehouseId,
          name: warehouseName,
        })
      );
    }
  }, []);
  const {
    posFlowData,
    loadCartDetails,
    isLoadCartSuccess,
    customerAccount,
    isOpenAddCustomer,
  } = useSelector<RootState, PosFlowDataProp>((state) => state.persistReducer);

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const { cartId, customer } = loadCartDetails || {};

  const setCustomerDataWithDrawer = async (item) => {
    const draftData = {
      stockWarehouseId: currentWorkspace?.stockWarehouseId,
      buyerId: item.customerId,
    };
    if (!savedDrafts?.data?.length) {
      const customerData = searchCustomers?.find((item) =>
        [jobName, order, customerPO].includes(item.lookupType)
      );
      !customerData && dispatch(savedDraftsRequest(draftData));
    }
    const { priceVisible } = posFlowData || {};
    const data = {
      ...posFlowData,
      customer: item,
      shippingCharge: {
        shippingAmt: 0,
        shippingAmtTax: 0,
      },
      cartProducts: [],
      addInStorage: [],
      priceVisible: (priceVisible !== undefined) ? priceVisible : true
    };
    dispatch(setPosFlowData(data));
  };

  const priceScheduleApiCall = () => {
    if (!priceScheduleDetails?.data?.length) {
      dispatch(priceSchedulesRequest({ pageSize: "1", size: 20 }));
    }
  };
  const priceScheduleData = () => {
    if (priceScheduleDetails?.data?.length) {
      setState({ ...state, right: true });
    } else {
      setState({ ...state, right: false });
      const preparedData = {
        stockWarehouseId: currentWorkspace?.stockWarehouseId,
        customerId: tempPersonId,
        takerId: currentTakerInfoStorage?.personId,
        cartId: null,
      };
      dispatch(loadCartRequest(preparedData));
      dispatch(savedDraftsReset());
      navigate(`${Paths.posCart}/${cartId}`);
      dispatch(setPosFlowData({ ...posFlowData, customer: customer }));
    }
  };

  useEffect(() => {
    if (priceScheduleIdData && !isPriceScheduleLoading) {
      priceScheduleData();
      SetPriceScheduleIdData(false);
    }
  }, [priceScheduleIdData, priceScheduleDetails?.data?.length, isLoading]);

  useEffect(() => {
    if (showDraftDrawer) {
      setShowDraftDrawer(false);
      Boolean(cartId) && navigate(`${Paths.posCart}/${cartId}`);
      dispatch(setPosFlowData({ ...posFlowData, customer: customer }));
    }
  }, [loadCartDetails]);

  useEffect(() => {
    dispatch(setNoLoadDetailsAction(false));
  }, []);

  // May be need later
  // useEffect(() => {
  //   if (draftLoadMessage) {
  //     setLoader(false);
  //     setDraftCartModal(true);
  //     setState({ ...state, right: true });
  //   }
  // }, [draftLoadMessage]);

  const customerPricingScheduleRef = useRef<any>();
  const savedOrderFoundInfoRef = useRef();

  useEffect(() => {
    if (draftLoadError) {
      const preparedData = {
        stockWarehouseId: currentWorkspace?.stockWarehouseId,
        customerId: tempPersonId,
        takerId: currentTakerInfoStorage?.personId,
        cartId: null,
      };
      if (posFlowData?.customer?.priceScheduleId) {
        dispatch(loadCartRequest(preparedData));

        setTimeout(() => {
          setLoader(false);
          setDraftCartModal(false);
          dispatch(savedDraftsReset());
          setShowDraftDrawer(true);
        }, 1000);
      }
    }
  }, [draftLoadError]);

  const bySwitchCustomerSelectionFlow = (customerId: number, customer: any) => {
    dispatch(shippingAddressSelectedData(false));
    dispatch(getShippingFacilitiesClear());
    const data = {
      ...posFlowData,
      customer: customer,
    };
    dispatch(setPosFlowData(data));
    const preparedData = {
      stockWarehouseId: currentWorkspace?.stockWarehouseId,
      customerId,
      takerId: currentTakerInfoStorage?.personId,
      cartId: null,
    };
    dispatch(loadCartRequest(preparedData));
    setTimeout(() => {
      setLoader(false);
      setShowDraftDrawer(true);
    }, 1000);
  };

  const changeView = () => {
    SetAvatarListVisible(false);
    SetSearchListVisible(true);
  };

  const searchAPI = async (keywords) => {
    await setSearchCustomersData([]);
    const data = {
      ...posFlowData,
      draftCartDeleted: false,
    };
    dispatch(setPosFlowData(data));

    if (keywords.length === 0) {
      dispatch(clearSearchCustomer());
    }
    if (keywords.length >= 1) {
      setLoader(true);
      debounceFn(keywords);
    }
  };

  const handleDebounceFn = (keywords) => {
    const currentWorkspaceInfo = getLocalStoraGeItem("workspace");

    const body = {
      page: pageSize,
      size: 20,
      keywords: keywords,
      stockWarehouseId: currentWorkspaceInfo?.stockWarehouseId,
    };
    setSearchCustomersData([]);
    dispatch(addCustomersRequest(body));
  };

  const debounceFn = useCallback(_.debounce(handleDebounceFn, 500), []);

  useEffect(() => {
    setRecentCustomersData(recentCustomers);
    setRecentCustomerLoader(false);
  }, [recentCustomers]);

  useEffect(() => {
    if (posFlowData?.draftCartDeleted) {
      setSearchCustomersData(searchCustomers);
    } else if (searchCustomers?.length && searchCustomersData?.length) {
      setPageSize(
        searchCustomersLength > searchCustomersData?.length
          ? pageSize + 1
          : pageSize
      );
      setSearchCustomersData(
        _.uniqBy([...searchCustomersData, ...searchCustomers])
      );
      setLoader(false);
    } else {
      setHaveMore(searchCustomersLength > searchCustomersData?.length);
      setSearchCustomersData(searchCustomers);
    }
  }, [searchCustomers]);

  useEffect(() => {
    if (error === "errors" && searchCustomerResponse) {
      setLoader(false);
    }
  }, [searchCustomerResponse]);

  const searchRefNewOrder = useRef<any>();
  const firstRef = useRef<any>();

  const handleSerchCustomer = (customer) => {
    setTempPersonId(customer?.customerId);
    dispatch(suggestedProductsClear());
    setAddCustomerFormModal(false);
    setTimeout(() => {
      const data = {
        ...posFlowData,
        customer: customer,
        shippingCharge: {
          shippingAmt: 0,
          shippingAmtTax: 0,
        },
        cartProducts: [],
        addInStorage: [],
      };
      dispatch(setPosFlowData(data));
      dispatch(getShippingFacilitiesClear());
      if (customer?.priceScheduleId) {
        if (currentTakerInfoStorage?.personId) {
          setCustomerDataWithDrawer(customer);
        } else {
          setState({ ...state, right: true });
          SetOpenTaker(true);
        }
      } else {
        SetOpenTaker(false);
        SetPriceScheduleIdData(true);
        priceScheduleApiCall();
      }
    }, 1000);
  };

  useEffect(() => {
    if (enterPress) {
      if (searchCustomersData?.length) {
        if (posFlowData?.isCartAvailable) {
          genericCustomerHandleKey(
            searchCustomersData[selectedIndex]?.isPosGeneric,
            searchCustomersData[selectedIndex]?.customerId,
            searchCustomersData[selectedIndex]?.hasInternalNotes,
            searchCustomersData[selectedIndex]
          );
        } else {
          customerAccountTab(searchCustomersData[selectedIndex], selectedIndex, searchCustomersData);
          false && handleSerchCustomer(searchCustomersData[selectedIndex]);
        }
      }
      setEnterPress(false);
    }
  }, [enterPress]);

  const handleNewCustomerModel = () => {
    dispatch(addNewCustomerModel(!isOpenAddCustomer));
    dispatch(addNewCustomerClear());
  };
  const tableRowRef = useRef<any>();

  const scrollToSetting = (selectedIndex) => {
    const elementId = document?.getElementById(`tableRowRef${selectedIndex}`);

    elementId?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "end",
    });
  };

  useEffect(() => {
    if (downPress) {
      scrollToSetting(selectedIndex);
      dispatch(arrowDownIndex(searchCustomersLength));
      setDownPress(false);
    }
  }, [downPress]);

  useEffect(() => {
    if (upPress) {
      if (selectedIndex > -1) {
        scrollToSetting(selectedIndex);
      }
      setUpPress(false);
    }
  }, [upPress]);

  const genericCustomerHandleKey = (
    isLastCustomerGeneric: boolean,
    recentCustomersId: number,
    customerNote: boolean,
    customer: any
  ) => {
    if (!avatarListVisible) {
      if (!isLastCustomerGeneric) {
        bySwitchCustomerSelectionFlow(recentCustomersId, customer);
      } else {
        dispatch(snakbarShow({ message: "You cannot select generic customer" }))
      }
    } else {
      if (isLastCustomerGeneric) {
        bySwitchCustomerSelectionFlow(recentCustomersId, customer);
      } else {
        dispatch(snakbarShow({ message: "You cannot select generic customer" }))
      }
    }
  };

  function handleKeyDown(e) {
    const { keyCode } = e;
    const {
      enter,
      numKey1,
      numpad1,
      numpad2,
      numKey2,
      numKey3,
      numpad3,
      numKey4,
      numpad4,
      numKey5,
      numpad5,
      numKey6,
      numpad6,
      numKey7,
      numpad7,
      numKey8,
      numpad8,
      forwardslash,
      divide,
      esc,
      downarrow,
      uparrow,
      f1,
      f10,
    } = keys;
    if (e.keyCode >= f1 && e.keyCode <= f10 && !searchCustomers?.length) {
      e.preventDefault();
      if (genericCustomers?.genericCustomers[e.keyCode - 112]) {
        customerAccountTab(
          genericCustomers?.genericCustomers[e.keyCode - 112],
          e.keyCode - 112,
          genericCustomers?.genericCustomers[e.keyCode - 112]
        ); //  New Function for redirect to customer detail page
      }
    }
    if (!state.right && !openTaker) {
      if (document?.activeElement !== searchRefNewOrder.current) {
        if (keyCode === enter) {
          setEnterPress(true);
        } else if (keyCode === numKey1 || keyCode === numpad1) {
          customerAccountTab(recentCustomersData[0], 0, recentCustomersData);
        } else if (keyCode === numKey2 || keyCode === numpad2) {
          customerAccountTab(recentCustomersData[1], 1, recentCustomersData);
        } else if (keyCode === numKey3 || keyCode === numpad3) {
          customerAccountTab(recentCustomersData[2], 2, recentCustomersData);
        } else if (keyCode === numKey4 || keyCode === numpad4) {
          customerAccountTab(recentCustomersData[3], 3, recentCustomersData);
        } else if (keyCode === numKey5 || keyCode === numpad5) {
          customerAccountTab(recentCustomersData[4], 4, recentCustomersData);
        } else if (keyCode === numKey6 || keyCode === numpad6) {
          customerAccountTab(recentCustomersData[5], 5, recentCustomersData);
        } else if (keyCode === numKey7 || keyCode === numpad7) {
          customerAccountTab(recentCustomersData[6], 6, recentCustomersData);
        } else if (keyCode === numKey8 || keyCode === numpad8) {
          customerAccountTab(recentCustomersData[7], 7, recentCustomersData);
        }
      }
      if (keyCode === forwardslash || keyCode === divide) {
        setTimeout(() => {
          searchRefNewOrder.current.focus();
          SetAvatarListVisible(false);
          SetSearchListVisible(true);
          dispatch(arrowReset());
        }, 1);
      } else if (keyCode === esc) {
        searchRefNewOrder.current?.blur();
        SetSearchListVisible(false);
        SetAvatarListVisible(true);
        dispatch(arrowReset());
      } else if (keyCode === downarrow) {
        if (document?.activeElement === searchRefNewOrder.current) {
          searchRefNewOrder.current.blur();
          dispatch(arrowReset());
        }
        setDownPress(true);
      } else if (keyCode === uparrow) {
        dispatch(arrowUpIndex());
        setUpPress(true);
      }
    }
  }

  useEffect(() => {
    document?.addEventListener("keydown", handleKeyDown);
    return function cleanup() {
      document?.removeEventListener("keydown", handleKeyDown);
    };
  }, [recentCustomersData, state.right, searchCustomers]);

  useHotkeys("home", (e) => {
    if (e?.keyCode !== keys.home) return;

    dispatch(setSelectedIndex(0));

    scrollToSetting(0);
  });
  useHotkeys("end", (e) => {
    if (e?.keyCode !== keys.end) return;

    dispatch(setSelectedIndex(searchCustomersData?.length - 1));

    scrollToSetting(searchCustomersData?.length - 1);
  });

  let arrayData = 0;
  let searchIndex = -1;
  function handleKeyUp(e) {
    const { downarrow, uparrow } = keys;
    if (e.keyCode === downarrow) {
      if (arrayData === searchCustomersData?.length) {
        arrayData = 0;
      } else {
        arrayData = arrayData + 1;
      }
    }
    if (e.keyCode === uparrow) {
      if (arrayData === 0) {
        arrayData = searchCustomersData?.length;
      } else {
        arrayData = arrayData - 1;
      }
    }
    if (e.key === "s") {
      searchIndex = searchIndex + 1;
    }
  }
  useEffect(() => {
    document?.addEventListener("keydown", handleKeyUp);
  }, [arrayData]);

  useEffect(() => {
    if (currentWorkspace) {
      setSelectedWorkspace(currentWorkspace?.stockWarehouseId);
    }
  }, [currentWorkspace]);

  const classes = useStyles();

  useEffect(() => {
    // *? If you want to open Continue with old price model then uncomment this code
    // if (isLoadCartSuccess && cartId) {
    //   if (loadCartDetails !== undefined && products) {
    //     const isPriceChangesProduct = products?.filter(
    //       (item) => item?.isPriceChanged
    //     );
    //     if (isPriceChangesProduct?.length > 0) {
    //       dispatch(getupdateItemModal(true));
    //     }
    //   }
    // }
    if (isSwitchCustomerFlow) {
      dispatch(setPosFlowData({ ...posFlowData, shiptoFacilities: loadCartDetails?.shipToByFacility, viaSwitchCustomer: true, scheduleAdded: false }))
      setIsSwitchCustomerFlow(false);
      setCustomerDataForGenericFlow(null);
      navigate(`${Paths.posCart}/${cartId}`);
    }
    //JES-4766
    // if (isLoadCartSuccess && customerSearchDataRow?.lookupDraftCartId) {
    //   navigate(`${Paths.posCart}/${customerSearchDataRow?.lookupDraftCartId}`);
    // }
    dispatch(loadCartSuccessClear(false));
    // May be needed later
    // dispatch(setPosFlowData({...posFlowData,customer:customer}))
  }, [isLoadCartSuccess]);

  const handleScroll = () => {
    const lastScroll = searchCustomersData?.length === searchCustomersLength;

    if (lastScroll) {
      setHaveMore(false);
    }
    if (searchCustomersData?.length < searchCustomersLength) {
      const body = {
        page: pageSize + 1,
        size: 20,
        keywords: searchString,
        stockWarehouseId: selectedWorkspace,
      };
      dispatch(addCustomersRequest(body));
    }
  };

  const singlerecentCustomerLength = recentCustomersData?.length === 1;

  useEffect(() => {
    dispatch(addNewCustomerClear());
  }, []);

  useEffect(() => {
    if (hasHash === hashRoute.search) return;

    const handleCustomerKeyDown = (event: KeyboardEvent) => {
      const { keyCode } = event;

      if (keyCode === keys.uparrow || keyCode === keys.downarrow) {
        event.preventDefault();
        const tableElement = document.getElementById("customer-mui-table");

        if (tableElement) {
          const tableContainer = tableElement.closest(
            ".MuiTableContainer-root"
          ) as HTMLElement;
          if (tableContainer) {
            const { scrollTop, clientHeight, scrollHeight } = tableContainer;
            const rowHeight =
              tableContainer.querySelectorAll("tr")[0]?.offsetHeight || 0;

            if (keyCode === keys.uparrow) {
              tableContainer.scrollTop = Math.max(scrollTop - rowHeight, 0);
            } else if (keyCode === keys.downarrow) {
              tableContainer.scrollTop = Math.min(
                scrollTop + rowHeight,
                scrollHeight - clientHeight
              );
            }
          }
        }
      }
    };

    document.addEventListener("keydown", handleCustomerKeyDown);

    return () => {
      document.removeEventListener("keydown", handleCustomerKeyDown);
    };
  }, [hasHash]);

  const toggleNotesModal = () => {
    dispatch(
      setPosFlowData({
        ...posFlowData,
        isCartAvailable: true,
        customer: customerDataForGenericFlow,
      })
    );
    setIsNotesModalVisible(!isNotesModalVisible);
  };

  const { stockWarehouseId } = currentWorkspace || {};
  const { personId } = currentTakerInfoStorage || {};

  const loadCartAPI = () => {
    const {
      customer: { customerId, priceScheduleId },
    } = posFlowData || {};

    const preparedData = {
      stockWarehouseId,
      customerId,
      takerId: personId,
      cartId: null,
    };

    //Added condition for to enable switch customer
    const { customer: posCustomer } = posFlowData || {};
    const posData = {
      ...posFlowData,
      customer: { ...posCustomer, isPosGeneric: false },
    };
    dispatch(setPosFlowData(posData));

    if (!priceScheduleId) {
      setState({ ...state, right: true });
      setIsPriceScheduleDrawerVisible(true);
      return;
    }
    dispatch(loadCartRequest(preparedData));
  };

  const handlePriceScheduleDrawerClose = () => {
    toggleNotesModal();
    setIsPriceScheduleDrawerVisible(true);
    setState({ ...state, right: false });
    setNewOrderShippingAddress(false);
  };

  const customerAccountTab = (item, index, data) => {

    const {
      customerId,
      priceScheduleId,
      hasInternalNotes,
      noMailFlag
    } = item || {};
    dispatch(setIsMail(item?.noMailFlag));
    dispatch(isPurchaseOrderNoEdited(false));
    dispatch(setTabIndexNumber(0));
    dispatch(clearPackingSlipData());
    if (!customerDataForGenericFlow) {
      setCustomerDataForGenericFlow(posFlowData.customer);
    }
    if (
      item.isPosGeneric &&
      posFlowData.customer?.isPosGeneric &&
      posFlowData.isCartAvailable
    ) {
      dispatch(snakbarShow({ message: Message }))
      return;
    }
    setIsSwitchCustomerFlow(posFlowData.isCartAvailable);
    const flag = posFlowData.isCartAvailable;
    const { priceVisible, isCartAvailable, cartProducts } = posFlowData || {};
    const customerData = {
      ...posFlowData,
      addDocument: [],
      customer: item,
      shippingCharge: {
        shippingAmt: 0,
        shippingAmtTax: 0,
      },
      cartProducts: isCartAvailable ? cartProducts : [],
      isCartAvailable: false,
      isMail: noMailFlag,
      priceVisible: (priceVisible !== undefined) ? priceVisible : true
    };

    dispatch(setPosFlowData(customerData));
    dispatch(setCustomerAlertHide(true))
    dispatch(setCustomerAccountInfo(item));
    dispatch(
      customerDocumentRequest({
        customerId: Number(customerId),
        buildingId: 0,
        orderId: 0,
      })
    );
    dispatch(taxDocumentRequest(Number(customer?.companyID)));
    if (!priceScheduleId && flag) {
      setState({ ...state, right: true });
      setIsPriceScheduleDrawerVisible(true);
      return;
    }
    if (hasInternalNotes && flag) {
      setIsNotesModalVisible(true);
      return;
    }
    // JES-4766
    // if (
    //   [order, jobName, customerPO].includes(lookupType) &&
    //   !lookupDraftCartId &&
    //   !flag
    // ) {
    //   const draftData = {
    //     stockWarehouseId,
    //     buyerId: customerId,
    //   };
    //   dispatch(savedDraftsRequest(draftData));
    // }
    // if (lookupDraftCartId && !flag) {
    //   const preparedData = {
    //     stockWarehouseId,
    //     customerId,
    //     takerId: personId,
    //     cartId: lookupDraftCartId,
    //   };
    //   dispatch(loadCartRequest(preparedData));
    // }
    if (flag) {
      const preparedData = {
        stockWarehouseId,
        customerId,
        takerId: personId,
        cartId: null,
      };
      dispatch(getShippingFacilitiesClear());
      dispatch(loadCartRequest(preparedData));
    }
    else {
      dispatch(resetLoadCart())
      navigate(Paths.customer, { state: { detail: data[index], searchString } });
    }
  };
  useEffect(() => {
    if (customerDocumentDetails?.length || taxDocumentDetails?.length) {
      const readOnlyDocument = [];
      for (let i = 0; i < customerDocumentDetails?.length; i++) {
        const { objectId, description, documentSaveType, typeName, name, url, fileSizeText, id, modifiedAt, modifiedBy } = customerDocumentDetails[i];

        const read = {};
        read["customerId"] = objectId;
        read["description"] = description;
        read["documentSaveType"] = documentSaveType;
        read["documentSaveTypeName"] = typeName;
        read["file"] = name;
        read["fileUrl"] = url;
        read["locationSaveType"] =
          documentSaveType === shared
            ? all
            : current;
        read["size"] = fileSizeText;
        read["typeId"] = id;
        read["uploadedBy"] = modifiedBy;
        read["readOnly"] = true;
        read["lastModified"] = modifiedBy;
        read["modifiedAt"] = modifiedAt;
        readOnlyDocument.push(read);
      }
      for (let i = 0; i < taxDocumentDetails?.length; i++) {
        const { buyerCompanyID, docDec, docType, typeName, certificateName, docURL, docSize, companyDocID, createdDate } = taxDocumentDetails[i];
        const read = {};
        read["customerId"] = buyerCompanyID;
        read["description"] = docDec;
        read["documentSaveType"] = docType;
        read["documentSaveTypeName"] = typeName;
        read["file"] = certificateName;
        read["fileUrl"] = docURL;
        read["locationSaveType"] =
          docType === shared
            ? all
            : current;
        read["size"] = docSize;
        read["typeId"] = companyDocID;
        read["uploadedBy"] = createdDate;
        read["readOnly"] = true;
        read["lastModified"] = createdDate;
        read["modifiedAt"] = createdDate;
        readOnlyDocument.push(read);
      }
      const uploadData = [];
      posFlowData?.addDocument?.length &&
        posFlowData?.addDocument.map((item: UploadedDocumentItemProp) => {
          const { originalFile } = item;
          if (originalFile) {
            uploadData.push(item);
          }
        });

      const allDoc = readOnlyDocument.concat(uploadData);

      const data = {
        ...posFlowData,
        customer: customerAccount,
        addDocument: allDoc,
      };
      dispatch(setPosFlowData(data));
    }
  }, [customerDocumentDetails, customerAccount, taxDocumentDetails]);
  const { creditHold, crossDark, pressEnter } = images;
  const { newRegisteredAccount, selectCustomer, changeCustomer } = title;
  const Message = "Switching to another generic customer is not allowed. Please select a registered customer only."
  const orderLayout = document.querySelector("#order-layout");
  if (orderLayout) {
    orderLayout["style"].marginTop = `77px`;
  }

  const searchElement = document.getElementById("newOrderMainContentId");
  useEffect(() => {
    if (!avatarListVisible) {
      searchElement?.classList?.add("new-order-search-section");
    }
  }, [!avatarListVisible]);

  useEffect(() => {
    if (avatarListVisible) {
      searchElement?.classList?.remove("new-order-search-section");
    }
  }, [avatarListVisible]);

  return (
    <>
      <div id="order-layout" className="new-order-main-section">
        <PosHelmet
          title="POS :: New Order"
          metaName="Admin page"
          content="admin page"
        />
        <div
          className={
            posFlowData?.isCartAvailable
              ? "swatch-customer-container new-container-order"
              : "new-container-order"
          }
        >
          {posFlowData?.isCartAvailable && (
            <PosIconButton
              className="back-button pos-icon-back-btn"
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBackRoundedIcon className="back-icon" />
            </PosIconButton>
          )}
          <div className="order-heading flex-column ">
            <div className="new-customer-line">
              <PosPageHeading
                heading={posFlowData?.isCartAvailable ? "Cart" : "New Order"}
                grayHeading
                className="new-order-main-header-spacing new-order-header-spacing"
              />
              {!posFlowData?.isCartAvailable && (
                <>
                  <PosButton
                    tabIndex={1}
                    variant="contained"
                    color="primary"
                    className="btn new-order-header-spacing tabIndex-focused new-registered-btn"
                    fontWeight
                    onClick={() => {
                      const data = {
                        ...posFlowData,
                        addDocument: [],
                        poNumber: "",
                        firstName: "",
                        lastName: "",
                        phone: "",
                        email: "",
                        companyName: "",
                      };
                      dispatch(setPosFlowData(data));
                      dispatch(setShippingInstructionNote(""));
                      dispatch(setStoreCreditAmout(""));
                      dispatch(setJobNameForAll(""));
                      dispatch(setMarkAsQuoteReducer(false));
                      dispatch(setCustomerAlertHide(true));
                      if (!getStatesData?.length) dispatch(getStatesRequest());
                      setAddCustomerFormModal(true);
                      setState({ ...state, right: true });
                      dispatch(addNewCustomerClear());
                      (document.activeElement as HTMLElement).blur();
                    }}
                    startIcon={<PersonAddIcon />}
                  >
                    {newRegisteredAccount}
                  </PosButton>
                </>
              )}
            </div>
            <div className="order-sub-heading">
              <PosPageHeading
                heading={
                  posFlowData?.isCartAvailable
                    ? changeCustomer
                    : selectCustomer
                }
                blackHeading
              />
            </div>
          </div>
        </div>
        <section className="search-with-content-section">
          <div className="position-relative search-wrapper">
            <div
              className={`${avatarListVisible
                ? "customer-search-border w100"
                : "customer-search-results w100"
                }`}
            >
              <SearchIcon />
              <PosInput
                variant="standard"
                id="mainInput"
                tabIndex={-1}
                InputProps={{ disableUnderline: true }}
                inputProps={{
                  tabIndex: -1,
                }}
                value={searchString}
                onChange={(e) => {
                  if (e.target.value.charAt(0) !== " ") {
                    setSearchString(e.target.value);
                  }
                }}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    if (document?.activeElement === searchRefNewOrder.current) {
                      searchRefNewOrder.current.blur();
                    }
                    dispatch(clearSearchCustomer());
                    setPageSize(1);
                    setHaveMore(true);
                    searchAPI(searchString);
                  }
                }}
                className={`${avatarListVisible
                  ? "order-search-input h-100"
                  : "order-search-input-results h-100"
                  } customer-search-input-spacing`}
                onFocus={() => {
                  changeView();
                }}
                placeholder="Search"
                inputRef={searchRefNewOrder}
              />
              {avatarListVisible ? (
                <span className="keyboard-shortcut-backslash">/</span>
              ) : (
                <>
                  {!loader &&
                    !avatarListVisible &&
                    recentCustomersData?.length ? (
                    <div className="enter-search-text-new-order">
                      <span>Press Enter</span>
                      <PosImage
                        src={pressEnter}
                        alt="enter-icon"
                        className="press-enter-image "
                      />
                      <span>to search</span>
                    </div>
                  ) : null}
                  <div
                    onClick={() => {
                      SetAvatarListVisible(true);
                      SetSearchListVisible(false);
                      setSearchCustomersData([]);
                      setSearchString("");
                      setLoader(false);
                      dispatch(clearSearchCustomer());
                    }}
                  >
                    <PosImage src={crossDark} alt="cross-icon" />
                  </div>
                </>
              )}
            </div>
            {!searchListVisible ? (
              <div className="direction-row">
                {genericCustomers.genericCustomers?.length > 0
                  ? genericCustomers.genericCustomers.map((item, index) => {
                    return (
                      <button
                        key={`generic${index}`}
                        tabIndex={2}
                        className="f-btn-chips tabIndex-focused"
                        onClick={() => {
                          customerAccountTab(
                            item,
                            index,
                            genericCustomers.genericCustomers
                          );
                        }}
                      >
                        <span className="f-btn-chip-label">
                          {item?.posCustCode}
                        </span>
                        <div className="keyboard-shortcut-block">
                          {FKEYS[index]}
                        </div>
                      </button>
                    );
                  })
                  : null}
              </div>
            ) : null}
          </div>

          {avatarListVisible && (
            <>
              {recentCustomerLoader ? (
                <div
                  style={{
                    height: 70,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ width: "100%" }} />
                </div>
              ) : (
                <>
                  <div className="avatar-speed-dial-list">
                    {recentCustomersData?.length > 0 && (
                      <div className="recent-products-suggestion">
                        <span className="suggestion-text">Recent</span>
                        <div className="horizontal-liner-fp" />
                      </div>
                    )}
                    <div className="suggestions-list" ref={firstRef}>
                      {recentCustomersData?.length ? (
                        recentCustomersData?.map((item, index) => {
                          return (
                            <button
                              key={`suggestion ${index}`}
                              tabIndex={3}
                              className="product-suggestion-list-item tabIndex-focused"
                              onClick={() => {
                                customerAccountTab(
                                  item,
                                  index,
                                  recentCustomersData
                                );
                              }}
                            >
                              <div className="recent-product-suggestion-info">
                                <span className="avatar-username">
                                  {item.posCustCode}
                                </span>
                                <span className="avatar-username-line-2">
                                  {item.companyName}
                                </span>
                              </div>

                              <div className="keyboard-shortcut-block">
                                {index + 1}
                              </div>
                            </button>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </div>
                    {(!currentWorkspace || addCustomerFormModal) && <WorkStationDrawer
                      addCustomerFormModal={addCustomerFormModal}
                      setSelectedWorkspace={setSelectedWorkspace}
                      draftCartModal={draftCartModal}
                      currentWorkspace={currentWorkspace}
                      currentTakerInfoStorage={currentTakerInfoStorage}
                      drawerClass={classes.drawer}
                      state={state}
                      setState={setState}
                      open={state["right"]}
                      openTaker={openTaker}
                      startLoader={() => setRecentCustomerLoader(true)}
                    />}
                  </div>
                  {recentCustomersData?.length ? (
                    <div className="shortcuts-line">
                      <KeyboardIcon />
                      <span className="use-keyboard-line">
                        Use key
                        {recentCustomersData?.length === 1 ? "" : "s"}{" "}
                        {singlerecentCustomerLength
                          ? `1`
                          : `1 to ${recentCustomersData?.length}`}{" "}
                        on your keyboard to directly search for the customer
                      </span>
                    </div>
                  ) : null}
                </>
              )}
            </>
          )}
          {searchListVisible && (
            <div className="search-results-wrapper">
              {searchCustomers?.length ? (
                <div className="products-suggestion-sp product-found-info-cust mt-0">
                  <div className="product-found-text">
                    <span>
                      {customerDataCount} / {searchCustomersLength} customer
                      {searchCustomersLength > 1 ? "s" : ""} found
                    </span>
                  </div>
                  <div className="horizontal-liner-sp product-found-horizontal-line" />
                </div>
              ) : (
                <Box sx={{ width: "100%" }}>
                  {error === "errors" && (
                    <div className="products-suggestion-nf">
                      <div>
                        <span>No customers found</span>
                      </div>
                      <div className="horizontal-liner-sp" />
                    </div>
                  )}
                </Box>
              )}
              <div className="order-user-list-table scroll-styling">
                <TableContainer className="cart-product-listing-data">
                  <InfiniteScroll
                    className="pb-60"
                    dataLength={searchCustomersData?.length || 0}
                    next={handleScroll}
                    height={550}
                    hasMore={doesHaveMore}
                    loader={<div></div>}
                    scrollThreshold={0.1}
                  >
                    <Table
                      className={classes.root}
                      stickyHeader
                      aria-label="sticky table"
                      id="customer-mui-table"
                    >
                      {searchCustomersData?.length ? (
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align="left" className="pt-0">
                              CODE
                            </StyledTableCell>
                            <StyledTableCell align="left" className="pt-0">
                              CUSTOMER
                            </StyledTableCell>
                            <StyledTableCell align="left" className="pt-0">
                              LOCATION
                            </StyledTableCell>
                            <StyledTableCell align="left" className="pt-0">
                              CONTACT
                            </StyledTableCell>
                            <StyledTableCell align="left" className="pt-0">
                              SCHEDULE
                            </StyledTableCell>
                            <StyledTableCell align="left" className="pt-0">
                              LAST ORDER
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              className="pt-0"
                            ></StyledTableCell>
                          </TableRow>
                        </TableHead>
                      ) : !searchCustomersData?.length && !searchString ? (
                        <TableBody className="no-data-found-class">
                          <TableRow>
                            <StyledTableCell>
                              {!avatarListVisible ? (
                                <>
                                  <NoDataFound />
                                </>
                              ) : (
                                <></>
                              )}
                            </StyledTableCell>
                          </TableRow>
                        </TableBody>
                      ) : (
                        <>
                        </>
                      )}
                      {searchCustomersData?.length ? (
                        <TableBody className="table-border-styling">
                          {searchCustomersData?.map((mainItem, index) => {
                            return (
                              <StyledTableRow
                                className={`table-divider`}
                                key={index + 1}
                                style={{
                                  cursor: "pointer",
                                  backgroundColor:
                                    index === selectedIndex
                                      ? "lightgray"
                                      : "white",
                                  borderRadius:
                                    index === selectedIndex ? 30 : 0,
                                }}
                                onClick={async () => {
                                  if (isLoading) return;
                                  (
                                    document.activeElement as HTMLElement
                                  ).blur();
                                  // setCustomerSearchDataRow(mainItem);
                                  customerAccountTab(
                                    mainItem,
                                    index,
                                    searchCustomersData
                                  );
                                }}
                                ref={tableRowRef[index]}
                                id={`tableRowRef${index}`}
                              >
                                <StyledTableCell className="int-16-reg">
                                  {mainItem.posCustCode}
                                </StyledTableCell>
                                <StyledTableCell className="int-16-reg">
                                  <div className="customer-name">
                                    <span>
                                      {renamePersonData(
                                        mainItem.isPosGeneric
                                          ? mainItem.posCustCode
                                          : mainItem.companyName
                                      )}
                                      {mainItem.creditHoldFlag ? (
                                        <PosImage
                                          src={creditHold}
                                          alt="credit hold"
                                          className="marginleft10"
                                          title="Credit Hold"
                                        />
                                      ) : null}
                                    </span>
                                  </div>
                                </StyledTableCell>

                                <StyledTableCell className="int-16-reg">
                                  {mainItem.city && mainItem.state
                                    ? `${mainItem.city}, ${mainItem.state}`
                                    : ""}
                                </StyledTableCell>
                                <StyledTableCell className="int-16-reg">
                                  <div className="customer-name">
                                    <span>
                                      {phoneNumberFormatNew(
                                        mainItem?.phone?.intCode,
                                        mainItem?.phone?.areaCode,
                                        mainItem?.phone?.prefix,
                                        mainItem?.phone?.lineNum,
                                        mainItem?.phone?.extn,
                                        false
                                      )}
                                    </span>
                                  </div>
                                </StyledTableCell>
                                <StyledTableCell className="int-16-reg">
                                  {mainItem.priceScheduleName}
                                </StyledTableCell>
                                <StyledTableCell className="int-16-reg">
                                  {mainItem.lastOrderedAt
                                    ? dayjs(mainItem.lastOrderedAt).format(
                                      MMDDYYYY
                                    )
                                    : ""}
                                </StyledTableCell>
                                <StyledTableCell className="hover-last-cell">
                                  <ArrowForwardIcon />
                                </StyledTableCell>
                              </StyledTableRow>
                            );
                          })}
                        </TableBody>
                      ) : searchString ? (
                        <TableBody>
                          <TableRow>
                            <StyledTableCell
                              style={{
                                paddingLeft: 5,
                                borderBottomWidth: 0,
                              }}
                            />
                          </TableRow>
                        </TableBody>
                      ) : (
                        <></>
                      )}
                    </Table>
                  </InfiniteScroll>
                </TableContainer>
              </div>
            </div>
          )}
        </section>
      </div>

      <CustomerNotesModal
        open={isNotesModalVisible}
        onClose={toggleNotesModal}
        handleNewOrder={loadCartAPI}
      />

      <NewOrderDrawer
        addCustomerFormModal={false}
        setDraftCartModal={setDraftCartModal}
        draftCartModal={draftCartModal}
        open={isPriceScheduleDrawerVisible && state["right"]}
        drawerClass={classes.drawer}
        state={state}
        setState={setState}
        openTaker={openTaker}
        customerPricingScheduleRef={customerPricingScheduleRef}
        newOrderShippingAddress={newOrderShippingAddress}
        savedOrderFoundInfoRef={savedOrderFoundInfoRef}
        onCloseDrawer={handlePriceScheduleDrawerClose}
      />
      {/* need regarding registered customr changes */}
      {isOpenAddCustomer ? (
        <AddNewCustomerModal
          accountRegisteredModel={isOpenAddCustomer}
          setAccountRegisteredModel={handleNewCustomerModel}
        />
      ) : (
        <></>
      )}
    </>
  );
};
export default NewOrder;
