import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import {
  PosImage,
  PosModel,
  PosPageHeading,
  PosTooltip,
} from "apps/pos/Common/uiCore";
import ShapedSkeleton from "apps/springboard/Component/ShapedSkeleton";
import { Button } from "components";
import {
  setIsApiLoading,
  setPutInStorageItems,
  setShipmentId,
} from "../pages/customerInfo/CustomerOrders/redux/customerOrder.slice";
import { getOrderId } from "../pages/customerInfo/CustomerOrders/redux/selector";
import { useGetStorageItems } from "../pages/customerInfo/CustomerStorage/hooks/useGetStorageItems";

import texts from "apps/pos/locales";
import { title } from "constants/constant";
import { ClearIcon } from "constants/icons";
import images from "constants/images";
import { Paths } from "enums";
import { Distributor, PosResponseProps, RootState } from "models";
import { DocumentSortBy } from "utils/data";
import { MMDDYYformat } from "../constants";

import { arrowResetGeneric } from "sagas/pos/actions";

import "./customerOrdersData.scss";

interface StorageDataModalProps {
  isLoading?: boolean;
  setCustomerOrdersDataModal?: (boolean) => void;
  customerOrdersDataModal?: boolean;
  storageItemsData?: any;
  // will add type later
  shipmentIdData?: any;
  customersOrderModelRef?: React.MutableRefObject<any>
}

const CustomerOrdersData = ({
  customerOrdersDataModal,
  setCustomerOrdersDataModal,
  shipmentIdData,
  customersOrderModelRef,
  isLoading,
}: StorageDataModalProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const distributor = useSelector<RootState, Distributor>(
    (state) => state.distributor
  );
  const { storageOutlinedIcon, noShipmentsFound } = images;
  const { date, id, items } = title;
  const { putInStorage } = texts;
  const [filteredShipmentIdData, setFilteredShipmentIdData] = useState([]);

  const [sortIndex, setSortIndex] = useState(0);

  const {
    getShipmentDataFromShipmentId,
    storageItemsData,
    getStorageItemLoading,
  } = useGetStorageItems();

  const isApiLoading = [getStorageItemLoading].includes(true);

  const orderId = useSelector(getOrderId);
  const { selectedIndexGeneric } = useSelector<RootState, PosResponseProps>(
    (state) => state.pos
  );
  const finalData = !!shipmentIdData?.length;

  useEffect(() => {
    dispatch(setIsApiLoading(isApiLoading));
  }, [isApiLoading]);

  useEffect(() => {
    if (storageItemsData?.length) {
      dispatch(setPutInStorageItems(storageItemsData));
      navigate(Paths.putInStorage);
    }
  }, [storageItemsData]);

  useEffect(() => {
    if (shipmentIdData?.length) {
      setFilteredShipmentIdData(shipmentIdData);
    }
  }, [shipmentIdData]);

  const handleSort = (index) => {
    setSortIndex(index);
    document.getElementById("dropdownMenuButton").focus();
  };

  const handleModalClose = () => {
    setCustomerOrdersDataModal(false);
    dispatch(arrowResetGeneric());
  };

  return (
    <>
      <PosModel
        open={customerOrdersDataModal}
        onClose={handleModalClose}
        className={`set-customer-code-modal`}
        dialogClassName={`set-customer-code-modal-container storage-data-modal-container set-storage-modal-container customer-order-data-container`}
        dialogHeader={
          <div className="modal-header">
            <div>
              <PosPageHeading
                heading="Shipments"
                blackHeading
                className="mb-1 small-page-heading"
              />
              <div className={`page-heading d-flex gap-2 small-page-heading focus-link-navigation`}>
                <span className="heading-gray-text">
                  <a
                    href={`${distributor?.httpsUrl}${Paths.manageOrder}?orderID=${orderId}`}
                    target="_blank"
                  >
                    <span>{`Order #${orderId}`}</span>
                    <PosImage
                      src={images.linkIcon}
                      height={24}
                      width={24}
                      alt="Link Image"
                    />
                  </a>
                </span>
              </div>
            </div>
            <Button
              className="bg-transparent border-0 p-0 button-focus"
              tabIndex={0}
              onClick={() => setCustomerOrdersDataModal(false)}
            >
              <ClearIcon className="clear-icon" />
            </Button>
          </div>
        }
      >
        <section className="document-main-section" ref={customersOrderModelRef}>
          <div className="document-container">
            {/* // Need For sort data */}
            {finalData && filteredShipmentIdData.length ? (
              <div className="dropdown txt-align sort-by-dropdown">
                <div className="relative-light-border"></div>
                <button
                  className="sort fw-medium fs-14 text-dark btn btn-link dropdown-toggle sorting-dropdown-btn"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {`Sort By: ${sortIndex ? DocumentSortBy[sortIndex] : "Recent first"
                    }`}
                </button>

                <div
                  className="dropdown-menu selectList-drop"
                  abyria-labelledby="dropdownMenuButton"
                >
                  {DocumentSortBy.map((option, index) => (
                    <button
                      onClick={() => handleSort(index)}
                      className={`btn btn-link ${index === sortIndex ? "active" : ""
                        }`}
                      key={`${index}-${option}`}
                    >
                      {option}
                    </button>
                  ))}
                </div>
              </div>
            ) : null}
            <div>
              <TableContainer
                component={Paper}
                className="customer-info-table-container"
              >
                <Table
                  aria-label="simple table"
                  className="customer-table-styling"
                >
                  {isLoading ? (
                    <div className="pt-3">
                      <ShapedSkeleton
                        width={600}
                        height={70}
                        shape="0 0 350 20, 460 0 140 20, 0 40 140 20, 460 40 140 20"
                        radius={4}
                        fullWidth={true}
                      />
                    </div>
                  ) : (
                    <>
                      {finalData && filteredShipmentIdData.length ? (
                        <>
                          <TableHead className="customer-order-table-header">
                            <TableRow>
                              <TableCell className="customer-info-table-title order-info-table-title order-first-table-row">
                                {date}
                              </TableCell>
                              <TableCell className="customer-info-table-title order-info-table-title order-second-table-row">
                                {id}
                              </TableCell>
                              <TableCell className="customer-info-table-title order-info-table-title order-third-table-row">
                                {items}
                              </TableCell>
                              <TableCell className="customer-info-table-title order-info-table-title order-fourth-table-row"></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {filteredShipmentIdData?.map((row, index) => {
                              const {
                                shipDate,
                                shipmentID,
                                itemCount,
                                isOkToPutInStorage,
                              } = row || {};
                              return (
                                <TableRow
                                  className={`customer-table-data-row cursor-pointer customer-table-data-row ${index === selectedIndexGeneric
                                    ? "selectedRow"
                                    : ""
                                    }`}
                                  key={row.date}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor:
                                      index === selectedIndexGeneric
                                        ? "lightgray"
                                        : "white",
                                  }}
                                  id={`tableRowRef${index}`}
                                >
                                  <TableCell className="customer-table-row-data-styling order-first-table-row">
                                    <span>
                                      {dayjs(shipDate).format(MMDDYYformat)}
                                    </span>
                                  </TableCell>
                                  <TableCell className="customer-table-row-data-styling order-second-table-row">
                                    <span>{shipmentID}</span>
                                  </TableCell>
                                  <TableCell className="customer-table-row-data-styling order-third-table-row">
                                    <span>{itemCount}</span>
                                  </TableCell>
                                  <TableCell
                                    className={`customer-table-row-data-styling put-storage-section put-storage-hover-content`}
                                  >
                                    <Button
                                      className={`cursor-pointer d-flex justify-content-end bg-transparent border-0 p-0 put-in-storage-button ${isOkToPutInStorage
                                        ? ""
                                        : "order-info-put-in-storage-disbaled"
                                        }`}
                                      onClick={() => {
                                        if (isOkToPutInStorage) {
                                          dispatch(setShipmentId(shipmentID));
                                          getShipmentDataFromShipmentId({
                                            shipmentId: shipmentID,
                                          });
                                        }
                                      }}
                                      disabled={!isOkToPutInStorage}
                                    >
                                      {isOkToPutInStorage ? (
                                        <div>
                                          <PosImage
                                            src={storageOutlinedIcon}
                                            alt="add product"
                                            className="mr-2"
                                          />
                                          <span className="put-storage-title">
                                            {putInStorage}
                                          </span>
                                        </div>
                                      ) : (
                                        <PosTooltip
                                          title={
                                            "Putting into Storage is not permitted at this time"
                                          }
                                          placement="top"
                                        >
                                          <div>
                                            <PosImage
                                              src={storageOutlinedIcon}
                                              alt="add product"
                                              className="mr-2"
                                            />
                                            <span className="put-storage-title">
                                              {putInStorage}
                                            </span>
                                          </div>
                                        </PosTooltip>
                                      )}
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </>
                      ) : (
                        <div className="pt-3 no-shipments-found">
                          <div>
                            <PosImage
                              src={noShipmentsFound}
                              alt="No Shipments available"
                              className="mr-2"
                            />
                          </div>
                          <div>No Shipments available</div>
                        </div>
                      )}
                    </>
                  )}
                </Table>
              </TableContainer>
            </div>
          </div>
        </section>
      </PosModel>
    </>
  );
};

export default CustomerOrdersData;
