import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { PosButton, PosImage, PosInput, PosModel } from "apps/pos/Common/uiCore";
import { Button } from "components";
import { CheckIcon, ClearIcon } from "constants/icons";
import images from "constants/images";

import useSetCustomerCodeHooks from "./mutationHooks";

import { constantCustomerCode, SetCustomerCodeModalProps } from "apps/pos/pages/customerInfo/Modal/type";

import { snakbarShow } from "sagas/pos/snakbar/snakbar.slice";
import "./style.scss";



const SetCustomerCode = ({
  openEditCustomerCode,
  setOpenEditCustomerCode,
  posCustCode,
  setPosCustCodes
}: SetCustomerCodeModalProps) => {
  const [customerCodeOld, checkCustomerCodeOld] = useState<number>(posCustCode);

  const dispatch = useDispatch();

  const { customerId } = useSelector(
    ({ persistReducer }) =>
      persistReducer?.posFlowData?.customer
    //  (persistReducer?.loadCartDetails?.customer ? persistReducer?.loadCartDetails?.customer?.customerId : persistReducer?.customerAccount?.customerId) || persistReducer?.posFlowData?.customer?.customerId
    // TODO note: Need this if something breakdown the flow
  ) || ""

  const {
    initialValues,
    values,
    setFieldValue,
    handleSubmit,
    setFieldError,
    dirty,
    isValid,
    errors,
    setErrors,
    resetForm
  } = useFormik({
    initialValues: {
      customerCode: posCustCode,
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      customerCode: Yup.string().matches(
        /^[a-zA-Z0-9]+$/,
        "Invalid customer code, please use alphanumeric characters only."
      ),
    }),
    onSubmit: (values, { setFieldError }) => {
      if (initialValues.customerCode === values.customerCode) {
        setFieldError("customerCode", constantCustomerCode.codeExistError);
      } else {
        setCustomerCodeMutation.mutate({
          personId: customerId,
          customerCode: values.customerCode,
        });
      }
    },
  });

  const { checkCustomerCodeExsistMutation, setCustomerCodeMutation } =
    useSetCustomerCodeHooks({
      setOpenEditCustomerCode,
      setFieldError,
      setErrors,
      showSuccessSnakBar: () => {
        setPosCustCodes(values?.customerCode);
        dispatch(snakbarShow({
          message: initialValues.customerCode
            ? constantCustomerCode.customerCodeChanged
            : constantCustomerCode.customerCodeSet
        }))
      },
    });

  useEffect(() => {
    if (dirty) {
      const checkCustomerCode = setTimeout(() => {
        if (
          (`${Number(values.customerCode)}`.length >= 6 && !customerCodeOld) ||
          customerCodeOld !== values.customerCode
        ) {
          checkCustomerCodeOld(values.customerCode);
          checkCustomerCodeExsistMutation.mutate({
            customerCode: values.customerCode,
          });
        }
      }, 500);
      return () => clearTimeout(checkCustomerCode);
    }
  }, [values.customerCode, checkCustomerCodeExsistMutation, customerCodeOld]);

  const { sandClockIcon } = images
  const { isLoading: checkCustomerCodeExsistMutationLoading } = checkCustomerCodeExsistMutation
  const { isLoading: setCustomerCodeMutationLoading } = setCustomerCodeMutation
  const isApiLoading = [checkCustomerCodeExsistMutationLoading, setCustomerCodeMutationLoading].includes(true)

  const handleClose = () => {
    if (!isApiLoading) {
      resetForm();
      setOpenEditCustomerCode(false)
    }
  }

  return (
    <>
      <PosModel
        open={openEditCustomerCode}
        onClose={handleClose}
        className={`set-customer-code-modal`}
        dialogClassName={`set-customer-code-modal-container`}
        dialogHeader={
          <div className="modal-header">
            <span className="modal-title">
              {constantCustomerCode.setCustomerCode}
            </span>

            <Button className="bg-transparent border-0 p-0 button-focus" tabIndex={0} onClick={handleClose} >
              <ClearIcon
                className="clear-icon"
              />
            </Button>
          </div>
        }
        dialogFooter={
          <>
            <div className="d-flex flex-row-reverse">
              <PosButton
                variant="contained"
                color="primary"
                className={posCustCode ? "change-cust-code-save-btn btn" : "change-cust-code-set-btn btn"}
                startIcon={<CheckIcon />}
                fontWeight
                disabled={(initialValues.customerCode === values.customerCode) || (dirty && !isValid) || isApiLoading}
                onClick={() => handleSubmit()}
              >
                {posCustCode
                  ? constantCustomerCode.saveChanges
                  : constantCustomerCode.setCode}
              </PosButton>
              <PosButton
                type="button"
                variant="contained"
                className="mr-2 change-code-cancel-btn btn"
                startIcon={<ClearIcon />}
                fontWeight
                onClick={handleClose}
              >
                {constantCustomerCode.cancel}
              </PosButton>

            </div>
          </>
        }
      >
        <form onSubmit={handleSubmit}>
          <div className="modal-form">
            <div className="modal-input-title mb-2">
              {constantCustomerCode.customerCode}
            </div>
            <PosInput
              tabIndex={1}
              variant="outlined"
              id="filled-basic"
              className="mt-2 search-input-focus"
              autoFocus
              name="productName"
              onChange={(e) => {
                setFieldValue("customerCode", e.target.value);
              }}
              value={values.customerCode}
            />
            <br />
            <div className="d-flex align-items-center mt-2">
              {!isValid ? (
                <div className="modal-input-error">
                  {errors.customerCode
                    ? errors.customerCode
                    : constantCustomerCode.codeExistError}
                </div>
              ) : (checkCustomerCodeExsistMutation?.isLoading ? <>
                <PosImage src={sandClockIcon} alt="waiting-icon" className="check-availability-icon" />
                <span className="availability-text">
                  Checking availability...
                </span>
              </> : null)
              }
            </div>
          </div>
        </form>
      </PosModel>
    </>
  );
};
export default SetCustomerCode;
