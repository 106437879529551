import DescriptionIcon from '@mui/icons-material/Description';
import { Card, CardContent, Icon, Typography } from '@mui/material';
import { ErrorMessage } from "apps/pos/formik/errorMessage";
import { snackbarDetail } from 'constants/constant';
import { PosFlowDataProp } from 'constants/interfaces';
import dayjs from 'dayjs';
import { Formik } from "formik";
import { PosResponseProps, RootState } from 'models';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cartEmailWithTotalsReset } from 'sagas/pos/actions';
import { snakbarShow } from 'sagas/pos/snakbar/snakbar.slice';
import ButtonDatePicker from './Common/ButtonDatePicker/ButtonDatePicker';
import { PosButton, PosInput, PosModel } from "./Common/uiCore";
import { EmailQuoteDetailSchema } from "./formik/validationSchema";

const EmailQuotes = ({ openEmailQuote, setOpenEmailQuote, expiryDate, setExpiryDate, printCartWithTotal, emailQuote }) => {

    const dispatch = useDispatch();
    const { posFlowData } = useSelector<RootState, PosFlowDataProp>(
        (reducerState) => reducerState.persistReducer
    );
    const { cartEmailQuoteSuccess, cartEmailQuote, cartEmailQuoteLoading } = useSelector<RootState, PosResponseProps>(
        (state) => state.pos
    );

    useEffect(() => {
        if (cartEmailQuoteSuccess && cartEmailQuote) {
            dispatch(snakbarShow({ message: snackbarDetail.quoteSent }))
            setOpenEmailQuote(false);
            setTimeout(() => {
                dispatch(cartEmailWithTotalsReset());
            }, 0)
        }
    }, [cartEmailQuoteSuccess, cartEmailQuote])

    const { customer, savedCart, cartProducts } = posFlowData || {};
    const { cartId } = savedCart || {}

    const handleKeyDown = (event) => {
        event.stopPropagation();
    }
    return (
        <PosModel
            open={openEmailQuote}
            onClose={() => { setOpenEmailQuote(false); }
            }
            dialogTitle
            title="Email Quote"
            dialogClassName="email-quote-modal-container modal-btn-content-width common-modal-btn-style"
            closeIconTabIndex={0}
        >
            <Formik
                initialValues={{
                    toEmail: customer?.email,
                    subject: "",
                    message: ""
                }}
                enableReinitialize
                validationSchema={EmailQuoteDetailSchema}
                onSubmit={(value) => {
                    const emailQuoteData = {
                        ...value,
                        toEmail: value.toEmail.split(",").map((email) => email.trim())
                    }
                    emailQuote(emailQuoteData);
                }}
            >
                {({ handleSubmit, values, setFieldValue }) => {
                    const { toEmail, subject, message } = values;
                    return (
                        <>
                            <Card sx={{ display: 'flex', alignItems: 'center', padding: 2, backgroundColor: '#f5f5f5', borderRadius: 2, boxShadow: 'none', cursor: 'pointer' }} onClick={printCartWithTotal}>
                                <Icon sx={{ marginRight: 2, color: '#6a1b9a', width: "24px", height: "32px" }}>
                                    <DescriptionIcon />
                                </Icon>
                                <CardContent sx={{ padding: '0', flexGrow: 1 }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                        Quote from Cart #{cartId}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {cartProducts?.length} items
                                    </Typography>
                                </CardContent>
                                <Typography variant="body2" sx={{ color: 'text.secondary', marginRight: 2 }}>
                                    <ButtonDatePicker value={expiryDate ? expiryDate : dayjs().add(1, 'day')} setExpiryDate={setExpiryDate} />
                                </Typography>
                            </Card>
                            <div className="restocking-fee-modal mt-3" onKeyDown={(e) => handleKeyDown(e)}>
                                <div>
                                    <span className="input-title">To (Customer email)</span>
                                    <PosInput
                                        variant="filled"
                                        id="filled-basic"
                                        autoFocus
                                        className="fee-amt-input search-input-focus mt-1"
                                        InputProps={{
                                            disableUnderline: true
                                        }}
                                        name="toEmail"
                                        value={toEmail}
                                        onChange={(e) => setFieldValue("toEmail", e.target.value)}
                                    />
                                    <ErrorMessage name="toEmail" />
                                </div>
                                <div className='mt-3'>
                                    <span className="input-title  mt-1">Subject</span>
                                    <PosInput
                                        variant="filled"
                                        id="filled-basic"
                                        className="fee-amt-input search-input-focus mt-1"
                                        InputProps={{
                                            disableUnderline: true
                                        }}
                                        name="subject"
                                        value={subject}
                                        onChange={(e) => setFieldValue("subject", e.target.value)}
                                    />
                                    <ErrorMessage name="subject" />
                                </div>
                                <div className='mt-3'>
                                    <span className="input-title">Message</span>
                                    <PosInput
                                        variant="filled"
                                        id="filled-basic"
                                        maxRows={1}
                                        InputProps={{
                                            disableUnderline: true
                                        }}
                                        className="customer-company-address search-input-focus mt-1"
                                        name="message"
                                        multiline
                                        onChange={(e) => {
                                            setFieldValue("message", e.target.value);
                                        }}
                                        value={message}
                                    />
                                    <ErrorMessage name="message" />
                                </div>
                                <div className="use-default-button-section flex-row-reverse">
                                    <PosButton
                                        variant="contained"
                                        color="primary"
                                        className={`use-default-btn ms-auto btn return-set-picking-btn ${cartEmailQuoteLoading
                                            ? "button-accent button-is-loading-black"
                                            : ""
                                            }`}
                                        onClick={() => handleSubmit()}
                                        disabled={cartEmailQuoteLoading}
                                    >
                                        Email Quote
                                    </PosButton>

                                </div>
                            </div>
                        </>
                    );
                }}
            </Formik>
        </PosModel >
    )
}

export default EmailQuotes;