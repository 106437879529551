import { makeStyles } from "../posStyle";

import { colors } from "constants/styles";

const useStyle = makeStyles(({
  title: {
    fontSize: "1.3125rem",
    fontFamily: "Source Code Pro",
    fontStyle: "normal",
    color: colors.titleColor,
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  grayTitle: {
    fontSize: "1.5rem !important",
    fontFamily: "Source Code Pro",
    fontStyle: "normal",
    color: "#AAAAAA !important",
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  buttonClassName: {
    padding: '0',
    minWidth: '1.875rem',
    width: '1.5rem',
    height: '1.5rem',
  },
  closeSharp: {
    fontSize: '1.25rem'
  },
  subTitle: {
    fontSize: "1.5rem !important",
    fontFamily: "Source Code Pro",
    fontStyle: "normal",
    color: "#000000 !important",
    width: "100%",
    letterSpacing: "-0.03rem",
    fontWeight: "600 !important"
  },
}));
export default useStyle;