import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import DrawIcon from '@mui/icons-material/Draw';
import PaymentsIcon from '@mui/icons-material/Payments';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { defaultSelectedRefundModeReverse } from 'constants/enum';
import images from 'constants/images';
import { ReturnFlowDataMainProps } from 'constants/interfaces';
import { RootState } from 'models';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { PosButton, PosModel } from "../Common/uiCore";
import { roundToTwoDecimalPlaces } from 'utils/utils';


const ReviewRefundsDialog = ({ open, handleClose, totalRefund, sameOrderIdData, refundMethodCheckedArray, completeReturn, isReturnProductsOrdersLoading }) => {
  const { visa, mastercard, amex, discover } = images;
  const {
    shippingFeeData,
    restockingFeeData,
    handlingFeeValue,
  } = useSelector<RootState, ReturnFlowDataMainProps>(
    (state) => state.persistReducer
  );

  // Set to track orders where fees have already been applied
  const appliedFees = new Set();
  const sums = {
    CHECK: 0,
    CASH: 0,
    REGISTERED_ACCOUNT: 0,
    VISA: 0,
    MASTERCARD: 0,
    DISCOVER: 0,
    AMERICAN_EXPRESS: 0,
    STORE_CREDIT: 0,
    VISA_NUMBER: 0,
    VISA_NAME: 0,
  };
  const cardDetails = {};

  function calculateTotalItemCreditNew(value, type) {
    return value
      ?.map((item) => {
        const qty = type === "return" ? item.returnQty : item.replaceQty;
        return item.unitPrice / (item.priceMU * 1.0) * Number(qty) * item.itemMU

      })
      .reduce((total, obj) => {
        const returnSum = Number(obj) + Number(total);
        return type === "return" ? Number(roundToTwoDecimalPlaces(returnSum)) : returnSum
      }, 0);
  }

  function calculateRefund(item, appliedFees, key, sums, refundType) {
    const returnSum = calculateTotalItemCreditNew([item], "return");
    const replacementSum = calculateTotalItemCreditNew([item], "replace");
    const totalItemCreditNew = Number((returnSum)) + Number((replacementSum));
    const tax = item.taxRate || 0;
    const totalTaxCredit = Number(totalItemCreditNew) * Number(tax);

    const shippingFeeInfo = shippingFeeData?.find(
      (item) => Number(item?.orderId) === Number(key)
    );
    const restockingFeeInfo = restockingFeeData?.find(
      (item) => Number(item?.orderId) === Number(key)
    );
    const handlingFeeInfo = handlingFeeValue?.find(
      (item) => Number(item?.orderId) === Number(key)
    );

    const { shippingFeeChange } = shippingFeeInfo || {};
    const { restockingFee } = restockingFeeInfo || {};
    const { handlingFee } = handlingFeeInfo || {};

    let totalRefundNew =
      Number((Number((totalItemCreditNew)) + Number(((totalTaxCredit)))));
    if (!appliedFees.has(key)) {
      totalRefundNew += Number((shippingFeeChange ?? 0));
      totalRefundNew -= (Number(restockingFee ?? 0) + Number(handlingFee ?? 0));

      appliedFees.add(key);
    }

    if (refundType.type === "3") {
      sums.STORE_CREDIT += totalRefundNew;
    } else if (refundType.type === "2") {

      if (item.creditCardType === "VISA") {
        sums.VISA += totalRefundNew;
      } else if (item.creditCardType === "MASTERCARD") {
        sums.MASTERCARD += totalRefundNew;
      } else if (item.creditCardType === "DISCOVER") {
        sums.DISCOVER += totalRefundNew;
      } else if (item.creditCardType === "AMERICAN_EXPRESS") {
        sums.AMERICAN_EXPRESS += totalRefundNew;
      }
      if (item?.cardNum) {
        if (!cardDetails[`${item.cardNum + "_" + item.creditCardType}`]) {
          cardDetails[`${item.cardNum + "_" + item.creditCardType}`] = {
            creditCardType: item.creditCardType,
            cardNum: item.cardNum,
            amount: 0,
          };
        }
        cardDetails[`${item.cardNum + "_" + item.creditCardType}`].amount += totalRefundNew;  // Accumulate refund
      }
    } else {
      const refundMode = defaultSelectedRefundModeReverse[refundType.type];
      sums[refundMode] += totalRefundNew;
    }
  }


  for (const key in sameOrderIdData) {
    sameOrderIdData[key].forEach((item) => {
      const refundType = refundMethodCheckedArray.find(
        (refund) => refund.orderId === item.orderId
      );
      calculateRefund(item, appliedFees, key, sums, refundType);
    });
  }
  return (
    <PosModel
      open={open}
      onClose={() =>
        handleClose(false)
      }
      dialogTitle
      title="Review Refunds"
      dialogClassName="review-refund-modal-container"
      subTitle={`$ ${totalRefund}`}
    >
      <List>
        {cardDetails ? Object.keys(cardDetails).map((cardNum, index) => (
          <Fragment key={index}>
            <ListItem>
              <ListItemIcon>
                <img src={cardDetails[cardNum].creditCardType === "VISA" ? visa : cardDetails[cardNum].creditCardType === "MASTERCARD" ? mastercard : cardDetails[cardNum].creditCardType === "AMERICAN_EXPRESS" ? amex : cardDetails[cardNum].creditCardType === "DISCOVER" ? discover : null} alt=""></img>
              </ListItemIcon>
              {/* Show card type and card number */}
              <ListItemText
                primary={`${cardDetails[cardNum].creditCardType} ${cardDetails[cardNum].cardNum}`}
              />
              <Typography>
                ${cardDetails[cardNum].amount.toFixed(2)}
              </Typography>
            </ListItem>
          </Fragment>
        )) : null}
        {sums.CASH ? <ListItem className='sr-payment-refund-review'>
          <ListItemIcon>
            <PaymentsIcon sx={{ color: '#388e3c' }} />
          </ListItemIcon>
          <ListItemText primary="Cash" />
          <Typography>${sums.CASH.toFixed(2)}</Typography>
        </ListItem> : null}
        {sums.CHECK ? <ListItem className='sr-payment-refund-review'>
          <ListItemIcon>
            <DrawIcon sx={{ color: '#8e24aa' }} />
          </ListItemIcon>
          <ListItemText primary="Check" />
          <Typography>${sums.CHECK.toFixed(2)}</Typography>
        </ListItem> : null}
        {sums.REGISTERED_ACCOUNT ? <ListItem className='sr-payment-refund-review'>
          <ListItemIcon>
            <AccountBalanceWalletIcon sx={{ color: '#795548' }} />
          </ListItemIcon>
          <ListItemText primary="Account" />
          <Typography>${sums.REGISTERED_ACCOUNT.toFixed(2)}</Typography>
        </ListItem> : null}
        {sums.STORE_CREDIT ? <ListItem className='sr-payment-refund-review'>
          <ListItemIcon>
            <CardMembershipIcon sx={{ color: '#d32f2f' }} />
          </ListItemIcon>
          <ListItemText primary="Store Credit" />
          <Typography>${sums.STORE_CREDIT.toFixed(2)}</Typography>
        </ListItem> : null}
      </List>
      <div className="sr-use-default-button-section mt-3">
        <PosButton variant="contained" color="secondary" className="use-default-btn"
          disabled={isReturnProductsOrdersLoading}
          onClick={() =>
            handleClose(false)}
        >
          Back
        </PosButton>
        <PosButton
          onClick={completeReturn}
          variant="contained" color="primary"
          className={`return-set-picking-btn use-complete-btn ${isReturnProductsOrdersLoading
            ? "button-accent button-is-loading-black"
            : ""
            }`}
          startIcon={!isReturnProductsOrdersLoading ? <TaskAltIcon /> : null}
          disabled={isReturnProductsOrdersLoading}
        >
          <span>
            {!isReturnProductsOrdersLoading
              ? "Complete Return"
              : null}
          </span>
        </PosButton>
      </div>
    </PosModel>
  );
};

export default ReviewRefundsDialog;
