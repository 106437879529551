import MaterialIcon from "apps/pos/Common/uiCore/MaterialIcon/MaterialIcon";
import SPButton from "components/SPButton/Button";
import { Button } from "components";
import { useEffect, useRef } from "react";
import SignaturePad from "signature_pad";

interface SignatureDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (signatureData: string) => void;
  initialSignature?: string;
  resetSignature: () => void;
}

export function SignatureDialog({
  isOpen,
  onClose,
  onSave,
  initialSignature,
  resetSignature,
}: SignatureDialogProps) {
  const dialogRef = useRef<HTMLDialogElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const signaturePadRef = useRef<SignaturePad | null>(null);

  useEffect(() => {
    if (isOpen) {
      dialogRef.current?.showModal();
    } else {
      dialogRef.current?.close();
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && canvasRef.current) {
      const canvas = canvasRef.current;
      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      canvas.width = canvas.offsetWidth * ratio;
      canvas.height = canvas.offsetHeight * ratio;
      canvas.getContext("2d")?.scale(ratio, ratio);

      signaturePadRef.current = new SignaturePad(canvas, {
        minWidth: 2,
        maxWidth: 4,
        throttle: 10,
        minDistance: 0,
      });

      if (initialSignature) {
        // Load the signature after a brief delay to ensure canvas is ready
        setTimeout(() => {
          if (signaturePadRef.current) {
            signaturePadRef.current.clear(); // Clear first to prevent ghosting
            signaturePadRef.current.fromDataURL(initialSignature);
          }
        }, 50);
      }
    }

    return () => {
      if (signaturePadRef.current) {
        signaturePadRef.current.clear();
      }
    };
  }, [isOpen, initialSignature]);

  function dispersionEffect(canvas, duration = 1000) {
    const ctx = canvas.getContext('2d');
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const particles = [];

    for (let y = 0; y < imageData.height; y++) {
      for (let x = 0; x < imageData.width; x++) {
        const index = (y * imageData.width + x) * 4;
        const alpha = imageData.data[index + 3];
        if (alpha > 0) {
          const delay = Math.sqrt(x * x + y * y) / Math.sqrt(canvas.width * canvas.width + canvas.height * canvas.height) * duration * 0.45;
          particles.push({
            x,
            y,
            size: 1,
            alpha: 1,
            delay,
            dx: (Math.random() - 0.5) * 0.75,
            dy: (Math.random() - 0.5) * 0.75
          });
        }
      }
    }

    const startTime = performance.now();

    function animate(time) {
      const elapsed = time - startTime;
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      particles.forEach((p) => {
        const particleElapsed = elapsed - p.delay;
        if (particleElapsed > 0) {
          const progress = Math.min(particleElapsed / (duration * 0.25), 1);

          p.x += p.dx * (1 - progress * 0.5);
          p.y += p.dy * (1 - progress * 0.5);

          p.alpha = Math.cos(progress * Math.PI) * 0.5 + 0.5;

          if (p.alpha > 0) {
            ctx.fillStyle = `rgba(0, 0, 0, ${p.alpha})`;
            ctx.fillRect(p.x, p.y, p.size, p.size);
          }
        } else {
          ctx.fillStyle = 'rgba(0, 0, 0, 1)';
          ctx.fillRect(p.x, p.y, p.size, p.size);
        }
      });

      if (elapsed < duration) {
        requestAnimationFrame(animate);
      }
    }

    requestAnimationFrame(animate);
  }


  const updateSignature = () => {
    if (signaturePadRef.current && !signaturePadRef.current.isEmpty()) {
      const signatureData = canvasRef.current?.toDataURL();
      if (signatureData) {
        onSave(signatureData);
      }
    }
  };

  const handleSave = () => {
    updateSignature();
    onClose();
  };

  const handleClear = () => {
    if (!signaturePadRef.current || signaturePadRef.current.isEmpty()) return;
    const permission = confirm("Clear signature?");
    if (!permission) return;
    dispersionEffect(canvasRef.current);
    signaturePadRef.current.clear();
    resetSignature();
  };

  useEffect(() => {
    signaturePadRef.current?.addEventListener("endStroke", () => {
      onSave(signaturePadRef.current?.toDataURL() ?? "");
    });

    return () => {
      signaturePadRef.current?.removeEventListener("endStroke", () => {
        onSave(signaturePadRef.current?.toDataURL() ?? "");
      });
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <dialog
      ref={dialogRef}
      className="signature-dialog"
      onClose={onClose}
    >
      <div className="dialog-header">

        <span className="draw-signature">Draw your signature</span>
        <Button className="bg-transparent border-0 p-0 button-focus"
          onClick={onClose} >
          <MaterialIcon icon="close" />
        </Button>
      </div>

      <div className="signature-pad-wrapper">
        <canvas
          ref={canvasRef}
          className="signature-pad"
        />
      </div>

      <div className="dialog-actions">
        <SPButton
          label='Clear'
          type='secondary'
          onClick={handleClear}
        />
        <SPButton
          label='Save'
          type='primary'
          onClick={handleSave}
        />
      </div>
    </dialog>
  );
}
