/* eslint-disable */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    PosButton,
    PosIconButton,
    PosImage,
    PosInput,
} from "apps/pos/Common/uiCore";
import { IProductsFilters } from "apps/product/modules/productListingContainer/types";
import { Button } from "components";

import { keys } from "utils/helper";
import { hideScrollCartPage, showScrollCartPage } from "utils/utils";

import { availabilityStatus, commonConstants, } from "apps/product/helper/commonConstants";
import { FileUploadIcon, KeyboardIcon } from "constants/icons";
import images from "constants/images";
import {
    PosFlowDataProp,
} from "constants/interfaces";
import { PosResponseProps, RootState } from "models";
import {
    SearchProductProps
} from './type';

import {
    getChangePriceInfo,
} from "sagas/persistReducer/actions";
import {
    arrowResetProductPage,
    clearSearchProduct,
    stockByWarehouseStart,
} from "sagas/pos/actions";


const SearchProduct = ({
    suggestionListVisible,
    cartEmpty,
    productSearch,
    addProductPageProductSearchRef,
    loader,
    addProductPageSearchRef,
    searchProductsData,
    addProductPageRef,
    suggestedProducts,
    singleSuggestedLength,
    searchEmpty,
    importingQuote,
    importQuote,
    setSuggestionListVisible,
    setCartEmpty,
    setPageSize,
    searchProductsAPI,
    setProductSearch,
    setShippingAddress,
    setSearchProductsData,
    handleOpen,
    showProductIncart,
    mainCartSearch,
    handleFilter,
    availabilityData,
    handleOptions,
    setAvailabilityData,
    setProductStatus,
    productStatus,
    setFilteredSearchProduct,
    availabilitydropdownRef,
    clearFilter,
    setProductData
}: SearchProductProps) => {
    const dispatch = useDispatch();

    const {
        suggestedProductsData
    } = useSelector<RootState, PosResponseProps>((state) => state.pos);

    const {
        posFlowData,
    } = useSelector<RootState, PosFlowDataProp>((state) => state.persistReducer);

    const { esc, enter } = keys
    const {
        searchBold,
        pressEnter,
        searchProductEmptyIcon,
        closeIcon
    } = images;

    const handleSearch = (product) => {
        setProductData(product);
        showProductIncart(product?.productId);
        dispatch(stockByWarehouseStart([product?.productId]));
        dispatch(getChangePriceInfo(false));
    }
    const { getProductFiltersData } = useSelector<
        RootState,
        IProductsFilters
    >((state) => state.productFiltersSlice);

    const { availability } = getProductFiltersData || {}
    const {
        availability: Availability,
    } = commonConstants

    useEffect(() => {
        const addData = {
            ...availability,
            ...availabilityStatus
        }
        setAvailabilityData(addData)
    }, [availability])
    return (
        <>
            <div className="cart-searchbar-wrapper">
                <div
                    className={`${suggestionListVisible
                        ? "order-search-border cart-product-search"
                        : !suggestionListVisible && !cartEmpty
                            ? "order-search-border cart-product-search"
                            : "customer-search-results cart-product-search"
                        } searchbar-wrapper`}
                >
                    <div className="searchbar-icon">
                        <PosImage src={searchBold} alt="bolt1" />
                    </div>

                    <PosInput
                        variant="standard"
                        id="standard-basic"
                        tabIndex={1}
                        InputProps={{ disableUnderline: true }}
                        inputProps={{
                            tabIndex: -1,
                        }}
                        placeholder="Search products"
                        className={`${suggestionListVisible
                            ? "order-search-input h-100 order-text"
                            : !suggestionListVisible && !cartEmpty
                                ? "order-search-input h-100 order-text"
                                : "order-search-input-results h-100 order-text"
                            } searchbar-input`}
                        value={productSearch}
                        inputRef={addProductPageProductSearchRef}
                        onFocus={() => {
                            hideScrollCartPage();
                            if (!cartEmpty) {
                                setCartEmpty(true);
                            }
                            setSuggestionListVisible(false);
                        }}
                        autoComplete="off"
                        onChange={(e) => {
                            if (
                                !e.target.value.includes("  ") &&
                                e.target.value.charAt(0) !== " "
                            ) {
                                setProductSearch(e.target.value);
                            }
                        }}
                        onKeyDown={(event) => {
                            dispatch(arrowResetProductPage());
                            if (event.keyCode === enter) {
                                addProductPageProductSearchRef.current.blur()
                                dispatch(clearSearchProduct());
                                setPageSize(1);
                                searchProductsAPI(productSearch);
                            }
                            if (event.keyCode === esc) {
                                addProductPageProductSearchRef.current.blur()
                                showScrollCartPage();
                                if (posFlowData?.cartProducts?.length > 0) {
                                    setProductSearch("");
                                    setSearchProductsData([]);
                                    setFilteredSearchProduct([]);
                                    setCartEmpty(false);
                                    setShippingAddress(false);
                                    dispatch(clearSearchProduct());
                                } else {
                                    setProductSearch("");
                                    setFilteredSearchProduct([]);
                                    setSearchProductsData([]);
                                    setSuggestionListVisible(true);
                                    setShippingAddress(false);
                                    dispatch(clearSearchProduct());
                                }
                            }
                        }}
                    />

                    {(suggestionListVisible || !cartEmpty) ? (
                        <div className="keyboard-shortcut-block">/</div>
                    ) : (
                        <>
                            <PosIconButton
                                className="icon-btn.close-input-icon-btn icon-btn clear-btn close-input-icon-btn pos-icon-back-btn"
                                onClick={() => {
                                    showScrollCartPage();
                                    dispatch(arrowResetProductPage());
                                    if (posFlowData?.cartProducts?.length > 0) {
                                        setProductSearch("");
                                        setCartEmpty(false);
                                        setShippingAddress(false);
                                        clearFilter();
                                        setFilteredSearchProduct([]);
                                        setSearchProductsData([]);
                                        dispatch(clearSearchProduct());
                                    } else {
                                        setProductSearch("");
                                        clearFilter();
                                        setFilteredSearchProduct([]);
                                        setSearchProductsData([]);
                                        setSuggestionListVisible(true);
                                        setShippingAddress(false);
                                        dispatch(clearSearchProduct());
                                    }
                                }}
                            >
                                <PosImage src={closeIcon} alt="shipping-icon" />
                            </PosIconButton>
                        </>
                    )}
                </div>
                {(!loader &&
                    !suggestionListVisible &&
                    searchProductsData?.length) ? (
                    <div className="position-relative w-100">
                        <div
                            className="enter-search-text mt-3 padding-right-search-text"
                            ref={addProductPageSearchRef}
                        >
                            <span>Press Enter</span>
                            <PosImage
                                src={pressEnter}
                                alt="press_enter_icon"
                                className="press-enter-image"
                            />
                            <span>to search</span>
                        </div>
                    </div>
                ) : (
                    <></>
                )}
            </div>
            {(!suggestionListVisible &&
                cartEmpty &&
                !loader &&
                !searchProductsData.length) ? (
                <div className="no-data-cart-sec position-relative w-100">
                    <div
                        className="enter-search-text mt-3 padding-right-search-text"
                        ref={addProductPageSearchRef}
                    >
                        <span>Press Enter</span>
                        <PosImage
                            src={pressEnter}
                            alt="press_enter_icon"
                            className="press-enter-image"
                        />
                        <span>to search</span>
                    </div>
                </div>) : null}

            {/* may need later */}
            {/* {(!suggestionListVisible && cartEmpty) ?
                <div className="filter-container filters product-list-select-dropdown">
                   <CustomDropDown label={Availability} dropdownData={availabilityData ? availabilityData : []} setOptions={handleOptions} setPreData={setProductStatus} preData={productStatus} handleFilter={handleFilter} availabilitydropdownRef={availabilitydropdownRef} /> 
                </div > : null
            } */}
            {searchProductsData.length ? <div className="hide-extra-text"></div> : null}
            {
                (!suggestionListVisible &&
                    cartEmpty &&
                    !loader &&
                    !searchProductsData.length) ? (
                    <div className="no-data-cart-sec" ref={mainCartSearch}>
                        {suggestedProductsData?.length ? (
                            <div className="cart-wrapper empty-cart-wrapper search-product-suggestion-wrapper">
                                <div className="product-search-suggestions">
                                    {suggestedProductsData?.length ? (
                                        <div className="title">
                                            <span>Suggestions</span>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    <div className="suggestions-list" ref={addProductPageRef}>
                                        {suggestedProducts?.map((product, index) => (
                                            <div key={index}>
                                                <Button
                                                    className="suggestion-list-item btn cart-page-product-suggestion suggetion-focus"
                                                    key={index}
                                                    onClick={() => { handleSearch(product) }}
                                                >
                                                    <div className="suggestion-info">
                                                        <div className="pos-product-image suggestion-image">
                                                            <PosImage
                                                                src={product?.thumbImg}
                                                                alt="back"
                                                            />
                                                        </div>
                                                        <span className="name">
                                                            {product?.productName}
                                                        </span>
                                                    </div>

                                                    <div className="keyboard-shortcut-block">
                                                        {index + 1}
                                                    </div>
                                                </Button>
                                            </div>
                                        ))}
                                    </div>
                                    {suggestedProductsData?.length ? (
                                        <div className="hint">
                                            <KeyboardIcon />
                                            <span className="hint-copy">
                                                Use key
                                                {suggestedProductsData?.length === 1
                                                    ? ""
                                                    : "s"}{" "}
                                                {singleSuggestedLength
                                                    ? `1`
                                                    : `1 to ${suggestedProductsData?.length}`}{" "}
                                                on your keyboard to directly search for the item
                                            </span>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className="search-empty-icon-div">
                                <PosImage
                                    src={searchProductEmptyIcon}
                                    alt="search-product-empty-icon"
                                />
                                <span className="search-text">{searchEmpty}</span>
                                <span className="search-text">{importingQuote}</span>
                                <PosButton
                                    startIcon={<FileUploadIcon />}
                                    variant="contained"
                                    className="import-quote-btn"
                                    onClick={() => handleOpen()}
                                >
                                    {importQuote}
                                </PosButton>
                            </div>
                        )}
                    </div>
                ) : null
            }
        </>
    )
}

export default SearchProduct