import "@fontsource/inter";
import { Box, Container, Grid, makeStyles } from "@material-ui/core";
import dayjs from "dayjs";
import _ from "lodash";
import moment from "moment";
import { Ref, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  PosButton,
  PosHelmet,
  PosIconButton,
  PosImage,
  PosInput,
  PosPageHeading,
  PosTooltip
} from "apps/pos/Common/uiCore";
import { Button } from "components";
import PaymentMethod from "./PaymentMethod";
import Skeleton from "./pages/customerInfo/Skeleton";

import texts from "apps/pos/locales";
import SetStorageModal from "apps/pos/storage/setStorage";
import { buttonTitle, numbersOnlyRegEx, staticData, storageItem, title } from "constants/constant";
import { ArrowBackRoundedIcon, InfoOutlinedIcon } from "constants/icons";
import images from "constants/images";
import {
  PickedQtyWithLocationProps,
  PosFlowDataProp,
  StorageDataProps,
  UploadedDocumentItemProp,
  storageProductData
} from "constants/interfaces";
import { Paths } from "enums";
import { PosResponseProps, RootState } from "models";
import {
  getLocalStoraGeItem,
  hideScrollCartPage,
  preventArrowKeys,
  showScrollCartPage,
} from "utils/utils";
import DocReuploadModal from "./pages/customerInfo/Documents/docReuploadAlert/DocReuploadModal";

import { keys, openAndPrintPdf } from "utils/helper";

import {
  examptShipTolocationData,
  setNoLoadDetailsAction,
  setPosFlowData,
  setShipToAddress,
  setToPickUp,
  showAlreadyPickedInfoData,
} from "sagas/persistReducer/actions";
import {
  addToCartDrawer,
  cartEmailWithTotalsRequest,
  cartPrintClear,
  cartPrintRequest,
  cartPrintWithTotalsRequest,
  getIsGoToCart,
  getWarehourseLocationsRequest,
  resetBillingDetails,
  setMarkAsQuoteReducer,
  setPriceCheckReducer,
} from "sagas/pos/actions";

import { ProductTypes } from "constants/enum";
import { snakbarShow } from "sagas/pos/snakbar/snakbar.slice";
import BackOrderConfirmationModal from "./BackOrderConfirmationModal/BackOrderConfirmationModal";
import OrderDetailsDrawer from "./Drawers/OrderDetailsDrawer";
import { useStyles } from "./OrderDetailsComponent/styleHelper";
import "./PickQuantity.scss";
import "./neworder.scss";
// import { useGetPayPalSettings } from "./hooks/useGetPayPalSettings";

const PickQuantity = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const paymentMethodRef = useRef<React.Ref<HTMLDivElement>>();
  // const {isPayPalAccount} = useGetPayPalSettings();

  const {
    getWarehouseLocations,
    cartPrint,
    cartPrintSuccess,
    warehouseLocationLoader,
    productsData,
    orderInstructionNote,
    shippingInstructionNote,
    quoteExpiryDate,
    temporaryStorageLocationNote,
    storeCreditAmount,
    priceCheckReducer,
    markAsQuoteReducer,
    jobNameForAll,
    isPurchaseOrderNoEdit
  } = useSelector<RootState, PosResponseProps>((state) => state.pos);

  const {
    shippingAddressSelected,
    subTotalAmountReducer,
    taxAmountReducer,
    shippingAmountReducer,
    shippingTaxAmountReducer,
    taxRateToBeSentReducer,
    shipTaxAmtToBeSentReducer,
  } =
    useSelector<RootState, PosFlowDataProp>(
      (reducerState) => reducerState.persistReducer
    );

  const { posFlowData, loadCartDetails, saveProductInfo, isPageReload, isTaxExempted } = useSelector<RootState, PosFlowDataProp>(
    (state) => state.persistReducer
  );

  const { cartProducts, customer, savedCart, poNumber, pickQuantityArr, shippingCharge, addDocument } = posFlowData || {};
  const toBeuploadedFiles = addDocument?.filter((item) => !item?.readOnly);


  const pickedQuantity = pickQuantityArr ?? "";

  useEffect(() => {
    dispatch(getIsGoToCart(true));
    if (posFlowData?.addInStorage) {
      const storageData = posFlowData?.addInStorage;
      if (Object.keys(storageData).length) {
        setItemInStorageData(storageData);
        setEstDeliveryForStorage(storageData?.estDeliveryDate);
        setLocationForStorage(storageData?.storageLocation);
        setReasonForStorage(storageData?.reason);
      }
      else {
        resetStorageData();
      }
    } else {
      resetStorageData();
    }
  }, [posFlowData]);

  useEffect(() => {
    localStorage.setItem("storageDetail", storageExpanded);
    showScrollCartPage();
    return () => {
      hideScrollCartPage();
    };
  }, []);

  const resetStorageData = () => {
    // Get the current date
    const currentDate = new Date();
    // Add two days to the current date
    currentDate.setDate(currentDate.getDate() + 2);
    // Format the date to the desired format
    const formattedDate = currentDate.toISOString();
    setEstDeliveryForStorage(formattedDate);
    setLocationForStorage(storageDesk);
    setReasonForStorage("");
    const storge: StorageDataProps = {
      storageLocation: storageDesk,
      estDeliveryDate: formattedDate,
      reason: "",
      items: []
    }
    setItemInStorageData(storge);
  }
  const {
    putInStorage, bodyTag, pickQtySubTextMessage, backOrderMessageForUnavailableItems
  } = texts;

  const {
    sameLocationQty,
    lessPickedThanOrder,
    setPickingInfo,
    location,
    storageDesk,
    estDeliveryDateCapital,
    continueToPayment,
  } = title

  const { inStorage,
    noItem,
    itemInStorage,
    removeAll,
    storageExpanded
  } = staticData

  const { stockWarehouseId } = getLocalStoraGeItem("workspace") || {};
  const [height, setHeight] = useState(0);
  const [returnProductsPickingDataBE, setReturnProductsPickingDataBE] =
    useState([]);
  const [pickReplaceQtyArrFinal, setPickReplaceQtyArrFinal] = useState([]);
  const [pickQtyStorageModal, setPickQtyStorageModal] = useState(false);
  const [pickQtyChangeStorageModal, setPickQtyChangeStorageModal] = useState(false);
  const [itemInStorageData, setItemInStorageData] = useState<StorageDataProps>(null);
  const [locationForStorage, setLocationForStorage] = useState<string>(storageDesk);
  const [estDeliveryForStorage, setEstDeliveryForStorage] = useState("");
  const [reasonForStorage, setReasonForStorage] = useState("");
  const [productForStorage, setProductForStorage] = useState<storageProductData>();
  const [balanceDetail, setBalanceDetail] = useState(false);
  const [shippingAddress, setShippingAddress] = useState(false);
  const [paymentMethodDrawer, setPaymentMethodDrawer] = useState(false);
  const [pickUpSelected, setPickUpSelected] = useState(false);
  const [creditAmtApplied, setCreditAmtApplied] = useState(customer?.storeCreditAmt);
  const [printLoader, setPrintLoader] = useState(false);
  const [printLoaderWithTotal, setPrintLoaderWithTotal] = useState(false);
  const [pickQtyStorageDataModal, setPickQtyStorageDataModal] = useState(false);
  const [selectedStorageItem, setSelectedStorageItem] = useState('');

  const [drawerState, setDrawerState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [, setShipToByFacilityId] = useState<number>(
    loadCartDetails?.shipToByFacility?.facilityId
  );
  const currentTaker = JSON.parse(localStorage.getItem("taker"));
  const [pickUpFlag, setPickUpFlag] = useState(true);
  const [showShipping, setShowShipping] = useState<boolean>(false);
  const [orderInstructions, setOrderInstructions] = useState("");
  const [shippingInstructions, setShippingInstructions] = useState("");
  const [temporaryStorageLocation, setTemporaryStorageLocation] = useState("");

  // Need to change below from orderDetails page
  const [priceInDecimal, setPriceInDecimal] = useState(false);

  const [showPrintOptions, setShowPrintOptions] = useState(false);
  const [printOptionsDrawer, setPrintOptionsDrawer] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const showPrices = savedCart?.customer?.showPrices;

  const [priceCheck, setPriceCheck] = useState(showPrices);
  const [markAsQuote, setMarkAsQuote] = useState(markAsQuoteReducer);
  const [expiryDate, setExpiryDate] = useState(quoteExpiryDate)
  const [backOrderConfirmationModal, setBackOrderConfirmationModal] = useState(false)
  const [totalBackOrderItems, setTotalBackOrderItems] = useState(0)
  const [backOrders, setBackOrders] = useState([])
  useEffect(() => {
    setExpiryDate(quoteExpiryDate)
  }, [quoteExpiryDate])

  const handlePriceCheck = () => {
    setPriceCheck(!priceCheck);
    dispatch(setPriceCheckReducer(!priceCheck))
  };

  const handleMarkAsQuoteCheck = () => {
    setMarkAsQuote(!markAsQuote);
    dispatch(setMarkAsQuoteReducer(!markAsQuote))
  };

  const drawerRef = useRef<Ref<HTMLDivElement>>();
  const observedDiv = useRef(null);
  useEffect(() => {
    setCreditAmtApplied(Number(storeCreditAmount || loadCartDetails?.customer?.storeCreditAmt));
  }, [storeCreditAmount, loadCartDetails]);

  useEffect(() => {
    if (orderInstructionNote) {
      setOrderInstructions(orderInstructionNote);
    }
  }, [orderInstructionNote]);

  useEffect(() => {
    if (shippingInstructionNote) {
      setShippingInstructions(shippingInstructionNote);
    }
  }, [shippingInstructionNote]);

  useEffect(() => {
    if (temporaryStorageLocationNote) {
      setTemporaryStorageLocation(temporaryStorageLocationNote);
    }
  }, [temporaryStorageLocationNote]);

  useEffect(() => {
    !(customer?.personId || customer?.customerId) &&
      navigate(Paths.posNewOrder);
    const data = {
      ...posFlowData,
      addProductPage: false,
    };

    dispatch(setPosFlowData(data));
  }, []);

  useEffect(() => {
    if (cartPrintSuccess) {
      if (printLoader) {
        setPrintLoader(false);
      }
      if (printLoaderWithTotal) {
        setPrintLoaderWithTotal(false);
      }
      dispatch(cartPrintClear());
      openAndPrintPdf(cartPrint?.data)
    }
  }, [cartPrintSuccess]);

  const { ticket, defaultIcon, storageOutlinedIcon, storageBlack } = images;

  function getUnique(array, key) {
    if (typeof key !== "function") {
      const property = key;
      key = function (item) {
        return item[property];
      };
    }
    return Array?.from(
      array &&
      array
        ?.reduce(function (map, item) {
          const keyInfo = key(item);
          if (!map.has(keyInfo)) map.set(keyInfo, item);
          return map;
        }, new Map())
        .values()
    );
  }

  const dataWithReplaceQty = posFlowData?.cartProducts?.filter(
    (item) => Number(item?.qtyOrdered) > 0
  );

  const pickingPassId =
    dataWithReplaceQty && getUnique(dataWithReplaceQty, "productId");

  useEffect(() => {
    if (getWarehouseLocations?.length) {
      setReturnProductsPickingDataBE(getWarehouseLocations);
    } else {
      setTotalBackOrderItems(dataWithReplaceQty?.length);
      setBackOrders(dataWithReplaceQty);
    }
  }, [getWarehouseLocations]);

  const calculateNewPickedValue = useCallback((obj) => {
    let newValue = 0;

    if (obj?.totalPickedValue) {
      newValue = Number(obj.totalPickedValue);
    } else if (obj?.oldData) {
      if (obj.oldData.length === 1 && obj.oldData[0].pickedQty) {
        newValue =
          obj.oldData[0].pickedQty > obj.oldData[0].availableQty
            ? obj.oldData[0].availableQty
            : obj.oldData[0].pickedQty;
      }
    } else if (obj?.pickedQtyWithLocation?.locations) {
      if (obj.pickedQtyWithLocation.locations[0].pickedQty) {
        newValue =
          obj.pickedQtyWithLocation.locations[0].pickedQty >
            obj.pickedQtyWithLocation.locations[0].availableQty
            ? obj.pickedQtyWithLocation.locations[0].availableQty
            : obj.pickedQtyWithLocation.locations[0].pickedQty;
      } else if (
        obj.qtyOrdered > obj.pickedQtyWithLocation.locations[0].availableQty
      ) {
        newValue = obj.pickedQtyWithLocation.locations[0].availableQty;
      } else {
        newValue = obj.qtyOrdered;
      }
    }
    // Return the lesser value between the new value and any initial value
    return Math.min(newValue, obj?.initialValue || Infinity);
  }, []);

  const handlePickQtyInfo = () => {
    // const initailPickReplaceShowData = dataWithReplaceQty?.filter((col) => {
    //   return returnProductsPickingDataBE?.find((selected) => {
    //     return (
    //       selected?.productName === col?.productName &&
    //       selected?.productId === col?.productId
    //     );
    //   });
    // });

    const finalPickReplaceShowData = dataWithReplaceQty?.map((obj) => {
      const hasPickQtyLocation = returnProductsPickingDataBE?.find(
        (nums) =>
          nums?.productId == obj?.productId &&
          nums?.productName == obj?.productName
      );

      if (hasPickQtyLocation) {
        const {
          locations,
          manufacturerCode,
          manufacturerName,
          productId,
          productName,
          stockWarehouseID,
          totalAvailableQuantity
        } = hasPickQtyLocation || {};

        const locationData = locations?.map((item, index) => {
          const {
            stockRowID,
            stockUnitID,
            stockShelfID,
            unitQtyAvailable,
            rowName,
            shelfName,
            unitName,
          } = item;

          return {
            stockWarehouseId: stockWarehouseID,
            productId,
            manufacturerId: obj?.manufacturerId,
            stockRowId: stockRowID,
            stockUnitId: stockUnitID,
            stockShelfId: stockShelfID,
            availableQty: unitQtyAvailable,
            pickedQty: obj?.oldData?.length
              ? obj?.oldData[index]?.pickedQty
              : undefined,
            unitName,
            rowName,
            shelfName,
            fullLocationName: `${rowName}-${unitName}-${shelfName}`,
          };
        });

        obj.pickedQtyWithLocation = {
          locations: locationData,
          manufacturerCode: manufacturerCode,
          manufacturerName: manufacturerName,
          productId: productId,
          productName: productName,
          stockWarehouseId: stockWarehouseID,
          totalAvailableQuantity: totalAvailableQuantity
        };

        if (locations?.length === 1) {
          const locationDataFinal = locations?.map((item) => {
            const {
              stockRowID,
              stockUnitID,
              stockShelfID,
              unitQtyAvailable,
              rowName,
              shelfName,
              unitName,
            } = item;

            calculateNewPickedValue(obj);
            return {
              stockWarehouseId: stockWarehouseID,
              productId,
              manufacturerId: obj?.manufacturerId,
              stockRowId: stockRowID,
              stockUnitId: stockUnitID,
              stockShelfId: stockShelfID,
              availableQty: unitQtyAvailable,
              pickedQty: calculateNewPickedValue(obj),
              rowName,
              shelfName,
              fullLocationName: `${rowName}-${unitName}-${shelfName}`,

            };
          });

          return {
            ...obj,
            qtyInStock: totalAvailableQuantity,
            pickedQtyWithLocation: {
              locations: locationDataFinal,
              manufacturerCode: manufacturerCode,
              manufacturerName: manufacturerName,
              productId: productId,
              productName: productName,
              stockWarehouseId: stockWarehouseID,
              totalAvailableQuantity: totalAvailableQuantity
            },
            totalPickedValue: calculateNewPickedValue(obj),
          };
        } else {
          return obj;
        }
      } else {
        obj.pickedQtyWithLocation = {}
        return obj;
      }
    });
    if (finalPickReplaceShowData) {
      setPickReplaceQtyArrFinal(finalPickReplaceShowData);
      setBackOrderCount(finalPickReplaceShowData);
    }
  }

  useEffect(() => {
    handlePickQtyInfo()
  }, [returnProductsPickingDataBE]);

  useEffect(() => {
    const returnProductIdArr = pickingPassId?.map(
      (item: PickedQtyWithLocationProps) => item.productId
    );
    const pickQuanityData = {
      stockWarehouseId,
      body: returnProductIdArr,
    };
    dispatch(getWarehourseLocationsRequest(pickQuanityData));
  }, []);

  const onChangePickQty = (index, id, value) => {
    const copyData = [...pickReplaceQtyArrFinal];
    let data;
    if (copyData[index]?.oldData && copyData[index]?.oldData.length) {
      data =
        copyData[index]?.oldData &&
        copyData[index]?.oldData.length &&
        copyData[index]?.oldData.map((item, index1) => {
          if (index1 === id) {
            return {
              ...item,
              pickedQty: value,
            };
          }
          return item;
        });
    } else {
      data = copyData[index]?.pickedQtyWithLocation?.locations.map(
        (item, index1) => {
          if (index1 === id) {
            return {
              ...item,
              pickedQty: value,
            };
          }
          return item;
        }
      );
    }

    const dataFinal = copyData?.map((copy, index2) => {
      if (index === index2) {
        const totalPickedValue =
          data &&
          data.length &&
          data.reduce((total, obj) => {
            return Number(obj.pickedQty ?? 0) + Number(total ?? 0);
          }, 0);
        if (itemInStorageData && itemInStorageData?.items) {
          const currentPicQtyStorageData = itemInStorageData?.items.filter(obj => obj.lineId === copy.lineIdData);
          const qtyPutInStorage = currentPicQtyStorageData?.length ? currentPicQtyStorageData[0].qtyPutInStorage : 0;
          if (qtyPutInStorage > totalPickedValue) {
            removeStorage(copy.lineIdData);
          }
        }
        return {
          ...copy,
          // totalPickedValue: data && data.length ? value : Number(totalPickedValue).toFixed(2),
          totalPickedValue: Number(totalPickedValue).toFixed(2),
          pickedQtyWithLocation: {
            ...copy?.pickedQtyWithLocation,
            locations: data,
          },
          oldData: data,
        };
      }
      return copy;
    });
    setPickReplaceQtyArrFinal(dataFinal);
    setBackOrderCount(dataFinal);
  };

  const setBackOrderCount = (data) => {
    //  draft api is not giving latest data of product that's why we added this logic from picking api
    const outOfStockProductDrafts = data?.filter((item) => {
      if (!item?.qtyInStock) {
        return item;
      }
    })

    outOfStockProductDrafts.forEach(product => {
      product.qtyInStock = 0;
    });
    // will add if not fully picked up will be added to the back-order list
    const hasBackOrder = data?.filter((item) => {
      const qtyIn = item?.qtyOrdered - Number(item?.totalPickedValue);
      const backOrderCondition = qtyIn > 0 &&
        (qtyIn < item?.qtyInStock || (item?.qtyInStock !== 0 && qtyIn > item?.qtyInStock));

      if (backOrderCondition) {
        return item;
      }
    });
    setTotalBackOrderItems(hasBackOrder?.length + outOfStockProductDrafts?.length)
    const mergeArray = [...hasBackOrder, ...outOfStockProductDrafts]
    setBackOrders(mergeArray)
  }

  const finalPickedQtyOnchange = (value, index, id, location) => {
    if (!numbersOnlyRegEx.test(value)) {
      value = "";
      onChangePickQty(index, id, "");
    } else if (Number(value) > location.availableQty) {
      onChangePickQty(index, id, location.availableQty);
    } else {
      onChangePickQty(index, id, Number(value));
    }
  };

  const calculatePickedValue = useCallback((location, item) => {
    const { pickedQty, availableQty } = location || {};
    const { oldData, qtyOrdered, qtyInStock } = item || {};

    if (pickedQty && Number(pickedQty) > Number(availableQty)) {
      return Number(availableQty);
    }

    if (oldData && oldData.length === 1) {
      return Number(oldData[0].pickedQty);
    }

    if (qtyOrdered && qtyOrdered > qtyInStock) {
      return Number(qtyInStock);
    }

    return Number(qtyOrdered);
  }, []);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (addDocument) {
      const objectFiles = addDocument?.filter((item: UploadedDocumentItemProp) => item?.originalFile) || [];
      let isIncorrectFile = false;
      objectFiles.forEach((item: UploadedDocumentItemProp) => {
        if (addDocument?.length && !item?.originalFile?.type) {
          isIncorrectFile = true;
        }
      })
      if (isPageReload || isIncorrectFile) {
        // setOpenModal(true);
      }
    }
  }, [isPageReload])

  const closeAlertModal = () => {
    const uploadedFiles = addDocument?.filter((item: UploadedDocumentItemProp) => item?.originalFile === undefined) || [];
    const data = { ...posFlowData, addDocument: uploadedFiles }
    dispatch(setPosFlowData(data))
    setOpenModal(false);
  }


  useEffect(() => {
    pickReplaceQtyArrFinal?.forEach((item, index) => {
      if (item?.pickedQtyWithLocation?.locations?.length === 1) {
        item?.pickedQtyWithLocation?.locations?.forEach(
          (location, locationIndex) => {

            finalPickedQtyOnchange(
              calculatePickedValue(location, item),
              index,
              locationIndex,
              location
            );
          }
        );

      }
    });

  }, [pickReplaceQtyArrFinal?.length]);

  const saveAndUpdateCart = () => {
    const newFinalArray = pickReplaceQtyArrFinal?.map((item, index) => {
      const hasPickQtyLocationFinal = pickReplaceQtyArrFinal.find(
        (nums, indexInfo) =>
          nums?.productId === item?.productId &&
          nums?.productName === item?.productName &&
          index === item?.uniqueProductId &&
          indexInfo === item?.uniqueProductId &&
          indexInfo === index
      );
      const { oldData, pickedQtyWithLocation } = hasPickQtyLocationFinal || {};
      return {
        ...item,
        qtyInStock: (item?.pickedQtyWithLocation && item?.pickedQtyWithLocation?.totalAvailableQuantity) ? item?.pickedQtyWithLocation?.totalAvailableQuantity : item?.qtyInStock,     //  draft api is not giving latest data of product that's why we added this logic from picking api
        pickedQtyWithLocation: {
          ...item?.pickedQtyWithLocation,
          locations: pickedQtyWithLocation?.locations?.length ? pickedQtyWithLocation?.locations : [] // might need this =>  oldData ? [...oldData] : [],
        },
        oldData: oldData ? [...oldData] : [],
      };
    });

    // const outOfStockProducts = posFlowData?.cartProducts?.filter(
    //   (item) => item?.qtyInStock === 0
    // );

    //  draft api is not giving latest data of product that's why we added this logic from picking api
    // const outOfStockProductDrafts = data?.filter((item) => {
    //   if (!item?.qtyInStock) {
    //     return item;
    //   }
    // })

    // const mergedArray = [...newFinalArray, ...outOfStockProductDrafts];
    const data = {
      ...posFlowData,
      proceedToPayment: true,
      cartProducts: newFinalArray,
      addInStorage: itemInStorageData
    };
    const dataWithNoQty: any = {
      ...posFlowData,
      proceedToPayment: true,
    };

    const filteredCartProducts = data?.cartProducts?.filter((item) => {
      return item?.qtyInStock > 0;
    });

    const sameproductData = _.groupBy(filteredCartProducts, "productId");

    const finalDetails = [];
    _.forEach(sameproductData, (value) => {
      finalDetails.push(
        value
          ?.map((item) => {
            return item?.pickedQtyWithLocation?.locations
              ?.map((location) => {
                return location;
              })
              .filter((i) => i.pickedQty);
          })
          .flat()
      );
    });

    const pickedSameQty = finalDetails?.map((info) => {
      const finalPickedQtyOutput = [];
      const uniqueName = Array.from(
        new Set(info?.map(({ stockRowId, stockUnitId, stockShelfId }) => `${stockRowId || 0}-${stockUnitId || 0}-${stockShelfId || 0}`))
      );

      uniqueName.forEach((n) => {
        finalPickedQtyOutput.push(
          info
            .filter(({ stockRowId, stockUnitId, stockShelfId }) => {
              return `${stockRowId || 0}-${stockUnitId || 0}-${stockShelfId || 0}` === n
            })
            .reduce((a, item) => {
              const val =
                a["fullLocationName"] === undefined
                  ? item?.pickedQty
                  : a["pickedQty"] + item?.pickedQty;

              return { ...item, fullLocationName: n, pickedQty: val };
            }, {})
        );
      });

      return finalPickedQtyOutput;
    });

    const isSameQtyCheck = pickedSameQty?.map((data) => {
      return data?.map((info) => {
        if (info?.pickedQty > info?.availableQty) {
          return true;
        }
        return false;
      });
    });

    const checkSameQtyPicked = isSameQtyCheck?.map((uniqueId) => {
      return uniqueId?.some((item) => {
        return item === true;
      });
    });

    const finalIsPickedSame = checkSameQtyPicked.some((item) => {
      return item === true;
    });

    const moreDataPicked = data?.cartProducts?.some(
      (item) => item?.totalPickedValue > item?.qtyOrdered
    );

    if (finalIsPickedSame) {
      dispatch(snakbarShow({ message: sameLocationQty }))
    } else {
      // check for remove if needed
      dispatch(setNoLoadDetailsAction(true));
      dispatch(setToPickUp(true));
      dispatch(setShipToAddress(true));
      dispatch(examptShipTolocationData(false));
      // Might be needed
      // dispatch(shippingAddressSelectedData(false));
      // end
      setTimeout(() => {
        dispatch(
          setPosFlowData(
            pickReplaceQtyArrFinal?.length === 0 ? dataWithNoQty : data
          )
        );
        dispatch(showAlreadyPickedInfoData(true));
      }, 1000);
    }
    return {
      data: pickReplaceQtyArrFinal?.length === 0 ? dataWithNoQty : data,
      finalIsPickedSame,
      moreDataPicked,
    }
  };

  const printInvoice = (optionNumber: number) => {
    const cartWithTotalProducts = [];

    const {
      sessionStartedAt,
      cartId,
      customer: savedCartCustomer,
      customerNote,
    } = savedCart;

    const preparedData = {
      sessionStartedAt: sessionStartedAt ? sessionStartedAt : "",
      cartId: cartId ? cartId : "",
      stockWarehouseId,
      customerId: savedCartCustomer?.customerId
        ? savedCartCustomer?.customerId
        : "",
      orderNote: orderInstructions,
      shippingNote: shippingInstructions,
      purchaseOrderNum: isPurchaseOrderNoEdit ? poNumber : loadCartDetails?.purchaseOrderNum,
      customerNote: customerNote ? customerNote : "",
      products: cartWithTotalProducts,
      storageNote: temporaryStorageLocation || '',
      takerName: currentTaker?.fullName,
      salesPersonName: posFlowData?.salesman?.salespersonName ? posFlowData?.salesman?.salespersonName : currentTaker?.fullName,
      shipToAddressId: shippingAddressSelected
        ? posFlowData?.shiptoFacilities?.facilityId
        : loadCartDetails?.shipToByFacility?.facilityId,
    };

    cartProducts?.length > 0 &&
      cartProducts?.forEach(
        (
          {
            productId,
            manufacturerCode,
            manufacturerId,
            vendorId,
            productName,
            vendorPrice,
            salesPrice,
            posRetailPrice,
            priceMU,
            muName,
            itemMU,
            itemMuName,
            unitPerCase,
            qtyInStock,
            qtyOrdered,
            orderNote,
            shippingNote,
            clearanceFlag,
            discontinueFlag,
            manufacturerName,
            overridePrice,
            updatedAvailableQty,
            totalPickedValue,
            itemType,
            markupMultiplier
          }
        ) => {
          cartWithTotalProducts.push({
            productId,
            manufacturerCode,
            manufacturerId,
            manufacturerName,
            vendorId,
            vendorName: manufacturerName,
            itemType: itemType || null,
            productName,
            priceMU,
            muName,
            itemMU,
            itemMuName,
            unitPerCase,
            qtyOrdered,
            vendorPrice: vendorPrice?.price ?? vendorPrice,
            salesPrice: salesPrice?.price ?? salesPrice,
            posRetailPrice: posRetailPrice ? Number(posRetailPrice) : 0,
            pickedQty: totalPickedValue ? Number(totalPickedValue) : 0,
            clearanceFlag,
            qtyInStock,
            updatedAvailableQty,
            discontinueFlag,
            markupMultiplier,
            overridePrice,
            orderNote,
            shippingNote,
          });
        }
      );


    isTaxExempted
      ? savedCart?.shipToByFacility?.tax?.shipTaxFlag === 1
        ? Number(subTotalAmountReducer) + Number(shippingAmountReducer)
        : Number(subTotalAmountReducer) +
        Number(shippingAmountReducer) +
        Number(shippingAmountReducer)
      : savedCart?.shipToByFacility?.tax?.shipTaxFlag === 1
        ? Number(subTotalAmountReducer) + Number(taxAmountReducer) + Number(shippingTaxAmountReducer)
        : Number(subTotalAmountReducer) +
        Number(taxAmountReducer) +
        Number(shippingAmountReducer) +
        Number(shippingTaxAmountReducer);

    const withTotalsData = cartWithTotalPayload()

    if (optionNumber === 1) {
      dispatch(cartPrintRequest(preparedData));
    }
    if (optionNumber === 2) {
      dispatch(cartPrintWithTotalsRequest(withTotalsData));
    }
  };
  const cartWithTotalPayload = () => {
    let taxScheduleToBeSent;

    if (savedCart?.customer?.taxExemptFlag && isTaxExempted) {
      taxScheduleToBeSent = "NONTAX";
      //return null;
    } else {
      if (
        savedCart?.shipToByFacility?.recipient?.taxExemptFlag &&
        isTaxExempted
      ) {
        taxScheduleToBeSent = "NONTAX";
      } else {
        if (isTaxExempted) {
          taxScheduleToBeSent = "NONTAX";
          // return
        } else {
          if (savedCart?.shipToByFacility?.tax?.shipTaxFlag === 0) {
            taxScheduleToBeSent = "NONTAX";
            // return
          } else {
            taxScheduleToBeSent = pickUpSelected
              ? loadCartDetails?.shipToByFacility?.tax?.taxSchedule
              : posFlowData?.shiptoFacilities?.tax?.taxSchedule;
            // return
          }
        }
      }
    }
    const grandTotalAmount =
      savedCart?.shipToByFacility?.tax?.shipTaxFlag === 1
        ? Number(subTotalAmountReducer) +
        Number(taxAmountReducer) +
        Number(shippingAmountReducer) +
        Number(shippingTaxAmountReducer)
        : Number(subTotalAmountReducer) + Number(taxAmountReducer) + Number(shippingAmountReducer);
    const cartProductsWithTotal = [];

    cartProducts?.length > 0 &&
      cartProducts?.forEach(
        (
          {
            productId,
            manufacturerCode,
            manufacturerId,
            vendorId,
            productName,
            vendorPrice,
            salesPrice,
            posRetailPrice,
            priceMU,
            muName,
            itemMU,
            itemMuName,
            unitPerCase,
            qtyInStock,
            qtyOrdered,
            orderNote,
            clearanceFlag,
            discontinueFlag,
            manufacturerName,
            overridePrice,
            updatedAvailableQty,
            totalPickedValue,
            itemType,
            markupMultiplier
          }
        ) => {
          cartProductsWithTotal.push({
            productId,
            manufacturerCode,
            manufacturerId,
            manufacturerName,
            vendorId,
            vendorName: manufacturerName,
            itemType: itemType || null,
            productName,
            priceMU,
            muName,
            itemMU,
            itemMuName,
            unitPerCase,
            qtyOrdered,
            vendorPrice: vendorPrice?.price ?? vendorPrice,
            salesPrice: salesPrice?.price ?? salesPrice,
            posRetailPrice: posRetailPrice ? Number(posRetailPrice) : 0,
            pickedQty: totalPickedValue ? Number(totalPickedValue) : 0,
            clearanceFlag,
            qtyInStock,
            updatedAvailableQty,
            discontinueFlag,
            markupMultiplier,
            overridePrice,
            orderNote,
          });
        }
      );

    const {
      sessionStartedAt,
      cartId,
      customer: savedCartCustomer,
      jobName,
    } = savedCart;

    const withTotalsData = {
      sessionStartedAt: sessionStartedAt,
      cartId: cartId,
      stockWarehouseId,
      customerId: savedCartCustomer?.customerId,
      purchaseOrderNum: isPurchaseOrderNoEdit ? poNumber : loadCartDetails?.purchaseOrderNum,
      customerNote: savedCartCustomer?.customerNote,
      orderNote: orderInstructions,
      shippingNote: shippingInstructions,
      showPrices: savedCartCustomer?.showPrices ? priceCheck : false,
      buyOnCreditHold: savedCartCustomer?.creditHoldFlag === 1,
      jobName: jobNameForAll ? jobNameForAll : jobName,
      takerId: currentTaker?.personId,
      taxRate: taxRateToBeSentReducer,
      taxSchedule: taxScheduleToBeSent,
      shipTaxFlag: loadCartDetails?.shipToByFacility?.tax?.shipTaxFlag,
      shipToAddressId: pickUpSelected
        ? loadCartDetails?.shipToByFacility?.facilityId
        : posFlowData?.shiptoFacilities?.facilityId,
      subTotalAmt: Number(subTotalAmountReducer),
      taxAmt:
        savedCartCustomer?.taxExemptFlag && isTaxExempted
          ? 0
          : loadCartDetails?.shipToByFacility?.recipient?.taxExemptFlag && isTaxExempted
            ? 0
            : isTaxExempted
              ? 0
              : Number(taxAmountReducer) || 0,
      taxExemptFlag: savedCartCustomer?.taxExemptFlag
        ? 1
        : savedCart?.shipToByFacility?.recipient?.taxExemptFlag
          ? 1
          : isTaxExempted
            ? 1
            : 0,
      shippingFeeAmt: Number(shippingAmountReducer),
      shippingTaxAmt: shipTaxAmtToBeSentReducer ? shipTaxAmtToBeSentReducer : 0.0,
      grandTotalAmt: Number(grandTotalAmount?.toFixed(4)) || 0,
      products: cartProductsWithTotal,
      takerName: currentTaker?.fullName,
      salesPersonName: posFlowData?.salesman?.salespersonName ? posFlowData?.salesman?.salespersonName : currentTaker?.fullName,
      setAsQuote: markAsQuote,
      setAsQuoteExpiryDate: expiryDate ? expiryDate : dayjs().add(1, 'day'),
    };
    return withTotalsData;
  }
  const emailQuote = (value) => {
    const { cartId } = savedCart;
    const withTotalsData = cartWithTotalPayload()
    const body = {
      customerEmail: value?.toEmail,
      subject: value.subject,
      message: value.message,
      takerEmail: currentTaker?.email,
      cartId: cartId,
      posCartWithTotalsPrint: withTotalsData
    }
    dispatch(cartEmailWithTotalsRequest(body))
  }

  const productsInCart = cartProducts
    ? cartProducts
    : saveProductInfo;

  const handlePaymentClick = () => {
    dispatch(resetBillingDetails())
    const finaldata = saveAndUpdateCart()

    if (finaldata?.finalIsPickedSame || finaldata?.moreDataPicked) return

    const result = productsInCart.filter((obj) => {
      return obj.qtyInStock === 0;
    });

    // start
    if (finaldata?.data?.cartProducts?.length) {
      let flag = false;
      const lessAddedQty = finaldata?.data?.cartProducts?.some(
        ({ totalPickedValue, qtyOrdered }) => {
          const numberQuantityValue = isNaN(Number(totalPickedValue))
            ? 0
            : Number(totalPickedValue);
          return (
            numberQuantityValue < qtyOrdered &&
            (!shippingAddressSelected || false)
          );
        }
      );

      const noAddedQty = finaldata?.data?.cartProducts?.every(({ totalPickedValue }) => {
        const numberQuantityValue = isNaN(Number(totalPickedValue))
          ? 0
          : Number(totalPickedValue);
        return !numberQuantityValue;
      });

      return finaldata?.data?.cartProducts?.map(() => {
        if (flag) {
          return;
        }

        const showAddressDrawer = (lessAddedQty || (customer?.isPosGeneric && result.length > 0) || noAddedQty) && !(shippingAddressSelected)
        setDrawerState({ ...drawerState, right: true });
        if ((loadCartDetails?.products.length && loadCartDetails?.shipToByFacility?.isStorePickup)) {
          setShippingAddress(true)
          setPickUpFlag(false);
          setShowShipping(false);
        } else {
          if (showAddressDrawer && pickUpFlag) {
            // setPickUpFlag(false); // This was used to show shipping drawer only once
            setShippingAddress(true)
            flag = true;
          } else {
            setShowShipping(false);
            if (customer?.storeCreditAmt) {
              setBalanceDetail(true);
            } else {
              setPaymentMethodDrawer(true);
            }
          }
        }
      });
    }
    else if (
      result.length > 0 &&
      customer?.isPosGeneric &&
      !shippingAddressSelected
    ) {
      setShippingAddress(true);
      setDrawerState({ ...drawerState, right: true });
    } else if (
      pickedQuantity?.length === 0 &&
      !shippingAddressSelected &&
      result.length === 0
    ) {
      setShippingAddress(true);
      setDrawerState({ ...drawerState, right: true });
    } else {
      setShowShipping(false);
      setDrawerState({ ...drawerState, right: true });
    }
    // end
  };

  const handleKeyPress = (e) => {
    const { keyCode } = e;
    const { p, d, esc } = keys;
    if (!pickQtyStorageModal && !pickQtyStorageDataModal && !pickQtyChangeStorageModal) {
      if ((document.activeElement.tagName === bodyTag)) {
        if (keyCode === p && !paymentMethodDrawer && !shippingAddress) { // && getWarehouseLocations?.length
          setShowPrintOptions(true);
          setPrintOptionsDrawer({ ...printOptionsDrawer, right: true });
        }
        else if (keyCode === d && !warehouseLocationLoader && !paymentMethodDrawer && !shippingAddress) { // && getWarehouseLocations?.length
          handleContinueToPayment();
        }
      }
    }
    if (keyCode === esc) {
      navigate(-1);
    }
  };

  useEffect(() => {
    document?.addEventListener("keydown", handleKeyPress);
    return function cleanup() {
      document?.removeEventListener("keydown", handleKeyPress);
    };
  }, [
    pickReplaceQtyArrFinal,
    pickQtyStorageModal,
    pickQtyStorageDataModal,
    pickQtyChangeStorageModal,
    paymentMethodDrawer,
    shippingAddress,
    warehouseLocationLoader
  ]);

  const handleElementResized = () => {
    if (observedDiv?.current?.offsetHeight !== null) {
      if (
        observedDiv &&
        observedDiv?.current &&
        observedDiv?.current?.offsetHeight !== height
      ) {
        setHeight(observedDiv?.current?.offsetHeight);
      }
    }
  };

  const resizeObserver = new ResizeObserver(handleElementResized);

  useEffect(() => {
    resizeObserver?.observe(observedDiv?.current);
    return function cleanup() {
      resizeObserver?.disconnect();
    };
  });
  //might need later
  // const staticPickingReplaceHeader = document.querySelector(
  //   ".static-picking-replace-header"
  // );

  const useHeightStyles = makeStyles({
    fixedPickedHeader: {
      top: "10px",
    },
    mainContentTopSpacing: {
      marginTop: "20px",
      //might need later
      // "@media (min-width: 960px)": {
      // marginTop: `${staticPickingReplaceHeader?.clientHeight}px`,
      // },
    },
  });

  const pickStickyInfoClasses = useHeightStyles();

  const handleKeyPressDown = (e) => {
    const { keyCode } = e;
    const { esc } = keys;
    const { cartId } = loadCartDetails;
    if (keyCode === esc && cartId) {
      backToCartPage()
    }
  }

  const backToCartPage = () => {
    const { cartId } = loadCartDetails
    const { productId } = productsData
    showScrollCartPage();
    if (productId) {
      dispatch(addToCartDrawer(true))
    }
    navigate(`${Paths.posCart}/${cartId}`);
  }

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPressDown);

    return function cleanup() {
      document.removeEventListener("keydown", handleKeyPressDown);
    };
  }, [loadCartDetails, productsData]);

  const storageDetail = (data) => {
    setEstDeliveryForStorage(data.estDeliveryDate);
    setLocationForStorage(data.storageLocation);
    setReasonForStorage(data.reason);
    setItemInStorageData(data);
  }

  const putInStorageClick = (product) => {
    setProductData(product)
    setPickQtyStorageModal(true);
  }

  const changeInStorageClick = (product) => {
    setPickQtyChangeStorageModal(true);
    setProductData(product)
  }

  const setProductData = (product) => {
    const data = {
      productId: Number(product.productId),
      storageLocation: locationForStorage,
      estDeliveryDate: estDeliveryForStorage,
      reason: reasonForStorage,
      pickQty: (Number(product?.totalPickedValue) && Number(product?.totalPickedValue) < Number(product?.qtyOrdered)) ? Number(product?.totalPickedValue) : !Number(product?.totalPickedValue) ? 0 : Number(product?.qtyOrdered),
      lineId: product?.lineIdData,
      manufacturerCode: product?.manufacturerCode,
      itemType: product?.itemType || null,
      qtyPutInStorage: 0,
      productName: product?.productName,
    }
    setProductForStorage(data);
  }
  const storageBox = (selectedItem: string) => {
    if (itemInStorageData?.items?.length) {
      const data = {
        storageLocation: locationForStorage,
        estDeliveryDate: estDeliveryForStorage,
        reason: reasonForStorage,
      }
      setProductForStorage(data);
      setPickQtyStorageDataModal(true)
      setSelectedStorageItem(selectedItem)
    }
  }

  const removeStorage = (productId) => {
    const itemIndex = itemInStorageData.items.findIndex(item => item.lineId === productId);
    // Create a copy of the original "items" array
    const updatedItems = [...itemInStorageData.items];

    if (itemIndex !== -1) {
      // If the item with the productId exists, remove it from the "items" array
      updatedItems.splice(itemIndex, 1);
    }
    if (itemInStorageData.items.length === 1) {
      resetStorageData()
    } else {
      // Update the other properties
      const updatedData = {
        ...itemInStorageData,
        items: updatedItems
      };

      // Update the state or use the updatedData as required
      setItemInStorageData(updatedData);
    }
  }

  const removeAllData = () => {
    resetStorageData();
  }

  const handleContinueToPayment = () => {
    const newFinalArray = pickReplaceQtyArrFinal?.map((item, index) => {
      const hasPickQtyLocationFinal = pickReplaceQtyArrFinal.find(
        (nums, indexInfo) =>
          nums?.productId === item?.productId &&
          nums?.productName === item?.productName &&
          index === item?.uniqueProductId &&
          indexInfo === item?.uniqueProductId &&
          indexInfo === index
      );
      const { oldData, pickedQtyWithLocation } = hasPickQtyLocationFinal || {};
      return {
        ...item,
        qtyInStock: (item?.pickedQtyWithLocation && item?.pickedQtyWithLocation?.totalAvailableQuantity) ? item?.pickedQtyWithLocation?.totalAvailableQuantity : item?.qtyInStock,     //  draft api is not giving latest data of product that's why we added this logic from picking api
        pickedQtyWithLocation: {
          ...item?.pickedQtyWithLocation,
          locations: pickedQtyWithLocation?.locations?.length ? pickedQtyWithLocation?.locations : [] // might need this =>  oldData ? [...oldData] : [],
        },
        oldData: oldData ? [...oldData] : [],
      };
    });

    const moreDataPicked = newFinalArray?.some(
      (item) => item?.totalPickedValue > item?.qtyOrdered
    );
    if (moreDataPicked) {
      dispatch(snakbarShow({ message: lessPickedThanOrder }))
    } else if (totalBackOrderItems) {
      setBackOrderConfirmationModal(true);
    } else {
      handlePaymentClick();
    }
  }
  const zeroBackOrderItems = !totalBackOrderItems ? "Items" : "items";
  const countOfBackOrderItems = totalBackOrderItems ? totalBackOrderItems : ''
  const textOfBackOrderItems = totalBackOrderItems === 1 ? 'item' : zeroBackOrderItems
  return (
    <div className="scrollbar-visible">
      <PosHelmet
        title="POS :: Pick Quantity"
        metaName="POS Pick Quantity page"
        content="POS Pick Quantity page"
      />
      {/* start */}
      <div className="new-container pick-replace-quantity-container">
        <section className="pick-replace-quantity-main-section pick-quantity-main-section">
          <div ref={observedDiv} className="static-picking-replace-header">
            <Container className="new-container pick-qty-container pick-qty-header-container">
              <div className="pick-replace-quantity-heading">
                <PosIconButton
                  className="btn back-next-btn-small-left return-picking-qty-back-btn pos-icon-back-btn"
                  onClick={() => {
                    backToCartPage();
                  }}
                  tabIndex={-1}
                >
                  <ArrowBackRoundedIcon />
                </PosIconButton>
                <div className="pick-quantity-heading-with-text mb-5">
                  <PosPageHeading heading={setPickingInfo} blackHeading />
                  <div className="pick-heading-sub-text">
                    <span className="mt-3">
                      {pickQtySubTextMessage}
                    </span>
                    {warehouseLocationLoader ?
                      <Skeleton count={1} />
                      : <span className="mt-2 back-order-counter">
                        <InfoOutlinedIcon />
                        {`${countOfBackOrderItems} ${textOfBackOrderItems} ${backOrderMessageForUnavailableItems}`}
                      </span>}
                  </div>
                </div>
                <div className={`in-storage-data-sec mb-5 ${itemInStorageData?.items?.length ? "" : "set-storage-data"}`}  >
                  <div className="qty-in-storage-sec">
                    <div className={`${itemInStorageData?.items?.length ? "cursor-pointer" : ""} d-flex align-items-center small-text-without-margin mb-3`}>
                      <PosImage src={storageBlack} alt="add product" className="mr-2" />
                      <span className="text-decoration-none text-black font-weight-600">
                        {itemInStorageData?.items?.length || noItem} {itemInStorage}
                      </span>
                      {itemInStorageData?.items?.length ? <Button className="storage-change-spacing bg-transparent border-0 p-0 button-focus-class"
                        onClick={removeAllData}
                      >
                        <span className="text-black text-underline">
                          {removeAll}
                        </span>
                      </Button> : null}
                    </div>
                  </div>
                  <div className="d-flex pick-qty-storage-item-info flex-column">
                    <Button className={`bg-transparent border-0 p-0 qty-str-focus-class ${itemInStorageData?.items?.length ? "cursor-pointer" : "pe-none"} d-flex flex-column qty-storage-item mb-3`}
                      onClick={() => storageBox(storageItem.location)}
                      disabled={!itemInStorageData?.items?.length}
                    >
                      <div className="qty-storage-item-title">
                        <span>
                          {location}
                        </span>
                      </div>
                      <div className={`${itemInStorageData?.items?.length ? "qty-storage-item-data-hover" : "qty-storage-item-data"}`} >
                        <span className="storage-span">
                          {locationForStorage}
                        </span>
                      </div>
                    </Button>
                    <Button className={`bg-transparent border-0 p-0 qty-str-focus-class ${itemInStorageData?.items?.length ? "cursor-pointer" : "pe-none"} d-flex flex-column qty-storage-item`}
                      onClick={() => storageBox(storageItem.estDeliveryDate)}
                      disabled={!itemInStorageData?.items?.length}
                    >
                      <div className="qty-storage-item-title">
                        <span>
                          {estDeliveryDateCapital}
                        </span>
                      </div>
                      <div className={`${itemInStorageData?.items?.length ? "qty-storage-item-data-hover" : "qty-storage-item-data"}`}>
                        <span className="storage-span">
                          {moment(estDeliveryForStorage).format("MM-DD-YYYY")}
                        </span>
                      </div>
                    </Button>
                  </div>
                </div>
              </div>
              <div className="pick-table-title-section">
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container className="">
                    <Grid xs={12} sm={4} md={1} lg={1}>
                      <div className="pick-replace-table-header">
                        <span className="title">LINE #</span>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      md={4}
                      lg={3}
                      className="pick-replace-item-name"
                    >
                      <div className="pick-replace-table-header">
                        <span className="title">ITEM</span>
                      </div>
                    </Grid>
                    <Grid item xs={6} sm={3} md={1} lg={1}>
                      <div className="pick-replace-table-header text-center">
                        <span className="title">ORDER QTY.</span>
                      </div>
                    </Grid>
                    <Grid item xs={6} sm={3} md={1} lg={1}>
                      <div className="pick-replace-table-header text-center">
                        <span className="title">BACK ORDER</span>
                      </div>
                    </Grid>
                    <Grid item xs={6} sm={3} md={1} lg={1}>
                      <div className="pick-replace-table-header text-center">
                        <span className="title">TOTAL PICKED</span>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={5} lg={5}>
                      <Grid container className="">
                        <Grid item xs={4}>
                          <div className="pick-replace-table-header text-center">
                            <span className="title">LOCATION</span>
                          </div>
                        </Grid>
                        <Grid item className="available-qty-title" xs={4}>
                          <div className="pick-replace-table-header text-center">
                            <span className="title">AVAILABLE QTY.</span>
                          </div>
                        </Grid>
                        <Grid item className="picked-qty-title" xs={4} >
                          <div className="pick-replace-table-header text-center">
                            <span className="title">PICKED QTY.</span>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </div>
            </Container>
          </div>
          {/* {returnProductsPickingDataBE?.length && */}
          <div
            id="pick-replace-qty-final"
            className={`left-padding ${pickStickyInfoClasses.mainContentTopSpacing}`}
          >
            <Container className="new-container pick-qty-container">
              <div className="picking-info-table">
                {pickReplaceQtyArrFinal?.length ? pickReplaceQtyArrFinal?.map((product, index) => {
                  const isAnyPicked = product?.pickedQtyWithLocation?.locations && product?.pickedQtyWithLocation?.locations.find(item => item?.pickedQty > 0)
                  const productID = product?.lineIdData;
                  const idExists = itemInStorageData?.items?.filter(obj => obj.lineId === productID);
                  const qtyPutInStorage = idExists?.length ? idExists[0].qtyPutInStorage : 0;
                  const { typeDiscontinued } = ProductTypes;
                  const discontinuedData =
                    product?.discontinued?.type === typeDiscontinued ||
                    product?.discontinueFlag === 1;
                  return (
                    <Box
                      sx={{ flexGrow: 1 }}
                      key={index}
                      className="pick-replace-content"
                    >
                      <Grid container className="">
                        <Grid
                          item
                          xs={12}
                          sm={3}
                          md={1}
                          lg={1}
                          className={`sticky-header-style ${pickStickyInfoClasses.fixedPickedHeader} pick-content-spacing pick-product-line-id flex-direction-column`}
                        >
                          <div className="pick-responsive-title pick-id-responsive-title line-id">
                            <span>LINE #</span>
                          </div>
                          <div className="pick-replace-table-header text-center pick-id-responsive-title line-id">
                            <span className="title">
                              {product.lineIdData}
                            </span>
                          </div>
                          <div className="cart-product-info cart-indicator d-flex align-items-center">
                            <PosTooltip
                              title={
                                discontinuedData
                                  ? "Discontinued"
                                  : product?.qtyInStock > 0
                                    ? "Available in stock"
                                    : product?.qtyInStock === 0 && "Out of stock"
                              }
                              placement="top"
                            >
                              <div className="indicator">
                                <span
                                  className={`indicator-style ${discontinuedData
                                    ? classes.colorYellow
                                    : product?.qtyInStock
                                      ? classes.colorGreen
                                      : classes.colorRed
                                    }`}
                                />
                              </div>
                            </PosTooltip>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={5}
                          md={4}
                          lg={3}
                          className={`pick-replace-item-name left-padding sticky-header-style 
                        ${pickStickyInfoClasses.fixedPickedHeader} 
                        pick-content-spacing d-flex`}
                        >
                          <div className="pick-replace-table-header pick-replace-product-img">
                            <div className={`text-center user-avatar`}>
                              <PosImage
                                src={defaultIcon}
                                className="search-list-img"
                                alt="product"
                              />
                            </div>
                            <div className="image-bottom-vertical-line">
                              <span></span>
                            </div>
                          </div>
                          <div className="pick-replace-table-header">
                            <div className="column-info">
                              <div className="btn-text-design">
                                <span className="picking-product-name">
                                  {product.productName}
                                </span>
                                <span className="picking-manufacturer-name">
                                  {product.manufacturerCode}
                                </span>
                                <span>

                                  {idExists?.length ? <div className="put-storage-section pick-qty-put-storage-section ml-5">
                                    <div className="cursor-pointer d-flex align-items-center">
                                      <PosImage src={storageOutlinedIcon} alt="add product" className="mr-2" />
                                      <span className="put-storage-title text-decoration-none">
                                        {`${qtyPutInStorage} ${inStorage}`}
                                      </span>
                                      <Button className="storage-change-spacing bg-transparent border-0 p-0 qty-str-focus-class"
                                        onClick={() => changeInStorageClick(product)}
                                      >
                                        <span className="put-storage-title storage-span">
                                          {buttonTitle.change}
                                        </span>
                                      </Button>
                                      <Button className="storage-change-spacing bg-transparent border-0 p-0 qty-str-focus-class" onClick={() => removeStorage(productID)}>
                                        <span className="put-storage-title storage-span">
                                          {buttonTitle.remove}
                                        </span>
                                      </Button>
                                    </div>
                                  </div>
                                    :
                                    <div className={`put-storage-section pick-qty-put-storage-section ${isAnyPicked ? "" : "disable-put-in-storage"}`}                                         >
                                      <Button className={`cursor-pointer d-flex align-items-center border-0 bg-transparent button-focus-class ${isAnyPicked ? "" : "disable-storage"}`} onClick={() => putInStorageClick(product)}>
                                        <PosImage src={storageOutlinedIcon} alt="add product" className="mr-2" />
                                        <span className="put-storage-title">
                                          {putInStorage}
                                        </span>
                                      </Button>
                                    </div>

                                  }

                                </span>
                              </div>
                            </div>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={4}
                          md={1}
                          lg={1}
                          className={`sticky-header-style 
                        ${pickStickyInfoClasses.fixedPickedHeader} 
                        pick-content-spacing d-flex text-center pick-order-content-spacing pick-ordered-qty-content-spacing`}
                        >
                          <div className="pick-responsive-title">
                            <span>ORDER QTY.</span>
                          </div>
                          <div className="pick-replace-table-header text-center">
                            <span className="replace-qty">
                              {product?.qtyOrdered}
                            </span>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={4}
                          md={1}
                          lg={1}
                          className={`sticky-header-style 
                        ${pickStickyInfoClasses.fixedPickedHeader} 
                        pick-content-spacing d-flex text-center pick-order-content-spacing pick-ordered-qty-content-spacing`}
                        >
                          <div className="pick-responsive-title">
                            <span>Back Order</span>
                          </div>
                          <div className="pick-replace-table-header text-center">
                            <span className="replace-qty">
                              {product?.totalPickedValue > 0 ? (product?.qtyOrdered - product?.totalPickedValue) < 0 ? "--" : (product?.qtyOrdered - product?.totalPickedValue) : product?.qtyOrdered}
                            </span>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={4}
                          md={1}
                          lg={1}
                          className={`sticky-header-style 
                        ${pickStickyInfoClasses.fixedPickedHeader} 
                        pick-content-spacing d-flex text-center pick-order-content-spacing`}
                        >
                          <div className="pick-responsive-title">
                            <span>TOTAL PICKED</span>
                          </div>
                          <div className="pick-replace-table-header text-center">
                            <span
                              className={`${product?.totalPickedValue === undefined
                                ? "light-text"
                                : "text-black"
                                } picked-replace-qty`}
                            >
                              {isNaN(Number(product?.totalPickedValue))
                                ? ""
                                : Number(product?.totalPickedValue) ?? 0}
                            </span>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={8}
                          md={5}
                          lg={5}
                          className="picking-info-table pick-content-spacing picking-location-table picked-qty-location-title"
                        >
                          <Grid
                            container
                            className="pick-responsive-title-grid"
                          >
                            <Grid item className="picking-qty-location" xs={4}>
                              <div className="pick-responsive-title text-center pick-location-responsive-title">
                                <span>LOCATION</span>
                              </div>
                            </Grid>
                            <Grid item className="available-qty-title" xs={4}>
                              <div className="pick-responsive-title text-center pick-location-responsive-title">
                                <span>AVAILABLE QTY.</span>
                              </div>
                            </Grid>
                            <Grid item xs={4}>
                              <div className="pick-responsive-title text-center pick-location-responsive-title">
                                <span>PICKED QTY.</span>
                              </div>
                            </Grid>
                          </Grid>
                          {product?.pickedQtyWithLocation?.locations?.map(
                            (location, id) => {
                              return (
                                <Grid
                                  container
                                  className="table-info-last-cell pl-0 pr-0"
                                  key={id}
                                >
                                  <Grid item className="picking-qty-location padding-all-pic-location" xs={4}>
                                    <div className="column-info text-center">
                                      <div className="btn-text-design  pick-cell-table">
                                        <span className="int-16-reg ">
                                          {location?.fullLocationName}
                                        </span>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item className="available-qty-title padding-all-pic-location" xs={4}>
                                    <div className="column-info text-center">
                                      <div className="btn-text-design pick-cell-table">
                                        <span className="int-16-reg">
                                          {location.availableQty}
                                        </span>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item xs={4} className="padding-all-pic-location focus-border-pick-qty">
                                    <div className="column-info text-center replace-pick-qty">
                                      <div className="btn-text-design pick-cell-table text-center">
                                        <PosInput
                                          name="qtyPicked"
                                          variant="standard"
                                          id="standard-basic"
                                          InputProps={{
                                            disableUnderline: true,
                                          }}
                                          inputProps={{
                                            maxLength: 8,
                                            pattern: "d{8}",
                                          }}
                                          placeholder="Enter Qty"
                                          className="pick-table-input pick-cell-table text-center pick-cell-table-input text-center"
                                          value={
                                            product?.pickedQtyWithLocation
                                              ?.locations[id].pickedQty
                                          }
                                          onKeyDown={preventArrowKeys}
                                          onWheel={(e) =>
                                            (
                                              e?.target as HTMLElement
                                            )?.blur()
                                          }
                                          onChange={(e) => {
                                            finalPickedQtyOnchange(
                                              e.target.value,
                                              index,
                                              id,
                                              location
                                            )
                                            // dispatch(shippingAddressSelectedData(false));
                                          }
                                          }
                                        />
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                              );
                            }
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  );
                }) : null}
              </div>
            </Container>
          </div>
          <div className="pick-replace-quantity-heading picking-qty-bottom-btn">
            <Container className="new-container pick-qty-container">
              <div className="pick-replace-quantity-right-btn-div pick-quantity-right-btn-div pick-replace-quantity-complete-btn-div">
                <PosButton
                  onClick={() => {
                    setShowPrintOptions(true);
                    setPrintOptionsDrawer({ ...printOptionsDrawer, right: true });
                  }}
                  onKeyDown={(e) => handleKeyPress(e)}
                  variant="contained"
                  color="secondary"
                  className="pick-replace-quantity-btn custom-size-13 btn"
                // disabled={!getWarehouseLocations?.length}
                >
                  <div className="d-flex align-items-center">
                    <div>
                      <PosImage src={ticket} alt="ticket-icon" />
                    </div>
                    <span className="icon-btn-right-spacing">
                      Print
                    </span>
                  </div>
                  <div className="ml-2">
                    <span className="schedule-shortcut">P</span>
                  </div>
                </PosButton>

                <PosButton
                  onClick={() => {
                    handleContinueToPayment()
                    // Require later
                    // setBalanceDetail(true)
                  }}
                  variant="contained"
                  color="primary"
                  className="pick-replace-quantity-btn custom-size-13 btn"
                  onKeyDown={(e) => handleKeyPress(e)}
                  disabled={warehouseLocationLoader}
                >
                  <div className="select-default-text mr-2">
                    <span className="int-18-reg-white">
                      {continueToPayment}
                    </span>
                  </div>
                  <div>
                    <span className="schedule-shortcut">D</span>
                  </div>
                </PosButton>
              </div>
            </Container>
          </div>
        </section>
      </div>
      {/* end */}
      <SetStorageModal
        storageModal={pickQtyStorageModal}
        setStorageModal={setPickQtyStorageModal}
        putInStorage
        storageDetail={storageDetail}
        productDetail={productForStorage}
        itemInStorageData={itemInStorageData}
        resetStorageData={resetStorageData}
      />
      <SetStorageModal
        storageModal={pickQtyChangeStorageModal}
        setStorageModal={setPickQtyChangeStorageModal}
        changeInStorage
        storageDetail={storageDetail}
        productDetail={productForStorage}
        itemInStorageData={itemInStorageData}
        resetStorageData={resetStorageData}
      />
      <SetStorageModal
        storageModal={pickQtyStorageDataModal}
        setStorageModal={setPickQtyStorageDataModal}
        storageDetail={storageDetail}
        productDetail={productForStorage}
        itemInStorageData={itemInStorageData}
        resetStorageData={resetStorageData}
        selectedStorageItem={selectedStorageItem}
      />


      {(paymentMethodDrawer || shippingAddress || balanceDetail) ? <PaymentMethod
        pickUpSelected={pickUpSelected}
        shipCharge={shippingCharge?.shippingAmt || 0}
        setShipChargeTax={() => {
          // Required Later
        }}
        setShipCharge={() => {
          // Required Later
        }}
        showShipping={showShipping}
        state={drawerState}
        setState={setDrawerState}
        priceInDecimal={priceInDecimal}
        setPriceInDecimal={setPriceInDecimal}
        orderInstructions={orderInstructions}
        shippingInstructions={shippingInstructions}
        setShippingInstructions={setShippingInstructions}
        open={paymentMethodDrawer || shippingAddress || balanceDetail}
        onClose={() => {
          setShowShipping(false);
          setPriceInDecimal(false);
          setPaymentMethodDrawer(false)
          // backToCartPage()
        }}
        className=''
        setShippingAddress={setShippingAddress}
        setPickUpSelected={setPickUpSelected}
        drawerState={drawerState}
        setDrawerState={setDrawerState}
        setShipToByFacilityId={setShipToByFacilityId}
        drawerRef={drawerRef}
        shippingAddress={shippingAddress}
        balanceDetail={balanceDetail}
        setBalanceDetail={setBalanceDetail}
        creditAmtApplied={creditAmtApplied}
        setCreditAmtApplied={setCreditAmtApplied}
        // isPayPalAccount={isPayPalAccount}
        paymentMethodRef={paymentMethodRef}
      /> : <></>}

      <DocReuploadModal uplodedDocs={toBeuploadedFiles} open={openModal} closeModal={closeAlertModal} />

      <OrderDetailsDrawer
        open={printOptionsDrawer["right"]}
        drawerClass={classes.drawer}
        onClick={() => {
          setPrintOptionsDrawer({ ...printOptionsDrawer, right: false });
          setShowShipping(false);
          setShowPrintOptions(false);
        }}
        orderInstructions={orderInstructions}
        setOrderInstructions={setOrderInstructions}
        shippingInstructions={shippingInstructions}
        setShippingInstructions={setShippingInstructions}
        temporaryStorageLocation={temporaryStorageLocation}
        setTemporaryStorageLocation={setTemporaryStorageLocation}
        showPrices={showPrices}
        handlePriceCheck={handlePriceCheck}
        handleMarkAsQuoteCheck={handleMarkAsQuoteCheck}
        expiryDate={expiryDate}
        setExpiryDate={setExpiryDate}
        priceCheck={priceCheckReducer}
        markAsQuote={markAsQuoteReducer}
        setPrintLoaderWithTotal={setPrintLoaderWithTotal}
        setPrintLoader={setPrintLoader}
        showPrintOptions={showPrintOptions}
        printInvoice={printInvoice}
        state={printOptionsDrawer}
        setState={setPrintOptionsDrawer}
        setShowPrintOptions={setShowPrintOptions}
        emailQuote={emailQuote}
      />

      <BackOrderConfirmationModal
        totalItems={totalBackOrderItems}
        backOrderItems={backOrders}
        open={backOrderConfirmationModal}
        onCloseModal={() => setBackOrderConfirmationModal(false)}
        continueToPayment={handlePaymentClick}
      />

    </div>
  );
};

export default PickQuantity;