import { FiberManualRecordIcon } from "constants/icons";

export const commonConstants = {
  export: "Export",
  newProduct: "New Product",
  products: "Products",
  addPendingProduct: "Add Pending Product",
  status: "Status",
  manufacturer: "Manufacturer",
  category: "Category",
  price: "Price",
  vendor: "Vendor",
  schedule: "Schedule",
  group: "Group",
  list: "View as List",
  grid: "View as Grid",
  qtyinStock: "Qty. in Stock",
  priceUOM: "Price UOM",
  pricePerUnit: "Price/Unit",
  itemOUM: "Item UOM",
  pricePerItem: "Price/Item",
  unitPerCases: "Units/Case",
  pricePerCase: "Price/Case",
  minimumToOrder: "Minimum Cases to Order",
  productSearchBarPlaceholder: "Product Name, Code, Manufacturer, Vendor",
  clearAll: "Clear All",
  electricalSupplies: "Electrical Supplies ",
  bulbs: " Bulbs ",
  lamps: " Lamps",
  min: 'Min:',
  Max: 'Max:',
  Inc: 'Increment:',
  statutable: "statuses",
  manufacturerIds: "manufacturerIds",
  vendorIds: "vendorIds",
  categoryIds: "categoryIds",
  productGroupIds: "productGroupIds",
  priceScheduleIds: "priceScheduleIds",
  clearanceFlag: "clearanceFlag",
  discontinueFlag: "discontinueFlag",
  target: "_blank",
  noreferrer: "noreferrer",
  DATE: "DATE",
  CHANGEDBY: "CHANGED BY",
  OLDPRICE: "OLD PRICE",
  NEWPRICE: "NEW PRICE",
  WAREHOUSE: "WAREHOUSE",
  AvlQty: "AVL. QTY.",
  SeeallLocations: "See all Locations",
  StockMinMax: "Stock Min/Max",
  qtyInStocks: "qtyInStock",
  Stockminmax: "StockMin/Max",
  priceOUM: "priceOUM",
  itemOum: "itemOUM",
  unitPerCase: "unitPerCase",
  minimumToOrders: "minimumToOrder",
  Weight: "Weight (in lbs)",
  MIN: "MIN",
  MAX: "MAX",
  INCREMENT: "INCREMENT",
  Back: "Back",
  SaveChanges: "Save Changes",
  addToBatch: "Add to Batch",
  availability: "Availability",
  available: "availability",
  searchProduct: "Searching products ",
  productFounds: "products found ",
  notFound: "No product found",
  productFound: "product found"
};

export enum layoutType {
  grid = "grid",
  list = "list",
}

export const productStatus = [
  {
    title: "Inactive",
    Component: (
      <FiberManualRecordIcon
        fontSize="small"
        color="error"
        sx={{ color: "#EB4E3D" }} // Red
        className="check-icon"
      />
    ),
  },
  {
    title: "Active",
    Component: (
      <FiberManualRecordIcon
        fontSize="small"
        sx={{ color: "#36c759" }} // Green
        className="check-icon"
      />
    ),
  },
  {
    title: "Pending",
    Component: (
      <FiberManualRecordIcon
        fontSize="small"
        sx={{ color: "#ffcf6e" }} // Yellow
        className="check-icon"
      />
    ),
  },
];

export const defaultFilter = {
  statuses: null,
  manufacturerIds: null,
  vendorIds: null,
  categoryIds: null,
  productGroupIds: null,
  priceScheduleIds: null,
  clearanceFlag: null,
  discontinueFlag: null
}

export const staticStatus = {
  "3": "Clearance",
  "4": "Discontinued"
}

export const availabilityStatus = {
  "5": "All",
  "6": "In Stock",
  "7": "Out of stock"
}

export const statusValue = {
  zero: 0,
  one: 1,
  three: 3,
  four: 4,
  full: "FULL",
  InStock: 6,
  OutStock: 7,
  All: 5
}