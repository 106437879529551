/* eslint-disable */
import "@fontsource/inter";
import { Box, Drawer } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import BalanceDrawer from "apps/pos/Drawers/BalanceDrawer";
import CardOption from "apps/pos/PaymentMethod/Components/PaymentOptionsComponent/CardOption";
import CashComponent from "apps/pos/PaymentMethod/Components/PaymentOptionsComponent/CashOption";
import CheckOption from "apps/pos/PaymentMethod/Components/PaymentOptionsComponent/CheckOption";
import PayLaterOption from "apps/pos/PaymentMethod/Components/PaymentOptionsComponent/PayLaterOption";
import SetUnpaidDues from "apps/pos/PaymentMethod/Components/PaymentOptionsComponent/SetUnpaidDues";
import ShippingInfo from "apps/pos/PaymentMethod/Components/ShippingInfo";
import ShowAllPaymentOptions from "apps/pos/PaymentMethod/Components/ShowAllPaymentOptions";
import ShowGenericCustomerOptions from "apps/pos/PaymentMethod/Components/ShowGenericCustomerOptions";

import {
  Container,
  PosButton,
  PosCheckbox,
  PosIconButton,
  PosImage
} from "apps/pos/Common/uiCore";
import POSTextareaAutosize from "components/Input/POSTextareaAutosize";
import SelectShippingAddress from "../SelectShippingAddress";

import {
  fiveDecimalRegex,
  oneDecimal,
  removeSpaceRegex,
  shipChargeRegex,
  twoDecimalRegex,
} from "constants/constant";
import {
  ProductPriceType,
  ProductTypes,
  cardNumbers,
  documentByModule
} from "constants/enum";
import { ArrowBackRoundedIcon } from "constants/icons";
import images from "constants/images";
import {
  PaymentMethodProps,
  PosFlowDataProp,
  UploadedDocumentItemProp,
} from "constants/interfaces";
import { addCustomerLabel, paymentMethodLabel } from "constants/posLabel";
import { Paths, PaymentMode, Placeholders, Tabs, TransactionType } from "enums";
import { PosResponseProps, RootState, ShippingDrawerProps } from "models";
import { keys } from "utils/helper";
import {
  authorizationToken,
  calculatePriceItemMuEachNew,
  getLocalStoraGeItem,
  priceToBeFixedConverter,
  replacePhoneNumber,
  roundToTwoDecimalPlaces,
} from "utils/utils";

import {
  isGpmCustomFlag,
  isOrderTotalCustomFlag,
  recipientInfoSwitchInfoData,
  setAllowPickupCheck,
  setCustomerAlertHide,
  setExemptTax,
  setOriginalOrderTotal,
  setOriginalPercentage,
  setPosFlowData,
  setShipTaxAmtToBeSent,
  setShippingAmountReducer,
  setShippingTaxAmount,
  setSubTotalAmount,
  setTakerIdToBeSent,
  setTaxAmount,
  setTaxExemptManually,
  setTaxRateToBeSent,
} from "sagas/persistReducer/actions";
import {
  cartSubmitClear,
  cartSubmitRequest,
  checkInvicePageRefresh,
  clearPackingSlipData,
  clearSearchCustomer,
  newProductAdded,
  savedDraftsReset,
  setJobNameForAll,
  setMarkAsQuoteReducer,
  setShippingInstructionNote,
  setStoreCreditAmout,
} from "sagas/pos/actions";

import { drawerUseStyles } from "theme/GlobalStyles";
import "./paymentMethos.scss";

const PaymentMethod = ({
  pickUpSelected,
  setShipChargeTax,
  setShipCharge,
  showShipping,
  balanceDetail,
  setBalanceDetail,
  state,
  setState,
  shipCharge,
  priceInDecimal,
  setPriceInDecimal,
  orderInstructions,
  shippingInstructions,
  setShippingInstructions,
  open,
  onClose,
  className,
  setShippingAddress,
  setPickUpSelected,
  drawerState,
  setDrawerState,
  setShipToByFacilityId,
  drawerRef,
  shippingAddress,
  creditAmtApplied,
  setCreditAmtApplied,
  // isPayPalAccount,
  paymentMethodRef,
}) => {
  const dispatch = useDispatch();
  const drawerClasses = drawerUseStyles();
  const { jobNameForAll, newProductIds, billingDetails } = useSelector<
    RootState,
    PosResponseProps
  >((state) => state.pos);
  const {
    posFlowData,
    isTaxExempted,
    loadCartDetails,
    recipientSwitchInfo,
    isOrderTotalCustom,
    isGPMCustom,
    skipOrdererInfo,
    allowPickupCheck
  } = useSelector<RootState, PosFlowDataProp>((state) => state.persistReducer);
  const { cartSubmitSuccess, cartSubmit, errorProcess, processLoading } =
    useSelector<RootState, PaymentMethodProps>((state) => state.pos);

  const { getTabShipping } = useSelector<RootState, ShippingDrawerProps>(
    (state) => state.pos
  );
  const { isPurchaseOrderNoEdit } = useSelector<RootState, PosResponseProps>(
    (state) => state.pos
  );
  const {
    savedCart,
    selectQuoteName,
    customer,
    shippingCharge,
    taker,
    poNumber,
    customerInfoUpdated,
    schedule,
    cartProducts,
    selectQuoteId,
    selectQuoteType,
    shiptoFacilities,
    phone,
    firstName,
    addDocument,
    email,
    addInStorage,
    lastName,
    companyName,
    isMail,
    cartProductDetails,
    salesman,
  } = posFlowData || {};

  const { items } = addInStorage || {};

  const {
    shipToByFacility,
    products,
    takerId,
    sessionStartedAt,
    cartId,
    purchaseOrderNum,
    jobName,
  } = savedCart || {};

  const currentCustomerDetail = loadCartDetails?.customer || {};

  const isCustomerGeneric =
    customer?.isPosGeneric || currentCustomerDetail?.isPosGeneric;

  const { shipToByFacility: loadCartShipToByFacility } = loadCartDetails || {};

  const loadShipToByFacility = loadCartShipToByFacility || {};

  const { typeClearance, typeDiscontinued } = ProductTypes;

  const [jobNameSave, setJobNameSave] = useState<string>(
    purchaseOrderNum ? purchaseOrderNum : ""
  );
  const [escapePress, setEscapePress] = useState(false);

  const decimalCount = (num) => {
    const numStr = String(num);
    if (numStr.includes(".")) {
      return numStr.split(".")[1].length;
    }
    return 0;
  };

  //TODO: will remove later
  const [shippingAmount, setShippingAmount] = useState<any>(
    shipCharge
      ? decimalCount(shipCharge) <= 2
        ? Number(shipCharge).toFixed(2)
        : Number(shipCharge).toFixed(5)
      : 0
  );

  const [tenderAmount, setTenderAmount] = useState<number>();
  const [checkNumber, setCheckNumber] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [receiptNumber, setReceiptNumber] = useState("");
  const [isBillingDetails, setIsBillingDetails] = useState(false);
  const [nameOnCard, setNameOnCard] = useState("");
  const [nameOnCardError, setNameOnCardError] = useState(false);
  const [payPalCardNumber, setPayPalCardNumber] = useState(null);
  const [payPalCardNumberError, setPayPalCardNumberError] = useState({
    isPayPalCardValidationError: false,
    isPayPalCardError: false,
  });
  const [payPalCardType, setPayPalCardType] = useState<any>(null);
  const [expiryDate, setExpiryDate] = useState(null);
  const [expiryDateError, setExpiryDateError] = useState({
    isValidationError: false,
    isExpiryDateError: false,
  });
  const [cvvNumber, setCvvNumber] = useState(null);
  const [cvvNumberError, setCvvNumberError] = useState(false);
  const [tempJobNameSave, setTempJobNameSave] = useState("");
  const [checkNumberError, setCheckNumberError] = useState(false);
  const [cashDrawerError, setCashDrawerError] = useState(false);
  const [checkNumberValidation, setCheckNumberValidation] = useState(false);
  const [checkDisable, setCheckDisable] = useState(false);
  const [cardNumberError, setCardNumberError] = useState(false);
  const [receiptNumberError, setReceiptNumberError] = useState(false);
  const showPrices = customer?.showPrices;
  const [repSwitch, setRepSwitch] = useState(showPrices);
  const { hidePrices, invoiceEmail, allowPickUp, KeepShippingLocation } = paymentMethodLabel;
  const [cardType, setCardType] = useState(1);
  const [mailFlag, setMailFlag] = useState(Boolean(isMail));
  const [cartExtPrice, setCartExtPrice] = useState(0);
  const [, setProfitPercentage] = useState(0);
  const [cashOptionsSelected, setCashOptionsSelected] = useState<any>();
  const [manualCard, setManualCard] = useState(true);
  const [allowPickup, setAllowPickup] = useState(false);
  const {
    cashPayment,
    checkPayment,
    cardPayment,
    accountPayment,
    customerCredit,
  } = PaymentMode;
  const { sale } = TransactionType;
  const taxRate = shiptoFacilities?.tax?.taxRate;
  const taxRatePercentage = shiptoFacilities?.tax?.taxRate * 100;
  const subTotalAmount = cartExtPrice ? cartExtPrice.toFixed(2) : 0;
  // might need
  // const taxAmount = ((cartExtPrice * taxRate) / 100).toFixed(2);

  const taxAmountWithoutRoundOff =
    ((Number(subTotalAmount) + Number(shippingAmount)) * taxRatePercentage) /
    100;
  const taxAmount = roundToTwoDecimalPlaces(taxAmountWithoutRoundOff);

  const taxForPayloadWithoutRoundOff = (cartExtPrice * taxRatePercentage) / 100;
  const taxAmountForPayload = roundToTwoDecimalPlaces(
    taxForPayloadWithoutRoundOff
  );

  const shipTaxFlagValue =
    !savedCart?.customer?.taxExemptFlag &&
    !shiptoFacilities?.recipient?.taxExemptFlag &&
    !isTaxExempted;

  const handlePriceCheck = () => {
    setRepSwitch(!repSwitch);
  };
  const handleInvoiceEmailCheck = () => {
    setMailFlag(!mailFlag);
  };

  const handleAllowPickUpCheck = () => {
    setAllowPickup(!allowPickup);
    dispatch(setAllowPickupCheck(!allowPickup))
  };

  const getPoNum = () => {
    if (loadCartDetails?.products.length) {
      if (isPurchaseOrderNoEdit) {
        return posFlowData?.poNumber || "";
      } else if (loadCartDetails?.purchaseOrderNum) {
        return loadCartDetails?.purchaseOrderNum;
      } else {
        return "";
      }
    } else {
      if (isPurchaseOrderNoEdit) {
        return posFlowData?.poNumber;
      } else {
        return "";
      }
    }
  };
  const [poNumberSave, setPoNumberSave] = useState<string>(getPoNum());

  const generic = customer?.isPosGeneric;
  const isGenericCustomer = generic ? true : false;
  const currentWorkspace = getLocalStoraGeItem("workspace");
  const { stockWarehouseId } = currentWorkspace;

  const [recipientInfoSwitch, setRecipientInfoSwitch] =
    useState(recipientSwitchInfo);

  useEffect(() => {
    setRecipientInfoSwitch(recipientSwitchInfo);
  }, [recipientSwitchInfo]);

  const handleRecipientInfoCheck = () => {
    setRecipientInfoSwitch((previousValue) => !previousValue);
    dispatch(recipientInfoSwitchInfoData(!recipientInfoSwitch));
  };

  //TODO: will remove later
  const [paymentMethodWrapper, setPaymentMethodWrapper] = useState<any>({
    showShippingAddress: shippingAddress,
    setShippingCharge: false,
    setUnpaidDues: false,
    showAllPaymentOptions:
      !shippingAddress && !isCustomerGeneric && !balanceDetail,
    showGenericCustomerOptions:
      !shippingAddress && isCustomerGeneric && !balanceDetail,
    cashOption: false,
    checkOption: false,
    cardOption: false,
    payLaterOption: false,
    showBalanceDrawer: balanceDetail,
  });

  const paymentMethodHanldeEvent = useRef({
    showShippingAddress: shippingAddress,
    setShippingCharge: false,
    setUnpaidDues: false,
    showAllPaymentOptions: !shippingAddress && !balanceDetail,
    showGenericCustomerOptions:
      !shippingAddress && isCustomerGeneric && !balanceDetail,
    cashOption: false,
    checkOption: false,
    cardOption: false,
    payLaterOption: false,
    showBalanceDrawer: balanceDetail,
  });

  const [loading, setLoading] = useState(false);
  const [finalTotal, setFinalTotal] = useState();

  const navigate = useNavigate();

  const handleKeyPress = (e) => {
    const { key, keyCode } = e;
    const { one, two, three, four, numKey1, numKey3, d, D, numKey4, numKey2 } =
      keys;
    const { creditCardOnlyFlag, hasInternalNotes } =
      currentCustomerDetail || {};
    if (
      !showShipping &&
      paymentMethodHanldeEvent.current.showAllPaymentOptions
    ) {
      if (creditCardOnlyFlag === 1 || hasInternalNotes) {
        if (key === one || keyCode === numKey1) {
          e.preventDefault();
          setPaymentMethodWrapper({
            showShippingAddress: false,
            setShippingCharge: false,
            setUnpaidDues: false,
            showAllPaymentOptions: false,
            showGenericCustomerOptions: false,
            cashOption: true,
            checkOption: false,
            cardOption: false,
            payLaterOption: false,
            showBalanceDrawer: false,
          });
        } else if (key === two || keyCode === numKey2) {
          setPaymentMethodWrapper({
            setShippingCharge: false,
            setUnpaidDues: false,
            showAllPaymentOptions: false,
            cashOption: false,
            checkOption: true,
            cardOption: false,
            payLaterOption: false,
          });
          setCheckNumberError(false);
          setCashDrawerError(false);
          if (isGenericCustomer) {
            setCheckDisable(true); // to disable Complete Order button
          }
        } else if (key === three || keyCode === numKey3) {
          setPaymentMethodWrapper({
            showShippingAddress: false,
            setShippingCharge: false,
            setUnpaidDues: false,
            showAllPaymentOptions: false,
            showGenericCustomerOptions: false,
            cashOption: false,
            checkOption: false,
            cardOption: true,
            payLaterOption: false,
            showBalanceDrawer: false,
          });
        }
      } else {
        if (key === one || keyCode === numKey1) {
          e.preventDefault();
          setPaymentMethodWrapper({
            showShippingAddress: false,
            setShippingCharge: false,
            setUnpaidDues: false,
            showAllPaymentOptions: false,
            showGenericCustomerOptions: false,
            cashOption: true,
            checkOption: false,
            cardOption: false,
            payLaterOption: false,
            showBalanceDrawer: false,
          });
        } else if (key === two || keyCode === numKey2) {
          setPaymentMethodWrapper({
            showShippingAddress: false,
            setShippingCharge: false,
            setUnpaidDues: false,
            showAllPaymentOptions: false,
            showGenericCustomerOptions: false,
            cashOption: false,
            checkOption: true,
            cardOption: false,
            payLaterOption: false,
            showBalanceDrawer: false,
          });
          if (isGenericCustomer) {
            setCheckDisable(true); // to disable Complete Order button
          }
        } else if (key === three || keyCode === numKey3) {
          setPaymentMethodWrapper({
            showShippingAddress: false,
            setShippingCharge: false,
            setUnpaidDues: false,
            showAllPaymentOptions: false,
            showGenericCustomerOptions: false,
            cashOption: false,
            checkOption: false,
            cardOption: true,
            payLaterOption: false,
            showBalanceDrawer: false,
          });
        } else if (!isCustomerGeneric && customer?.creditHoldFlag !== 1) {
          if (key === four || keyCode === numKey4) {
            setPaymentMethodWrapper({
              showShippingAddress: false,
              setShippingCharge: false,
              setUnpaidDues: false,
              showAllPaymentOptions: false,
              showGenericCustomerOptions: false,
              cashOption: false,
              checkOption: false,
              cardOption: false,
              payLaterOption: true,
              showBalanceDrawer: false,
            });
          }
        }
      }
    }
  };

  useEffect(() => {
    document?.addEventListener("keydown", handleKeyPress);
    return function cleanup() {
      document?.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  useEffect(() => {
    paymentMethodHanldeEvent.current = paymentMethodWrapper;
  }, [paymentMethodWrapper]);

  useEffect(() => {
    if (jobNameForAll) {
      setTempJobNameSave(jobNameForAll);
    } else if (jobName) {
      setTempJobNameSave(jobName);
    } else if (selectQuoteName && !jobName) {
      setTempJobNameSave(selectQuoteName);
    }
  }, [jobName, selectQuoteName]);

  const totalTaxAmt = (
    !isTaxExempted
      ? taxAmount
        ? Number((cartExtPrice * taxRate) / 100) +
        Number((shippingAmount * taxRate) / 100)
        : 0
      : shipTaxFlagValue
        ? Number((cartExtPrice * taxRate) / 100) +
        Number((shippingAmount * taxRate) / 100)
        : 0
  ).toFixed(2);

  useEffect(() => {
    if (cartSubmitSuccess) {
      const data = {
        ...posFlowData,
        customer: {},
        orderCompleteData: cartSubmit,
        addDocument: [],
        poNumber: "",
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        companyName: "",
      };

      dispatch(setPosFlowData(data));
      dispatch(setShippingInstructionNote(""));
      dispatch(setJobNameForAll(""));
      dispatch(setMarkAsQuoteReducer(false));
      dispatch(setCustomerAlertHide(true));
      dispatch(setStoreCreditAmout(""));
      dispatch(cartSubmitClear());
      dispatch(isOrderTotalCustomFlag(false));
      dispatch(isGpmCustomFlag(false));
      dispatch(setOriginalPercentage(""));
      dispatch(setOriginalOrderTotal(""));
      dispatch(newProductAdded([]));
      setLoading(false);
      navigate(Paths.posOrderComplete);
      dispatch(setExemptTax(false));
      dispatch(setTaxExemptManually(false));
      dispatch(setSubTotalAmount(""));
      dispatch(setTaxAmount(""));
      dispatch(setShippingAmountReducer(""));
      dispatch(setShipTaxAmtToBeSent(""));
      dispatch(setShippingTaxAmount(""));
      dispatch(setTakerIdToBeSent(""));
      dispatch(setTaxRateToBeSent(""));
    }
  }, [cartSubmitSuccess]);

  const updateCalculation = () => {
    let calculateExtPrice = 0;
    let calculateTotalQuantity = 0;
    let calculateVendorPrice = 0;
    let calculateNetPrice = 0;
    cartProducts?.length &&
      cartProducts.forEach(
        ({
          priceMU,
          itemMU,
          posRetailPrice,
          qtyOrdered,
          vendorPrice,
        }) => {
          const itemMuPriceWithPosRetailPrice =
            calculatePriceItemMuEachNew(
              priceMU,
              itemMU,
              posRetailPrice,
              qtyOrdered
            ) || 0;
          calculateExtPrice =
            calculateExtPrice +
            parseFloat(roundToTwoDecimalPlaces(itemMuPriceWithPosRetailPrice));

          calculateVendorPrice =
            calculateVendorPrice + Number(vendorPrice);
          calculateTotalQuantity = calculateTotalQuantity + qtyOrdered;
          calculateNetPrice = calculateNetPrice + Number(posRetailPrice);
        }
      );

    const profitPercentageCalc =
      ((calculateNetPrice - calculateVendorPrice) / calculateNetPrice) * 100;
    setCartExtPrice(Number(roundToTwoDecimalPlaces(calculateExtPrice)));

    setProfitPercentage(profitPercentageCalc);
  };

  useEffect(() => {
    updateCalculation();
  }, [cartProducts, cartProductDetails?.qtyOrdered]);

  const handleCustomerDetail = (values) => {
    let data;
    const {
      fullName,
      phoneNumber,
      extCode,
      email: genericEmail,
      companyName: genericCompanyName,
    } = values || {};
    const splitFullname = fullName?.split(" ");
    const genericUserFirstName = splitFullname.slice(0, -1).join(" ");
    const genericUserLastName = splitFullname[splitFullname?.length - 1];
    const genericPhoneInfo = {
      type: "DIRECT1",
      number: phoneNumber,
      extn: extCode,
      intCode: "1",
    };
    if (recipientInfoSwitch) {
      const phoneRemoveSpace = phoneNumber
        ? phoneNumber.replace(removeSpaceRegex, "")
        : "";
      const areaCodeInfo =
        phoneRemoveSpace && phoneRemoveSpace?.substring(0, 3);
      const prefixInfo = phoneRemoveSpace && phoneRemoveSpace?.substring(3, 6);
      const lineNumInfo =
        phoneRemoveSpace && phoneRemoveSpace?.substring(6, 10);
      data = {
        ...posFlowData,
        shiptoFacilities: {
          ...shiptoFacilities,
          recipient: {
            ...shiptoFacilities?.recipient,
            firstName: genericUserFirstName,
            lastName: genericUserLastName,
            companyName: genericCompanyName || null,
            email: genericEmail || null,
            phone1: areaCodeInfo,
            phone2: prefixInfo,
            phone3: lineNumInfo,
            phone4: extCode || "",
          },
        },
      };
      dispatch(recipientInfoSwitchInfoData(true));
    } else {
      data = {
        ...posFlowData,
        firstName: genericUserFirstName,
        lastName: genericUserLastName,
        phone: genericPhoneInfo || {},
        email: genericEmail || null,
        companyName: genericCompanyName || null,
      };
    }

    dispatch(setPosFlowData(data));
  };

  useEffect(() => {
    if (errorProcess || errorProcess === undefined) {
      setLoading(false);
      dispatch(cartSubmitClear());
    }
  }, [errorProcess]);

  useEffect(() => {
    const id = customer?.personId || customer?.customerId;
    if (!id) {
      navigate(Paths.posNewOrder);
    }
  }, []);

  const cartProductsDetails = [];

  cartProducts?.length &&
    cartProducts?.forEach((product) => {
      const {
        pickedQtyWithLocation,
        productId,
        manufacturerCode,
        manufacturerId,
        vendorId,
        productName,
        vendorPrice,
        salesPrice,
        initialPriceData,
        posRetailPrice,
        priceMU,
        muName,
        itemMU,
        itemMuName,
        unitPerCase,
        qtyInStock,
        qtyOrdered,
        shippingNote,
        orderNote,
        clearance,
        discontinued,
        overridePrice,
        priceScheduleVendorProductGroupId,
        lineIdData,
        isCustomPrice,
        type,
        productPriceTypeApplied,
        itemType,
        markupMultiplier,
      } = product || {};
      const finalPickConfigData = pickedQtyWithLocation?.locations
        ?.map((qtyData: any) => {
          return {
            lineId: lineIdData,
            availableQty: qtyData?.availableQty,
            manufacturerId: qtyData?.manufacturerId,
            pickedQty: qtyData?.pickedQty,
            stockWarehouseId: qtyData?.stockWarehouseId || stockWarehouseId,
            productId: qtyData?.productId,
            stockRowId: qtyData?.stockRowId,
            stockUnitId: qtyData?.stockUnitId,
            stockShelfId: qtyData?.stockShelfId,
          };
        })
        .filter((i: any) => i?.pickedQty > 0);
      const customPrice = isCustomPrice
        ? ProductPriceType.CUSTOM
        : ProductPriceType[type];
      const marginAppiled =
        customer?.fixMarginCustFlag > 0
          ? ProductPriceType.FIXED_MARGIN_PRICE
          : customPrice;
      const productPriceType =
        productPriceTypeApplied && customer?.fixMarginCustFlag === 0
          ? typeof productPriceTypeApplied === "string"
            ? ProductPriceType[productPriceTypeApplied]
            : productPriceTypeApplied
          : marginAppiled;
      const priceType =
        isOrderTotalCustom || isGPMCustom
          ? ProductPriceType.CUSTOM
          : productPriceType;
      const isNewProduct = newProductIds.includes(productId);

      cartProductsDetails.push({
        lineId: lineIdData,
        productId,
        manufacturerCode,
        manufacturerId,
        vendorId,
        itemType: itemType || null,
        productName,
        vendorPrice: vendorPrice?.price ?? vendorPrice,
        salesPrice: salesPrice?.price ?? salesPrice,
        initialPosRetailPrice: initialPriceData || 0,
        finalPosRetailPrice: Number(posRetailPrice) || 0,
        priceScheduleVendorProductGroupId,
        priceMU,
        productPriceTypeApplied: priceType,
        muName,
        itemMU,
        itemMuName,
        unitPerCase,
        qtyInStock,
        qtyOrdered,
        clearanceFlag: clearance?.type === typeClearance ? 1 : 0,
        discontinueFlag: discontinued?.type === typeDiscontinued ? 1 : 0,
        markupMultiplier,
        overridePrice: overridePrice || null,
        pickConfigs: finalPickConfigData ?? null,
        isNew: isNewProduct,
        shippingNote: shippingNote,
        orderNote: orderNote,
      });
    });

  const shippingTaxAmount = shippingCharge?.shippingAmtTax
    ? shippingCharge?.shippingAmtTax
    : 0;

  const shippingChargeAmount = shippingCharge?.shippingAmt
    ? shippingCharge.shippingAmt
    : 0;

  let grandTotalAmount;
  if (customer?.taxExemptFlag && isTaxExempted) {
    grandTotalAmount =
      Number(subTotalAmount) +
      Number(Number(shippingAmount)?.toFixed(2)) -
      Number(creditAmtApplied ?? 0);
  } else {
    if (shipToByFacility?.recipient?.taxExemptFlag && isTaxExempted) {
      grandTotalAmount =
        Number(subTotalAmount) +
        Number(Number(shippingAmount)?.toFixed(2)) -
        Number(creditAmtApplied ?? 0);
    } else {
      if (isTaxExempted) {
        grandTotalAmount =
          Number(subTotalAmount) +
          Number(Number(shippingAmount)?.toFixed(2)) -
          Number(creditAmtApplied ?? 0);
      } else {
        if (shipToByFacility?.tax?.shipTaxFlag === 0) {
          grandTotalAmount =
            Number(subTotalAmount) +
            Number(taxAmount) +
            Number(Number(shippingAmount)?.toFixed(2)) -
            Number(creditAmtApplied ?? 0);
        } else {
          grandTotalAmount =
            Number(subTotalAmount) +
            Number(Number(taxAmount)?.toFixed(2)) +
            Number(Number(shippingAmount)?.toFixed(2)) -
            Number(creditAmtApplied ?? 0);
        }
      }
    }
  }

  let shipTaxAmtToBeSent;

  if (customer?.taxExemptFlag && isTaxExempted) {
    shipTaxAmtToBeSent = 0;
  } else {
    if (shipToByFacility?.recipient?.taxExemptFlag && isTaxExempted) {
      shipTaxAmtToBeSent = 0;
    } else {
      if (isTaxExempted) {
        shipTaxAmtToBeSent = 0;
      } else {
        if (shipToByFacility?.tax?.shipTaxFlag === 0) {
          shipTaxAmtToBeSent = 0;
        } else {
          shipTaxAmtToBeSent =
            shiptoFacilities?.tax?.shipTaxFlag ??
              shipToByFacility?.tax?.shipTaxFlag
              ? Number(Number(shippingTaxAmount))
              : 0;
        }
      }
    }
  }

  let taxRateToBeSent;

  if (customer?.taxExemptFlag && isTaxExempted) {
    taxRateToBeSent = 0.0;
  } else {
    if (shipToByFacility?.recipient?.taxExemptFlag && isTaxExempted) {
      taxRateToBeSent = 0.0;
    } else {
      if (isTaxExempted) {
        taxRateToBeSent = 0.0;
      } else {
        if (shipToByFacility?.tax?.shipTaxFlag === 0) {
          taxRateToBeSent = 0.0;
        } else {
          taxRateToBeSent = pickUpSelected
            ? loadShipToByFacility?.tax?.taxRate
            : shiptoFacilities?.tax?.taxRate;
        }
      }
    }
  }

  let taxScheduleToBeSent;

  if (customer?.taxExemptFlag && isTaxExempted) {
    taxScheduleToBeSent = "NONTAX";
  } else {
    if (shipToByFacility?.recipient?.taxExemptFlag && isTaxExempted) {
      taxScheduleToBeSent = "NONTAX";
    } else {
      if (isTaxExempted) {
        taxScheduleToBeSent = "NONTAX";
      } else {
        if (shipToByFacility?.tax?.shipTaxFlag === 0) {
          taxScheduleToBeSent = "NONTAX";
        } else {
          taxScheduleToBeSent = pickUpSelected
            ? loadShipToByFacility?.tax?.taxSchedule
            : shiptoFacilities?.tax?.taxSchedule;
        }
      }
    }
  }

  const currentTaker = JSON.parse(localStorage.getItem("taker"));

  let takerIdToBeSent;

  if (products?.length) {
    takerIdToBeSent = takerId === 0 ? currentTaker?.personId : takerId;
  } else if (taker?.personId) {
    takerIdToBeSent = taker?.personId;
  } else {
    takerIdToBeSent = currentTaker?.personId;
  }

  const decodedData = authorizationToken("Authorization");

  const uploadData = [];
  addDocument?.length &&
    addDocument.map((item: UploadedDocumentItemProp) => {
      const {
        file,
        description,
        typeId,
        documentSaveType,
        locationSaveType,
        uploadedBy,
        originalFile,
      } = item;
      if (originalFile) {
        let data = {
          fileName: file,
          description: description,
          typeId: typeId,
          documentSaveType: documentSaveType,
          locationSaveType: locationSaveType,
          uploadedBy: uploadedBy,
        };
        uploadData.push(data);
      }
    });

  const completeOrderAPI = () => {
    const formFiles = [];
    addDocument?.length &&
      addDocument.map((item: UploadedDocumentItemProp) => {
        const {
          file,
          description,
          typeId,
          documentSaveType,
          locationSaveType,
          uploadedBy,
          originalFile,
        } = item;
        formFiles.push(originalFile);
        const fileUploadedData = {
          file: file,
          description: description,
          typeId: typeId,
          documentSaveType: documentSaveType,
          locationSaveType: locationSaveType,
          uploadedBy: uploadedBy,
        };
        return fileUploadedData;
      });
    dispatch(savedDraftsReset());
    dispatch(clearSearchCustomer());
    dispatch(clearPackingSlipData());
    dispatch(checkInvicePageRefresh(false));
    const data = {
      ...posFlowData,
      savedCart: {
        jobName: null,
      },
      poNumber: null,
    };
    dispatch(setPosFlowData(data));
    // Might need later.
    // let newNumber = replacePhoneNumber(currentCustomerDetail?.phone?.number);
    // const areaCodeInfo = newNumber && newNumber?.substring(0, 3);
    // const prefixInfo = newNumber && newNumber?.substring(3, 6);
    // const lineNumInfo = newNumber && newNumber?.substring(6, 10);

    // const phoneData = {
    //   type: "DIRECT1",
    //   intCode: currentCustomerDetail?.phone?.intCode,
    //   areaCode: areaCodeInfo,
    //   prefix: prefixInfo,
    //   lineNum: lineNumInfo,
    //   extn: customer?.phone?.extn || "1",
    //   number: currentCustomerDetail?.phone?.number,
    // };

    let ordererNewNumber = replacePhoneNumber(phone?.number);
    const ordererAreaCodeInfo =
      ordererNewNumber && ordererNewNumber?.substring(0, 3);
    const ordererPrefixInfo =
      ordererNewNumber && ordererNewNumber?.substring(3, 6);
    const ordererLineNumInfo =
      ordererNewNumber && ordererNewNumber?.substring(6, 10);

    const ordererPhoneData = {
      type: "DIRECT1",
      intCode: phone?.intCode,
      areaCode: ordererAreaCodeInfo,
      prefix: ordererPrefixInfo,
      lineNum: ordererLineNumInfo,
      extn: phone?.extn,
      number: `+${phone?.intCode
        } (${ordererAreaCodeInfo}) ${ordererPrefixInfo}-${ordererLineNumInfo}; ${phone?.extn || ""
        }`,
    };

    let ordererInfoData = {};
    let billingInfoPhoneInfo = {};
    const { recipient } = shiptoFacilities || {};

    const genericPhoneInfo = {
      type: Placeholders.phoneType,
      areaCode: recipient?.phone1,
      prefix: recipient?.phone2,
      lineNum: recipient?.phone3,
      number: `${recipient?.phone1}${recipient?.phone2}${recipient?.phone3}`,
      extn: recipient?.phone4 || "",
      intCode: "1",
    };

    const firstNameGeneric = recipientSwitchInfo
      ? recipient?.firstName
      : firstName;
    const lastNameGeneric = recipientSwitchInfo
      ? recipient?.lastName
      : lastName;
    const emailNameGeneric = recipientSwitchInfo
      ? recipient?.email
      : email
        ? email
        : null;
    const companyNameGeneric = recipientSwitchInfo
      ? recipient?.companyName
      : companyName;
    const phoneNumberGeneric = recipientSwitchInfo
      ? genericPhoneInfo?.number
        ? genericPhoneInfo
        : null
      : phone?.number
        ? ordererPhoneData
        : null;

    if (billingDetails) {
      const billingInfoPhone =
        billingDetails?.phoneNumber && billingDetails?.phoneNumber?.split(" ");

      billingInfoPhoneInfo = {
        type: Placeholders.phoneType,
        areaCode: billingInfoPhone ? billingInfoPhone[0] : "",
        prefix: billingInfoPhone ? billingInfoPhone[1] : "",
        lineNum: billingInfoPhone ? billingInfoPhone[2] : "",
        number: `${billingInfoPhone ? billingInfoPhone[0] : ""}${billingInfoPhone ? billingInfoPhone[1] : ""
          }${billingInfoPhone ? billingInfoPhone[2] : ""}`,
        extn: billingDetails?.extCode || "",
        intCode: "1",
      };
    }

    if (isCustomerGeneric && !skipOrdererInfo) {
      if (paymentMethodWrapper?.cardOption) {
        ordererInfoData = {
          firstName: firstNameGeneric,
          lastName: lastNameGeneric,
          phone: phoneNumberGeneric,
          email: emailNameGeneric,
          companyName: companyNameGeneric,
        };
      } else {
        ordererInfoData = {
          firstName: firstNameGeneric,
          lastName: lastNameGeneric,
          phone: phoneNumberGeneric,
          email: emailNameGeneric,
          companyName: companyNameGeneric,
        };
      }
    } else if (paymentMethodWrapper?.cardOption) {
      ordererInfoData = {
        firstName: billingDetails?.firstName,
        lastName: billingDetails?.lastName,
        phone: null,
        email: billingDetails?.email,
        companyName: billingDetails?.companyName,
      };
    } else {
      ordererInfoData = {
        firstName: null,
        lastName: null,
        phone: null,
        email: null,
        companyName: null,
      };
    }

    const {
      customerId,
      creditHoldFlag,
      hasInternalNotes,
      lookupType,
      customerNote,
      showPrices,
      priceScheduleId,
      taxExemptFlag,
      storeCreditAmt,
    } = customer || {};

    const finalCustomerdata = {
      ...ordererInfoData,
      buyOnBanNote: hasInternalNotes,
      customerId: customerId,
      buyOnCreditHold: creditHoldFlag === 1,
      customerInfoUpdated: customerInfoUpdated,
      lookupType: lookupType,
      customerNote: customerNote,
      showPrices: showPrices ? repSwitch : false,
      isPosGeneric: isCustomerGeneric,
      noMailFlag: Number(mailFlag),
      storeCreditAmt,
    };

    const parts = nameOnCard.split(" ");
    const finalName = [parts.shift(), parts.join(" ")];

    const finalFirstName = finalName[0];
    const finalLastName = finalName[1] ? finalName[1] : "";

    const processData = {
      sessionStartedAt: sessionStartedAt,
      cartId: cartId,
      customer: finalCustomerdata,
      loggedInUserId: decodedData?.DefaultLoginId,
      transactionType: sale,
      stockWarehouseId,
      takerId: currentTaker?.personId,
      purchaseOrderNum: isPurchaseOrderNoEdit ? poNumber : loadCartDetails?.purchaseOrderNum,
      jobName: tempJobNameSave
        ? tempJobNameSave
        : jobNameSave
          ? jobNameSave
          : jobName,
      taxRate: taxRateToBeSent,
      taxSchedule: taxScheduleToBeSent,
      shipTaxFlag: shipToByFacility?.tax?.shipTaxFlag,
      initialShipToAddressId: shipToByFacility?.facilityId,
      finalShipToAddressId: shiptoFacilities?.facilityId,

      salesPersonId:
        posFlowData?.salesman?.ssoId || posFlowData?.salesman?.personId || 0,
      initialScheduleId:
        priceScheduleId ??
        schedule?.id ??
        currentCustomerDetail.priceScheduleId,
      finalScheduleId:
        schedule?.id ??
        priceScheduleId ??
        currentCustomerDetail.priceScheduleId,
      paymentMode: paymentMethodWrapper?.cashOption
        ? cashPayment
        : paymentMethodWrapper?.checkOption
          ? checkPayment
          : paymentMethodWrapper?.cardOption
            ? cardPayment
            : paymentMethodWrapper?.payLaterOption
              ? accountPayment
              : paymentMethodWrapper?.setUnpaidDues
                ? customerCredit
                : "",
      paymentRef1: paymentMethodWrapper?.checkOption
        ? checkNumber
        : paymentMethodWrapper?.cardOption
          ? cardNumber
          : null,
      paymentRef2: paymentMethodWrapper?.cardOption ? receiptNumber : null,
      subTotalAmt: Number(subTotalAmount),
      taxAmt:
        taxExemptFlag && isTaxExempted
          ? 0
          : shipToByFacility?.recipient?.taxExemptFlag && isTaxExempted
            ? 0
            : isTaxExempted
              ? 0
              : Number(taxAmountForPayload),
      taxExemptFlag: taxExemptFlag
        ? 1
        : shipToByFacility?.recipient?.taxExemptFlag
          ? 1
          : isTaxExempted
            ? 1
            : 0,
      shippingFeeAmt: Number(Number(shippingAmount)?.toFixed(2)),
      shippingTaxAmt: shippingAmount
        ? shipTaxAmtToBeSent
          ? shipTaxAmtToBeSent
          : 0
        : 0,
      grandTotalAmt: Number(grandTotalAmount.toFixed(4)),
      tenderAmt: Number(Number(tenderAmount)?.toFixed(4)),
      tenderBalanceAmt:
        paymentMethodWrapper?.cashOption && tenderAmount
          ? Number(priceToBeFixedConverter(tenderAmount - grandTotalAmount))
          : 0,
      products: cartProductsDetails,
      quoteId: selectQuoteId ? selectQuoteId : null,
      quoteType: selectQuoteType ? selectQuoteType : documentByModule.notSet,
      uploadFiles: uploadData?.length ? uploadData : null,
      addInStorage:
        addInStorage && items && items?.length ? addInStorage : null,
      creditCardType: paymentMethodWrapper?.cardOption ? cardType : null,
      // creditCardType: paymentMethodWrapper?.cardOption ? manualCard ? payPalCardType?.value : cardType : null,
      creditAmtApplied: Number(creditAmtApplied) || 0,
      balanceAmtPaid: 0,
      cashDrawerId: paymentMethodWrapper?.setUnpaidDues
        ? 0
        : Number(cashOptionsSelected),
      shippingNote: shippingInstructions,
      orderNote: orderInstructions,
      takerName: currentTaker?.fullName,
      firstName: nameOnCard ? finalFirstName : null,
      lastName: nameOnCard ? finalLastName : null,
      cardNum: payPalCardNumber ? payPalCardNumber : null,
      cardExpDate: expiryDate ? expiryDate : null,
      cardCvv2: cvvNumber ? cvvNumber : null,
      zip: billingDetails?.zip ? billingDetails?.zip : null,
      street: billingDetails?.companyAddress
        ? billingDetails?.companyAddress
        : null,
      salesPersonName: posFlowData?.salesman?.salespersonName
        ? posFlowData?.salesman?.salespersonName
        : "",
      noDeliverToShipAddressButPickupFlag: allowPickupCheck ?? false
    };

    processData["shippingNote"] = shippingInstructions;

    const processDataFormat = JSON.stringify(JSON.stringify(processData));
    dispatch(checkInvicePageRefresh(false));

    let formData = new FormData();
    formData.append("posOrderTransactionRequest", processDataFormat);

    addDocument.forEach((item: UploadedDocumentItemProp) => {
      const { originalFile } = item;
      if (originalFile) {
        formData.append("formFiles", originalFile);
      }
    });

    if (paymentMethodWrapper?.checkOption) {
      if (!checkNumber || !cashOptionsSelected) {
        !checkNumber && setCheckNumberError(true);
        !cashOptionsSelected && setCashDrawerError(true);
      } else {
        if (isGenericCustomer) {
          const countDocuments = addDocument.filter(
            (e) =>
              e?.documentSaveTypeName?.toLowerCase() ===
              documentByModule.addressProf.toLowerCase()
          ).length;
          if (countDocuments || grandTotalAmount < 50) {
            setLoading(true);
            dispatch(cartSubmitRequest(formData, cartId));
          }
        } else {
          setLoading(true);
          dispatch(cartSubmitRequest(formData, cartId));
        }
      }
    } else if (paymentMethodWrapper?.cardOption) {
      if (!receiptNumber) {
        setReceiptNumberError(true);
      }
      if (!cashOptionsSelected) {
        setCashDrawerError(true);
      }
      const { four } = cardNumbers || {};
      if (
        cardNumber &&
        cardNumber.length === four &&
        receiptNumber &&
        cashOptionsSelected
      ) {
        setLoading(true);
        dispatch(cartSubmitRequest(formData, cartId));
      }
      if (!cardNumber || cardNumber.length !== four) {
        setCardNumberError(true);
      }
    } else if (paymentMethodWrapper?.payLaterOption) {
      if (!cashOptionsSelected) {
        setCashDrawerError(true);
      } else {
        setLoading(true);
        dispatch(cartSubmitRequest(formData, cartId));
      }
    } else if (paymentMethodWrapper?.setUnpaidDues) {
      setLoading(true);
      dispatch(cartSubmitRequest(formData, cartId));
    } else {
      if (cashOptionsSelected) {
        setJobNameSave(tempJobNameSave ? tempJobNameSave : jobNameSave);
        setLoading(true);
        dispatch(cartSubmitRequest(formData, cartId));
      } else {
        setCashDrawerError(true);
      }
    }
    dispatch(setPosFlowData({ ...posFlowData, viaSwitchCustomer: false }));
  };

  useEffect(() => {
    let finalTotal;
    if (customer?.taxExemptFlag && isTaxExempted) {
      finalTotal =
        Number(subTotalAmount) + Number(Number(shippingAmount)?.toFixed(2));
    } else {
      if (shipToByFacility?.recipient?.taxExemptFlag) {
        finalTotal =
          Number(subTotalAmount) + Number(Number(shippingAmount)?.toFixed(2));
      } else {
        if (isTaxExempted) {
          finalTotal =
            Number(subTotalAmount) + Number(Number(shippingAmount)?.toFixed(2));
        } else {
          if (shipToByFacility?.tax?.shipTaxFlag === 0) {
            finalTotal =
              Number(subTotalAmount) +
              Number(Number(taxAmount)?.toFixed(2)) +
              Number(Number(shippingAmount)?.toFixed(2));
          } else {
            finalTotal =
              Number(subTotalAmount) +
              Number(Number(taxAmount)?.toFixed(2)) +
              Number(Number(shippingAmount)?.toFixed(2));
          }
        }
      }
    }
    finalTotal = finalTotal.toFixed(2);
    setTenderAmount(finalTotal);
    setFinalTotal(finalTotal);
  }, [
    customer?.taxExemptFlag,
    isTaxExempted,
    subTotalAmount,
    shippingAmount,
    shipToByFacility,
    taxAmount,
    taxRate,
  ]);

  const { check } = images;

  const shippingHandle = (e) => {
    setPriceInDecimal(false);
    if (fiveDecimalRegex.test(e.target.value) || e.target.value === "") {
      setShippingAmount(e.target.value);
    } else if (e.target.value === "0" || shipChargeRegex.test(e.target.value)) {
      setShippingAmount(e.target.value);
    }
    if (e.target.value === ".") {
      setShippingAmount("0.");
    }
    if (e.target.value.charAt(0) === ".") {
      setShippingAmount(
        e.target.value?.replace(e.target.value.charAt(0), "0.")
      );
    }
  };

  const saveCharges = () => {
    if (!isNaN(shippingAmount)) {
      setShipCharge(shippingAmount);
      setShipChargeTax((shippingAmount * taxRatePercentage) / 100);
      const data = {
        ...posFlowData,
        shippingCharge: {
          shippingAmt:
            shippingAmount &&
              shippingAmount?.charAt(shippingAmount.length - 1) === "."
              ? shippingAmount?.replace(
                shippingAmount?.charAt(shippingAmount?.length - 1),
                ".00"
              )
              : shippingAmount,
          shippingAmtTax: isTaxExempted
            ? 0
            : (Number(Number(shippingAmount).toFixed(2)) * taxRatePercentage) /
            100,
          shippingAmtTaxWithFix: isTaxExempted
            ? 0
            : (
              (Number(Number(shippingAmount)) * taxRatePercentage) /
              100
            ).toFixed(2),
        },
      };

      dispatch(setPosFlowData(data));
      onClose();
      setState({ ...state, right: false });
    }
  };

  const saveCardPayment = () => {
    setPaymentMethodWrapper({
      showShippingAddress: false,
      setShippingCharge: false,
      setUnpaidDues: false,
      showAllPaymentOptions: false,
      cashOption: false,
      checkOption: false,
      cardOption: true,
      payLaterOption: false,
      showBalanceDrawer: false,
    });
    setCardNumberError(false);
    setReceiptNumberError(false);
  };
  const taxAdded = (
    !isTaxExempted
      ? taxAmount
        ?
        Number(taxAmount)
        : 0
      : shipTaxFlagValue
        ? Number(taxAmount)
        : 0
  ).toFixed(2);

  const isFreeShip = currentCustomerDetail?.freeShipCustFlag;

  const getCreditCardType = (type) => {
    setCardType(type);
  };

  const skipForNowBalance = () => {
    setCreditAmtApplied(0);
    dispatch(setStoreCreditAmout("0"));
    setPaymentMethodWrapper({
      setShippingCharge: false,
      setUnpaidDues: false,
      showAllPaymentOptions: true,
      cashOption: false,
      checkOption: false,
      cardOption: false,
      payLaterOption: false,
      showBalanceDrawer: false,
    });
  };
  const includePending = (total) => {
    setFinalTotal(total);
    setTenderAmount(total);
    const isCreditPay = Number(total) <= 0;
    setPaymentMethodWrapper({
      showShippingAddress: false,
      setShippingCharge: false,
      setUnpaidDues: isCreditPay,
      showAllPaymentOptions: !isCreditPay,
      showGenericCustomerOptions: false,
      cashOption: false,
      checkOption: false,
      cardOption: false,
      payLaterOption: false,
      showBalanceDrawer: false,
    });
  };
  const changeCreditAmt = (e) => {
    const inputValue = e?.target ? e.target.value : e;
    const validated = inputValue.match(twoDecimalRegex);
    if (validated) {
      setCreditAmtApplied(inputValue);
      dispatch(setStoreCreditAmout(inputValue));
    }
    if (inputValue === "") {
      dispatch(setStoreCreditAmout("0"));
    }
  };


  const setShippingPaymentWrapped = () => {
    const customCredit = customer?.storeCreditAmt;
    setBalanceDetail(customCredit && !isCustomerGeneric);
    setPaymentMethodWrapper({
      showShippingAddress: false,
      setShippingCharge: false,
      setUnpaidDues: false,
      showAllPaymentOptions: !customCredit && !isCustomerGeneric,
      showGenericCustomerOptions: isCustomerGeneric,
      cashOption: false,
      checkOption: false,
      cardOption: false,
      payLaterOption: false,
      showBalanceDrawer: customCredit && !isCustomerGeneric,
    });
  };
  const {
    setShippingCharge,
    showGenericCustomerOptions,
    showAllPaymentOptions,
    showBalanceDrawer,
    setUnpaidDues,
  } = paymentMethodWrapper || {};
  const { ShippingInstructions: shippingInstructionsLabel, additionalSetting } =
    addCustomerLabel;
  const handleChangeShipping = (e) => {
    setShippingInstructions((e.target as HTMLInputElement).value);
    dispatch(setShippingInstructionNote((e.target as HTMLInputElement).value));
  };
  const salesPersonName = salesman?.salespersonName
    ? salesman.salespersonName
    : "";


  const handleDrawerClose = () => {
    if (!paymentMethodWrapper.showShippingAddress) {
      if (
        !setShippingCharge &&
        !showGenericCustomerOptions &&
        !showAllPaymentOptions &&
        !showBalanceDrawer
      ) {
        setCashDrawerError(false);
        !processLoading &&
          setPaymentMethodWrapper({
            showShippingAddress: false,
            setShippingCharge: false,
            setUnpaidDues: false,
            showAllPaymentOptions: !setUnpaidDues,
            cashOption: false,
            checkOption: false,
            cardOption: false,
            payLaterOption: false,
            showBalanceDrawer: setUnpaidDues,
          });
        setTenderAmount(finalTotal);
      } else {
        onClose();
        setShippingAddress(false);
        setBalanceDetail(false);
      }
    } else {
      if (
        getTabShipping === Tabs.tabIndexFirst ||
        getTabShipping === Tabs.tabIndexTwo ||
        getTabShipping === Tabs.tabIndexThree ||
        getTabShipping === Tabs.tabIndexFour
      ) {
        setEscapePress(true);
      } else {
        setShippingAddress(false);
      }
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      hideBackdrop={false}
      className={`${className ?? ""} ${drawerClasses.drawer
        } main-drawer-classes default-drawer-style no-cart-drawer main-drawer-classes drawer-scrollbar-style customer-sec-delete-draft-drawer`}
      onClose={handleDrawerClose}
      id={`'cartDrawerContent'`} // ${isShippingDrawer ? '' : 'cartDrawerContent'}
      ref={paymentMethodRef}
    >
      <Box sx={{ width: 600 }} role="presentation">
        {!setShippingCharge &&
          !showGenericCustomerOptions &&
          !showAllPaymentOptions &&
          !showBalanceDrawer ? (
          <PosIconButton
            className="back-next-btn-small btn responsive-back-btn pos-icon-back-btn"
            tabIndex={-1}
            onClick={() => {
              setCashDrawerError(false);

              !processLoading &&
                setPaymentMethodWrapper({
                  showShippingAddress: false,
                  setShippingCharge: false,
                  setUnpaidDues: false,
                  showAllPaymentOptions: !setUnpaidDues,
                  cashOption: false,
                  checkOption: false,
                  cardOption: false,
                  payLaterOption: false,
                  showBalanceDrawer: setUnpaidDues,
                });
              setTenderAmount(finalTotal);
            }}
          >
            <ArrowBackRoundedIcon />
          </PosIconButton>
        ) : (
          <PosIconButton
            className="back-next-btn-small back-responsive-btn responsive-back-btn btn pos-icon-back-btn"
            tabIndex={-1}
            onClick={() => {
              onClose();
              setShippingAddress(false);
              setBalanceDetail(false);
            }}
          >
            <ArrowBackRoundedIcon />
          </PosIconButton>
        )}
      </Box>

      <Container className="modal-content-style pos-drawer-container">
        <main
          className={`${paymentMethodWrapper.cashOption ||
            paymentMethodWrapper.checkOption ||
            paymentMethodWrapper.cardOption ||
            paymentMethodWrapper.payLaterOption ||
            paymentMethodWrapper.setUnpaidDues
            ? "payment-method-model-bottom"
            : ""
            }`}
        >
          {showShipping ? (
            <ShippingInfo
              isFreeShip={isFreeShip}
              currentCustomerDetail={currentCustomerDetail}
              shiptoFacilities={shiptoFacilities}
              baseAmount={subTotalAmount}
              shippingAmount={shippingAmount}
              isTaxExempted={isTaxExempted}
              taxValue={taxRate}
              taxAdded={taxAdded}
              finalTotal={finalTotal}
              priceInDecimal={priceInDecimal}
              decimalCount={decimalCount}
              saveCharges={saveCharges}
              shippingHandle={shippingHandle}
              posFlowData={posFlowData}
            />
          ) : null}
          {paymentMethodWrapper?.showBalanceDrawer ? (
            <BalanceDrawer
              finalTotal={subTotalAmount}
              skipForNowBalance={skipForNowBalance}
              taxRate={taxRate}
              shippingAmount={shippingAmount}
              taxAmount={taxAmount}
              shipTaxAmount={(shipCharge * taxRate) / 100}
              creditAmtApplied={creditAmtApplied}
              changeCreditAmt={changeCreditAmt}
              includePending={includePending}
            />
          ) : null}
          {paymentMethodWrapper.setUnpaidDues ? (
            <SetUnpaidDues
              tempJobNameSave={tempJobNameSave}
              setTempJobNameSave={setTempJobNameSave}
              subTotal={subTotalAmount}
              taxAmount={taxAmount}
              shipTaxAmount={(shipCharge * taxRate) / 100}
              orderId={cartId}
              shippingTaxAmount={shippingChargeAmount}
              salesPersonName={salesPersonName}
              shipToTaxFlag={shiptoFacilities?.tax?.shipTaxFlag}
              shippingFee={shippingAmount}
              creditAmtApplied={creditAmtApplied}
              poNumberSave={poNumberSave}
              setPoNumberSave={setPoNumberSave}
            />
          ) : null}

          {!showShipping &&
            !balanceDetail &&
            paymentMethodWrapper.showShippingAddress ? (
            <SelectShippingAddress
              setPickUpSelected={setPickUpSelected}
              state={drawerState}
              setState={setDrawerState}
              setShipToByFacilityId={setShipToByFacilityId}
              drawerRef={drawerRef}
              onCloseDrawer={() => {
                setShippingAddress(false);
              }}
              isPaymentMethodWrapper={false}
              setPaymentMethodWrapper={setShippingPaymentWrapped}
              escapePress={escapePress}
              setEscapePress={setEscapePress}
            />
          ) : null}

          {!showShipping &&
            !balanceDetail &&
            paymentMethodWrapper.showGenericCustomerOptions ? (
            <ShowGenericCustomerOptions
              handleRecipientInfoCheck={handleRecipientInfoCheck}
              handleCustomerDetail={handleCustomerDetail}
              setPaymentMethodWrapper={setPaymentMethodWrapper}
            />
          ) : null}

          {!showShipping && paymentMethodWrapper.showAllPaymentOptions ? (
            <ShowAllPaymentOptions
              currentCustomerDetail={currentCustomerDetail}
              saveCardPayment={saveCardPayment}
              setPaymentMethodWrapper={setPaymentMethodWrapper}
              setCheckNumberError={setCheckNumberError}
              isGenericCustomer={isGenericCustomer}
              setCheckDisable={setCheckDisable}
              setCashDrawerError={setCashDrawerError}
            />
          ) : null}

          {paymentMethodWrapper.cashOption ? (
            <CashComponent
              finalTotal={finalTotal}
              tempJobNameSave={tempJobNameSave}
              tenderAmount={tenderAmount}
              oneDecimal={oneDecimal}
              setTempJobNameSave={setTempJobNameSave}
              setTenderAmount={setTenderAmount}
              cashOptionsSelected={cashOptionsSelected}
              setCashOptionsSelected={setCashOptionsSelected}
              setCashDrawerError={setCashDrawerError}
              cashDrawerError={cashDrawerError}
              subTotal={subTotalAmount}
              taxAmount={taxAmount}
              shipTaxAmount={(shipCharge * taxRate) / 100}
              orderId={cartId}
              shippingTaxAmount={shippingChargeAmount}
              salesPersonName={salesPersonName}
              shipToTaxFlag={shiptoFacilities?.tax?.shipTaxFlag}
              shippingFee={shippingAmount}
              creditAmtApplied={creditAmtApplied}
              poNumberSave={poNumberSave}
              setPoNumberSave={setPoNumberSave}
            />
          ) : null}

          {paymentMethodWrapper.checkOption ? (
            <CheckOption
              finalTotal={finalTotal}
              tempJobNameSave={tempJobNameSave}
              checkNumber={checkNumber}
              setTempJobNameSave={setTempJobNameSave}
              checkNumberError={checkNumberError}
              setCheckNumber={setCheckNumber}
              setCheckNumberError={setCheckNumberError}
              isGenericCustomer={isGenericCustomer}
              setCheckDisable={setCheckDisable}
              cashOptionsSelected={cashOptionsSelected}
              setCashOptionsSelected={setCashOptionsSelected}
              setCashDrawerError={setCashDrawerError}
              cashDrawerError={cashDrawerError}
              subTotal={subTotalAmount}
              taxAmount={taxAmount}
              shipTaxAmount={(shipCharge * taxRate) / 100}
              orderId={cartId}
              shippingTaxAmount={shippingChargeAmount}
              salesPersonName={salesPersonName}
              shipToTaxFlag={shiptoFacilities?.tax?.shipTaxFlag}
              shippingFee={shippingAmount}
              creditAmtApplied={creditAmtApplied}
              poNumberSave={poNumberSave}
              setPoNumberSave={setPoNumberSave}
            />
          ) : null}

          {paymentMethodWrapper.cardOption ? (
            <CardOption
              finalTotal={finalTotal}
              tempJobNameSave={tempJobNameSave}
              setTempJobNameSave={setTempJobNameSave}
              setCardNumberError={setCardNumberError}
              cardNumberError={cardNumberError}
              receiptNumberError={receiptNumberError}
              receiptNumber={receiptNumber}
              setReceiptNumber={setReceiptNumber}
              setReceiptNumberError={setReceiptNumberError}
              getCreditCardType={getCreditCardType}
              cardType={cardType}
              checkNumberValidation={checkNumberValidation}
              setCheckNumberValidation={setCheckNumberValidation}
              subTotal={subTotalAmount}
              taxAmount={taxAmount}
              shipTaxAmount={(shipCharge * taxRate) / 100}
              orderId={cartId}
              cashOptionsSelected={cashOptionsSelected}
              setCashOptionsSelected={setCashOptionsSelected}
              setCashDrawerError={setCashDrawerError}
              cashDrawerError={cashDrawerError}
              // isPayPalAccount={isPayPalAccount}
              isBillingDetails={isBillingDetails}
              setIsBillingDetails={setIsBillingDetails}
              // manualCard={manualCard}
              setManualCard={setManualCard}
              nameOnCard={nameOnCard}
              setNameOnCard={setNameOnCard}
              nameOnCardError={nameOnCardError}
              setNameOnCardError={setNameOnCardError}
              completeOrderAPI={completeOrderAPI}
              payPalCardNumber={payPalCardNumber}
              setPayPalCardNumber={setPayPalCardNumber}
              payPalCardNumberError={payPalCardNumberError}
              setPayPalCardNumberError={setPayPalCardNumberError}
              payPalCardType={payPalCardType}
              setPayPalCardType={setPayPalCardType}
              expiryDate={expiryDate}
              setExpiryDate={setExpiryDate}
              expiryDateError={expiryDateError}
              setExpiryDateError={setExpiryDateError}
              cvvNumber={cvvNumber}
              setCvvNumber={setCvvNumber}
              cvvNumberError={cvvNumberError}
              setCvvNumberError={setCvvNumberError}
              cardNumber={cardNumber}
              setCardNumber={setCardNumber}
              shippingTaxAmount={shippingChargeAmount}
              salesPersonName={salesPersonName}
              loading={loading}
              shipToTaxFlag={shiptoFacilities?.tax?.shipTaxFlag}
              shippingFee={shippingAmount}
              creditAmtApplied={creditAmtApplied}
              poNumberSave={poNumberSave}
              setPoNumberSave={setPoNumberSave}
            />
          ) : null}

          {paymentMethodWrapper.payLaterOption ? (
            <PayLaterOption
              tempJobNameSave={tempJobNameSave}
              setTempJobNameSave={setTempJobNameSave}
              subTotal={subTotalAmount}
              taxAmount={taxAmount}
              shipTaxAmount={(shipCharge * taxRate) / 100}
              orderId={cartId}
              cashOptionsSelected={cashOptionsSelected}
              setCashOptionsSelected={setCashOptionsSelected}
              setCashDrawerError={setCashDrawerError}
              cashDrawerError={cashDrawerError}
              shippingTaxAmount={shippingChargeAmount}
              salesPersonName={salesPersonName}
              finalTotal={finalTotal}
              shipToTaxFlag={shiptoFacilities?.tax?.shipTaxFlag}
              shippingFee={shippingAmount}
              creditAmtApplied={creditAmtApplied}
              poNumberSave={poNumberSave}
              setPoNumberSave={setPoNumberSave}
            />
          ) : null}

          {paymentMethodWrapper.cashOption ||
            paymentMethodWrapper.checkOption ||
            paymentMethodWrapper.cardOption || // verify this condition
            // (paymentMethodWrapper.cardOption && !manualCard) || // verify this condition
            paymentMethodWrapper.payLaterOption ||
            paymentMethodWrapper.setUnpaidDues ? (
            <div className="shipping-btns flex-column btn-margin-top-30">
              <div className="hide-price-checkbox mt-0">
                <PosCheckbox
                  checked={allowPickup}
                  onChange={handleAllowPickUpCheck}
                  label={allowPickUp}
                  className="checkbox-focus"
                />
                <span className="allow-pickup-text">{KeepShippingLocation}</span>
              </div>
              <div className="complete-order-btn">
                <PosButton
                  variant="contained"
                  color="primary"
                  className={`${loading ? "loading" : ""
                    } modal-select-default-btn ship-btn-dark justify-content-start payment-complete-order-btn button-focus-class`}
                  onClick={() => completeOrderAPI()}
                  disabled={
                    (paymentMethodWrapper.cashOption &&
                      tenderAmount - Number(finalTotal) < 0) ||
                    checkDisable ||
                    loading
                  }
                >
                  {!loading && (
                    <PosImage
                      className="icn-btn complete-order-icon"
                      src={check}
                      alt="check-icon"
                    />
                  )}
                  <span className="complete-order-text">
                    {loading ? (
                      <>
                        <span>Processing...</span>
                      </>
                    ) : (
                      "Complete Order"
                    )}
                  </span>
                </PosButton>
              </div>
              <div className="cash-hide-price-option">
                <div className="additional-settings-title">
                  <span>{additionalSetting}</span>
                </div>
                {showPrices ? (
                  <div className="hide-price-checkbox mt-0">
                    <PosCheckbox
                      checked={!repSwitch}
                      onChange={handlePriceCheck}
                      label={hidePrices}
                      className="checkbox-focus"
                    />
                  </div>
                ) : null}
              </div>
              <div className="hide-price-checkbox mt-0">
                <PosCheckbox
                  checked={!mailFlag}
                  onChange={handleInvoiceEmailCheck}
                  label={invoiceEmail}
                  className="checkbox-focus"
                />
              </div>
              <div>
                <div className="order-num-input">
                  <span className="shipping-instructions-btn-size text-black">
                    {shippingInstructionsLabel}
                  </span>
                </div>
                <div className="margintop-10 text-area-focus">
                  <POSTextareaAutosize
                    id="shipping-instruction"
                    name="shipping-instruction"
                    className="double-bordered-input"
                    value={shippingInstructions}
                    onChange={handleChangeShipping}
                  />
                </div>
              </div>
            </div>
          ) : null}

        </main>
      </Container>
    </Drawer>
  );
};

export default PaymentMethod;
/* eslint-disable */
