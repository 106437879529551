import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { ErrorMessage } from "apps/pos/formik/errorMessage";
import { UpdatedBasicProductDetailSchema } from "apps/pos/formik/validationSchema";
import { PosButton, PosInput, PosSelect } from "apps/pos/Common/uiCore";
import ProductStatusDetail from "../../Components/ProductDetails/ProductStatusDetail";
import IconButton from 'apps/product/Modals/Elements/IconButton'


import { sectionName, labels, productStatusNoInactiveOptions } from "../../modules/productDetailsContainer/constants";
import { sectionTitle } from "constants/constant";
import { addManufactureLink, addVendorLink } from "constants/staticData";
import { authorizationToken, blockInvalidChar, setFocusTo } from "utils/utils";

import { newProductGroupRequest, newProductManufacturerRequest, newProductVendorRequest } from "sagas/pos/actions";
import useProductsDetailsHooks from "../../modules/productDetailsContainer/hooks/mutationHooks";
import { updateBasicProductStart } from "sagas/productsDetails/singleProductDetails.slice";

import "../ProductDetailsCommonModal.scss";
import ReturnToGroup from '../../Components/Categorycomponents/ReturnTo/ReturnToGroup';
const ProductDetailsOverviewModals = ({
    open,
    onClose,
    statusValue,
    setStatusBox,
    productStatusOptions,
    statusBox,
    handleSelectStatus,
    updateBasicProduct,
    getCategoriesMenuProductData,
    getSingleProductData,
    distributor,
    getNewProductManufacturerData,
    getNewProductVendorData,
    getNewProductGroupData,
    focusText,
    removeFocus
}) => {
    const dispatch = useDispatch();

    const {
        updateBasicProductsMutation,
    } = useProductsDetailsHooks();
    //getCategoriesMenuProductData  
    const { details, availability } = getSingleProductData || {}
    const { productId, productName, description, manufacturerCode, manufacturerId, vendorId, categoryId, productGroupId, activeFlag } = details || {}
    const { availability: available, daysToShip, qtyInStock } = availability || {};
    const [isEqual, setIsEqual] = useState(false);


    const isNotSetInactive = (activeFlag === 1 && qtyInStock > 0);
    const [returnTo, setReturnTo] = useState({
        row: { categoryId: '', categoryName: '' },
        unit: { categoryId: '', categoryName: '' },
        shelf: { categoryId: '', categoryName: '' },
    });
    const [returnInitTo, setReturnInitTo] = useState({
        row: { categoryId: '', categoryName: '' },
        unit: { categoryId: '', categoryName: '' },
        shelf: { categoryId: '', categoryName: '' },
    });

    useEffect(() => {
        const isEqual = deepEqual(returnTo, returnInitTo);
        setIsEqual(isEqual);
        if (statusValue?.id !== activeFlag) {
            setIsEqual(false)
        } else {
            setIsEqual(true)
        }
    }, [returnTo, returnInitTo, statusValue]);

    const findIds = (data, targetId) => {
        for (const item of data) {
            if (item.categoryId === targetId) {
                return { parentId: item?.categoryId, parentName: item?.categoryName, childId: "", childName: "", innerChildId: "", innerChildName: "" };
            }
            if (item.children) {
                for (const childItem of item.children) {
                    if (childItem.categoryId === targetId) {
                        return { parentId: item?.categoryId, parentName: item?.categoryName, childId: childItem?.categoryId, childName: childItem?.categoryName, innerChildId: "", innerChildName: "" };
                    }
                    if (childItem.children) {
                        for (const innerChildItem of childItem.children) {
                            if (innerChildItem.categoryId === targetId) {
                                return { parentId: item?.categoryId, parentName: item?.categoryName, childId: childItem?.categoryId, childName: childItem?.categoryName, innerChildId: innerChildItem?.categoryId, innerChildName: innerChildItem?.categoryName };
                            }
                        }
                    }
                }
            }
        }
        return null;
    }

    const initalCategory = () => {
        const obj = {
            row: { categoryId: '', categoryName: '' },
            unit: { categoryId: '', categoryName: '' },
            shelf: { categoryId: '', categoryName: '' },
        }
        setReturnTo(obj);
        setReturnInitTo(obj);
    }
    // set an initial line if returnTo is empty
    useEffect(() => {
        if (categoryId && getCategoriesMenuProductData) {
            const result = findIds(getCategoriesMenuProductData, categoryId);
            if (result) {
                const obj = {
                    row: { categoryId: result?.parentId, categoryName: result?.parentName },
                    unit: { categoryId: result?.childId, categoryName: result?.childName },
                    shelf: { categoryId: result?.innerChildId, categoryName: result?.innerChildName },
                }
                setReturnTo(obj);
                setReturnInitTo(obj);
            } else {
                initalCategory()
            }
        } else {
            initalCategory()
        }
    }, []);

    useEffect(() => {
        if (!getNewProductGroupData?.length) {
            dispatch(newProductGroupRequest());
        }
        if (!getNewProductManufacturerData?.length) {
            dispatch(newProductManufacturerRequest());
        }
        if (!getNewProductVendorData?.length) {
            dispatch(newProductVendorRequest());
        }
    }, [])

    const handleClose = (resetForm) => () => {
        resetForm();
        onClose();
        initalCategory();
    }

    const deepEqual = (obj1, obj2) => {
        if (obj1 === obj2) return true;

        if (typeof obj1 !== 'object' || typeof obj2 !== 'object') return false;

        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        if (keys1.length !== keys2.length) return false;

        for (const key of keys1) {
            if (!keys2.includes(key)) return false;

            if (!deepEqual(obj1[key], obj2[key])) return false;
        }

        return true;
    }

    return (
        <Formik
            initialValues={{
                manufacturerId: manufacturerId,
                productName: productName,
                description: description ? description : null,
                manufacturerCode: manufacturerCode,
                vendorId: vendorId,
                categoryId: categoryId,
                productGroupId: productGroupId || "",
                dayToShipId: daysToShip,
                availableId: available
            }}
            validationSchema={UpdatedBasicProductDetailSchema}
            enableReinitialize
            onSubmit={(values) => {
                const decodedData = authorizationToken("Authorization");
                let categoryID = categoryId;

                if (returnTo?.shelf && returnTo?.shelf?.categoryId) {
                    categoryID = returnTo?.shelf?.categoryId;
                } else if (returnTo?.unit && returnTo?.unit?.categoryId) {
                    categoryID = returnTo?.unit?.categoryId;
                } else if (returnTo?.row && returnTo?.row?.categoryId) {
                    categoryID = returnTo?.row?.categoryId;
                } else {
                    categoryID = categoryId;
                }
                const { dayToShipId, availableId, description, manufacturerId, vendorId, manufacturerCode, productName, productGroupId } = values
                const body = {
                    productId: productId,
                    handlerId: decodedData?.DefaultLoginId,
                    categoryId: categoryID ? categoryID : categoryId,
                    productGroupId: productGroupId ? productGroupId : null,
                    activeFlag: statusValue?.id,
                    manufacturerId,
                    vendorId,
                    manufacturerCode,
                    productName,
                    description,
                    availability: Number(availableId),
                    daysToShip: Number(dayToShipId),
                }
                dispatch(updateBasicProductStart())
                updateBasicProductsMutation.mutate(body);
            }}
        >
            {({ values, setFieldValue, resetForm, handleSubmit, dirty }) => {
                const { manufacturerCode, manufacturerId, productName, description, vendorId, productGroupId, dayToShipId, availableId } = values
                const { Category, code, name, descriptionTitle, manufacturer, vendor, status, DaysToShip, Availability, } = sectionTitle
                const { group, Back, SaveChanges } = labels;
                const { DetailsOption } = sectionName
                return (
                    <>
                        <Dialog
                            className='overview-dialog'
                            open={open}
                            onClose={onClose}
                        >
                            <div className="dialog-header">
                                <h3>{DetailsOption}</h3>
                                <IconButton
                                    classes='close-btn'
                                    secondary={true}
                                    title='Close'
                                    icon='close'
                                    onClick={handleClose(resetForm)}
                                />
                            </div>
                            <DialogContent>
                                <div className="product-detail-section-bottom-border mb-4 pb-4">
                                    <div className="notes-added-user-name">
                                        <span>
                                            {code}
                                        </span>
                                    </div>
                                    <div className="characteristics-main-info">
                                        <div className="w-100">
                                            <PosInput
                                                variant="filled"
                                                id={code}
                                                type="text"
                                                className={`product-detail-search-input input-focus-styling`}
                                                name="manufacturerCode"
                                                value={manufacturerCode}
                                                onChange={(e) => {
                                                    setFieldValue("manufacturerCode", e.target.value);
                                                }}
                                                InputProps={{
                                                    disableUnderline: true,
                                                }}
                                                inputRef={i => i && focusText === code && setFocusTo(i, removeFocus)}
                                            />
                                        </div>
                                    </div>
                                    <ErrorMessage name="manufacturerCode" />
                                </div>
                                <div className="product-detail-section-bottom-border mb-4 pb-4">
                                    <div className="notes-added-user-name">
                                        <span>
                                            {name}
                                        </span>
                                    </div>
                                    <div className="characteristics-main-info">
                                        <div className="w-100">
                                            <PosInput
                                                variant="filled"
                                                id={name}
                                                type="text"
                                                className={`product-detail-search-input input-focus-styling`}
                                                name="productName"
                                                value={productName}
                                                onChange={(e) => {
                                                    setFieldValue("productName", e.target.value);
                                                }}
                                                InputProps={{
                                                    disableUnderline: true,
                                                }}
                                                inputRef={i => i && focusText === name && setFocusTo(i, removeFocus)}
                                            />
                                        </div>
                                    </div>
                                    <ErrorMessage name="productName" />
                                </div>
                                <div className="product-detail-section-bottom-border mb-4 pb-4">
                                    <div className="notes-added-user-name">
                                        <span>
                                            {descriptionTitle}
                                        </span>
                                    </div>
                                    <div className="characteristics-main-info">
                                        <div className="w-100">
                                            <PosInput
                                                variant="filled"
                                                id={descriptionTitle}
                                                InputProps={{
                                                    disableUnderline: true,
                                                }}
                                                type="text"
                                                className="customer-company-address input-focus-styling"
                                                name="description"
                                                value={description}
                                                maxRows={1}
                                                onChange={(e) => {
                                                    setFieldValue("description", e.target.value);
                                                }}
                                                multiline
                                                rows={8}
                                                inputRef={i => i && focusText === descriptionTitle && setFocusTo(i, removeFocus)}
                                            />
                                        </div>
                                    </div>
                                    <ErrorMessage name="description" />
                                </div>
                                <div className="product-detail-section-bottom-border mb-4 pb-4">
                                    <div className="notes-added-user-name">
                                        <span>
                                            {status}
                                        </span>
                                    </div>
                                    <div className="characteristics-main-info">
                                        <ProductStatusDetail
                                            statusValue={statusValue}
                                            setStatusBox={setStatusBox}
                                            id={status}
                                            productStatusOptions={isNotSetInactive ? productStatusNoInactiveOptions : productStatusOptions}
                                            statusBox={statusBox}
                                            handleSelectStatus={handleSelectStatus}
                                            inputRef={i => i && focusText === status && setFocusTo(i, removeFocus)}
                                            productId={productId}
                                            distributor={distributor}
                                            activeFlag={activeFlag}
                                        />
                                    </div>
                                    <div className="small-text gray-text">
                                        <span className="ml-0">{statusValue?.subLabel}</span>
                                    </div>
                                </div>
                                <div className="product-detail-section-bottom-border mb-4 pb-4">
                                    <div className="notes-added-user-name d-flex align-items-center justify-content-between">
                                        <span>
                                            {manufacturer}
                                        </span>
                                        <a href={`${distributor.httpsUrl}${addManufactureLink}`} target="_blank" className="side-link">Add Manufacturer</a>
                                    </div>
                                    <div className="characteristics-main-info">
                                        <div className="w-100">
                                            <PosSelect
                                                isSearch
                                                id={manufacturer}
                                                className=" product-detail-select"
                                                name="manufacturerId"
                                                variant="filled"
                                                value={manufacturerId}
                                                defaultValue={manufacturerId}
                                                onChange={(e) => {
                                                    setFieldValue("manufacturerId", e.target.value);
                                                }}
                                                inputRef={i => i && focusText === manufacturer && setFocusTo(i, removeFocus)}
                                                options={
                                                    getNewProductManufacturerData?.length &&
                                                    getNewProductManufacturerData?.map(({ companyName, companyId }) => {
                                                        return {
                                                            label: companyName,
                                                            value: companyId,
                                                        };
                                                    })
                                                } />
                                        </div>
                                    </div>
                                    <ErrorMessage name="manufacturerId" />
                                </div>
                                <div className="product-detail-section-bottom-border mb-4 pb-4">
                                    <div className="notes-added-user-name d-flex align-items-center justify-content-between">
                                        <span>
                                            {vendor}
                                        </span>
                                        <a href={`${distributor.httpsUrl}${addVendorLink}`} target="_blank" className="side-link">Add Vendor </a>
                                    </div>
                                    <div className="characteristics-main-info">
                                        <div className="w-100" id={vendor}>
                                            <PosSelect
                                                isSearch
                                                className=" product-detail-select"
                                                name="vendorId"
                                                variant="filled"
                                                defaultValue={vendorId}
                                                value={vendorId}
                                                onChange={(e) => {
                                                    setFieldValue("vendorId", e.target.value);
                                                }}
                                                options={
                                                    getNewProductVendorData?.length &&
                                                    getNewProductVendorData.map(({ companyName, companyId }) => {
                                                        return {
                                                            label: companyName,
                                                            value: companyId,
                                                        };
                                                    })
                                                }
                                                inputRef={i => i && focusText === vendor && setFocusTo(i, removeFocus)}
                                            />
                                        </div>
                                    </div>
                                    <ErrorMessage name="vendorId" />
                                </div>
                                <div className="product-detail-section-bottom-border mb-4 pb-4">
                                    <div className="notes-added-user-name">
                                        <span>
                                            {group}
                                        </span>
                                    </div>
                                    <div className="characteristics-main-info">
                                        <div className="w-100">
                                            <PosSelect
                                                isSearch
                                                className=" product-detail-select"
                                                name="productGroupName"
                                                variant="filled"
                                                id={group}
                                                defaultValue={productGroupId}
                                                value={productGroupId}
                                                onChange={(e) => {
                                                    setFieldValue("productGroupId", e.target.value);
                                                }}
                                                options={
                                                    getNewProductGroupData?.length &&
                                                    getNewProductGroupData?.map(({ name, id }) => {
                                                        return {
                                                            label: name,
                                                            value: id,
                                                        };
                                                    })
                                                }
                                                inputRef={i => i && focusText === group && setFocusTo(i, removeFocus)}
                                            />
                                        </div>
                                        <ErrorMessage name="productGroupId" />
                                    </div>
                                    {/* Might need later  <div className="no-pricing-schedule-sec">
                                    {!isOnPriceSchedule ?
                                        <div className="d-flex align-items-center mt-2">
                  <MaterialIcon icon="warning" size="20" classes="mr-1 product-detail-warning-color"/>
                  <span>{noPriceSchedule}</span>
                </div> 
                                        : null}
                                </div> */}
                                </div>
                                <div className="product-detail-section-bottom-border mb-4 pb-4">
                                    <div className="notes-added-user-name">
                                        <span>
                                            {Category}
                                        </span>
                                    </div>
                                    <div className="characteristics-main-info">
                                        <div className="w-100" id={Category} ref={i => i && focusText === Category && setFocusTo(i, removeFocus)}>
                                            <ReturnToGroup categoryMenu={getCategoriesMenuProductData}
                                                returnTo={returnTo} setReturnTo={setReturnTo}
                                            />
                                        </div>
                                        <div className="small-text gray-text mt-2">
                                            <span className="ml-0">Resets to root category when the product is set as Pending</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="product-detail-section-bottom-border mb-4 pb-4">
                                    <div className="notes-added-user-name">
                                        <span>
                                            {Availability}
                                        </span>
                                    </div>
                                    <div className="characteristics-main-info">
                                        <div className="w-100">
                                            <PosInput
                                                variant="filled"
                                                id={Availability}
                                                type="number"
                                                className="product-detail-search-input input-focus-styling"
                                                value={availableId}
                                                onChange={(e) => {
                                                    setFieldValue('availableId', e.target.value);
                                                }}
                                                InputProps={{
                                                    disableUnderline: true,
                                                }}
                                                onKeyDown={(e) => blockInvalidChar(e)}
                                                onWheel={(e) => (e?.target)?.blur()}
                                                inputRef={i => i && focusText === Availability && setFocusTo(i, removeFocus)}
                                            />
                                        </div>
                                    </div>
                                    <ErrorMessage name="availableId" />
                                </div>
                                <div className="mb-4 pb-4">
                                    <div className="notes-added-user-name">
                                        <span>
                                            {DaysToShip}
                                        </span>
                                    </div>
                                    <div className="characteristics-main-info">
                                        <div className="w-100">
                                            <PosInput
                                                variant="filled"
                                                id={DaysToShip}
                                                type="number"
                                                className="product-detail-search-input input-focus-styling"
                                                value={dayToShipId}
                                                onChange={(e) => {
                                                    setFieldValue('dayToShipId', e.target.value);
                                                }}
                                                InputProps={{
                                                    disableUnderline: true,
                                                }}
                                                onKeyDown={(e) => blockInvalidChar(e)}
                                                onWheel={(e) => (e?.target)?.blur()}
                                                inputRef={i => i && focusText === DaysToShip && setFocusTo(i, removeFocus)}
                                            />
                                        </div>
                                    </div>
                                    <ErrorMessage name="dayToShipId" />
                                </div>
                            </DialogContent>
                            <DialogActions className="notes-model-footer">
                                <PosButton
                                    variant="contained"
                                    onClick={handleClose(resetForm)}
                                    className="back-button first-product-detail-btn"
                                >
                                    {Back}
                                </PosButton>
                                <PosButton
                                    fontWeight
                                    className={`btn ml-2 second-product-detail-btn ${updateBasicProduct ? "button-accent button-is-loading-black" : ""}`}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleSubmit()}
                                    disabled={updateBasicProduct || (!dirty && isEqual)}
                                >
                                    {SaveChanges}
                                </PosButton>
                            </DialogActions>
                        </Dialog>
                    </>
                );
            }}
        </Formik>
    );
}

export default ProductDetailsOverviewModals;