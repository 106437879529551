import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PosButton, PosImage, PosInput } from "apps/pos/Common/uiCore";
import AddDocumentModal from "./addDocument/AddDocumentModal";
import OpenDocumentModal from "./openDocument/openDocumentModal";

import { documentByModule } from "constants/enum";
import { AddIcon, InputAdornment, SearchIcon } from "constants/icons";
import images from "constants/images";
import {
  PosFlowDataProp,
  UploadedDocumentItemProp,
} from "constants/interfaces";
import { CustomerDocumentData, PosResponseProps, RootState } from "models";
import { keys } from "utils/helper";
import { scrollToSetting } from "utils/utils";

import { setPosFlowData } from "sagas/persistReducer/actions";
import { arrowDownIndexGeneric, arrowResetGeneric, arrowUpIndexGeneric } from "sagas/pos/actions";

import { staticData } from "constants/constant";
import { customerDocumentRequest, taxDocumentRequest } from "sagas/pos/documentUpload/actions";
import "./document.scss";
import ListDocument from "./listDocument";

const Document = ({ savedOrderFoundInfoRef }) => {

  const { posFlowData, isPageReload } = useSelector<RootState, PosFlowDataProp>(
    (state) => state.persistReducer
  );
  const { customerDocumentDetails, isLoading, taxDocumentDetails, isTaxDocLoading } = useSelector<
    RootState,
    CustomerDocumentData
  >((state) => state.documentReducer);
  const { selectedIndexGeneric } = useSelector<RootState, PosResponseProps>(
    (state) => state.pos
  );

  const searchCustomersDocumet = useRef<HTMLInputElement>();

  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState("");
  const [openModel, setOpenModel] = useState(false);
  const [openDocumentModal, setDocumentOpenModal] = useState(false);
  const [documentListItem, setDocumentListItem] = useState([]);
  const [deleteDocumentData, setDeleteDocumentData] = useState(false);
  const [keyIndex, setkeyIndex] = useState("");
  const [documentDataObj, setDocumentDataObj] = useState(null);
  const { noDocumentIcon } = images;
  const [filteredCustomerDocumentDetails, setFilteredCustomerDocumentDetails] = useState(customerDocumentDetails);
  const [filteredTaxDocumentDetails, setFilteredTaxDocumentDetails] = useState(taxDocumentDetails);
  const [downPress, setDownPress] = useState(false);
  const [upPress, setUpPress] = useState(false);

  useEffect(() => {
    setFilteredCustomerDocumentDetails(customerDocumentDetails);
    setFilteredTaxDocumentDetails(taxDocumentDetails);
  }, [customerDocumentDetails, taxDocumentDetails]);

  useEffect(() => {
    dispatch(setPosFlowData(posFlowData))
    dispatch(
      customerDocumentRequest({
        customerId: Number(posFlowData?.customer?.customerId),
        buildingId: 0,
        orderId: 0,
      })
    );
    dispatch(taxDocumentRequest(Number(posFlowData?.customer?.companyID)));
  }, [])

  useEffect(() => {
    if (isPageReload) {
      // setOpenModal(true);
    }
  }, [isPageReload])


  const isGenericUser = useMemo(() => {
    return posFlowData?.customer ? posFlowData?.customer.isPosGeneric : false;
  }, [posFlowData]);

  useEffect(() => {
    if (document?.activeElement === searchCustomersDocumet.current) {
      dispatch(arrowResetGeneric());
    }
  }, [])

  const { shared, all, current } = documentByModule;

  useEffect(() => {
    if (customerDocumentDetails?.length || taxDocumentDetails?.length) {
      const readOnlyDocument = [];
      for (let i = 0; i < customerDocumentDetails?.length; i++) {
        const { objectId, description, documentSaveType, typeName, name, url, fileSizeText, id, modifiedAt, modifiedBy } = customerDocumentDetails[i];
        const read = {};
        read["customerId"] = objectId;
        read["description"] = description;
        read["documentSaveType"] = documentSaveType;
        read["documentSaveTypeName"] = typeName;
        read["file"] = name;
        read["fileUrl"] = url;
        read["locationSaveType"] =
          documentSaveType === shared
            ? all
            : current;
        read["size"] = fileSizeText;
        read["typeId"] = id;
        read["uploadedBy"] = modifiedBy;
        read["readOnly"] = true;
        read["lastModified"] = modifiedBy;
        read["modifiedAt"] = modifiedAt;
        readOnlyDocument.push(read);
      }
      for (let i = 0; i < taxDocumentDetails?.length; i++) {
        const { buyerCompanyID, docDec, docType, typeName, certificateName, docURL, docSize, companyDocID, createdDate } = taxDocumentDetails[i];
        const read = {};
        read["customerId"] = buyerCompanyID;
        read["description"] = docDec;
        read["documentSaveType"] = docType;
        read["documentSaveTypeName"] = typeName;
        read["file"] = certificateName;
        read["fileUrl"] = docURL;
        read["locationSaveType"] =
          docType === shared
            ? all
            : current;
        read["size"] = docSize;
        read["typeId"] = companyDocID;
        read["uploadedBy"] = createdDate;
        read["readOnly"] = true;
        read["lastModified"] = createdDate;
        read["modifiedAt"] = createdDate;
        readOnlyDocument.push(read);
      }
      const uploadData = [];
      posFlowData?.addDocument?.length &&
        posFlowData?.addDocument.map((item: UploadedDocumentItemProp) => {
          const { originalFile } = item;
          if (originalFile) {
            uploadData.push(item);
          }
        });

      const allDoc = readOnlyDocument.concat(uploadData);

      const data = {
        ...posFlowData,
        addDocument: allDoc,
      }

      dispatch(setPosFlowData(data));
    }
  }, [customerDocumentDetails, taxDocumentDetails]);

  const handleClick = (_, modifiedAt) => {
    const timeStampModify = new Date(modifiedAt).getTime();
    setkeyIndex(timeStampModify.toString());
  };

  function removeDocumentItem(indexNumber) {
    const newDocumentList = documentListItem?.filter(
      (data) => new Date(data?.modifiedAt).getTime().toString() !== indexNumber
    );
    setDocumentListItem(newDocumentList);
    const data = {
      ...posFlowData,
      addDocument: newDocumentList,
    };
    dispatch(setPosFlowData(data));
    setDeleteDocumentData(false);
  }

  const handleCallback = () => {
    // Required Later
  };

  const handleKeyPress = (e) => {
    const { keyCode } = e;
    const { downarrow, uparrow, enter, tab } = keys
    const isButtonFocused = document.activeElement.tagName.toLowerCase() === 'button'
    if (keyCode === enter && !isButtonFocused) {
      const element = document.querySelector('.Mui-focusVisible');
      if (element) {
        return
      }
      else if (selectedIndexGeneric > -1) {
        const item = documentListItem[selectedIndexGeneric]
        const date = new Intl.DateTimeFormat("en-GB", {
          year: "numeric",
          month: "short",
          day: "2-digit",
        }).format(item?.lastModified);
        handleDraft(item, selectedIndexGeneric, date)
      }
    }
    if (keyCode === downarrow && !savedOrderFoundInfoRef.current && !openModel && !openDocumentModal) {
      setDownPress(true);
    } else if (keyCode === uparrow && !savedOrderFoundInfoRef.current && !openDocumentModal) {
      setUpPress(true);
    }
    if (keyCode === tab) {
      dispatch(arrowResetGeneric())
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return function cleanup() {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [selectedIndexGeneric, openModel, openDocumentModal]);

  useEffect(() => {
    async function downPressHandler() {
      if (downPress) {
        scrollToSetting(selectedIndexGeneric);
        if (documentListItem?.length) {
          await dispatch(arrowDownIndexGeneric(documentListItem.length));
        }
        setDownPress(false);
      }
    }
    downPressHandler()
  }, [downPress]);

  useEffect(() => {
    async function upPressHandler() {
      if (upPress) {
        await dispatch(arrowUpIndexGeneric());
        if (selectedIndexGeneric > -1) {
          scrollToSetting(selectedIndexGeneric);
        }
        setUpPress(false);
      }
    }
    upPressHandler()

  }, [upPress]);


  const handleDraft = (item, index, date) => {
    handleClick(index, item?.modifiedAt);
    setDocumentOpenModal(true);
    setDocumentDataObj({ item, date });
  }

  const handleChange = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);

    // Filter customer documents
    const filteredCustomerDocuments = customerDocumentDetails.filter(doc =>
      doc.name.toLowerCase().includes(value) ||
      doc.typeName.toLowerCase().includes(value)
    );

    // Filter tax documents
    const filteredTaxDocuments = taxDocumentDetails.filter(doc =>
      doc.certificateName.toLowerCase().includes(value) ||
      doc.companyName.toLowerCase().includes(value)
    );

    // Update state with filtered documents
    setFilteredCustomerDocumentDetails(filteredCustomerDocuments);
    setFilteredTaxDocumentDetails(filteredTaxDocuments);
  };

  return (
    <section className="document-main-section">
      <div className="document-container">
        {
          isGenericUser ?
            <div className="mt-5 text-center row justify-content-center">
              <PosImage
                src={noDocumentIcon}
                height={100}
                width={100}
                alt="add product"
              />
              <span className="int-20-reg">No document uploaded</span>
            </div>
            :
            isLoading || isTaxDocLoading ?
              <ListDocument isLoading={true} /> :
              (filteredCustomerDocumentDetails?.length > 0 || filteredTaxDocumentDetails?.length > 0 || searchTerm) ?
                <>
                  <div className="input-with-add-button" key="document_list">
                    <PosInput
                      variant="filled"
                      id="filled-basic"
                      type="text"
                      className="document-search-input search-input-focus"
                      autoFocus
                      inputRef={searchCustomersDocumet}
                      placeholder={staticData.documentSearchPlaceholder}
                      value={searchTerm}
                      onChange={handleChange}
                      InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                    {!isGenericUser ? (
                      <PosButton
                        className="add-document-main-btn cust-info-add-doc-btn btn"
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => {
                          setOpenModel(true);
                        }}
                      >
                        Add Document
                      </PosButton>
                    ) : null}
                  </div>
                  <ListDocument isLoading={false} customerDocumentDetails={filteredCustomerDocumentDetails} taxDocumentDetails={filteredTaxDocumentDetails} searchTerm={searchTerm} handleDraft={handleDraft} />
                </>
                :
                <div className="mt-5 text-center row justify-content-center">
                  <PosImage
                    src={noDocumentIcon}
                    height={100}
                    width={100}
                    alt="add product"
                  />
                  <span className="int-20-reg">Drag and Drop a document here</span>
                  <span className="int-20-reg">or click the button below to add</span>
                  <div className="d-flex justify-content-center mt-4">
                    <PosButton
                      className="add-document-main-btn cust-info-add-doc-btn btn"
                      variant="contained"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        setOpenModel(true);
                      }}
                    >
                      Add Document
                    </PosButton>
                  </div>
                </div>
        }
      </div>
      {posFlowData?.addDocument?.length > 0 && (
        <OpenDocumentModal
          readOnlyInfo={true}
          open={openDocumentModal}
          setOpenModel={setDocumentOpenModal}
          keyIndex={keyIndex}
          setDeleteDocumentData={setDeleteDocumentData}
          deleteDocumentData={deleteDocumentData}
          removeDocumentItem={removeDocumentItem}
          fileData={documentDataObj}
          handleCallback={handleCallback}
          taxExemption={false}
        />
      )}
      {openModel ? (
        <AddDocumentModal
          openModel={openModel}
          setOpenModel={setOpenModel}
          taxExemption={false}
          documentBy={documentByModule.customerList}
        />
      ) : null}

      {/* <DocReuploadModal uplodedDocs={toBeuploadedFiles} open={openModal} closeModal={closeAlertModal} /> */}
    </section>
  );
};

export default Document;
