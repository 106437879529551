import jwt_decode from "jwt-decode";
import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Cookies from "universal-cookie";

import { AddUserForm, ManageUserModal } from "apps/admin";
import Button from "apps/admin/components/Elements/Button";
import { Users } from "./User";

import {
  Tab,
  TabNavigationAdmin
} from "components";
import { adminTitles } from "constants/constant";
import { adminMessage } from "constants/message";
import { UserListHeader } from "constants/styles";
import { Paths, ResponceStatus, Tabs } from "enums";
import { RootState, User, user } from "models";
import { UpdateModalContent } from "sagas/modal/actions";
import { snakbarShow } from "sagas/pos/snakbar/snakbar.slice";
import {
  getAllUsers,
  getAwaitingApprovalUsers,
  getInvitedUsers,
  resetUserFlag,
  reviewUserStart,
  searchValue,
  setEditUserDetailFalse,
  setLoginUser
} from "sagas/user/actions";
import { getLoggedInUser } from "services";

import "styles/admin/UserList/UserListStyle.css";
import "styles/common.css";
import "./UserList.scss";


export function UserList() {
  const dispatch = useDispatch();
  const [usersListData, setUserListData] = useState<User[]>();
  const [awaitingApprovalUserListData, setAwaitingApprovalUserListData] =
    useState<User[]>();
  const [invitedUserListData, setInvitedUserListData] = useState<User[]>();
  const [page, setPage] = useState(1);
  const [pageAwait, setPageAwait] = useState(1);
  const [pageInvite, setPageInvite] = useState(1);
  const [usersCount, setuserscount] = useState(1);
  const [awaitingApprovalCount, setAwaitingApprovalCount] = useState(0);
  const [invitedCount, setInvitedCount] = useState(0);
  const [selectedTab, setSelectedTab] = useState(1);

  const { allUsersData, awaitingApprovalData, invitedUserData, loggedInUser, editUserSuccess, editUserPermissionSuccess, searchUser, searchUserId, error: errorMessage } = useSelector<
    RootState,
    user
  >((state) => state.user);
  const [loginUser, setLoginUsers] = useState<User>();


  const { tabIndexFirst, tabIndexTwo, tabIndexThree } = Tabs;
  const navigate = useNavigate();

  const params: any = useParams();

  const cookies = new Cookies();
  const token = cookies.get("Authorization");
  let decodedData = null;
  let decoded = null;

  if (token) {
    decoded = jwt_decode(token || "");
    decodedData = JSON.parse(decoded?.id);
  }
  useEffect(() => {
    if (Object.keys(loggedInUser).length) {
      setLoginUsers(loggedInUser);
      getApiCall(loggedInUser)
    }
  }, [loggedInUser]);

  const getApiCall = (user) => {
    if (user) {
      const userPermissionsCheck = user?.permissions.userAdminPermissions;
      if (userPermissionsCheck.canView === 0) {
        const tempArray = [user];
        setUserListData(tempArray);
      }
      if (userPermissionsCheck.canReview === 1) {
        dispatch(getAwaitingApprovalUsers(pageAwait));
      }
      if (userPermissionsCheck.canInvite === 1) {
        dispatch(getInvitedUsers(pageInvite));
      }
    }
  }

  const updateCurrentUserDetail = () => {
    getLoggedInUser(decodedData?.DefaultLoginId).then(async (response) => {
      getApiCall(response)
      await dispatch(setLoginUser(response));
      await setLoginUsers(response);
    });
  }
  const { profileUpdate, failProfileUpdate } = adminMessage;
  const { success, error } = ResponceStatus;

  useEffect(() => {
    if (editUserSuccess === success) {
      dispatch(snakbarShow({ message: profileUpdate }))
      dispatch(
        UpdateModalContent({
          isOpen: false,
          children: null,
          fixedModal: true,
          removePaddingBottom: false,
          noMaxWidth: false
        })
      );
      dispatch(resetUserFlag());
      navigate(-1);
      if (!searchUser && loginUser?.permissions?.userAdminPermissions?.canView === 1) {
        if (Number(loggedInUser.personId) === Number(searchUserId)) {
          updateCurrentUserDetail();
        }
        dispatch(getAllUsers(page));
      } else {
        if (!searchUser && loginUser?.permissions?.userAdminPermissions?.canView === 0) {
          updateCurrentUserDetail();
        }
      }
      dispatch(setEditUserDetailFalse());
    }
    if (editUserSuccess === error) {
      dispatch(snakbarShow({ message: failProfileUpdate }))
      dispatch(
        UpdateModalContent({
          isOpen: false,
          children: null,
          fixedModal: true,
          removePaddingBottom: false,
          noMaxWidth: false
        })
      );
      dispatch(resetUserFlag());
    }
  }, [editUserSuccess]);

  useEffect(() => {
    if (editUserPermissionSuccess === success) {
      dispatch(snakbarShow({ message: profileUpdate }))
      dispatch(
        UpdateModalContent({
          isOpen: false,
          children: null,
          fixedModal: true,
          removePaddingBottom: true,
          noMaxWidth: false
        })
      );
      dispatch(resetUserFlag());
      navigate(-1);
      if (!searchUser && loginUser?.permissions?.userAdminPermissions?.canView === 1) {
        dispatch(getAllUsers(page));
      } else {
        if (!searchUser && loginUser?.permissions?.userAdminPermissions?.canView === 0) {
          updateCurrentUserDetail();
        }
      }
      dispatch(setEditUserDetailFalse());
    }
    if (editUserPermissionSuccess === error) {
      dispatch(snakbarShow({ message: errorMessage }))
      dispatch(
        UpdateModalContent({
          isOpen: false,
          children: null,
          fixedModal: true,
          removePaddingBottom: false,
          noMaxWidth: false
        })
      );
      dispatch(resetUserFlag());
    }
  }, [editUserPermissionSuccess]);


  useEffect(() => {
    const { pathname } = location;
    if (pathname === `${Paths.adminUsers}/${params.id}`) {
      searchUserDetail(params.id)
    }
  }, [loginUser]);

  const mangeUserModel = (user) => {
    const isUser = loginUser?.personId === params.id;

    if (loginUser?.permissions?.userAdminPermissions?.canView === 1) {
      if (params.id === loginUser.personId) {
        dispatch(
          UpdateModalContent({
            isOpen: true,
            isAdmin: true,
            classModal: "user-profile-dialog",
            url: Paths.adminUsers,
            children: (
              <ManageUserModal
                isUser={isUser}
                user={user}
                isApproval={false}
                loginUser={loginUser}
                page={page}
                url={Paths.adminUsers}
              />
            ),
            modalSize: "xl",
            fixedModal: true,
            removePaddingBottom: false,
            noMaxWidth: false
          })
        );
      } else {
        dispatch(
          UpdateModalContent({
            isOpen: true,
            isAdmin: true,
            classModal: "user-profile-dialog",
            url: Paths.adminUsers,
            children: (
              <ManageUserModal
                isUser={isUser}
                user={user}
                isApproval={false}
                loginUser={loginUser}
                page={page}
                url={Paths.adminUsers}
              />
            ),
            modalSize: "xl",
            fixedModal: true,
            removePaddingBottom: false,
            noMaxWidth: false
          })
        );
      }
    }
  }

  const searchUserDetail = (userId) => {
    getLoggedInUser(userId).then((response) => {
      mangeUserModel(response);
    });
  }

  const location = useLocation();
  useEffect(() => {
    //TODO: react
    const { pathname } = location;
    if (params?.id && pathname === `${Paths.adminUsers}/${params?.id}/${Paths.adminReviewIdUsers}`) {
      setSelectedTab(tabIndexThree);
      dispatch(reviewUserStart(params?.id));
    }
  }, [dispatch, params?.id]);

  useEffect(() => {
    setUserListData(allUsersData?.data?.slice(0, 10));
    setuserscount(allUsersData?.total);
    if (allUsersData?.count == 0 && selectedTab == tabIndexFirst) {
      setPage(1);
    }
  }, [allUsersData?.data]);

  useEffect(() => {
    if (!awaitingApprovalData?.total && selectedTab === tabIndexThree && loginUser && page) {
      setSelectedTab(tabIndexFirst);
      const userPermissionsCheck = loginUser?.permissions.userAdminPermissions;
      if (userPermissionsCheck.canView === 1) {
        dispatch(getAllUsers(page));
        setUserListData(allUsersData?.data?.slice(page * 10 - 10, page * 10));
      }
    }
    if (selectedTab == tabIndexFirst && loginUser && page) {
      const userPermissionsCheck = loginUser?.permissions.userAdminPermissions;
      if (userPermissionsCheck.canView === 1) {
        dispatch(getAllUsers(page));
        setUserListData(allUsersData?.data?.slice(page * 10 - 10, page * 10));
      }
    }
  }, [loginUser, page]);

  useEffect(() => {
    setAwaitingApprovalUserListData(awaitingApprovalData?.data?.slice(0, 10));
    setAwaitingApprovalCount(awaitingApprovalData?.total);
    if (!awaitingApprovalData?.total && selectedTab === tabIndexThree) {
      setSelectedTab(tabIndexFirst);
      setPage(1)
    }
    if (!awaitingApprovalData?.count && selectedTab === tabIndexThree) {
      setPage(1);
    }
    if (window.location.pathname === Paths.adminReviewUsers || window.location.pathname === `${Paths.adminUsers}/${params?.id}/${Paths.adminReviewIdUsers}`) {
      awaitingApprovalData?.data?.length === 0 && navigate(Paths.adminUsers);
    }
  }, [awaitingApprovalData?.total]);

  useEffect(() => {
    if (selectedTab == tabIndexThree && loginUser && pageAwait) {
      const userPermissionsCheck = loginUser?.permissions.userAdminPermissions;
      if (userPermissionsCheck.canReview === 1) {
        const { pathname } = location;
        if (pathname !== `${Paths.adminUsers}/${params?.id}/${Paths.adminReviewIdUsers}`) {
          dispatch(getAwaitingApprovalUsers(pageAwait));
        }
        setAwaitingApprovalUserListData(
          awaitingApprovalData?.data?.slice(pageAwait * 10 - 10, pageAwait * 10)
        );
      }
    }
  }, [loginUser, pageAwait]);

  useEffect(() => {
    setInvitedUserListData(invitedUserData?.data?.slice(0, 10));
    setInvitedCount(invitedUserData?.total);
    if (!invitedUserData?.total && selectedTab == tabIndexTwo) {
      setSelectedTab(tabIndexFirst);
      setPage(1)
    }
    if (window.location.pathname === Paths.adminInvitedUsers) {
      invitedUserData?.data?.length === 0 && navigate(Paths.adminUsers);
    }

    if (selectedTab == tabIndexTwo) {
      dispatch(getInvitedUsers(pageInvite));
    }
  }, [invitedUserData?.total]);

  useEffect(() => {
    if (selectedTab == tabIndexTwo && loginUser && pageInvite) {
      const userPermissionsCheck = loginUser?.permissions.userAdminPermissions;
      if (userPermissionsCheck.canInvite === 1) {
        dispatch(getInvitedUsers(pageInvite));
        setInvitedUserListData(
          invitedUserData?.data?.slice(pageInvite * 10 - 10, pageInvite * 10)
        );
      }
    }
  }, [loginUser, pageInvite]);

  const { permissions } = loginUser || {};

  const canReviewVal = permissions?.userAdminPermissions?.canReview;
  const canInviteVal = permissions?.userAdminPermissions?.canInvite;

  const usersListTabs: Tab[] = [
    {
      id: "all",
      label: "All",
      selected: selectedTab === tabIndexFirst,
      disabled: canReviewVal === 0 && canInviteVal === 0,
      content: Users(
        usersListData || [],
        page,
        setPage,
        usersCount,
        false,
        false,
        selectedTab,
        loginUser,
        setSelectedTab
      ),
      onClick: () => {
        setPage(1);
        navigate(Paths.adminUsers);
        setSelectedTab(tabIndexFirst);
      },
    },
    {
      id: "invited",
      label: (
        <div className="awaiting-badge-text">
          Invited
          {invitedCount >= 1 ? (
            <div className="approval-count btn-primary">
              <span className="approval-count-style">{invitedCount}</span>
            </div>
          ) : (
            ""
          )}
        </div>
      ),
      selected: selectedTab === tabIndexTwo,
      disabled:
        !invitedUserData?.data?.length ||
        loginUser?.permissions?.userAdminPermissions?.canInvite === 0,
      content: Users(
        invitedUserListData || [],
        pageInvite,
        setPageInvite,
        invitedCount,
        true,
        false,
        selectedTab,
        loginUser,
        setSelectedTab
      ),
      onClick: () => {
        setPageInvite(1);
        navigate(Paths.adminInvitedUsers);
        setSelectedTab(tabIndexTwo);
        dispatch(searchValue(""))
      },
    },

    {
      id: "awaiting",
      label: (
        <div className="awaiting-badge-text">
          Awaiting Approval
          {awaitingApprovalCount === 0 ? (
            "" && setSelectedTab(tabIndexFirst)
          ) : (
            <div className="approval-count btn-primary">
              <span className="approval-count-style">
                {awaitingApprovalCount}
              </span>
            </div>
          )}
        </div>
      ),
      selected: selectedTab === tabIndexThree,
      disabled:
        !awaitingApprovalData?.data?.length ||
        loginUser?.permissions?.userAdminPermissions?.canReview === 0,
      content: Users(
        awaitingApprovalUserListData,
        pageAwait,
        setPageAwait,
        awaitingApprovalCount,
        false,
        true,
        selectedTab,
        loginUser,
        setSelectedTab
      ),
      onClick: () => {
        setPageAwait(1);
        navigate(Paths.adminReviewUsers);
        setSelectedTab(tabIndexThree);
        dispatch(searchValue(""))
      },
    },
  ];
  const usersLength = usersCount >= 2;

  const adminTitle = useMemo(() => {
    switch (window.location.pathname) {
      case Paths.adminUsers:
        return adminTitles?.adminUser;
      case Paths.adminInvitedUsers:
        return adminTitles?.adminInvite;
      case Paths.adminReviewUsers || `${Paths.adminUsers}/${params?.id}/${Paths.adminReviewIdUsers}`:
        return adminTitles?.adminReview;
      default:
        return adminTitles?.admin;
    }
  }, [window.location.pathname]);

  return (
    <>
      <div className="user-list-container container-xl">
        <Helmet>
          <title>{adminTitle}</title>
          <meta name="Admin page" content="admin page" />
        </Helmet>
        <UserListHeader>
          <span className="m-0 fs-40 roboto-light black_text fw-300" title={`${Number(usersCount ? usersCount : 0).toLocaleString()} users`}>
            {`Users ${`${usersLength ? ` (${Intl.NumberFormat('en', { notation: 'compact' }).format(usersCount)})` : ''}`}`}
          </span>
          {canInviteVal === 1 && (
            <Button
              onClick={() => {
                dispatch(
                  UpdateModalContent({
                    isOpen: true,
                    isAdmin: true,
                    navigate: false,
                    classModal: "add-new-user-dialog",
                    children: (
                      <AddUserForm
                        onSuccess={() => {
                          dispatch(getInvitedUsers(pageInvite));
                          dispatch(
                            UpdateModalContent({
                              isOpen: false,
                              children: null,
                              fixedModal: true,
                              removePaddingBottom: false,
                              noMaxWidth: false
                            })
                          );
                        }}
                        onError={() => {
                          dispatch(
                            UpdateModalContent({
                              isOpen: false,
                              children: null,
                              fixedModal: true,
                              removePaddingBottom: false,
                              noMaxWidth: false
                            })
                          );
                        }}
                      />
                    ),
                    fixedModal: false,
                    removePaddingBottom: false,
                    noMaxWidth: false
                  })
                );
              }}
              label='Add new user'
              mobileLabel='Add'
              title='Add new user'
              icon='add'
              accent
              iconSize="24"
            />
          )}
        </UserListHeader>
        <TabNavigationAdmin tabs={usersListTabs} className={canReviewVal != 0 && canInviteVal != 0 ? "card-header border-bottom tab-style left-side-tabs-header users-tab-div" : ""} />
      </div>
    </>
  )
}