import { AnyAction } from "redux";
import { all, call, put, takeLatest } from "redux-saga/effects";

import * as services from "../../services";
import * as actions from "./actions";
import * as constants from "./constants";

import { snakbarShow } from "sagas/pos/snakbar/snakbar.slice";

import texts from "apps/pos/locales";

//Worker Saga
function* getPriceSchedulesAsync(action: AnyAction) {
  try {
    const priceSchedulesData = yield call(
      services.getPriceSchedules,
      action.payload
    );
    yield put(actions.priceSchedulesSuccess(priceSchedulesData));
  } catch (errors) {
    yield put(actions.priceSchedulesError("errors"));
  }
}

function* getSavedDraftsListAsync(action: AnyAction) {
  try {
    const savedDrafts = yield call(services.getSavedDraftsList, action.payload);

    yield put(actions.savedDraftsSuccess(savedDrafts));
  } catch (errors) {
    yield put(actions.savedDraftsError("errors"));
  }
}

function* getTakersAsync() {
  try {
    const takersData = yield call(services.getTakers);
    yield put(actions.takersSuccess(takersData));
  } catch (errors) {
    yield put(actions.takersError("errors"));
  }
}

function* getSalesPersonsAsync() {
  try {
    const salesPersonsData = yield call(services.getSalesPersons);
    yield put(actions.salespersonsSuccess(salesPersonsData));
  } catch (errors) {
    yield put(actions.salespersonsError("errors"));
  }
}

function* getRecentCustomersAsync(action: AnyAction) {
  try {
    const recentCustomersData = yield call(
      services.recentCustomers,
      action.payload
    );
    yield put(actions.recentCustomersSuccess(recentCustomersData));
  } catch (errors) {
    yield put(actions.recentCustomersError("errors"));
  }
}

function* getProductsAsync(action: AnyAction) {
  try {
    const productsData = yield call(services.getProducts, action.payload);
    yield put(actions.productsSuccess(productsData));
    if (Number(action.updatePrice) >= 0) yield put(actions.setChangeCartPrice(action.updatePrice));
    if (Number(action.initialCustomPrice) >= 0) yield put(actions.setInitialCustomPrice(action.initialCustomPrice));
  } catch (errors) {
    yield put(actions.productsError("errors"));
  }
}

function* getSuggestedProductsWatcherAsync(action: AnyAction) {
  try {
    const suggestedProductsData = yield call(
      services.suggestedProducts,
      action.payload
    );
    yield put(actions.suggestedProductsSuccess(suggestedProductsData));
  } catch (errors) {
    yield put(actions.suggestedProductsError("errors"));
  }
}

// function* getFacilityTypesAsync(action: AnyAction) {
//   try {
//     const facilityTypesData = yield call(services.facilityTypes);
//     yield put(actions.getFacilityTypesSuccess(facilityTypesData));
//   } catch (errors) {
//     yield put(actions.getFacilityTypesError("errors"));
//   }
// }

// function* getJobDescriptionAsync(action: AnyAction) {
//   try {
//     const jobDescriptionData = yield call(services.jobDescription);
//     yield put(actions.getJobDescriptionSuccess(jobDescriptionData));
//   } catch (errors) {
//     yield put(actions.getJobDescriptionError("errors"));
//   }
// }

function* getStatesAsync() {
  try {
    const statesData = yield call(services.states);
    yield put(actions.getStatesSuccess(statesData));
  } catch (errors) {
    yield put(actions.getStatesError("errors"));
  }
}

function* getShiptoFacilitiesAsync(action: AnyAction) {
  try {
    const shiptoData = yield call(services.shiptoFacilities, action.payload);
    yield put(actions.getShiptoFacilitiesSuccess(shiptoData));
  } catch (errors) {
    yield put(actions.getShiptoFacilitiesError("errors"));
  }
}

function* getShippingFacilitiesAsync(action: AnyAction) {
  try {
    const shippingData = yield call(
      services.shippingFacilities,
      action.payload
    );
    yield put(actions.getShippingFacilitiesSuccess(shippingData));
  } catch (errors) {
    yield put(actions.getShippingFacilitiesError("errors"));
  }
}

// start
function* getSalesHistoryAsync(action: AnyAction) {
  try {
    const salesHistoryData = yield call(services.salesHistory, action.payload);
    yield put(actions.getSalesHistorySuccess(salesHistoryData));
  } catch (errors) {
    yield put(actions.getSalesHistoryError("errors"));
  }
}

function* getUserPriceScheduleDataAsync(action: AnyAction) {
  try {
    const userPriceScheduleData = yield call(
      services.userPriceScheduleData,
      action.payload
    );
    yield put(actions.getUserPriceScheduleDataSuccess(userPriceScheduleData));
  } catch (errors) {
    yield put(actions.getUserPriceScheduleDataError("errors"));
  }
}

function* getQuotesHistoryAsync(action: AnyAction) {
  try {
    const quotesHistoryData = yield call(services.quotesHistory, action.payload);
    yield put(actions.getQuotesHistorySuccess(quotesHistoryData));
  } catch (errors) {
    yield put(actions.getQuotesHistoryError("errors"));
  }
}

function* geItemHistoryAsync(action: AnyAction) {
  try {
    const itemHistoryData = yield call(services.itemHistory, action.payload);
    yield put(actions.getItemHistorySuccess(itemHistoryData));
  } catch (errors) {
    yield put(actions.getItemHistoryError("errors"));
  }
}

function* gePurchaseHistoryAsync(action: AnyAction) {
  try {
    const purchaseHistoryData = yield call(services.purchaseHistory, action.payload);
    yield put(actions.getPurchaseHistorySuccess(purchaseHistoryData));
  } catch (errors) {
    yield put(actions.getPurchaseHistoryError("errors"));
  }
}

function* geInvoiceHistoryAsync(action: AnyAction) {
  try {
    const invoiceHistoryData = yield call(services.invoiceHistory, action.payload);
    yield put(actions.getInvoiceHistorySuccess(invoiceHistoryData));
  } catch (errors) {
    yield put(actions.getInvoiceHistoryError("errors"));
  }
}

function* gePosPriceChangeHistoryAsync(action: AnyAction) {
  try {
    const posPriceChangeHistoryData = yield call(services.posPriceChangeHistory, action.payload);
    yield put(actions.getPosPriceChangeHistorySuccess(posPriceChangeHistoryData));
  } catch (errors) {
    yield put(actions.getPosPriceChangeHistoryError("errors"));
  }
}
function* getMovingAvgCostChangeHistoryAsync(action: AnyAction) {
  try {
    const getMovingAvgCostChangeHistoryData = yield call(services.movingAvgCostHistory, action.payload);
    yield put(actions.getMovingAvgCostHistorySuccess(getMovingAvgCostChangeHistoryData));
  } catch (errors) {
    yield put(actions.getMovingAvgCostHistoryError("errors"));
  }
}

// end

function* addShippingFacilitiesAsync(action: AnyAction) {
  try {
    const addShippingData = yield call(
      services.addShippingFacilities,
      action.payload,
      action.customerId
    );
    yield put(actions.addShippingFacilitiesSuccess(addShippingData));
  } catch (errors) {
    yield put(actions.addShippingFacilitiesError(errors));
  }
}

function* searchCustomersAsync(action: AnyAction) {
  try {
    const searchCustomerData = yield call(
      services.searchCustomers,
      action.payload
    );
    yield put(actions.addCustomersSuccess(searchCustomerData));
  } catch (errors) {
    yield put(actions.addCustomersError("errors"));
  }
}

function* searchReturnCustomersAsync(action: AnyAction) {
  try {
    const searchCustomerData = yield call(
      services.searchReturnCustomers,
      action.payload
    );
    yield put(actions.addCustomersSuccess(searchCustomerData));
  } catch (errors) {
    yield put(actions.addCustomersError("errors"));
  }
}

function* searchProductsAsync(action: AnyAction) {
  try {
    const searchProductData = yield call(
      services.searchProducts,
      action.payload
    );
    yield put(actions.searchProductSuccess(searchProductData));
  } catch (errors) {
    yield put(actions.searchProductError("errors"));
  }
}

function* searchReturnProductsAsync(action: AnyAction) {
  try {
    const searchProductData = yield call(
      services.searchReturnProducts,
      action.payload
    );
    yield put(actions.searchProductSuccess(searchProductData));
  } catch (errors) {
    yield put(actions.searchProductError("errors"));
  }
}

function* addPriceScheduleAsync(action: AnyAction) {
  try {
    const addPriceScheduleData = yield call(
      services.addPriceSchedule,
      action.payload
    );
    yield put(actions.addPriceSchedulesSuccess(addPriceScheduleData));
  } catch (errors) {
    yield put(actions.addPriceSchedulesError("errors"));
  }
}

function* getStockByWarehouseAsync(action: AnyAction) {
  try {
    const stockData = yield call(services.getStockInformation, action.payload);
    yield put(actions.stockByWarehouseSuccess(stockData));
  } catch (errors) {
    yield put(actions.stockByWarehouseError("errors"));
  }
}

function* addNewProductAsync(action: AnyAction) {
  try {
    const addNewProductData = yield call(
      services.addnewProduct,
      action.payload
    );
    yield put(actions.addNewProductSuccess(addNewProductData));
  } catch (errors) {
    yield put(actions.addNewProductError(errors.detail));
  }
}

function* updatePriceScheduleAsync(action: AnyAction) {
  try {
    const updatePriceScheduleData = yield call(
      services.updatePriceSchedule,
      action.payload
    );
    yield put(actions.updatePriceSchedulesSuccess(updatePriceScheduleData));
  } catch (errors) {
    yield put(actions.updatePriceSchedulesError("errors"));
  }
}
function* cartSubmitAsync(action: AnyAction) {
  try {
    const cartData = yield call(services.cartSubmit, action.payload, action.cartId);
    yield put(actions.cartSubmitSuccess(cartData));
  } catch (errors) {
    yield put(actions.cartSubmitError(errors.detail));
  }
}
function* cartPrintAsync(action: AnyAction) {
  try {
    const cartData = yield call(services.cartPrint, action.payload);
    yield put(actions.cartPrintSuccess(cartData));
  } catch (errors) {
    yield put(actions.cartPrintError("errors"));
  }
}

function* cartPrintWithTotalsAsync(action: AnyAction) {
  try {
    const cartData = yield call(services.cartWithTotalsPrint, action.payload);
    yield put(actions.cartPrintWithTotalsSuccess(cartData));
  } catch (errors) {
    yield put(actions.cartPrintWithTotalsError("errors"));
  }
}

function* cartDraftAsync(action: AnyAction) {
  try {
    const cartData = yield call(services.cartDraft, action.payload, action.cartId);
    yield put(actions.cartDraftSuccess(cartData));
  } catch (errors) {
    yield put(actions.cartDraftError("errors"));
  }
}

function* getWarehouseLocationsAsync(action: AnyAction) {
  try {
    const getWarehouseLocationData = yield call(
      services.getWarehouseLocation,
      action.payload
    );
    yield put(
      actions.getWarehourseLocationsSuccess(getWarehouseLocationData)
    );
  } catch (errors) {
    yield put(actions.getWarehourseLocationsError("errors"));
  }
}

function* getCategoriesAsync() {
  try {
    const categoriesData = yield call(services.getCategories);
    yield put(actions.categoriesSuccess(categoriesData));
  } catch (errors) {
    yield put(actions.categoriesError("errors"));
  }
}

function* getNewProductManufacturerAsync() {
  try {
    const newProductManufacturerData = yield call(
      services.getNewProductManufacturer
    );
    yield put(
      actions.newProductManufacturerSuccess(newProductManufacturerData)
    );
  } catch (errors) {
    yield put(actions.newProductManufacturerError("errors"));
  }
}

function* getNewProductVendorAsync() {
  try {
    const newProductVendorData = yield call(services.getNewProductVendor);
    yield put(actions.newProductVendorSuccess(newProductVendorData));
  } catch (errors) {
    yield put(actions.newProductVendorError("errors"));
  }
}

function* getNewProductGroupAsync() {
  try {
    const newProductGroupData = yield call(services.getNewProductGroup);
    yield put(actions.newProductGroupSuccess(newProductGroupData));
  } catch (errors) {
    yield put(actions.newProductGroupError("errors"));
  }
}

// Start Adjust Stock api
// Get Rows
function* getRowsActiveAsync(action: AnyAction) {
  try {
    const getRowsActiveData = yield call(
      services.getRowsActiveData,
      action.payload
    );
    if (getRowsActiveData) {
      yield put(actions.getRowsActiveSuccess(getRowsActiveData));
    } else {
      yield put(actions.getRowsActiveError(getRowsActiveData));
    }
  } catch (errors) {
    yield put(actions.getRowsActiveError(errors));
  }
}
// Get Units
function* getUnitsActiveAsync(action: AnyAction) {
  try {
    const getUnitsActiveData = yield call(
      services.getUnitsActiveData,
      action.payload
    );
    yield put(actions.getUnitsActiveSuccess(getUnitsActiveData));
  } catch (errors) {
    yield put(actions.getUnitsActiveError("errors"));
  }
}
// Get Shelf
function* getShelfAsync(action: AnyAction) {
  try {
    const getShelfData = yield call(services.getShelfData, action.payload);
    yield put(actions.getShelfSuccess(getShelfData));
  } catch (errors) {
    yield put(actions.getShelfError("errors"));
  }
}
// Stock In
function* stockInAsync(action: AnyAction) {
  const { stockAdded, failedAdd } = texts;
  try {
    const stockInData = yield call(services.stockInData, action.payload);
    yield put(actions.stockInSuccess(stockInData));
    yield put(snakbarShow({ message: stockAdded }));
  } catch (errors) {
    yield put(snakbarShow({ message: failedAdd }));
    yield put(actions.stockInError("errors"));
  }
}
// Stock Out
function* stockOutAsync(action: AnyAction) {
  const { stockRemove, failedRemove } = texts
  try {
    const stockOutData = yield call(services.stockOutData, action.payload);
    yield put(actions.stockOutSuccess(stockOutData));
    yield put(snakbarShow({ message: stockRemove }));
  } catch (errors) {
    yield put(snakbarShow({ message: failedRemove }));
    yield put(actions.stockOutError("errors"));
  }
}
// Stock Move
function* stockMoveAsync(action: AnyAction) {
  const { failedMove, stockMoved } = texts;
  try {
    const stockMoveData = yield call(services.stockMoveData, action.payload);
    yield put(snakbarShow({ message: stockMoved }));
    yield put(actions.stockMoveSuccess(stockMoveData));
  } catch (errors) {
    yield put(snakbarShow({ message: failedMove }));
    yield put(actions.stockMoveError("errors"));
  }
}
// End Adjust Stock api
// Start Item Ledger Pagination
function* itemLedgerAsync(action: AnyAction) {
  try {
    const itemLedgerData = yield call(services.itemLedger, action.payload);
    yield put(actions.itemLedgerSuccess(itemLedgerData));
  } catch (errors) {
    yield put(actions.itemLedgerError(errors));
  }
}
// End Item Ledger
// Start from Location Product
function* fromLocationProductAsync(action: AnyAction) {
  try {
    const fromLocationProductData = yield call(
      services.fromLocationProduct,
      action.payload
    );
    yield put(actions.fromLocationProductSuccess(fromLocationProductData));
  } catch (errors) {
    yield put(actions.fromLocationProductError("errors"));
  }
}
// End from Location Product

// Start from Location Row
function* fromLocationRowAsync(action: AnyAction) {
  try {
    const fromLocationRowData = yield call(
      services.getFromLocationRowsData,
      action.payload
    );
    yield put(actions.fromLocationRowSuccess(fromLocationRowData));
  } catch (errors) {
    yield put(actions.fromLocationRowError("errors"));
  }
}
// End from Location Row

// Start from Location Unit
function* fromLocationUnitAsync(action: AnyAction) {
  try {
    const fromLocationUnitData = yield call(
      services.getFromLocationUnitsData,
      action.payload
    );
    yield put(actions.fromLocationUnitSuccess(fromLocationUnitData));
  } catch (errors) {
    yield put(actions.fromLocationUnitError("errors"));
  }
}
// End from Location Unit

// Start from Location Shelf
function* fromLocationShelfAsync(action: AnyAction) {
  try {
    const fromLocationShelfData = yield call(
      services.getFromLocationShelfData,
      action.payload
    );
    yield put(actions.fromLocationShelfSuccess(fromLocationShelfData));
  } catch (errors) {
    yield put(actions.fromLocationShelfError("errors"));
  }
}
// End from Location Shelf

function* documentTypesAsync(action: AnyAction) {
  try {
    let documentTypesData;
    if (action.payload) {
      documentTypesData = yield call(services.getCompaniesDocumentTypesData, action.payload);
    }
    else {
      documentTypesData = yield call(services.getDocumentTypesData, action.payload);
    }

    if (documentTypesData?.data) {
      yield put(actions.getDocumentTypesSuccess(documentTypesData.data));
    }
  } catch (errors) {
    yield put(actions.getDocumentTypesError(errors));
  }
}

// Start Item Ledger Detail
function* itemLedgerInoutAsync(action: AnyAction) {
  try {
    const itemLedgerData = yield call(services.itemLedgerInout, action.payload);
    yield put(actions.itemLedgerInoutSuccess(itemLedgerData));
  } catch (errors) {
    yield put(actions.itemLedgerInoutError(errors));
  }
}
// End Item Ledger

function* searchDraftsAsync(action: AnyAction) {
  try {
    const searchDraftData = yield call(
      services.searchDrafts,
      action.payload
    );
    yield put(actions.searchDraftsSuccess(searchDraftData));
  } catch (errors) {
    yield put(actions.searchDraftsError("errors"));
  }
}

function* noMailFlagAsync(action: AnyAction) {
  try {
    const noMailFlagData = yield call(
      services.setNoMailFlag,
      action.payload
    );
    yield put(actions.noMailSuccess(noMailFlagData));
  } catch (errors) {
    yield put(actions.noMailError("errors"));
  }
}

function* getCustomerRegionAsync(action: AnyAction) {
  try {
    const data = yield call(
      services.getCustomerRegion,
      action.payload
    );
    yield put(
      actions.getCustomerRegionSuccess(data)
    );
  } catch (errors) {
    yield put(actions.getCustomerRegionError("errors"));
  }
}

function* sendEmailQuoteAsync(action: AnyAction) {
  try {
    const data = yield call(
      services.sendEmailQuote,
      action.payload
    );
    yield put(
      actions.cartEmailWithTotalsSuccess(data)
    );
  } catch (errors) {
    yield put(actions.cartEmailWithTotalsError("errors"));
  }
}

// packing slip
function* packingSlipAsync(action: AnyAction) {
  try {
    const response = yield call(services.getPackingSlip, action.payload);
    yield put(actions.packingSlipSuccess(response));
  } catch (error) {
    yield put(actions.packingSlipError("errors"));
  }
}


//Watcher Saga



function* getPriceSchedulesWatcher() {
  yield takeLatest(constants.GET_PRICESCHEDULES_START, getPriceSchedulesAsync);
}

function* getSavedDraftsListWatcher() {
  yield takeLatest(constants.GET_SAVED_DRAFTS_START, getSavedDraftsListAsync);
}

function* getTakersWatcher() {
  yield takeLatest(constants.GET_TAKERS_START, getTakersAsync);
}

function* getSalesPersonsWatcher() {
  yield takeLatest(constants.GET_SALESPERSONS_START, getSalesPersonsAsync);
}

function* getRecentCustomersWatcher() {
  yield takeLatest(
    constants.GET_RECENT_CUSTOMERS_START,
    getRecentCustomersAsync
  );
}

function* getProductsWatcher() {
  yield takeLatest(constants.GET_PRODUCTS_START, getProductsAsync);
}

function* getSuggestedProductsWatcher() {
  yield takeLatest(
    constants.GET_SUGGESTED_PRODUCTS_START,
    getSuggestedProductsWatcherAsync
  );
}


function* addNewProductWatcher() {
  yield takeLatest(constants.ADD_NEW_PRODUCT_START, addNewProductAsync);
}

// TODO:
// function* getFacilityTypesWatcher() {
//   yield takeLatest(
//     constants.GET_SHIPPING_FACILITIES_START,
//     getFacilityTypesAsync
//   );
// }

// function* getJobDescriptionWatcher() {
//   yield takeLatest(
//     constants.GET_SHIPPING_FACILITIES_START,
//     getJobDescriptionAsync
//   );
// }

function* getStatesWatcher() {
  yield takeLatest(constants.GET_STATES_START, getStatesAsync);
}

function* getShiptoFacilitiesWatcher() {
  yield takeLatest(
    constants.GET_SHIPTO_FACILITIES_START,
    getShiptoFacilitiesAsync
  );
}

function* getShippingFacilitiesWatcher() {
  yield takeLatest(
    constants.GET_SHIPPING_FACILITIES_START,
    getShippingFacilitiesAsync
  );
}

// start
function* getSalesHistoryWatcher() {
  yield takeLatest(constants.GET_SALES_HISTORY_START, getSalesHistoryAsync);
}

function* getUserPriceScheduleDataWatcher() {
  yield takeLatest(
    constants.USER_PRICE_SCHEDULE_DATA_REQUEST,
    getUserPriceScheduleDataAsync
  );
}

function* getQuotesHistoryWatcher() {
  yield takeLatest(constants.GET_QUOTE_HISTORY_START, getQuotesHistoryAsync);
}

function* getItemHistoryWatcher() {
  yield takeLatest(constants.GET_ITEM_HISTORY_START, geItemHistoryAsync);
}

function* getPurchaseHistoryWatcher() {
  yield takeLatest(constants.GET_PURCHASE_HISTORY_START, gePurchaseHistoryAsync);
}

function* getInvoiceHistoryWatcher() {
  yield takeLatest(constants.GET_INVOICE_HISTORY_START, geInvoiceHistoryAsync);
}

function* getPosPriceChangeHistoryWatcher() {
  yield takeLatest(constants.GET_POS_PRICE_CHANGE_HISTORY_START, gePosPriceChangeHistoryAsync);
}
function* getMovingAvgCostChangeHistoryWatcher() {
  yield takeLatest(constants.GET_MOVING_AVG_COST_HISTORY_START, getMovingAvgCostChangeHistoryAsync);
}

// end

function* addShippingFacilitiesWatcher() {
  yield takeLatest(
    constants.ADD_SHIPPING_FACILITIES_START,
    addShippingFacilitiesAsync
  );
}

function* searchCustomersWatcher() {
  yield takeLatest(constants.SEARCH_CUSTOMERS_START, searchCustomersAsync);
}

function* searchReturnCustomersWatcher() {
  yield takeLatest(
    constants.SEARCH_RETURN_CUSTOMERS_START,
    searchReturnCustomersAsync
  );
}

function* searchProductsWatcher() {
  yield takeLatest(constants.SEARCH_PRODUCTS_START, searchProductsAsync);
}

function* searchReturnProductsWatcher() {
  yield takeLatest(
    constants.SEARCH_RETURN_PRODUCTS_START,
    searchReturnProductsAsync
  );
}

function* addPriceScheduleWatcher() {
  yield takeLatest(constants.ADD_PRICE_SCHEDULE_START, addPriceScheduleAsync);
}

function* updatePriceScheduleWatcher() {
  yield takeLatest(
    constants.UPDATE_PRICE_SCHEDULE_START,
    updatePriceScheduleAsync
  );
}

function* cartSubmitWatcher() {
  yield takeLatest(constants.CART_SUBMIT_START, cartSubmitAsync);
}

function* cartPrintWatcher() {
  yield takeLatest(constants.CART_PRINT_START, cartPrintAsync);
}

function* cartDraftWatcher() {
  yield takeLatest(constants.CART_DRAFT_START, cartDraftAsync);
}

function* cartPrintWithTotalsWatcher() {
  yield takeLatest(
    constants.CART_PRINT_WITH_TOTALS_START,
    cartPrintWithTotalsAsync
  );
}

function* getStockByWarehouseWatcher() {
  yield takeLatest(
    constants.STOCK_BY_WAREHOUSE_START,
    getStockByWarehouseAsync
  );
}

function* getWarehouseLocationsWatcher() {
  yield takeLatest(
    constants.GET_WAREHOUSE_LOCATIONS_START,
    getWarehouseLocationsAsync
  );
}

function* getCategoriesWatcher() {
  yield takeLatest(constants.GET_CATEGORIES_REQUEST, getCategoriesAsync);
}

function* getNewProductManufacturerDataWatcher() {
  yield takeLatest(
    constants.GET_NEW_PRODUCT_MANUFACTURER_REQUEST,
    getNewProductManufacturerAsync
  );
}

function* getNewProductVendorDataWatcher() {
  yield takeLatest(
    constants.GET_NEW_PRODUCT_VENDOR_REQUEST,
    getNewProductVendorAsync
  );
}

function* getNewProductGroupDataWatcher() {
  yield takeLatest(
    constants.GET_NEW_PRODUCT_GROUP_REQUEST,
    getNewProductGroupAsync
  );
}

// Start Adjust Stock api
//Get Rows
function* getRowsActiveWatcher() {
  yield takeLatest(constants.GET_ROWS_ACTIVE_START, getRowsActiveAsync);
}
// Get Units
function* getUnitsActiveWatcher() {
  yield takeLatest(constants.GET_UNIT_ACTIVE_START, getUnitsActiveAsync);
}
// Get Shelf
function* getShelfWatcher() {
  yield takeLatest(constants.GET_SHELF_START, getShelfAsync);
}
// Stock In
function* stockInWatcher() {
  yield takeLatest(constants.STOCK_IN_START, stockInAsync);
}
// Stock Out
function* stockOutWatcher() {
  yield takeLatest(constants.STOCK_OUT_START, stockOutAsync);
}
// Stock Move
function* stockMoveWatcher() {
  yield takeLatest(constants.STOCK_MOVE_START, stockMoveAsync);
}
// End Adjust Stock api
// Start Item Ledger Pagination
function* itemLedgerWatcher() {
  yield takeLatest(constants.ITEM_LEDGER_START, itemLedgerAsync);
}
// End Item Ledger
// Start From location product
function* fromLocationProductWatcher() {
  yield takeLatest(
    constants.FROM_LOCATION_PRODUCT_START,
    fromLocationProductAsync
  );
}
// End From location product

// Start From location Row
function* fromLocationRowWatcher() {
  yield takeLatest(constants.FROM_LOCATION_ROW_START, fromLocationRowAsync);
}
// End From location Row
// Start From location Unit
function* fromLocationUnitWatcher() {
  yield takeLatest(constants.FROM_LOCATION_UNIT_START, fromLocationUnitAsync);
}
// End From location Unit
// Start From location Shelf
function* fromLocationShelfWatcher() {
  yield takeLatest(constants.FROM_LOCATION_SHELF_START, fromLocationShelfAsync);
}
// End From location Shelf

function* documentTypesWatcher() {
  yield takeLatest(
    constants.DOCUMENT_TYPES_REQUEST,
    documentTypesAsync
  );
}

// Start Item Ledger detail
function* itemLedgerInoutWatcher() {
  yield takeLatest(constants.ITEM_LEDGER_INOUT_START, itemLedgerInoutAsync);
}
// End Item Ledger

function* searchDraftsWatcher() {
  yield takeLatest(constants.SEARCH_DRAFT_START, searchDraftsAsync);
}

function* noMailWatcher() {
  yield takeLatest(constants.NO_MAIL_START, noMailFlagAsync);
}


function* cartClearDraftsAsync(action: AnyAction) {

  try {
    const cartData = yield call(services.cartClear, action.payload);
    yield put(actions.cartClearDraftsSuccess(cartData));
  } catch (errors) {
    yield put(actions.cartClearDraftsError(errors.detail));
  }
}

function* cartClearDraftsWatcher() {
  yield takeLatest(constants.CLEAR_CART_DRAFTS_START, cartClearDraftsAsync);
}

function* getCustomerRegionsWatcher() {
  yield takeLatest(constants.CUSTOMER_REGION_LIST_START, getCustomerRegionAsync);
}

function* sendEmailQuoteWatcher() {
  yield takeLatest(constants.CART_EMAIL_WITH_TOTALS_START, sendEmailQuoteAsync);
}

function* getPackingSlipWatcher() {
  yield takeLatest(constants.PACKING_SLIP_START, packingSlipAsync);
}

export default function* posSaga() {
  yield all([
    getPriceSchedulesWatcher(),
    getSavedDraftsListWatcher(),
    getTakersWatcher(),
    getSalesPersonsWatcher(),
    getRecentCustomersWatcher(),
    searchCustomersWatcher(),
    searchProductsWatcher(),
    searchReturnProductsWatcher(),
    addPriceScheduleWatcher(),
    updatePriceScheduleWatcher(),
    getProductsWatcher(),
    getSuggestedProductsWatcher(),
    // getFacilityTypesWatcher(),
    // getJobDescriptionWatcher(),
    getStatesWatcher(),
    getShiptoFacilitiesWatcher(),
    getShippingFacilitiesWatcher(),
    getSalesHistoryWatcher(),
    getUserPriceScheduleDataWatcher(),
    addShippingFacilitiesWatcher(),
    cartSubmitWatcher(),
    cartPrintWatcher(),
    cartDraftWatcher(),
    cartPrintWithTotalsWatcher(),

    addNewProductWatcher(),
    getWarehouseLocationsWatcher(),
    getStockByWarehouseWatcher(),
    getCategoriesWatcher(),
    getNewProductManufacturerDataWatcher(),
    getNewProductVendorDataWatcher(),
    getNewProductGroupDataWatcher(),
    //Start Adjust Stock api
    getRowsActiveWatcher(),
    getUnitsActiveWatcher(),
    getShelfWatcher(),
    stockInWatcher(),
    stockOutWatcher(),
    stockMoveWatcher(),
    //End Stock api
    // Item Ledger
    itemLedgerWatcher(),
    itemLedgerInoutWatcher(),
    fromLocationProductWatcher(),
    fromLocationRowWatcher(),
    fromLocationUnitWatcher(),
    fromLocationShelfWatcher(),
    documentTypesWatcher(),
    searchReturnCustomersWatcher(),
    getQuotesHistoryWatcher(),
    getItemHistoryWatcher(),
    getPurchaseHistoryWatcher(),
    getInvoiceHistoryWatcher(),
    searchDraftsWatcher(),
    noMailWatcher(),
    cartClearDraftsWatcher(),
    getPosPriceChangeHistoryWatcher(),
    getMovingAvgCostChangeHistoryWatcher(),
    getCustomerRegionsWatcher(),
    sendEmailQuoteWatcher(),
    getPackingSlipWatcher()
  ]);
}
