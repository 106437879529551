export async function trimSignature(signatureData: string): Promise<string> {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        resolve(signatureData);
        return;
      }

      // Set canvas size to match image
      canvas.width = img.width;
      canvas.height = img.height;

      // Draw image onto canvas
      ctx.drawImage(img, 0, 0);

      // Get image data
      const data = ctx.getImageData(0, 0, canvas.width, canvas.height).data;
      let left = canvas.width;
      let right = 0;
      let top = canvas.height;
      let bottom = 0;

      // Find signature boundaries
      for (let i = 0; i < canvas.width; i++) {
        for (let j = 0; j < canvas.height; j++) {
          const alpha = data[(j * canvas.width + i) * 4 + 3];
          if (alpha !== 0) {
            left = Math.min(left, i);
            right = Math.max(right, i);
            top = Math.min(top, j);
            bottom = Math.max(bottom, j);
          }
        }
      }

      // Add padding
      const padding = 10;
      left = Math.max(0, left - padding);
      right = Math.min(canvas.width, right + padding);
      top = Math.max(0, top - padding);
      bottom = Math.min(canvas.height, bottom + padding);

      const trimmedWidth = right - left;
      const trimmedHeight = bottom - top;

      // Create new canvas for trimmed image
      const trimmedCanvas = document.createElement('canvas');
      trimmedCanvas.width = trimmedWidth;
      trimmedCanvas.height = trimmedHeight;
      const trimmedCtx = trimmedCanvas.getContext('2d');

      if (trimmedCtx) {
        trimmedCtx.drawImage(
          canvas,
          left, top, trimmedWidth, trimmedHeight,
          0, 0, trimmedWidth, trimmedHeight
        );
        resolve(trimmedCanvas.toDataURL());
      } else {
        resolve(signatureData);
      }
    };

    img.src = signatureData;
  });
}