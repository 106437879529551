import { useCallback, useEffect, useRef, useState } from "react";
import { InputAdornment } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import _ from "loadsh";
import { useHotkeys } from "react-hotkeys-hook";

import { PosImage, PosInput, PosModel, PosTypography, PosIconButton } from "apps/pos/Common/uiCore";
import { colors } from "apps/pos/orderData";

import images from "constants/images";
import { RootState, searchCustomerModalProps } from "models";
import { SearchIcon } from "constants/icons";
import { CustomerInfoProp, PosFlowDataProp, SearchCustomerModalProps } from "constants/interfaces";
import { keys, renamePersonData } from "utils/helper";

import {
  returnCustomersRequest,
  arrowDownIndex,
  clearSearchCustomer,
  suggestedProductsClear,
  setSelectedIndex,
} from "sagas/pos/actions";
import { setPosFlowData } from "sagas/persistReducer/actions";
import { returnProductDataModalAction, returnSearchedCustomerDataAction, searchReturnInvoiceClear, searchReturnInvoiceRequest } from "sagas/returns/actions";

import useStyles, { StyledTableCell, StyledTableRow } from "./style";
import "apps/pos/PrivacySettings/privacySettings.scss";
import "apps/pos/poscommonstyling.scss";
import "./searchCustomersModal.scss"
import { keyPressHandle } from "apps/pos/ReturnProduct/ReturnProductHelperFunction";

const SearchCustomerModel = ({
  open, onClose, productInformation }) => {

  const { returnSearchedCustomerData,
    returnSearchedProductData,
    returnSearchedInvoiceData
  } = useSelector<RootState, SearchCustomerModalProps>(
    (state) => state.returnReducer
  );

  const [pageSize, setPageSize] = useState<number>(1);
  const [doesHaveMore, setHaveMore] = useState<boolean>(true);
  const [enterPress, setEnterPress] = useState<boolean>(false);
  const [downPress, setDownPress] = useState<boolean>(false);
  const [upPress, setUpPress] = useState<boolean>(false);
  const [searchString, setSearchString] = useState("");
  const [searchCustomersData, setSearchCustomersData] = useState<CustomerInfoProp[]>();
  // const [selectedWorkspace, setSelectedWorkspace] = useState("");
  const [customerCount, setCustomerCount] = useState<number>(0);
  const [active] = useState(0);

  // const currentWorkspace = JSON.parse(localStorage.getItem("workspace"));

  const searchCustomerModalSearchRef = useRef<any>();
  const tableRowRef = useRef();

  const { posFlowData } = useSelector<RootState, PosFlowDataProp>(
    (state) => state.persistReducer
  );

  const {
    searchCustomers,
    searchCustomersLength,
    error,
    selectedIndex,
    customerDataCount,
  } = useSelector<RootState, searchCustomerModalProps>((state) => state.pos);

  const dispatch = useDispatch();

  const searchAPI = (keywords) => {
    const data = {
      ...posFlowData,
      draftCartDeleted: false,
    };
    dispatch(setPosFlowData(data));

    if (keywords.length === 0) {
      setSearchCustomersData([]);
      dispatch(clearSearchCustomer());
    }
    if (keywords.length >= 1) {
      debounceFn(keywords);
    }
  };

  useHotkeys('home', (e) => {
    if (e?.keyCode !== keys.home) return

    dispatch(setSelectedIndex(0));

    scrollToSetting(0);

  }
  )
  useHotkeys("end", (e) => {
    if (e?.keyCode !== keys.end) return;

    dispatch(setSelectedIndex(searchCustomersData?.length - 1));

    scrollToSetting(searchCustomersData?.length - 1);
  });

  const handleDebounceFn = (keywords) => {
    // const currentWorkspace = JSON.parse(localStorage.getItem("workspace"));
    const body = {
      page: pageSize,
      size: 20,
      query: keywords,
      stockWarehouseId: 0, //currentWorkspace.stockWarehouseId,
    };
    setSearchCustomersData([]);
    dispatch(returnCustomersRequest(body));
  };

  const debounceFn = useCallback(_.debounce(handleDebounceFn, 1000), []);

  useEffect(() => {
    if (searchCustomers?.length && searchCustomersData?.length) {
      setPageSize(searchCustomersLength > searchCustomersData?.length ? pageSize + 1 : pageSize)
      setSearchCustomersData(
        _.uniqBy([...searchCustomersData, ...searchCustomers])
      );
    } else {
      setHaveMore(searchCustomersLength > searchCustomersData?.length)
      setSearchCustomersData(searchCustomers);
    }
  }, [searchCustomers]);

  useEffect(() => {
    if (enterPress) {
      if (searchCustomersData?.length) {
        handleSerchCustomer(
          searchCustomersData[selectedIndex]
        );
      }
      setEnterPress(false);
    }
  }, [enterPress]);

  // useEffect(() => {
  //   const currentWorkstation = JSON.parse(localStorage.getItem("workspace"));
  //   if (currentWorkstation) {
  //     setSelectedWorkspace(currentWorkstation.stockWarehouseId);
  //   }
  // }, [currentWorkspace]);

  useEffect(() => {
    setCustomerCount(customerDataCount);
  }, [customerDataCount]);

  useEffect(() => {
    if (open) {
      setSearchString(returnSearchedCustomerData?.fullName)
    }
  }, [open]);

  const handleSerchCustomer = (customer) => {
    dispatch(suggestedProductsClear());
    setTimeout(() => {
      const data = {
        ...posFlowData,
        customer: customer,
        shippingCharge: {
          shippingAmt: 0,
          shippingAmtTax: 0,
        },
        cartProducts: [],
        addInStorage: [],
      };
      dispatch(setPosFlowData(data));
    }, 1000);
    if (Object.keys(productInformation).length === 0) {
      dispatch(returnProductDataModalAction(true))
    }
  };

  const scrollToSetting = (selectedIndex) => {
    const elementId = document?.getElementById(`tableRowRef${selectedIndex}`);

    elementId?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "end",
    });
  };

  useEffect(() => {
    if (downPress) {
      scrollToSetting(selectedIndex);
      dispatch(arrowDownIndex(searchCustomersLength));
      setDownPress(false);
    }
  }, [downPress]);

  useEffect(() => {
    if (upPress) {
      if (selectedIndex > -1) {
        scrollToSetting(selectedIndex);
      }
      setUpPress(false);
    }
  }, [upPress]);

  const handleProductSelect = (customer) => {
    dispatch(searchReturnInvoiceClear())
    dispatch(returnSearchedCustomerDataAction(customer))
    onClose();
    keyPressHandle(returnSearchedProductData,
      searchReturnInvoiceRequest,
      customer,
      returnSearchedInvoiceData,
      dispatch);
  };

  const handleScroll = () => {
    if (searchCustomersData?.length === searchCustomersLength) {
      setHaveMore(false);
    }
    if (searchCustomersData?.length < searchCustomersLength) {
      const body = {
        page: pageSize + 1,
        size: 20,
        query: searchString,
        stockWarehouseId: 0, //selectedWorkspace,
      };
      dispatch(returnCustomersRequest(body));
    }
  };

  const searchCustomerModalClose = () => {
    onClose();
    setSearchString('')
  };

  const classes = useStyles();
  const { creditHold } = images;

  return (
    <>
      <PosModel
        open={open}
        onClose={searchCustomerModalClose}
        dialogClassName="search-return-customer-modal"
        dialogHeader={
          <div className="search-customer-header">
            <PosTypography title="Return Items" className="title" />
            <PosIconButton
              onClick={searchCustomerModalClose}
              color="primary"
              className="model-header-close-button"
            >
              <CloseIcon className="close-icon" />
            </PosIconButton>
          </div>
        }
      >
        <section className="search-return-customer-modal-section">
          <div className="title-with-search-icon">
            <PosTypography
              title="Select a Customer"
              className="customer-title"
            />
            <PosImage
              src={images.returnSearch}
              className="search-icon"
              alt="return-search-icon"
            />
          </div>
          <PosInput
            variant="outlined"
            type="text"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            id="mainInput"
            autoFocus
            value={searchString}
            onChange={(e) => {
              if (
                !e.target.value.includes("  ") &&
                e.target.value.charAt(0) !== " "
              ) {
                dispatch(clearSearchCustomer());
                setSearchString(e.target.value);
              }
            }}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                if (document?.activeElement === searchCustomerModalSearchRef.current) {
                  searchCustomerModalSearchRef.current.blur()
                }
                dispatch(clearSearchCustomer());
                setPageSize(1);
                setHaveMore(true);
                searchAPI(searchString);
              }
            }}
            className="search-customer-input"
            onFocus={() => {
              dispatch(clearSearchCustomer());
            }}
            placeholder="Search Customer"
            inputRef={searchCustomerModalSearchRef}
          />

          <section className="customer-search-table-main-section">
            <div>
              {searchCustomers?.length ? (
                <div className="products-suggestion-sp product-found-info mt-0">
                  <div className="product-found-text">
                    <span>
                      {`${customerCount} / ${searchCustomersLength} customer${searchCustomersLength > 1 ? "s" : ""} found`}
                    </span>
                  </div>
                  <div className="horizontal-liner-sp product-found-horizontal-line" />
                </div>
              ) : (
                <Box sx={{ width: "100%" }}>
                  {error === "errors" && (
                    <div className="products-found product-found-info">
                      <div className="product-found-text">
                        <span className="int-14-reg">No customers found</span>
                      </div>
                      <div className="horizontal-liner-sp product-found-horizontal-line" />
                    </div>
                  )}
                </Box>
              )}
              <div className="scroll-styling return-product-modal-data">
                <TableContainer className="customer-search-table-main-container">
                  <InfiniteScroll
                    dataLength={searchCustomersData?.length || 0}
                    next={() => handleScroll()}
                    height={500}
                    hasMore={doesHaveMore}
                    loader={false}
                    scrollThreshold={0.9}
                  >
                    <Table
                      className={`${classes.root} customer-search-table`}
                      stickyHeader
                      aria-label="sticky table"
                    >
                      {searchCustomersData?.length ? (
                        <TableHead className="customer-search-table-head">
                          <TableRow className="customer-search-table-header-row">
                            <StyledTableCell align="left"></StyledTableCell>
                            <StyledTableCell align="left">
                              CUSTOMER
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              LOCATION
                            </StyledTableCell>
                            <StyledTableCell align="left" className="searched-customer-phone">
                              CONTACT
                            </StyledTableCell>
                            <StyledTableCell align="left" className="searched-customer-order-date">
                              LAST ORDER
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                      ) : null}
                      {searchCustomersData?.length ? (
                        <TableBody className="table-border-styling">
                          {searchCustomersData?.map(
                            (mainItem, index) => {
                              const { creditHoldFlag, phone, lastOrderedAt, city, state, companyName, posCustCode } = mainItem
                              return (
                                <StyledTableRow
                                  className={`table-divider ${active ? "active" : ""
                                    }`}
                                  key={index + 1}
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor:
                                      index === selectedIndex
                                        ? "lightgray"
                                        : "white",
                                    borderRadius:
                                      index === selectedIndex ? 30 : 0,
                                  }}
                                  onClick={async () => {
                                    handleSerchCustomer(mainItem);
                                    dispatch(suggestedProductsClear());
                                    handleProductSelect(mainItem);
                                    setTimeout(() => {
                                      const data = {
                                        ...posFlowData,
                                        customer: mainItem,
                                        shippingCharge: {
                                          shippingAmt: 0,
                                          shippingAmtTax: 0,
                                        },
                                        cartProducts: [],
                                        addInStorage: []
                                      };
                                      dispatch(setPosFlowData(data));
                                    }, 1000);
                                  }}
                                  ref={tableRowRef[index]}
                                  id={`tableRowRef${index}`}
                                >
                                  <StyledTableCell>
                                    {
                                      <div
                                        style={{
                                          backgroundColor:
                                            colors[index % 7],
                                        }}
                                        className={`text-center user-avatar-list`}
                                      >
                                        <span style={{ color: "black" }}>
                                          {companyName ? companyName
                                            .match(/(^\S\S?|\b\S)?/g)
                                            .join("")
                                            .match(/(^\S|\S$)?/g)
                                            .join("")
                                            .toUpperCase() : null}
                                        </span>
                                      </div>
                                    }
                                  </StyledTableCell>

                                  <StyledTableCell className="int-16-reg d-flex flex-wrap align-items-center">
                                    {renamePersonData(posCustCode, companyName)}
                                    {creditHoldFlag ? (
                                      <PosImage
                                        src={creditHold}
                                        alt="credit-hold"
                                        className="marginleft10"
                                      />
                                    ) : null}
                                  </StyledTableCell>

                                  <StyledTableCell className="int-16-reg">
                                    {city && state ? `${city}, ${state}` : ''}
                                  </StyledTableCell>
                                  <StyledTableCell className="int-16-reg searched-customer-phone">
                                    <div>
                                      {phone}
                                    </div>
                                  </StyledTableCell>
                                  <StyledTableCell className="int-16-reg searched-customer-order-date">
                                    {lastOrderedAt ===
                                      "0001-01-01T00:00:00+00:00"
                                      ? ""
                                      : moment(
                                        lastOrderedAt
                                      ).format("MMM DD, YYYY")}
                                  </StyledTableCell>
                                </StyledTableRow>)
                            }
                          )}
                        </TableBody>
                      ) : searchString ? (
                        <TableBody>
                          <TableRow>
                            <StyledTableCell
                              style={{
                                paddingLeft: 5,
                                borderBottomWidth: 0,
                              }}
                            >
                            </StyledTableCell>
                          </TableRow>
                        </TableBody>
                      ) : (
                        <></>
                      )}
                    </Table>
                  </InfiniteScroll>
                </TableContainer>
              </div>
            </div>
          </section>
        </section>
      </PosModel>
    </>
  );
};
export default SearchCustomerModel;
