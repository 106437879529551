import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import MaterialIcon from "apps/pos/Common/uiCore/MaterialIcon";
import ReturnToLineItem from "../ReturnToLineItem";

import { fiveWarehouseID } from "constants/constant";
import { Numbers, tabType } from "constants/enum";
import { PosResponseProps, RootState } from "models";
import { setMoveTabDisable } from "sagas/pos/actions";
import { getLocationData } from "utils/getLocationData";
import { getLocalStoraGeItem, saleable } from "utils/utils";
import "./ReturnToGroup.scss";
import { useAllRowsData } from "apps/pos/UnitsAvailable/AdjustStock/queryHooks/useAllRowsData";

export const newReturnLine = () => {
  return {
    id: crypto.randomUUID().toString(),
    row: { id: "", name: "" },
    unit: { id: "", name: "" },
    shelf: { id: "", name: "" },
    returnQty: "",
    replaceQty: "",
  };
};

interface ReturnToGroupProps {
  // will give proper types later.
  setLocationData?: any;
  disabled?: boolean;
  editReturnTo?: any;
  cartEditModalData?: any;
  disabledMultiple?: boolean;
  disableReturnReplace?: boolean;
  toLocationShelfDetails?: any;
  getLocationProductWise?: boolean;
  setSingleLocationData?: any;
  tabValue?: number | string;
  units?: any;
  setUnits?: any;
  unitsErrors?: any;
  setUnitsErrors?: any;
  unitsLoading?: boolean;
  setUnitsLoading?: any;
  shelves?: any;
  setShelves?: any;
  shelvesErrors?: any;
  setShelvesErrors?: any;
  shelvesLoading?: boolean;
  setShelvesLoading?: any;
  isReturnCart?: boolean;
  activeFlag?: number
  isNonSaleableHide?: boolean;
  reason?: number,
  productStockWarehouseId?: number
}

export const ReturnToGroup = ({
  setLocationData = (returnTo) => {
    return returnTo
  },
  disabled = false,
  editReturnTo,
  cartEditModalData,
  disabledMultiple = false,
  disableReturnReplace = false,
  toLocationShelfDetails = undefined,
  getLocationProductWise = false,
  setSingleLocationData = (returnTo) => {
    return returnTo
  },
  tabValue,
  activeFlag,
  units,
  setUnits,
  unitsErrors,
  setUnitsErrors,
  unitsLoading,
  setUnitsLoading,
  shelves,
  setShelves,
  shelvesErrors,
  setShelvesErrors,
  shelvesLoading,
  setShelvesLoading,
  isReturnCart = false,
  isNonSaleableHide,
  reason,
  productStockWarehouseId
}: ReturnToGroupProps) => {

  const dispatch = useDispatch();
  const { } = useAllRowsData(productStockWarehouseId); // fetch all rows data
  const [returnTo, setReturnTo] = useState([]);
  const [init, setInit] = useState(true);
  const { zero } = Numbers
  const { productsData, allRowsData, allRowDataLoading, singleRowDataLoading, allRowsDataError, singleRowsData, singleRowsDataError, inStockOutSuccess } = useSelector<RootState, PosResponseProps>(
    (state) => ({ ...state.pos, ...state.persistReducer })
  );
  const { stockWarehouseId } = getLocalStoraGeItem("workspace") || {}
  // set an initial line if returnTo is empty
  useEffect(() => {
    if (returnTo.length) return;
    setReturnTo(() => [newReturnLine()]);
  }, [returnTo]);

  // set an initial line if returnTo is empty
  useEffect(() => {
    setSingleLocationData && setSingleLocationData(returnTo[0]);
  }, [returnTo]);

  useEffect(() => {
    if ((tabValue === tabType.remove && singleRowsData?.length === 1 && getLocationProductWise) || (tabValue === tabType.move && singleRowsData?.length === 1 && getLocationProductWise)) {
      if (inStockOutSuccess || (units?.length === 1 && shelves?.length === 1)) return

      const singleRow = singleRowsData?.find((item) => Boolean(item))
      setUnitsLoading(true)
      getLocationData({ setUnitsLoading, setUnits, setShelvesLoading, setShelves, setShelvesErrors, setUnitsErrors, row: singleRow, productsData, getLocationProductWise })
    }

  }, [singleRowsData])

  const saleableRows = useMemo(() => Array.isArray(allRowsData) ? allRowsData.filter(saleable) : [], [allRowsData])

  useEffect(() => {
    if (tabValue === tabType.add && saleableRows.length === 1 && !units?.length && !shelves?.length && !isReturnCart) {
      const row = saleableRows?.find((item) => Boolean(item))
      setUnitsLoading(true)
      getLocationData({
        setUnitsLoading,
        setUnits,
        setShelvesLoading,
        setShelves,
        setShelvesErrors,
        setUnitsErrors,
        row,
        productsData,
        getLocationProductWise
      })
    }

  }, [tabValue, isReturnCart])

  useEffect(() => {
    if (saleableRows.length === 1 && units?.length === 1 && shelves?.length === 1 && (!isReturnCart && stockWarehouseId !== fiveWarehouseID)) {
      const row = saleableRows?.find((item) => Boolean(item))
      const unit = units?.find((item) => Boolean(item))
      const shelf = shelves?.find((item) => Boolean(item))

      setReturnTo(current => {
        const singleCurrent = current[0]
        const newArray = [...current];

        const updatedArray = {
          ...singleCurrent,
          row: { id: row?.stockRowID ?? row?.rowID, name: row?.rowName },
          unit: { id: unit?.stockUnitID ?? unit?.unitID, name: unit?.unitName },
          shelf: { id: shelf?.stockShelfID ?? shelf?.shelfID, name: shelf?.shelfName },
        }
        newArray.splice(0, 1, updatedArray);
        return newArray;
      });
    }

  }, [saleableRows, units, shelves])

  useEffect(() => {
    if (singleRowsData.length === 1 && units?.length === 1 && shelves?.length === 1 && tabValue !== tabType.add && (!isReturnCart && stockWarehouseId !== fiveWarehouseID)) {

      const row = singleRowsData?.find((item) => Boolean(item))
      const unit = units?.find((item) => Boolean(item))
      const shelf = shelves?.find((item) => Boolean(item))

      setReturnTo(current => {
        const singleCurrent = current[0]
        const newArray = [...current];
        const updatedArray = {
          ...singleCurrent,
          row: { id: row?.stockRowID ?? row?.rowID, name: row?.rowName },
          unit: { id: unit?.stockUnitID ?? unit?.unitID, name: unit?.unitName },
          shelf: { id: shelf?.stockShelfID ?? shelf?.shelfID, name: shelf?.shelfName },
        }
        newArray.splice(0, 1, updatedArray);
        return newArray;
      });
    }

  }, [singleRowsData, units, shelves])
  useEffect(() => {
    if (saleableRows?.length === 1 && units?.length === 1 && shelves?.length === 1 && (!isReturnCart && stockWarehouseId !== fiveWarehouseID)) {
      (tabValue === tabType.add) && dispatch(setMoveTabDisable(true))
    }
  }, [saleableRows, units, shelves])

  const addReturnLine = () => {
    setReturnTo(() => [...returnTo, newReturnLine()]);
  };

  const handleRemoveLine = (id) => {
    setReturnTo((current) => current.filter((line) => line.id !== id));
  };

  useEffect(() => {
    if (editReturnTo) {
      if (init) {
        setInit(false);
        if (isReturnCart) {
          setReturnTo(editReturnTo);
        }
      } else {
        setLocationData(returnTo);
      }
    }
  }, [returnTo, editReturnTo]);

  return (
    <>
      {!disableReturnReplace && (
        <div className="return-to-header">
          <span>Location</span>
          {
            reason == 6 ? <span>Overshipped Qty.</span> :
              <>
                <span>Credit Qty.</span>
                {activeFlag !== zero && <span>Replacement Qty.</span>}
              </>
          }
        </div>
      )}

      <div className="return-to-line-items">
        {returnTo.map((line) => (
          <ReturnToLineItem
            key={line.id}
            initialLocation={line}
            cartEditModalData={cartEditModalData}
            disabledMultiple={disabledMultiple}
            disableReturnReplace={disableReturnReplace}
            toLocationShelfDetails={toLocationShelfDetails}
            getLocationProductWise={getLocationProductWise}
            removeLine={() => {
              handleRemoveLine(line.id);
            }}
            activeFlag={activeFlag}
            setReturnTo={setReturnTo}
            rowsData={getLocationProductWise ? singleRowsData : allRowsData}
            rowsLoading={getLocationProductWise ? singleRowDataLoading : allRowDataLoading}
            rowsError={getLocationProductWise ? singleRowsDataError : allRowsDataError}
            unitsData={units}
            unitsLoading={unitsLoading}
            unitsError={unitsErrors}
            shelvesData={shelves}
            shelvesLoading={shelvesLoading}
            shelvesError={shelvesErrors}
            isNonSaleableHide={isNonSaleableHide}
            isReturnCart={isReturnCart}
            reason={reason}
          />
        ))}
        {!disabledMultiple && (
          <button
            onClick={addReturnLine}
            disabled={disabled}
            className="return-to-line-add-button"
          >
            <MaterialIcon icon="add" />
          </button>
        )}
      </div>
    </>
  );
};

export default ReturnToGroup;
