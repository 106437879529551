import { useFormik } from "formik";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "apps/pos/Common/Button/Button";
import MaskInput from "apps/pos/Common/maskInput/maskInput";
import {
  PosCheckbox,
  PosInput,
  PosPageHeading,
} from "apps/pos/Common/uiCore";
import { RootState } from "models";

import { letterCapitalRegex, numbersOnlyRegEx, numericOnlyRegex, phoneReplaceD, phoneReplaceOne } from "constants/constant";
import { ContactsIcon } from "constants/icons";
import { PosFlowDataProp } from "constants/interfaces";

import { shippingAddressSchema } from "apps/pos/formik/validationSchema";
import { blockInvalidCharForPhone } from "utils/utils";

import { skipOrdererInfo } from "sagas/persistReducer/actions";


interface PaymentMethodI {
  setShippingCharge: boolean,
  setUnpaidDues: boolean,
  showAllPaymentOptions: boolean,
  showGenericCustomerOptions: boolean,
  cashOption: boolean,
  checkOption: boolean,
  cardOption: boolean,
  payLaterOption: boolean,
  showBalanceDrawer?: boolean,
  showShippingAddress?: boolean
}

interface ShowGenricCustomerI {
  handleRecipientInfoCheck: () => void;
  handleCustomerDetail: (text: any, skip?: boolean) => void;
  setPaymentMethodWrapper?: (text: PaymentMethodI) => void;
  setShowSaveLater?: (text: boolean) => void;
  isSaveForLater?: boolean
}

const ShowGenericCustomerOptions = ({
  handleRecipientInfoCheck,
  handleCustomerDetail,
  setPaymentMethodWrapper,
  setShowSaveLater,
  isSaveForLater = false
}: ShowGenricCustomerI) => {
  const dispatch = useDispatch()
  const {
    posFlowData,
    recipientSwitchInfo,
  } = useSelector<RootState, PosFlowDataProp>((state) => state.persistReducer);

  const isFullyPicked = useMemo(() => {
    const sumOrderQty = posFlowData?.cartProducts.reduce((acc, item) => acc + Number(item.qtyOrdered), 0);
    const sumPickedQty = posFlowData?.cartProducts.reduce((acc, item) => acc + Number(item.totalPickedValue)
      , 0);
    return (sumOrderQty === sumPickedQty);
  }, [posFlowData?.cartProducts]);
  const { customer } = posFlowData || {}
  const {
    intCode,
    extn,
    phoneNumber,
    fullName,
    email,
    companyName,
    isStorePickup
  } = useMemo(() => {
    const { shiptoFacilities, firstName: persistFirstName, lastName: persistLastName, email: persistEmail, phone, companyName: persistCompanyName } = posFlowData || {};

    const { recipient, isStorePickup } = shiptoFacilities || {};
    const { firstName, lastName, phone1: areaCode, phone2: prefix, phone3: lineNum, phone4: extn, email, companyName: recipientCompany } = recipient || {}
    let phoneNumber = "";
    let custoNum = "";
    let emailAddress = email;
    if (recipientSwitchInfo && areaCode && prefix && lineNum) {
      phoneNumber = `${areaCode} ${prefix} ${lineNum}`;
    }
    if (email === "info@goodmart.com") {
      emailAddress = "";
    }
    if (phone?.areaCode && phone?.prefix && phone?.lineNum) {
      custoNum = `${phone?.areaCode} ${phone?.prefix} ${phone?.lineNum}`;
    } else {
      const numberPhn = phone?.number;
      custoNum = numberPhn?.length === 10 ? phone?.number.replace(phoneReplaceD, '').replace(phoneReplaceOne, '') : phone?.number;
    }
    const fullname = firstName ? `${firstName} ${lastName}` : `${firstName ?? ''}${lastName ?? ''}`;
    const persistFullName = persistFirstName ? `${persistFirstName ?? ''} ${persistLastName ?? ''}` : `${persistFirstName ?? ''}${persistLastName ?? ''}`;

    return recipientSwitchInfo ? {
      intCode: 1,
      extn,
      phoneNumber,
      email: emailAddress,
      fullName: fullname,
      isStorePickup,
      companyName: recipientCompany,
    } : {
      intCode: 1,
      extn: phone?.extn ?? '',
      phoneNumber: custoNum,
      email: `${persistEmail ?? ''}`,
      fullName: persistFullName ?? '',
      companyName: persistCompanyName ?? '',
      isStorePickup,
    }
  }, [posFlowData?.shiptoFacilities, recipientSwitchInfo])

  const onSubmit = (values) => {
    handleCustomerDetail(values);
    dispatch(skipOrdererInfo(false))
    if (setShowSaveLater) {
      setShowSaveLater(true)
    } else {
      const customCredit = customer?.storeCreditAmt;
      setPaymentMethodWrapper({
        setShippingCharge: false,
        setUnpaidDues: false,
        showAllPaymentOptions: !customCredit,
        showGenericCustomerOptions: false,
        cashOption: false,
        checkOption: false,
        cardOption: false,
        payLaterOption: false,
        showBalanceDrawer: Boolean(customCredit),
        showShippingAddress: false
      })
    }
  }

  const { handleSubmit, values, setFieldValue, errors, touched } = useFormik({
    initialValues: {
      fullName,
      countryCode: intCode,
      phoneNumber,
      email,
      extCode: extn,
      companyName,
    },
    enableReinitialize: true,
    validationSchema: shippingAddressSchema,
    onSubmit: (values) => onSubmit(values)
  });

  const handleFullnameChange = (value, fieldName, regex) => {
    value = value.trimStart()
    if (value.split(" ").length - 1 < 2) {
      handleChange(value, fieldName, regex)
    }
  }

  const handleChange = (value, fieldName, regex, isCheck = true) => {
    if (value === "" || (isCheck && regex?.test(value))) {
      setFieldValue(fieldName, value);
    } else {
      setFieldValue(fieldName, value);
    }
  }

  const handleEmailChange = (value, fieldName) => {
    if (
      !value.includes("  ") &&
      value.charAt(0) !== " "
    ) {
      setFieldValue(fieldName, value);
    }
  }

  const handleCompanyNameChange = (fieldName, value) => {
    setFieldValue(fieldName, value);
  }

  const skipAndContinue = () => {
    dispatch(skipOrdererInfo(true))
    const customCredit = customer?.storeCreditAmt;
    setPaymentMethodWrapper({
      setShippingCharge: false,
      setUnpaidDues: false,
      showAllPaymentOptions: !customCredit,
      showGenericCustomerOptions: false,
      cashOption: false,
      checkOption: false,
      cardOption: false,
      payLaterOption: false,
      showBalanceDrawer: Boolean(customCredit),
      showShippingAddress: false
    })
  }
  const onExtCodeChange = (value, setFieldValue) => {
    blockInvalidCharForPhone(value)
    const numericValue = value.replace(numericOnlyRegex, '');
    setFieldValue('extCode', numericValue);
    if (numbersOnlyRegEx.test(value)) {
      setFieldValue('extCode', value);
    }
  }

  return (
    <div>
      <div className="pricing-modal-header mb-4">
        <h1 className="pricing-schedule-heading">
          <div className="d-flex justify-content-between flex-wrap align-items-center">
            <div>
              <PosPageHeading heading={isSaveForLater ? "" : "Payment"} grayHeading />
              <PosPageHeading heading={isSaveForLater ? "Add Orderer Details" : "Add Customer Details"} blackHeading />
            </div>
            <div>
              <ContactsIcon />
            </div>
          </div>
        </h1>
      </div>
      <div className="save-later-text-gray-bg save-later-text mt-0 mr-0">
        {isSaveForLater ? <span className="save-later-text-style int-14-reg">
          You have selected a Generic customer account for this cart. Add
          customer details to help track this order in the future.
        </span> : <span className="save-later-text-style int-14-reg">
          You have selected a Generic customer account for this cart. Add
          customer details to help track this order in the future.
        </span>
        }
      </div>
      <div className="mt-4 mb-4">
        <div className={`hide-price-checkbox mt-0 ${isStorePickup ? 'disable-section' : ''}`}>
          <PosCheckbox
            checked={recipientSwitchInfo}
            onChange={handleRecipientInfoCheck}
            label="Use same details as Recipient info."
            disabled={isStorePickup}
            className={`checkbox-focus ${isStorePickup ? 'disabled-checkbox' : ''}`}
          />
        </div>
      </div>

      <>
        <form className="" id="addContactInfoForm">
          <div className="facility-box">
            <div>
              <span className="int-20-reg">Full name</span>
              <span className="phone-optional-head ml-3">(First name, space, last name)</span>
            </div>
            <div className="mt-2 mb-4">
              <PosInput
                variant="outlined"
                id="outlined-basic"
                className="double-bordered-input"
                name="fullName"
                value={values.fullName}
                onChange={(e) => handleFullnameChange(e.target.value, "fullName", letterCapitalRegex)}
                onBlur={() => {
                  handleCustomerDetail(values)
                }}
              />
            </div>
            {errors.fullName && touched.fullName ? (
              <p className="validation-error-text">
                {errors.fullName}
              </p>
            ) : null}
          </div>
          <div className="facility-box">
            <div>
              <span className="int-20-reg">Company name</span>
              <span className="phone-optional-head ml-3">(Optional)</span>
            </div>
            <div className="mt-2 mb-4">
              <PosInput
                variant="filled"
                id="filled-basic"
                InputProps={{ disableUnderline: true }}
                className="double-bordered-input search-input-focus"
                name="companyName"
                value={values.companyName}
                onChange={(e) => handleCompanyNameChange("companyName", e.target.value)}
                onBlur={() => {
                  handleCustomerDetail(values)
                }}
              />
            </div>

          </div>
          <div className="facility-box">
            <div className="phone-number-title">
              <div>
                <span className="form-field-title">Phone Number</span>
                <span className="phone-optional-head ml-3">(Optional)</span>
              </div>
              <div className="phone-ext-title">
                <span>Ext</span>
                <span className="phone-optional-head ml-3">(Optional)</span>
              </div>
            </div>
            <div className="facilit-phone-number mt-2 mb-4">
              <PosInput
                variant="filled"
                id="filled-basic"
                InputProps={{ disableUnderline: true }}
                inputProps={{ maxLength: 3 }}
                disabled
                className="phone-country-code-input mb-0 mt-2"
                value={values.countryCode}
                onChange={(e) => handleChange(e.target.value, "countryCode", letterCapitalRegex)}
                onWheel={(e) => (e?.target as HTMLElement)?.blur()}
                onBlur={() => {
                  handleCustomerDetail(values)
                }}
              />
              <div className="masked-phone-number">
                <MaskInput
                  placeholder=""
                  mask="999 999 9999"
                  isClassName="masked-phone-number-input mb-0 tabInput-mobile"
                  id="filled-basic"
                  name="phoneNumber"
                  alwaysShowMask={true}
                  onChange={(e) => handleEmailChange(e.target.value, "phoneNumber")}
                  onKeyPress={(e) => blockInvalidCharForPhone(e)}
                  value={values.phoneNumber || ""}
                  onWheel={(e) => (e?.target as HTMLElement)?.blur()}
                  onBlur={() => {
                    handleCustomerDetail(values)
                  }}
                />
              </div>
              <PosInput
                variant="filled"
                InputProps={{ disableUnderline: true }}
                id={"extCode"}
                className="phone-ext-input mb-0 mt-2 search-input-focus"
                value={values.extCode}
                inputProps={{ maxLength: 4 }}
                onChange={(e) => { onExtCodeChange(e.target.value, setFieldValue) }}
                onWheel={(e) => (e?.target as HTMLElement)?.blur()}
                onBlur={() => {
                  handleCustomerDetail(values)
                }}
              />
            </div>
            {errors.phoneNumber && touched.phoneNumber ? (
              <p className="validation-error-text">
                <>{errors.phoneNumber}</>
              </p>
            ) : null}
          </div>
          <div className="facility-box">
            <div>
              <span className="int-20-reg">Email</span>
              <span className="phone-optional-head ml-3">(Optional)</span>
            </div>
            <div className="mt-2 mb-4">
              <PosInput
                variant="filled"
                id="filled-basic"
                InputProps={{ disableUnderline: true }}
                className="double-bordered-input search-input-focus"
                name="email"
                value={values.email}
                onChange={(e) => handleEmailChange(e.target.value, "email")}
                onBlur={() => {
                  handleCustomerDetail(values)
                }}
              />
            </div>
            {errors.email && touched.email ? (
              <p className="validation-error-text">
                <>{errors.email}</>
              </p>
            ) : null}
          </div>
          <div className="d-flex">
            {
              (!isSaveForLater && isFullyPicked) ?
                <>
                  <Button
                    classes={`skip-shipping-btn button-focus-class ${isSaveForLater ? "save-skip-btn" : "payment-skip-btn"} `}
                    onClick={skipAndContinue}
                  >
                    <span className="btn-text">Skip</span>
                  </Button>
                </> : null
            }
            <Button
              classes="skip-shipping-btn set-charges order-details-continue-btn button-focus-class"
              onClick={handleSubmit}
            >
              <span className="btn-text">Continue</span>
            </Button>
          </div>
        </form>
      </>
    </div>
  );
};

export default ShowGenericCustomerOptions;
