import { AnyAction } from "redux";

import texts from "apps/pos/locales";
import { getLocalStoraGeItem } from "utils/utils";
import * as constants from "./constants";

const initialState = {
  posFlowData: {
    priceVisible: true,
    cartProducts: [] as any[],
  },
  loadCartDetails: {},
  errorText: "",
  loadCartStatus: "",
  error: "",
  isPrivacy: false,
  isTaxExempted: false,
  isPickAddress: true,
  isShowAddress: true,
  errorData: {},
  newTaxRate: "",
  newShipTaxRate: "",
  saveProductInfo: [],
  changePriceInfo: false,
  showOriginalPrice: false,
  showAlreadyPickedInfo: false,
  backUpProducts: [],
  exemptShipToLocation: false,
  shippingAddressSelected: false,
  cartLoading: false,
  setModifiedPriceInfo: false,
  recipientSwitchInfo: false,
  isDataLoad: false,
  setNoLoadDetails: false,
  personNotesInfo: [],
  returnFlowData: {},
  shippingFeeData: [],
  restockingFeeData: [],
  handlingFeeValue: [],
  returnAllDataArray: [],
  loadReturnData: false,
  loadProductDraft: false,
  isLoadCartSuccess: false,
  tabOpenIndex: 0,
  starredProductsDetails: null,
  customerAccount: {},
  savedDraftsList: [],
  isTaxMessage: true,
  isOpenAddCustomer: false,
  isMail: false,
  isGPMCustom: false,
  isOrderTotalCustom: false,
  originalProfit: 0,
  originalTotal: 0,
  isPageReload: false,
  skipOrdererInfo: false,
  salesman: {},
  taxScheduleToBeSent: "",
  subTotalAmountReducer: "",
  shippingAmountReducer: "",
  taxAmountReducer: "",
  shipTaxAmtToBeSentReducer: "",
  shippingTaxAmountReducer: "",
  takerIdToBeSentReducer: "",
  taxRateToBeSentReducer: "",
  isManuallySetTaxExempt: false,
  isCustomerAlertHide: true,
  starredMetadataSb: [],
  refundMethodCheckedArray: [],
  allowPickupCheck: false,
  setUpdatedStockDataOnRefreshLoading: false,
  setUpdatedStockDataOnRefreshError: false
};

const { privateText } = texts

const reducer = function persistReducer(
  state = initialState,
  action: AnyAction
) {
  switch (action.type) {
    case constants.SET_POS_FLOW_DATA:
      let pageReload = false;
      if (action.payload?.addDocument !== undefined) {
        const uplodFiles = action?.payload?.addDocument.filter((item) => item?.documentSaveType === privateText);
        uplodFiles.forEach((item) => {
          if (item?.originalFile?.size === undefined) {
            pageReload = true;
          }
        });
      }

      const cartDataWithStock = action.payload?.cartProducts?.length > 0 && action.payload?.cartProducts?.filter((item) => {
        return item?.qtyInStock > 0
      })
      const cartDataWithoutStock = action.payload?.cartProducts?.length > 0 && action.payload?.cartProducts?.filter((item) => {
        if (item?.qtyInStock === 0) {
          return {
            ...item,
            uniqueProductId: '',
          }
        }
      })
      const cartDataWithStockAndId = cartDataWithStock?.length > 0 && cartDataWithStock?.map((item, index) => {
        return {
          ...item,
          uniqueProductId: index
        }
      })

      const cartAllDataInfo = [...cartDataWithStockAndId || [], ...cartDataWithoutStock || []]
      const cartAllDataInfoWithIndex = cartAllDataInfo?.map((item, index) => {
        return {
          ...item,
          indexNumber: index,
          lineIdData: index + 1
        }
      })

      const uniqueDocument = action.payload?.addDocument && action.payload?.addDocument.filter(function ({ file, typeId, readOnly }) {
        const key = `${file}${typeId}${readOnly}`;
        return !this.has(key) && this.add(key);
      }, new Set);

      return { ...state, posFlowData: { ...action.payload, cartProducts: cartAllDataInfoWithIndex || [], addDocument: uniqueDocument || [] }, isLoading: false, isPageReload: pageReload };
    case constants.RESET_POS_FLOW_DATA:
      return { ...initialState };
    case constants.LOAD_CART_START:
      return { ...state, isLoading: true, cartLoading: true, isDataLoad: false };
    case constants.LOAD_CART_SUCCESS:
      const currentTaker = getLocalStoraGeItem("taker");
      const data = action.payload.data.products;
      //   .map((item) =>{
      //   return ({
      //     ...item,
      //     clearance: item?.productPrices?.find(
      //       (item: ProductPriceProps) => item.type === typeClearance
      //     ),
      //     discontinued: item?.productPrices?.find(
      //       (item: ProductPriceProps) => item.type === typeDiscontinued
      //     ),
      //     salesOverride: item?.productPrices?.find(
      //       (item: ProductPriceProps) => item.type === typeSalesOverride
      //     ),
      //     priceScheduleSingle: item?.productPrices?.find(
      //       (item: ProductPriceProps) => item.type === typePriceScheduleSingle
      //     ),
      //     priceScheduleVolume: item?.productPrices?.filter(
      //       (item: ProductPriceProps) => item.type === typePriceScheduleVolume
      //     ),
      //     lastPosRetailPrice: item?.productPrices?.find(
      //       (item: ProductPriceProps) => item.type === typeLastPosRetailPrice
      //     ),
      //     productPrices: item?.productPrices.map((item) => {
      //       return {
      //         ...item,
      //         //TODO: do not remove this undefined as this is part of flow
      //         setPrice: undefined,
      //       };
      //     }),
      //   })}
      // );
      const salesman = data?.length ? { ...currentTaker, salespersonName: currentTaker?.fullName } : {}
      return {
        ...state,
        loadCartDetails: { ...action.payload.data, products: data, salesman },
        loadCartStatus: action.payload.status,
        isLoading: false,
        cartLoading: false,
        isDataLoad: true,
        isLoadCartSuccess: true,
      };
    case constants.LOAD_CART_ERROR:
      return {
        ...state,
        loadCartDetails: [],
        loadCartStatus: "error",
        errorData: action.error,
        isLoading: false,
        cartLoading: false,
        isDataLoad: false
      };
    case constants.CLEAR_LOAD_CART_SUCCESS_FLAG:
      return {
        ...state, isLoadCartSuccess: false, cartLoading: false
      }
    case constants.RESET_LOAD_CART:
      return {
        ...state,
        loadCartDetails: [],
        cartLoading: false,
        loadCartStatus: "",
      }

    case constants.SET_NO_LOAD_DETAILS:
      return { ...state, setNoLoadDetails: action.payload };
    case constants.SET_PRIVACY:
      return { ...state, isPrivacy: action.payload };
    case constants.SET_EXEMPT_TAX:
      return { ...state, isTaxExempted: action.payload };
    case constants.SET_TO_PICKUP:
      return { ...state, isPickAddress: action.payload };
    case constants.IS_TAX_MESSAGE:
      return { ...state, isTaxMessage: action.payload };
    case constants.CLEAR_LOAD_CART:
      return initialState.loadCartDetails;
    case constants.SET_SHIP_TO_ADDRESS:
      return { ...state, isShowAddress: action.payload };

    case constants.SET_TAX_RATE_OF_SHIPPING_ADDRESS:
      return { ...state, newShipTaxRate: action.payload };

    case constants.SET_CUSTOM_TAX_RATE:
      return { ...state, newTaxRate: action.payload };

    case constants.SAVE_PRODUCT:
      return { ...state, saveProductInfo: action.payload };

    case constants.GET_CHANGE_PRICE_INFO:
      return { ...state, changePriceInfo: action.payload };

    case constants.SHOW_ORIGINAL_PRICE:
      return { ...state, showOriginalPrice: action.payload };

    case constants.SHOW_ALREADY_PICKED_INFO:
      return { ...state, showAlreadyPickedInfo: action.payload };

    case constants.STORE_BACKUP_PRODUCTS:
      return { ...state, backUpProducts: action.payload };

    case constants.EXEMPT_SHIP_TO_LOCATION:
      return { ...state, exemptShipToLocation: action.payload };
    case constants.SET_MODIFIED_PRICE:
      return { ...state, setModifiedPriceInfo: action.payload };

    case constants.SHIPPING_ADDRESS_SELECTED:
      return { ...state, shippingAddressSelected: action.payload };

    case constants.RECIPIENT_INFO_SWITCH_INFO:
      return { ...state, recipientSwitchInfo: action.payload };

    case constants.CLEAR_CART_BACKUP_PRODUCTS:
      return { ...state, clearCartBackupData: action.payload };

    case constants.PERSON_NOTES:
      return { ...state, personNotesInfo: action.payload };

    case constants.SET_RETURN_FLOW_DATA:
      return { ...state, returnFlowData: action.payload, returnFlowDataLoading: false };

    case constants.SHIPPING_FEE_DATA:
      const tempIndex = state?.shippingFeeData?.findIndex((item) => {
        return item?.orderId === action?.payload?.orderId
      })
      const tempData = state?.shippingFeeData || [];
      if (tempIndex > -1) {
        tempData[tempIndex] = action.payload
      }
      else {
        tempData.push(action.payload)
      }
      return { ...state, shippingFeeData: tempData };

    case constants.SHIPPING_FEE_CLEAR_DATA:
      return { ...state, shippingFeeData: [] };

    case constants.RESTOCKING_FEE_DATA:
      const tempRestockingIndex = state?.restockingFeeData?.findIndex?.((item) => {
        return item?.orderId === action?.payload?.orderId
      })
      const tempRestockingData = state?.restockingFeeData || [];
      if (tempRestockingIndex > -1) {
        tempRestockingData[tempRestockingIndex] = action.payload
      }
      else {
        tempRestockingData.push(action.payload)
      }
      return { ...state, restockingFeeData: tempRestockingData };
    case constants.RESTOCKING_FEE_REPLACE_DATA:
      return { ...state, restockingFeeData: action?.payload };
    case constants.RESTOCKING_FEE_CLEAR_DATA:
      return { ...state, restockingFeeData: [] };

    case constants.HANDLING_FEE_DATA:
      const tempHandlingIndex = state?.handlingFeeValue?.findIndex?.((item) => {
        return item?.orderId == action?.payload?.orderId
      })

      const tempHandlingData = state?.handlingFeeValue || [];
      if (tempHandlingIndex > -1) {
        tempHandlingData[tempHandlingIndex] = action.payload
      }
      else {
        tempHandlingData.push(action.payload)
      }
      return { ...state, handlingFeeValue: tempHandlingData };
    case constants.HANDLING_FEE_REPLACE_DATA:
      return { ...state, handlingFeeValue: action?.payload }
    case constants.HANDLING_FEE_CLEAR_DATA:
      return { ...state, handlingFeeValue: [] };

    case constants.LOAD_RETURN_DATA:
      return { ...state, loadReturnData: action.payload };

    case constants.LOAD_PRODUCT_DRAFT_ACTION:
      return { ...state, loadProductDraft: action.payload };

    case constants.SET_TAB_INDEX:
      return { ...state, tabOpenIndex: action.payload }

    case constants.RETURN_ALL_DATA_ARRAY:
      return { ...state, returnAllDataArray: action.payload }

    case constants.ADD_NEW_CUSTOMER_INFO_START:
      return {
        ...state,
        isLoading: true,
        customerAddSuccess: false,
        errorAddNewCustomer: false,
        isCustomerAdd: true,
      };
    case constants.ADD_NEW_CUSTOMER_INFO_SUCCESS:
      return {
        ...state,
        newCustomerData: action.payload.data,
        isLoading: false,
        customerAddSuccess: true,
        errorAddNewCustomer: false,
        isCustomerAdd: false
      };
    case constants.ADD_NEW_CUSTOMER_INFO_ERROR:
      return {
        ...state,
        isLoading: false,
        customerAddSuccess: false,
        error: action.error,
        errorAddNewCustomer: false,
        isCustomerAdd: false
      };
    case constants.ADD_NEW_CUSTOMER_INFO_CLEAR:
      return {
        ...state,
        isLoading: false,
        customerAddSuccess: false,
        error: {},
        errorAddNewCustomer: false,
        isCustomerAdd: false
      };

    case constants.SET_NEW_CUCTOMER_CODE:
      return {
        ...state,
        posFlowData: {
          ...state.posFlowData,
          customer: {
            ...(state.posFlowData as any).customer,
            posCustCode: action.payload.posCustCode
          }
        }
      };

    case constants.SET_PRICE_VISIBLE_BY_DEFAULT:
      return {
        ...state,
        posFlowData: {
          ...state.posFlowData,
          priceVisible: true,
        }
      };
    case constants.SET_STORE_PICKED_ADDRESS:
      return {
        ...state,
        storePickedAddress: action.payload,
      };

    case constants.MANIPULATE_STARRED_PRODUCT_INFO:
      return {
        ...state,
        starredProductsDetails: action.payload
      }
    case constants.CLEAR_STARRED_PRODUCT_INFO:
      return {
        ...state,
        starredProductsDetails: null
      }
    case constants.CUSTOMER_ACCOUNT_INFO:
      return {
        ...state,
        customerAccount: action.payload
      }
    case constants.CLEAR_CUSTOMER_ACCOUNT_INFO:
      return {
        ...state,
        customerAccount: {}
      }
    case constants.GET_DRAFTS_START:
      return { ...state, isLoading: true, isDraftLoading: action.payload.page === 1 ? true : false };
    case constants.GET_DRAFTS_SUCCESS:
      return {
        ...state,
        savedDraftsList: {
          data: (state.savedDraftsList && (state.savedDraftsList as any)?.data) ? [...(state.savedDraftsList as any)?.data, ...action.payload.data] : action.payload.data,
          count: action.payload.count,
          total: action.payload.total,
          page: action.payload.page,
          isPaginated: action.payload.isPaginated,
          message: action.payload.message,
          success: action.payload.success,
        },
        isLoading: false,
        isDraftLoading: false
      };
    case constants.OPTIMISTIC_DELETE_DRAFT:
      return {
        ...state,
        savedDraftsList: {
          ...state.savedDraftsList,
          data: (state.savedDraftsList && (state.savedDraftsList as any)?.data) ? (state.savedDraftsList as any).data.filter((item) => item.cartId !== action.payload) : (state.savedDraftsList as any)?.data,
        },
      };
    case constants.GET_DRAFTS_ERROR:
      return {
        ...state,
        savedDraftsList: [],
        isLoading: false,
        isDraftLoading: false
      };
    case constants.GET_DRAFTS_RESET:
      return {
        ...state,
        savedDraftsList: [],
        isLoading: false,
        isDraftLoading: false,
      };
    case constants.ADD_NEW_CUSTOMER_MODEL:
      return {
        ...state,
        isOpenAddCustomer: action.payload,
        isLoading: false,
      }
    case constants.IS_EMAIL:
      return {
        ...state,
        isMail: action.payload,
        isLoading: false,
      }
    case constants.IS_GPM_CUSTOM:
      return {
        ...state,
        isGPMCustom: action.payload,
        isLoading: false,
      }
    case constants.IS_ORDER_TOTAL_CUSTOM:
      return {
        ...state,
        isOrderTotalCustom: action.payload,
        isLoading: false,
      }
    case constants.SET_ORIGINAL_PERCENTAGE:
      return {
        ...state,
        originalProfit: action.payload,
        isLoading: false,
      }
    case constants.SET_ORDER_TOTAL:
      return {
        ...state,
        originalTotal: action.payload,
        isLoading: false,
      }
    case constants.SKIP_ORDERER_INFO:
      return { ...state, skipOrdererInfo: action.payload };
    case constants.SUB_TOTAL_AMOUNT:
      return {
        ...state,
        subTotalAmountReducer: action.payload,
      };
    case constants.TAX_AMOUNT:
      return {
        ...state,
        taxAmountReducer: action.payload,
      };
    case constants.SHIPPING_AMOUNT:
      return {
        ...state,
        shippingAmountReducer: action.payload,
      };
    case constants.SHIPPING_AMOUNT_TO_BE_SENT:
      return {
        ...state,
        shipTaxAmtToBeSentReducer: action.payload,
      };
    case constants.SHIPPING_AMOUNT:
      return {
        ...state,
        shippingAmountReducer: action.payload,
      };
    case constants.SHIPPING_TAX_AMOUNT:
      return {
        ...state,
        shippingTaxAmountReducer: action.payload,
      };
    case constants.TAKER_ID_TO_BE_SENT:
      return {
        ...state,
        takerIdToBeSentReducer: action.payload,
      };
    case constants.TAX_RATE_TO_BE_SENT:
      return {
        ...state,
        taxRateToBeSentReducer: action.payload,
      };
    case constants.SET_EXEMPT_TAX_MANUALLY:
      return { ...state, isManuallySetTaxExempt: action.payload };
    case constants.SET_CUSTOMER_ALERT_HIDE:
      return {
        ...state,
        isCustomerAlertHide: action.payload,
      };
    case constants.MANIPULATE_STARRED_METADATA_SB:
      return { ...state, starredMetadataSb: action.payload }
    case constants.REFUND_METHOD_CHANGE:
      return {
        ...state,
        refundMethodCheckedArray: action.payload,
      }
    case constants.SET_ALLOW_PICKUP:
      return {
        ...state,
        allowPickupCheck: action.payload,
      }
    case constants.UPDATE_CUSTOMER_DATA_MESSAGE:
      return {
        ...state,
        newCustomerData: {},
        customerAddSuccess: false
      };
    case constants.SET_UPDATED_STOCK_DATA_ON_REFRESH_START: {
      return {
        ...state,
        setUpdatedStockDataOnRefreshLoading: true
      }
    }

    case constants.SET_UPDATED_STOCK_DATA_ON_REFRESH_ERROR: {
      return {
        ...state,
        setUpdatedStockDataOnRefreshError: true,
        setUpdatedStockDataOnRefreshLoading: false
      }
    }

    case constants.SET_UPDATED_STOCK_DATA_ON_REFRESH_SUCCESS:
      const stockData = action.payload.updatedStockData.data.reduce((acc, product) => {
        acc[product.productId] = product.stockWarehouses.find(w => w.stockWarehouseId == action.payload.stockWareHouseId)?.unitQtyAvailable || 0;
        return acc;
      }, {});

      return {
        ...state,
        setUpdatedStockDataOnRefreshLoading: false,
        setUpdatedStockDataOnRefreshError: false,
        posFlowData: {
          ...state.posFlowData,
          cartProducts: state.posFlowData?.cartProducts?.map(item => ({
            ...item,
            qtyInStock: stockData[item.productId] !== undefined ? stockData[item.productId] : item.qtyInStock
          }))
        }
      };
    default:
      return state;
  }
};

export default reducer;
