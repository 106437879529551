import { InputAdornment } from "@mui/material";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Container, PosButton, PosImage, PosInput } from "apps/pos/Common/uiCore";
import DynamicTable from "components/Table/Table";
import { snackbarDetail } from "constants/constant";
import { AddCircleIcon, CloseIcon, SearchIcon } from "constants/icons";
import images from "constants/images";
import { PosFlowDataProp } from "constants/interfaces";
import { Paths, SortIndex } from "enums";
import { PosResponseProps, RootState, user } from "models";
import { DocumentSortBy } from "utils/data";
import { keys } from "utils/helper";
import { getLocalStoraGeItem, scrollToSetting } from "utils/utils";
import { dataExportData } from "./constant";

import {
  loadCartRequest,
  recipientInfoSwitchInfoData,
  setPosFlowData,
} from "sagas/persistReducer/actions";
import {
  arrowDownIndexGeneric,
  arrowResetGeneric,
  arrowUpIndexGeneric,
  getShippingFacilitiesRequest,
  setJobNameForAll
} from "sagas/pos/actions";
import { useGetDraftsDetails } from "./hooks/useGetDraftDetails";
import { useGetSalesPersons } from "./hooks/useGetSalesPersons";
import { useGetTakers } from "./hooks/useGetTakers";
import { useDraftSearchParam } from "./hooks/useSearchByJob";

import "apps/pos/pages/Drafts/drafts.scss";
import { snakbarShow } from "sagas/pos/snakbar/snakbar.slice";
import { cartClear } from "services";

const Drafts = () => {
  const dispatch = useDispatch();
  const currentTaker = getLocalStoraGeItem("taker");
  const isActiveCart = window.location.search.includes("cart");
  const navigate = useNavigate();
  const currentWorkspace = getLocalStoraGeItem("workspace");
  const [customerDraftDelete, setCustomerDraftDelete] = useState(false);

  const [doesHaveMore, setHaveMore] = useState<boolean>(true);
  const [searchValue, setSearchValue] = useState("");
  const [selectedPersonId, setSelectedPersonId] = useState(0);
  const [selectedTaker, setSelectedTaker] = useState(0);
  const { salesPersons } = useGetSalesPersons();
  const { takersDetails } = useGetTakers();
  const [sortIndex, setSortIndex] = useState(0);
  const [loadedData, setLoadedData] = useState([]);
  const [downPress, setDownPress] = useState(false);
  const [upPress, setUpPress] = useState(false);
  const { ASCENDING, DESCENDING } = SortIndex;
  const [scrollState, setScrollState] = useState(false);
  const [sortBy, setSetSortBy] = useState(false);
  const [enterPress, setEnterPress] = useState(false);
  const [searchDraftValue, setSearchDraftValue] = useState([]);
  const [hasInitialLoadOccurred, setHasInitialLoadOccurred] = useState(false);

  const searchCustomersDraft = useRef<HTMLInputElement>();
  const { setDraftsParma, draftsValues, isDraftFetching, isDraftSuccess } =
    useGetDraftsDetails();
  const {
    setDraftSearchParam,
    draftSearchData,
    isSuccess,
    isDraftSearchLoading,
    isDraftSearchError,
  } = useDraftSearchParam();

  const { loggedInUser } = useSelector<RootState, user>((state) => state.user);
  const { loadCartDetails, posFlowData, isLoadCartSuccess } = useSelector<
    RootState,
    PosFlowDataProp
  >((state) => state.persistReducer);
  const { selectedIndexGeneric } = useSelector<RootState, PosResponseProps>(
    (state) => state.pos
  );

  const { data: draftsData, page, total } = draftsValues || {};
  const { stockWarehouseId } = currentWorkspace || {};
  const finalData =
    (searchDraftValue?.length || searchValue) && enterPress
      ? searchDraftValue
      : loadedData;

  const body = {
    stockWarehouseId: stockWarehouseId,
    page: 1,
    size: 20,
    sort: sortIndex ? ASCENDING : DESCENDING,
  };

  const finalLoading = hasInitialLoadOccurred
    ? [isDraftFetching]
    : [isDraftSearchLoading];

  const isLoadingApi = finalLoading.includes(true);

  useEffect(() => {
    setHasInitialLoadOccurred(true);
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setSearchDraftValue(draftSearchData);
      setHasInitialLoadOccurred(false);
    }
    if (isDraftSearchError) {
      setSearchDraftValue([]);
      setHasInitialLoadOccurred(false);
    }
  }, [isSuccess, searchValue, isDraftSearchError]);

  useEffect(() => {
    if (isDraftSuccess) {
      if (sortBy) {
        setLoadedData(draftsData);
        setHasInitialLoadOccurred(false);
      } else {
        setLoadedData((prevData) => [...prevData, ...draftsData]);
        setHasInitialLoadOccurred(false);
      }
    }
  }, [isDraftSuccess, sortBy]);

  useEffect(() => {
    setDraftsParma(body);
  }, [sortIndex]);

  useEffect(() => {
    if (isLoadCartSuccess && !isActiveCart) {
      if (loadCartDetails !== undefined && loadCartDetails?.products) {
        const { customer } = loadCartDetails || {};
        const { isPosGeneric, fullName, companyName, phone, email } =
          customer || {};
        if (isPosGeneric) {
          const splitFullname = fullName?.split(" ");
          const genericUserFirstName = splitFullname?.slice(0, -1).join(" ");
          const genericUserLastName = splitFullname[splitFullname?.length - 1];

          const data = {
            ...posFlowData,
            customer: customer,
            firstName: genericUserFirstName || "",
            lastName: genericUserLastName || "",
            phone: phone || "",
            email: email || "",
            companyName: companyName || "",
            isStorePickup: false,
            shippingCharge: {
              shippingAmt: loadCartDetails?.shippingFeeAmt,
            },
          };
          dispatch(recipientInfoSwitchInfoData(false));
          dispatch(setPosFlowData(data));
        }
        // *? If you want to open Continue with old price model then uncomment this code
        // const isPriceChangesProduct = products?.filter(
        //   (item) => item?.isPriceChanged
        // );
        // if (isPriceChangesProduct?.length > 0) {
        //   dispatch(getupdateItemModal(true));
        // }
      }
      navigate(`${Paths.posCart}/${loadCartDetails?.cartId}`);
    }
  }, [isLoadCartSuccess]);

  useEffect(() => {
    async function downPressHandler() {
      if (downPress) {
        scrollToSetting(selectedIndexGeneric);
        if (loadedData?.length) {
          await dispatch(arrowDownIndexGeneric(loadedData.length));
        }
        setDownPress(false);
      }
    }
    downPressHandler();
  }, [downPress]);

  useEffect(() => {
    async function upPressHandler() {
      if (upPress) {
        await dispatch(arrowUpIndexGeneric());
        if (selectedIndexGeneric > -1) {
          scrollToSetting(selectedIndexGeneric);
        }
        setUpPress(false);
      }
    }
    upPressHandler();
  }, [upPress]);

  const handleKeyPress = (e) => {
    const { keyCode } = e;
    const { downarrow, uparrow, tab } = keys;
    if (keyCode === keys.enter) {
      setEnterPress(true);
      if (document.activeElement === searchCustomersDraft.current) {
        setScrollState(true);
        const payload = {
          stockWarehouseId: currentWorkspace?.stockWarehouseId,
          jobName: String(searchValue),
          salesPersonId: Number(selectedPersonId),
          takerId: Number(selectedTaker),
        };
        setDraftSearchParam(payload);
      } else {
        const element = document.querySelector(".Mui-focusVisible");
        if (element) {
          return;
        } else if (selectedIndexGeneric > -1) {
          const { cartId } = loadedData[selectedIndexGeneric];
          handleSearchCustomer(cartId);
        }
      }
    }
    if (keyCode === downarrow) {
      if (document?.activeElement === searchCustomersDraft.current) {
        dispatch(arrowResetGeneric());
        searchCustomersDraft.current.blur();
      }
      setDownPress(true);
    } else if (keyCode === uparrow) {
      setUpPress(true);
    }
    if (keyCode === tab) {
      dispatch(arrowResetGeneric());
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return function cleanup() {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [searchValue, searchCustomersDraft, loadedData]);

  const handleClearSearch = () => {
    setSearchValue("");
    setTimeout(() => {
      setLoadedData(draftsData);
    }, 500);
    setScrollState(false);
  };

  const handlePersonId = (event) => {
    setSelectedPersonId(event.target.value);
    setScrollState(true);
    const payload = {
      stockWarehouseId: currentWorkspace?.stockWarehouseId,
      jobName: String(searchValue),
      salesPersonId: Number(event.target.value),
      takerId: Number(selectedTaker),
    };
    if (Boolean(event.target.value)) {
      setDraftSearchParam(payload);
    }
  };

  const handleTaker = (event) => {
    setSelectedTaker(event.target.value);
    setScrollState(true);
    const payload = {
      stockWarehouseId: currentWorkspace?.stockWarehouseId,
      jobName: String(searchValue),
      salesPersonId: Number(selectedPersonId),
      takerId: Number(event.target.value),
    };
    if (Boolean(event.target.value)) {
      setDraftSearchParam(payload);
    }
  };

  const handleSort = (index) => {
    setSortIndex(index);
    setSetSortBy(true);
  };

  const getColumnHeaders = (excludedColumns = []) => {
    return dataExportData
      .filter((column) => !excludedColumns.includes(column.key))
      .map((column) => ({
        key: column.key,
        label: column.label,
        format:
          column.key === "createdAt"
            ? (value) => moment(value).format("MM/DD/YYYY")
            : undefined,
      }));
  };

  const handleScroll = () => {
    if (draftsData?.length === total) {
      setHaveMore(false);
      return;
    }
    if (draftsData?.length < total && !scrollState) {
      const body = {
        stockWarehouseId: currentWorkspace?.stockWarehouseId,
        page: page + 1,
        size: 20,
        sort: sortIndex ? ASCENDING : DESCENDING,
      };
      setSetSortBy(false);
      setDraftsParma(body);
    }
  };

  const priceScheduleData = (data) => {
    const preparedData = {
      stockWarehouseId: currentWorkspace?.stockWarehouseId,
      takerId: currentTaker?.personId,
      customerId: data?.customerId,
      cartId: data?.cartId,
    };
    dispatch(setJobNameForAll(""));
    dispatch(snakbarShow({ message: snackbarDetail.resumeDraft }));
    dispatch(getShippingFacilitiesRequest(data?.customerId));
    dispatch(loadCartRequest(preparedData));
  };

  const handleSearchCustomer = (data) => {
    priceScheduleData(data);
  };

  const clearCartIdAPI = async (id) => {
    const res = await cartClear(Number(id?.cartId));
    dispatch(snakbarShow({ message: snackbarDetail.savedDraft }));
    setTimeout(() => {
      setCustomerDraftDelete(false);
    }, 1000);
    setTimeout(() => {
      if (res?.data) {
        const filteredDataInfo = loadedData.filter(
          (data) => data?.cartId !== id?.cartId
        );
        setLoadedData(filteredDataInfo);
      }
    }, 1000);
  };

  const excludedColumns = [];
  return (
    <Container className="new-container large-screen-container-styling">
      <div className="customer-info-page">
        <div className="drafts-container">
          <div className="pricing-modal-header mt-0 mb-0">
            <div className="drafts-container-heading">
              <div className="drafts-container-heading-text">Drafts</div>
              <div className="d-flex drafts-container-heading-order-btn">
                <div>
                  <PosButton
                    className="btn start-new-order-btn"
                    tabIndex={1}
                    id="start-order-btn"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      if (loggedInUser) {
                        localStorage.setItem(
                          "taker",
                          JSON.stringify(loggedInUser)
                        );
                      }
                      navigate(Paths.posNewOrder);
                    }}
                    startIcon={<AddCircleIcon />}
                  >
                    <span className="order-text">Start a New Order</span>
                  </PosButton>
                </div>
              </div>
            </div>
            <div>
              <div className="drafts-container-search">
                <PosInput
                  value={searchValue}
                  variant="filled"
                  id="filled-basic"
                  type="text"
                  className="document-search-input search-input-focus"
                  autoFocus
                  inputRef={searchCustomersDraft}
                  onBlur={() => {
                    searchCustomersDraft.current.blur();
                  }}
                  placeholder="Job name, Customer, PO #"
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <>
                        <div className="enter-search-text-new-order">
                          <span>Press Enter</span>
                          <PosImage
                            src={images.pressEnter}
                            alt="enter-icon"
                            className="press-enter-image "
                          />
                          <span>to search</span>
                        </div>
                        {searchValue ? (
                          <InputAdornment
                            position="end"
                            className="search-order-input-last-adornment"
                          >
                            <div className="d-flex align-items-center">
                              <CloseIcon
                                className="cursor-pointer ml-2"
                                onClick={handleClearSearch}
                              />
                            </div>
                          </InputAdornment>
                        ) : (
                          <></>
                        )}
                      </>
                    ),
                  }}
                />
              </div>
              <div className="drafts-container-selection">
                <span>
                  <select
                    id="selectPerson"
                    name="selectPerson"
                    value={selectedTaker}
                    onChange={handleTaker}
                    className="fs-14"
                  >
                    <option value={0}>All Takers</option>
                    {takersDetails.map((person) => (
                      <option key={person.personId} value={person.personId}>
                        {person.fullName}
                      </option>
                    ))}
                  </select>
                </span>
                <span>
                  <select
                    id="selectPerson"
                    name="selectPerson"
                    value={selectedPersonId}
                    onChange={handlePersonId}
                    className="fs-14"
                  >
                    <option value={0}>All Salespeople</option>
                    {salesPersons.map((person) => (
                      <option key={person.personId} value={person.personId}>
                        {person.salespersonName}
                      </option>
                    ))}
                  </select>
                </span>
              </div>
              <div className="pb-3 dropdown txt-align sort-by-dropdown sort-dropdown-index pt-3">
                <div className="relative-light-border"></div>
                <div>
                  <span className="text-uppercase sort fw-medium fs-12 text-dark">
                    Sort By:
                  </span>
                  <select
                    id="sortBy"
                    name="sortBy"
                    value={sortIndex}
                    onChange={(index) => handleSort(index)}
                    className="fs-12 text-uppercase custom-sort-by-select"
                  >
                    {DocumentSortBy.map((value, index) => (
                      <option
                        key={index}
                        value={value}
                        className="custom-sort-by-select-options"
                      >
                        {value}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            {/* )} */}
            <div>
              <DynamicTable
                data={finalData || []}
                isLoading={isLoadingApi}
                getColumnHeaders={getColumnHeaders}
                handleScroll={handleScroll}
                doesHaveMore={doesHaveMore}
                handleSearchCustomer={handleSearchCustomer}
                excludedColumns={excludedColumns}
                clearCartIdAPI={clearCartIdAPI}
                customerDraftDelete={customerDraftDelete}
                setCustomerDraftDelete={setCustomerDraftDelete}
                searchValue={searchValue}
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Drafts;
