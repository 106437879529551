import { PosButton, PosDatePicker } from "apps/pos/Common/uiCore";
import { cashFilterOption, dateEnum } from "constants/staticData";
import dayjs from "dayjs";
import moment from "moment";
import { useEffect, useState } from "react";

const GenerateCashDrawer = (props) => {
    const { closeAllDialogs, modelName, cashMenuUrl } = props;
    const { mdFormat, ymdFormat, mmmmyyyy } = dateEnum || {};

    const currentDate = dayjs().format(mdFormat)
    const [fromDate, setFromDate] = useState(dayjs());
    const [toDate, setToDate] = useState(dayjs());
    const [selectedFilter, setSelectedFilter] = useState('Today'); // Default selected filter

    const currentMonth = moment().format(mmmmyyyy);
    const prevousMonth = moment().subtract(1, "month").format(mmmmyyyy);

    const handleGenerate = () => {
        const currentDate = dayjs().format(ymdFormat)
        if (selectedFilter == cashFilterOption.today) {
            window.open(`${cashMenuUrl}?dt=${currentDate}&df=${currentDate}`, "_blank");
        } else {
            window.open(`${cashMenuUrl}?dt=${toDate.format(ymdFormat)}&df=${fromDate.format(ymdFormat)}`, "_blank");
        }
    }

    const setFilter = (type) => {
        let startDt;
        let endDt;
        if (type == cashFilterOption.today) {
            startDt = dayjs()
            endDt = dayjs()
        }
        else if (type == cashFilterOption.lastWeek) {
            startDt = moment().subtract(7, 'day');
            endDt = moment().subtract(1, 'day');
        } else if (type == cashFilterOption.prevMonth) {
            startDt = moment(prevousMonth).startOf('month');
            endDt = moment(prevousMonth).endOf('month');
        } else if (type == cashFilterOption.currMonth) {
            startDt = moment(currentMonth).startOf('month');
            endDt = moment(currentMonth).endOf('month');
        } else {
            startDt = fromDate;
            endDt = toDate;
        }
        setFromDate(dayjs(startDt.format(ymdFormat)));
        setToDate(dayjs(endDt.format(ymdFormat)));
        setSelectedFilter(type); // Set the selected filter type
    }

    useEffect(() => {
        const today = dayjs();
        if (fromDate.isSame(today, 'day') && toDate.isSame(today, 'day')) {
            setSelectedFilter(cashFilterOption.today);
        } else if (
            fromDate.isSame(today.subtract(7, 'days'), 'day') &&
            toDate.isSame(today.subtract(1, 'day'), 'day')
        ) {
            setSelectedFilter(cashFilterOption.lastWeek);
        } else if (
            fromDate.isSame(dayjs(moment(prevousMonth).startOf('month').format(ymdFormat)), 'day') &&
            toDate.isSame(dayjs(moment(prevousMonth).endOf('month').format(ymdFormat)), 'day')
        ) {
            setSelectedFilter(cashFilterOption.prevMonth);
        } else if (
            fromDate.isSame(dayjs(moment(currentMonth).startOf('month').format(ymdFormat)), 'day') &&
            toDate.isSame(dayjs(moment(currentMonth).endOf('month').format(ymdFormat)), 'day')
        ) {
            setSelectedFilter(cashFilterOption.currMonth);
        } else {
            setSelectedFilter(null); // No filter selected
        }
    }, [fromDate, toDate, currentMonth, prevousMonth]);

    return (
        <>
            <header>
                <p className="dialog-title">{modelName}</p>
                <button className="icon-btn dialog-close-btn" type="button" onClick={closeAllDialogs}>
                    <span>Close</span>
                </button>
            </header>
            <div className="add-bookmark-form">
                <div className="datePicker-group">
                    <div className="set-storage-type-section">
                        <div className="title-text mb-2 font-weight-600">
                            From
                        </div>
                        <div className="custom-datePicker storage-date-picker">
                            <PosDatePicker value={fromDate} onChange={(newValue) => { setFromDate(dayjs(newValue)) }} isDefaultSet={true} toDate={toDate} />
                        </div>
                    </div>
                    <div className="set-storage-type-section">
                        <div className="title-text mb-2 font-weight-600">
                            To
                        </div>
                        <div className="custom-datePicker storage-date-picker">
                            <PosDatePicker value={toDate} onChange={(newValue) => { setToDate(dayjs(newValue)) }} isDefaultSet={false} fromDate={fromDate} />
                        </div>
                    </div>
                </div>
                <div className="days-group">
                    <div
                        className={`days-list ${selectedFilter === cashFilterOption.today ? 'selected' : ''}`}
                        onClick={() => { setFilter(cashFilterOption.today) }}
                    >
                        {`Today, ${currentDate}`}
                    </div>
                    <div
                        className={`days-list ${selectedFilter === cashFilterOption.lastWeek ? 'selected' : ''}`}
                        onClick={() => { setFilter(cashFilterOption.lastWeek) }}
                    >
                        Last 7 days
                    </div>
                    <div
                        className={`days-list ${selectedFilter === cashFilterOption.prevMonth ? 'selected' : ''}`}
                        onClick={() => { setFilter(cashFilterOption.prevMonth) }}
                    >
                        {prevousMonth}
                    </div>
                    <div
                        className={`days-list ${selectedFilter === cashFilterOption.currMonth ? 'selected' : ''}`}
                        onClick={() => { setFilter(cashFilterOption.currMonth) }}
                    >
                        {currentMonth}
                    </div>
                </div>
                <div className="actions">
                    <PosButton type="submit" variant="contained"
                        color="primary" onClick={handleGenerate}>Generate</PosButton>
                </div>
            </div>
        </>
    )
}
export default GenerateCashDrawer;