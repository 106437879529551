import { requiredMessage } from "utils/utils";
import { array, boolean, number, object, ref, string } from "yup";

import { fullNameRegex, phoneRegex, trailingLatterAplaNum } from "constants/constant";
import { PaymentMethodValidationMessage } from "constants/message";

const commonStringField = (msg: string) =>
  string().required(requiredMessage(msg));

const {
  pricingSchedule,
  phoneNumber,
  digit10Req,
  firstName,
  lastName,
  customerCode,
  countryCode,
  companyAddress,
  city,
  zip,
  emailAddressReq,
  emailAddressInValid,
  fixMarginValid,
  zipMaxLength,
  companyName
} = PaymentMethodValidationMessage;

const AddCustomerSchema = object().shape({
  firstName: string().required(firstName),
  lastName: string().required(lastName),
  customerCode: string().required(customerCode),
  title: string(),
  countryCode: string().required(countryCode),
  companyName: string(),
  companyAddress: string().required(companyAddress),
  city: string().required(city),
  zip: number().min(10000, zipMaxLength)
    .max(99999, zipMaxLength).required(zip).nullable(),
  // extCode: number().required(extCode).nullable(), // TODO need this code if the validation change
  jobDescription: number(),
  state: number(),
  emailAddress: string().email(emailAddressInValid).required(emailAddressReq),
  phoneNumber: string()
    .matches(phoneRegex, digit10Req)
    .required(phoneNumber)
    .nullable(),
  pricingSchedule: string().required(pricingSchedule),
  fixMarginRate: number().typeError(fixMarginValid).min(0, fixMarginValid).max(0.99, fixMarginValid),
});

const productDetailSchema = object().shape({
  countryCode: string().required(countryCode),
});

const AddAddressSchema = object().shape({
  firstName: string().required(firstName),
  lastName: string().required(lastName),
  countryCode: string().required(countryCode),
  title: string(),
  companyName: string().required(companyName),
  companyAddress: string().required(companyAddress),
  city: string().required(city),
  zip: number().min(10000, zipMaxLength)
    .max(99999, zipMaxLength).required(zip).nullable(),
  jobDescription: number(),
  state: number(),
  emailAddress: string().email(emailAddressInValid).required(emailAddressReq),
  phoneNumber: string()
    .matches(phoneRegex, digit10Req)
    .required(phoneNumber)
    .nullable(),
});

const shippingAddressSchema = object().shape({
  fullName: string().required("Full Name is required.").matches(fullNameRegex, 'Please enter full name').matches(trailingLatterAplaNum, "Full name can only have alphanumeric characters"),
  countryCode: string(),
  phoneNumber: string()
    .matches(phoneRegex, digit10Req)
    .nullable(),
  email: string().email(emailAddressInValid).nullable(),
});

const AddProductSchemaValidation = ({ shortName }) => {
  return object().shape({
    productName: string().required("Product Name is required."),
    description: string().required("Description is required"),
    category: string().required("Category is required"),
    manufacturer: string().required("Manufacturer is required"),
    manufacturerProductCode: string().required("Manufacturer Code is required."),
    vendorName: string().required("Vendor Name is required"),
    universalProductCode: string().min(12, "Must be 12 digits"),
    units: number().required("Units / Case is required.").nullable(),
    stockUnits: number().required("Stock Units is required.").nullable(),
    minimumCases: number().required("Minimum Cases is required.").nullable(),
    itemsInMeasure: number().required("Number of Items is required.").nullable(),
    priceMeasure: string().required("Price Measure is required."),
    lumijectQAPrice: number()
      .required(`${shortName} Wholesale Price is required.`)
      .nullable(),
    vendorPrice: number()
      .required("Vendor Price is required.")
      .nullable()
      .lessThan(
        ref("lumijectQAPrice"),
        `Must be less than ${shortName} Wholesale price`
      ).moreThan(0, 'Must be grater than 0'),
    measureText: string().required("Measure Text is required."),
    measureValue: number().required("Measure Value is required.").nullable(),
    // Might require
    // adjustQuantity: number().required("Adjust Quantity is required.").nullable(),
    productGroup: string().required("Product Group is required"),
    // Might require
    // location: string()
    //   .test("location", "Location is Required", (data, { parent }) => {
    //     const { locationRow, locationUnit, locationShelf } = parent;
    //     return (
    //       Boolean(locationRow) && Boolean(locationUnit) && Boolean(locationShelf)
    //     );
    //   })
    //   .default(""),
  })

}

// need for checking at other scenarios for below conditions
const newRow = object().shape({
  id: string(),
  name: string()
});
const newUnit = object().shape({
  id: string(),
  name: string()
});
const newShelf = object().shape({
  id: string(),
  name: string()
});

const row = string().required("Please select row");
const unit = string().required("Please select unit");
const shelf = string().required("Please select shelf");

const addAdjustQuantitySchema = object().shape({
  addAdjustStockRow: row,
  addAdjustStockUnit: unit,
  addAdjustStockShelf: shelf,
  quantityToAdd: commonStringField("Add quantity").test(
    "Add quantity",
    "Please enter the quantity  greater than 0.",
    function (item) {
      const itemValue = Number(item);
      return itemValue > 0;
    }
  ),
});

const addReturnQuantitySchema = object().shape({
  returnQuantity: number()
    .required("Return Quantity should be a number.")
    .nullable(),
  adjustStockUnit: unit,
  adjustStockShelf: shelf,
  quantityToAdd: commonStringField("Add quantity"),
});
const addReturnCartSchema = object().shape({
  manufacturerReturnPolicy: string().required(
    "Please provide Manufacturer Return Policy"
  ),
  reason: string().required("Please select reason"),
  // need for checking at other scenarios
  returnTo: array()
    .of(
      object().shape({
        row: newRow,
        shelf: newShelf,
        unit: newUnit,
        replaceQty: number(),
        returnQty: number(),
        overShipmentQty: number(),
      })
    ).required("Please Select Return To Location")
    .test('conditional-validation', '', function (returnTo) {
      const reason = this.parent.reason;

      if (!returnTo || returnTo.length === 0) {
        return this.createError({ message: "Please Select Return To Location" });
      }

      const validateItem = (item: any, isReason6: boolean) => {
        const hasLocation = item.row?.id && item.shelf?.id && item.unit?.id;
        const hasQuantity = isReason6 ? item.overShipmentQty : (item.replaceQty || item.returnQty);

        if (hasLocation && !hasQuantity) {
          return this.createError({ message: "Enter Qty." });
        }
        if (!hasLocation && hasQuantity) {
          return this.createError({ message: "Please select location" });
        }
        if (hasLocation && hasQuantity) {
          return true;
        }
        return null; // Neither location nor quantity is present
      };

      if (returnTo.length === 1) {
        const result = validateItem(returnTo[0], reason === '6');
        return result === null ? this.createError({ message: "Please select location and add quantity data" }) : result;
      } else if (returnTo.length > 1) {
        let hasAnyData = false;
        for (const item of returnTo) {
          const result = validateItem(item, reason === '6');

          if (result === true) {
            hasAnyData = true;
          } else if (result !== null) {
            return result; // Return error if any item fails validation
          }
        }
        // If no items have data, don't trigger validation
        return hasAnyData ? true : this.createError({ message: "Please provide data for at least one return location" });
      }

      return true;
    }),
});

const removeAdjustQuantitySchema = (qtyInStock: number) =>
  object().shape({
    removeAdjustStockRow: row,
    removeAdjustStockUnit: unit,
    removeAdjustStockShelf: shelf,
    quantityToRemove: commonStringField("Remove quantity")
      .test(
        "Remove quantity",
        "Please enter the quantity  greater than 0.",
        function (item) {
          const itemValue = Number(item);
          return itemValue > 0;
        }
      )
      .test(
        "Remove quantity",
        "Please enter the quantity less than or equal to the available quantity",
        function (item) {
          const itemValue = Number(item);
          return itemValue <= qtyInStock;
        }
      ),
  });

const moveAdjustQuantitySchema = (qtyInStock: number) =>
  object().shape({
    productLocationRow: row,
    productLocationUnit: unit,
    productLocationShelf: shelf,
    moveToLocationRowD2: row,
    moveToLocationUnitD2: unit,
    moveToLocationShelfD2: shelf,
    quantityToMove: commonStringField("Move quantity")
      .test(
        "Move quantity",
        "Please enter the quantity  greater than 0.",
        function (item) {
          const itemValue = Number(item);
          return itemValue > 0;
        }
      )
      .test(
        "Move quantity",
        "Please enter the quantity less than or equal to available quantity in the From location",
        function (item) {
          return Number(item) <= qtyInStock;
        }
      ),
  });
const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100MB in bytes
const AddDocumentSchema = object().shape({
  file: array().min(1, 'Select a file')
    .test("file-size", "This file is too large, Please select a file smaller than 100 MB", function (item) {
      return item[0]?.size <= MAX_FILE_SIZE;
    }),
  documentSaveType: string().required("Document Type is required"),
});

const UploadDeliveryProofSchema = object().shape({
  file: array().min(1, 'Select a file')
    .test("Select a file", "Please enter valid file", function (item) {
      return item[0]?.size
    }),
});
const UpdatedDocumentSchema = object().shape({
  description: string(),
  documentSaveType: string(),
});

const UpdatedBasicProductDetailSchema = object().shape({
  manufacturerCode: string().required("Code is required."),
  productName: string().required("Product Name is required."),
  description: string(),
  manufacturerId: number().required("Manufacturer is required"),
  vendorId: number().required("Vendor is required"),
  productGroupId: number(),
  // categoryId: string().required("Category is required"), // need this
  availableId: number().required("Availability is required"),
  dayToShipId: number().required("Days to ship is required"),
});

const UpdatedMeasurementsProductDetailSchema = object().shape({
  workStationId: number(),
  min: number().test(
    'minCheck',
    'Min should be less than max',
    function (value) {
      const max = this.parent.max;
      if (value !== undefined && max !== undefined && value > max) {
        return false;
      }
      return true;
    }
  ).test(
    'minValueCheck',
    'Min should not be less than 0',
    function (value) {
      if (value !== undefined && value < 0) {
        return false;
      }
      return true;
    }
  ),
  max: number().test(
    'maxCheck',
    'Max should be greater than min',
    function (value) {
      const min = this.parent.min;
      if (value !== undefined && min !== undefined && value < min) {
        return false;
      }
      return true;
    }
  ).test(
    'maxValueCheck',
    'Max should not be less than 0',
    function (value) {
      if (value !== undefined && value < 0) {
        return false;
      }
      return true;
    }
  ),
  increment: number().test(
    'incrementValueCheck',
    'Increment should be greater than 0',
    function (value) {
      if (value !== undefined && value <= 0) {
        return false;
      }
      return true;
    }
  ).test(
    'incrementMaxCheck',
    'Increment should be less or equal to max',
    function (value) {
      const max = this.parent.max;
      if (value !== undefined && value > max) {
        return false;
      }
      return true;
    }
  ),
  priceMu: number().min(1, 'Value must be greater than 0').required("Price UOM is required"),
  muName: string().required("Price UOM is required"),
  minimumCases: number().min(1, 'Value must be greater than 0').required("Minimum Cases to Order is required"),
  unitPerCase: number().min(1, 'Value must be greater than 0').required("Units/Case is required"),
});


const UpdatedIdentifiersProductDetailSchema = object().shape({
  ean: string().matches(/^[0-9]{13}$/, 'Must be a 13 digit number'),
  gcid: string().matches(/^[a-zA-Z0-9]{16}$/, 'Must be a 16 characters'),
  gtin: string().matches(/^[0-9]{8}$|^[0-9]{12}$|^[0-9]{13}$|^[0-9]{14}$/, "Must be either 8 or 12 or 13 or 14 digit"),
  upc: string().matches(/^[0-9]{12}$/, 'Must be a 12 digit number'),
  amazonAsin: string().matches(/^[a-zA-Z0-9]{10}$|^[a-zA-Z0-9]{13}$/, 'Must be either 10 or 13 characters'),
});

const UpdatedSeoProductDetailSchema = object().shape({
  footNote: string(),
  keywords: string(),
  folderPath: string(),
  hyperlink: string(),
});

const UpdatedImageProductDetailSchema = object().shape({
  bigImg: string().required("Default Large Image is required"),
  thumbImg: string().required("Default Thumbnail is required"),
  pib: string(),
  psds: string(),
});

const UpdatedCostAndPriceProductDetailSchema = object().shape({
  vendorPrice: number().required("Vendor Price is required")
    .test("Vendor Price is required", "Vendor price must be more than manufacturer price", function (value) {
      const manufacturerPrice = this.parent.manufacturerPrice;
      return value >= manufacturerPrice;
    }),
  manufacturerPrice: number().required("Manufacturer Price is required"),
  goldenRodPrice: number().required("Golden Rod Price is required"),
  stockPrice: number().required("Stock Price is required")
    .test("Stock Price is required", "Stock price must not exceed the vendor price", function (value) {
      const vendorPricePrice = this.parent.vendorPrice;
      return value <= vendorPricePrice;
    }),
  overridePrice: number(),
  salesPrice: number().required("Wholesale is required"),
  listPrice: number().required("List Price is required"),
  claimBackPrice: number().required("Claim Back Price is required"),
  hotBuyPrice: number(),
  hotBuyExpiryDate: string(),
  clearancePrice: number(),
  discontinuedPrice: number(),
});

const addretailPriceSchedulesSchema = object().shape({
  retailPriceSchedules: array().of(object().shape({
    name: string(),
    hasBreakQty: boolean(),
    vendorProductGroups: array()
      .of(
        object().shape({
          breakQty: number(),
          markupMultiplier: number(),
          vpgComputePriceType: string(),
          description: string(),
          returnQty: string(),
          vendorId: number(),
          id: number(),
          createdAt: string(),
          priceScheduleId: number(),
          productGroupId: number(),
          createdBy: number(),
        })
      )
  }))
});

const UpdatedOverviewProductDetailSchema = object().shape({
  productName: string().required("Product Name is required."),
  description: string(),
  productGroupId: number(),
  manufacturerId: number().required("Manufacturer is required"),
  manufacturerCode: string().required("Code is required."),
  vendorId: number().required("Vendor is required"),
  upc: string().matches(/^[0-9]{12}$/, 'Must be a 12 digit number'),
  priceMu: number().min(1, 'Value must be greater than 0').required("Price UOM is required"),
  muName: string().required("Price UOM is required"),
  itemMU: number().min(1, 'Value must be greater than 0').required("Item UOM is required"),
  itemMuName: string().required("Item UOM is required"),
  minimumCases: number().min(1, 'Value must be greater than 0').required("Minimum Cases to Order is required"),
  unitPerCase: number().min(1, 'Value must be greater than 0').required("Units/Case is required"),
  salesPrice: number().required("Wholesale is required"),
  vendorPrice: number().required("Vendor Price is required"),
});

const chargeCreditValidateSchema = object().shape({
  warehouse: string().required("Warehouse is required"),
  firstName: string().required("Name is required"),
  lastName: string().required("Name is required"),
  street: string().required("Street is required"),
  city: string().required(" City is required"),
  zip: number().min(10000, zipMaxLength)
    .max(99999, zipMaxLength).required(zip).nullable(),
  state: string().required("State is required"),
  phoneNumber: string().required("Phone Number is required"),
  amount: number().required("Amount is required").nullable(),
  cardNum: string().required("Cart Details is required"),
  cardExpDate: string().required("Cart Details is required"),
  cardCVV2: string().required("Cart Details is required"),
})

const EmailQuoteDetailSchema = object().shape({
  toEmail: string()
    .required("Email is required")
    .test(
      "is-valid-emails",
      function (value) {
        if (!value) return this.createError({ message: "Email is required" });

        const emails = value.split(",").map((email) => email.trim());
        for (const email of emails) {
          if (!string().email().isValidSync(email)) {
            return this.createError({
              message: `The email "${email}" is invalid.`,
            });
          }
        }
        return true;
      }
    ),
  subject: string().required("Subject is required."),
  message: string().required("Message is required."),
});

export {
  AddAddressSchema, addAdjustQuantitySchema, AddCustomerSchema, AddDocumentSchema, AddProductSchemaValidation, addretailPriceSchedulesSchema, addReturnCartSchema, addReturnQuantitySchema, EmailQuoteDetailSchema, moveAdjustQuantitySchema, productDetailSchema, removeAdjustQuantitySchema, shippingAddressSchema, UpdatedBasicProductDetailSchema, UpdatedCostAndPriceProductDetailSchema, UpdatedDocumentSchema, UpdatedIdentifiersProductDetailSchema, UpdatedImageProductDetailSchema, UpdatedMeasurementsProductDetailSchema, UpdatedOverviewProductDetailSchema, UpdatedSeoProductDetailSchema, UploadDeliveryProofSchema, chargeCreditValidateSchema
};

