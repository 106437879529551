import axios from "axios";
import { requestInterceptor, responseInterceptor, requestErrorHandler, responseErrorHandler } from "./config2"; // Adjust import path as needed

/**
 * Create a dynamic Axios instance with a custom base URL.
 * @param {string} baseURL - The base URL for the Axios instance.
 * @returns {AxiosInstance} - Configured Axios instance.
 */
const createDynamicService = (baseURL: string) => {
    const instance = axios.create({
        baseURL,
        timeout: 70000,
        headers: {
            "Content-Type": "application/json",
        },
    });

    instance.interceptors.request.use(requestInterceptor, requestErrorHandler);
    instance.interceptors.response.use(responseInterceptor, responseErrorHandler);

    return instance;
};

export default createDynamicService;
