import serviceV1 from 'services/config';
import serviceV2 from 'services/config2';

export interface SearchProductsPayload extends IPaginations {
  keywords: string;
  filters?: IFilters;
}

interface IPaginations {
  page: number;
  size: number;
}
interface IParent {
  parentId: number;
}
interface IProductsId {
  productsId: number;
}
interface IAddCharacteristics {
  productId: number;
  characteristics: characteristicIdI[]
}
interface characteristicIdI {
  characteristicId: number;
  characteristicTypeId: number;
  characteristicTypeValue1: string;
  characteristicTypeValue2: string;
}
interface IHandlerId {
  productsId: number;
  handlerId: number
}
interface IUserId {
  productsId: number;
  userId: number
}
interface IChangeTypeID {
  productsId: number;
  changeTypeID: number
}
interface IStockId {
  stockId: number;
}
interface ICompanyType {
  companyTypeId: number;
}

interface statusUpdate {
  handlerID: number;
  productID: number;
  status: number;
}
interface IProductSearch {
  page: number;
  size: number;
}

interface IProductStockMinMax {
  stockWarehouseID: number;
  productID: number;
}

interface IProductsId {
  productsId: number;
}
interface IKeyword {
  keywords: string;
}

interface IAccessorieProducts {
  productsId: number;
  relativeType: number;
}
interface IPriceAndCostProducts {
  productsId: number;
  priceTypeId: number;
  page: number;
  size: number;
}
// Will remove 'any' once we get proper data from BE
export interface IFilters {
  statuses?: number[] | null;
  manufacturerIds?: number[] | null;
  vendorIds?: number[] | null;
  categoryIds?: number[] | null;
  scheduleIds?: number[] | null;
  productGroupIds?: number[] | null;
}

interface IproductToProductID {
  productToProductID: number;
}
interface ICharacteristicsRemove {
  productId: number;
  characteristicId: number;
}

interface IproductID {
  productId: number;
}

interface IcouponID {
  productId: number;
  couponId: number;
  couponItemPrice: number;
}
interface IrowId {
  rowID: number;
}

interface IBasicDetail {
  productId: number,
  handlerId: number,
  categoryId: number,
  productGroupId: number,
  activeFlag: number,
  manufacturerId: number,
  vendorId: number,
  manufacturerCode: string,
  productName: string,
  description: string,
  availability: number,
  daysToShip: number
}

interface IMeasurementsDetail {
  productId: number,
  handlerId: number,
  weight: number,
  priceMU: number,
  muName: string,
  itemMU: number,
  itemMuName: string,
  minimumCases: number,
  unitPerCase: number,
  stockUnitPerCase: number,
  stockItemMinMax: {
    stockWarehouseId: number,
    productId: number,
    minLevel: number,
    maxLevel: number,
    increment: number,
    id: number,
    handlerId: number
  }
}

interface IIdentifiersDetail {
  productId: number,
  handlerId: number,
  ean: string,
  gcid: string,
  gtin: string,
  upc: string,
  amazonAsin: string
}

interface IFlagsDetail {
  productId: number,
  handlerId: number,
  noFroogleFlag: number,
  dropshipFlag: number,
  hidePriceFlag: number,
  containMercuryFlag: number,
  hideFromAmazonFeed: boolean,
  isForClearanceEmailBlast: boolean,
  isToSetOrderUnusual: boolean,
  isVirtual: boolean
}

interface ISeoDetail {
  productId: number,
  handlerId: number,
  footNote: string,
  keywords: string,
  folderPath: string,
  hyperlink: string
}

interface IImageDetail {
  productId: number,
  handlerId: number,
  bigImg: string,
  thumbImg: string,
  pib: string,
  psds: string
}

interface ICostAndPriceDetail {
  productId: number,
  handlerId: number,
  vendorPrice: number,
  salesPrice: number,
  overridePrice: number,
  manufacturerPrice: number,
  stockPrice: number,
  listPrice: number,
  goldenRodPrice: number,
  claimBackPrice: number,
  claimBackFlag: number,
  hotBuyPrice: number,
  hotBuyExpiryDate: string,
  clearancePrice: number,
  unitQty: number,
  discontinuedPrice: number
}

interface IAddProductNote {
  productId: number,
  note: string,
  handlerId: number
}

interface IRemoveProductNote {
  productId: number,
  noteId: number,
}

interface IUpdateProductNote {
  noteId: number,
  productId: number,
  note: string,
  handlerId: number
}


interface ILogsProduct {
  page: number,
  productID: number,
  size: number
}

interface IAddProductNote {
  productId: number,
  note: string,
  handlerId: number
}

interface IRemoveProductNote {
  productId: number,
  noteId: number,
}

interface IUpdateProductNote {
  noteId: number,
  productId: number,
  note: string,
  handlerId: number
}

interface IAddAccessories {
  productID1: number,
  productID2: number,
  relationType: number
}

interface IRetailPrice {
  vendorId: number,
  productGroupId: number,
}

interface IProductID {
  productID: number
}

interface IRetailPriceUpdate {
  priceSchedules: IRetailUpdate[]
}
interface IRetailUpdate {
  id: number,
  name: string,
  vendorProductGroups: IVenderProductGroups[]
}

interface IVenderProductGroups {
  id: number,
  priceScheduleId: number,
  vendorId: number,
  productGroupId: number,
  breakQty: number,
  markupMultiplier: number,
  vpgComputePriceType: string,
  createdBy: number,
  createdAt: string,
  description: string,
}
interface IPriceScheduleID {
  priceScheduleId: number,
}
interface IAddMinMaxBatch {
  stockWarehouseId: number,
  productId: number,
  companyId: number,
  isMfr: boolean
}

interface IOverviewProduct {
  handlerID: number,
  productID: number,
  categoryID: number,
  manufacturerID: number,
  vendorID: number,
  productName: string,
  description: string,
  manufacturerCode: string,
  upc: string,
  hyperlink: string,
  folderPath: string,
  minimumCases: number,
  unitPerCase: number,
  stockUnitPerCase: number,
  priceMU: number,
  muName: string,
  salesPrice: number,
  vendorPrice: number,
  dropShipFlag: number,
  containMercuryFlag: number,
  weight: number,
  itemMU: number,
  itemMuName: string,
  productGroupId: number
}
//  Search Products
export function searchProduct(payload: SearchProductsPayload) {
  return serviceV1.post(`products/by/keywords/all`, payload).then((response) => {
    return response.data;
  });
}

export function getAllProducts(payload: IProductSearch) {
  return serviceV2
    .post(`products`, payload)
    .then((response) => {
      return response.data;
    });
}

export function getSingleProducts(payload: IProductsId) {
  return serviceV2
    .get(`products/${payload.productsId}`)
    .then((response) => {
      return response.data;
    });
}

export function updateSingleProducts(payload: IProductsId) {
  return serviceV1
    .get(`products/${payload.productsId}`)
    .then((response) => {
      return response.data;
    });
}

export function updateSinglePendingProducts(payload: IProductsId) {
  return serviceV1
    .get(`products/${payload.productsId}/pending`)
    .then((response) => {
      return response.data;
    });
}

export function getSingleProductCharacteristics(payload: IProductsId) {
  return serviceV1
    .get(`products/${payload.productsId}/characters`)
    .then((response) => {
      return response.data;
    });
}

export function createProductsUserClearance(payload: IHandlerId) {
  return serviceV1
    .post(`products/${payload.productsId}/clearance/users/${payload.handlerId}`)
    .then((response) => {
      return response.data;
    });
}

export function createProductsPriceClearance(payload: IProductsId) {
  return serviceV1
    .post(`products/${payload.productsId}/clearance/with/price`)
    .then((response) => {
      return response.data;
    });
}

export function deleteProductsClearance(payload: IProductsId) {
  return serviceV1
    .delete(`products/${payload.productsId}/clearance`)
    .then((response) => {
      return response.data;
    });
}

export function updateProductsStatus(payload: statusUpdate) {
  return serviceV1
    .put(`products/${payload.productID}/status`, payload)
    .then((response) => {
      return response.data;
    });
}

export function createProductsUserDiscontinued(payload: IUserId) {
  return serviceV1
    .post(`products/${payload.productsId}/discontinued/users/${payload.userId}`)
    .then((response) => {
      return response.data;
    });
}

export function updateProductsPriceDiscontinued(payload: IProductsId) {
  return serviceV1
    .put(`products/${payload.productsId}/discontinued/price`)
    .then((response) => {
      return response.data;
    });
}

export function deleteProductsDiscontinued(payload: IProductsId) {
  return serviceV1
    .delete(`products/${payload.productsId}/discontinued`)
    .then((response) => {
      return response.data;
    });
}

export function createStock() {
  return serviceV2
    .post(`stock/minmax/_bulk`)
    .then((response) => {
      return response.data;
    });
}

export function updateStock(payload: IStockId) {
  return serviceV2
    .put(`stock/minmax/${payload.stockId}`)
    .then((response) => {
      return response.data;
    });
}

export function deleteStock(payload: IStockId) {
  return serviceV2
    .delete(`stock/minmax/${payload.stockId}`)
    .then((response) => {
      return response.data;
    });
}

export function getProductGroups() {
  return serviceV2
    .get(`productGroups`)
    .then((response) => {
      return response.data;
    });
}

export function updateProductGroups(payload: IProductsId) {
  return serviceV1
    .put(`products/${payload.productsId}/prices/all`)
    .then((response) => {
      return response.data;
    });
}

export function getHistoryForProductGroups(payload: IChangeTypeID) {
  return serviceV1
    .get(`products/${payload.productsId}/types/${payload.changeTypeID}/changeLog`)
    .then((response) => {
      return response.data;
    });
}

export function getProductCategories(payload: IPaginations) {
  return serviceV1
    .get(`categories?page=${payload.page}&size=${payload.size}`)
    .then((response) => {
      return response.data;
    });
}

export function getProductSubCategories(payload: IParent) {
  return serviceV1.get(`categories/parents/${payload.parentId}/subcategories`).then((response) => {
    return response.data;
  });
}

export function getProductVendors(payload: ICompanyType) {
  return serviceV1.get(`companies/types/${payload.companyTypeId}/vendors`).then((response) => {
    return response.data;
  });
}

export function getProductManufacturers(payload: ICompanyType) {
  return serviceV1.get(`companies/types/${payload.companyTypeId}/manufacturers`).then((response) => {
    return response.data;
  });
}

export function getProductFilters() {
  return serviceV2.get(`products/filters`).then((response) => {
    return response.data;
  });
}

export function getMinMaxStock(payload: IProductStockMinMax) {
  return serviceV2
    .get(`stock/warehouses/${payload.stockWarehouseID}/products/${payload.productID}/minmax`)
    .then((response) => {
      return response.data;
    });
}

export function getCoupons(payload: IProductsId) {
  return serviceV2
    .get(`coupons/products/${payload.productsId}`)
    .then((response) => {
      return response.data;
    });
}

export function getCouponsById(payload: IKeyword) {
  return serviceV1
    .post(`coupons/by/keywords`, payload)
    .then((response) => {
      return response?.data;
    });
}

export function getAllCoupons() {
  return serviceV1
    .get(`/coupons/all`)
    .then((response) => {
      return response?.data;
    });
}

export function getNotes(payload: IProductsId) {
  return serviceV2
    .get(`products/${payload.productsId}/notes`)
    .then((response) => {
      return response.data;
    });
}

export function getCharacteristics(payload: IProductsId) {
  return serviceV1
    .get(`products/${payload.productsId}/characteristics`)
    .then((response) => {
      return response.data;
    });
}

export function getAccessories(payload: IAccessorieProducts) {
  return serviceV1
    .get(`products/${payload.productsId}/types/${payload.relativeType}/related`)
    .then((response) => {
      return response.data;
    });
}


export function getPriceAndCost(payload: IPriceAndCostProducts) {
  return serviceV1
    .get(`changelogs/${payload.productsId}/pricetypes/${payload.priceTypeId}/changelog/price?page=${payload.page}&size=${payload.size}`)
    .then((response) => {
      return response.data;
    });
}

export function getAccessoriesAll(payload: IAccessorieProducts) {
  return serviceV1
    .get(`products/${payload.productsId}/types/${payload.relativeType}/related`)
    .then((response) => {
      return response.data;
    });
}

export function removeParticularAccessories(payload: IproductToProductID) {
  return serviceV1
    .delete(`products/related/${payload.productToProductID}`)
    .then((response) => {
      return response.data;
    });
}

export function removeAllRelatedItemsByProduct(payload: IAccessorieProducts) {
  return serviceV2
    .delete(`products/${payload.productsId}/types/${payload.relativeType}/related`)
    .then((response) => {
      return response.data;
    });
}

export function getCharacteristicsAll() {
  return serviceV1
    .get(`products/characteristics/types/all`)
    .then((response) => {
      return response.data;
    });
}

export function removeCouponsByRowId(payload: IrowId) {
  return serviceV2
    .delete(`coupons/${payload.rowID}`)
    .then((response) => {
      return response.data;
    });
}

export function removeAllCoupons(payload: IproductID) {
  return serviceV2
    .delete(`coupons/products/${payload.productId}/dissociate`)
    .then((response) => {
      return response.data;
    });
}

export function addCoupons(payload: IcouponID) {
  return serviceV2
    .post(`coupons/${payload.couponId}/products/${payload.productId}/associate`, payload)
    .then((response) => {
      return response.data;
    });
}

export function updateProductBasic(payload: IBasicDetail) {
  return serviceV2
    .put(`products/${payload.productId}/basic`, payload)
    .then((response) => {
      return response.data;
    });
}

export function updateProductMeasurements(payload: IMeasurementsDetail) {
  return serviceV2
    .put(`products/${payload.productId}/measure`, payload)
    .then((response) => {
      return response.data;
    });
}

export function updateProductIdentifiers(payload: IIdentifiersDetail) {
  return serviceV2
    .put(`products/${payload.productId}/identifiers`, payload)
    .then((response) => {
      return response.data;
    });
}

export function updateProductFlages(payload: IFlagsDetail) {
  return serviceV2
    .put(`products/${payload.productId}/flags`, payload)
    .then((response) => {
      return response.data;
    });
}

export function updateProductSeo(payload: ISeoDetail) {
  return serviceV2
    .put(`products/${payload.productId}/seo`, payload)
    .then((response) => {
      return response.data;
    });
}

export function updateProductImage(payload: IImageDetail) {
  return serviceV2
    .put(`products/${payload.productId}/imgdocs/upload`, payload)
    .then((response) => {
      return response.data;
    });
}

export function removeParticularCharacteristics(payload: ICharacteristicsRemove) {
  return serviceV2
    .delete(`products/${payload.productId}/characteristics/${payload.characteristicId}`)
    .then((response) => {
      return response?.data;
    });
}

export function removeAllCharacteristics(payload: IProductsId) {
  return serviceV2
    .delete(`products/${payload.productsId}/characteristics`)
    .then((response) => {
      return response?.data;
    });
}

export function GetAllCharacteristicTypeList() {
  return serviceV1
    .get(`products/characteristics/types/all`)
    .then((response) => {
      return response?.data;
    });
}

export function AddCharacteristic(payload: IAddCharacteristics) {
  return serviceV2
    .post(`products/${payload.productId}/characteristics/_bulk`, payload)
    .then((response) => {
      return response?.data;
    });
}
export function updateProductCostAndPrice(payload: ICostAndPriceDetail) {
  return serviceV2
    .put(`products/${payload.productId}/prices`, payload)
    .then((response) => {
      return response.data;
    });
}

export function addNotes(payload: IAddProductNote) {
  return serviceV2
    .post(`products/notes/add/_bulk`, [payload])
    .then((response) => {
      return response.data;
    });
}

export function removeNotes(payload: IRemoveProductNote) {
  return serviceV2
    .delete(`products/${payload.productId}/notes/${payload.noteId}`)
    .then((response) => {
      return response.data;
    });
}

export function removeAllNotes(payload: IProductsId) {
  return serviceV2
    .delete(`products/${payload.productsId}/notes`)
    .then((response) => {
      return response.data;
    });
}

export function updateNotes(payload: IUpdateProductNote) {
  return serviceV2
    .put(`products/notes/update/_bulk`, [payload])
    .then((response) => {
      return response.data;
    });
}

export function getLogs(payload: ILogsProduct) {
  return serviceV1
    .get(`changelogs/${payload.productID}/changelog/consolidated?page=${payload.page}&size=${payload.size}`)
    .then((response) => {
      return response.data;
    });
}

export function getCategoriesMenu() {
  return serviceV2
    .get(`categories/menu`).then((response) => {
      return response.data;
    });
}

export function addAccessories(payload: IAddAccessories) {
  return serviceV1
    .post(`products/related`, payload)
    .then((response) => {
      return response.data;
    });
}

export function getRetailPriceScedules(payload: IRetailPrice) {
  return serviceV2
    .get(`priceschedules/vendors/${payload.vendorId}/productgroups/${payload.productGroupId}`).then((response) => {
      return response.data;
    });
}

export function removeClearance(payload: IProductID) {
  return serviceV1
    .delete(`products/${payload.productID}/clearance`)
    .then((response) => {
      return response.data;
    });
}

export function removeDiscontinued(payload: IProductID) {
  return serviceV1
    .delete(`products/${payload.productID}/discontinued`)
    .then((response) => {
      return response.data;
    });
}

export function updateRetailPriceScedules(payload: IRetailPriceUpdate) {
  return serviceV2
    .post(`priceschedules/multiple/_bulk`, payload)
    .then((response) => {
      return response.data;
    });
}

export function deletePriceScedules(payload: IPriceScheduleID) {
  return serviceV2
    .delete(`priceschedules/${payload.priceScheduleId}`)
    .then((response) => {
      return response.data;
    });
}

export function addMinMaxBatch(payload: IAddMinMaxBatch) {
  return serviceV2
    .post(`stock/minmax/batch`, payload)
    .then((response) => {
      return response.data;
    });
}

export function deleteMinMaxBatch(minMaxStockId: number) {
  return serviceV2
    .delete(`stock/minmax/${minMaxStockId}`)
    .then((response) => {
      return response.data;
    });
}

export function getTicketProductCount(payload: IProductsId) {
  return serviceV1
    .get(`tickets/targettypes/7/targets/${payload.productsId}/count`)
    .then((response) => {
      return response.data;
    });
}

export function updateOverview(payload: IOverviewProduct) {
  return serviceV1
    .put(`products/${payload.productID}/pending`, payload)
    .then((response) => {
      return response.data;
    });
}
