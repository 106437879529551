import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import Button from 'components/SPButton/Button';
import images from 'constants/images';
import { packingSlipStart } from 'sagas/pos/actions';
import { Divider, PosIconButton, PosImage } from '../Common/uiCore';
import CustomerSendModal from './components/CustomerSendModal';
import RenderHtmlWithComponent from './components/RenderHtmlWithComponent';

import { Paths } from 'enums/paths';
import { Distributor, RootState } from 'models';
import { snakbarShow } from 'sagas/pos/snakbar/snakbar.slice';
import "./PackingSlip.scss";

interface PackingSlipState {
   packingSlip: string | null;
   packingSlipLoading: boolean;
   packingSlipError: string | null;
   displaySignature: boolean;
}

export const PackingSlip = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();

   const { orderId, shipmentId } = useParams();

   const [isModalOpen, setIsModalOpen] = useState(false);

   // Selectors
   const {
      packingSlip,
      packingSlipLoading,
      packingSlipError,
      displaySignature
   } = useSelector((state: { pos: PackingSlipState }) => state.pos);

   const {
      fullDomain
   } = useSelector<RootState, Distributor>((state) => state.distributor);

   // Handlers
   const handleNavigateBack = useCallback(() => {
      navigate(Paths.posOrderComplete);
   }, [navigate]);

   const handleOpenSignaturePad = useCallback(() => {
      const signatureEvent = new KeyboardEvent('keydown', {
         keyCode: 83,
         which: 83,
         key: 'S',
         code: 'KeyS',
         bubbles: true
      });
      document.dispatchEvent(signatureEvent);
   }, []);

   const handleSaveSignature = useCallback(async () => {
      setIsModalOpen(true);
      // setIsSignatureSaved(true);
   }, []);

   const handleCloseModal = useCallback(() => {
      setIsModalOpen(false);
   }, []);

   const handleKeyPress = (e) => {
      const { keyCode } = e;
      if (['INPUT', 'TEXTAREA'].includes(e.target.tagName)) {
         return;
      }

      if (document.querySelector('.signature-dialog[open]')) {
         const dialog = document.querySelector('.signature-dialog') as HTMLDialogElement;
         dialog?.close();
         return;
      }
      if (isModalOpen) {
         handleCloseModal();
         return;
      }
      if (keyCode === 27) {
         handleNavigateBack();
         return;
      }
   }

   // Effects 
   useEffect(() => {
      if (packingSlipError) {
         dispatch(snakbarShow({ message: packingSlipError }));
         handleNavigateBack();
      }
   }, [packingSlipError]);


   useEffect(() => {
      if (!packingSlip) {
         dispatch(packingSlipStart({ orderId: orderId, shipmentId: shipmentId, fullDomain: fullDomain }));
      }
   }, [dispatch, packingSlip]);

   useEffect(() => {
      document.addEventListener("keydown", handleKeyPress);
      return function cleanup() {
         document?.removeEventListener("keydown", handleKeyPress);
      };
   }, []);

   // Loading and error states
   if (packingSlipLoading) {
      return <div className="packing-slip-loading"></div>;
   }

   if (packingSlipError) {
      return <div className="packing-slip-error">Error loading packing slip</div>;
   }

   return (
      <div className='main-packing-slip'>
         <div className='d-flex packing-slip-header gap-6'>
            <div>
               <PosIconButton
                  className="cancel-packing-slip-btn"
                  onClick={handleNavigateBack}
               >
                  <PosImage src={images.closeIcon} alt="close" />
               </PosIconButton>
            </div>

            <div className="d-flex justify-content-between w-100">
               <h1 className="title">Packing Slip</h1>

               <div className="signature-actions">
                  {displaySignature ? (
                     <div className='text-white'>
                        <Button
                           label="Save"
                           type='primary'
                           onClick={handleSaveSignature}
                           leadingIcon='check'
                           disabled={isModalOpen}
                        />
                     </div>
                  ) : (
                     <Button
                        label={
                           <>Sign
                              <span className="keyboard-shortcut-pay keyboard-shortcut avatar-shortcut ml-2">
                                 S
                              </span>
                           </>
                        }
                        type='secondary'
                        onClick={handleOpenSignaturePad}
                        leadingIcon='draw'
                        iconSize={14}
                     />
                  )}
               </div>
            </div>
         </div>
         <div id="packing-Slip" className='server-html-container'>
            <div>
               <Divider className="packing-slip-divider" />
            </div>

            <main>
               <RenderHtmlWithComponent htmlString={packingSlip || ''} />
            </main>

            <CustomerSendModal
               open={isModalOpen}
               onClose={handleCloseModal}
            />
         </div>
      </div >
   );
};

export default PackingSlip;

