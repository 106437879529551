import LinearProgress from "@mui/material/LinearProgress";
import { useSelector } from "react-redux";

import { IProductsSlice } from "apps/product/modules/productListingContainer/types";
import { PosFlowDataProp, ReturnFlowDataMainProps } from "constants/interfaces";
import { PosResponseProps, RootState } from "models";

import { getIsApiLoading } from "apps/pos/pages/customerInfo/CustomerOrders/redux/selector";
import { NotesSliceI, SingleProductSliceI } from "apps/product/modules/productDetailsContainer/types";
import { ProductAccessorieSliceI } from "sagas/productsDetails/productAccessories.slice";
import { ProductCouponsSliceI } from "sagas/productsDetails/productCoupons.slice";
import "./style.scss";

const LinearLoader = () => {
  const { isLoading, processLoading, isItemLedgerLoaded, isOrderSearching, packingSlipLoading } = useSelector<
    RootState,
    PosResponseProps
  >((state) => state.pos);
  const { isLoading: isLoadingPersistReducer, cartLoading, setUpdatedStockDataOnRefreshLoading } = useSelector<
    RootState,
    PosFlowDataProp
  >((state) => state.persistReducer);
  const { searchReturnInvoiceLoading } = useSelector<
    RootState,
    ReturnFlowDataMainProps
  >((state) => state.returnReducer);

  const { productSearchLoader } = useSelector<RootState, IProductsSlice>(
    (state) => state.productSliceReducer
  );

  const { isRemoveProductsCoupons, isRemoveAllProductsCoupons, isAddProductsCoupons } = useSelector<RootState, ProductCouponsSliceI>(
    (state) => state.productsCouponSlice
  );

  const { isAddProductsAccessories, isRemoveAllRelatedItemsByProduct } = useSelector<RootState, ProductAccessorieSliceI>((state) => state.productsAccessorieSlice);

  const { isRemoveClearancePriceProduct, isDiscontinuedPriceProduct, isDeletePriceScedulesProduct } = useSelector<RootState, SingleProductSliceI>((state) => state.singleProductSlice);

  const isApiLoading = useSelector(getIsApiLoading)
  const { isRemoveProductsNotes, isRemoveAllProductsNotes } = useSelector<RootState, NotesSliceI>((state) => state.productsNoteSlice);


  return (
    <span className="app-loader">
      {[
        packingSlipLoading,
        productSearchLoader,
        isItemLedgerLoaded,
        isLoading,
        isLoadingPersistReducer,
        searchReturnInvoiceLoading,
        processLoading,
        cartLoading,
        isApiLoading,
        isAddProductsAccessories,
        isRemoveClearancePriceProduct,
        isDiscontinuedPriceProduct,
        isRemoveProductsCoupons,
        isRemoveAllProductsCoupons,
        isAddProductsCoupons,
        isRemoveAllRelatedItemsByProduct,
        isDeletePriceScedulesProduct,
        isRemoveAllProductsNotes,
        isRemoveProductsNotes,
        isOrderSearching,
        setUpdatedStockDataOnRefreshLoading
      ].includes(true) ? (
        <LinearProgress />
      ) : null}
    </span>
  );
};

export default LinearLoader;
