import { useNavigate } from "react-router-dom";
import { InputAdornment } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@material-ui/core";
import { CloseIcon, SearchIcon } from "constants/icons";
import _ from "lodash";
import { useHotkeys } from "react-hotkeys-hook";

import {
  PosImage,
  PosInput,
  PosIconButton,
  PosModel,
  PosTypography,
} from "apps/pos/Common/uiCore";

// import { getLocalStoraGeItem } from "utils/utils";
import images from "constants/images";
import { PosResponseProps, RootState } from "models";
import { PosFlowDataProp } from "constants/interfaces";
import { Paths } from "enums";
import { keys } from "utils/helper";

import {
  arrowDownIndexProductPage,
  clearSearchProduct,
  searchReturnProductRequest,
  setSelectedProductIndex,
} from "sagas/pos/actions";
import {
  moveToReturnPageAction,
  searchReturnInvoiceClear,
  searchReturnInvoiceRequest,
} from "sagas/returns/actions";

import useStyles, { StyledTableCell } from "./style";
import "apps/pos/PrivacySettings/privacySettings.scss";
import "apps/pos/poscommonstyling.scss";
import "./searchProductModal.scss";

const SearchProductModel = ({
  open,
  onClose,
  setSelectProduct,
  selectCustomer,
  invoiceNumber,
}) => {
  const [productSearch, setProductSearch] = useState<string>("");
  const productSearchRef = useRef<any>();
  const [pageSize, setPageSize] = useState<number>(1);
  const [searchProductsData, setSearchProductsData] = useState([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [enterPress, setEnterPress] = useState<boolean>(false);
  const [downPress, setDownPress] = useState<boolean>(false);
  const [upPress, setUpPress] = useState<boolean>(false);

  const tableRowRef = useRef();

  const { posFlowData } = useSelector<
    RootState,
    PosFlowDataProp
  >((state) => state.persistReducer);
  const { searchProducts, selectedProductIndex, error, searchProductsTotal, productDataCount } =
    useSelector<RootState, PosResponseProps>((state) => state.pos);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useHotkeys('home', (e) => {
    if (e?.keyCode !== keys.home) return
    dispatch(setSelectedProductIndex(0));
    scrollToSetting(0);
  }
  )
  useHotkeys('end', (e) => {
    if (e?.keyCode !== keys.end) return;
    dispatch(setSelectedProductIndex(searchProductsData?.length - 1));
    scrollToSetting(searchProductsData?.length - 1);
  }
  )

  useEffect(() => {
    if (enterPress) {
      handleProductSelect(searchProductsData[selectedProductIndex]);
      setEnterPress(false);
    }
  }, [enterPress]);

  const scrollToSetting = (selectedProductIndex) => {
    const elementId = document?.getElementById(
      `tableRowRef${selectedProductIndex}`
    );
    elementId?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "end",
    });
  };

  useEffect(() => {
    async function downPressHandler() {
      if (downPress) {
        await dispatch(arrowDownIndexProductPage(searchProductsData?.length));
        scrollToSetting(selectedProductIndex + 1);
        setDownPress(false);
      }
    }
    downPressHandler();
  }, [downPress]);

  useEffect(() => {
    if (upPress) {
      if (selectedProductIndex > -1) {
        scrollToSetting(selectedProductIndex);
      }
      setUpPress(false);
    }
  }, [upPress]);

  // const currentWorkspace = getLocalStoraGeItem("workspace");

  const handleProductSelect = (product) => {
    dispatch(moveToReturnPageAction(false));
    dispatch(setSelectProduct(product));
    onClose();
    setSearchProductsData([]);
    dispatch(searchReturnInvoiceClear());
    navigate(Paths.posReturnProducts, {
      state: { detail: product, selectedCustomer: selectCustomer },
    });

    const invoicePayload = {
      stockWarehouseId: 0, //currentWorkspace?.stockWarehouseId,
      productId: product.productId,
      customerId: selectCustomer?.customerId,
      invoiceOrOrderId: Number(invoiceNumber),
      numOfDays: 0,
      page: pageSize,
      size: 10,
    };
    (selectCustomer?.customerId || invoiceNumber) &&
      dispatch(searchReturnInvoiceRequest(invoicePayload));
  };

  const searchProductsAPI = async (keywords) => {
    await setSearchProductsData([]);
    if (keywords?.length === 0) {
      dispatch(clearSearchProduct());
    }
    if (keywords.length >= 1) {
      setLoader(true);
      debounceFn(keywords);
    }
  };

  const handleDebounceFn = async (keywords) => {
    // const currentWorkspace = JSON.parse(
    //   await localStorage.getItem("workspace")
    // );

    const body = {
      page: 1,
      size: 20,
      stockWarehouseId: 0, //currentWorkspace?.stockWarehouseId,
      query: keywords,
      personId: posFlowData?.customer?.customerId,
    };
    dispatch(searchReturnProductRequest(body));
  };

  const debounceFn = useCallback(_.debounce(handleDebounceFn, 1000), []);

  function handleScroll() {
    // const currentWorkspace = JSON.parse(localStorage.getItem("workspace"));

    setPageSize(pageSize + 1);
    if (searchProductsData.length < searchProductsTotal) {
      const { customer } = posFlowData || {}
      const body = {
        page: pageSize + 1,
        size: 20,
        stockWarehouseId: 0, //currentWorkspace.stockWarehouseId,
        query: productSearch,
        personId: customer?.personId || customer?.customerId,
      };
      dispatch(searchReturnProductRequest(body));
    }
  }

  useEffect(() => {
    setSearchProductsData(searchProducts);
    setLoader(false);
  }, [searchProducts]);

  useEffect(() => {
    if (error) {
      setLoader(false);
    }
  }, [error]);

  useEffect(() => {
    if (open) {
      setSearchProductsData([]);
      setProductSearch("");
    }
  }, [open]);

  useEffect(() => {
    if (searchProducts?.length) {
      setSearchProductsData(
        _.uniqBy([...searchProductsData, ...searchProducts], "productId")
      );
    }
  }, [searchProducts]);

  const classes = useStyles();
  const { boltIcon } = images;

  return (
    <>
      <PosModel
        open={open}
        onClose={() => {
          onClose();
          setSearchProductsData([]);
          setProductSearch("");
        }}
        dialogClassName="search-return-product-modal"
        dialogHeader={
          <div className="search-product-header">
            <PosTypography title="Return Items" className="title" />
            <PosIconButton
              onClick={() => {
                onClose();
                setSearchProductsData([]);
                setProductSearch("");
              }}
              color="primary"
              className="model-header-close-button"
            >
              <CloseIcon className="close-icon" />
            </PosIconButton>
          </div>
        }
      >
        <section className="search-return-product-modal-section">
          <div className="title-with-search-icon">
            <PosTypography
              title="Which product would you like to return?"
              className="product-title"
            />
            <PosImage
              src={images.returnSearch}
              className="search-icon"
              alt="return-search-icon"
            />
          </div>
          <div className="cart-searchbar-wrapper">
            <PosInput
              variant="outlined"
              type="text"
              className="search-product-input"
              autoFocus
              placeholder="Search Product"
              value={productSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              inputRef={productSearchRef}
              onChange={(e) => {
                if (
                  !e.target.value.includes("  ") &&
                  e.target.value.charAt(0) !== " "
                ) {
                  setProductSearch(e.target.value);
                }
              }}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  if (document?.activeElement === productSearchRef.current) {
                    productSearchRef.current.blur()
                  }
                  dispatch(clearSearchProduct());
                  setPageSize(1);
                  searchProductsAPI(productSearch);
                }
              }}
            />
          </div>

          <>
            {loader ? null : error === "errors" ? (
              <Box sx={{ width: "100%" }}>
                <div className="products-found product-found-info">
                  <div className="product-found-text">
                    <span className="int-14-reg">No products found</span>
                  </div>
                  <div className="horizontal-liner-sp product-found-horizontal-line" />
                </div>
              </Box>
            ) : (
              <div className="search-results-container w-100">
                {searchProductsData?.length ? (
                  <div className="products-found product-found-info">
                    <div className="product-found-text">
                      <span className="int-14-reg">
                        {productDataCount}/
                        {searchProductsTotal} product
                        {searchProducts.length > 1 ? "s" : ""} found
                      </span>
                    </div>
                    <div className="horizontal-liner-sp product-found-horizontal-line" />
                  </div>
                ) : (
                  <></>
                )}
                <div className="scroll-styling return-product-modal-data">
                  <TableContainer className="product-search-table-main-container">
                    <InfiniteScroll
                      dataLength={searchProductsData?.length}
                      next={() => {
                        handleScroll();
                      }}
                      height={500}
                      hasMore={searchProductsTotal !== productDataCount}
                      loader={<></>}
                    >
                      <Table
                        className={`${classes.root} product-search-table`}
                        stickyHeader
                        aria-label="sticky table"
                      >
                        {searchProductsData?.length ? (
                          <TableHead className="sticky-table-header product-search-table-head">
                            <TableRow className="product-search-table-header-row">
                              <StyledTableCell align="left"></StyledTableCell>
                              <StyledTableCell align="left">
                                ITEM
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                DESCRIPTION
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                VENDOR
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                        ) : (
                          <></>
                        )}

                        {searchProductsData?.length ? (
                          <TableBody className="table-border-styling">
                            {searchProductsData?.length &&
                              searchProductsData.map((product, index) => {
                                return (
                                  <TableRow
                                    onClick={() => handleProductSelect(product)}
                                    key={index}
                                    className={
                                      index === selectedProductIndex
                                        ? "frequently-used-schedule frequently-used-schedule-index"
                                        : "frequently-used-schedule"
                                    }
                                    ref={tableRowRef[product.productId]}
                                    id={`tableRowRef${index}`}
                                  >
                                    {/* <StyledTableCell align="left"></StyledTableCell> */}
                                    <StyledTableCell align="left">
                                      <div
                                        className={`text-center user-avatar`}
                                      >
                                        <PosImage
                                          src={product?.thumbImg}
                                          className="search-list-img"
                                          alt="default-icon"
                                        />

                                        {product.isPopular === true ? (
                                          <PosImage
                                            src={boltIcon}
                                            className="search-list-icon"
                                            alt="popular-icon"
                                          />
                                        ) : null}
                                      </div>
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      <div className="product-name-with-code">
                                        <span className="int-16-reg normal-content">
                                          {product.productName}
                                        </span>
                                        <span className="manufacture-code">
                                          {product.manufacturerCode}
                                        </span>
                                      </div>
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      <span className="int-16-reg normal-content">
                                        {product.description}
                                      </span>
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      <span className="vender-name-text">
                                        {product.vendorName
                                          ? product.vendorName
                                          : "-"}
                                      </span>
                                    </StyledTableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        ) : (
                          <></>
                        )}
                      </Table>
                    </InfiniteScroll>
                  </TableContainer>
                </div>
              </div>
            )}
          </>
        </section>
      </PosModel>
    </>
  );
};
export default SearchProductModel;
