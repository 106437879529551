import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import AddIcon from '@material-ui/icons/Add';
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from "@material-ui/icons/GetApp";
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BoltIcon from '@mui/icons-material/Bolt';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CloseIcon from '@mui/icons-material/Close';
import ContactsIcon from '@mui/icons-material/Contacts';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CreditCardOffIcon from '@mui/icons-material/CreditCardOff';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DownloadIcon from '@mui/icons-material/Download';
import ErrorIcon from '@mui/icons-material/Error';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import HelpIcon from '@mui/icons-material/Help';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PasswordIcon from '@mui/icons-material/Password';
import PercentIcon from '@mui/icons-material/Percent';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import PrintIcon from '@mui/icons-material/Print';
import PublicIcon from '@mui/icons-material/Public';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ReceiptLongRoundedIcon from '@mui/icons-material/ReceiptLongRounded';
import RefreshIcon from '@mui/icons-material/Refresh';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import RestoreIcon from '@mui/icons-material/Restore';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import SummarizeIcon from '@mui/icons-material/Summarize';
import WarningIcon from '@mui/icons-material/Warning';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import { InputAdornment } from "@mui/material";
export {
  AddCircleIcon, AddIcon, ArrowBackRoundedIcon, ArrowDropDownIcon, ArrowDropUpIcon, ArrowForwardIcon,
  ArrowForwardIosIcon, ArrowUpwardIcon, AssignmentTurnedInIcon, AttachMoneyIcon, BoltIcon, BorderColorIcon, Brightness1Icon, CardMembershipIcon, CheckCircleIcon, CheckCircleOutlineOutlinedIcon, CheckCircleRoundedIcon, CheckIcon, ClearIcon, CloseIcon, ContactsIcon, ContentCopyIcon, CreditCardIcon, CreditCardOffIcon, DeleteOutlineRoundedIcon, Diversity1Icon, DoDisturbIcon, DoneAllIcon, DownloadIcon, EditIcon, ErrorIcon, FiberManualRecordIcon, FileUploadIcon, FormatListBulletedIcon, ForwardToInboxIcon, GetAppIcon, HelpIcon, HelpOutlineIcon, HelpOutlineOutlinedIcon, HourglassTopIcon, InfoIcon, InfoOutlinedIcon, InputAdornment, InsertDriveFileIcon, KeyboardArrowLeft,
  KeyboardArrowRight, KeyboardIcon, LocalOfferIcon, MonetizationOnIcon, MoneyOffIcon, OpenInNewIcon, PasswordIcon, PercentIcon, PersonAddIcon, PhotoLibraryIcon, PrintIcon, PublicIcon, QueryStatsIcon, ReceiptIcon, ReceiptLongRoundedIcon, RefreshIcon, RemoveRedEyeIcon, ReplayCircleFilledIcon, ReportProblemIcon, RestoreIcon, SearchIcon, SettingsIcon, ShoppingBagIcon, SkipNextIcon, SkipPreviousIcon, SquareFootIcon, StarIcon, StarOutlineIcon, SummarizeIcon, UnfoldLessIcon,
  UnfoldMoreIcon, WarningIcon, WorkspacesIcon
};

