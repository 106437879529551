import React, { useEffect, useState } from "react";
import { SignatureDialog } from "./SignatureDialog";
import { trimSignature } from "../utils/signature";
import MaterialIcon from "apps/pos/Common/uiCore/MaterialIcon/MaterialIcon";
import { useDispatch, useSelector } from "react-redux";
import { clearSignatures } from "sagas/pos/actions";
import { setSignatures } from "sagas/pos/actions";

interface SignaturePadProps {
  onSignatureChange?: (signature: string | null) => void;
  className?: string;
  isOpen?: boolean;
  autoOpen?: boolean;
  name?: string;
}

export function SignaturePad({
  className = "",
  autoOpen = false,
  name
}: SignaturePadProps) {

  const dispatch = useDispatch();
  const { displaySignature, originalSignature } = useSelector((state: any) => state.pos);

  const [signaturePadProps, setSignaturePadProps] = useState<{
    isOpen: boolean;
    autoOpen?: boolean;
  }>({
    isOpen: autoOpen,
    autoOpen,
  });

  const handleKeyPress = (e) => {
    const { keyCode } = e;
    if (['INPUT', 'TEXTAREA'].includes(e.target.tagName)) {
      return;
    }
    if (keyCode === 83) {
      setSignaturePadProps({ isOpen: !signaturePadProps.isOpen })
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return function cleanup() {
      document?.removeEventListener("keydown", handleKeyPress);
    };
  }, [signaturePadProps.isOpen]);

  const handleSaveSignature = async (signatureData: string) => {
    const trimmed = await trimSignature(signatureData);
    dispatch(setSignatures({
      display: trimmed,
      original: signatureData
    }));
  };

  const resetSignature = () => {
    dispatch(clearSignatures());
  };

  return (
    <div className={`${className} signature-button-container`}>
      <button
        onClick={() => setSignaturePadProps({ isOpen: true })}
        className={`signature-button ${displaySignature ? 'has-signature' : 'signature-btn'}`}
      >
        {displaySignature ? (
          <div className="signature-content">
            <img
              src={displaySignature ?? originalSignature}
              alt=""
            />
            <p>{name}</p>
          </div>
        ) : (
          <div className="signature-content">
            <div>
              <div className="d-flex justify-content-between align-items-center">
                <span className="d-flex align-items-center">
                  <MaterialIcon icon="draw" size="14" classes="mr-2" />
                  <p>Sign here</p>
                </span>
                <span className="keyboard-shortcut-pay keyboard-shortcut avatar-shortcut ml-2">
                  S
                </span>
              </div>
            </div>
          </div>
        )}
      </button>

      <SignatureDialog
        isOpen={signaturePadProps?.isOpen ?? false}
        onClose={() => setSignaturePadProps({ isOpen: false })}
        onSave={handleSaveSignature}
        initialSignature={originalSignature ?? undefined}
        resetSignature={resetSignature}
      />
    </div>
  );
}