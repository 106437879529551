import { Divider } from "@mui/material";
import { PosImage } from "apps/pos/Common/uiCore";
import { buttonTitle } from "constants/constant";
import images from "constants/images";
import { Paths } from "enums";
import { Icon } from "apps/springboard/Component/SBItems/elements";

const ProductStatusItem = ({ handleSelectStatus, item, statusValue, checkSmall, isClickable }): any => {
    return (
        <>
            <div className="d-flex align-items-start product-detail-status-item" onClick={() => !isClickable && handleSelectStatus(item)}>
                {statusValue?.status === item?.status &&
                    <PosImage src={checkSmall} alt="checked product" className="product-detail-status-checked-icon" />}
                <div className={`product-status-${item?.status} product-detail-status-sec`}>
                    <div className="status-name">
                        <span className="status-icon"></span>
                        {item?.label}
                    </div>
                    <div className="status-subtitle">{item?.subLabel}</div>
                </div>
                {isClickable &&
                    <Icon name={"north_east"} className={"product-detail-active-link-icon"} color={"#000"} />
                }
            </div>
            {item.id === 1 && <Divider className="divider-margin-left" />}
        </>
    )
}


const ProductStatusDetail = ({
    statusValue,
    setStatusBox,
    productStatusOptions,
    statusBox,
    handleSelectStatus,
    inputRef,
    id,
    productId,
    distributor,
    activeFlag
}) => {
    const { checkSmall, downSmallArrow } = images;
    const { active } = buttonTitle;
    const statusActivelink = `${distributor?.httpsUrl}${Paths.manageStatusActive}?updateFlag=1&productID=${productId}`

    return (
        <>
            <div id={id} ref={inputRef} className={`main-detail-product-status product-status-${statusValue?.status ? statusValue?.status : 'Active'}`} onClick={() => setStatusBox(!statusBox)} >
                <span className="status-icon"></span>
                <span onClick={() => setStatusBox(!statusBox)} className={`selected-status-label ${statusBox ? 'text-underline' : ''}`}>
                    {statusValue?.label ? statusValue?.label : active}
                </span>
                <PosImage src={downSmallArrow} alt="arrow" className={statusBox ? 'up-arrow' : 'down-arrow'} />
            </div>
            <div className="product-detail-main-status-div">
                {statusBox && <div className="product-detail-status" >
                    {productStatusOptions?.map((item) => {
                        const isClickable = item.id === 1 && activeFlag !== 1;
                        if (isClickable) {
                            return (
                                <a href={statusActivelink} target="_blank" style={{ color: "inherit !important" }}>
                                    <ProductStatusItem handleSelectStatus={handleSelectStatus} item={item} statusValue={statusValue} checkSmall={checkSmall} isClickable={isClickable} />
                                </a>
                            )
                        } else {
                            return <><ProductStatusItem handleSelectStatus={handleSelectStatus} item={item} statusValue={statusValue} checkSmall={checkSmall} isClickable={isClickable} /></>
                        }
                    })}
                </div>}
            </div>
        </>
    )
}

export default ProductStatusDetail
