import "@fontsource/inter";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Container,
  PosButton,
  PosInput,
  PosPageHeading,
  PosSnackbar,
} from "apps/pos/Common/uiCore";
import { Button, Select } from "components";
import DeleteDraftComponent from "./SavedOrdersFoundComponent/DeleteDraftComponent";
import DraftList from "./SavedOrdersFoundComponent/DraftList";

import { SavedOrdersFoundProps } from "./SavedOrdersFoundComponent/type";

import { sectionTitle } from "constants/constant";
import { AddIcon, ClearIcon, SearchIcon, SkipNextIcon } from "constants/icons";
import { optionsDraftSort } from "constants/staticData";
import { Paths } from "enums";
import { RootState } from "models";
import { getContentById, keys, renamePersonData } from "utils/helper";

import {
  clearLoadCartDetails,
  loadCartRequest,
  recipientInfoSwitchInfoData,
  setCustomerAccountInfo,
  setCustomerAlertHide,
  setPosFlowData,
  shippingAddressSelectedData,
  skipOrdererInfo
} from "sagas/persistReducer/actions";
import {
  arrowDraftDownIndex,
  arrowDraftUpIndex,
  cartClearDraftsRequest,
  getShippingFacilitiesRequest,
  savedDraftsRequest,
  savedDraftsReset,
  setJobNameForAll,
  setMarkAsQuoteReducer,
  setShippingInstructionNote,
  setStoreCreditAmout,
} from "sagas/pos/actions";
import { cartClearAllDrafts } from "services/pos/pos.service";

import { snakbarShow } from "sagas/pos/snakbar/snakbar.slice";
import "./savedOrdersFound.scss";

const SavedOrdersFound = ({
  setState,
  state,
  savedOrderFoundInfoRef,
  setIsDeleteClick,
  isDeleteClick
}: SavedOrdersFoundProps) => {
  const dispatch = useDispatch();

  const { savedDrafts, selectedDraftIndex, posFlowData, customerAccount, loadCartDetails } =
    useSelector<RootState, any>((state) => ({
      ...state.pos,
      ...state.persistReducer,
    }));

  const navigate = useNavigate();
  const draftRowRef = useRef<any>();
  const savedDraftRef = useRef<any>();

  const [searchString, setSearchString] = useState("");
  const [deleteAllPopUp, setDeleteAllPopUp] = useState(false);
  const [deleteCartId, setDeleteCartId] = useState<any>({});
  const [draftData, setDraftData] = useState<any>(savedDrafts?.data || []);
  const [selectedOption, setSelectedOption] = useState(
    optionsDraftSort[0].value
  );
  const [sortedData, setSortedData] = useState([]);
  const [downPress, setDownPress] = useState(false);
  const [upPress, setUpPress] = useState(false);
  const [isSnackbarVisibleResume, setIsSnackbarVisibleResume] = useState(false);
  const [isDraftCartId, setIsDraftCartId] = useState("");
  const [showDraftDrawer, setShowDraftDrawer] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);

  useEffect(() => {
    setDeletePopUp(isDeleteClick)
    if (!isDeleteClick) {
      setDeleteAllPopUp(isDeleteClick)
    }
  }, [isDeleteClick])

  useEffect(() => {
    setSortedData(savedDrafts?.data.reverse() && savedDrafts?.data.reverse());
  }, []);

  useEffect(() => {
    if (!deletePopUp && (selectedOption === optionsDraftSort[1].value || optionsDraftSort[0].value)) {
      setSortedData(savedDrafts?.data.reverse());
    } else {
      setSortedData(savedDrafts?.data);
    }
  }, [selectedOption, searchString, deletePopUp]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return function cleanup() {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  useEffect(() => {
    if (downPress) {
      scrollToSetting(selectedDraftIndex + 3);
      dispatch(arrowDraftDownIndex(sortedData.length));
      setDownPress(false);
    }
  }, [downPress]);

  useEffect(() => {
    if (upPress) {
      if (selectedDraftIndex > -1) {
        scrollToSetting(selectedDraftIndex);
      }
      setUpPress(false);
    }
  }, [upPress]);

  useEffect(() => {
    if (
      loadCartDetails !== undefined &&
      loadCartDetails?.products &&
      isDraftCartId
    ) {
      const { isPosGeneric, fullName, companyName, phone, email } = loadCartDetails?.customer || {}
      if (isPosGeneric) {
        const splitFullname = (fullName?.split(" "))
        const genericUserFirstName = splitFullname?.slice(0, -1).join(" ");
        const genericUserLastName = splitFullname[splitFullname?.length - 1];

        const data = {
          ...posFlowData,
          firstName: genericUserFirstName || "",
          lastName: genericUserLastName || "",
          phone: phone || "",
          email: email || "",
          companyName: companyName || "",
          isStorePickup: false
        };
        dispatch(recipientInfoSwitchInfoData(false));
        dispatch(skipOrdererInfo(false))
        dispatch(setPosFlowData(data));
      }
      // *? If you want to open Continue with old price model then uncomment this code
      // const isPriceChangesProduct = loadCartDetails?.products?.filter(
      //   (item: any) => item?.isPriceChanged
      // );
      // if (isPriceChangesProduct?.length > 0) {
      //   dispatch(getupdateItemModal(true));
      //   if (isDraftCartId) {
      //     setTimeout(() => {
      //       setIsSnackbarVisibleResume(false);
      //       navigate(`${Paths.posCart}/${isDraftCartId}`);
      //     }, 1000);
      //   }
      // } else {
      if (isDraftCartId) {
        setTimeout(() => {
          navigate(`${Paths.posCart}/${isDraftCartId}`);
        }, 1000);
      } else {
        if (showDraftDrawer) {
          Boolean(loadCartDetails?.cartId) &&
            navigate(`${Paths.posCart}/${loadCartDetails?.cartId}`);
        }
      }
      // }
    } else {
      Boolean(loadCartDetails?.cartId) &&
        navigate(`${Paths.posCart}/${loadCartDetails?.cartId}`);
    }
  }, [loadCartDetails]);

  const scrollToSetting = (selectedDraftIndex) => {
    const elementId = document?.getElementById(
      `draftRowRef${selectedDraftIndex}`
    );

    elementId?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "end",
    });
  };

  const handleKeyPress = (e) => {
    if (document.activeElement !== savedDraftRef.current) {
      if (e.keyCode === keys.d) {
        loadCartAPI(draftData?.length ? draftData[0]?.cartId : null);
        setIsDraftCartId(draftData?.length ? draftData[0]?.cartId : null);
        setIsSnackbarVisibleResume(true);
      }
      if (e.keyCode === keys.n) {
        const data = {
          ...posFlowData,
          cartProducts: [],
          addInStorage: []
        };
        dispatch(clearLoadCartDetails());
        dispatch(setPosFlowData(data));
        loadCartAPI(null);
        setIsDraftCartId(null);
      }
      if (e.keyCode === keys.downarrow) {
        setDownPress(true);
      }
      if (e.keyCode === keys.uparrow) {
        dispatch(arrowDraftUpIndex());
        setUpPress(true);
      }
    }
  };

  const clearCartIdAPI = async () => {
    const currentWorkspace = JSON.parse(localStorage.getItem("workspace"));
    const payload = {
      stockWarehouseId: currentWorkspace?.stockWarehouseId,
      buyerId: deleteCartId.customerId,
    };
    dispatch(cartClearDraftsRequest(Number(deleteCartId?.cartId)));
    if (draftData.length > 1) {
      dispatch(savedDraftsRequest(payload));
    }
    dispatch(snakbarShow({ message: "Saved draft deleted" }))
    setTimeout(function () {
      const filteredData = draftData.filter(
        (data) => data?.cartId !== deleteCartId?.cartId
      );
      setDraftData(filteredData);
      setDeletePopUp(false);
      setIsDeleteClick(false);
    }, 1000);

    setTimeout(() => {
      const filteredDataInfo = draftData.filter(
        (data) => data?.cartId !== deleteCartId?.cartId
      );
      setDraftData(filteredDataInfo);
      if (filteredDataInfo?.length === 0) {
        const data = {
          ...posFlowData,
          draftCartDeleted: true,
        };
        setState({ ...state, right: false });
        dispatch(savedDraftsReset());
        dispatch(setPosFlowData(data));
      }
    }, 1000);
  };

  const clearAllDraftAPI = () => {
    cartClearAllDrafts(Number(posFlowData.customer.customerId));
    dispatch(snakbarShow({ message: "Deleting..." }))
    setTimeout(function () {
      dispatch(snakbarShow({ message: "All drafts deleted" }))
      setDeletePopUp(false);
      setIsDeleteClick(false);
    }, 1000);

    setTimeout(() => {
      setState({ ...state, right: false });
      dispatch(savedDraftsReset());
    }, 2000);
  };

  const loadCartAPI = (draftCartId) => {
    draftCartId && setIsSnackbarVisibleResume(true);
    const currentWorkspace = JSON.parse(localStorage.getItem("workspace"));
    const currentTaker = JSON.parse(localStorage.getItem("taker"));
    const preparedData = {
      stockWarehouseId: currentWorkspace.stockWarehouseId,
      customerId: posFlowData?.customer?.customerId,
      takerId: currentTaker?.personId,
      cartId: draftCartId,
    };
    dispatch(getShippingFacilitiesRequest(posFlowData?.customer?.customerId));
    dispatch(loadCartRequest(preparedData));
    !draftCartId && setShowDraftDrawer(true);
  };

  // start
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  )
  const contentSec = getContentById()

  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener('change', e => setMatches(e.matches));
  }, [contentSec]);

  useEffect(() => {
    const elHeight = document.getElementById("drawer-header")?.clientHeight;
    if (contentSec && matches) {
      contentSec.style.paddingTop = `${elHeight + 20}px`;
    }
    else if (contentSec && !matches) {
      contentSec.style.paddingTop = '0px';
    }
  }, [contentSec, matches]);
  // end

  const { resumeLastDraft } = sectionTitle;

  const handleLoadCart = () => {
    dispatch(clearLoadCartDetails());
    const data = {
      ...posFlowData,
      salesman: undefined,
      cartProducts: [],
      addInStorage: [],
      isCartAvailable: false,
      addDocument: [],
      poNumber: "",
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      companyName: "",
      selectQuoteName: "",
      selectQuoteId: null,
      selectQuoteType: "",
    };

    dispatch(setPosFlowData(data));
    dispatch(setShippingInstructionNote(""));
    dispatch(setStoreCreditAmout(""));
    dispatch(setJobNameForAll(""));
    dispatch(setMarkAsQuoteReducer(false));
    dispatch(setCustomerAlertHide(true));
    loadCartAPI(null);
    setIsDraftCartId(null);
    dispatch(shippingAddressSelectedData(false));
    dispatch(setCustomerAccountInfo(customerAccount));
  }

  const handleSearch = (value) => {
    if (
      !value.includes("  ") &&
      value.charAt(0) !== " "
    ) {
      setSearchString(value);
    }
  }

  return (
    <>
      <Container className="modal-content-style modal-container-styling" ref={savedOrderFoundInfoRef}>
        {!deletePopUp ? (
          <main>
            <div>
              <div className="drawer-static-header" id="drawer-header">
                <div className="pricing-modal-header">
                  <PosPageHeading heading="Saved drafts found" blackHeading />
                </div>
                <div className="select-ws-text">
                  <h3 className="select-schedule-list mr-0">
                    One or more drafts found for {renamePersonData(posFlowData?.customer?.posCustCode, posFlowData?.customer?.companyName)}
                    . Resume a saved draft or start a new order. Optionally, you
                    can also delete any previous drafts that you don’t need.
                  </h3>
                </div>
                <div className="shipping-btns delete-drawer-btn-spacing">
                  {draftData?.length ? (
                    <>
                      <Button
                        className="modal-select-default-btn ship-btn-dark border-0 button-focus-resume-draft"
                        type="button"
                        onClick={() => {
                          loadCartAPI(
                            draftData?.length ? draftData[0]?.cartId : null
                          );
                          setIsDraftCartId(
                            draftData?.length ? draftData[0]?.cartId : null
                          );
                          setIsSnackbarVisibleResume(true);
                        }}
                      >
                        <div className="select-default-text">
                          <div>
                            <SkipNextIcon />
                          </div>
                          <div className="default-schedule-heading">
                            <span className="int-18-reg-bold-white">
                              {resumeLastDraft}
                            </span>
                          </div>
                        </div>
                        <div>
                          <span className="keyboard-shortcut avatar-shortcut text-black">
                            D
                          </span>
                        </div>
                      </Button>
                    </>
                  ) : null}

                  <Button
                    className="modal-select-default-btn ship-btn border-0 button-focus-resume-draft"
                    type="button"
                    onClick={handleLoadCart}
                  >
                    <div className="select-default-text">
                      <div>
                        <AddIcon />
                      </div>
                      <div className="default-schedule-heading">
                        <span className="int-18-reg-bold">Start a New Order</span>
                      </div>
                    </div>
                    <div>
                      <span className="keyboard-shortcut avatar-shortcut">N</span>
                    </div>
                  </Button>
                </div>
                {draftData?.length > 0 ? (
                  <div className="search-with-close-input">
                    <div className="search-icon">
                      <SearchIcon />
                    </div>
                    <PosInput
                      variant="outlined"
                      id="outlined-basic"
                      className="double-bordered-input"
                      placeholder="Search previous drafts"
                      value={searchString}
                      inputRef={savedDraftRef}
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                    <Button
                      className="close-icon border-0 bg-transparent p-0 button-focus"
                      onClick={() => {
                        setSearchString("");
                      }}
                    >
                      <ClearIcon />
                    </Button>
                  </div>
                ) : (
                  <></>
                )}
              </div>

              {draftData?.length && !Boolean(searchString) ? (
                <div className="modal-subheading modal-subheading-delete mb-2"
                  id="shippingScrollStyling">
                  <div className="sort-by-sec sort-by-focus">
                    <span className="text-black d-flex sort-by-div">
                      SORT BY:
                      <Select
                        isClassName={false}
                        name="sortBy"
                        selectClassName="select-draft-sort"
                        defaultValue={selectedOption}
                        value={selectedOption}
                        onChange={(e) => setSelectedOption(e.target.value)}
                        options={optionsDraftSort.map(({ label, value }) => {
                          return {
                            label,
                            value,
                          };
                        })}
                      />
                    </span>
                  </div>
                  <div className="delete-all-draft-btn-sec">
                    <PosButton
                      className="delete-saved-draft delete-all-draft-btn"
                      variant="text"
                      onClick={() => {
                        setIsDeleteClick(true);
                        setDeleteAllPopUp(true);
                        setDeletePopUp(true);
                      }}
                    >
                      Delete All
                    </PosButton>
                  </div>
                </div>
              ) : (
                <></>
              )}
              <DraftList
                draftRowRef={draftRowRef}
                loadCartAPI={loadCartAPI}
                setIsDraftCartId={setIsDraftCartId}
                setDeletePopUp={setDeletePopUp}
                setIsDeleteClick={setIsDeleteClick}
                setDeleteCartId={setDeleteCartId}
                stringSearch={searchString}
                isSnackbarVisibleResume={isSnackbarVisibleResume}
                selectedOption={selectedOption}
                searchString={searchString}
              />
            </div>
          </main>
        ) : (
          <DeleteDraftComponent
            deleteAllPopUp={deleteAllPopUp}
            setDeleteAllPopUp={setDeleteAllPopUp}
            deleteCartId={deleteCartId}
            clearAllDraftAPI={clearAllDraftAPI}
            setIsDeleteClick={setIsDeleteClick}
            setDeletePopUp={setDeletePopUp}
            clearCartIdAPI={clearCartIdAPI}
            setCustomerDraftDelete={() => {
              // required Later
            }}
          />
        )}
        <PosSnackbar
          open={isSnackbarVisibleResume}
          message="Resuming draft"
          onClose={() => {
            setIsSnackbarVisibleResume(false);
          }}
          actionText={"Clear Cart"}
        />
      </Container>
    </>
  );
};

export default SavedOrdersFound;
