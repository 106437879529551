import { Grid } from "@material-ui/core";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  PosButton,
  PosTooltip,
} from "apps/pos/Common/uiCore";
import PosToggle from "apps/pos/Common/uiCore/posToggle/posToggle";
import SetCustomerCode from "apps/pos/pages/customerInfo/Modal/SetCustomerCode";
import { CreditCardOffIcon, MoneyOffIcon } from "constants/icons";
import { constantCustomerInfo, customerType } from "../customerInfo/constantHelper";
import SetPricingScheduleModal from "../customerInfo/Modal/SetPricingScheduleModal";

import { DateFormat } from "constants/enum";
import { PosFlowDataProp } from "constants/interfaces";
import { RootState } from "models";
import { phoneNumberFormatNew } from "utils/utils";

import { setIsMail, setPosFlowData } from "sagas/persistReducer/actions";
import { noMailStart } from "sagas/pos/actions";

import "./customerDetails.scss";
import { useGetCustomerDetails } from "./hooks/useGetCustomerDetails";

const CustomerInfoUpdated = () => {
  const { posFlowData } = useSelector<RootState, PosFlowDataProp>(
    (state) => state.persistReducer
  );

  const {
    fullName,
    isPosGeneric,
    phone,
    createdAt,
    companyName,
    city,
    state: stateName,
    customerId,
  } = posFlowData?.customer || {};
  const [openEditCustomerCode, setOpenEditCustomerCode] = useState(false);
  const [openEditPricingSchedule, setOpenEditPricingSchedule] = useState(false);
  const lastName = fullName?.split(" ")[1];
  const { setCustomerParamsId, userDetails } = useGetCustomerDetails()

  const { creditHoldFlag, posCustCode, priceScheduleId, priceScheduleName, taxExemptFlag, email, storeCreditAmt, lastOrderedAt } = userDetails ?? {}
  const [posCustCodes, setPosCustCodes] = useState(posCustCode);

  let phoneNumber = "";
  if (phone) {
    phoneNumber = phoneNumberFormatNew(
      phone?.intCode,
      phone?.areaCode,
      phone?.prefix,
      phone?.lineNum,
      phone?.extn,
      false
    );
  }

  useEffect(() => {
    if (customerId) {
      setCustomerParamsId(customerId)
    }
  }, [customerId])

  useEffect(() => {
    setPosCustCodes(posCustCode);
  }, [posCustCode])

  const { MMMM_D_YYYY_HH_MM } = DateFormat;
  const dispatch = useDispatch()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    document.getElementById("notification-toggle").focus();
    dispatch(noMailStart({ personId: customerId, noMail: Number(event.target.checked) }))
    dispatch(setIsMail(event.target.checked))
    dispatch(setPosFlowData({ ...posFlowData, isMail: event.target.checked }))
  };

  const isActiveCart = window.location.search.includes("cart");
  const priceScheduleEdit = isActiveCart ? false : posFlowData?.schedule?.name
  return (
    <div className="customer-modal-container">
      <div>
        <Grid
          container
          className="credit-balance-grid-section"
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            className="balance-credit-sec"
          >
            <div className="d-flex unpaid-balance-info">
              <div className="balance-title">
                <span>
                  STORE CREDIT
                </span>
              </div>
              <div className="balance-data">
                <span className={`${storeCreditAmt ? "" : "no-balance-data"}`}>
                  ${storeCreditAmt ? storeCreditAmt.toFixed(2) : "0.00"}
                </span>
              </div>
            </div>
          </Grid>
          {(creditHoldFlag || taxExemptFlag) ? <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            className="tex-credit-sec"
          >
            {creditHoldFlag ? (<div className="credit-section">
              <span className="credit-info">
                <CreditCardOffIcon />
                {constantCustomerInfo.creditHold}
              </span>
            </div>) : null}
            {taxExemptFlag ? (
              <div className="credit-section">
                <PosTooltip title="Customer Tax Exempt" placement="top">
                  <MoneyOffIcon className="money-off-icon ml-0" />
                </PosTooltip>
                <span className="tax-text">{constantCustomerInfo.taxExempt}</span>
              </div>) : null}
          </Grid> : null}
        </Grid>
      </div>
      <div
        className={`${lastName === customerType.generic ? "mt-0" : ""
          } customer-details-section `}
      >
        <div className="code-contaier customer-account-row customer-account-row-style flex-wrap">
          <div className="d-flex flex-wrap align-items-center">
            <span className="mb-0 customer-info-title-left-section">
              Code
            </span>
            <div className="customer-used-schedule">
              <span className={`customer-schedule-list ${posCustCodes ? "" : "light-gray-text"}`}>{posCustCodes ? posCustCodes : "Not Set"}</span>
            </div>
          </div>
          {(!isPosGeneric && !isActiveCart) ?
            <div className="d-flex align-items-center customr-account-sec-btn">
              <PosButton
                onClick={() => setOpenEditCustomerCode(true)}
                className="mr-2 customer-info-change-btn btn cust-code-change-btn"
                variant="contained"
                tabIndex={3}
              >
                Change
              </PosButton>
            </div>
            : null}
        </div>
        {companyName ? (
          <div className="d-flex customer-account-row customer-account-row-style flex-wrap">
            <span className="mb-0 customer-info-title-left-section">
              {constantCustomerInfo.company}
            </span>
            <div className="customer-used-schedule">
              <span className="customer-schedule-list">{companyName}</span>
            </div>
          </div>
        ) : null}
        <div className="d-flex customer-account-row customer-account-row-style flex-wrap">
          <span className="mb-0 customer-info-title-left-section">
            {constantCustomerInfo.address}
          </span>
          <div className="customer-used-schedule">
            <div className="d-flex flex-column flex-wrap">
              <span className="customer-schedule-list">
                {city}, {stateName}
              </span>
            </div>
          </div>
        </div>
        <div className="code-contaier customer-account-row customer-account-row-style flex-wrap">
          <div className="d-flex flex-wrap align-items-center">
            <span className="mb-0 customer-info-title-left-section">
              {constantCustomerInfo.pricingSchedule}
            </span>
            <div className="customer-used-schedule">
              <span className={`customer-schedule-list  ${(priceScheduleName || priceScheduleEdit) ? "" : "light-gray-text"}`}>{(priceScheduleEdit || priceScheduleName) ? priceScheduleEdit || priceScheduleName : "Not Set"}</span>
            </div>
          </div>
          {(!isPosGeneric && !isActiveCart) ?
            <div className="d-flex align-items-center customr-account-sec-btn">
              <PosButton
                className="mr-2 customer-info-change-btn btn cust-code-change-btn"
                onClick={() => setOpenEditPricingSchedule(true)}
                tabIndex={4}
              >
                Change
              </PosButton>
            </div>
            : null}
        </div>
        {phone ? (
          <div className="d-flex customer-account-row customer-account-row-style flex-wrap">
            <span className="mb-0 customer-info-title-left-section">
              {constantCustomerInfo.phoneNumber}
            </span>
            <div className="customer-used-schedule">
              <span className="customer-schedule-list">
                {phoneNumber ? phoneNumber : ""}
              </span>
            </div>
          </div>
        ) : null}
        {email ? (
          <div className="d-flex customer-account-row customer-account-row-style flex-wrap">
            <span className="mb-0 customer-info-title-left-section">
              {constantCustomerInfo.emailAddress}
            </span>
            <div className="customer-used-schedule">
              <span className="customer-schedule-list">{email}</span>
            </div>
          </div>
        ) : null}
        {lastOrderedAt ? (
          <div className="d-flex customer-account-row customer-account-row-style flex-wrap">
            <span className="mb-0 customer-info-title-left-section">
              {constantCustomerInfo.lastOrder}
            </span>
            <div className="customer-used-schedule">
              <div className="d-flex flex-column flex-wrap">
                <span className="customer-schedule-list">
                  {moment(lastOrderedAt).format(MMMM_D_YYYY_HH_MM)}
                </span>
                <div className="small-text gray-text mt-2">
                  <span className="ml-0">{moment(lastOrderedAt).fromNow()}</span>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {createdAt ? (
          <div className="d-flex customer-account-row customer-account-row-style flex-wrap">
            <span className="mb-0 customer-info-title-left-section">
              {constantCustomerInfo.customerSince}
            </span>
            <div className="customer-used-schedule">
              <div className="d-flex flex-column">
                <span className="customer-schedule-list">
                  {moment(createdAt).format(MMMM_D_YYYY_HH_MM)}
                </span>
                <div className="small-text gray-text mt-2">
                  <span className="ml-0">{moment(createdAt).fromNow()}</span>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div>
          <div className="customer-notification-sec-title">
            <span>
              Notification
            </span>
          </div>
          <div>
            <div className="d-flex customer-account-row customer-account-row-style flex-wrap">
              <span className="mb-0 customer-info-title-left-section">
                Opt out of all notifications
              </span>
              <div className="customer-used-schedule customer-notification-toggle toggle-focus">
                <PosToggle value={Boolean(posFlowData?.isMail)} onChange={handleChange} customerDetails tabIndex={5} id="notification-toggle" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <SetCustomerCode
        openEditCustomerCode={openEditCustomerCode}
        setOpenEditCustomerCode={setOpenEditCustomerCode}
        posCustCode={posCustCodes}
        setPosCustCodes={setPosCustCodes}
      />
      <SetPricingScheduleModal
        openEditPricingSchedule={openEditPricingSchedule}
        setOpenEditPricingSchedule={setOpenEditPricingSchedule}
        priceScheduleId={priceScheduleId}
      />
    </div>
  );
};
export default CustomerInfoUpdated;