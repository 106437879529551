import { Box, Drawer } from "@material-ui/core";
import { InputAdornment } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Button from "apps/pos/Common/Button/Button";
import HandleLongData from "apps/pos/Common/HandleLongData";
import {
  Container,
  PosImage,
  PosInput,
  PosSnackbar,
} from "apps/pos/Common/uiCore";
import DeleteDraftComponent from "apps/pos/SavedOrdersFoundComponent/DeleteDraftComponent";
import Skeleton from "../Skeleton";

import { snackbarDetail, staticData, title } from "constants/constant";
import { CloseIcon, SearchIcon } from "constants/icons";
import images from "constants/images";
import { PosFlowDataProp } from "constants/interfaces";
import { Paths, SortIndex } from "enums";
import { PosResponseProps, RootState } from "models";
import { drawerUseStyles } from "theme/GlobalStyles";
import { DocumentSortBy } from "utils/data";
import { keys } from "utils/helper";
import { getLocalStoraGeItem, scrollToSetting } from "utils/utils";
import { constantCustomerInfo } from "../constantHelper";

import {
  loadCartRequest,
  recipientInfoSwitchInfoData,
  savedDraftsListPageRequest,
  savedDraftsListPageReset,
  setPosFlowData,
  deleteDraftOptimistically
} from "sagas/persistReducer/actions";
import {
  arrowDownIndexGeneric,
  arrowResetGeneric,
  arrowUpIndexGeneric,
  clearPackingSlipData,
  clearSearchDrafts,
  getShippingFacilitiesRequest,
  savedDraftsRequest,
  savedDraftsReset,
  searchDraftsRequest,
  setJobNameForAll
} from "sagas/pos/actions";
import { cartClear } from "services";

import { snakbarShow } from "sagas/pos/snakbar/snakbar.slice";
import { useGetCustomerDetails } from "../../CustomerDetails/hooks/useGetCustomerDetails";
import "./customerDrafts.scss";

const CustomerDrafts = ({
  handleCloseModal,
  setDeleteSuccess,
  setDraftCartId,
  savedOrderFoundInfoRef,
}) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const {
    customerAccount,
    savedDraftsList,
    posFlowData,
    isDraftLoading,
    loadCartDetails,
    isLoadCartSuccess,
  } = useSelector<RootState, PosFlowDataProp>((state) => state.persistReducer);

  const {
    searchDrafts,
    // draftLoadMessage,
    selectedIndexGeneric,
    isDraftSearchLoading: isSearchApiLoading,
  } = useSelector<RootState, PosResponseProps>((state) => state.pos);

  const { setCustomerParamsId, userDetails } = useGetCustomerDetails()
  const { creditHoldFlag, posCustCode, priceScheduleId, priceScheduleName, taxExemptFlag, email, storeCreditAmt, lastOrderedAt } = userDetails ?? {}

  const searchCustomersDraft = useRef<HTMLInputElement>();
  const [doesHaveMore, setHaveMore] = useState<boolean>(true);
  const [sortIndex, setSortIndex] = useState(0);
  const [deleteCartId, setDeleteCartId] = useState<any>({});
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [isSnackbarVisibleResume, setIsSnackbarVisibleResume] = useState(false);
  const [customerDraftDelete, setCustomerDraftDelete] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [enterPress, setEnterPress] = useState(false)
  const currentWorkspace = getLocalStoraGeItem("workspace");
  const [downPress, setDownPress] = useState(false);
  const [upPress, setUpPress] = useState(false);
  const { ASCENDING, DESCENDING } = SortIndex
  const tableRowRef = useRef<React.Ref<HTMLTableRowElement>>();
  const { customer } = posFlowData || {};
  const { customerId } = customer || {};

  useEffect(() => {
    if (customerId) {
      setCustomerParamsId(customerId)
    }
  }, [customerId])

  useEffect(() => {
    const data = {
      ...posFlowData,
      customer: {
        ...posFlowData?.customer,
        priceScheduleId: priceScheduleId,
        priceScheduleName: priceScheduleName,
        taxExemptFlag: taxExemptFlag,
        creditHoldFlag: creditHoldFlag,
        posCustCode: posCustCode,
        email: email,
        storeCreditAmt: storeCreditAmt,
        lastOrderedAt: lastOrderedAt
      },
    };
    dispatch(setPosFlowData(data));
  }, [priceScheduleId, priceScheduleName]);

  useEffect(() => {
    setTimeout(() => {
      if (searchCustomersDraft.current) {
        searchCustomersDraft.current.focus();
      }
    }, 0);
    if (document?.activeElement === searchCustomersDraft.current) {
      dispatch(arrowResetGeneric());
    }
  }, [])

  useEffect(() => {
    dispatch(savedDraftsListPageReset());
    const { stockWarehouseId } = currentWorkspace || {};
    const body = {
      stockWarehouseId: stockWarehouseId,
      buyerId: customerId,
      page: 1,
      size: 20,
      sort: sortIndex ? ASCENDING : DESCENDING,
    };
    dispatch(savedDraftsListPageRequest(body));
  }, [sortIndex]);
  const { priceVisible } = posFlowData || {};

  useEffect(() => {
    if (priceVisible === undefined) {
      const data = { ...posFlowData, priceVisible: true };
      dispatch(setPosFlowData(data))
    }
  }, [])

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return function cleanup() {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [searchValue, selectedIndexGeneric, searchCustomersDraft]);

  useEffect(() => {
    async function downPressHandler() {
      if (downPress) {
        scrollToSetting(selectedIndexGeneric);
        if (dataDisplay?.length) {
          await dispatch(arrowDownIndexGeneric(dataDisplay.length));
        }
        setDownPress(false);
      }
    }
    downPressHandler()

  }, [downPress]);

  useEffect(() => {
    async function upPressHandler() {
      if (upPress) {
        await dispatch(arrowUpIndexGeneric());
        if (selectedIndexGeneric > -1) {
          scrollToSetting(selectedIndexGeneric);
        }
        setUpPress(false);
      }
    }
    upPressHandler()

  }, [upPress]);

  const { resumeDraft, clearCart } = snackbarDetail
  const { data, page, total } = savedDraftsList || {};

  const dataDisplay = (searchDrafts?.length || searchValue) && enterPress ? searchDrafts : data;
  const currentTaker = getLocalStoraGeItem("taker");
  const { deleteDarkGrayIcon, deleteHoverDarkGrayIcon } = images;
  const classes = drawerUseStyles();

  const isActiveCart = window.location.search.includes("cart");

  const handleScroll = () => {
    if (data?.length === total) {
      setHaveMore(false);
    }
    if (data?.length < total) {
      const body = {
        stockWarehouseId: currentWorkspace?.stockWarehouseId,
        buyerId: Number(customerAccount?.customerId),
        page: page + 1,
        size: 20,
        sort: sortIndex ? ASCENDING : DESCENDING,
      };
      dispatch(savedDraftsListPageRequest(body));
    }
  };

  const removeDraft = (draft) => {
    setDeleteCartId(draft);
    setDeletePopUp(true);
  };

  const clearCartIdAPI = async () => {
    const currentWorkspace = JSON.parse(localStorage.getItem("workspace"));
    const payload = {
      stockWarehouseId: currentWorkspace?.stockWarehouseId,
      buyerId: deleteCartId.customerId,
    };
    const res = await cartClear(Number(deleteCartId?.cartId));
    if (res && data.length > 1) {
      dispatch(savedDraftsRequest(payload));
    }
    if (res) {
      dispatch(deleteDraftOptimistically(deleteCartId?.cartId));
    }
    setDeleteSuccess(true);
    dispatch(snakbarShow({ message: "Saved draft deleted" }))
    setTimeout(() => {
      setDeletePopUp(false);
      setCustomerDraftDelete(false);
    }, 1000);
    setTimeout(() => {
      const filteredDataInfo = data.filter(
        (data) => data?.cartId !== deleteCartId?.cartId
      );
      if (filteredDataInfo?.length === 0) {
        const data = {
          ...posFlowData,
          draftCartDeleted: true,
        };
        dispatch(savedDraftsReset());
        dispatch(setPosFlowData(data));
      }
    }, 1000);
  };

  const clearAllDraftAPI = () => {
    deletePopUp && setDeletePopUp(false);
  };

  const handleKeyPress = (e) => {
    const { keyCode } = e;
    const { downarrow, uparrow, tab } = keys
    if (keyCode === keys.enter) {
      if (document.activeElement === searchCustomersDraft.current) {
        setEnterPress(true);
        const payload = {
          stockWarehouseId: currentWorkspace?.stockWarehouseId,
          buyerId: posFlowData?.customer.customerId,
          jobName: searchValue,
        };
        dispatch(searchDraftsRequest(payload));
      } else {
        const element = document.querySelector(".Mui-focusVisible");
        if (element) {
          return;
        } else if (selectedIndexGeneric > -1) {
          const { cartId } = dataDisplay[selectedIndexGeneric];
          handleSerchCustomer(cartId);
        }
      }
    }
    if (keyCode === downarrow && !savedOrderFoundInfoRef.current) {
      if (document?.activeElement === searchCustomersDraft.current) {
        dispatch(arrowResetGeneric());
        searchCustomersDraft.current.blur();
      }
      setDownPress(true);
    } else if (keyCode === uparrow && !savedOrderFoundInfoRef.current) {
      setUpPress(true);
    }
    if (keyCode === tab) {
      dispatch(arrowResetGeneric())
    }
  };

  const priceScheduleData = (cartId) => {
    const preparedData = {
      stockWarehouseId: currentWorkspace?.stockWarehouseId,
      customerId: posFlowData?.customer.customerId,
      takerId: currentTaker?.personId,
      cartId,
    };
    dispatch(setJobNameForAll(""));
    setIsSnackbarVisibleResume(true);
    dispatch(clearPackingSlipData());
    dispatch(getShippingFacilitiesRequest(posFlowData?.customer?.customerId));
    dispatch(loadCartRequest(preparedData));
  };

  useEffect(() => {
    if (isLoadCartSuccess && !isActiveCart) {
      setIsSnackbarVisibleResume(false)
      if (loadCartDetails !== undefined && loadCartDetails?.products) {
        const { customer } = loadCartDetails || {}
        const { isPosGeneric, fullName, companyName, phone, email } = customer || {}
        if (isPosGeneric) {
          const splitFullname = (fullName?.split(" "))
          const genericUserFirstName = splitFullname?.slice(0, -1).join(" ");
          const genericUserLastName = splitFullname[splitFullname?.length - 1];

          const data = {
            ...posFlowData,
            firstName: genericUserFirstName || "",
            lastName: genericUserLastName || "",
            phone: phone || "",
            email: email || "",
            companyName: companyName || "",
            isStorePickup: false,
            shippingCharge: {
              shippingAmt: loadCartDetails?.shippingFeeAmt,
            },
          };
          dispatch(recipientInfoSwitchInfoData(false));
          dispatch(setPosFlowData(data));
        }
        // *? If you want to open Continue with old price model then uncomment this code
        // const isPriceChangesProduct = products?.filter(
        //   (item) => item?.isPriceChanged
        // );
        // if (isPriceChangesProduct?.length > 0) {
        //   dispatch(getupdateItemModal(true));
        // }
      }

      navigate(`${Paths.posCart}/${loadCartDetails?.cartId}`);
    }
  }, [isLoadCartSuccess])

  const handleSerchCustomer = (cartId) => {
    if (posFlowData?.customer.hasInternalNotes === true) {
      handleCloseModal(true);
      setDraftCartId(cartId)
    } else {
      priceScheduleData(cartId);
    }
  };

  const handleClearSearch = () => {
    setSearchValue("");
    dispatch(clearSearchDrafts());
  };

  const onCloseResumeDraft = () => {
    setIsSnackbarVisibleResume(false)
  }
  const handleSort = (index) => {
    setSortIndex(index)
    document.getElementById("dropdownMenuButton").focus();
  };

  return (
    <>
      <section className="document-main-section">
        <div className="document-container">
          <div className="input-with-add-button">
            <PosInput
              value={searchValue}
              variant="filled"
              id="filled-basic"
              type="text"
              className="document-search-input search-input-focus"
              inputRef={searchCustomersDraft}
              onBlur={() => {
                searchCustomersDraft?.current?.blur()
              }}
              placeholder={staticData.draftSearchPlaceholder}
              onChange={(e) => {
                setEnterPress(false)
                setSearchValue(e.target.value)
              }}
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <>
                    <div className="enter-search-text-new-order">
                      <span>Press Enter</span>
                      <PosImage
                        src={images.pressEnter}
                        alt="enter-icon"
                        className="press-enter-image "
                      />
                      <span>to search</span>
                    </div>
                    {searchValue ? (
                      <InputAdornment
                        position="end"
                        className="search-order-input-last-adornment"
                      >
                        <div className="d-flex align-items-center">
                          <CloseIcon
                            className="cursor-pointer ml-2"
                            onClick={handleClearSearch}
                          />
                        </div>
                      </InputAdornment>
                    ) : (
                      <></>
                    )}
                  </>
                ),
              }}
            />
          </div>
          {/* // Need For sort data */}
          {dataDisplay?.length ? (
            <div className="dropdown txt-align sort-by-dropdown sort-dropdown-index">
              <div className="relative-light-border"></div>
              <button
                className="sort fw-medium fs-14 text-dark btn btn-link dropdown-toggle sorting-dropdown-btn"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {`Sort By: ${sortIndex ? DocumentSortBy[sortIndex] : "Recent first"
                  }`}
              </button>
              <div
                className="dropdown-menu selectList-drop"
                abyria-labelledby="dropdownMenuButton"
              >
                {DocumentSortBy.map((option, index) => (
                  <button
                    onClick={() => handleSort(index)}
                    className={`btn btn-link ${index === sortIndex ? "active" : ""
                      }`}
                    key={`${index}sort`}
                  >
                    {option}
                  </button>
                ))}
              </div>
            </div>
          ) : null}

          <div>
            <TableContainer
              component={Paper}
              className="customer-info-table-container"
            >
              <InfiniteScroll
                dataLength={data?.length || 0}
                next={handleScroll}
                height={600}
                hasMore={doesHaveMore}
                loader={false}
                scrollThreshold={0.9}
              >
                {isDraftLoading || isSearchApiLoading ? (
                  <Skeleton count={5} />
                ) : (
                  <Table
                    aria-label="simple table"
                    className="customer-table-styling"
                  >
                    {dataDisplay?.length ? (
                      <TableHead className="customer-draft-header">
                        <TableRow>
                          <TableCell className="customer-info-table-title first-table-row">
                            DATE
                          </TableCell>
                          <TableCell className="customer-info-table-title second-table-row">
                            ID
                          </TableCell>
                          <TableCell className="customer-info-table-title third-table-row">
                            PO #
                          </TableCell>
                          <TableCell className="customer-info-table-title fourth-table-row">
                            JOB NAME
                          </TableCell>
                          <TableCell className="customer-info-table-title fifth-table-row">
                            ORDERER
                          </TableCell>
                          <TableCell className="customer-info-table-title sixth-table-row">
                            ITEMS
                          </TableCell>
                          <TableCell className="customer-info-table-title seventh-table-row"></TableCell>
                        </TableRow>
                      </TableHead>
                    ) : null}
                    <TableBody>
                      {dataDisplay?.length ? (
                        dataDisplay?.map((row, index) => {
                          const {
                            createdAt,
                            cartId,
                            jobName,
                            companyName,
                            purchaseOrderNum,
                            posCustCode,
                            itemCount,
                          } = row;
                          return (
                            <TableRow
                              className={`customer-table-data-row draft-data-row ${index === selectedIndexGeneric
                                ? "selectedRow"
                                : ""
                                }`}
                              key={`${createdAt}draft`}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                              style={{
                                cursor: "pointer",
                                backgroundColor:
                                  index === selectedIndexGeneric
                                    ? "lightgray"
                                    : "white",
                              }}
                              ref={tableRowRef[index]}
                              id={`tableRowRef${index}`}
                              tabIndex={0}
                              role="button"
                              onKeyDown={(e) => {
                                if (
                                  e.key === "Enter" &&
                                  !document.activeElement.classList.contains(
                                    "button-focus-delete-draft"
                                  )
                                ) {
                                  handleSerchCustomer(cartId);
                                }
                              }}
                            >
                              <TableCell
                                className="customer-table-row-data-styling"
                                onClick={() => handleSerchCustomer(cartId)}
                              >
                                <span>
                                  {createdAt
                                    ? moment(createdAt).format("MM/DD/YY")
                                    : ""}
                                </span>
                              </TableCell>
                              <TableCell
                                className="customer-table-row-data-styling"
                                onClick={() => handleSerchCustomer(cartId)}
                              >
                                <span>{cartId}</span>
                              </TableCell>
                              <TableCell
                                className="customer-table-row-data-styling"
                                onClick={() => handleSerchCustomer(cartId)}
                              >
                                <HandleLongData jobName={purchaseOrderNum} />
                              </TableCell>
                              <TableCell
                                className="customer-table-row-data-styling"
                                onClick={() => handleSerchCustomer(cartId)}
                              >
                                <HandleLongData jobName={jobName} />
                              </TableCell>
                              <TableCell
                                className="customer-table-row-data-styling d-flex flex-column"
                                onClick={() => handleSerchCustomer(cartId)}
                              >
                                <div>
                                  <span>{companyName || ""}</span>
                                </div>
                                <div className="orderer-name">
                                  <span>{posCustCode || ""}</span>
                                </div>
                              </TableCell>
                              <TableCell
                                className="customer-table-row-data-styling"
                                onClick={() => handleSerchCustomer(cartId)}
                              >
                                <span>{itemCount}</span>
                              </TableCell>
                              <TableCell className="draft-sec-last-cell"></TableCell>
                              <div className="customer-table-row-data-styling customer-table-row-hover-data d-flex h-100 ">
                                <button
                                  className="draft-delete-section customer-table-row-remove hover-with-delete border-0 bg-transparent p-0 button-focus-delete-draft"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeDraft(row);
                                    setCustomerDraftDelete(true);
                                  }}
                                >
                                  <PosImage
                                    src={deleteDarkGrayIcon}
                                    alt="delete-icon"
                                    className="delet-order-img"
                                  />
                                  <PosImage
                                    src={deleteHoverDarkGrayIcon}
                                    alt="delete-icon"
                                    className="delet-order-hover-img"
                                  />
                                </button>
                              </div>
                            </TableRow>
                          );
                        })
                      ) : !searchValue ? (
                        <TableRow className="border-remove">
                          <TableCell colSpan={6} className="mt-5 text-center pt-6">
                            <PosImage
                              src={images.noDraftsIcon}
                              height={105}
                              width={186}
                              alt="add product"
                            />
                            <span className="int-20-reg d-block empty-state-fonts">
                              {constantCustomerInfo.draftsEmptyState}
                            </span>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow className="border-remove">
                          <TableCell colSpan={6} className="mt-5 text-center">
                            <span className="int-20-reg d-block empty-state-fonts">
                              {title.noDraftsSaved} "{searchValue}"
                            </span>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                )}
              </InfiniteScroll>
            </TableContainer>
          </div>
        </div>
        <Drawer
          anchor={"right"}
          open={customerDraftDelete}
          hideBackdrop={false}
          className={`${classes.drawer} main-drawer-classes default-drawer-style no-cart-drawer main-drawer-classes drawer-scrollbar-style customer-sec-delete-draft-drawer`}
          onClose={() => setCustomerDraftDelete(false)}
          id="cartDrawerContent"

        >
          <Box sx={{ width: 600 }} role="presentation">
            <Button
              classes="back-next-btn-small back-responsive-btn responsive-back-btn"
              onClick={() => setCustomerDraftDelete(false)}
              tabIndex={-1}
            >
              <i className="fas fa-arrow-left" />
            </Button>
          </Box>
          <Container className="modal-content-style modal-container-styling">
            <DeleteDraftComponent
              deleteAllPopUp={false}
              deleteCartId={deleteCartId}
              clearAllDraftAPI={clearAllDraftAPI}
              setDeletePopUp={setDeletePopUp}
              clearCartIdAPI={clearCartIdAPI}
              setCustomerDraftDelete={setCustomerDraftDelete}
            />
          </Container>
        </Drawer>
      </section>
      <PosSnackbar
        open={isSnackbarVisibleResume}
        message={resumeDraft}
        onClose={onCloseResumeDraft}
        actionText={clearCart}
      />
    </>
  );
};
export default CustomerDrafts;
