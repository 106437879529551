import { ChangeEvent, Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ManageUserModal, UserListItems } from "apps/admin";

import {
    InputWithIcon, LockStatusForm
} from "components";
import { Keys, PaginationFormat } from "constants/enum";
import { adminMessage } from "constants/message";
import { Paths, Placeholders, Tabs } from "enums";
import { RootState, User, user } from "models";
import { UpdateModalContent } from "sagas/modal/actions";
import {
    getAllSearchUsers,
    getAllUsers,
    getInvitedUsers,
    searchValue,
    setSearchUserId
} from "sagas/user/actions";
import { cancelInviteUser, getLoggedInUser, resendInviteUser, searchUserByParam } from "services";

import { snakbarShow } from "sagas/pos/snakbar/snakbar.slice";
import "styles/admin/UserList/UserListStyle.css";
import "styles/common.css";
import "./UserList.scss";


export function Users(
    userData: User[],
    page: number,
    setPage: any,
    usersCount: number,
    isInvited: boolean,
    isAwaitingApproval: boolean,
    activeTab: number,
    loginUser: User,
    setSelectedTab: any
) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [currentUser, setCurrentUser] = useState<User>();
    const [searchTerm, setSearchTerm] = useState("");
    const [userSearchResults, setUserSearchResults] = useState([]);
    const [pagec, setPagec] = useState(1);

    const { invitedUserData, isPaginated, searchUser, searchUserResult, searchUserLoading, searchUserId } = useSelector<
        RootState,
        user
    >((state) => state.user);
    const { inviteSent, failInviteSent, inviteCancel, failInviteCancel, } = adminMessage;
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.value) {
            dispatch(searchValue(""))
            setSearchPage(1)
        }
        setSearchTerm(e.target.value);
    };
    const { One, Ten, Ascending } = PaginationFormat;
    const { enter } = Keys;

    useEffect(() => {
        if (searchUserId && searchUser) {
            const foundIndex = userSearchResults.findIndex(searchUserData => searchUserData.id == Number(searchUserId));
            if (foundIndex) {
                getLoggedInUser(Number(searchUserId)).then((response) => {
                    const data = {
                        email: response.email,
                        firstName: response?.firstName,
                        fullName: response?.fullName,
                        id: response.personId,
                        lastName: response?.lastName,
                        roles: [response?.roles[0].type],
                        status: response?.status.type,
                        createdAt: response?.createdAt
                    }
                    userSearchResults[foundIndex] = data;
                    dispatch(setSearchUserId(""))
                })
            }
        }
    }, [searchUserId]);

    const handleKeyPress = (event) => {
        if (event.key === enter) {
            if (searchTerm) {
                dispatch(searchValue(searchTerm))
                dispatch(getAllSearchUsers({ page: One, query: searchTerm }))
            } else {
                dispatch(searchValue(""))
                setUserSearchResults([]);
            }
        }
    }

    useEffect(() => {
        setUserSearchResults(searchUserResult)
        setMoreSearchAvailable(isPaginated)
    }, [searchUserResult, isPaginated])

    useEffect(() => {
        setSearchTerm(searchUser)
    }, [activeTab])

    useEffect(() => {
        setPagec(pagec);
    }, [usersCount]);

    useEffect(() => {
        if (window.location.pathname === Paths.adminInvitedUsers) {
            !invitedUserData?.data && navigate(Paths.adminUsers);
        }
    }, [invitedUserData?.data]);

    const manageUserOption = () => {
        const isUser = loginUser?.personId === currentUser?.personId;
        return {
            id: "manage",
            type: "manage",
            icon: "fa-cog",
            option: "Manage",
            optionAction: () => {
                dispatch(
                    UpdateModalContent({
                        isOpen: true,
                        isAdmin: true,
                        navigate: true,
                        classModal: "user-profile-dialog",
                        children: (
                            <ManageUserModal
                                isUser={isUser}
                                user={currentUser}
                                isApproval={false}
                                loginUser={loginUser}
                                page={page}
                            />
                        ),
                        modalSize: "xl",
                        fixedModal: true,
                        removePaddingBottom: false,
                        noMaxWidth: false
                    })
                );
            },
        };
    };

    const reviewOption = () => {
        return {
            id: "review",
            type: "",
            icon: "fa-th-list",
            option: "Review",
            optionAction: () => {
                // Required Later
            },
        };
    };

    const resendOption = () => {
        return {
            id: "resendInvitation",
            type: "",
            icon: "fa-envelope-open",
            option: "Resend Invitation",
            optionAction: () => {
                resendInviteUser(currentUser?.personId, loginUser?.personId)
                    .then((res) => {
                        if (res?.data) {
                            dispatch(snakbarShow({ message: inviteSent }))
                        } else {
                            dispatch(snakbarShow({ message: failInviteSent }))
                        }
                    })
                    .catch(() => {
                        // Required Later
                    });
            },
        };
    };

    const cancelInviteOption = () => {
        return {
            id: "cancel",
            type: "",
            icon: "fa-times-circle",
            option: "Cancel Invitation",
            optionAction: () => {
                cancelInviteUser(currentUser?.personId)
                    .then((res) => {
                        if (res?.data && res?.data?.data) {
                            dispatch(snakbarShow({ message: inviteCancel }))
                            dispatch(getInvitedUsers(page));
                        } else {
                            dispatch(snakbarShow({ message: failInviteCancel }))
                        }
                    })
                    .catch(() => {
                        // Required Later
                    });
            },
        };
    };

    const lockUnlockOption = () => {
        return {
            id: "lock",
            type: currentUser?.status?.type,
            icon:
                currentUser?.status?.type === "LOCKED_OUT" ? "fa-unlock" : "fa-lock",
            option:
                currentUser?.status?.type === "LOCKED_OUT"
                    ? "Unlock account"
                    : "Lock account",
            optionAction: (user) => {
                dispatch(
                    UpdateModalContent({
                        isOpen: true,
                        isAdmin: true,
                        children: (
                            <LockStatusForm
                                currentPage={page}
                                user={user}
                                onSuccess={() => {
                                    dispatch(getAllUsers(page));
                                    dispatch(
                                        UpdateModalContent({
                                            isOpen: false,
                                            children: null,
                                            fixedModal: true,
                                            removePaddingBottom: false,
                                            noMaxWidth: false
                                        })
                                    );
                                }}
                                onError={() => {
                                    dispatch(
                                        UpdateModalContent({
                                            isOpen: false,
                                            children: null,
                                            fixedModal: true,
                                            removePaddingBottom: false,
                                            noMaxWidth: false
                                        })
                                    );
                                }}
                            />
                        ),
                        fixedModal: true,
                        removePaddingBottom: true,
                        noMaxWidth: true
                    })
                );
            },
        };
    };
    const { permissions } = loginUser || {};
    const { tabIndexFirst } = Tabs;
    const { search, Search, searchAmongUser } = Placeholders;

    //Search Lazy Loading
    const [searchPage, setSearchPage] = useState(1);
    const [moreSearchAvailable, setMoreSearchAvailable] = useState(isPaginated);
    useEffect(() => {
        (async () => {

            if (moreSearchAvailable && searchTerm) {
                const body = {
                    page: searchPage,
                    size: Ten,
                    sortBy: Ascending,
                    query: searchTerm
                }
                const response = await searchUserByParam(body);
                if (response?.data) {
                    const { data, isPaginated } = response.data;
                    const previousSearch = [...userSearchResults, ...data];
                    setMoreSearchAvailable(isPaginated);
                    setUserSearchResults(previousSearch);
                } else {
                    setUserSearchResults([]);
                    setMoreSearchAvailable(false);
                }
            }
        })();
    }, [searchPage])

    useEffect(() => {
        if (!searchUserResult) return;

        const observerOptions = {
            root: document.querySelector(".body"),
            rootMargin: "0px",
            threshold: 0,
        };
        // watch all tips when they enter the viewport
        const watchTips = (entries) => {
            entries.forEach((entry) => {
                if (!entry.isIntersecting) return;
                setSearchPage((prev) => prev + 1);
            });
        };

        // observe the tips footer to lazily load more available tips
        const tipsObserver = new IntersectionObserver(watchTips, observerOptions);

        const tipItems = document.querySelectorAll(".target_scroll");
        tipItems.forEach((item) => {
            tipsObserver.observe(item);
        });

        // cleanup
        return () => {
            tipsObserver.disconnect();
        };
    }, [userSearchResults]);

    return (
        <Fragment>
            {
                activeTab === tabIndexFirst && permissions?.userAdminPermissions?.canView === 1 ?
                    (<InputWithIcon
                        className="mb-4 mt-4"
                        textInputStyle="text-input-style"
                        id={search}
                        label=""
                        type="input"
                        placeholder={searchAmongUser}
                        name={search}
                        ariaLabel={Search}
                        iconName={search}
                        iconPosition={"prepend"}
                        iconStyle="grey-icon-color"
                        value={searchTerm}
                        onChange={handleChange}
                        onKeyPress={handleKeyPress}
                        isSearch={true}
                        register
                    />) : null
            }

            <div className="user-list">
                <UserListItems
                    usersCount={usersCount}
                    page={page}
                    setPage={setPage}
                    activeTab={activeTab}
                    setSelectedTab={setSelectedTab}
                    options={
                        currentUser?.status?.name === "Active" ||
                            currentUser?.status?.type === "LOCKED_OUT"
                            ? permissions?.userAdminPermissions?.canLock === 1
                                ? [manageUserOption(), lockUnlockOption()]
                                : loginUser.personId === currentUser.personId && [
                                    manageUserOption(),
                                ]
                            : currentUser?.status?.name === "Invited"
                                ? [resendOption(), manageUserOption(), reviewOption()]
                                : currentUser?.status?.type === "NEW" && [
                                    resendOption(),
                                    cancelInviteOption(),
                                ]
                    }
                    setUserToEdit={setCurrentUser}
                    isInvited={isInvited}
                    isAwaitingApproval={isAwaitingApproval}
                    users={
                        userData?.length && !searchUser ? userData : userSearchResults
                    }
                    searchTerm={searchUser}
                    lockUnlockOptions={lockUnlockOption()}
                    loginUser={loginUser}
                    isLoader={searchUserLoading}
                    isPaginate={isPaginated}
                    moreSearchAvailable={moreSearchAvailable}
                />
            </div>
        </Fragment>
    );
}