import { Icon } from "apps/springboard/Component/SBItems/elements";
import { useMemo, useState } from "react";
import "./RefundSelect.scss";
// import { defaultSelectedRefundMode } from "constants/enum";

const shouldDisplayCash = ['CASH', 'CREDIT_CARD', 'CHECK'];
const shouldDisplayCard = ['CREDIT_CARD'];
const shouldDisplayAccount = ['CASH', 'CREDIT_CARD', 'CHECK', 'REGISTERED_ACCOUNT'];
const shouldDisplayStoreCredit = ['CASH', 'CREDIT_CARD', 'CHECK', 'CUSTOMER_CREDIT'];

const refundMethods = [
    {
        title: "Refund via Cash",
        optionLabel: "Cash",
        value: "0",
        icon: "payments",
        allowedPaymentModes: shouldDisplayCash,
        checkCustomerType: false
    },
    {
        title: "Refund via Card",
        optionLabel: "Card",
        value: "2",
        icon: "credit_card",
        allowedPaymentModes: shouldDisplayCard,
        checkCustomerType: false
    },
    {
        title: "Refund to Account",
        optionLabel: "Account",
        value: "4",
        icon: "account_balance_wallet",
        allowedPaymentModes: shouldDisplayAccount,
        checkCustomerType: true
    },
    {
        title: "Refund in Store Credit",
        optionLabel: "Store Credit",
        value: "3",
        icon: "card_membership",
        allowedPaymentModes: shouldDisplayStoreCredit,
        checkCustomerType: true
    }
];


const RefundSelect = ({ customerType, payment, onChange, totalRefundNew, defaultSelectedRefundMode }) => {

    const paymentMode = payment[0]?.paidVia
    const [selectedValue, setSelectedValue] = useState(defaultSelectedRefundMode || "");

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
        onChange(event.target.value);
    };

    const options = useMemo(() => {
        return refundMethods.reduce((acc, method) => {
            if (
                method.allowedPaymentModes.includes(paymentMode) &&
                (!method.checkCustomerType || (method.checkCustomerType && !customerType))
            ) {
                acc.push(
                    <option value={method.value} key={method.value}>
                        {method.optionLabel}
                    </option>
                );
            }
            return acc;
        }, []);
    }, [customerType, payment]);

    return (
        <label htmlFor={options.length > 1 ? "refund-method-select" : ""} className="refund-select">
            <span className="refund-select-label-group">
                <span className="refund-select-label">
                    {selectedValue === "2"
                        ? `Refund via ${payment[0]?.creditCardType}${payment[0]?.cardNum ? ` ${payment[0]?.cardNum}` : ""}`
                        : refundMethods.find((m) => m.value === selectedValue)?.title
                    }
                </span>
                {options.length > 1 && <Icon
                    name="expand_more"
                    size={14}
                    className="refund-select-label-icon"
                />}
            </span>

            <span className="refund-select-value-group">
                <Icon
                    name={refundMethods.find((m) => m.value === selectedValue)?.icon}
                    size={20}
                    className={`refund-select-value-icon ${refundMethods
                        .find((m) => m.value === selectedValue)
                        ?.optionLabel.toLowerCase()
                        .split(" ")
                        .join("-")}`}
                />
                <span className="refund-select-value">$ {totalRefundNew}</span>
            </span>

            {options.length > 1 && <select
                id="refund-method-select"
                className="refund-select-dropdown"
                value={selectedValue}
                onChange={handleChange}
                aria-label="Select refund method"
            >
                {options}
            </select>}
        </label>
    )
}

export default RefundSelect;




