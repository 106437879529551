import {
  Paper,
  Table, TableBody, TableCell,
  TableContainer,
  TableHead, TableRow
} from '@mui/material';
import { Button } from "components";
import { PosButton, PosImage, PosModel } from "../Common/uiCore";


import { backOrderConfirmationModalConstants } from "constants/constant";
import { ClearIcon } from "constants/icons";

import images from 'constants/images';
import "./backOrderConfirmationModal.scss";

const BackOrderConfirmationModal = ({ totalItems, backOrderItems, open, onCloseModal, continueToPayment }) => {
  const { item, items, backorder, backorders, Backorder, continueWith } = backOrderConfirmationModalConstants
  const { defaultIcon } = images;
  return (
    <>
      <PosModel
        className={`set-backorder-code-modal`}
        dialogClassName={`set-backorder-code-modal-container  set-backorder-modal-container`}
        open={open}
        onClose={onCloseModal}
        dialogHeader={
          <div className="modal-header align-items-center">
            <span className="modal-title">
              {`${Backorder} ${totalItems} ${totalItems > 1 ? items : item}?`}
            </span>
            <Button className="bg-transparent border-0 p-0 button-focus" onClick={onCloseModal}>
              <ClearIcon
                className="clear-icon"
              />
            </Button>
          </div>
        }
        dialogFooter={
          <>
            <div
              className={`d-flex flex-wrap jcfe w-100 flex-row-reverse justify-end`}
            >
              <div className="d-flex flex-wrap">
                <PosButton
                  variant="contained"
                  color="secondary"
                  className={`btn-font-600 add-to-return-cart-button mt-2 back-order-confirmation-modal-btn btn`}
                  fontWeight
                  onClick={() => {
                    continueToPayment()
                    onCloseModal()
                  }}
                >
                  {`${continueWith} ${totalItems > 1 ? backorders : backorder}`}
                </PosButton>
              </div>
              <div>
                <PosButton
                  type="button"
                  variant="contained"
                  className={`add-to-return-cart-button mr-2 mt-2 btn-font-600 set-storage-back-btn btn`}
                  fontWeight
                  onClick={onCloseModal}
                >
                  Back
                </PosButton>
              </div>
            </div>
          </>
        }
      >
        <div className="message-body">
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 700 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow className='table-header'>
                    <TableCell className='px-2 border-0'>Line #</TableCell>
                    <TableCell className='px-2 border-0'>Item</TableCell>
                    <TableCell className='text-right px-2 border-0'>Ordered</TableCell>
                    <TableCell className='text-right px-2 border-0'>Picked</TableCell>
                    <TableCell className='text-right px-2 border-0'>Back Order</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {backOrderItems?.map((item) => (
                    <TableRow key={item.productId}>
                      <TableCell className='py-2 px-2 border-0 line-id-backorder'>{item.lineIdData}</TableCell>
                      <TableCell className='py-2 px-2'>
                        <div className="back-order-info cart-product-info d-flex pr-2">
                          <div className='pr-2'>
                            <PosImage
                              src={item?.thumbImg || defaultIcon}
                              alt="search-list-icon"
                              className="search-list-img"
                            />
                          </div>
                          <div className="product-data-text">
                            <div className='pb-1'>
                              <span className="product-name-cart font-medium text-black">
                                {item?.productName}
                              </span>
                            </div>
                            <div>
                              <span className="manufacturer-code">{item?.manufacturerCode}</span>
                            </div>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className='py-2 px-2 backorder-qty'>{item.qtyOrdered}</TableCell>
                      <TableCell className='py-2 px-2 backorder-qty'>{isNaN(Number(item?.totalPickedValue))
                        ? 0
                        : Number(item?.totalPickedValue) ?? 0}</TableCell>
                      <TableCell className='py-2 px-2 backorder-qty'>{item?.totalPickedValue > 0 ? (item?.qtyOrdered - item?.totalPickedValue) < 0 ? "--" : (item?.qtyOrdered - item?.totalPickedValue) : item?.qtyOrdered}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </PosModel>
    </>
  );
};

export default BackOrderConfirmationModal;
